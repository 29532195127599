define("account-berry-frontend/plaid-link/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      accounts: {
        serialize: false,
        deserialize: 'records'
      },
      plaid_link_transactions: {
        serialize: true,
        deserialize: 'records'
      }
    }
  });

  _exports.default = _default;
});