define("account-berry-frontend/w2/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    year: _emberData.default.attr('string'),
    company_id: _emberData.default.attr('number'),
    electronic_filing_number: _emberData.default.attr('string'),
    bso_user_id: _emberData.default.attr('string'),
    a: _emberData.default.attr('string'),
    kind_of_player: _emberData.default.attr('string'),
    json_data: _emberData.default.attr(''),
    created_at: _emberData.default.attr(),
    updated_at: _emberData.default.attr()
  });

  _exports.default = _default;
});