define("account-berry-frontend/invoice-payment/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    session_id: _emberData.default.attr('string'),
    public_token: _emberData.default.attr('string'),
    invoice_id: _emberData.default.attr('number'),
    app_fee: _emberData.default.attr('number'),
    amount: _emberData.default.attr('number'),
    tax: _emberData.default.attr('number'),
    receipt_url: _emberData.default.attr('string'),
    paid: _emberData.default.attr('boolean'),
    template: _emberData.default.attr('string'),
    stripe_account_id: _emberData.default.attr('string')
  });

  _exports.default = _default;
});