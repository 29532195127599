define("account-berry-frontend/companies/show/employees/choferil/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    init: function init() {
      var _this2 = this;

      this._super();

      var channel = this.get('session.pusher').subscribe("choferil-channel-".concat(this.get('session.data.authenticated.id')));
      channel.bind('quarter', function (response) {
        _this2.store.findRecord('json-storage', response.json_storage_id).then(function (jsonStorage) {
          _this2.set('quarterData', jsonStorage.get('stored_json.quarter'));
        });
      });
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    employeeRows: [{
      ssX: 56,
      ssY: 273,
      nameX: 240,
      nameY: 273,
      licenseX: 418,
      licenseY: 273,
      weeksX: 514,
      weeksY: 273
    }, {
      ssX: 56,
      ssY: 252,
      nameX: 240,
      nameY: 252,
      licenseX: 418,
      licenseY: 252,
      weeksX: 514,
      weeksY: 252
    }, {
      ssX: 56,
      ssY: 231,
      nameX: 240,
      nameY: 231,
      licenseX: 418,
      licenseY: 231,
      weeksX: 514,
      weeksY: 231
    }, {
      ssX: 56,
      ssY: 213,
      nameX: 240,
      nameY: 213,
      licenseX: 418,
      licenseY: 213,
      weeksX: 514,
      weeksY: 213
    }, {
      ssX: 56,
      ssY: 192,
      nameX: 240,
      nameY: 192,
      licenseX: 418,
      licenseY: 192,
      weeksX: 514,
      weeksY: 192
    }, {
      ssX: 56,
      ssY: 174,
      nameX: 240,
      nameY: 174,
      licenseX: 418,
      licenseY: 174,
      weeksX: 514,
      weeksY: 174
    }, {
      ssX: 56,
      ssY: 154,
      nameX: 240,
      nameY: 154,
      licenseX: 418,
      licenseY: 154,
      weeksX: 514,
      weeksY: 154
    }, {
      ssX: 56,
      ssY: 132,
      nameX: 240,
      nameY: 132,
      licenseX: 418,
      licenseY: 132,
      weeksX: 514,
      weeksY: 132
    }, {
      ssX: 56,
      ssY: 114,
      nameX: 240,
      nameY: 114,
      licenseX: 418,
      licenseY: 114,
      weeksX: 514,
      weeksY: 114
    }, {
      ssX: 56,
      ssY: 93,
      nameX: 240,
      nameY: 93,
      licenseX: 418,
      licenseY: 93,
      weeksX: 514,
      weeksY: 93
    }],
    paymentDateObserver: Ember.observer('paymentDate', function () {
      try {
        var paymentDate = this.get('paymentDate').split("-");
        this.set('year', paymentDate[0]);
        this.set('month', paymentDate[1]);
        this.set('day', paymentDate[2]);
      } catch (error) {}
    }),
    pdfCoordinates: Ember.computed('quarterData', 'company', 'employerStatusChange', 'month', 'day', 'year', 'paymentMethod', 'numRecibo', 'quarterEndingOn', 'paymentAmount', 'weeklyRate', 'weeksPaid', function () {
      var totalTaxDue = (parseFloat(this.get('weeklyRate') || 0) * parseFloat(this.get('weeksPaid'))).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      return {
        'employerStatusChange': {
          value: this.get('employerStatusChange') ? "X" : "",
          x: 36,
          y: 658,
          size: 10
        },
        'month': {
          value: this.get('paymentDate') ? this.get('month') : "",
          x: 286,
          y: 638,
          size: 10
        },
        'day': {
          value: this.get('paymentDate') ? this.get('day') : "",
          x: 311,
          y: 638,
          size: 10
        },
        'year': {
          value: this.get('paymentDate') ? this.get('year') : "",
          x: 330,
          y: 638,
          size: 10
        },
        'ck': {
          value: this.get('paymentMethod') == 'ck' ? "X" : "",
          x: 407,
          y: 638,
          size: 10
        },
        'efe': {
          value: this.get('paymentMethod') == 'efe' ? "X" : "",
          x: 432,
          y: 638,
          size: 10
        },
        'verif': {
          value: this.get('paymentMethod') == 'verif' ? "X" : "",
          x: 457,
          y: 638,
          size: 10
        },
        'numRecibo': {
          value: this.get('numRecibo') || "",
          x: 486,
          y: 638,
          size: 10
        },
        'quarterEnded': {
          value: this.get('quarterEndingOn'),
          x: 403,
          y: 548,
          size: 10
        },
        'totalWeeksPaid': {
          value: this.get('weeksPaid') ? this.get('weeksPaid').toString() : "",
          x: 262,
          y: 482,
          size: 10
        },
        'totalTaxDue': {
          value: totalTaxDue || "",
          x: 262,
          y: 457,
          size: 10
        },
        'paymentAmount': {
          value: this.get('paymentAmount') || "",
          x: 262,
          y: 432,
          size: 10
        },
        'position': {
          value: this.get('position') || "",
          x: 99,
          y: 384,
          size: 10
        },
        'phone': {
          value: this.get('phone') || "",
          x: 315,
          y: 384,
          size: 10
        },
        'date': {
          value: this.get('date') ? this.get('date') : "",
          x: 461,
          y: 384,
          size: 10
        }
      };
    }),
    pageSetup: function pageSetup(page, keys, coordinates, pdfDoc) {
      var _this3 = this;

      var _this = this;

      keys.forEach(function (k) {
        var contentStream = pdfDoc.createContentStream(PDFLib.drawText(_this.get('helveticaFont').encodeText(_this3.get("".concat(coordinates, ".").concat(k, ".value"))), {
          x: _this.get("".concat(coordinates, ".").concat(k, ".x")),
          y: _this.get("".concat(coordinates, ".").concat(k, ".y")),
          size: _this.get("".concat(coordinates, ".").concat(k, ".size")) ? _this.get("".concat(coordinates, ".").concat(k, ".size")) : 10,
          font: 'Helvetica',
          colorRgb: _this.get("".concat(coordinates, ".").concat(k, ".color")) ? _this.get("".concat(coordinates, ".").concat(k, ".color")) : [0, 0, 1]
        }));
        page.addContentStreams(pdfDoc.register(contentStream));
      });
    },
    pdfProcess: function pdfProcess(pdfDoc) {
      var _this = this; // let pdfDoc = PDFLib.PDFDocumentFactory.load(existingPdfDocBytes);
      // pdfDoc = this.addPage(pdfDoc)


      var _pdfDoc$embedStandard = pdfDoc.embedStandardFont(PDFLib.StandardFonts.Helvetica),
          _pdfDoc$embedStandard2 = _slicedToArray(_pdfDoc$embedStandard, 2),
          helveticaRef = _pdfDoc$embedStandard2[0],
          helveticaFont = _pdfDoc$embedStandard2[1];

      this.set('helveticaRef', helveticaRef);
      this.set('helveticaFont', helveticaFont);
      var pages = pdfDoc.getPages();
      var firstPage = pages[0];
      pages.forEach(function (page) {
        page.addFontDictionary('Helvetica', helveticaRef);
      }); // ------------- FIRST PAGE ---------------------

      var firstPageKeys = Object.keys(this.get('pdfCoordinates')); // let valueKeys = Object.keys(this.get('firstPageValues'));
      // valueKeys.forEach((k) => {
      //   this.get(`firstPageValues.${k}`).forEach((coord, index) => {
      //     this.setValues(pdfDoc, firstPage, parseFloat(val).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), coord)
      //   })
      // });

      this.pageSetup(firstPage, firstPageKeys, 'pdfCoordinates', pdfDoc);
      this.employeeRowsProcedure(firstPage, pdfDoc); // ----------------------------------------------

      this.get('applicationController').set('showLoading', false);
      var pdfBytes = PDFLib.PDFDocumentWriter.saveToBytes(pdfDoc);
      this.downloadPdf(pdfBytes);
    },
    employeeRowsProcedure: function employeeRowsProcedure(firstPage, pdfDoc) {
      var _this4 = this;

      var totalEmployeeRowPages = Math.ceil(this.get('quarterData.employee_data.length') / 10);

      var copyPage = function copyPage(page) {
        var copiedPage = PDFLib.PDFPage.fromDict(PDFLib.PDFDictionary.from(new Map(page.map), page.index));

        if (page.getMaybe('Contents')) {
          page.normalizeContents();
          var originalContents = page.Contents;
          var copiedContents = PDFLib.PDFArray.fromArray(originalContents.array.slice(), page.index);
          copiedPage.set('Contents', copiedContents);
        }

        return copiedPage;
      };

      var _loop = function _loop(i) {
        var newPage = copyPage(firstPage);
        var newPageIndex = i;
        pdfDoc.insertPage(newPageIndex, newPage);
        var pages = pdfDoc.getPages();
        newPage = pages[newPageIndex];

        _this4.get('employeeRows').forEach(function (row, index) {
          var employeeIndex = index + 10 * i;

          if (_this4.get('quarterData.employee_data')[employeeIndex]) {
            _this4.addEmployeeRow(_this4.get('quarterData.employee_data')[index].ss, {
              x: row.ssX,
              y: row.ssY
            }, pdfDoc, newPage);

            _this4.addEmployeeRow(_this4.get('quarterData.employee_data')[index].name, {
              x: row.nameX,
              y: row.nameY
            }, pdfDoc, newPage);

            _this4.addEmployeeRow(_this4.get('quarterData.employee_data')[index].license_number, {
              x: row.licenseX,
              y: row.licenseY
            }, pdfDoc, newPage);

            _this4.addEmployeeRow(_this4.get('quarterData.employee_data')[index].total_weeks, {
              x: row.weeksX,
              y: row.weeksY
            }, pdfDoc, newPage);
          }
        });
      };

      for (var i = 1; i < totalEmployeeRowPages; i++) {
        _loop(i);
      }

      ; // This is the second page
      // It is done after the other pages because the second page has to be copied with the employee rows empty

      this.get('employeeRows').forEach(function (row, index) {
        if (index + 1 <= _this4.get('quarterData.employee_data.length')) {
          _this4.addEmployeeRow(_this4.get('quarterData.employee_data')[index].ss, {
            x: row.ssX,
            y: row.ssY
          }, pdfDoc, firstPage);

          _this4.addEmployeeRow(_this4.get('quarterData.employee_data')[index].name, {
            x: row.nameX,
            y: row.nameY
          }, pdfDoc, firstPage);

          _this4.addEmployeeRow(_this4.get('quarterData.employee_data')[index].license_number, {
            x: row.licenseX,
            y: row.licenseY
          }, pdfDoc, firstPage);

          _this4.addEmployeeRow(_this4.get('quarterData.employee_data')[index].total_weeks, {
            x: row.weeksX,
            y: row.weeksY
          }, pdfDoc, firstPage);
        }
      });
    },
    addEmployeeRow: function addEmployeeRow(val, coord, pdfDoc, page) {
      var contentStream = pdfDoc.createContentStream(PDFLib.drawText(this.get('helveticaFont').encodeText(val.toString()), {
        x: coord.x,
        y: coord.y,
        size: 8,
        font: 'Helvetica',
        colorRgb: [0, 0, 1]
      }));
      page.addContentStreams(pdfDoc.register(contentStream));
    },
    downloadPdf: function downloadPdf(data) {
      var blob = new Blob([data], {
        type: "application/pdf"
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob); // link.download = "myFileName.pdf";
      // link.click();

      window.open(link.href);
    },
    getPDF: Ember.observer('quarterData', function () {
      var _this5 = this;

      fetch('https://trimestrales.s3.amazonaws.com/Choferil.pdf').then(function (res) {
        var file = res.arrayBuffer();
        file.then(function (f) {
          var pdfDoc = PDFLib.PDFDocumentFactory.load(new Uint8Array(f));

          _this5.pdfProcess(pdfDoc);
        });
      });
    }),
    actions: {
      setYear: function setYear(year) {
        this.set('quarterYear', year);

        if (this.get('quarter')) {
          this.set('quarterEndingOn', "".concat(this.get('quarter'), "-").concat(this.get('quarterYear')));
        } // this.set('pdfCoordinates.type.y', y)

      },
      setQuarter: function setQuarter(quarter) {
        var y = 0;

        switch (quarter) {
          case "31-03":
            y = 672;
            this.set('periodoContributivo', "primero");
            break;

          case "30-06":
            this.set('periodoContributivo', "segundo");
            y = 654;
            break;

          case "30-09":
            this.set('periodoContributivo', "tercero");
            y = 636;
            break;

          case "31-12":
            this.set('periodoContributivo', "cuarto");
            y = 619;
            break;

          default:
            this.set('firstPagePdfCoordinates.type.value', "");
            break;
        }

        this.set('quarter', quarter);
        this.set('quarterEndingOn', "".concat(quarter, "-").concat(this.get('quarterYear')));
        this.set('trimestreY', y);
      },
      generate: function generate() {
        var _this6 = this;

        var data = JSON.stringify({
          quarter: {
            date: this.get('quarterEndingOn'),
            type: 'choferil'
          }
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/quarters"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            _this6.get('applicationController').set('showLoading', true);
          },
          errorCallback: function errorCallback(e) {
            _this6.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(e.payload.errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      }
    }
  });

  _exports.default = _default;
});