define("account-berry-frontend/companies/show/vendors/purchase-orders/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nFFaPR5R",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"purchase-order-form\",null,[[\"purchaseOrder\",\"store\",\"companyId\",\"reloadRoute\"],[[20,[\"model\"]],[20,[\"store\"]],[20,[\"companyId\"]],\"reloadRoute\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/companies/show/vendors/purchase-orders/show/template.hbs"
    }
  });

  _exports.default = _default;
});