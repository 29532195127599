define("account-berry-frontend/components/ath-movil-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    init: function init() {
      this._super();

      var _this = this;

      var ATHM_Checkout = {
        env: 'production',
        //   env: 'sandbox',
        publicToken: this.get('invoicePayment.public_token'),
        //   publicToken: 'sandboxtoken01875617264',
        timeout: 600,
        theme: 'btn',
        lang: 'en',
        total: this.get('invoicePayment.amount'),
        tax: this.get('invoicePayment.tax'),
        subtotal: this.get('invoicePayment.amount') - (this.get('invoicePayment.tax') || 0),
        metadata1: this.get('invoicePayment.invoice_id'),
        // metadata2: 'metadata2 test',
        onCompletedPayment: function onCompletedPayment(data) {
          _this.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success: </strong>&nbsp<span>Invoice Paid!</span></div>'
          });

          setTimeout(function () {
            location.reload();
          }, 1000);
        },
        onCancelledPayment: function onCancelledPayment(data) {},
        onExpiredPayment: function onExpiredPayment(data) {} // items: [
        //     {
        //         "name":"First Item",
        //         "description":"This is a description.",
        //         "quantity":"1",
        //         "price":"1.00",
        //         "tax":"1.00",
        //         "metadata":"metadata test"
        //     },
        //     {
        //         "name":"Second Item",
        //         "description":"This is another description.",
        //         "quantity":"1",
        //         "price":"1.00",
        //         "tax":"1.00",
        //         "metadata":"metadata test"
        //     }
        // ]

      };

      jQuery['loadScript'] = function (_0xa0aex1, _0xa0aex2) {
        jQuery['ajax']({
          url: _0xa0aex1,
          dataType: 'script',
          success: _0xa0aex2,
          async: true
        });
      };

      var ATHMDomain = '';
      var ATHMResource = '';
      var errorMessage = '<b>ATH M\xF3vil ERROR: </b></br>';
      var ATHMApiPaymentResource = '';
      VerifyVariables();

      try {
        if (!ATHM_Checkout['env']) {
          throw '[env] field is not defined correctly. Please input one of the following values: [sandbox, production].';
        }
      } catch (err) {
        errorMessage += err + '</br>';
        $('#ATHMovil_Checkout_Button')['html']('<div style=\'position: absolute; color: #ff0000; font-size: 12px;\'>' + errorMessage + '</div>');
        console['log'](errorMessage);
      }

      ;

      if (ATHM_Checkout['env']) {
        switch (ATHM_Checkout['env']) {
          case 'sandbox':
            ATHMDomain = 'https://www.athmovil.com/api/';
            ATHMApiPaymentResource = 'sandboxconsultTransactionUIID';
            ATHMResource = 'v3/sandboxApiloginPost?lang=' + ATHM_Checkout['lang'].toString();
            break;

          case 'production':
            ATHMDomain = 'https://www.athmovil.com/api/';
            ATHMApiPaymentResource = 'consultTransactionUIID';
            ATHMResource = 'v3/apiloginPost?lang=' + ATHM_Checkout['lang'].toString();
            break;

          default:
            ATHMDomain = ATHM_Checkout['env'];
            ATHMApiPaymentResource = 'consultTransactionUIID';

            if (ATHM_Checkout['publicToken'] == 'sandboxtoken01875617264') {
              ATHMApiPaymentResource = 'sandboxconsultTransactionUIID';
              ATHMResource = 'v3/sandboxApiloginPost?lang=' + ATHM_Checkout['lang'].toString();
            } else {
              ATHMResource = 'v3/apiloginPost?lang=' + ATHM_Checkout['lang'].toString();
            }

        }
      }

      ;

      function VerifyVariables() {
        try {
          if (ATHM_Checkout['env'] == '' || ATHM_Checkout['env'] == null) {
            LogEmptyVariable('env');
          } else {
            var _0xa0aex8 = ATHM_Checkout['env'];

            if (_0xa0aex8 != 'sandbox' && _0xa0aex8 != 'production') {
              if (_0xa0aex8['indexOf']('http') < -1) {
                LogInvalidDataVariable('env');
              }
            }
          }

          ;

          if (ATHM_Checkout['publicToken'] == '' || ATHM_Checkout['publicToken'] == null) {
            LogEmptyVariable('publicToken');
          }

          ;

          if (ATHM_Checkout['theme'] == '' || ATHM_Checkout['theme'] == null) {
            LogEmptyVariable('theme');
          } else {
            if (ATHM_Checkout['theme'] != 'btn' && ATHM_Checkout['theme'] != 'btn-dark' && ATHM_Checkout['theme'] != 'btn-light') {
              LogInvalidDataVariable('theme');
            }
          }

          ;

          if (ATHM_Checkout['total'] == '' || ATHM_Checkout['total'] == null) {
            LogEmptyVariable('total');
          } else {
            if (isNaN(ATHM_Checkout['total'])) {
              LogInvalidTypeVariable('total');
            } else {
              var _0xa0aex9 = ATHM_Checkout['total'];
            }
          }

          ;

          if (!ATHM_Checkout['tax'] == '' || !ATHM_Checkout['tax'] == null) {
            if (isNaN(ATHM_Checkout['tax'])) {
              LogInvalidTypeVariable('tax');
            }
          }

          ;

          if (!ATHM_Checkout['subtotal'] == '' || !ATHM_Checkout['subtotal'] == null) {
            if (isNaN(ATHM_Checkout['subtotal'])) {
              LogInvalidTypeVariable('subtotal');
            }
          }

          ;

          if (ATHM_Checkout['lang'] == '' || ATHM_Checkout['lang'] == null) {
            LogEmptyVariable('lang');
          } else {
            if (ATHM_Checkout['lang'] != 'en' && ATHM_Checkout['lang'] != 'es') {
              LogInvalidDataVariable('lang');
            }
          }

          ;

          if (!ATHM_Checkout['timeout'] == '' || !ATHM_Checkout['timeout'] == null) {
            if (isNaN(ATHM_Checkout['timeout'])) {
              LogInvalidTypeVariable('timeout');
            } else {
              var _0xa0aexa = ATHM_Checkout['timeout'];

              if (_0xa0aexa > 600 || _0xa0aexa < 60) {
                LogInvalidTypeVariable('timeout');
              }
            }
          }
        } catch (err) {
          console['error'](err);
        }
      }

      function LogEmptyVariable(_0xa0aexc) {
        console['error'](_0xa0aexc + ' is required');
      }

      function LogInvalidTypeVariable(_0xa0aexe) {
        console['error'](_0xa0aexe + ' data type value is invalid');
      }

      function LogInvalidDataVariable(_0xa0aexe) {
        console['error'](_0xa0aexe + ' value is invalid');
      }

      function validateDecimal(_0xa0aex11) {
        var _0xa0aex12 = /^\d*(\.\d{1})?\d{0,100}$/;

        if (_0xa0aex12['test'](_0xa0aex11)) {
          return true;
        } else {
          return false;
        }
      }

      function validateNumber(_0xa0aex11) {
        var _0xa0aex12 = /^[0-9]+$/;

        if (_0xa0aex12['test'](_0xa0aex11)) {
          return true;
        } else {
          return false;
        }
      } // function validateItems() {
      //     var _0xa0aex15 = '';
      //     var _0xa0aex16 = 1;
      //     if (!ATHM_Checkout['items'] != null) {
      //         var _0xa0aex17 = ATHM_Checkout['items'];
      //         for (let i in _0xa0aex17) {
      //             if (!validateNumber(_0xa0aex17[i]['quantity'])) {
      //                 _0xa0aex15 += '[items.quantity[' + _0xa0aex16 + ']] field is invalid. \x0A'
      //             };
      //             if (!validateDecimal(_0xa0aex17[i]['price'])) {
      //                 _0xa0aex15 += '[items.price[' + _0xa0aex16 + ']] field is invalid. \x0A'
      //             };
      //             if (!validateDecimal(_0xa0aex17[i]['tax'])) {
      //                 _0xa0aex15 += '[items.tax[' + _0xa0aex16 + ']] field is invalid.'
      //             };
      //             _0xa0aex16++
      //         }
      //     };
      //     if (!ATHM_Checkout['total'] != null && !validateDecimal(String(ATHM_Checkout['total']))) {
      //         _0xa0aex15 += '[total] field is invalid. \x0A'
      //     };
      //     if (ATHM_Checkout['subtotal'] != null && !validateDecimal(String(ATHM_Checkout['subtotal']))) {
      //         _0xa0aex15 += '[subtotal] field is invalid. \x0A'
      //     };
      //     if (ATHM_Checkout['tax'] != null && !validateDecimal(String(ATHM_Checkout['tax']))) {
      //         _0xa0aex15 += '[tax] field is invalid. \x0A'
      //     };
      //     return _0xa0aex15
      // }


      $['loadScript'](ATHMDomain + 'js/athmovil.base.js', function () {
        try {
          if (!ATHM_Checkout['env']) {
            throw '[env] field is not defined correctly. Please input one of the following values: [sandbox, production].';
          }

          ;

          if (!ATHM_Checkout['theme']) {
            throw '[theme] field is not defined correctly. Please input one of the following values: [btn, btn-dark, btn-light].';
          }

          ;

          if (ATHM_Checkout['theme'] != 'btn' && ATHM_Checkout['theme'] != 'btn-dark' && ATHM_Checkout['theme'] != 'btn-light') {
            throw '[theme] field is not defined correctly. Please input one of the following values: [btn, btn-dark, btn-light].';
          }

          ;

          if (!ATHM_Checkout['publicToken']) {
            throw '[publicToken] field is not defined correctly.';
          }

          ;

          if (!ATHM_Checkout['lang']) {
            throw '[lang] field is not defined correctly. Please input one of the following values: [en, es].';
          }

          ;

          if (!ATHM_Checkout['total']) {
            throw '[total] field is not defined correctly.';
          }

          ; // var _0xa0aex18 = validateItems();
          // if (_0xa0aex18 != '') {
          //     throw _0xa0aex18
          // }
        } catch (err) {
          errorMessage += err + '</br>';
          $('#ATHMovil_Checkout_Button')['html']('<div style=\'position: absolute; color: #ff0000; font-size: 12px;\'>' + errorMessage + '</div>');
          console['log'](errorMessage);
          return;
        }

        ;
        var _0xa0aex19 = '';
        var _0xa0aex1a = '';

        var _0xa0aex1b;

        if (ATHM_Checkout['lang'] == 'es') {
          _0xa0aex1a = 'css/athm.js.button-es.css';
        } else {
          _0xa0aex1a = 'css/athm.js.button.css';
        }

        ;
        var _0xa0aex1c = '';

        switch (ATHM_Checkout['theme'].toString()) {
          case 'btn':
            _0xa0aex1c = 'ATHMovilbtn';
            break;

          case 'btn-dark':
            _0xa0aex1c = 'ATHMovilbtn-dark';
            break;

          case 'btn-light':
            _0xa0aex1c = 'ATHMovilbtn-light';
            break;

          default:
            break;
        }

        ;
        $('head')['append']($('<link rel=\'stylesheet\' href=\'' + ATHMDomain + _0xa0aex1a + '\' type=\'text/css\' media=\'screen\' />'));
        $('head')['append']($('<link rel=\'stylesheet\' href=\'' + ATHMDomain + 'css/athm.js.overlay.css' + '\' type=\'text/css\' media=\'screen\' />'));
        $('#ATHMovil_Checkout_Button')['addClass'](_0xa0aex1c);
        $('#ATHMovil_Checkout_Button')['click'](function (_0xa0aex1d) {
          _0xa0aex1d['preventDefault']();

          var _0xa0aex1e = $(this);

          var _0xa0aex1f = String(document['location']);

          var _0xa0aex20 = _0xa0aex1f['split']('://');

          var _0xa0aex21 = String(_0xa0aex20[0] + '://' + _0xa0aex20[1]['split']('/')[0]);

          _0xa0aex19 = guid();
          ATHM_Checkout['uiid'] = _0xa0aex19;

          _0xa0aex22(ATHMDomain + ATHMResource, 'post', 'ATHMovilAPIPayment', '', 'jsonObject', ATHM_Checkout, _0xa0aex21);
        });

        function _0xa0aex22(_0xa0aex23, _0xa0aex24, _0xa0aex25, _0xa0aex26, _0xa0aex27, _0xa0aex28, _0xa0aex29) {
          _0xa0aex40();

          var _0xa0aex2a = 650;
          var _0xa0aex2b = 650;

          var _0xa0aex2c = window['screenLeft'] != undefined ? window['screenLeft'] : screen['left'];

          var _0xa0aex2d = window['screenTop'] != undefined ? window['screenTop'] : screen['top'];

          var _0xa0aex2e = window['innerWidth'] ? window['innerWidth'] : document['documentElement']['clientWidth'] ? document['documentElement']['clientWidth'] : screen['width'];

          var _0xa0aex2f = window['innerHeight'] ? window['innerHeight'] : document['documentElement']['clientHeight'] ? document['documentElement']['clientHeight'] : screen['height'];

          var _0xa0aex30 = _0xa0aex2e / 2 - _0xa0aex2a / 2 + _0xa0aex2c;

          var _0xa0aex31 = _0xa0aex2f / 2 - _0xa0aex2b / 2 + _0xa0aex2d;

          _0xa0aex1b = window['open']('', _0xa0aex25, 'resizable=yes, scrollbars=yes, width=' + _0xa0aex2a + ', height=' + _0xa0aex2b + ', top=' + _0xa0aex31 + ', left=' + _0xa0aex30);

          _0xa0aex1b['focus']();

          _0xa0aex3a(_0xa0aex1b);

          var _0xa0aex32 = document['createElement']('form');

          _0xa0aex32['setAttribute']('action', _0xa0aex23);

          _0xa0aex32['style']['display'] = 'none';

          _0xa0aex32['setAttribute']('target', _0xa0aex25);

          _0xa0aex32['setAttribute']('method', _0xa0aex24);

          var _0xa0aex33 = document['createElement']('input');

          _0xa0aex33['setAttribute']('name', _0xa0aex27);

          _0xa0aex33['setAttribute']('value', JSON['stringify'](_0xa0aex28));

          _0xa0aex32['appendChild'](_0xa0aex33);

          var _0xa0aex34 = document['createElement']('input');

          _0xa0aex34['setAttribute']('name', 'trustedDomain');

          _0xa0aex34['setAttribute']('value', _0xa0aex29);

          _0xa0aex32['appendChild'](_0xa0aex34);

          document['body']['appendChild'](_0xa0aex32);

          _0xa0aex32['submit']();

          setTimeout(function () {
            _0xa0aex32['parentNode']['removeChild'](_0xa0aex32);
          }, 1000);
          return false;
        }

        function _0xa0aex35(_0xa0aex36) {
          var _0xa0aex37 = _0xa0aex36['data'];

          _0xa0aex38(_0xa0aex36['data']);
        }

        function _0xa0aex38(_0xa0aex37) {
          var _0xa0aex39 = _0xa0aex41(_0xa0aex37);

          if (_0xa0aex37['status'] == 'CANCEL') {
            ATHM_Checkout['onCancelledPayment'](_0xa0aex39);
          }

          ;

          if (_0xa0aex37['status'] == 'TIMEOUT') {
            ATHM_Checkout['onExpiredPayment'](_0xa0aex39);
          }

          ;

          if (_0xa0aex37['status'] == 'SUCCESS') {
            ATHM_Checkout['onCompletedPayment'](_0xa0aex39);
          }

          ;
          $('#ATHMreopen-modal')['hide']();
        }

        function _0xa0aex3a(_0xa0aex1b) {
          setTimeout(function _0xa0aex3b() {
            if (_0xa0aex1b['closed']) {
              _0xa0aex3c();
            } else {
              setTimeout(_0xa0aex3b, 250);
            }
          }, 250);
        }

        function _0xa0aex3c() {
          $['getScript'](ATHMDomain + 'js/athmovil.min.js', function (_0xa0aex39) {
            var _0xa0aex3d = ATHMProxyHeader + 'v3' + '/' + ATHMApiPaymentResource + '?';

            var _0xa0aex3e = {
              tax: ATHM_Checkout['tax'] ? Number(ATHM_Checkout['tax'])['toFixed'](2) : Number(0)['toFixed'](2),
              subtotal: ATHM_Checkout['subtotal'] ? Number(ATHM_Checkout['subtotal'])['toFixed'](2) : Number(0)['toFixed'](2),
              total: Number(ATHM_Checkout['total'])['toFixed'](2),
              metadata1: ATHM_Checkout['metadata1'],
              metadata2: ATHM_Checkout['metadata2'],
              items: ATHM_Checkout['items'] ? JSON['stringify'](ATHM_Checkout['items']) : '[]',
              timeout: ATHM_Checkout['timeout'],
              token: ATHM_Checkout['publicToken'],
              uiid: _0xa0aex19
            };
            utilAsyncRequestJson(_0xa0aex3d, 'GET', JSON['parse'](JSON['stringify'](_0xa0aex3e)))['then'](function (_0xa0aex37) {
              if (ATHM_Checkout['publicToken'] == 'sandboxtoken01875617264') {
                _0xa0aex37 = {
                  'data': {}
                };
                _0xa0aex37['data']['total'] = ATHM_Checkout['total'];
                _0xa0aex37['data']['tax'] = ATHM_Checkout['tax'] ? ATHM_Checkout['tax'] : 0;
                _0xa0aex37['data']['subtotal'] = ATHM_Checkout['subtotal'] ? ATHM_Checkout['subtotal'] : 0;
                _0xa0aex37['data']['netAmount'] = _0xa0aex37['data']['total'] - _0xa0aex37['data']['fee'];
                _0xa0aex37['data']['metadata1'] = ATHM_Checkout['metadata1'] ? ATHM_Checkout['metadata1'] : '';
                _0xa0aex37['data']['metadata2'] = ATHM_Checkout['metadata2'] ? ATHM_Checkout['metadata2'] : '';
                _0xa0aex37['data']['items'] = ATHM_Checkout['items'] ? JSON['stringify'](ATHM_Checkout['items']) : '[]';
              }

              ;

              _0xa0aex38(_0xa0aex37);
            }, function (_0xa0aex3f) {
              console['log']('error in processing your request', _0xa0aex3f);
            });
          });
        }

        $('body')['append']('<div id="ATHMreopen-modal"></div>');
        $('#ATHMreopen-modal')['append']('<div class="ATHMmodal-content">' + '<div><img id="overlay-logo" src="' + ATHMDomain + '/images/athm-overlay-logo.svg"/></div>' + '<p></p>' + '<button id="reopen-btn"></button>' + '<button id="close-btn"></button>' + '</div>');

        function _0xa0aex40() {
          $('#ATHMreopen-modal')['show']();

          if (ATHM_Checkout['lang'] == 'es') {
            $('.ATHMmodal-content p')['html']('Presiona el bot&oacute;n de abajo para reabrir el navegador con el proceso de pago de ATH M&oacute;vil.');
            $('#reopen-btn')['html']('REABRIR');
            $('#close-btn')['html']('CERRAR');
          } else {
            $('.ATHMmodal-content p')['html']('Click the button below to reopen the ATH M\xF3vil payment browser.');
            $('#reopen-btn')['html']('REOPEN');
            $('#close-btn')['html']('CLOSE');
          }
        }

        $('#reopen-btn')['click'](function () {
          _0xa0aex1b['focus']();
        });
        $('#close-btn')['click'](function () {
          _0xa0aex1b['blur']();

          _0xa0aex1b['close']();
        });
        $('#ATHMreopen-modal')['click'](function () {
          _0xa0aex1b['focus']();
        });

        function _0xa0aex41(_0xa0aex39) {
          var _ = _0xa0aex39['data'];
          delete _['completed'];
          delete _['transactionID'];
          delete _['pending'];
          delete _['orderType'];
          delete _['trustedDomain'];
          delete _['error'];
          delete _['errorMessage'];
          return _;
        }
      }); // {
      //     "mode": "full",
      //     "isActive": false
      // }
    }
  });

  _exports.default = _default;
});