define("account-berry-frontend/plaid-link/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _DS$Model$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _emberData.default.Model.extend((_DS$Model$extend = {
    public_token: _emberData.default.attr('string'),
    plaid_account_id: _emberData.default.attr('string'),
    company_id: _emberData.default.attr('string'),
    plaid_account_name: _emberData.default.attr('string'),
    plaid_account_type: _emberData.default.attr('string'),
    plaid_account_subtype: _emberData.default.attr('string'),
    institution_name: _emberData.default.attr('string'),
    account_id: _emberData.default.attr('number')
  }, _defineProperty(_DS$Model$extend, "company_id", _emberData.default.attr()), _defineProperty(_DS$Model$extend, "created_at", _emberData.default.attr('date')), _defineProperty(_DS$Model$extend, "account_name", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "subscription_active", _emberData.default.attr('boolean')), _defineProperty(_DS$Model$extend, "subscribe", _emberData.default.attr('boolean')), _defineProperty(_DS$Model$extend, "accounts", _emberData.default.attr()), _defineProperty(_DS$Model$extend, "plaid_link_transactions", _emberData.default.hasMany('plaid-link-transaction')), _DS$Model$extend));

  _exports.default = _default;
});