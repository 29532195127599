define("account-berry-frontend/companies/show/employees/payrolls/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ay2RHQPt",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"payroll-form\",null,[[\"companyId\",\"payroll\",\"store\",\"reloadRoute\",\"accountId\",\"reconciliationId\",\"toggleShowLoading\"],[[20,[\"companyId\"]],[20,[\"model\"]],[20,[\"store\"]],\"reloadRoute\",[20,[\"accountId\"]],[20,[\"reconciliationId\"]],\"toggleShowLoading\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/companies/show/employees/payrolls/show/template.hbs"
    }
  });

  _exports.default = _default;
});