define("account-berry-frontend/companies/show/reports/general-ledger/controller", ["exports", "account-berry-frontend/config/environment", "ember-cli-pagination/computed/paged-array"], function (_exports, _environment, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    cable: Ember.inject.service(),
    hideZeros: false,
    includeClosingEntries: true,
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    generateTable: function generateTable(print) {
      var _this2 = this;

      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.get('company.name'), "</h3><h3 style='text-align: center;'>General Ledger</h3>");
      table += "<h4 style='text-align: center;'>From ".concat(moment(this.get('fromDate')).format('MMMM D, YYYY'), " to ").concat(moment(this.get('toDate')).format('MMMM D, YYYY'), "</h4>");

      if (print) {
        table += "<table style='width:100%; font-size: 1vw'><thead><tr><th style='text-align: left;'>&emsp;TYPE</th>";
      } else {
        table += "<table id='generalLedgerTable' style='width:100%; font-size: 14px'><thead><tr><th style='text-align: left;'>TYPE</th>";
      }

      table += "<th style='text-align: left;'>DATE</th>";
      table += "<th style='text-align: left;'>NUM</th>";
      table += "<th style='text-align: left;'>NAME</th>";
      table += "<th style='text-align: left;'>MEMO</th>";
      table += "<th style='text-align: right;'>DEBIT</th>";
      table += "<th style='text-align: right;'>CREDIT</th>";
      table += "<th style='text-align: right'>BALANCE</th></tr></thead><tbody>";
      this.get('generalLedgerData.account_data').forEach(function (data) {
        if (_this2.get('hideZeros') && data.transactions.length !== 0 || !_this2.get('hideZeros')) {
          table += "<tr><td colspan='7'>".concat(data.account.number ? data.account.number : "", " ").concat(data.account.name, "</td>");

          if (!print) {
            table += "<td></td><td></td><td></td><td></td><td></td><td></td>";
          }

          table += "<td style='text-align: right;'>".concat(parseFloat(data.account.before_balance).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");

          if (data.account.is_title) {
            data.account.sub_accounts.forEach(function (subData) {
              if (print) {
                table += "<tr><td colspan='7'>&emsp;".concat(subData.account.number ? subData.account.number : "", " ").concat(subData.account.name, "</td>");
              } else {
                table += "<tr><td colspan='7'>".concat(subData.account.number ? subData.account.number : "", " ").concat(subData.account.name, "</td>");
              }

              if (!print) {
                table += "<td></td><td></td><td></td><td></td><td></td><td></td>";
              }

              table += "<td style='text-align: right;'>".concat(parseFloat(subData.account.before_balance).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
              subData.transactions.forEach(function (transaction) {
                if (print) {
                  table += "<tr><td>&emsp;&emsp;".concat(transaction.type, "</td>");
                } else {
                  table += "<tr><td>".concat(transaction.type, "</td>");
                }

                table += "<td>".concat(transaction.date, "</td>");
                table += "<td>".concat(transaction.number, "</td>");
                table += "<td>".concat(transaction.name ? transaction.name : "", "</td>");
                table += "<td>".concat(transaction.memo ? transaction.memo : "", "</td>");
                table += "<td style='text-align: right'>".concat(transaction.debit ? parseFloat(transaction.amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "", "</td>");
                table += "<td style='text-align: right'>".concat(transaction.credit ? parseFloat(transaction.amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "", "</td>");
                table += "<td style='text-align: right'>".concat(parseFloat(transaction.balance).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
              });

              if (print) {
                table += "<tr><td colspan='5'>&emsp;Total ".concat(subData.account.number ? subData.account.number : "", " ").concat(subData.account.name, "</td>");
              } else {
                table += "<tr><td colspan='5'>Total ".concat(subData.account.number ? subData.account.number : "", " ").concat(subData.account.name, "</td>");
              }

              if (!print) {
                table += "<td></td><td></td><td></td><td></td>";
              }

              table += "<td style='text-align: right; ".concat(subData.no_transactions ? "" : "border-top: 1px solid $light-grey;", "'>").concat(subData.account.total_account_debit ? parseFloat(subData.account.total_account_debit).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "", "</td>");
              table += "<td style='text-align: right; ".concat(subData.no_transactions ? "" : "border-top: 1px solid $light-grey;", "'>").concat(subData.account.total_account_credit ? parseFloat(subData.account.total_account_credit).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "", "</td>");
              table += "<td style='text-align: right; ".concat(subData.no_transactions ? "" : "border-top: 1px solid $light-grey;", "'>").concat(subData.account.total_account_balance ? parseFloat(subData.account.total_account_balance).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "", "</td></tr>");
            });
          }

          data.transactions.forEach(function (transaction) {
            if (print) {
              table += "<tr><td>&emsp;".concat(transaction.type, "</td>");
            } else {
              table += "<tr><td>".concat(transaction.type, "</td>");
            }

            table += "<td>".concat(transaction.date, "</td>");
            table += "<td>".concat(transaction.number, "</td>");
            table += "<td>".concat(transaction.name ? transaction.name : "", "</td>");
            table += "<td>".concat(transaction.memo ? transaction.memo : "", "</td>");
            table += "<td style='text-align: right'>".concat(transaction.debit ? parseFloat(transaction.amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "", "</td>");
            table += "<td style='text-align: right'>".concat(transaction.credit ? parseFloat(transaction.amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "", "</td>");
            table += "<td style='text-align: right'>".concat(parseFloat(transaction.balance).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
          });
          table += "<tr><td colspan='5'>Total ".concat(data.account.number ? data.account.number : "", " ").concat(data.account.name, "</td>");

          if (!print) {
            table += "<td></td><td></td><td></td><td></td>";
          }

          table += "<td style='text-align: right; ".concat(data.no_transactions ? "" : "border-top: 1px solid $light-grey;", "'>").concat(data.account.total_account_debit ? parseFloat(data.account.total_account_debit).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "", "</td>");
          table += "<td style='text-align: right; ".concat(data.no_transactions ? "" : "border-top: 1px solid $light-grey;", "'>").concat(data.account.total_account_credit ? parseFloat(data.account.total_account_credit).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "", "</td>");
          table += "<td style='text-align: right; ".concat(data.no_transactions ? "" : "border-top: 1px solid $light-grey;", "'>").concat(data.account.total_account_balance ? parseFloat(data.account.total_account_balance).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "", "</td></tr>");
        }
      });
      table += "<tr><td colspan='5'>TOTAL</td>";

      if (!print) {
        table += "<td></td><td></td><td></td><td></td>";
      }

      table += "<td style='text-align: right; border-top: 1px solid $light-grey;'>".concat(this.get('generalLedgerData.totals.total_debit') ? parseFloat(this.get('generalLedgerData.totals.total_debit')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "", "</td>");
      table += "<td style='text-align: right; border-top: 1px solid $light-grey;'>".concat(this.get('generalLedgerData.totals.total_credit') ? parseFloat(this.get('generalLedgerData.totals.total_credit')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "", "</td>");
      table += "<td style='text-align: right; border-top: 1px solid $light-grey;'>".concat(parseFloat(this.get('generalLedgerData.totals.total_balance')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      getGeneralLedger: function getGeneralLedger() {
        var _this3 = this;

        var data = JSON.stringify({
          report: {
            from_date: this.fromDate,
            to_date: this.toDate,
            include_closing_entries: this.includeClosingEntries
          },
          report_type: 'general_ledger'
        });

        var _this$get = this.get('session.data.authenticated'),
            email = _this$get.email,
            token = _this$get.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

        var _this = this;

        consumer.createSubscription({
          channel: "ReportsChannel",
          room: "GeneralLedger" + this.get('session.data.authenticated.id')
        }, {
          connected: function connected() {
            console.log('Connected to ReportsChannel-GeneralLedger');

            _this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(_this.companyId, "/reports"), {
              method: 'POST',
              contentType: 'application/json',
              data: data,
              headers: headers
            }).then(function () {
              _this.get('applicationController').set('showLoading', true);
            }).catch(function () {
              _this.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
              });
            });
          },
          received: function received(data) {
            if (data.error) {
              _this3.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
              });
            } else {
              _this3.set('generalLedgerData', data.report);

              consumer.disconnect();

              _this3.get('applicationController').set('showLoading', false);
            }
          },
          disconnected: function disconnected() {
            console.log("ReportsChannel-GeneralLedger disconnected");
          }
        });
      },
      showAccountTransactions: function showAccountTransactions(id) {
        var _this4 = this;

        this.store.findRecord('account', id).then(function (account) {
          _this4.set('account', account); // this.transactionsActionCable();


          _this4.set('accountTransactions', true);
        });
      },
      cancelAccountTransactions: function cancelAccountTransactions() {
        this.set('accountTransactions', false);
      },
      print: function print() {
        var table = this.generateTable(true);
        Ember.$('.print-container').empty();
        Ember.$('.print-container').append(table);
        Ember.$('.print-container').printThis({
          importCSS: false
        }); // let w = window.open();
        // w.document.write("<div id='print-container'></div>");
        // w.document.getElementById('print-container').innerHTML += table;
        // w.print();
        // w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        Ember.$('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        Ember.$('#generalLedgerTable').table2CSV({
          delivery: 'download',
          filename: 'GeneralLedgerTable.csv'
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        Ember.$('#generalLedgerTable').remove();
        Ember.$('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});