define("account-berry-frontend/landed-cost/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    date: _emberData.default.attr(),
    bill_id: _emberData.default.attr(),
    vendor_id: _emberData.default.attr(),
    company_id: _emberData.default.attr(),
    amount: _emberData.default.attr(),
    accounts: _emberData.default.attr(),
    landed_accounts: _emberData.default.attr(),
    received_items: _emberData.default.attr(),
    landed_received_items: _emberData.default.attr(),
    ref_number: _emberData.default.attr(),
    undeletable: _emberData.default.attr()
  });

  _exports.default = _default;
});