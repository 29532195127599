define("account-berry-frontend/bill/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    bill_date: _emberData.default.attr(),
    bill_due_date: _emberData.default.attr(),
    ref_number: _emberData.default.attr(),
    bill_terms: _emberData.default.attr(),
    amount: _emberData.default.attr('number'),
    memo: _emberData.default.attr(),
    bill_accounts: _emberData.default.attr(),
    vendor_id: _emberData.default.attr(),
    accounts: _emberData.default.attr(),
    uneditable: _emberData.default.attr(),
    vendor: _emberData.default.attr(),
    has_landed_cost: _emberData.default.attr(),
    undeletable: _emberData.default.attr(),
    labels: _emberData.default.attr(),
    label_ids: _emberData.default.attr()
  });

  _exports.default = _default;
});