define("account-berry-frontend/companies/show/customers/taxes/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.setProperties({
        'breadCrumb': 'Add Tax',
        'companyId': this.paramsFor('companies.show').company_id
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('companies.show.customers.taxes.new', {
        into: 'application'
      });
    }
  });

  _exports.default = _default;
});