define("account-berry-frontend/components/video-help/component", ["exports", "account-berry-frontend/utils/videos"], function (_exports, _videos) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedVideo: Ember.computed('currentPath', function () {
      var _this = this;

      var allVideos = (0, _videos.default)();
      var selectedVideo = (0, _videos.default)();

      for (var section in allVideos) {
        if (allVideos.hasOwnProperty(section)) {
          // Do things here
          allVideos[section].forEach(function (video) {
            var route = video.route;

            if (route && _this.get('currentPath').includes(route)) {
              selectedVideo = video;
              return selectedVideo;
            }
          });
        }
      }

      return selectedVideo;
    }),
    actions: {
      showModal: function showModal() {
        this.set('showVideo', true);
      },
      hideModal: function hideModal() {
        this.set('showVideo', false);
      }
    }
  });

  _exports.default = _default;
});