define("account-berry-frontend/signup/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    queryParams: ['email', 'token'],
    email: null,
    token: null,
    didInsertElement: function didInsertElement() {
      this.set('rootURL', _environment.default.rootURL);
    },
    actions: {
      submit: function submit() {
        var _this = this;

        var user = {
          first_name: this.get('first_name'),
          last_name: this.get('last_name'),
          email: this.get('email'),
          password: this.get('password'),
          password_confirmation: this.get('passwordConfirmation')
        };
        var invite_token = this.get('token');
        this.get('ajax').request("".concat(_environment.default.apiURL, "/users"), {
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            user: user,
            invite_token: invite_token
          })
        }).then(function () {
          _this.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>A confirmation email has been sent.</span></div>'
          });

          _this.get('session').authenticate('authenticator:devise', user.email, user.password).catch(function (reason) {
            _this.set('errorMessage', reason.error || reason);
          });

          _this.get('session').invalidate();
        });
      }
    }
  });

  _exports.default = _default;
});