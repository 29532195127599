define("account-berry-frontend/components/inline-transaction/component", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['inline-transaction'],
    refNumber: Ember.inject.service(),
    notify: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    // For triggering reset of dropdown components
    reset: false,
    init: function init() {
      this._super();

      this.set('transaction', {});
      this.setPayees();
      this.generateTransactionNumber();
    },
    isDepositTransaction: Ember.computed('transactionType', function () {
      return this.transactionType === 'deposit';
    }),
    generateTransactionNumber: function generateTransactionNumber() {
      var _this = this;

      var isDeposit = this.get('isDepositTransaction');
      var ajaxOptions = {
        url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/").concat(isDeposit ? "deposits" : "checks", "/number"),
        method: 'GET',
        contentType: 'application/json',
        successCallback: function successCallback(response) {
          var last_ref_number = isDeposit ? response.last_deposit_number : response.last_check_number;

          var transactionNumber = _this.get('refNumber').generate(last_ref_number || "");

          _this.set('transaction.number', transactionNumber);
        },
        errorCallback: function errorCallback(response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        }
      };
      this.get('ajaxCall').request(ajaxOptions);
    },
    setPayees: function setPayees(payee, type) {
      var _this2 = this;

      this.store.findAll('payee').then(function (payees) {
        _this2.set('payees', payees);
      });

      if (payee && type) {
        if (this.get('isDepositTransaction')) {
          this.set('transaction.payeeId', payee.id);
        } else {
          // let payee = this.store.findRecord(type, id);
          this.set('transaction.payeeName', payee.get('name'));
          this.set('transaction.payeeId', payee.get('id'));
          this.set('transaction.payeeType', type);
        }

        if (type.toLowerCase() === "vendor") {
          var vendor = this.store.peekRecord('vendor', payee.get('id'));

          if (vendor && vendor.get('account_id')) {
            this.set('transaction.accountId', vendor.get('account_id'));
          }
        }
      }
    },
    actions: {
      setTransactionAccount: function setTransactionAccount(accountId) {
        this.set('transaction.accountId', accountId);
      },
      setPayee: function setPayee(payee, type) {
        this.setPayees(payee, type);
        this.sendAction('reloadPayees');
      },
      setCashAccount: function setCashAccount(cashAccountId) {
        this.set('transaction.cashAccountId', cashAccountId);
      },
      setLabels: function setLabels(index, labels) {
        this.set('transaction.label_ids', labels ? labels.map(function (label) {
          return label.id;
        }) : []);
        this.set('transaction.labels', labels);
      },
      create: function create() {
        var _this3 = this;

        Ember.$('form').submit(false);
        var data = {};

        if (this.transactionType === "check") {
          data = {
            check_date: this.get('transaction.transaction_date'),
            check_number: this.get('transaction.number'),
            payee_id: this.get('transaction.payeeId'),
            payee_type: this.get('transaction.payeeType')
          };
        } else {
          data = {
            deposit_date: this.get('transaction.transaction_date'),
            deposit_number: this.get('transaction.number')
          };
        }

        data.payee_name = this.get('transaction.payeeName');
        data.customer_id = this.get('transaction.payeeId');
        data.amount = this.get('transaction.amount');
        data.accounts = [{
          amount: this.get('transaction.amount'),
          account_id: this.get('transaction.accountId'),
          label_ids: this.get('transaction.label_ids')
        }];
        data.memo = this.get('transaction.memo');
        data.cash_account_id = this.get('transaction.cashAccountId');
        data.payment_method = this.get('transaction.paymentMethod');
        data.company_id = this.get('companyId'); // if (this.transactionType === "check") {
        //   data = JSON.stringify({ check: data });
        // } else {
        //   data = JSON.stringify({ deposit: data });
        // }

        var account = this.store.peekRecord('account', data.accounts[0].account_id);

        if (this.transactionType === "check" && account.get('subject_to_vendor_withholding')) {
          this.set('showModal', true);
          Ember.$('form').unbind('submit');
        } else {
          Ember.$("#transactionNumber").focus();
          var transaction = this.store.createRecord(this.transactionType, data);
          transaction.save().then(function () {
            _this3.sendAction('addTransactionToList', transaction);

            _this3.set('transaction', {
              number: _this3.get('refNumber').generate(_this3.get('transaction.number') || "")
            });

            Ember.$('#transactionAccount option').prop('selected', function () {
              return this.defaultSelected;
            });
            Ember.$('#payeeInput option').prop('selected', function () {
              return this.defaultSelected;
            });
            Ember.$('#cashAccount option').prop('selected', function () {
              return this.defaultSelected;
            });

            _this3.toggleProperty('reset');

            Ember.$('form').unbind('submit');

            _this3.get('notify').success({
              html: "<i class=\"icon-checkmark lg\"></i> <div><strong>Success:</strong>&nbsp<span>".concat(_this3.transactionType, " Created</span></div>")
            });
          }).catch(function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            Ember.$('form').unbind('submit');

            _this3.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          });
        }
      },
      goToCheckForm: function goToCheckForm() {
        this.get('router').transitionTo('companies.show.checks.new', this.get('companyId'));
      },
      closeModal: function closeModal() {
        this.set('showModal', false);
      }
    }
  });

  _exports.default = _default;
});