define("account-berry-frontend/components/top-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c2CDoy0I",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"top-nav navbar navbar-default navbar-fixed-top\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"\"],[7],[0,\"\\n    \"],[2,\" Collapse button \"],[0,\"\\n    \"],[6,\"button\"],[9,\"id\",\"topNavToggleButton\"],[9,\"class\",\"navbar-toggle\"],[9,\"data-toggle\",\"collapse\"],[9,\"data-target\",\"#collapsable-top-nav\"],[9,\"aria-controls\",\"#collapsable-top-nav\"],[7],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"icon-bar\"],[7],[8],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"icon-bar\"],[7],[8],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"icon-bar\"],[7],[8],[0,\"\\n    \"],[8],[2,\" Closing Collapse button \"],[0,\"\\n    \"],[2,\" Website Logo \"],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"navbar-brand\"],[7],[0,\"\\n      \"],[6,\"img\"],[9,\"src\",\"assets/images/logos/berry.svg\"],[7],[8],[0,\"\\n    \"],[8],[2,\" Closing Website Logo \"],[0,\"\\n  \"],[8],[0,\"\\n  \"],[2,\" Navigation Bar \"],[0,\"\\n  \"],[6,\"div\"],[9,\"id\",\"collapsable-top-nav\"],[9,\"class\",\"nav-collapse collapse navbar-responsive-collapse\"],[9,\"aria-expanded\",\"true\"],[7],[0,\"\\n    \"],[2,\" Navigation Menu \"],[0,\"\\n\"],[0,\"    \"],[6,\"div\"],[9,\"class\",\"\"],[7],[0,\"\\n      \"],[1,[20,[\"session\",\"data\",\"authenticated\",\"name\"]],false],[0,\"\\n      \"],[6,\"button\"],[3,\"action\",[[19,0,[]],\"invalidateSession\"]],[7],[0,\"Logout\"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[2,\" Closing Navigation Bar class=\\\"nav-collapse collapse navbar-responsive-collapse\\\" \"],[0,\"\\n\"],[8],[2,\" Closing class=\\\"navbar navbar-inverse navbar-fixed-top\\\" \"],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/top-nav/template.hbs"
    }
  });

  _exports.default = _default;
});