define("account-berry-frontend/companies/show/reconciliations/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(param) {
      return this.store.findRecord('reconciliation', param.reconciliation_id);
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        'breadCrumb': "".concat(model.get('cash_account'), " (").concat(moment(model.get('date')).format("MM/DD/YYYY"), ")"),
        'companyId': this.paramsFor('companies.show').company_id
      });
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.reconciliations.show', {
        into: 'application',
        model: model
      });
    }
  });

  _exports.default = _default;
});