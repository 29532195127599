define("account-berry-frontend/components/select-taxes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E4l4k2gc",
    "block": "{\"symbols\":[\"tax\"],\"statements\":[[6,\"select\"],[10,\"id\",[25,\"concat\",[\"selectTaxes-\",[20,[\"index\"]]],null],null],[9,\"class\",\"btn no-background\"],[10,\"onchange\",[25,\"action\",[[19,0,[]],\"updateTaxes\"],[[\"value\"],[\"target.value\"]]],null],[7],[0,\"\\n  \"],[6,\"option\"],[10,\"id\",[25,\"concat\",[\"yOption-\",[20,[\"index\"]]],null],null],[9,\"value\",\"y\"],[10,\"selected\",[18,\"y\"],null],[7],[0,\"Y\"],[8],[0,\"\\n  \"],[6,\"option\"],[10,\"id\",[25,\"concat\",[\"nOption-\",[20,[\"index\"]]],null],null],[9,\"value\",\"n\"],[10,\"selected\",[18,\"n\"],null],[7],[0,\"N\"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"button\"],[9,\"class\",\"btn accountberry-btn\"],[3,\"action\",[[19,0,[]],\"openItemTaxesModal\"]],[7],[0,\"Customize\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"selectTaxes\"]]],null,{\"statements\":[[4,\"modal-dialog\",null,[[\"targetAttachment\"],[\"center\"]],{\"statements\":[[0,\"    \"],[6,\"h4\"],[7],[0,\"Select Applicable Taxes\"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"invoice-item-taxes\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"taxes\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[7],[0,\"\\n          \"],[1,[25,\"input\",null,[[\"type\",\"class\",\"checked\"],[\"checkbox\",\"form-control\",[19,1,[\"selected\"]]]]],false],[0,\" \\n          \"],[6,\"span\"],[7],[1,[19,1,[\"name\"]],false],[0,\" - \"],[1,[25,\"format-currency\",[[19,1,[\"percentage\"]],true],null],false],[0,\"%\"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[8],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"btn accountberry-btn\"],[3,\"action\",[[19,0,[]],\"setItemTaxes\"]],[7],[0,\"Ok\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/select-taxes/template.hbs"
    }
  });

  _exports.default = _default;
});