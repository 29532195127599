define("account-berry-frontend/components/payment-items/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E4gW+PA+",
    "block": "{\"symbols\":[\"paymentItem\"],\"statements\":[[4,\"each\",[[20,[\"paymentItems\"]]],null,{\"statements\":[[4,\"unless\",[[25,\"or\",[[25,\"and\",[[25,\"eq\",[[19,1,[\"amount_due\"]],0],null],[25,\"not\",[[25,\"eq\",[[19,1,[\"ref_number\"]],[20,[\"payment\",\"ref_number\"]]],null]],null]],null],[19,1,[\"estimate\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,\"option\"],[10,\"value\",[19,1,[\"id\"]],null],[10,\"selected\",[25,\"eq\",[[19,1,[\"ref_number\"]],[20,[\"payment\",\"ref_number\"]]],null],null],[7],[1,[19,1,[\"ref_number\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/payment-items/template.hbs"
    }
  });

  _exports.default = _default;
});