define("account-berry-frontend/components/label-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service('notify'),
    confirmDelete: false,
    editLabel: false,
    init: function init() {
      this._super();

      if (!this.label) {
        this.set('label', {
          company_id: this.get('companyId')
        });
      }
    },
    errorHandler: function errorHandler(response) {
      var errors = "An Unkown Error Occured.";

      if (response) {
        if (response.errors && response.errors[0].status !== "500") {
          errors = response.errors.map(function (error) {
            return error;
          });
        } else if (response.payload.errors) {
          errors = response.payload.errors.map(function (error) {
            return error;
          });
        }
      }

      this.get('notify').alert({
        html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
      });
    },
    actions: {
      setLabelColor: function setLabelColor(color) {
        this.set('label.color', color);
      },
      setLabelTextColor: function setLabelTextColor(color) {
        this.set('label.text_color', color);
      },
      createLabel: function createLabel() {
        var _this = this;

        var newLabel = this.store.createRecord('label', this.label);
        newLabel.save().then(function () {
          _this.sendAction('reloadRoute');

          _this.get('notify').success({
            html: "<i class=\"icon-checkmark lg\"></i> <div><strong>Success:</strong>&nbsp<span>Label Created</span></div>"
          });
        }, function (response) {
          newLabel.rollbackAttributes();

          _this.errorHandler(response);
        });
        this.setProperties({
          "label.name": null,
          "label.color": null
        });
      },
      updateLabel: function updateLabel() {
        var _this2 = this;

        this.store.findRecord('label', this.label.id).then(function (label) {
          label.setProperties(_this2.label);
          label.save().then(function () {
            _this2.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Label Updated</span></div>'
            });

            _this2.toggleProperty('editLabel');
          });
        }, function (response) {
          _this2.errorHandler(response);

          _this2.toggleProperty('editLabel');
        });
      },
      deleteLabel: function deleteLabel() {
        var _this3 = this;

        this.label.destroyRecord().then(function () {
          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Label Deleted</span></div>"
          });

          _this3.sendAction('reloadRoute');
        }).catch(function (response) {
          _this3.errorHandler(response);

          _this3.label.rollbackAttributes();
        });
        this.set('confirmDelete', false);
      },
      toggleUpdate: function toggleUpdate(cancel) {
        this.toggleProperty('editLabel');

        if (cancel) {
          this.label.rollbackAttributes();
        }
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      }
    }
  });

  _exports.default = _default;
});