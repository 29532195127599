define("account-berry-frontend/companies/show/reports/employee-earnings-detail/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    cable: Ember.inject.service(),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    init: function init() {
      var _this2 = this;

      Ember.$.initialize('.employee-earnings-detail-table', function () {
        _this2.setTable();
      });
      this.store.findRecord('payroll-setting', 1).then(function (payrollSetting) {
        _this2.set('payrollSettings', payrollSetting);
      });
    },
    dataObserver: Ember.observer('employeeEarningsDetailData', function () {
      this.setTable();
    }),
    setTable: function setTable() {
      var printableTable = Ember.$('.employee-earnings-detail-table').clone(true);
      printableTable.css('font-size', '0.5vw');
      this.set('printableTable', printableTable);
      Ember.$('.employee-earnings-detail-table').tableHeadFixer({
        // fix table header
        head: true,
        // fix table footer
        foot: false,
        // fix x left columns
        left: 1,
        // fix x right columns
        right: 0,
        // z-index
        'z-index': 0
      });
    },
    actions: {
      getEmployeeEarningsDetail: function getEmployeeEarningsDetail() {
        var _this3 = this;

        var data = JSON.stringify({
          report: {
            from_date: this.fromDate,
            to_date: this.toDate
          },
          report_type: 'employee_earnings_detail'
        });

        var _this$get = this.get('session.data.authenticated'),
            email = _this$get.email,
            token = _this$get.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

        var _this = this;

        consumer.createSubscription({
          channel: "ReportsChannel",
          room: "EmployeeEarningsDetail" + this.get('session.data.authenticated.id')
        }, {
          connected: function connected() {
            console.log('Connected to ReportsChannel-EmployeeEarningsDetail');

            _this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(_this.companyId, "/reports"), {
              method: 'POST',
              contentType: 'application/json',
              data: data,
              headers: headers
            }).then(function () {
              _this.get('applicationController').set('showLoading', true);
            }).catch(function () {
              _this.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
              });
            });
          },
          received: function received(data) {
            if (data.error) {
              _this3.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
              });
            } else {
              _this3.set('employeeEarningsDetailData', data.report);

              consumer.disconnect();

              _this3.get('applicationController').set('showLoading', false);
            }
          },
          disconnected: function disconnected() {
            console.log("ReportsChannel-EmployeeEarningsDetail disconnected");
          }
        });
      },
      print: function print() {
        this.printableTable.printThis({
          // pageTitle: `${this.get('company.name')} - Employee Earnings Detail`,
          header: "<h4 style='text-align: center;'>From ".concat(moment(this.get('fromDate')).format('MMMM D, YYYY'), " To ").concat(moment(this.get('toDate')).format('MMMM D, YYYY'), "</h4>")
        });
      },
      csvExport: function csvExport() {
        Ember.$('#earningsDetail table').table2CSV({
          delivery: 'download',
          filename: 'earnings_detail_table.csv'
        });
      } // print() {
      //   Ember.$('#uncollectedBills').printThis();
      // },
      // csvExport() {
      //   let table = "<table id='uncollectedBillsTable' style='width:100%; font-size: 14px'><thead>";
      //   table += "<tr><th></th><th>TYPE</th><th>DATE</th><th>NUMBER</th>";
      //   table +="<th>DUE DATE</th><th>AGING</th><th>OPEN BALANCE</th></thead>";
      //   table += "<tbody>";
      //   this.get('employeeEarningsDetailData.customers').forEach((customer) => {
      //     table += `<tr><td>${customer.name}</td><td></td><td></td><td></td><td></td><td></td></tr>`;
      //     customer.transactions.forEach((transaction) => {
      //       table += `<tr><td>${transaction.type}</td>`;
      //       table += `<td>${transaction.date}</td>`;
      //       table += `<td>${transaction.number}</td>`;
      //       table += `<td>${transaction.due_date ? transaction.due_date : ""}</td>`;
      //       table += `<td>${transaction.aging ? transaction.aging : ""}</td>`;
      //       table += `<td>${parseFloat(transaction.open_balance).toFixed(2)}</td></tr>`;
      //     });
      //     table += `<tr><td>Total ${customer.name}</td><td></td><td></td><td></td><td></td>`;
      //     table += `<td>${parseFloat(customer.total).toFixed(2)}</td></tr>`;
      //   });
      //   table += "<tr><td>Total</td><td></td><td></td><td></td><td></td>";
      //   table += `<td>${parseFloat(this.get('employeeEarningsDetailData.total')).toFixed(2)}</td></tr>`;
      //   table += "</tbody></table>";
      //   Ember.$('#csvExport').show();
      //   document.getElementById('csvExport').innerHTML += table;
      //   Ember.$('#uncollectedBillsTable').table2CSV({
      //     delivery: 'download',
      //     filename: 'UncollectedBillsTable'
      //   });
      //   // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;
      //   Ember.$('#uncollectedBillsTable').remove();
      //   Ember.$('#csvExport').hide();
      // }

    }
  });

  _exports.default = _default;
});