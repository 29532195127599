define("account-berry-frontend/customer/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    billing_address_line_one: attr('string'),
    billing_address_line_two: attr('string'),
    billing_city: attr('string'),
    billing_country: attr('string'),
    billing_state: attr('string'),
    billing_zipcode: attr('string'),
    company_id: attr(),
    company_ein: attr('string'),
    company_name: attr('string'),
    display_name: attr('string'),
    email: attr('string'),
    first_name: attr('string'),
    last_name: attr('string'),
    name: attr('string'),
    notes: attr('string'),
    phone: attr('string'),
    shipping_address_line_one: attr('string'),
    shipping_address_line_two: attr('string'),
    shipping_city: attr('string'),
    shipping_state: attr('string'),
    shipping_zipcode: attr('string'),
    shipping_country: attr('string'),
    website: attr('string'),
    merchant_id: attr('string'),
    ordered_items: attr(),
    transactions: attr(),
    undeletable: attr()
  });

  _exports.default = _default;
});