define("account-berry-frontend/application/controller", ["exports", "account-berry-frontend/config/environment", "logrocket"], function (_exports, _environment, _logrocket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    browserObserver: Ember.inject.service(),
    processLoading: Ember.inject.service('process-loading'),
    breadCrumb: "Central",
    breadCrumbPath: "companies",
    hasPermission: Ember.computed(function () {
      return true;
    }),
    queryParams: ['signup'],
    signup: null,
    showLogin: Ember.computed('signup', function () {
      return this.get('signup') !== "true";
    }),
    mobileCheck: function mobileCheck() {
      var check = false;

      (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);

      return check;
    },
    init: function init() {
      this._super(); // Make sure always on https


      if (window.location.href.match('http:') && _environment.default["environment"] !== "development") {
        window.location.href = window.location.href.replace('http', 'https');
      }

      this.set('onMobile', this.mobileCheck()); // Setup pusher service

      if (!this.get('session.pusher')) {
        var pusher = new Pusher(_environment.default.pusherKey, {
          cluster: 'us2',
          encrypted: true
        });
        this.set('session.pusher', pusher);
      } // if (!(!!window.chrome && !!window.chrome.webstore) && (navigator.userAgent.toLowerCase().includes("nt 5.1") || navigator.userAgent.toLowerCase().includes("nt 6.0"))) {
      //   this.set('noSupport', true);
      //   this.set('noSupportMessage', Ember.String.htmlSafe('It seems you are running on Windows XP, which is a deprecated Operating System. You may try <a href="https://www.google.com/chrome/" target="_blank">Chrome</a> browser for Windows XP, but we recomend you upgrade your operating system.'));
      //   console.log(navigator.appVersion);
      // }
      // Verify user browser


      if (!this.get('browserObserver').getBrowser().includes('Chrome') && !this.get('browserObserver').getBrowser().includes('Firefox') && !this.get('session.isAuthenticated')) {
        this.get('notify').alert({
          html: "<span>It looks like you are not using any of the recommended browsers to use this application. For the best experience, we recommend using one of our these browsers: <a href='https://www.google.com/chrome/' target='_blank'>Chrome</a> or <a href='https://www.mozilla.org/en-US/firefox/new/' target='_blank'>Firefox</a></span>",
          closeAfter: null
        });
      } // This is to make sure only one notification shows at a time


      setTimeout(function () {
        var emberNotifyObserver = new MutationObserver(function (mutationList) {
          if (mutationList[0].target.children.length > 1) {
            mutationList[0].target.children[0].remove();
          }
        });
        emberNotifyObserver.observe(document.getElementsByClassName('ember-notify-cn')[0], {
          childList: true
        });
      }, 500); // Initialize logrocket

      if (_environment.default['environment'] !== "development") {
        _logrocket.default.init('jzv7kt/accountberry');

        _logrocket.default.identify(this.get('session.data.authenticated.email'));
      }

      this.zendeskSetup();
    },
    zendeskSetup: function zendeskSetup() {
      zE('webWidget', 'hide');
      zE('webWidget:on', 'open', function () {
        $('.support-button').slideToggle();
        zE('webWidget', 'show');
      });
      zE('webWidget:on', 'close', function () {
        $('.support-button').slideToggle();
        zE('webWidget', 'hide');
      });
      window.zESettings = {
        webWidget: {
          contactForm: {
            subject: true
          }
        }
      };
      zE('webWidget', 'prefill', {
        name: {
          value: "".concat(this.get('session.data.authenticated.name') || "")
        },
        email: {
          value: this.get('session.data.authenticated.email')
        }
      });
      $('.wrapper-AtBcr').remove();
    },
    sessionDataAuthObserver: Ember.observer('session.data.authenticated.email', function () {
      if (_environment.default['environment'] !== "development") {
        _logrocket.default.identify(this.get('session.data.authenticated.email'));
      }
    }),
    devEnv: Ember.computed(function () {
      return _environment.default.environment === "development";
    }),
    storeModels: Ember.computed(function () {
      return ['item', 'user-role', 'journal-entry', 'account', 'reconciliation', 'closing-entry', 'return', 'purchase-order', 'receiving-and-bill', 'bill', 'vendor', 'account', 'payee', 'customer'];
    }),
    enabledPath: Ember.computed('currentPath', function () {
      switch (this.currentPath) {
        case 'signup':
          break;

        case 'confirmed':
          break;

        case 'password_reset':
          break;

        case 'invoice_payment':
          break;

        default:
          return false;
      }

      return true;
    }),
    currentLocation: Ember.computed('currentPath', function () {
      return this.currentPath;
    }),
    inDashboard: Ember.computed('currentPath', function () {
      var routeName = this.currentPath;
      return routeName.includes('companies.show.dashboard');
    }),
    inCompany: Ember.computed('currentPath', function () {
      var routeName = this.currentPath;
      return routeName.includes('companies.') && routeName !== 'companies.index' && routeName !== 'companies.edit';
    }),
    inSignup: Ember.computed(function () {
      var routeName = this.currentPath;

      if (routeName.includes('signup')) {
        return true;
      } else {
        return false;
      }
    }),
    pathObserver: Ember.observer('currentPath', function () {
      var routeName = this.currentPath;

      if (routeName === 'login' && this.get('session.isAuthenticated')) {
        this.transitionToRoute('/');
      }

      this.removeSignupQueryParam(); // In company verifier

      this.inCompanyProcedure(routeName); // Logic to reload company for updated menu button counts

      this.updatedMenuButtonCountsProcedure(routeName); // View Permission verifier

      this.hasPermissionProcedure(routeName);
    }),
    removeSignupQueryParam: function removeSignupQueryParam() {
      if (window.location.href.match('signup=') && this.get('session.isAuthenticated')) {
        if (window.location.href.match('signup=true')) {
          window.location.href = window.location.href.replace("?signup=true", '');
        } else {
          window.location.href = window.location.href.replace("?signup=false", '');
        }
      }
    },
    inCompanyProcedure: function inCompanyProcedure(routeName) {
      var notInCompany = ['companies.', 'companies.new', 'companies.index', 'companies.edit'];

      if (routeName.includes('companies.') && !notInCompany.includes(routeName)) {
        this.set('companyId', location.href.split("//")[1].split("/")[1]);
        this.set('inCompany', true);
      } else {
        this.set('inCompany', false);
      }
    },
    updatedMenuButtonCountsProcedure: function updatedMenuButtonCountsProcedure(routeName) {
      var companyReloadRoutes = ["companies.show.index", "companies.show.customers.index", "companies.show.vendors.index", "companies.show.employees.index"];

      if (companyReloadRoutes.includes(routeName)) {
        this.store.findRecord('company', this.target.currentURL.split("/")[1]);
      }
    },
    hasPermissionProcedure: function hasPermissionProcedure(routeName) {
      var companies = this.store.peekAll('company');
      var company = companies.findBy('id', this.companyId);

      if (company) {
        // let company = this.store.peekRecord('company', this.get('companyId'));
        if (company && this.inCompany) {
          var permissions = company.get('permissions');
          var locations = ['customers', 'vendors', 'employees', 'reports'];
          var companyLocations = ['users', 'items', 'physical-inventory', 'accounts', 'journal-entries', 'reconciliations', 'closing-entries', 'labels', 'recurrencies', 'batches'];
          var hasPermission = true;
          Object.keys(permissions).forEach(function (key) {
            if (hasPermission) {
              if (!permissions[key].read) {
                if (key === "company") {
                  hasPermission = !(routeName === "companies.show.index");
                  companyLocations.forEach(function (location) {
                    if (routeName.includes(location)) {
                      hasPermission = false;
                    }
                  });
                } else {
                  locations.forEach(function (location) {
                    if (routeName.includes(location) && key === location) {
                      hasPermission = false;
                    }
                  });
                }
              }
            }
          });
          this.set('hasPermission', hasPermission);
        }
      }
    },
    actions: {
      afterLogin: function afterLogin() {
        this.transitionToRoute('/');
      },
      toggleLogin: function toggleLogin() {
        this.toggleProperty('showLogin');
      },
      openWidget: function openWidget() {
        zE('webWidget', 'open');
      }
    }
  });

  _exports.default = _default;
});