define("account-berry-frontend/app", ["exports", "account-berry-frontend/resolver", "ember-load-initializers", "account-berry-frontend/config/environment"], function (_exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  Ember.onerror = function (error) {
    // debugger;
    Ember.Logger.error(error); // alert("Oops, looks like something went wrong! \nOur support team should be looking into this problem. \nIf you can't continue working due to this error please try refreshing the page.")
  };

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});