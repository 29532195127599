define("account-berry-frontend/companies/show/batches/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    applicationController: Ember.inject.controller('application'),
    journalEntryImportAnalysis: Ember.computed('model', function () {
      if (this.get('model.import_type') === 'Journal Entries' || this.get('model.import_type') === 'Banco Popular' || this.get('model.import_type') === 'First Bank') {
        return true;
      } else {
        return false;
      }
    }),
    importTypeName: Ember.computed('model.import_type', function () {
      if (this.get('model.import_type') === "Banco Popular") {
        return "Bank";
      } else {
        return this.get('model.import_type');
      }
    }),
    actions: {
      reloadRoute: function reloadRoute() {
        this.send('refreshModel');
      },
      toggleShowLoading: function toggleShowLoading(status, show) {
        if (status) {
          this.set('applicationController.showLoading', show);
        } else {
          this.toggleProperty('applicationController.showLoading');
        }
      },
      cancel: function cancel() {
        this.transitionToRoute('companies.show.batches');
      }
    }
  });

  _exports.default = _default;
});