define("account-berry-frontend/return/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    returned_date: _emberData.default.attr(),
    ref_number: _emberData.default.attr(),
    memo: _emberData.default.attr(),
    net_amount: _emberData.default.attr('number'),
    discount_amount: _emberData.default.attr('number'),
    freight_amount: _emberData.default.attr('number'),
    tax_amount: _emberData.default.attr('number'),
    total_amount: _emberData.default.attr('number'),
    vendor_id: _emberData.default.attr(),
    customer_id: _emberData.default.attr(),
    company_id: _emberData.default.attr(),
    receiving_and_bill_id: _emberData.default.attr(),
    invoice_id: _emberData.default.attr(),
    returned_items: _emberData.default.attr(),
    vendor: _emberData.default.attr(),
    customer: _emberData.default.attr(),
    receiving_and_bill_number: _emberData.default.attr(),
    invoice_number: _emberData.default.attr(),
    uneditable: _emberData.default.attr(),
    tax_account_id: _emberData.default.attr(),
    freight_account_id: _emberData.default.attr(),
    tax_ids: _emberData.default.attr(),
    labels: _emberData.default.attr(),
    label_ids: _emberData.default.attr()
  });

  _exports.default = _default;
});