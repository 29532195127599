define("account-berry-frontend/helpers/all-caps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.allCaps = allCaps;
  _exports.default = void 0;

  function allCaps(params
  /*, hash*/
  ) {
    return params;
  }

  var _default = Ember.Helper.helper(function (params) {
    return params[0] ? params[0].toUpperCase() : "";
  });

  _exports.default = _default;
});