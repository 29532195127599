define("account-berry-frontend/helpers/subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.subtract = subtract;
  _exports.default = void 0;

  function subtract(params
  /*, hash*/
  ) {
    return params;
  }

  var _default = Ember.Helper.helper(function (params) {
    var total;
    params.forEach(function (value) {
      value = Number(value);

      if (total) {
        total -= value;
      } else {
        total = value;
      }
    });
    return parseFloat(total.toFixed(2));
  });

  _exports.default = _default;
});