define("account-berry-frontend/components/purchase-return-form/component", ["exports", "ember-cli-pagination/computed/paged-array", "account-berry-frontend/config/environment"], function (_exports, _pagedArray, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    refNumber: Ember.inject.service(),
    notify: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    totalItemQuantity: 0,
    totalItemCosts: 0,
    init: function init() {
      var _this2 = this;

      this._super();

      if (!this.return) {
        this.set('return', {
          net_amount: 0,
          total_amount: 0,
          tax_amount: 0,
          freight_amount: 0
        });
      } else {
        this.calculateTotalItemAmounts();
      }

      if (this.newReturn) {
        this.generateRefNumber();
      }

      this.store.findAll('account').then(function (accounts) {
        _this2.set('accounts', accounts.filter(function (account) {
          if (account.get('account_type.name') === "Cost Of Goods Sold" || account.get('account_type.name') === "Expense" || account.get('account_type.name') === "Other Expense") {
            return account;
          }
        }));

        if (_this2.get('return.tax_account_id') && _this2.get('return.freight_account_id')) {
          _this2.set('taxAccount', _this2.store.peekRecord('account', _this2.get('return.tax_account_id')));

          _this2.set('freightAccount', _this2.store.peekRecord('account', _this2.get('return.freight_account_id')));
        }
      });
      this.store.findAll('vendor').then(function (vendors) {
        _this2.set('vendor', vendors);
      });
      this.store.findAll('payee').then(function (payees) {
        _this2.set('payees', payees);
      });
      this.store.findAll('label').then(function (labels) {
        _this2.set('labels', labels.toArray());
      });
      this.store.findAll('receiving-and-bill').then(function (receivingAndBills) {
        _this2.set('receivingAndBills', receivingAndBills);

        if (_this2.get('return.vendor_id')) {
          _this2.setReceivingAndBills(_this2.get('return.vendor_id'));
        }
      });
    },
    errorHandler: function errorHandler(response) {
      var errors = "An Unkown Error Occured.";

      if (response.errors) {
        try {
          errors = response.errors.map(function (error) {
            return error;
          });
        } catch (error) {}
      }

      if (response.errors && response.errors[0].status !== "500") {
        errors = response.errors.map(function (error) {
          return error;
        });
      } else if (response.payload.errors) {
        errors = response.payload.errors.map(function (error) {
          return error;
        });
      }

      this.get('notify').alert({
        html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
      });
    },
    generateRefNumber: function generateRefNumber() {
      var _this3 = this;

      var ajaxOptions = {
        url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/returns/number"),
        method: 'GET',
        contentType: 'application/json',
        successCallback: function successCallback(response) {
          var refNumber = _this3.get('refNumber').generate(response.purchase_last_ref_number || "");

          _this3.set('return.ref_number', refNumber);
        },
        errorCallback: function errorCallback(response) {
          _this3.errorHandler(response);
        }
      };
      this.get('ajaxCall').request(ajaxOptions);
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['vendors'];
    }),
    setInputReceivedItems: function setInputReceivedItems() {
      var _this4 = this;

      var returnedItems = this.get('return.returned_items');
      var receivingAndBill = this.get('return.receiving_and_bill_id') ? this.store.peekRecord('receiving-and-bill', this.get('return.receiving_and_bill_id')) : null;
      var returnedItemsData = [];

      if (returnedItems && receivingAndBill) {
        returnedItems.forEach(function (returnedItem) {
          if (returnedItem.id) {
            var receivedItem = receivingAndBill.get('received_items').find(function (receivedItem) {
              return receivedItem.id === returnedItem.received_item_id;
            });

            var item = _this4.store.findRecord('item', returnedItem.item_id);

            returnedItemsData.push({
              received_item_id: receivedItem ? returnedItem.received_item_id : returnedItem.id,
              item_id: returnedItem.item_id,
              item: receivedItem ? receivedItem.ordered_item.item : item,
              po_number: receivedItem ? receivedItem.po_number : returnedItem.po_number,
              received_quantity: receivedItem ? receivedItem.item_quantity : returnedItem.item_quantity,
              returned_quantity: returnedItem.returned_quantity,
              cost: receivedItem ? receivedItem.cost : returnedItem.cost
            });
          }
        });
      }

      this.set('inputReceivedItems', returnedItemsData);
    },
    receivingAndBillsObserver: Ember.observer('receivingAndBills', function () {
      this.setInputReceivedItems();
    }),
    inputReceivedItems: [],
    pagedInputReceivedItems: (0, _pagedArray.default)('inputReceivedItems', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    inputReceivedItemsObserver: Ember.observer('inputReceivedItems', function () {
      try {
        this.set('pagedInputReceivedItems', (0, _pagedArray.default)('inputReceivedItems', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    updateReturnAmounts: function updateReturnAmounts() {
      var netAmount = 0;
      var freightAmount = this.get('return.freight_amount') ? Number(this.get('return.freight_amount')) : 0;
      var taxAmount = this.get('return.tax_amount') ? Number(this.get('return.tax_amount')) : 0;
      this.get('inputReceivedItems').forEach(function (receivedItem) {
        if (receivedItem.returned_quantity) {
          netAmount += Number(receivedItem.cost) * Number(receivedItem.returned_quantity);
        }
      });
      var totalAmount = netAmount + freightAmount + taxAmount;
      this.setProperties({
        'return.net_amount': Number(netAmount).toFixed(2),
        'return.total_amount': Number(totalAmount).toFixed(2)
      });
    },
    calculateTotalItemAmounts: function calculateTotalItemAmounts() {
      var inputReceivedItems = this.get('inputReceivedItems');
      var totalItemQuantity = 0;
      var totalItemCosts = 0;

      for (var i in inputReceivedItems) {
        // Set total item quantity
        totalItemQuantity += inputReceivedItems[i].returned_quantity ? Number(inputReceivedItems[i].returned_quantity) : 0; // Set total item unit costs

        if (inputReceivedItems[i].item) {
          totalItemCosts += inputReceivedItems[i].cost ? Number(inputReceivedItems[i].cost) : 0;
        }
      }

      this.setProperties({
        'totalItemQuantity': totalItemQuantity,
        'totalItemCosts': totalItemCosts.toFixed(2)
      });
    },
    inputOrderedItemObserver: Ember.observer('inputReceivedItems.@each.cost', 'inputReceivedItems.@each.returned_quantity', 'inputReceivedItems.@each', 'inputReceivedItems', function () {
      this.calculateTotalItemAmounts();
    }),
    vendorsObserver: Ember.observer('vendors', function () {
      if (this.get('return.vendor_id')) {
        this.set('vendor', this.store.peekRecord('vendor', this.get('return.vendor_id')));
        this.setVendorInfo(this.get('return.vendor_id'));
      }
    }),
    taxFreightAmountObserver: Ember.observer('return.freight_amount', 'return.tax_amount', function () {
      this.updateReturnAmounts();
    }),
    setVendorInfo: function setVendorInfo(vendorId) {
      var vendor = this.get('vendor') ? this.get('vendor') : this.store.peekRecord('vendor', vendorId);

      if (vendor) {
        var vendorInfo = "".concat(vendor.get('first_name') || "", " ").concat(vendor.get('last_name') || "");

        if (vendor.get('email')) {
          vendorInfo += "\n".concat(vendor.get('email'));
        }

        if (vendor.get('website')) {
          vendorInfo += "\n".concat(vendor.get('website'));
        }

        vendorInfo += "\n".concat(vendor.get('company_name'), "\n");

        if (vendor.get('address_line_one')) {
          vendorInfo += "".concat(vendor.get('address_line_one'), "\n");
        }

        if (vendor.get('address_line_two')) {
          vendorInfo += "".concat(vendor.get('address_line_two'), "\n");
        }

        if (vendor.get('city')) {
          vendorInfo += "".concat(vendor.get('city'));
        }

        if (vendor.get('state')) {
          vendorInfo += ", ".concat(vendor.get('state'));
        }

        if (vendor.get('country')) {
          vendorInfo += ", ".concat(vendor.get('country'));
        }

        if (vendor.get('zipcode')) {
          vendorInfo += ", ".concat(vendor.get('zipcode'));
        }

        this.set('vendorInfo', vendorInfo);
      }
    },
    setReceivingAndBills: function setReceivingAndBills(vendor_id) {
      var vendorReceivingAndBills = this.get('receivingAndBills').map(function (receivingAndBill) {
        if (receivingAndBill.get('vendor_id').toString() === vendor_id.toString()) {
          return receivingAndBill;
        }
      });
      this.set('vendorReceivingAndBills', vendorReceivingAndBills.filter(Boolean));
    },
    actions: {
      updateReturnedDate: function updateReturnedDate(date) {
        this.set('return.returned_date', date.toISOString().slice(0, 10));
      },
      updateVendor: function updateVendor(vendor) {
        this.set('vendor', this.store.peekRecord('vendor', vendor.id));
        this.set('return.vendor_id', vendor.id);
        this.setVendorInfo(vendor.id);
        this.setReceivingAndBills(vendor.id);
        this.setProperties({
          'return.receiving_and_bill_id': "",
          'return.returned_items': []
        });
      },
      updateReceivingAndBill: function updateReceivingAndBill(value) {
        if (!value) {
          value = Ember.$("#selectReceivingAndBill")[0].value;
        }

        var selectedReceivingAndBill = this.store.peekRecord('receiving-and-bill', value);
        this.set('return.receiving_and_bill_id', value);
        this.set('return.returned_items', selectedReceivingAndBill.get('received_items'));
        this.setInputReceivedItems();
      },
      updateItemExtendedCost: function updateItemExtendedCost(index) {
        var receivedItem = this.get('inputReceivedItems')[index];

        if (receivedItem.returned_quantity) {
          Ember.set(receivedItem, 'extendedCost', Number(receivedItem.returned_quantity * Number(receivedItem.cost)).toFixed(2));
        }

        var moreThanAvailable = Number(receivedItem.returned_quantity) > receivedItem.item.available;
        var moreThanReceived = Number(receivedItem.returned_quantity) > receivedItem.received_quantity;

        if (moreThanAvailable || moreThanReceived) {
          var message = "";

          if (moreThanAvailable) {
            message = "Return quantity cannot be greater than the available amount";
          } else if (moreThanReceived) {
            message = "Return quantity cannot be greater than received amount";
          }

          Ember.$("#itemQuantity-".concat(index)).tooltip({
            placement: "right",
            trigger: 'hover focus',
            title: message
          });
          Ember.$("#itemQuantity-".concat(index)).tooltip('show');
        } else {
          Ember.$("#itemQuantity-".concat(index)).tooltip('destroy');
        }

        this.updateReturnAmounts();
      },
      create: function create() {
        var _this5 = this;

        var returnedItems = [];
        this.get('inputReceivedItems').forEach(function (receivedItem) {
          if (receivedItem.returned_quantity && receivedItem.returned_quantity > 0) {
            returnedItems.push({
              item_id: receivedItem.item_id,
              returned_quantity: receivedItem.returned_quantity,
              received_item_id: receivedItem.received_item_id
            });
          }
        });
        this.setProperties({
          'return.returned_items': returnedItems,
          'return.company_id': this.get('companyId')
        });
        var data = JSON.stringify({
          return: this.get('return')
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/returns"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            _this5.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success: </strong>&nbsp<span>Return Created</span></div>'
            });

            _this5.sendAction('reloadRoute');

            _this5.get('router').transitionTo('companies.show.vendors.returns', _this5.companyId);
          },
          errorCallback: function errorCallback(response) {
            _this5.errorHandler(response);
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      update: function update() {
        var _this = this;

        var returnedItems = [];
        this.get('inputReceivedItems').forEach(function (receivedItem) {
          if (receivedItem.returned_quantity && receivedItem.returned_quantity > 0) {
            returnedItems.push({
              item_id: receivedItem.item_id,
              returned_quantity: receivedItem.returned_quantity,
              received_item_id: receivedItem.received_item_id
            });
          }
        });
        this.setProperties({
          'return.returned_items': returnedItems,
          'return.company_id': this.get('companyId')
        });
        this.store.findRecord('return', this.return.id, {
          backgroundReload: false
        }).then(function (returnRecord) {
          var _this6 = this;

          returnRecord.setProperties(_this.return);
          returnRecord.set('received_items', returnedItems);
          returnRecord.save().then(function () {
            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Return Updated</span></div>'
            });

            if (_this.accountId) {
              _this.get('router').transitionTo('companies.show.accounts.transactions', _this.companyId, _this.accountId);
            } else {
              _this.get('router').transitionTo('companies.show.vendors.returns', _this.companyId);
            }
          }, function (response) {
            _this6.errorHandler(response);
          });
        });
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      deleteReturn: function deleteReturn() {
        var _this7 = this;

        this.return.destroyRecord().then(function () {
          _this7.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Return Deleted</span></div>"
          });

          _this7.sendAction('reloadRoute');

          _this7.get('router').transitionTo('companies.show.vendors.returns', _this7.companyId);
        }).catch(function (response) {
          _this7.errorHandler(response);
        });
      },
      setTaxAccount: function setTaxAccount(id) {
        this.set('return.tax_account_id', id);
        this.set('taxAccount', this.store.peekRecord('account', id));
      },
      setLabels: function setLabels(index, labels) {
        this.set('return.label_ids', labels.map(function (label) {
          return label.id;
        }));
        this.set('return.labels', labels);
      },
      setFreightAccount: function setFreightAccount(id) {
        this.set('return.freight_account_id', id);
        this.set('freightAccount', this.store.peekRecord('account', id));
      },
      cancel: function cancel() {
        if (Ember.typeOf(this.get('return')) === "instance") {
          this.get('return').rollbackAttributes();
        }

        if (this.accountId) {
          this.get('router').transitionTo('companies.show.accounts.transactions', this.companyId, this.accountId);
        } else {
          this.get('router').transitionTo('companies.show.vendors.returns', this.companyId);
        }
      }
    }
  });

  _exports.default = _default;
});