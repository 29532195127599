define("account-berry-frontend/confirmed/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    didInsertElement: function didInsertElement() {
      this.set('rootURL', _environment.default.rootURL);
    }
  });

  _exports.default = _default;
});