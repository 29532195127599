define("account-berry-frontend/companies/show/checks/route", ["exports", "ember-cli-pagination/remote/paged-remote-array"], function (_exports, _pagedRemoteArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    reset: true,
    beforeModel: function beforeModel(transition) {
      this.set('reset', !transition.queryParams.show);
    },
    model: function model() {
      var companyId = this.paramsFor('companies.show').company_id;

      var checks = _pagedRemoteArray.default.create({
        modelName: 'check',
        store: this.store,
        page: 1,
        otherParams: {
          company_id: companyId,
          check: {
            company_id: companyId
          }
        }
      });

      return checks;
    },
    setupController: function setupController(controller) {
      var _this = this;

      var companyId = this.paramsFor('companies.show').company_id;
      controller.set('breadCrumb', 'Checks');
      controller.set('companyId', companyId);

      if (this.reset) {
        controller.set('fromDate', null);
        controller.set('toDate', null);
        controller.set('cashAccountId', null);
        controller.set('sortBy', null);
        controller.set('search', null);
        this.store.findAll('vendor').then(function (vendors) {
          controller.set('vendors', vendors);
        }); // this.store.findAll('employee').then((employees) => {
        //   controller.set('employees', employees);
        // });
        // this.store.findAll('customer').then((customers) => {
        //   controller.set('customers', customers);
        // });
        // this.store.findAll('payee', { reload: true }).then((payees) => {
        //   controller.set('payees', payees);
        // });

        this.store.findAll('label', {
          reload: true
        }).then(function (labels) {
          controller.set('labels', labels);
        });
        this.store.findRecord('company', companyId).then(function (company) {
          _this.set('company', company);
        });
        this.store.findAll('account', {
          reload: true
        }).then(function (accounts) {
          var cashAccounts = accounts.filter(function (account) {
            if (account.get('account_type.name').toLowerCase() === 'cash' && !account.get('is_title')) {
              return account;
            }
          });
          var filteredAccounts = accounts.filter(function (account) {
            return account.get('account_type.name') !== "Inventory";
          });
          controller.set('accounts', filteredAccounts);
          controller.set('cashAccounts', cashAccounts);
        });
      } else {
        controller.sortFilterChecks();
      }
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.checks', {
        into: 'application',
        model: model
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});