define("account-berry-frontend/process-loading/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var requestPropertyChange = function requestPropertyChange() {
        _this.notifyPropertyChange('startStopProperty');
      };

      Ember.$(document).ajaxStart(requestPropertyChange);
      Ember.$(document).ajaxStop(requestPropertyChange);
    },
    requestInProgress: Ember.computed('startStopProperty', function () {
      return Ember.$.active !== 0;
    })
  });

  _exports.default = _default;
});