define("account-berry-frontend/components/check-form/component", ["exports", "ember-cli-pagination/computed/paged-array", "account-berry-frontend/config/environment"], function (_exports, _pagedArray, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    refNumber: Ember.inject.service(),
    notify: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    numFormat: Ember.inject.service(),
    createPayee: false,
    checkAmount: Ember.computed.alias('check.amount'),
    init: function init() {
      var _this = this;

      this._super();

      if (!this.get('check')) {
        this.set('check', {
          amount: 0
        }); // this.verifyOriginalRetentionAmount();

        this.getAmountPaidInYear(); // this.set('originalRetentionAmount', 0);
      } else {
        this.set('check.payment_items', this.get('check.payments'));

        if (this.get('check.payee_type') === "vendor") {
          this.set('vendorId', this.get('check.payee_id'));
          this.store.findRecord('vendor', this.get('vendorId')).then(function (vendor) {
            _this.set('vendor', vendor); // this.verifyOriginalRetentionAmount();


            _this.getAmountPaidInYear();
          });
        }
      }

      if (this.newCheck) {
        this.generateCheckNumber();
      }

      this.setProperties({
        accountOpen: !this.get('paymentForm'),
        paymentOpen: !!this.get('paymentForm')
      });

      if (this.get('check.recurrency')) {
        this.set('check.recurrency.active', true);
      }
    },
    errorHandler: function errorHandler(response) {
      var errors = "An Unkown Error Occured.";

      if (response) {
        if (response.errors && response.errors[0].status !== "500") {
          errors = response.errors.map(function (error) {
            return error;
          });
        } else if (response && response.payload && response.payload.errors) {
          errors = response.payload.errors.map(function (error) {
            return error;
          });
        }
      }

      this.get('notify').alert({
        html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
      });
    },
    generateCheckNumber: function generateCheckNumber() {
      var _this2 = this;

      var ajaxOptions = {
        url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/checks/number"),
        method: 'GET',
        contentType: 'application/json',
        successCallback: function successCallback(response) {
          var checkNumber = _this2.get('refNumber').generate(response.last_check_number || "");

          _this2.set('check.check_number', checkNumber);
        },
        errorCallback: function errorCallback(response) {
          _this2.errorHandler(response);
        }
      };
      this.get('ajaxCall').request(ajaxOptions);
    },
    company: Ember.computed('companyId', function () {
      var company = this.store.peekRecord('company', this.companyId);
      this.set('informativePlan', company.get('stripe_plan_id') === _environment.default.stripe_plan_id.gold_plan);
      return company;
    }),
    companyPermission: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    paymentPermission: Ember.computed('company', function () {
      return this.get('company.permissions')['vendors'];
    }),
    createPermission: Ember.computed('company', function () {
      if (this.get('paymentForm')) {
        return this.get('company.permissions')['vendors'].write;
      } else {
        return this.get('company.permissions')['company'].write;
      }
    }),
    editPermission: Ember.computed('company', function () {
      if (this.get('paymentForm')) {
        return this.get('company.permissions')['vendors'].edit;
      } else {
        return this.get('company.permissions')['company'].edit;
      }
    }),
    deletePermission: Ember.computed('company', function () {
      if (this.get('paymentForm')) {
        return this.get('company.permissions')['vendors'].delete;
      } else {
        return this.get('company.permissions')['company'].delete;
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      this.store.findAll('account').then(function (accounts) {
        var filteredAccounts = accounts.filter(function (account) {
          return account.get('account_type.name') !== "Inventory";
        });

        _this3.set('accounts', filteredAccounts);
      });
      this.store.findAll('payment').then(function (payments) {
        _this3.set('payments', payments);
      });
      this.store.findAll('payee').then(function (payees) {
        _this3.set('payees', payees);
      });
      this.store.findAll('label').then(function (labels) {
        _this3.set('labels', labels);
      });

      if (this.get('check.cash_account')) {
        this.send('updateCashAccount', this.get('check.cash_account.id'));
      } // tooltip logic


      Ember.$('#checkTotal').tooltip({
        placement: "bottom",
        trigger: "hover",
        title: "Check amount is calculated from the forms below"
      });

      if (this.get('setCashAccountCleared')) {
        this.set('cashAccountCleared', true);
      }
    },
    accounts: [],
    cashAccounts: Ember.computed('accounts', function () {
      var cashAccounts = this.accounts.filter(function (account) {
        if (account.get('account_type.name').toLowerCase() === 'cash' && !account.get('is_title')) {
          return account;
        }
      });
      return cashAccounts;
    }),
    inputAccounts: Ember.computed('check.account_checks', function () {
      var _this4 = this;

      var accountChecks = this.get('check.account_checks');

      if (accountChecks) {
        accountChecks.map(function (ac) {
          if (ac.labels) {
            ac.labels.forEach(function (label) {
              label.selected = true;
            });
          }

          if (ac.account_id !== _this4.get('check.cash_account_id')) {
            Ember.set(ac, 'amount', ac.account_action === 'debit' ? parseFloat(ac.amount).toFixed(2) : parseFloat(ac.amount).toFixed(2) * -1);
          } else if (ac.clear) {
            _this4.set('setCashAccountCleared', true);
          }
        });
        return accountChecks;
      } else {
        return [{}];
      }
    }),
    inputPayments: Ember.computed('check.payment_items', function () {
      var paymentItems = this.get('check.payment_items');

      if (paymentItems) {
        paymentItems.map(function (paymentItem) {
          if (paymentItem.labels) {
            paymentItem.labels.forEach(function (label) {
              label.selected = true;
            });
          }

          Ember.set(paymentItem, 'payment_amount', parseFloat(paymentItem.payment_amount).toFixed(2));
          Ember.set(paymentItem, 'discount', parseFloat(paymentItem.discount).toFixed(2));
        });
        return paymentItems;
      } else {
        return [{}];
      }
    }),
    pagedInputAccounts: (0, _pagedArray.default)('inputAccounts', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    pagedInputPayments: (0, _pagedArray.default)('inputPayments', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    inputAccountsObserver: Ember.observer('inputAccounts', function () {
      try {
        this.set('pagedInputAccounts', (0, _pagedArray.default)('inputAccounts', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    vendorObserver: Ember.observer('vendor', 'check.check_date', function () {
      this.getAmountPaidInYear();
    }),
    getAmountPaidInYear: function getAmountPaidInYear() {
      var _this5 = this;

      var dateValid = false;
      var year;

      if (this.get('check.check_date') && this.get('check.check_date') !== "" && this.get('check.check_date').includes("-")) {
        year = this.get('check.check_date').split("-")[0];
        dateValid = Number(this.get('check.check_date').split("-")[0]) >= 2000;
      }

      if (this.get('vendor') && dateValid) {
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/vendors/").concat(this.get('vendor.id'), "/amount_paid_in_year"),
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            vendor: {
              year: year
            }
          }),
          successCallback: function successCallback(response) {
            _this5.set('amountPaidInYear', response.amount_paid_in_year);

            _this5.verifyOriginalRetentionAmount();
          },
          errorCallback: function errorCallback(response) {
            _this5.errorHandler(response);
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      }
    },
    verifyOriginalRetentionAmount: function verifyOriginalRetentionAmount() {
      var _this6 = this;

      if (this.get('informativePlan')) {
        var totalRetentionAmount = 0; // let retentionAmountIndex;

        if (this.get('vendor')) {
          this.get('inputAccounts').forEach(function (a, index) {
            if (a.account_id) {
              // let retentionAccountIndex = a.account_id === this.get('vendor.retention_account_id') ? index : null;
              var account = _this6.store.peekRecord('account', a.account_id);

              if (account.get('subject_to_vendor_withholding') && a.amount) {
                totalRetentionAmount += parseFloat(a.amount);
              }
            }
          });
          this.get('inputPayments').forEach(function (p, index) {
            if (p.payment_amount) {
              var payment_amount = parseFloat(p.payment_amount);
              var retention_percentage = parseFloat(p.retention_percentage);
              totalRetentionAmount += payment_amount * retention_percentage;
            }
          });
          var originalRetentionAmount = parseFloat(this.get('amountPaidInYear')) - totalRetentionAmount;
          originalRetentionAmount = originalRetentionAmount || 0;
          this.set('originalRetentionAmount', originalRetentionAmount < 0 ? 0 : originalRetentionAmount);
        }
      }
    },
    retentionAccountProcedure: function retentionAccountProcedure() {
      var _this7 = this;

      var totalRetentionAmount = 0;
      var retentionAccountIndex;

      if (this.get('vendor') && this.get('vendor.track_year_end_report') && this.get('vendor.retention_account_id')) {
        this.get('inputAccounts').forEach(function (a, index) {
          if (a.account_id) {
            retentionAccountIndex = a.account_id === _this7.get('vendor.retention_account_id') ? index : retentionAccountIndex;

            var account = _this7.store.peekRecord('account', a.account_id); // debugger;


            if (account.get('subject_to_vendor_withholding') && a.amount) {
              totalRetentionAmount += parseFloat(a.amount);
            }
          }
        });
        this.get('inputPayments').forEach(function (p, index) {
          if (p.payment_amount) {
            var payment_amount = parseFloat(p.payment_amount);
            var retention_percentage = parseFloat(p.retention_percentage);
            totalRetentionAmount += payment_amount * retention_percentage;
          }
        });

        if (totalRetentionAmount >= 0) {
          var vendorAmountPaidInYear = this.get('originalRetentionAmount') || 0;

          if (vendorAmountPaidInYear < this.get('vendor.retention_after_amount')) {
            totalRetentionAmount = totalRetentionAmount - (this.get('vendor.retention_after_amount') - vendorAmountPaidInYear);
          } else {
            totalRetentionAmount = totalRetentionAmount;
          }

          totalRetentionAmount = totalRetentionAmount > 0 ? totalRetentionAmount : 0;

          if (totalRetentionAmount > 0) {
            totalRetentionAmount = totalRetentionAmount * (this.get('vendor.retention_percentage') * 0.01) * -1;

            if (retentionAccountIndex && !this.get('inputAccounts')[retentionAccountIndex].removed) {
              var inputAccount = this.get('inputAccounts')[retentionAccountIndex];
              Ember.set(inputAccount, 'amount', this.get('numFormat').truncate(totalRetentionAmount).toFixed(2));
            } else {
              this.get('inputAccounts').pushObject({
                account_id: this.get('vendor.retention_account_id'),
                description: "".concat(this.get('vendor.retention_percentage'), "% Vendor Withholding"),
                amount: this.get('numFormat').truncate(totalRetentionAmount).toFixed(2)
              });
            } // this.set('pagedInputAccounts', pagedArray('inputAccounts', { infinite: "unpaged", page: 1, perPage: 100 }));

          } else {
            if (retentionAccountIndex) {
              this.send('removeAccountInput', retentionAccountIndex);
            }
          }
        }
      }
    },
    retentionAccountObserver: Ember.observer('inputAccounts', 'inputAccounts.@each.account_id', 'inputAccounts.@each.amount', 'inputPayments', 'inputPayments.@each.account_id', 'inputPayments.@each.payment_amount', function () {
      if (this.get('informativePlan')) {
        this.retentionAccountProcedure();
      }
    }),
    inputPaymentsObserver: Ember.observer('inputPayments', 'inputPayments.@each.amount_due', function () {
      try {
        this.set('pagedInputPayments', (0, _pagedArray.default)('inputPayments', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    payees: Ember.computed(function () {
      var _this8 = this;

      return this.store.peekAll('payee').toArray().filter(function (payee) {
        return payee.get('company_id').toString() === _this8.get('companyId').toString();
      });
    }),
    payments: Ember.computed(function () {
      var _this9 = this;

      return this.store.peekAll('payment').toArray().filter(function (payment) {
        return payment.get('company_id').toString() === _this9.get('companyId').toString();
      });
    }),
    formatCurrency: function formatCurrency(amount) {
      return parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    },
    generatePrintableCheck: function generatePrintableCheck(blankCheck) {
      var _this10 = this;

      var cashAccount = this.store.peekRecord('account', this.get('check.cash_account_id'));
      var printableCheck = "<style>\n      @font-face {\n        font-family: 'micr-encoding';\n        src: url(".concat(_environment.default.apiURL, "/micr-encoding.regular.ttf);\n        font-weight: normal;\n        font-style: normal;\n      }\n\n      // html, body {\n      //   height:100%;\n      //   zoom: 95%;\n      //   margin: 0 !important; \n      //   padding: 0 !important;\n      //   overflow: hidden;\n      // }\n\n      // .print-container {\n      //   margin-top: -0.4em;\n      //   height: 100%; \n      //   margin: 0 !important; \n      //   padding: 0 !important;\n      //   overflow: hidden;\n      // }\n    </style>");
      printableCheck += "<div style='font-family: Verdana; width: 100%; font-size: 12px; margin-top: 1em;'>"; // Check

      printableCheck += "<div style='width: 100%;'></div>";
      var company = this.store.peekRecord('company', this.get('companyId'));

      if (blankCheck) {
        printableCheck += "<div style=\"display: flex; justify-content: space-between; margin-bottom: -4em;\">";
        printableCheck += "<div style=\"display: flex; align-items: center; width: 30%;\">";

        if (company.get('picture.url')) {
          printableCheck += "<img style=\"width: 15%; margin-right: 1em;\" src=".concat(company.get('picture.url'), ">");
        }

        printableCheck += "\n        <div>\n          <b>".concat(company.get('name'), "</b>\n          <br>\n          ").concat(company.get('postal_address_line_one') || "", "\n          <br>\n          ").concat(company.get('postal_address_line_two') || "", "\n          <br>\n          ").concat(company.get('postal_address_city') || "", ",\n          ").concat(company.get('postal_address_state') || "", ",\n          ").concat(company.get('postal_address_zip') || "", "\n          <br>\n          ").concat(company.get('phone') || "", "\n        </div>\n      </div>");
        printableCheck += "<div style=\"text-align: center; width: 30%;\"><strong>".concat(cashAccount.get('bank_name') || "", " <br> ").concat(cashAccount.get('bank_address') || '', "</strong></div>");
        printableCheck += "<div style='text-align: right; width: 30%;'><strong>No.</strong> ".concat(this.get('check.check_number') ? this.get('check.check_number').replace(/\D/g, '') : "", "</div>");
        printableCheck += "</div>";
      }

      printableCheck += "<div style='display:flex; justify-content: flex-end; margin-bottom: ".concat(blankCheck ? "1em" : "3em", "; margin-top: ").concat(blankCheck ? "1.5em" : "3em", "; margin-right: 2em'><span>").concat(blankCheck ? "<strong>DATE:</strong> " : "").concat(moment(this.get('check.check_date')).format('MM/DD/YYYY'), "</span></div>");

      if (blankCheck) {
        printableCheck += "<div style='display:flex; justify-content: flex-end; margin-bottom: 1em; margin-right: 2em'><span>".concat(cashAccount.get('check_message') || "", "</span></div>");
      }

      printableCheck += "<div style='display: flex; justify-content: space-between; margin-bottom: 1.5em;'>";
      printableCheck += "<span style='margin-left: ".concat(blankCheck ? '' : '5em', ";'>").concat(blankCheck ? "<strong style='margin-right: 1em;'>Pay To The <br> Order Of:</strong> " : "").concat(this.get('check.payee_name'), "</span>");
      printableCheck += "<span style='margin-right: 2.5em;'>".concat(blankCheck ? "<strong>AMOUNT: $</strong> " : "", "**").concat(Ember.$('#checkAmount').val(), "*</span></div>");
      printableCheck += "<span>*".concat(Ember.$('.text-amount').text().trim().toUpperCase(), " ").concat(blankCheck ? "DOLLARS" : "", "</span>");
      printableCheck += "<div style='margin-top: ".concat(blankCheck ? '4em' : '7em', "; margin-left: 3em; display: flex; justify-content: space-between;'><span style=' max-width: 50%; white-space: nowrap; overflow: hidden; ").concat(blankCheck ? "border-bottom: 1px solid black;" : "", "'>").concat(this.get('check.memo') || "", "</span>");
      printableCheck += "".concat(blankCheck ? "<span style='width: 30%; border-bottom: 1px solid black'></span>" : "", "</div>");

      if (blankCheck) {
        // printableCheck += `<div style='margin-top: 1.5em; margin-left: -10em; text-align: center; font-family: micr-encoding; font-size: 24px;'>C${this.get('check.check_number') ? this.get('check.check_number').replace(/\D/g,'') : ""}C A${cashAccount.get('routing_number') || ''} A${cashAccount.get('account_number') || ''}C</div>`;
        printableCheck += "<div style='margin-top: 2em; text-align: center; font-family: micr-encoding; font-size: 24px;'>A".concat(cashAccount.get('routing_number') || '', "A ").concat(cashAccount.get('account_number') || '', "C ").concat(this.get('check.check_number') ? this.get('check.check_number').replace(/\D/g, '') : "", "</div>");
      } // Check Content


      printableCheck += "<div style='margin-top: ".concat(blankCheck ? '4em' : '9em', "; height: 62%;'>"); // Bottom/Stub Sections

      var section = "<h5 style='margin-top: 1.3em; text-align: center'>".concat(company.get('name'), "</h5>");
      section += "<h5>".concat(this.store.peekRecord(Ember.$('#payeeName').val().split(',')[1].toLowerCase(), Ember.$('#payeeName').val().split(',')[0]).get('name'), "</h5>");
      section += "<span style=\"font-size: 0.7vh; margin-left: 1em;\"><strong>No.</strong> #".concat(this.get('check.check_number') ? this.get('check.check_number').replace(/\D/g, '') : "", "</span>");
      section += "<br>";
      section += "<span style=\"font-size: 0.7vh; margin-left: 1em;\"><strong>Date:</strong> ".concat(moment(this.get('check.check_date')).format('MM/DD/YYYY'), "</span>");
      section += "<br>";
      section += "<div style='height: 50%; width: 96%; margin-left: 2em; display: flex;'>";

      if (this.get('inputAccounts').length > 1) {
        section += "<table style=\"font-size: 0.7vh; width: ".concat(this.get('inputPayments').length > 0 && this.get('inputPayments')[0].category ? '50%' : '100%', ";\">");
        section += "<thead><th style='text-align: left;'>Account</th><th style='text-align: left;'>Description</th><th style='text-align: right;'>Amount</th></thead>";
        section += "<tbody>";
        var totalAccountAmount = 0;
        this.get('inputAccounts').forEach(function (account) {
          if (!account.is_cash_account) {
            var accountRecord = _this10.store.peekRecord('account', account.account_id);

            section += "<tr>";
            section += "<td>".concat(accountRecord.get('name'), "</td>");
            section += "<td>".concat(account.description || "", "</td>");
            section += "<td style='text-align: right;'>".concat(parseFloat(account.amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
            totalAccountAmount += parseFloat(account.amount);
          }
        });
        section += "<tr><td></td><td></td><td style='text-align: right; border-top: 1px solid grey;'><strong>".concat(totalAccountAmount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</strong></td></tr>");
        section += "</tbody></table>".concat(this.get('inputPayments').length > 0 && this.get('inputPayments')[0].category ? '' : '</div>').concat(this.get('inputPayments').length > 0 ? "<div style='width: 1px; margin-right: 1em;'></div>" : "");
      }

      if (this.get('inputPayments').length > 0 && this.get('inputPayments')[0].category) {
        section += "<table style=\"font-size: 0.7vh; width: ".concat(this.get('inputAccounts').length > 1 ? '50%' : '100%', ";\">");
        section += "<thead><th style='text-align: left;'>Category</th><th style='text-align: left;'>Ref Number</th><th style='text-align: right;'>Payment</th></thead>";
        section += "<tbody>";
        var totalPaymentAmount = 0;
        this.get('inputPayments').forEach(function (payment) {
          section += "<tr>";
          section += "<td>".concat(payment.category, "</td>");
          section += "<td>".concat(payment.ref_number, "</td>");
          section += "<td style='text-align: right;'>".concat(parseFloat(payment.payment_amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
          totalPaymentAmount += parseFloat(payment.payment_amount);
        });
        section += "<tr><td></td><td></td><td style='text-align: right; border-top: 1px solid grey;'><strong>".concat(totalPaymentAmount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</strong></td></tr>");
        section += "</tbody></table></div>";
      }

      printableCheck += section;
      printableCheck += "<div style='position: absolute; width: 100%; top: 60em;'>";
      printableCheck += section;
      printableCheck += "</div></div></div>";
      return printableCheck;
    },
    voidProcedure: function voidProcedure() {
      this.get('inputAccounts').forEach(function (account) {
        Ember.set(account, 'amount', 0.00);
      });
      this.get('inputPayments').forEach(function (payment) {
        Ember.set(payment, 'discount', 0.00);
        Ember.set(payment, 'payment_amount', 0.00);
      });
      this.send('updateCheckAmount');
    },
    actions: {
      addAccount: function addAccount() {
        this.get('inputAccounts').pushObject({}); // this.set('pagedInputAccounts', pagedArray('inputAccounts', { infinite: "unpaged", page: 1, perPage: 100 }));
      },
      addPayment: function addPayment() {
        this.get('inputPayments').pushObject({}); // this.set('pagedInputPayments', pagedArray('inputPayments', { infinite: "unpaged", page: 1, perPage: 100 }));
      },
      removeAccountInput: function removeAccountInput(index) {
        if (this.get('inputAccounts')[index].id) {
          Ember.set(this.get('inputAccounts')[index], 'removed', true);
        } else {
          this.get('inputAccounts').removeAt(index);
        }

        this.send('updateCheckAmount'); // this.set('pagedInputAccounts', pagedArray('inputAccounts', { infinite: "unpaged", page: 1, perPage: 100 }));
      },
      removePaymentInput: function removePaymentInput(index) {
        this.get('inputPayments').removeAt(index);
        this.send('updateCheckAmount'); // this.set('pagedInputPayments', pagedArray('inputPayments', { infinite: "unpaged", page: 1, perPage: 100 }));
      },
      updatePayee: function updatePayee(payee, type) {
        var _this11 = this;

        if (type) {
          this.setProperties({
            'check.payee_id': payee.id,
            'check.payee_type': type
          });
          this.set('check.payee_name', payee.get('display_name') ? payee.get('display_name') : payee.get('name')); // If payee is a vendor, we need to set the vendorId for payment logic
          // And get vendor retention data if vendor is tracked and company is in gold plan or above

          if (type.toLowerCase() === "vendor") {
            this.set('vendorId', payee.get('id'));
            this.store.findRecord('vendor', payee.get('id')).then(function (vendor) {
              var company = _this11.store.peekRecord('company', _this11.get('companyId'));

              if (vendor.get('track_year_end_report') && company.get('stripe_plan_id') === _environment.default.stripe_plan_id.gold_plan) {
                _this11.set('vendor', vendor);
              } else {
                _this11.set('vendor', null);
              }

              if (vendor.get('account_id') && !_this11.paymentForm) {
                _this11.send('removeAccountInput', _this11.get('inputAccounts').length - 1);

                _this11.get('inputAccounts').pushObject({
                  account_id: vendor.get('account_id')
                });
              }
            });
          }

          this.setProperties({
            'inputPayments': [{}, {}],
            'check.payment_items': null
          });
        }
      },
      updateCashAccount: function updateCashAccount(value) {
        this.set('check.cash_account_id', value);
      },
      updateAccountList: function updateAccountList(id, index) {
        // let value = Ember.$(`#selectCheckAccount-${index}`)[0].value;
        Ember.set(this.get('inputAccounts')[index], 'account_id', id);
      },
      setPaymentCategory: function setPaymentCategory(payment, index) {
        var selectOptions = Ember.$("#selectPaymentItem-".concat(index, " option"));
        selectOptions[0].text = "Select Payment Item";
        selectOptions[0].value = "";
        selectOptions[0].selected = true;
        Ember.set(this.get('inputPayments')[index], 'id', null);
        Ember.set(this.get('inputPayments')[index], 'type', null);
        Ember.set(this.get('inputPayments')[index], 'amount_due', "");
        var value = Ember.$("#selectPaymentCategory-".concat(index))[0].value;
        Ember.set(payment, 'type', value);
      },
      updatePaymentList: function updatePaymentList(payment, index) {
        var value = Ember.$("#selectPaymentItem-".concat(index))[0].value;
        var id = value.slice(0, -1);
        payment = this.store.peekRecord('payment', value);
        Ember.set(this.get('inputPayments')[index], 'id', id);
        Ember.set(this.get('inputPayments')[index], 'type', payment.get('type'));
        Ember.set(this.get('inputPayments')[index], 'amount_due', payment.get('amount_due'));
        Ember.set(this.get('inputPayments')[index], 'retention_percentage', payment.get('retention_percentage')); // this.set('pagedInputPayments', pagedArray('inputPayments', { infinite: "unpaged", page: 1, perPage: 100 }));
      },
      updateCheckAmount: function updateCheckAmount() {
        var checkAmount = 0;
        this.get('inputAccounts').map(function (account) {
          if (!account.removed && !account.is_cash_account) {
            checkAmount += account.amount ? Number(account.amount) : 0;
          }
        });
        this.get('inputPayments').map(function (payment) {
          checkAmount += payment.payment_amount ? Number(payment.payment_amount) : 0; // checkAmount += payment.discount && payment.discount !== "NaN" ? Number(payment.discount) : 0;
        });

        if (checkAmount > 0) {
          this.set('check.void', false);
        }

        this.set('checkAmount', checkAmount.toFixed(2));
      },
      setRecurrency: function setRecurrency(recurrency) {
        if (recurrency.active) {
          this.set('checkRecurrency', recurrency);
        } else {
          this.set('checkRecurrency', null);
        }
      },
      formatAmount: function formatAmount(account, payment, discount) {
        if (account) {
          Ember.set(account, 'amount', Number(account.amount).toFixed(2));
        } else if (payment && !discount) {
          Ember.set(payment, 'payment_amount', Number(payment.payment_amount).toFixed(2));

          if (!payment.discount || payment.discount === "NaN") {
            Ember.set(payment, 'discount', Number("0").toFixed(2));
          }
        } else if (payment && discount) {
          Ember.set(payment, 'discount', Number(payment.discount).toFixed(2));
        }
      },
      updateCheckDate: function updateCheckDate(date) {
        this.set('check.check_date', date.toISOString().slice(0, 10));
      },
      create: function create() {
        var _this12 = this;

        this.retentionAccountProcedure();
        this.send('updateCheckAmount');
        var checkAccounts = this.get('inputAccounts').map(function (inputAccount) {
          if (inputAccount.account_id || inputAccount.account_id === 0) {
            inputAccount.label_ids = inputAccount.labels ? inputAccount.labels.map(function (label) {
              return label.id;
            }) : [];
            return inputAccount;
          }
        });
        var checkPayments = {};
        this.get('inputPayments').map(function (inputPayment) {
          if (inputPayment.id || inputPayment.id === 0) {
            var _data = {
              payment_amount: inputPayment.payment_amount,
              discount: inputPayment.discount,
              id: inputPayment.id,
              label_ids: inputPayment.labels ? inputPayment.labels.map(function (label) {
                return label.id;
              }) : []
            };

            if (checkPayments[inputPayment.type]) {
              checkPayments[inputPayment.type].push(_data);
            } else {
              checkPayments[inputPayment.type] = [_data];
            }
          }
        }); // Filter out unpopulated account rows

        this.set('check.accounts', checkAccounts.filter(Boolean));
        this.set('check.payments', checkPayments);
        this.set('check.recurrency', this.get('checkRecurrency'));
        var data = JSON.stringify({
          check: this.get('check')
        });
        var accountPaymentAmountSum = 0;
        this.get('inputAccounts').forEach(function (account) {
          accountPaymentAmountSum += account.amount ? parseFloat(Number(account.amount).toFixed(2)) : 0;
        });
        this.get('inputPayments').forEach(function (payment) {
          accountPaymentAmountSum += payment.payment_amount ? parseFloat(Number(payment.payment_amount).toFixed(2)) : 0; // accountPaymentAmountSum += payment.discount ? Number(payment.discount) : 0;
        });

        if (parseFloat(Number(accountPaymentAmountSum).toFixed(2)) !== parseFloat(Number(this.get('check.amount')).toFixed(2))) {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Account and payment amount sum must be equal to check amount</span></div>"
          });
          return;
        }

        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/checks/"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            _this12.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Check Created</span></div>'
            });

            if (_this12.accountId) {
              _this12.get('router').transitionTo('companies.show.accounts.transactions', _this12.companyId, _this12.accountId);
            } else if (_this12.paymentForm) {
              _this12.set('check', {
                amount: 0,
                cash_account_id: _this12.get('check.cash_account_id')
              });

              _this12.store.findAll('payment').then(function (payments) {
                _this12.set('payments', payments);
              });

              _this12.generateCheckNumber();

              _this12.set('inputAccounts', [{}, {}]);

              _this12.set('inputPayments', [{}, {}]);

              Ember.$('#payeeName').val('').trigger('change');

              _this12.set('vendorId', null);
            } else {
              _this12.sendAction('reloadRoute');

              _this12.get('router').transitionTo('companies.show.checks', _this12.companyId, {
                queryParams: {
                  show: true
                }
              });
            }
          },
          errorCallback: function errorCallback(response) {
            _this12.errorHandler(response);
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      update: function update() {
        var _this13 = this;

        this.retentionAccountProcedure();
        this.send('updateCheckAmount');
        var accountPaymentAmountSum = 0;
        var checkAccounts = this.get('inputAccounts').map(function (inputAccount) {
          if (inputAccount.account_id || inputAccount.account_id === 0) {
            inputAccount.label_ids = inputAccount.labels ? inputAccount.labels.map(function (label) {
              return label.id;
            }) : [];
            return inputAccount;
          }
        });
        var checkPayments = {};
        this.get('inputPayments').map(function (inputPayment) {
          if (inputPayment.id || inputPayment.id === 0) {
            var data = {
              payment_amount: inputPayment.payment_amount,
              discount: inputPayment.discount,
              id: inputPayment.id,
              label_ids: inputPayment.labels ? inputPayment.labels.map(function (label) {
                return label.id;
              }) : []
            };

            if (checkPayments[inputPayment.type]) {
              checkPayments[inputPayment.type].push(data);
            } else {
              checkPayments[inputPayment.type] = [data];
            }
          }
        });
        this.set('check.accounts', checkAccounts.filter(Boolean));
        this.set('check.payments', checkPayments);
        this.set('check.recurrency', this.get("checkRecurrency"));
        this.get('inputAccounts').forEach(function (account) {
          if (!account.is_cash_account && !account.removed) {
            accountPaymentAmountSum += account.amount ? parseFloat(Number(account.amount).toFixed(2)) : 0;
          }
        });
        this.get('inputPayments').forEach(function (payment) {
          accountPaymentAmountSum += payment.payment_amount ? parseFloat(Number(payment.payment_amount).toFixed(2)) : 0; // accountPaymentAmountSum += payment.discount ? Number(payment.discount) : 0;
        });

        if (parseFloat(Number(accountPaymentAmountSum).toFixed(2)) !== parseFloat(Number(this.get('check.amount')).toFixed(2))) {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Account and payment amount sum must be equal to check amount</span></div>"
          });
          return;
        }

        this.store.findRecord('check', this.check.id, {
          backgroundReload: false
        }).then(function (check) {
          check.setProperties(_this13.check);
          check.save().then(function () {
            _this13.sendAction('reloadRoute');

            _this13.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Check Updated</span></div>'
            });

            if (_this13.accountId) {
              _this13.get('router').transitionTo('companies.show.accounts.transactions', _this13.companyId, _this13.accountId);
            } else if (_this13.reconciliationId) {
              if (_this13.reconciliationId === "new") {
                _this13.get('router').transitionTo('companies.show.reconciliations.new', _this13.companyId);
              } else {
                _this13.get('router').transitionTo('companies.show.reconciliations.show', _this13.companyId, _this13.reconciliationId);
              }
            } else {// this.get('router').transitionTo('companies.show.checks', this.companyId, { queryParams: { show: true }});
            }
          }, function (response) {
            _this13.errorHandler(response);
          });
        });
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      deleteCheck: function deleteCheck() {
        var _this14 = this;

        this.check.destroyRecord().then(function () {
          _this14.get('notify').alert({
            html: '<i class="icon-warning lg"></i> <div class="message-text"><span>Check Deleted</span></div>'
          });

          _this14.sendAction('reloadRoute');

          _this14.get('router').transitionTo('companies.show.checks', _this14.companyId);
        }).catch(function (response) {
          _this14.errorHandler(response);
        });
      },
      loadNext: function loadNext() {
        this.get('pagedChecks').loadNextPage();
      },
      toggleAccountCollapse: function toggleAccountCollapse() {
        this.toggleProperty('accountOpen');
      },
      togglePaymentCollapse: function togglePaymentCollapse() {
        this.toggleProperty('paymentOpen');
      },
      cancel: function cancel() {
        if (Ember.typeOf(this.get('check')) === "instance") {
          this.get('check').rollbackAttributes();
        }

        if (this.accountId) {
          this.get('router').transitionTo('companies.show.accounts.transactions', this.companyId, this.accountId);
        } else if (this.reconciliationId) {
          if (this.reconciliationId === "new") {
            this.get('router').transitionTo('companies.show.reconciliations.new', this.companyId);
          } else {
            this.get('router').transitionTo('companies.show.reconciliations.show', this.companyId, this.reconciliationId);
          }
        } else if (this.paymentForm) {
          this.get('router').transitionTo('companies.show.vendors', this.companyId);
        } else {
          this.get('router').transitionTo('companies.show.checks', this.companyId);
        }
      },
      print: function print(blankCheck) {
        this.set('confirmPrint', false);
        var printableCheck = this.generatePrintableCheck(blankCheck);
        Ember.$('.print-container').empty();
        Ember.$('.print-container').append("<html><body>");
        Ember.$('.print-container').append(printableCheck);
        Ember.$('.print-container').append("</html></body>");
        Ember.$('.print-container').printThis({
          importCSS: false
        }); // let w = window.open();
        // w.document.write("<div class='print-container'></div>");
        // w.document.getElementsByClassName('print-container')[0].innerHTML += printableCheck;
        // let w = window.open();
        // w.document.write("<div id='print-container'></div>");
        // w.document.getElementById('print-container').innerHTML += printableCheck;
        // setTimeout(function() { 
        //   w.print();
        //   w.close();
        // }, 300);
      },
      toggleConfirmPrint: function toggleConfirmPrint() {
        this.set('confirmPrint', true);
      },
      setAccountLabels: function setAccountLabels(index, labels) {
        Ember.set(this.get('inputAccounts')[index], 'labels', labels);
      },
      setPaymentLabels: function setPaymentLabels(index, labels) {
        Ember.set(this.get('inputPayments')[index], 'labels', labels);
      },
      toggleConfirmVoid: function toggleConfirmVoid() {
        this.toggleProperty('confirmVoid');
      },
      voidCheck: function voidCheck() {
        this.set('check.void', true);
        this.voidProcedure();
        this.set('confirmVoid', false);
      }
    }
  });

  _exports.default = _default;
});