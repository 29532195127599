define("account-berry-frontend/sales-order/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    address: _emberData.default.attr(),
    so_date: _emberData.default.attr(),
    due_date: _emberData.default.attr(),
    so_number: _emberData.default.attr(),
    estimate: _emberData.default.attr('boolean'),
    company_id: _emberData.default.attr(),
    customer_id: _emberData.default.attr(),
    total_so_amount: _emberData.default.attr('number'),
    net_so_amount: _emberData.default.attr('number'),
    tax_amount: _emberData.default.attr('number'),
    freight_amount: _emberData.default.attr('number'),
    memo: _emberData.default.attr(),
    items: _emberData.default.attr(),
    customer: _emberData.default.attr(),
    sold_items: _emberData.default.attr(),
    ordered_items: _emberData.default.attr(),
    back_ordered_items: _emberData.default.attr(),
    closed: _emberData.default.attr(),
    undeletable: _emberData.default.attr(),
    customer_message: _emberData.default.attr(),
    labels: _emberData.default.attr(),
    label_ids: _emberData.default.attr()
  });

  _exports.default = _default;
});