define("account-berry-frontend/invoice-payment/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init: function init() {
      var _this = this;

      setTimeout(function () {
        _this.checkoutButtonProcedure();
      }, 1000);
    },
    template: Ember.computed('invoicePayment', function () {
      if (this.get('invoicePayment')) {
        var template = this.get('invoicePayment.template');
        return Ember.String.htmlSafe(template);
      }
    }),
    checkoutButtonProcedure: function checkoutButtonProcedure() {
      if (this.get('invoicePayment') && this.get('invoicePayment.session_id')) {
        var stripe = Stripe(_environment.default.stripe.publishableKey);
        var checkoutButton = document.getElementById('checkoutButton');
        var sessionId = this.get('invoicePayment.session_id');

        if (checkoutButton) {
          checkoutButton.addEventListener('click', function () {
            stripe.redirectToCheckout({
              // Make the id field from the Checkout Session creation API response
              // available to this file, so you can provide it as argument here
              // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
              sessionId: sessionId
            }).then(function (result) {// debugger;
              // If `redirectToCheckout` fails due to a browser or network
              // error, display the localized error message to your customer
              // using `result.error.message`.
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});