define("account-berry-frontend/payroll/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    pay_date: _emberData.default.attr(),
    begin_date: _emberData.default.attr(),
    end_date: _emberData.default.attr(),
    regular_hours: _emberData.default.attr('number'),
    overtime_hours: _emberData.default.attr('number'),
    earnings: _emberData.default.attr('number'),
    deductions: _emberData.default.attr('number'),
    reimbursements: _emberData.default.attr('number'),
    net_amount: _emberData.default.attr('number'),
    payroll_checks: _emberData.default.attr(),
    employees: _emberData.default.attr(),
    cash_account_id: _emberData.default.attr(),
    recurrency: _emberData.default.attr(),
    undeletable: _emberData.default.attr()
  });

  _exports.default = _default;
});