define("account-berry-frontend/utils/account-type-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = accountTypeGroups;

  function accountTypeGroups() {
    return {
      "Assets": {
        "debit": true,
        "credit": false
      },
      "Liabilities": {
        "debit": false,
        "credit": true
      },
      "Equity": {
        "debit": false,
        "credit": true
      },
      "Income": {
        "debit": false,
        "credit": true
      },
      "Expenses": {
        "debit": true,
        "credit": false
      },
      "Costs of goods sold": {
        "debit": true,
        "credit": false
      }
    };
  }
});