define("account-berry-frontend/companies/show/ath-movil/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    applicationController: Ember.inject.controller('application'),
    ajaxCall: Ember.inject.service(),
    cable: Ember.inject.service(),
    session: Ember.inject.service(),
    confirmDelete: false,
    tokenProcedure: false,
    modelObserver: Ember.observer('model', function () {
      if (!this.get('channelConnected')) {
        this.setChannel();
      }
    }),
    user: Ember.computed('session.data.authenticated.id', function () {
      return this.store.peekRecord('user', this.get('session.data.authenticated.id'));
    }),
    setChannel: function setChannel() {
      var _this2 = this;

      var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

      var _this = this;

      consumer.createSubscription({
        channel: "AthBusinessChannel",
        room: this.get('companyId')
      }, {
        connected: function connected() {
          console.log('Connected to AthBusinessChannel');

          _this.set('channelConnected', true);
        },
        received: function received(data) {
          var _this = _this2;

          if (data.error) {
            console.log("Error: " + data.error);

            _this2.get('applicationController').set('showLoading', false);

            _this2.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(data.error, "</span></div>")
            });

            _this2.set('confirmPayment', false); // this.set('paymentModal', true)

          } else {
            _this2.store.findAll('ath-movil', {
              reload: true
            }).then(function (model) {
              _this.set('model', model.get('firstObject'));
            });

            _this2.set('tokenProcedure', false);

            _this2.set('confirmPayment', false);

            _this2.get('applicationController').set('showLoading', false);

            _this2.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>ATH Business Synced!</span></div>'
            });
          }
        }
      });
    },
    errorHandler: function errorHandler(response) {
      var errors = "An Unkown Error Occured.";

      if (response) {
        if (response.errors && response.errors[0].status !== "500") {
          errors = response.errors.map(function (error) {
            return error;
          });
        } else if (response.payload.errors) {
          errors = response.payload.errors.map(function (error) {
            return error;
          });
        }
      }

      this.get('notify').alert({
        html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
      });
    },
    actions: {
      beginSync: function beginSync() {
        this.set('tokenProcedure', true);
      },
      confirm: function confirm() {
        if (this.get('model.public_token') && this.get('model.private_token')) {
          this.set('confirmPayment', true);
        } else {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>No Public or Private Token entered.</span></div>"
          });
        }
      },
      create: function create(_create) {
        var _this3 = this;

        if (_create) {
          if (this.get('session.data.authenticated.stripe_card_id') || this.get('user.card_last4_digits')) {
            this.set('model.company_id', this.get('companyId'));
            var data = JSON.stringify({
              athMovil: this.model
            });
            var ajaxOptions = {
              url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/ath_movils"),
              method: 'POST',
              contentType: 'application/json',
              data: data,
              successCallback: function successCallback(res) {
                // Wait for action cable response
                _this3.get('applicationController').set('showLoading', true); // this.get('notify').success({ html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>ATH Business Synced!</span></div>' });

              },
              errorCallback: function errorCallback(response) {
                _this3.errorHandler(response);
              }
            };
            this.get('ajaxCall').request(ajaxOptions);
          } else {
            this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>Please Add a Payment Method</span></div>"
            });
            this.set('paymentModal', true);
          }
        } else {
          this.set('confirmPayment', false);
        }
      },
      update: function update() {
        var _this4 = this;

        this.get('model').save().then(function () {
          _this4.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Accounts Updated</span></div>'
          });
        });
      },
      delete: function _delete() {
        var _this5 = this;

        this.get('model').destroyRecord().then(function () {
          _this5.set('confirmDelete', false);

          _this5.set('model', {
            setup: true
          });

          _this5.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>ATH Business Sync Deleted</span></div>'
          });
        });
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      togglePaymentModal: function togglePaymentModal() {
        this.toggleProperty('paymentModal');
      },
      reloadRoute: function reloadRoute() {
        this.sendAction('reloadRoute');
      },
      updateAccount: function updateAccount(value, attributeName) {
        this.set("model.".concat(attributeName), value);
      }
    }
  });

  _exports.default = _default;
});