define("account-berry-frontend/companies/show/labels/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g5Z457MR",
    "block": "{\"symbols\":[\"label\"],\"statements\":[[6,\"thead\"],[7],[0,\"\\n  \"],[6,\"tr\"],[9,\"class\",\"header-row\"],[7],[0,\"\\n    \"],[6,\"th\"],[9,\"class\",\"clickable text-center\"],[3,\"action\",[[19,0,[]],\"sortBy\",\"color\"]],[7],[0,\"LABEL COLOR\"],[8],[0,\"\\n    \"],[6,\"th\"],[9,\"class\",\"clickable text-center\"],[3,\"action\",[[19,0,[]],\"sortBy\",\"text_color\"]],[7],[0,\"TEXT COLOR\"],[8],[0,\"\\n    \"],[6,\"th\"],[9,\"class\",\"clickable\"],[3,\"action\",[[19,0,[]],\"sortBy\",\"name\"]],[7],[0,\"TEXT\"],[8],[0,\"\\n    \"],[6,\"th\"],[9,\"class\",\"text-center\"],[7],[0,\"PREVIEW\"],[8],[0,\"\\n    \"],[6,\"th\"],[9,\"class\",\"text-center\"],[7],[0,\"OPTIONS\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"table-container\"],[7],[0,\"\\n\"],[4,\"infinite-scroll\",null,[[\"action\"],[\"loadNext\"]],{\"statements\":[[0,\"      \"],[6,\"table\"],[9,\"class\",\"content-table table \"],[7],[0,\"\\n        \"],[6,\"tbody\"],[10,\"class\",[20,[\"session\",\"data\",\"authenticated\",\"theme\"]],null],[7],[0,\"\\n          \"],[1,[25,\"label-row\",null,[[\"createLabel\",\"store\",\"companyId\",\"reloadRoute\",\"userPermissions\"],[true,[20,[\"store\"]],[20,[\"companyId\"]],\"reloadRoute\",[20,[\"userPermissions\"]]]]],false],[0,\"\\n\"],[4,\"each\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"            \"],[1,[25,\"label-row\",null,[[\"label\",\"store\",\"userPermissions\",\"reloadRoute\"],[[19,1,[]],[20,[\"store\"]],[20,[\"userPermissions\"]],\"reloadRoute\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"],[6,\"tr\"],[7],[0,\"\\n  \"],[6,\"td\"],[9,\"colspan\",\"4\"],[7],[0,\"\\n\"],[0,\"  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/companies/show/labels/template.hbs"
    }
  });

  _exports.default = _default;
});