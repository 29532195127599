define("account-berry-frontend/companies/show/accept-ownership/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    ajaxCall: Ember.inject.service(),
    queryParams: ['newOwnerId', 'companyName', 'companyId'],
    newOwnerId: null,
    companyName: null,
    companyId: null,
    newOwnerIdObserver: Ember.observer('newOwnerId', function () {
      var _this = this;

      this.set('rootURL', _environment.default.rootURL);
      var companyId = this.get('company.id');

      if (this.get('session.data.authenticated.id').toString() === this.get('newOwnerId').toString()) {
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/transfer_ownerships/1/accept_ownership"),
          method: 'PUT',
          contentType: 'application/json',
          data: JSON.stringify({
            ownership_transfer: {
              new_owner_id: this.get('user.id'),
              current_owner_id: this.get('session.data.authenticated.id'),
              company_id: companyId
            }
          }),
          successCallback: function successCallback() {
            setTimeout(function () {
              window.location = "".concat(_environment.default.rootURL);
            }, 2000);
          },
          errorCallback: function errorCallback(response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload && response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });

            setTimeout(function () {
              window.location = "".concat(_environment.default.rootURL);
            }, 2000);
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      } else {
        this.get('router').transitionTo('/');
      }
    })
  });

  _exports.default = _default;
});