define("account-berry-frontend/companies/show/reports/reconciliation-report/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.findAll('reconciliation', {
        reload: true
      }).then(function (reconciliations) {
        return reconciliations.toArray();
      });
    },
    setupController: function setupController(controller) {
      controller.set('breadCrumb', 'Reconciliation Report');
      controller.set('companyId', this.paramsFor('companies.show').company_id);
      controller.setProperties({
        reconciliationData: null,
        reconciliation: null,
        clearedTransactions: null,
        unclearedTransactions: null
      });
      this.store.findAll('account').then(function (accounts) {
        controller.set('accounts', accounts);
      });
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.reports.reconciliation-report', {
        into: 'application',
        model: model
      });
    }
  });

  _exports.default = _default;
});