define("account-berry-frontend/payment/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    payment_id: _emberData.default.attr(),
    ref_number: _emberData.default.attr(),
    category: _emberData.default.attr(),
    estimate: _emberData.default.attr('boolean'),
    amount_due: _emberData.default.attr('number'),
    type: _emberData.default.attr(),
    vendor_id: _emberData.default.attr(),
    customer_id: _emberData.default.attr(),
    company_id: _emberData.default.attr(),
    retention_percentage: _emberData.default.attr('number')
  });

  _exports.default = _default;
});