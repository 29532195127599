define("account-berry-frontend/components/label-tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hy/xSYm6",
    "block": "{\"symbols\":[],\"statements\":[[6,\"span\"],[10,\"class\",[26,[\"label-tag \",[25,\"if\",[[20,[\"label\",\"id\"]],[20,[\"label\",\"id\"]],\"preview\"],null]]]],[7],[1,[20,[\"label\",\"name\"]],false],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/label-tag/template.hbs"
    }
  });

  _exports.default = _default;
});