define("account-berry-frontend/components/payment-method/component", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    stripev3: Ember.inject.service(),
    session: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    cable: Ember.inject.service(),
    init: function init() {
      this._super();

      this.setChannel();
    },
    creditCardInfo: Ember.computed('user', function () {
      if (this.get('user.card_brand')) {
        return Ember.String.htmlSafe("\n        <div class=\"flex-column\">\n          <span>Card: <strong>".concat(this.get('user.card_brand'), "</strong> </span> \n          <span>Last 4 Digits: <strong>").concat(this.get('user.card_last4_digits'), "</strong></span> \n          <span>Exp Date: <strong>").concat(this.get('user.card_exp_date'), "</strong></span>\n        </div>"));
      } else {
        return "No Payment Method Exists";
      }
    }),
    setChannel: function setChannel() {
      var _this2 = this;

      var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

      var _this = this;

      consumer.createSubscription({
        channel: "PaymentMethodChannel",
        room: this.get('session.data.authenticated.id')
      }, {
        connected: function connected() {
          console.log('Connected to PaymentMethodChannel');

          _this.set('channelConnected', true);
        },
        received: function received(data) {
          // debugger;
          if (data.error) {
            console.log("Error: " + data.error);

            _this2.get('applicationController').set('showLoading', false);

            _this2.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Unable to Update Payment Method. Please Try Again.</span></div>"
            }); // this.set('paymentModal', true)

          } else {
            _this2.set('session.data.authenticated.stripe_card_id', 1);

            _this2.get('store').findRecord('user', _this2.get('user.id')).then(function (user) {
              user.set('card_brand', data.card_brand);
              user.set('card_last4_digits', data.card_last4_digits);
              user.set('card_exp_date', data.card_exp_date);
            });

            _this2.get('applicationController').set('showLoading', false);

            _this2.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Payment Method Updated</span></div>'
            });

            _this2.sendAction('reloadRoute');

            _this2.sendAction('toggleNewPaymentMethod');

            consumer.disconnect();
          }
        }
      });
    },
    actions: {
      cancel: function cancel() {
        this.sendAction('toggleNewPaymentMethod');
      },
      submit: function submit(stripeElement) {
        var _this3 = this;

        var stripe = this.get('stripev3');
        this.set('user_id', this.get('session.data.authenticated.id'));
        stripe.createToken(stripeElement).then(function (response) {
          var token = response.token.id;
          var cardId = response.token.card ? response.token.card.id : null;

          if (token && cardId) {
            var ajaxOptions = {
              url: "".concat(_environment.default.apiURL, "/companies/1/users/").concat(_this3.get('session.data.authenticated.id'), "/payment_method"),
              method: 'PUT',
              contentType: 'application/json',
              data: JSON.stringify({
                payment_method_token: token,
                stripe_card_id: cardId
              }),
              successCallback: function successCallback(response) {
                _this3.get('applicationController').set('showLoading', true); // this.get('notify').success({ html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Payment Method Updated</span></div>' });
                // this.set('session.data.authenticated.stripe_card_id', 1)
                // this.get('store').findRecord('user', this.get('user.id')).then(function(user) {
                //   user.set('card_brand', response.card_brand);
                //   user.set('card_last4_digits', response.card_last4_digits);
                //   user.set('card_exp_date', response.card_exp_date);
                // });
                // this.sendAction('reloadRoute');
                // this.sendAction('toggleNewPaymentMethod');

              },
              errorCallback: function errorCallback(e) {
                _this3.get('notify').alert({
                  html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Unable to Update Payment Method. Please Try Again.</span></div>"
                });
              }
            };

            _this3.get('ajaxCall').request(ajaxOptions);
          }
        }).then().catch(function (e) {
          debugger;

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Unable to Update Payment Method. Please Try Again.</span></div>"
          });
        });
      }
    }
  });

  _exports.default = _default;
});