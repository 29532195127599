define("account-berry-frontend/payroll-internal-setting/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    date: _emberData.default.attr(),
    ss_ceiling: _emberData.default.attr('number'),
    ss_percentage: _emberData.default.attr('number'),
    medicare_percentage: _emberData.default.attr('number'),
    medicare_over_percentage: _emberData.default.attr('number'),
    medicare_over_amount_married_filing_jointly: _emberData.default.attr('number'),
    medicare_over_amount_married_filing_separate: _emberData.default.attr('number'),
    medicare_over_amount_single: _emberData.default.attr('number'),
    medicare_over_amount_head_of_household: _emberData.default.attr('number'),
    medicare_over_amount_qualifying_widower: _emberData.default.attr('number')
  });

  _exports.default = _default;
});