define("account-berry-frontend/companies/show/accept-ownership/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TZjTku/f",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"id\",\"confirmedContainer\"],[7],[0,\"\\n  \"],[6,\"img\"],[10,\"src\",[26,[[18,\"rootURL\"],\"assets/images/logos/logo-slogan.svg\"]]],[7],[8],[0,\"\\n  \"],[6,\"h1\"],[7],[0,\"You are now owner & creator of \"],[1,[18,\"companyName\"],false],[8],[0,\"\\n\"],[8],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/companies/show/accept-ownership/template.hbs"
    }
  });

  _exports.default = _default;
});