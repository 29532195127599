define("account-berry-frontend/companies/show/bank-sync/show/controller", ["exports", "account-berry-frontend/config/environment", "ember-cli-pagination/computed/paged-array"], function (_exports, _environment, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    allTransactionsSelected: false,
    sortAscending: false,
    confirmDelete: false,
    confirmImport: false,
    premiumModal: false,
    init: function init() {
      var _this = this;

      this._super();

      this.store.findAll('payee').then(function (payees) {
        _this.set('payees', payees);
      });
      this.store.findAll('customer').then(function (customers) {
        _this.set('customers', customers);
      });
      this.store.findAll('vendor').then(function (vendors) {
        _this.set('vendors', vendors);
      });
      this.store.findAll('employee').then(function (employees) {
        _this.set('employees', employees);
      });
    },
    user: Ember.computed('session.data.authenticated.id', function () {
      return this.store.peekRecord('user', this.get('session.data.authenticated.id'));
    }),
    pagedTransactions: (0, _pagedArray.default)("model.plaid_link_transactions", {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    modelObserver: Ember.observer('model.plaid_link_transactions.@each', function () {
      this.set('pagedTransactions', (0, _pagedArray.default)("model.plaid_link_transactions", {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    company: Ember.computed('companyId', function () {
      if (!this.subscribedToChannel && this.companyId) {
        this.subscribeToChannels();
      }

      return this.store.peekRecord('company', this.companyId);
    }),
    accounts: Ember.computed('companyId', function () {
      return this.store.peekAll('account');
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this2 = this;

      var channel = this.get('session.pusher').subscribe("synced-transactions-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        _this2.get('notify').success({
          html: "<i class=\"icon-checkmark lg\"></i> <div><strong>Success:</strong>&nbsp<span>".concat(data.imported_count, " of ").concat(data.total, " Transactions Successfully Imported</span></div>")
        });

        _this2.send('refreshModel');
      });
      channel.bind('synced', function (data) {
        _this2.get('notify').success({
          html: "<i class=\"icon-checkmark lg\"></i> <div><strong>Success:</strong>&nbsp<span>".concat(data.total, " Transactions have been synced</span></div>")
        });

        _this2.send('refreshModel');
      });
      channel.bind('monthly-limit', function (data) {
        _this2.get('notify').alert({
          html: "<i class=\"icon-warning lg\"></i> <div><span>You have reached the monthy limit. To sync more transactions, upgrade this account to Premium</span></div>"
        }); // this.send('refreshModel');

      });
      channel.bind('error', function (error) {
        _this2.get('notify').alert({
          html: "<i class=\"icon-warning lg\"></i> <div><span>".concat(error.message, "</span></div>")
        });
      });
      channel.bind('link_update', function (data) {
        _this2.set('token', data.token);

        _this2.set('linkUpdateModal', true);
      });
      this.set('subscribedToChannel', true);
    },
    checkTransactions: Ember.observer('allTransactionsSelected', function () {
      var _this3 = this;

      this.get('model.plaid_link_transactions').forEach(function (t) {
        Ember.set(t, 'checked', _this3.allTransactionsSelected);
      });
    }),
    anyTransactionSelected: Ember.computed('model.plaid_link_transactions.@each.checked', function () {
      return !!this.get('model.plaid_link_transactions').find(function (t) {
        return t.checked;
      });
    }),
    generateCSV: function generateCSV() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.get('model.institution_name'), " Transactions</h3><table id='bankSyncTransactionsTable' style='width:100%; font-size: 14px'>");
      table += "<th style='text-align: left;'>DATE</th>";
      table += "<th style='text-align: left;'>DESCRIPTION</th>";
      table += "<th style='text-align: left;'>AMOUNT</th></tr></thead>";
      table += "<tbody>";
      this.get('model.plaid_link_transactions').forEach(function (transaction) {
        table += "<tr>";
        table += "<td>".concat(transaction.get("date") || "", "</td>");
        table += "<td>".concat(transaction.get("name") || "", "</td>");
        table += "<td>".concat(transaction.get("amount") || "", "</td>");
        table += "</tr>";
      });
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      getTransactions: function getTransactions() {
        var _this4 = this;

        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/plaid_links/").concat(this.plaidLinkId, "/plaid_link_transactions"),
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            plaidLinkTransaction: {
              company_id: this.companyId,
              plaid_link_id: this.plaidLinkId,
              from_date: this.fromDate,
              to_date: this.toDate
            }
          }),
          successCallback: function successCallback() {
            _this4.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Getting Transactions...</span></div>'
            });
          },
          errorCallback: function errorCallback(response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload && response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this4.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      deleteSelected: function deleteSelected() {
        var _this5 = this;

        var deleteIds = [];
        this.get('model.plaid_link_transactions').forEach(function (t) {
          if (t.checked) {
            deleteIds.push(t.id);
          }
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/plaid_links/").concat(this.plaidLinkId, "/plaid_link_transactions/delete_multiple"),
          method: 'DELETE',
          contentType: 'application/json',
          data: JSON.stringify({
            plaidLinkTransaction: {
              ids: deleteIds
            }
          }),
          successCallback: function successCallback() {
            _this5.send('refreshModel');

            _this5.set('confirmDelete', false);

            _this5.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Transactions Deleted</span></div>'
            });
          },
          errorCallback: function errorCallback(response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload && response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this5.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      importSelected: function importSelected() {
        var _this6 = this;

        var importData = [];
        this.get('model.plaid_link_transactions').forEach(function (t) {
          if (t.checked) {
            importData.push({
              id: t.id,
              account_id: t.account_id,
              payee_id: t.payee_id,
              payee_type: t.payee_type,
              payee_name: t.payee_name,
              transaction_number: t.number,
              description: t.get('name'),
              date: t.get('date')
            });
          }
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/plaid_links/").concat(this.plaidLinkId, "/plaid_link_transactions/create_multiple"),
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            plaidLinkTransaction: {
              import_data: importData
            }
          }),
          successCallback: function successCallback() {
            _this6.set('confirmImport', false);

            _this6.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Transactions are being Imported</span></div>'
            });
          },
          errorCallback: function errorCallback(response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload && response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this6.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      setTransactionAccount: function setTransactionAccount(value, index) {
        var t = this.get('model.plaid_link_transactions').objectAt(index);
        Ember.set(t, 'account_id', value);
      },
      setPayee: function setPayee(payee, payeeType, transaction, index) {
        var t = this.get('model.plaid_link_transactions').objectAt(index);
        Ember.set(t, 'payee_id', payee.id);
        Ember.set(t, 'payee_name', payee.get('display_name'));
        Ember.set(t, 'payee_type', payeeType);
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      toggleConfirmImport: function toggleConfirmImport() {
        this.toggleProperty('confirmImport');
      },
      togglePremiumModal: function togglePremiumModal() {
        this.toggleProperty('premiumModal');
      },
      togglePaymentModal: function togglePaymentModal() {
        this.toggleProperty('paymentModal');
      },
      subscribeToPremium: function subscribeToPremium() {
        var _this7 = this;

        var plaidLink = this.get('model');
        plaidLink.set('subscribe', true);
        plaidLink.save().then(function () {
          _this7.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Account Subscribed to Premium Service!</span></div>'
          });

          _this7.setProperties({
            'premiumModal': false
          });

          plaidLink.set('subscription_active', true);
        }, function (response) {
          _this7.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(response.errors, "</span></div>")
          });

          _this7.set('paymentModal', true);
        });
      },
      reloadRoute: function reloadRoute() {
        this.send('refreshModel');
      },
      setEdit: function setEdit(transaction) {
        transaction.toggleProperty('edit', true);
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('model.plaid_link_transactions', this.get('model.plaid_link_transactions').sortBy(property));
        } else {
          this.set('model.plaid_link_transactions', this.get('model.plaid_link_transactions').sortBy(property).reverse());
        } // this.set('pagedRepresentatives', pagedArray('model', { infinite: "unpaged", page: 1, perPage: 100 }));

      },
      loadNext: function loadNext() {
        this.get('pagedTransactions').loadNextPage();
      },
      closeLinkUpdateModal: function closeLinkUpdateModal() {
        this.set('linkUpdateModal', false);
      },
      csvExport: function csvExport() {
        var table = this.generateCSV();
        $('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        $('#bankSyncTransactionsTable').table2CSV({
          delivery: 'download',
          filename: "bank_sync_transactions_".concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        $('#bankSyncTransactionsTable').remove();
        $('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});