define("account-berry-frontend/components/employee-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service('notify'),
    init: function init() {
      this._super();

      if (!this.get('employee')) {
        this.set('employee', {});
      }
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['employees'];
    }),
    filing_statuses: ["Married Filing Jointly", "Married Filing Separate", "Single", "Head of Household", "Qualifying Widow(er) with Dependent Child"],
    actions: {
      setEmployeeImage: function setEmployeeImage(file) {
        var reader = new FileReader();

        var _this = this;

        reader.onload = function (e) {
          _this.set('imagePreview', e.target.result);
        };

        reader.readAsDataURL(file);
        this.set('employee.picture', file);
      },
      create: function create() {
        var _this2 = this;

        this.set('employee.company_id', this.companyId);
        var newEmployee = this.store.createRecord('employee', this.employee);
        newEmployee.save().then(function () {
          _this2.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Employee Created</span></div>'
          });

          _this2.sendAction('reloadRoute');

          _this2.get('router').transitionTo('companies.show.employees.employees-table', _this2.companyId);
        }, function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          newEmployee.rollbackAttributes();

          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      update: function update() {
        var _this = this;

        this.set('employee.company_id', this.companyId);
        this.store.findRecord('employee', this.employee.id, {
          reload: true
        }).then(function (employee) {
          employee.setProperties(_this.employee);
          employee.save().then(function () {
            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Employee Updated</span></div>'
            });

            _this.sendAction('reloadRoute');

            _this.get('router').transitionTo('companies.show.employees.employees-table', _this.companyId);
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            employee.rollbackAttributes();

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          });
        });
      },
      toggleConfirmDelete: function toggleConfirmDelete(employee) {
        if (employee && employee.get('undeletable')) {
          this.set('deleteModalMessage', 'You cannot delete this employee because it has transactions.\nYou can still terminate the employee, if you do you will not be able to create new transactions for it.\nAre you sure you want to terminate this Employee?\n');
          this.set('deleteButton', "Terminate");
        } else {
          this.set('deleteModalMessage', "Are you sure you want to delete this Employee?");
          this.set('deleteButton', "Delete");
        }

        this.toggleProperty('confirmDelete');
      },
      updateBirthDate: function updateBirthDate(date) {
        this.set('employee.birth_date', date);
      },
      setEmployeeGender: function setEmployeeGender(gender) {
        this.set('employee.gender', gender);
      },
      fillPhysicalAddress: function fillPhysicalAddress() {
        this.setProperties({
          "employee.physical_address_line_one": this.get('employee.address_line_one'),
          "employee.physical_address_line_two": this.get('employee.address_line_two'),
          "employee.physical_city": this.get('employee.city'),
          "employee.physical_state": this.get('employee.state'),
          "employee.physical_zipcode": this.get('employee.zip_code'),
          "employee.physical_country": this.get('employee.country')
        });
      },
      deleteEmployee: function deleteEmployee() {
        var _this3 = this;

        this.employee.destroyRecord().then(function () {
          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Employee Deleted</span></div>"
          });

          _this3.sendAction('reloadRoute');

          _this3.get('router').transitionTo('companies.show.employees.employees-table', _this3.companyId);
        });
      },
      updateVacationAccrualType: function updateVacationAccrualType(accrualType) {
        this.set('employee.vacation_accrual_type', accrualType);
      },
      updateSicknessAccrualType: function updateSicknessAccrualType(accrualType) {
        this.set('employee.sickness_accrual_type', accrualType);
      },
      updateVacationAccrualExpiration: function updateVacationAccrualExpiration(accrualExpiration) {
        this.set('employee.vacation_accrual_expiration', accrualExpiration);
      },
      updateSicknessAccrualExpiration: function updateSicknessAccrualExpiration(accrualExpiration) {
        this.set('employee.sickness_accrual_expiration', accrualExpiration);
      },
      updateVacationExpirationDate: function updateVacationExpirationDate(expirationDate) {
        this.set('employee.vacation_expiration_date', expirationDate);
      },
      updateSicknessExpirationDate: function updateSicknessExpirationDate(expirationDate) {
        this.set('employee.sickness_expiration_date', expirationDate);
      },
      setFilingStatus: function setFilingStatus(value) {
        this.set('employee.filing_status', value);
      },
      cancel: function cancel() {
        if (Ember.typeOf(this.get('employee')) === "instance") {
          this.get('employee').rollbackAttributes();
        }

        this.get('router').transitionTo('companies.show.employees.employees-table', this.companyId);
      }
    }
  });

  _exports.default = _default;
});