define("account-berry-frontend/companies/show/shopify/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2GM1+XZE",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"flex-column flex-center\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"flex-row space-between\"],[7],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"icon-accountberry lg\"],[7],[6,\"span\"],[9,\"class\",\"path1\"],[7],[8],[6,\"span\"],[9,\"class\",\"path2\"],[7],[8],[8],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"left-spacing right-spacing icon-repeat lg\"],[7],[8],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"icon-shopify lg\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[6,\"h3\"],[7],[1,[20,[\"model\",\"shop\"]],false],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"flex-row flex-center\"],[7],[0,\"\\n      \"],[6,\"button\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"btn accountberry-btn danger\"],[3,\"action\",[[19,0,[]],\"unsync\"]],[7],[0,\"Unsync Shop\"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"h4\"],[7],[0,\"Enter your myshopify url\"],[8],[0,\"\\n\\n      \"],[6,\"span\"],[9,\"class\",\"form-group width-15\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"input-group bottom-spacing-small\"],[7],[0,\"\\n          \"],[1,[25,\"input\",null,[[\"class\",\"name\",\"type\",\"value\",\"placeholder\"],[\"form-control\",\"myshopify\",\"text\",[20,[\"myshopifyShop\"]],\"myshop\"]]],false],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"input-group-addon\"],[7],[0,\".myshopify.com\"],[8],[0,\"\\n\"],[0,\"        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\\n      \"],[6,\"a\"],[10,\"href\",[18,\"shopifyPermissionUrl\"],null],[7],[0,\"\\n        \"],[6,\"button\"],[9,\"class\",\"btn accountberry-btn success\"],[10,\"disabled\",[25,\"not\",[[20,[\"myshopifyShop\"]]],null],null],[7],[0,\"CONNECT SHOPIFY\"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/companies/show/shopify/template.hbs"
    }
  });

  _exports.default = _default;
});