define("account-berry-frontend/companies/show/accounts/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EYB9Tr6X",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"account-form\",null,[[\"companyId\",\"account\",\"accountTypeId\",\"refreshModel\",\"store\"],[[20,[\"companyId\"]],[20,[\"model\"]],[20,[\"model\",\"account_type_id\"]],\"refreshModel\",[20,[\"store\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/companies/show/accounts/show/template.hbs"
    }
  });

  _exports.default = _default;
});