define("account-berry-frontend/user/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    address_line_one: _emberData.default.attr(),
    address_line_two: _emberData.default.attr(),
    city: _emberData.default.attr(),
    country: _emberData.default.attr(),
    email: _emberData.default.attr(),
    first_name: _emberData.default.attr(),
    picture: _emberData.default.attr(),
    last_name: _emberData.default.attr(),
    state: _emberData.default.attr(),
    phone: _emberData.default.attr(),
    zipcode: _emberData.default.attr(),
    name: _emberData.default.attr(),
    company: _emberData.default.belongsTo('company'),
    role: _emberData.default.attr(),
    user_role: _emberData.default.attr(),
    unique_session_id: _emberData.default.attr(),
    card_last4_digits: _emberData.default.attr(),
    card_brand: _emberData.default.attr(),
    card_exp_date: _emberData.default.attr(),
    theme: _emberData.default.attr(),
    trial_days_left: _emberData.default.attr('number')
  });

  _exports.default = _default;
});