define("account-berry-frontend/ref-number/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    generate: function generate(last_ref_number) {
      last_ref_number = last_ref_number.toString();
      var ref_number = 1;

      if (last_ref_number) {
        var output = [];
        var json = last_ref_number.split(' ');
        json.forEach(function (item) {
          output.push(item.replace(/\'/g, '').split(/(\d+)/).filter(Boolean));
        }); // return [].concat.apply([], output);

        var ref_number_arr = [].concat.apply([], output);
        var done = false;
        var count = ref_number_arr.length - 1;

        while (!done) {
          var num = parseInt(ref_number_arr[count]);

          if (num) {
            ref_number_arr[count] = num + 1;
            ref_number = ref_number_arr.join("");
            done = true;
          }

          count--;

          if (count < 0 && !done) {
            ref_number = last_ref_number + "1";
            done = true;
          }
        }
      }

      return ref_number;
    }
  });

  _exports.default = _default;
});