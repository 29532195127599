define("account-berry-frontend/batch/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    company_id: _emberData.default.attr('number'),
    import_type: _emberData.default.attr('string'),
    imported: _emberData.default.attr('boolean'),
    import_data: _emberData.default.attr(),
    created_at: _emberData.default.attr('date')
  });

  _exports.default = _default;
});