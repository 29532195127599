define("account-berry-frontend/components/a480-input-account/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      setAccount: function setAccount(accountId, index) {
        this.sendAction('setAccount', accountId, index, this.selectId);
      },
      removeAccount: function removeAccount(index) {
        this.sendAction('removeAccount', index, this.selectId);
      }
    }
  });

  _exports.default = _default;
});