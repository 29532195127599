define("account-berry-frontend/companies/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    notify: Ember.inject.service('notify'),
    needs: Ember.computed(function () {
      return ['companies'];
    }),
    beforeModel: function beforeModel(transition) {
      var companyId = transition.params['companies.show'].company_id;
      var company = this.store.peekRecord('company', companyId);

      if (!company || company.get('isDeleted') || company.get('deleted')) {
        this.transitionTo('/');
      }
    },
    model: function model(param) {
      return this.store.findRecord('company', param.company_id, {
        reload: true
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('breadCrumbPath', "companies.show", model.id);
      controller.set('breadCrumb', model.data.name);
      controller.set('company', model); // this.get('session').set('data.company_id', model.id);
    },
    afterModel: function afterModel(model) {
      if (!model.get('creator.stripe_subscription_active')) {
        this.get('notify').alert({
          html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>This company's subscription is not active. If you are the creator of this company, please verify that your payment information is correct.</span></div>",
          closeAfter: null
        });
        this.transitionTo("/");
      }
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show', {
        into: 'application',
        model: model
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});