define("account-berry-frontend/four-oh-four/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect: function redirect() {
      // this redirects / to /dashboard
      this.transitionTo('/');
    }
  });

  _exports.default = _default;
});