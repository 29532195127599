define("account-berry-frontend/components/new-company-step-four/component", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.store.findAll('subscription-plan').then(function (subscriptionPlans) {
        _this.set('subscriptionPlans', subscriptionPlans);
      });
      $(function () {
        $('[data-toggle="popover"]').popover({
          html: true
        });
      });
    },
    plans: Ember.computed('subscriptionPlans', function () {
      var _this2 = this;

      var subscriptionPlans = this.get('subscriptionPlans');
      var plans = [];

      if (subscriptionPlans) {
        subscriptionPlans.forEach(function (plan) {
          switch (plan.get('stripe_plan_id')) {
            case _environment.default.stripe_plan_id.bronze_plan:
              plans.push({
                id: plan.id,
                selected: _this2.get('company.subscription_plan_id') ? _this2.get('company.subscription_plan_id').toString() === plan.id : false,
                stripePlanId: plan.get('stripe_plan_id'),
                amount: plan.get('amount'),
                title: "Bronze Register",
                whatsIncluded: [{
                  name: "Error Prevention Validator",
                  included: true
                }, {
                  name: "Export your data",
                  included: true
                }, {
                  name: "100 Transactions per month",
                  included: true
                }, {
                  name: "Up to 2 Users",
                  included: true
                }, {
                  name: "1 Cash/Bank Account, Fee per Additional Cash/Bank Account $5",
                  included: true
                }, {
                  name: "Balance Sheet, Profit & Loss, Statement of Cash Flows, General Ledger & other reports",
                  included: true
                }, {
                  name: "Batch Data Importing",
                  included: true
                }, {
                  name: "Unlimited Transactions",
                  included: false
                }, {
                  name: "Unlimited Users",
                  included: false
                }, {
                  name: "Unlimited Cash Accounts",
                  included: false
                }, {
                  name: "Label/Job Costing Feature",
                  included: false
                }, {
                  name: "Purchase Orders, Bills & Credits, Pay Vendors, Returns & keep track of Accounts Payable aging reports",
                  included: false
                }, {
                  name: "Sales Orders, Invoices, Sales Representatives, Receive Payments & Returns",
                  included: false
                }, {
                  name: "Inventory Features",
                  included: false
                }, {
                  name: "Automatic Vendor Withholding Tracking",
                  included: false
                }, {
                  name: "Payroll Features",
                  included: false
                }, {
                  name: "Tax Return features",
                  included: false
                }, {
                  name: "Bank Sync",
                  included: false
                }],
                info: "",
                disabled: _this2.get('company.counts.active_employees_count') > 0,
                img: "".concat(_environment.default.rootURL, "assets/images/logos/bronze_plan.svg")
              });
              break;

            case _environment.default.stripe_plan_id.silver_plan:
              plans.push({
                id: plan.id,
                selected: _this2.get('company.subscription_plan_id') ? _this2.get('company.subscription_plan_id').toString() === plan.id : false,
                stripePlanId: plan.get('stripe_plan_id'),
                amount: plan.get('amount'),
                title: "Silver Business",
                whatsIncluded: [{
                  name: "Error Prevention Validator",
                  included: true
                }, {
                  name: "Export your data",
                  included: true
                }, {
                  name: "100 Transactions per month",
                  included: false
                }, {
                  name: "Up to 2 Users",
                  included: false
                }, {
                  name: "1 Cash/Bank Account, Fee per Additional Cash/Bank Account $5",
                  included: false
                }, {
                  name: "Balance Sheet, Profit & Loss, Statement of Cash Flows, General Ledger & other reports",
                  included: true
                }, {
                  name: "Batch Data Importing",
                  included: true
                }, {
                  name: "Unlimited Transactions",
                  included: true
                }, {
                  name: "Unlimited Users",
                  included: true
                }, {
                  name: "Unlimited Cash Accounts",
                  included: true
                }, {
                  name: "Label/Job Costing Feature",
                  included: false
                }, {
                  name: "Purchase Orders, Bills & Credits, Pay Vendors, Returns & keep track of Accounts Payable aging reports",
                  included: true
                }, {
                  name: "Sales Orders, Invoices, Sales Representatives, Receive Payments & Returns",
                  included: true
                }, {
                  name: "Inventory Features",
                  included: true
                }, {
                  name: "Automatic Vendor Withholding Tracking",
                  included: false
                }, {
                  name: "Payroll Features",
                  included: false
                }, {
                  name: "Tax Return features",
                  included: false
                }, {
                  name: "Bank Sync",
                  included: false
                }],
                info: "",
                disabled: _this2.get('company.counts.active_employees_count') > 0,
                img: "".concat(_environment.default.rootURL, "assets/images/logos/silver_plan.svg")
              });
              break;

            case _environment.default.stripe_plan_id.gold_plan:
              plans.push({
                id: plan.id,
                selected: _this2.get('company.subscription_plan_id') ? _this2.get('company.subscription_plan_id').toString() === plan.id : false,
                stripePlanId: plan.get('stripe_plan_id'),
                amount: plan.get('amount'),
                title: "Gold Enterprise",
                whatsIncluded: [{
                  name: "Error Prevention Validator",
                  included: true
                }, {
                  name: "Export your data",
                  included: true
                }, {
                  name: "100 Transactions per month",
                  included: false
                }, {
                  name: "Up to 2 Users",
                  included: false
                }, {
                  name: "1 Cash/Bank Account, Fee per Additional Cash/Bank Account $5",
                  included: false
                }, {
                  name: "Balance Sheet, Profit & Loss, Statement of Cash Flows, General Ledger & other reports",
                  included: true
                }, {
                  name: "Batch Data Importing",
                  included: true
                }, {
                  name: "Unlimited Transactions",
                  included: true
                }, {
                  name: "Unlimited Users",
                  included: true
                }, {
                  name: "Unlimited Cash Accounts",
                  included: true
                }, {
                  name: "Label/Job Costing Feature",
                  included: true
                }, {
                  name: "Purchase Orders, Bills & Credits, Pay Vendors, Returns & keep track of Accounts Payable aging reports",
                  included: true
                }, {
                  name: "Sales Orders, Invoices, Sales Representatives, Receive Payments & Returns",
                  included: true
                }, {
                  name: "Inventory Features",
                  included: true
                }, {
                  name: "Automatic Vendor Withholding Tracking",
                  included: true
                }, {
                  name: "Payroll Features",
                  included: true
                }, {
                  name: "Tax Return features",
                  included: true
                }, {
                  name: "Bank Sync",
                  included: true
                }],
                info: "+ $1 per Employee**",
                disabled: false,
                img: "".concat(_environment.default.rootURL, "assets/images/logos/gold_plan.svg")
              });
              break;

            default:
              break;
          }
        });
      }

      return plans;
    }),
    actions: {
      selectPlan: function selectPlan(plan) {
        var _this3 = this;

        if (!plan.disabled) {
          this.get('plans').forEach(function (p) {
            if (p === plan) {
              _this3.set('company.subscription_plan_id', plan.id);

              Ember.set(p, 'selected', true);
            } else {
              Ember.set(p, 'selected', false);
            }
          });
        }

        this.sendAction('selectPlan', plan);
      },
      toggleNewPaymentMethod: function toggleNewPaymentMethod() {
        this.toggleProperty('newPaymentMethod');
      },
      togglePricing: function togglePricing() {
        this.toggleProperty('showPricing');

        if (this.get('showPricing')) {
          $(function () {
            $('[data-toggle="popover"]').popover({
              html: true
            });
          });
        }
      },
      create: function create() {
        this.sendAction('create');
      },
      back: function back() {
        this.sendAction('updateCompanyData', this.get('company'), 'stepFour', 'stepThree');
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      },
      reloadRoute: function reloadRoute() {
        this.sendAction("reloadRoute");
      }
    }
  });

  _exports.default = _default;
});