define("account-berry-frontend/companies/show/employees/payroll-settings/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      // Using "1" as the id because ember requires it for this procedure
      // The server ignores the id, it does not need it to find the company's payroll settings since it only has one
      return this.store.findRecord('payroll-setting', 1, {
        reload: true
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('breadCrumb', 'Payroll Settings');
      controller.set('companyId', this.paramsFor('companies.show').company_id);
      controller.set('payrollSettings', model);
      this.store.findAll('account', {
        reload: true
      }).then(function (accounts) {
        var validAccountTypes = ['Cost Of Goods Sold', 'Current Liability', 'Expense', 'Other Assets'];
        var expenseAccounts = [];
        var filteredAccounts = [];
        accounts.forEach(function (account) {
          if (validAccountTypes.includes(account.get('account_type.name'))) {
            filteredAccounts.push(account);

            if (account.get('account_type.name') === "Expense") {
              expenseAccounts.push(account);
            }
          }
        });
        controller.set('accounts', filteredAccounts);
        controller.set('expenseAccounts', expenseAccounts);
      });
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.employees.payroll-settings', {
        into: 'application',
        model: model
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});