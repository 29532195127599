define("account-berry-frontend/components/select-bill/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/YXu0P4k",
    "block": "{\"symbols\":[\"bill\"],\"statements\":[[6,\"span\"],[9,\"class\",\"form-group has-float-label\"],[7],[0,\"\\n  \"],[6,\"select\"],[9,\"class\",\"btn accountberry-btn\"],[9,\"name\",\"bill\"],[10,\"onchange\",[25,\"action\",[[19,0,[]],\"updateBill\"],[[\"value\"],[\"target.value\"]]],null],[10,\"disabled\",[18,\"disabled\"],null],[10,\"required\",[18,\"required\"],null],[7],[0,\"\\n    \"],[6,\"option\"],[9,\"value\",\"\"],[9,\"selected\",\"\"],[7],[0,\"Select Bill\"],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"bills\"]]],null,{\"statements\":[[0,\"      \"],[6,\"option\"],[10,\"value\",[19,1,[\"id\"]],null],[10,\"selected\",[25,\"eq\",[[25,\"concat\",[\"\",[20,[\"billId\"]]],null],[25,\"concat\",[\"\",[19,1,[\"id\"]]],null]],null],null],[7],[0,\"\\n        \"],[1,[19,1,[\"ref_number\"]],false],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[8],[0,\"\\n  \"],[6,\"label\"],[9,\"for\",\"bill\"],[7],[0,\"Bill\"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/select-bill/template.hbs"
    }
  });

  _exports.default = _default;
});