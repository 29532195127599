define("account-berry-frontend/settings/account-profile/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    imagePreview: null,
    actions: {
      cancel: function cancel() {
        this.model.rollbackAttributes();
        this.set('imagePreview', null);
        this.transitionToRoute('settings');
      },
      setUserImage: function setUserImage(file) {
        if (file) {
          var reader = new FileReader();

          var _this = this;

          reader.onload = function (e) {
            _this.set('imagePreview', e.target.result);
          };

          reader.readAsDataURL(file);
          this.set('model.picture', file);
        }
      },
      update: function update() {
        var _this2 = this;

        this.model.save().then(function () {
          _this2.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Account Updated</span></div>'
          });
        }, function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      }
    }
  });

  _exports.default = _default;
});