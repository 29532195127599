define("account-berry-frontend/vendor/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    address_line_one: _emberData.default.attr(),
    address_line_two: _emberData.default.attr(),
    city: _emberData.default.attr(),
    company_id: _emberData.default.attr(),
    company_ein: _emberData.default.attr(),
    company_name: _emberData.default.attr(),
    country: _emberData.default.attr(),
    display_name: _emberData.default.attr(),
    email: _emberData.default.attr(),
    first_name: _emberData.default.attr(),
    last_name: _emberData.default.attr(),
    name: _emberData.default.attr(),
    notes: _emberData.default.attr(),
    state: _emberData.default.attr(),
    phone: _emberData.default.attr(),
    website: _emberData.default.attr(),
    merchant_id: _emberData.default.attr(),
    zipcode: _emberData.default.attr(),
    ordered_items: _emberData.default.attr(),
    transactions: _emberData.default.attr(),
    account_id: _emberData.default.attr(),
    undeletable: _emberData.default.attr(),
    track_year_end_report: _emberData.default.attr('boolean'),
    retention_after_amount: _emberData.default.attr('number'),
    retention_percentage: _emberData.default.attr('number'),
    retention_account_id: _emberData.default.attr('number'),
    amount_paid_in_year: _emberData.default.attr('number'),
    is_company: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});