define("account-berry-frontend/components/payment-method/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r0wpahKO",
    "block": "{\"symbols\":[\"stripeElement\",\"stripeError\"],\"statements\":[[6,\"div\"],[9,\"id\",\"paymentMethod\"],[9,\"class\",\"col-md-12\"],[7],[0,\"\\n  \"],[6,\"h4\"],[9,\"class\",\"text-center\"],[7],[0,\"Set Payment Information\"],[8],[0,\"\\n  \"],[6,\"h5\"],[9,\"class\",\"text-center accountberry-color\"],[7],[6,\"strong\"],[7],[0,\"You won't be charged until your free trial expires.\"],[8],[8],[0,\"\\n  \"],[6,\"h5\"],[9,\"class\",\"text-center top-spacing\"],[7],[1,[18,\"creditCardInfo\"],false],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"flex-column flex-center top-spacing\"],[7],[0,\"\\n    \"],[6,\"h5\"],[7],[0,\"Debit or Credit Card\"],[8],[0,\"\\n\"],[4,\"stripe-card\",null,[[\"options\"],[[20,[\"options\"]]]],{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n        \"],[6,\"a\"],[9,\"href\",\"https://stripe.com\"],[9,\"target\",\"_blank\"],[7],[0,\"\\n          \"],[6,\"img\"],[9,\"class\",\"text-center\"],[9,\"src\",\"/assets/images/powered_by_stripe.svg\"],[9,\"alt\",\"Powered By Stripe Image\"],[7],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"top-spacing bottom-spacing text-center\"],[7],[0,\"\\n        \"],[6,\"button\"],[9,\"class\",\"btn accountberry-btn success\"],[3,\"action\",[[19,0,[]],\"submit\",[19,1,[]]]],[7],[0,\"Submit Payment Method\"],[8],[0,\"\\n        \"],[6,\"button\"],[9,\"class\",\"btn accountberry-btn\"],[3,\"action\",[[19,0,[]],\"cancel\"]],[7],[0,\"Cancel\"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/payment-method/template.hbs"
    }
  });

  _exports.default = _default;
});