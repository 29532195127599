define("account-berry-frontend/components/twitter-feed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E1B9S7nH",
    "block": "{\"symbols\":[],\"statements\":[[6,\"a\"],[9,\"class\",\"twitter-timeline\"],[9,\"href\",\"https://twitter.com/accountberryapp?ref_src=twsrc%5Etfw\"],[7],[0,\"\\n  Tweets by accountberryapp\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/twitter-feed/template.hbs"
    }
  });

  _exports.default = _default;
});