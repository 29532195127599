define("account-berry-frontend/components/log-rocket-init/component", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      if (_environment.default['environment'] !== "development") {
        LogRocket.init('0m2dxl/accountberry');
        LogRocket.identify(this.get('session.data.authenticated.id'), {
          name: this.get('session.data.authenticated.name'),
          email: this.get('session.data.authenticated.email')
        });
      }
    }
  });

  _exports.default = _default;
});