define("account-berry-frontend/components/recurrency-setter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Component.extend({
    tagName: '',
    notify: service('notify'),
    init: function init() {
      this._super();

      this.recurrencySetter();
    },
    didInsertElement: function didInsertElement() {
      this.recurrencySetter();
    },
    recurrencySetter: function recurrencySetter() {
      if (this.get('recurrency')) {
        if (this.get('recurrency.active') === undefined || this.get('recurrency.active')) {
          this.set('recurrency.active', true);
          this.send('setRecurrency', this.get('recurrency'));
          $('#recurrentContainer').collapse('show');
        } else {
          this.send('setRecurrency', this.get('recurrency'));
        }
      } else {
        this.set('recurrency', {
          active: false,
          frequency: 'day',
          duration: 5,
          every: 1
        });
      }
    },
    recurrencyObserver: Ember.observer('recurrency.frequency', 'recurrency.active', 'recurrency.every', 'recurrency.duration', 'recurrency.unlimited', 'recurrency.transaction_date', function () {
      this.recurrencySetter();
    }),
    actions: {
      setFrequency: function setFrequency(frequency) {
        this.set('recurrency.frequency', frequency);
      },
      setRecurrency: function setRecurrency(recurrency) {
        this.sendAction('setRecurrency', recurrency);
      },
      toggleRecurrency: function toggleRecurrency() {
        this.toggleProperty('recurrency.active');

        if (this.get('recurrency.active')) {
          this.get('notify').success({
            html: "<div class=\"message-text\"><span>Recurrency Enabled</span></div>"
          });
        } else {
          this.get('notify').alert({
            html: "<div class=\"message-text\"><span>Recurrency Disabled</span></div>"
          });
        }
      }
    }
  });

  _exports.default = _default;
});