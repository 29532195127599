define("account-berry-frontend/companies/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return this.store.findAll('company', {
        reload: true
      }).then(function (companies) {
        return companies.toArray().sortBy('name');
      });
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies', {
        into: 'application',
        model: model
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});