define("account-berry-frontend/purchase-order/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    po_date: _emberData.default.attr(),
    required_date: _emberData.default.attr(),
    po_number: _emberData.default.attr(),
    ship_via: _emberData.default.attr(),
    fob: _emberData.default.attr(),
    po_terms: _emberData.default.attr(),
    item_amount: _emberData.default.attr(),
    net_po_amount: _emberData.default.attr(),
    tax_amount: _emberData.default.attr(),
    freight_amount: _emberData.default.attr(),
    total_po_amount: _emberData.default.attr(),
    vendor_message: _emberData.default.attr(),
    address: _emberData.default.attr(),
    memo: _emberData.default.attr(),
    vendor_id: _emberData.default.attr(),
    vendor: _emberData.default.attr(),
    company_id: _emberData.default.attr(),
    ordered_items: _emberData.default.attr(),
    items: _emberData.default.attr(),
    undeletable: _emberData.default.attr(),
    back_ordered_items: _emberData.default.attr(),
    received_items: _emberData.default.attr(),
    closed: _emberData.default.attr(),
    tax_account_id: _emberData.default.attr(),
    freight_account_id: _emberData.default.attr(),
    label_ids: _emberData.default.attr(),
    labels: _emberData.default.attr()
  });

  _exports.default = _default;
});