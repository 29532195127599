define("account-berry-frontend/companies/show/reports/payroll-detail/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    cable: Ember.inject.service(),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    init: function init() {
      var _this2 = this;

      Ember.$.initialize('.payroll-detail-table', function () {
        _this2.setTable();
      }); // this.store.findRecord('payroll-setting', 1).then((payrollSetting) => {
      //   this.set('payrollSettings', payrollSetting);
      // });
      // this.store.findAll('payroll').then((payrolls) => {
      //   this.set('payrolls', payrolls.sortBy('pay_date').reverse());
      // });
      // let channel = this.get('session.pusher').subscribe(`payroll-detail-channel-${this.get('session.data.authenticated.id')}`);
      // channel.bind('report', (response) => {
      //   this.store.findRecord('json-storage', response.json_storage_id).then((jsonStorage) => {
      //     this.set('payrollDetailData', jsonStorage.get('stored_json.report'));
      //     this.get('applicationController').set('showLoading', false);
      //   });
      // });  
    },
    setTable: function setTable() {
      var printableTable = Ember.$('#payrollDetail').clone(true);
      printableTable.css('font-size', '1vw');
      this.set('printableTable', printableTable);
      Ember.$('.payroll-detail-table').tableHeadFixer({
        // fix table header
        head: true,
        // fix table footer
        foot: false,
        // fix x left columns
        left: 1,
        // fix x right columns
        right: 0,
        // z-index
        'z-index': 0
      });
    },
    actions: {
      getPayrollDetail: function getPayrollDetail() {
        var _this3 = this;

        var data = JSON.stringify({
          report: {
            id: this.id,
            date: this.date
          },
          report_type: 'payroll_detail'
        });

        var _this$get = this.get('session.data.authenticated'),
            email = _this$get.email,
            token = _this$get.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

        var _this = this;

        consumer.createSubscription({
          channel: "ReportsChannel",
          room: "PayrollDetail" + this.get('session.data.authenticated.id')
        }, {
          connected: function connected() {
            console.log('Connected to ReportsChannel-PayrollDetail');

            _this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(_this.companyId, "/reports"), {
              method: 'POST',
              contentType: 'application/json',
              data: data,
              headers: headers
            }).then(function () {
              _this.get('applicationController').set('showLoading', true);
            }).catch(function () {
              _this.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
              });
            });
          },
          received: function received(data) {
            if (data.error) {
              _this3.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
              });
            } else {
              _this3.set('payrollDetailData', data.report);

              consumer.disconnect();

              _this3.get('applicationController').set('showLoading', false);
            }
          },
          disconnected: function disconnected() {
            console.log("ReportsChannel-PayrollDetail disconnected");
          }
        });
      },
      updatePayroll: function updatePayroll(id) {
        this.set('id', id);
        this.set('date', this.store.peekRecord('payroll', id).get('pay_date'));
      },
      print: function print() {
        this.printableTable.printThis(); // table.css('font-size', '1vw');
        // table.printThis({
        //   pageTitle: `${this.get('company.name')} - Employee Earnings Detail`,
        //   header: `<h4 style='text-align: center;'>From ${moment(this.get('fromDate')).format('MMMM D, YYYY')} To ${moment(this.get('toDate')).format('MMMM D, YYYY')}</h4>`
        // });
        // table.css('font-size', 'inherit');
      },
      csvExport: function csvExport() {
        Ember.$('.payroll-detail-table').table2CSV({
          delivery: 'download',
          filename: 'payroll_detail_report.csv'
        });
      } // print() {
      //   Ember.$('#uncollectedBills').printThis();
      // },
      // csvExport() {
      //   let table = "<table id='uncollectedBillsTable' style='width:100%; font-size: 14px'><thead>";
      //   table += "<tr><th></th><th>TYPE</th><th>DATE</th><th>NUMBER</th>";
      //   table +="<th>DUE DATE</th><th>AGING</th><th>OPEN BALANCE</th></thead>";
      //   table += "<tbody>";
      //   this.get('employeeEarningsDetailData.customers').forEach((customer) => {
      //     table += `<tr><td>${customer.name}</td><td></td><td></td><td></td><td></td><td></td></tr>`;
      //     customer.transactions.forEach((transaction) => {
      //       table += `<tr><td>${transaction.type}</td>`;
      //       table += `<td>${transaction.date}</td>`;
      //       table += `<td>${transaction.number}</td>`;
      //       table += `<td>${transaction.due_date ? transaction.due_date : ""}</td>`;
      //       table += `<td>${transaction.aging ? transaction.aging : ""}</td>`;
      //       table += `<td>${parseFloat(transaction.open_balance).toFixed(2)}</td></tr>`;
      //     });
      //     table += `<tr><td>Total ${customer.name}</td><td></td><td></td><td></td><td></td>`;
      //     table += `<td>${parseFloat(customer.total).toFixed(2)}</td></tr>`;
      //   });
      //   table += "<tr><td>Total</td><td></td><td></td><td></td><td></td>";
      //   table += `<td>${parseFloat(this.get('employeeEarningsDetailData.total')).toFixed(2)}</td></tr>`;
      //   table += "</tbody></table>";
      //   Ember.$('#csvExport').show();
      //   document.getElementById('csvExport').innerHTML += table;
      //   Ember.$('#uncollectedBillsTable').table2CSV({
      //     delivery: 'download',
      //     filename: 'UncollectedBillsTable'
      //   });
      //   // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;
      //   Ember.$('#uncollectedBillsTable').remove();
      //   Ember.$('#csvExport').hide();
      // }

    }
  });

  _exports.default = _default;
});