define("account-berry-frontend/company/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    accounting_method: attr(),
    accounts: hasMany('account'),
    chautteur_insurance_rate: attr(),
    checks: hasMany('check'),
    last_closing_entry_date: attr(),
    contact_name: attr(),
    counts: attr(),
    costumers: hasMany('customer'),
    customers_names: attr(),
    email: attr(),
    ein: attr(),
    employees_names: attr(),
    first_month_accounting_year: attr(),
    incorporation_date: attr(),
    items: hasMany('item'),
    legal_name: attr(),
    merchant_registration: attr(),
    naics_code: attr(),
    name: attr(),
    // picture: attr()
    picture: attr(),
    postal_address_line_one: attr(),
    postal_address_line_two: attr(),
    postal_address_city: attr(),
    postal_address_state: attr(),
    postal_address_zip: attr(),
    postal_address_country: attr(),
    physical_address_line_one: attr(),
    physical_address_line_two: attr(),
    physical_address_city: attr(),
    physical_address_zip: attr(),
    physical_address_country: attr(),
    phone: attr(),
    role: attr(),
    termination_date: attr(),
    taxes: hasMany('tax'),
    tax_form: attr(),
    unemployment_number: attr(),
    users: hasMany('user'),
    user_roles: hasMany('user_role'),
    vendors: hasMany('vendor'),
    vendors_names: attr(),
    website: attr(),
    permissions: attr(),
    invites: attr(),
    subscription_plan_id: attr(),
    deleted: attr(),
    creator: attr(),
    stripe_plan_id: attr(),
    bank_account: attr(),
    clover_sync: attr(),
    app_message_read: attr('boolean'),
    app_message: _emberData.default.attr()
  });

  _exports.default = _default;
});