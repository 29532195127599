define("account-berry-frontend/companies/show/bank-sync/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    sortAscending: false,
    syncableAccounts: [],
    subscribedToChannel: false,
    applicationController: Ember.inject.controller('application'),
    premiumModal: false,
    init: function init() {
      var _this = this;

      this.store.findAll('account').then(function (accounts) {
        var syncableTypes = ['cash', 'current liability', 'long term liability'];
        var syncableAccounts = accounts.filter(function (account) {
          return syncableTypes.includes(account.get('account_type.name').toLowerCase()) && !account.get('is_title');
        });

        _this.set('syncableAccounts', syncableAccounts);
      });
    },
    user: Ember.computed('session.data.authenticated.id', function () {
      return this.store.peekRecord('user', this.get('session.data.authenticated.id'));
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this2 = this;

      var channel = this.get('session.pusher').subscribe("bank-sync-channel-".concat(this.companyId));
      channel.bind('synced', function () {
        _this2.get('notify').success({
          html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Account was successfully linked</span></div>'
        });

        _this2.send('refreshModel');
      });
      this.set('subscribedToChannel', true);
    },
    company: Ember.computed('companyId', function () {
      if (!this.subscribedToChannel && this.companyId) {
        this.subscribeToChannels();
      }

      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    actions: {
      delete: function _delete() {
        var _this3 = this;

        this.plaidLink.destroyRecord().then(function () {
          var accountName = _this3.get('plaidLink.plaid_account_name');

          _this3.set('model', _this3.store.peekAll('plaid-link').filter(function (plaidLink) {
            return !plaidLink.get('isDeleted');
          }));

          _this3.setProperties({
            plaidLink: {},
            confirmDelete: false
          });

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(accountName || "", " Account Sync Deleted</span></div>")
          });
        }, function (response) {
          var errors = "An Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          }

          _this3.plaidLink.rollbackAttributes();

          _this3.set('confirmDelete', false);

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      update: function update(accountId, index) {
        var _this4 = this;

        var plaidLink = this.get('model')[index];
        plaidLink.set('account_id', accountId);
        plaidLink.save().then(function () {
          _this4.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Account Linked To Bank</span></div>'
          });
        });
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        if (id) {
          this.set('plaidLink', this.store.peekRecord('plaid-link', id));
        }

        this.toggleProperty('confirmDelete');
      },
      togglePremiumModal: function togglePremiumModal(index) {
        this.toggleProperty('premiumModal');
        this.set('premiumIndex', index);
      },
      togglePaymentModal: function togglePaymentModal() {
        this.toggleProperty('paymentModal');
      },
      subscribeToPremium: function subscribeToPremium() {
        var _this5 = this;

        var plaidLink = this.get('model')[this.get('premiumIndex')];
        plaidLink.set('subscribe', true);
        plaidLink.save().then(function () {
          _this5.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Account Subscribed to Premium Service!</span></div>'
          });

          _this5.setProperties({
            'premiumIndex': null,
            'premiumModal': false
          });

          plaidLink.set('subscription_active', true);
        }, function (response) {
          _this5.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(response.errors, "</span></div>")
          });

          _this5.set('paymentModal', true);
        });
      },
      reloadRoute: function reloadRoute() {
        this.send('refreshModel');
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('model', this.get('model').sortBy(property));
        } else {
          this.set('model', this.get('model').sortBy(property).reverse());
        } // this.set('pagedRepresentatives', pagedArray('model', { infinite: "unpaged", page: 1, perPage: 100 }));

      }
    }
  });

  _exports.default = _default;
});