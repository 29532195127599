define("account-berry-frontend/companies/show/employees/payrolls/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    reset: true,
    beforeModel: function beforeModel(transition) {
      this.set('reset', !transition.queryParams.show);
    },
    model: function model(param) {
      return this.store.findRecord('payroll', param.payroll_id);
    },
    setupController: function setupController(controller, model) {
      controller.set('breadCrumb', "Payroll ".concat(model.get('pay_date')));
      controller.set('companyId', this.paramsFor('companies.show').company_id);
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.employees.payrolls.show', {
        into: 'application',
        model: model
      });
    }
  });

  _exports.default = _default;
});