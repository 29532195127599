define("account-berry-frontend/components/employee-import-analysis/component", ["exports", "ember-cli-pagination/computed/paged-array"], function (_exports, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    session: Ember.inject.service('session'),
    notify: Ember.inject.service('notify'),
    pagedAnalyzedImportData: (0, _pagedArray.default)('analyzedImportData', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    analyzedImportDataObserver: Ember.observer('analyzedImportData', function () {
      this.set('pagedAnalyzedImportData', (0, _pagedArray.default)('analyzedImportData', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    filing_statuses: ["Married Filing Jointly", "Married Filing Separate", "Single", "Head of Household", "Qualifying Widow(er) with Dependent Child"],
    init: function init() {
      var _this = this;

      this._super();

      this.set('channel', this.get('session.pusher').subscribe("import-channel-".concat(this.get('session.data.authenticated.id'))));
      this.get('channel').bind('import', function (response) {
        _this.sendAction('toggleShowLoading', true, false);

        if (response.error) {
          _this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(response.error, "</span></div>")
          });
        } else {
          _this.sendAction('reloadRoute');

          _this.get('notify').success({
            html: "<i class=\"icon-checkmark lg\"></i> <div><strong>Success:</strong>&nbsp<span>Batch Imported Successfully.</span></div>"
          });

          _this.get('router').transitionTo('companies.show.batches', _this.companyId);
        }
      });
      this.get('channel').bind('destroy', function (response) {
        _this.sendAction('toggleShowLoading');

        if (response.error) {
          _this.store.unloadAll('batch');

          _this.sendAction('reloadRoute');

          _this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(response.error, "</span></div>")
          });
        } else {
          _this.sendAction('reloadRoute');

          _this.get('notify').alert({
            html: "<i class=\"icon-checkmark lg\"></i> <div><strong>Success:</strong>&nbsp<span>Batch Deleted.</span></div>"
          });

          _this.get('router').transitionTo('companies.show.batches', _this.companyId);
        }
      });
    },
    sendBatch: function sendBatch(remember) {
      var _this2 = this;

      var successMessage = remember ? "Batch Remembered for future analysis" : "Batch is being imported";
      this.store.findRecord('batch', this.batch.id, {
        backgroundReload: false
      }).then(function (batch) {
        batch.setProperties(_this2.batch);
        batch.save().then(function () {
          _this2.sendAction('toggleShowLoading');

          _this2.get('notify').success({
            html: "<i class=\"icon-checkmark lg\"></i> <div><strong>Success:</strong>&nbsp<span>".concat(successMessage, "</span></div>")
          });

          if (remember) {
            // this.sendAction('toggleShowLoading');
            _this2.sendAction('reloadRoute');

            _this2.get('router').transitionTo('companies.show.batches', _this2.companyId);
          }
        }, function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this2.sendAction('toggleShowLoading', true, false);

          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          }); // this.sendAction('cancel');

        });
      });
    },
    actions: {
      toggleImportAlert: function toggleImportAlert() {
        this.toggleProperty('importAlert');
      },
      setGender: function setGender(employee, index) {
        var value = Ember.$("#selectGender-".concat(index))[0].value;
        Ember.set(employee, 'gender', value);
      },
      setFilingStatus: function setFilingStatus(employee, index) {
        var value = Ember.$("#selectFilingStatus-".concat(index))[0].value;
        Ember.set(employee, 'filing_status', value);
      },
      updateVacationAccrualType: function updateVacationAccrualType(accrualType) {
        this.set('employee.vacation_accrual_type', accrualType);
      },
      updateSicknessAccrualType: function updateSicknessAccrualType(accrualType) {
        this.set('employee.sickness_accrual_type', accrualType);
      },
      updateVacationAccrualExpiration: function updateVacationAccrualExpiration(accrualExpiration) {
        this.set('employee.vacation_accrual_expiration', accrualExpiration);
      },
      updateSicknessAccrualExpiration: function updateSicknessAccrualExpiration(accrualExpiration) {
        this.set('employee.sickness_accrual_expiration', accrualExpiration);
      },
      updateVacationExpirationDate: function updateVacationExpirationDate(expirationDate) {
        this.set('employee.vacation_expiration_date', expirationDate);
      },
      updateSicknessExpirationDate: function updateSicknessExpirationDate(expirationDate) {
        this.set('employee.sickness_expiration_date', expirationDate);
      },
      import: function _import() {
        this.set('batch.import_data', {
          data: this.get('pagedAnalyzedImportData.content'),
          import: true
        });
        this.toggleProperty('importAlert'); // this.get('channel').bind('import', (response) => {
        //   this.sendAction('toggleShowLoading', true, false);
        //   if (response.error) {
        //     this.get('notify').alert({ html: `<i class="icon-warning lg"></i> <div class="message-text"><span>${response.error}</span></div>` });
        //   } else {
        //     this.sendAction('reloadRoute');
        //     this.get('notify').success({ html: `<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Batch Imported Successfully.</span></div>` });
        //     this.get('router').transitionTo('companies.show.batches', this.companyId);
        //   }
        // });
        // this.sendAction('toggleShowLoading');

        this.sendBatch(false);
      },
      remember: function remember() {
        this.set('batch.import_data', {
          data: this.get('pagedAnalyzedImportData.content')
        });
        this.sendAction('toggleShowLoading');
        this.sendBatch(true);
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      deleteBatch: function deleteBatch() {
        var _this3 = this;

        this.batch.destroyRecord().then(function () {
          _this3.sendAction('toggleShowLoading');

          _this3.set('confirmDelete', false);
        }).catch(function (response) {
          var errors = "An Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          }

          _this3.batch.rollbackAttributes();

          _this3.set('confirmDelete', false);

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      loadNext: function loadNext() {
        this.get('pagedAnalyzedImportData').loadNextPage();
      },
      cancel: function cancel() {
        this.sendAction('reloadRoute');
        this.sendAction('cancel');
      }
    }
  });

  _exports.default = _default;
});