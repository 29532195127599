define("account-berry-frontend/companies/show/reports/ap-aging-summary/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    cable: Ember.inject.service(),
    daysRange: 30,
    dueDate: true,
    showFullDetail: false,
    hideCleanBalance: true,
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    actions: {
      getApAgingSummary: function getApAgingSummary() {
        var _this2 = this;

        var data = JSON.stringify({
          report: {
            date: this.date,
            due_date: this.dueDate,
            days_range: this.daysRange
          },
          report_type: 'ap_aging_summary'
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/reports"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            _this2.get('applicationController').set('showLoading', true);
          },
          errorCallback: function errorCallback() {
            _this2.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
            });
          }
        };
        var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

        var _this = this;

        consumer.createSubscription({
          channel: "ReportsChannel",
          room: "ApAgingSummary" + this.get('session.data.authenticated.id')
        }, {
          connected: function connected() {
            console.log('Connected to ReportsChannel-ApAgingSummary');

            _this.get('ajaxCall').request(ajaxOptions);
          },
          received: function received(data) {
            if (data.error) {
              _this2.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
              });
            } else {
              _this2.set('apAgingSummaryData', data.report);

              consumer.disconnect();

              _this2.get('applicationController').set('showLoading', false);
            }
          },
          disconnected: function disconnected() {
            console.log("ReportsChannel-ApAgingSummary disconnected");
          }
        });
      },
      getFullDetail: function getFullDetail(vendorId, daysStart, daysEnd) {
        var _this3 = this;

        var data = JSON.stringify({
          report: {
            vendor_id: vendorId,
            date: this.get('date'),
            due_date: this.get('dueDate'),
            days_start: daysStart,
            days_end: daysEnd
          },
          report_type: 'ap_aging_summary_detail'
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/reports"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            _this3.get('applicationController').set('showLoading', true);
          },
          errorCallback: function errorCallback() {
            _this3.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
            });
          }
        };
        var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

        var _this = this;

        consumer.createSubscription({
          channel: "ReportsChannel",
          room: "ApAgingSummaryDetail" + this.get('session.data.authenticated.id')
        }, {
          connected: function connected() {
            console.log('Connected to ReportsChannel-ApAgingSummaryDetail');

            _this.get('ajaxCall').request(ajaxOptions);
          },
          received: function received(data) {
            if (data.error) {
              _this3.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
              });
            } else {
              _this3.set('apAgingSummaryDetailData', data.report);

              _this3.set('showFullDetail', true);

              consumer.disconnect();

              _this3.get('applicationController').set('showLoading', false);
            }
          },
          disconnected: function disconnected() {
            console.log("ReportsChannel-ApAgingSummaryDetail disconnected");
          }
        });
      },
      setDaysRange: function setDaysRange(daysRange) {
        this.set('daysRange', daysRange);

        if (this.date) {
          this.send('getApAgingSummary');
        }
      },
      setDateType: function setDateType(dueDate) {
        this.set('dueDate', dueDate.length > 0 ? true : false);

        if (this.date) {
          this.send('getApAgingSummary');
        }
      },
      print: function print() {
        Ember.$('#apAgingSummary').printThis();
      },
      csvExport: function csvExport() {
        var _this4 = this;

        var table = "<table id='apAgingSummaryTable' style='width:100%; font-size: 14px'><thead>";
        table += "<tr><th>A/P Aging Summary As of ".concat(moment(this.get('date')).format('MMMM D, YYYY'), "</th>"); // table += "<th class='small-report-column'></th>";

        table += "<th class='table-amount text-center'>CURRENT</th>";
        table += "<th class='table-amount text-center'>0 - ".concat(this.daysRange, "</th>");
        table += "<th class='table-amount text-center'>".concat(this.daysRange + 1, " - ").concat(this.daysRange * 2, "</th>");
        table += "<th class='table-amount text-center'>".concat(this.daysRange * 2 + 1, " - ").concat(this.daysRange * 3, "</th>");
        table += "<th class='table-amount text-center'>".concat(this.daysRange * 3 + 1, " ></th>");
        table += "<th class='table-amount text-center'>TOTAL</th></tr></thead><tbody>";
        this.get('apAgingSummaryData.vendors').forEach(function (vendor) {
          if (!(_this4.get('hideCleanBalance') && (vendor.total === 0 || vendor.total === "0.0"))) {
            table += "<tr><td class='small-report-column'>".concat(vendor.name, "</td>");
            table += "<td class='small-report-column'>".concat(parseFloat(vendor.current).toFixed(2), "</td>");
            table += "<td class='small-report-column'>".concat(parseFloat(vendor.first_column).toFixed(2), "</td>");
            table += "<td class='small-report-column'>".concat(parseFloat(vendor.second_column).toFixed(2), "</td>");
            table += "<td class='small-report-column'>".concat(parseFloat(vendor.third_column).toFixed(2), "</td>");
            table += "<td class='small-report-column'>".concat(parseFloat(vendor.fourth_column).toFixed(2), "</td>");
            table += "<td class='small-report-column'>".concat(parseFloat(vendor.total).toFixed(2), "</td></tr>");
          }
        });
        table += "<tr><td class='small-report-column'>TOTAL</td>";
        table += "<td class='table-amount'>".concat(parseFloat(this.get('apAgingSummaryData.totals.current')).toFixed(2), "</td>");
        table += "<td class='table-amount'>".concat(parseFloat(this.get('apAgingSummaryData.totals.first_column')).toFixed(2), "</td>");
        table += "<td class='table-amount'>".concat(parseFloat(this.get('apAgingSummaryData.totals.second_column')).toFixed(2), "</td>");
        table += "<td class='table-amount'>".concat(parseFloat(this.get('apAgingSummaryData.totals.third_column')).toFixed(2), "</td>");
        table += "<td class='table-amount'>".concat(parseFloat(this.get('apAgingSummaryData.totals.fourth_column')).toFixed(2), "</td>");
        table += "<td class='table-amount'>".concat(parseFloat(this.get('apAgingSummaryData.totals.total')).toFixed(2), "</td></tr></tbody></table>");
        Ember.$('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        Ember.$('#apAgingSummaryTable').table2CSV({
          delivery: 'download',
          filename: 'ApAgingSummaryTable.csv'
        });
        Ember.$('#apAgingSummaryTable').remove();
        Ember.$('#csvExport').hide();
      },
      back: function back() {
        this.set('showFullDetail', false);
      }
    }
  });

  _exports.default = _default;
});