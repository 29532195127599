define("account-berry-frontend/confirmed/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      didTransition: function didTransition() {
        var _this = this;

        Ember.run.later(function () {
          _this.transitionTo('application');
        }, 4000);
      }
    }
  });

  _exports.default = _default;
});