define("account-berry-frontend/components/resizable-columns/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['resizable-column', 'unselectable', 'col-md-8', 'col-md-push-2'],
    didInsertElement: function didInsertElement() {
      var pressed = false;
      var start, startX, startWidth;
      Ember.$("table tr").mousedown(function (e) {
        start = Ember.$("table th");
        pressed = true;
        startX = e.pageX;
        startWidth = Ember.$("table th").width();
        Ember.$(start).addClass("resizing");
        Ember.$('table').addClass("resize");
      });
      Ember.$(document).mousemove(function (e) {
        if (pressed) {
          Ember.$(start).width(startWidth + (e.pageX - startX));
        }
      });
      Ember.$(document).mouseup(function () {
        if (pressed) {
          Ember.$(start).removeClass("resizing");
          pressed = false;
        }
      });
    }
  });

  _exports.default = _default;
});