define("account-berry-frontend/components/tax-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service('notify'),
    init: function init() {
      var _this = this;

      this._super();

      if (!this.get('tax')) {
        this.set('tax', {});
      }

      this.store.findAll('account').then(function (accounts) {
        _this.set('accounts', accounts.filter(function (account) {
          return account.get('account_type.name') === "Current Liability";
        }));

        if (_this.get('tax.account_id')) {
          _this.set('account', _this.store.peekRecord('account', _this.get('tax.account_id')));
        }
      });
      this.store.findAll('payee').then(function (payees) {
        _this.set('payees', payees);
      });
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['customers'];
    }),
    actions: {
      create: function create() {
        var _this2 = this;

        this.set('tax.company_id', this.companyId);
        var newTax = this.store.createRecord('tax', this.tax); // this.set('tax', {});

        newTax.save().then(function () {
          _this2.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Tax Created</span></div>'
          });

          _this2.sendAction('reloadRoute');

          _this2.get('router').transitionTo('companies.show.customers.taxes', _this2.companyId);
        }, function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          newTax.rollbackAttributes();

          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      update: function update() {
        var _this3 = this;

        this.store.findRecord('tax', this.tax.id, {
          backgroundReload: false
        }).then(function (tax) {
          tax.setProperties(_this3.tax);
          tax.save().then(function () {
            _this3.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Tax Updated</span></div>'
            });

            _this3.get('router').transitionTo('companies.show.customers.taxes', _this3.companyId);
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this3.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          });
        });
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      deleteTax: function deleteTax() {
        var _this4 = this;

        this.tax.destroyRecord().then(function () {
          _this4.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Tax Deleted</span></div>"
          });

          _this4.sendAction('reloadRoute');

          _this4.get('router').transitionTo('companies.show.customers.taxes', _this4.companyId);
        });
      },
      setAccount: function setAccount(id) {
        this.set('tax.account_id', id);
      },
      setVendor: function setVendor(vendor) {
        this.set('tax.vendor_id', vendor.id);
      },
      cancel: function cancel() {
        if (Ember.typeOf(this.get('tax')) === "instance") {
          this.get('tax').rollbackAttributes();
        }

        this.get('router').transitionTo('companies.show.customers.taxes', this.companyId);
      }
    }
  });

  _exports.default = _default;
});