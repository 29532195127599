define("account-berry-frontend/components/payroll-form/component", ["exports", "ember-cli-pagination/computed/paged-array", "account-berry-frontend/config/environment"], function (_exports, _pagedArray, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    refNumber: Ember.inject.service(),
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    numFormat: Ember.inject.service(),
    numberToWords: Ember.inject.service(),
    earningsTab: true,
    deductionsTab: false,
    reversedSort: false,
    init: function init() {
      var _this = this;

      this._super();

      if (!this.payroll) {
        this.set('payroll', {});
      } else {
        this.set('payroll.cash_account', {
          id: this.get('payroll.cash_account_id')
        });
      }

      if (this.get('payroll.recurrency')) {
        this.set('payroll.recurrency.active', true);
      }

      var importChannel = this.get('session.pusher').subscribe("employee-hours-import-channel-".concat(this.get('session.data.authenticated.id')));
      importChannel.bind('import', function (response) {
        if (_this.isDestroyed) {
          return;
        } else {
          if (response.error) {
            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(response.error, "</span></div>")
            });

            _this.set('reviewing', false);
          } else {
            _this.store.findRecord('json-storage', response.json_storage_id).then(function (jsonStorage) {
              _this.sendAction('toggleShowLoading', true, false);

              _this.set('employeeHourData', jsonStorage.get('stored_json.data'));

              _this.set('importModal', false);
            });
          }
        }
      });
      var payrollChannel = this.get('session.pusher').subscribe("payroll-channel-".concat(this.get('session.data.authenticated.id')));
      payrollChannel.bind('payroll', function (response) {
        if (_this.isDestroyed) {
          return;
        } else {
          _this.store.findRecord('json-storage', response.json_storage_id).then(function (jsonStorage) {
            _this.sendAction('toggleShowLoading', true, false);

            if (jsonStorage.get('stored_json.errors')) {
              _this.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(jsonStorage.get('stored_json.errors'), "</span></div>")
              });
            } else {
              _this.set('payroll', jsonStorage.get('stored_json.payroll'));

              _this.sendAction('reloadRoute');

              if (_this.get('paystub')) {
                _this.set('confirmPrint', false);

                _this.send('print', _this.get('paystub'), _this.get('blankCheck'));
              } else {
                _this.get('notify').success({
                  html: "<i class=\"icon-checkmark lg\"></i> <div><strong>Success: </strong>&nbsp<span>Payroll ".concat(_this.get('newPayroll') ? "Created" : "Updated", "</span></div>")
                });

                if (_this.accountId) {
                  _this.get('router').transitionTo('companies.show.accounts.transactions', _this.companyId, _this.accountId);
                } else if (_this.reconciliationId) {
                  if (_this.reconciliationId === "new") {
                    _this.get('router').transitionTo('companies.show.reconciliations.new', _this.companyId);
                  } else {
                    _this.get('router').transitionTo('companies.show.reconciliations.show', _this.companyId, _this.reconciliationId);
                  }
                } else {// this.get('router').transitionTo('companies.show.checks', this.companyId, { queryParams: { show: true }});
                } // this.get('router').transitionTo('companies.show.employees.payrolls', this.companyId);

              }
            }
          });
        }
      });
    },
    pagedPayrollChecks: (0, _pagedArray.default)('payroll.payroll_checks', {
      infinite: "unpaged",
      page: 1,
      perPage: 200
    }),
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this.store.findAll('account', {
        reload: true
      }).then(function (accounts) {
        var filteredAccounts = accounts.filter(function (account) {
          if (account.get('account_type.name').toLowerCase() === 'cash' && !account.get('is_title')) {
            return account;
          }
        });

        _this2.set('cashAccounts', filteredAccounts);
      });
      this.store.findRecord('payroll-setting', 1).then(function (payrollSetting) {
        _this2.set('payrollSettings', payrollSetting);
      });
      this.store.findAll('employee', {
        reload: true
      }).then(function (employees) {
        _this2.set('employees', employees);

        if (_this2.get('payroll.payroll_checks')) {
          _this2.setPayrollChecks();
        }
      });
      this.store.findAll('label', {
        reload: true
      }).then(function (labels) {
        _this2.set('labels', labels);
      });
      this.store.findAll('payroll-internal-setting', {
        reload: true
      }).then(function (payrollInternalSettings) {
        _this2.set('payrollInternalSettings', payrollInternalSettings);
      });
    },
    errorHandler: function errorHandler(response) {
      var errors = "An Unkown Error Occured.";

      try {
        if (response.errors && response.errors[0].status !== "500") {
          errors = response.errors.map(function (error) {
            return error;
          });
        } else if (response.payload.errors) {
          errors = response.payload.errors.map(function (error) {
            return error;
          });
        }
      } catch (error) {}

      this.get('notify').alert({
        html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
      });
    },
    generateCheckNumbers: function generateCheckNumbers() {
      var _this3 = this;

      var ajaxOptions = {
        url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/checks/number"),
        method: 'GET',
        contentType: 'application/json',
        successCallback: function successCallback(response) {
          var lastCheckNumber = response.last_check_number;

          if (_this3.get('payroll.payroll_checks')) {
            _this3.get('payroll.payroll_checks').forEach(function (pc) {
              var checkNumber = _this3.get('refNumber').generate(lastCheckNumber || "");

              Ember.set(pc, 'check_number', checkNumber);
              lastCheckNumber = checkNumber;
            });

            _this3.set('pagedPayrollChecks', (0, _pagedArray.default)('payroll.payroll_checks', {
              infinite: "unpaged",
              page: 1,
              perPage: 200
            }));
          }
        },
        errorCallback: function errorCallback(response) {
          _this3.errorHandler(response);
        }
      };
      this.get('ajaxCall').request(ajaxOptions);
    },
    employeesObserver: Ember.observer('employees', function () {
      if (this.get('employees') && this.get('payroll.payroll_checks')) {
        this.setPayrollChecks();
      }
    }),
    setPayrollChecks: function setPayrollChecks(dateChanged) {
      var _this4 = this;

      var payrollChecks = this.get('payroll.payroll_checks') && !dateChanged ? this.get('payroll.payroll_checks') : []; // Run if begin and pay dates are set

      if (this.get('payroll.begin_date') && this.get('payroll.pay_date') && this.get('employees')) {
        this.get('employees').forEach(function (employee) {
          // Skip if employee is terminated
          if (!employee.get('terminated')) {
            var employeeInPayrollCheck = false;

            if (_this4.get('payroll.payroll_checks') && !dateChanged) {
              employeeInPayrollCheck = _this4.get('payroll.payroll_checks').find(function (payrollCheck) {
                return payrollCheck.employee_id.toString() === employee.id.toString();
              });
            } // If employee has rates and is not already in payroll checks


            if (employee.get('employee_rates') && !employeeInPayrollCheck) {
              // Get employee rates where date is less or equal to payroll begin date
              var employeeRates = employee.get('employee_rates').filter(function (employeeRate) {
                return new Date(employeeRate.date) <= new Date(_this4.get('payroll.begin_date'));
              });
              var employeeRate; // If there is any employee rate where date is less or equal to payroll begin date
              // Then sort employee rates by date and set the employeeRate to the last one.

              if (employeeRates) {
                employeeRates = employeeRates.sort(function (a, b) {
                  return new Date(a.date) - new Date(b.date);
                });
                employeeRate = employeeRates[employeeRates.length - 1];
              }

              payrollChecks.push({
                id: employee.id,
                employee_id: employee.id,
                employee: employee,
                employee_rate_id: employeeRate ? employeeRate.id : null,
                employee_rate: employeeRate,
                labels: _this4.get('newPayroll') && employeeRate ? employeeRate.labels : [],
                earnings_amount: 0,
                deductions_amount: 0,
                calculate_state_income_tax: true,
                calculate_federal_income_tax: true,
                calculate_other_income_tax: true,
                custom_disability: false
              });
            }
          }
        });

        if (dateChanged) {
          this.generateCheckNumbers();
        }
      }

      this.set('payrollChecks', payrollChecks);
      this.set('payroll.payroll_checks', payrollChecks);
      this.set('pagedPayrollChecks', (0, _pagedArray.default)('payroll.payroll_checks', {
        infinite: "unpaged",
        page: 1,
        perPage: 200
      }));
    },
    payrollChecksObserver: Ember.observer('payroll.payroll_checks', function () {
      this.setPayrollChecks();
      this.set('pagedPayrollChecks', (0, _pagedArray.default)('payroll.payroll_checks', {
        infinite: "unpaged",
        page: 1,
        perPage: 200
      }));
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['employees'];
    }),
    anyEarnings: function anyEarnings(payrollCheck) {
      var earnings = [!!payrollCheck.regular_hours && payrollCheck.regular_hours !== 0, !!payrollCheck.overtime_hours && payrollCheck.overtime_hours !== 0, !!payrollCheck.vacation_hours && payrollCheck.vacation_hours !== 0, !!payrollCheck.sick_hours && payrollCheck.sick_hours !== 0, !!payrollCheck.bonus && payrollCheck.bonus !== 0, !!payrollCheck.allowances && payrollCheck.allowances !== 0, !!payrollCheck.commission && payrollCheck.commission !== 0, !!payrollCheck.tips && payrollCheck.tips !== 0, !!payrollCheck.reimbursements && payrollCheck.reimbursements !== 0, !!payrollCheck.other_taxable_earnings_one && payrollCheck.other_taxable_earnings_one !== 0, !!payrollCheck.other_taxable_earnings_two && payrollCheck.other_taxable_earnings_two !== 0, !!payrollCheck.other_taxable_earnings_three && payrollCheck.other_taxable_earnings_three !== 0, !!payrollCheck.other_taxable_earnings_four && payrollCheck.other_taxable_earnings_four !== 0, !!payrollCheck.other_taxable_earnings_five && payrollCheck.other_taxable_earnings_five !== 0, !!payrollCheck.other_non_taxable_earnings_one && payrollCheck.other_non_taxable_earnings_one !== 0, !!payrollCheck.other_non_taxable_earnings_two && payrollCheck.other_non_taxable_earnings_two !== 0, !!payrollCheck.other_non_taxable_earnings_three && payrollCheck.other_non_taxable_earnings_three !== 0, !!payrollCheck.other_non_taxable_earnings_four && payrollCheck.other_non_taxable_earnings_four !== 0, !!payrollCheck.other_non_taxable_earnings_five && payrollCheck.other_non_taxable_earnings_five !== 0];
      return earnings.includes(true);
    },
    filterPayrollChecks: function filterPayrollChecks() {
      var _this5 = this;

      return this.get('payroll.payroll_checks').filter(function (payrollCheck) {
        var anyEarnings = _this5.anyEarnings(payrollCheck);

        return payrollCheck.earnings_amount && (anyEarnings || payrollCheck.void);
      });
    },
    maskSsn: function maskSsn(ssn) {
      try {
        var count = ssn.length < 4 ? ssn.length : ssn.length - 4;
        var last4Digits = ssn.substr(count);
        var maskedSsn = '*'.repeat(count) + last4Digits;
        return maskedSsn;
      } catch (error) {// debugger;
      }
    },
    generatePayStub: function generatePayStub(blankCheck) {
      var _this6 = this;

      var cashAccount = this.store.peekRecord('account', this.get('payroll.cash_account_id'));
      var payrollChecks = this.filterPayrollChecks();
      var payroll = "<style>\n      @font-face {\n        font-family: 'micr-encoding';\n        src: url(".concat(_environment.default.apiURL, "/micr-encoding.regular.ttf);\n        font-weight: normal;\n        font-style: normal;\n      }\n    </style>"); // sort by check_number

      payrollChecks = payrollChecks.sort(function (a, b) {
        return (a.check_number || "").localeCompare(b.check_number || "", undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      payrollChecks.forEach(function (payrollCheck) {
        // Validation for use case when only wanting to print a specific employee's paystub
        var printPayrollCheck = !_this6.get('employeeId');

        if (_this6.get('employeeId') && payrollCheck.employee_id === _this6.get('employeeId')) {
          printPayrollCheck = true;
        } // 


        if (printPayrollCheck) {
          var employee = _this6.store.peekRecord('employee', payrollCheck.employee_id);

          var earningsAmount = payrollCheck.earnings_amount ? parseFloat(payrollCheck.earnings_amount) : 0;
          var reimbursementsAmount = payrollCheck.reimbursements ? parseFloat(payrollCheck.reimbursements) : 0;
          var deductionsAmount = payrollCheck.deductions_amount ? parseFloat(payrollCheck.deductions_amount) : 0;
          var netAmount = earningsAmount + reimbursementsAmount - deductionsAmount; // let netAmount = earningsAmount - deductionsAmount; #This change was due to a net in check print from the company Compás Consulting Group (dquezada@compascg.com) 

          payroll += "<div style='font-family: Verdana; width: 100%; font-size: 12px; margin-top: 1em; page-break-after: always;'>"; // Check

          payroll += "<div style='width: 100%;'></div>";

          var company = _this6.store.peekRecord('company', _this6.get('companyId'));

          if (blankCheck) {
            payroll += "<div style=\"display: flex; justify-content: space-between; margin-bottom: -4em;\">";
            payroll += "<div style=\"display: flex; align-items: center; width: 30%;\">";

            if (company.get('picture.url')) {
              payroll += "<img style=\"width: 15%; margin-right: 1em;\" src=".concat(company.get('picture.url'), ">");
            }

            payroll += "\n              <div>\n                <b>".concat(company.get('name'), "</b>\n                <br>\n                ").concat(company.get('postal_address_line_one') || "", "\n                <br>\n                ").concat(company.get('postal_address_line_two') || "", "\n                <br>\n                ").concat(company.get('postal_address_city') || "", ",\n                ").concat(company.get('postal_address_state') || "", ",\n                ").concat(company.get('postal_address_zip') || "", "\n                <br>\n                ").concat(company.get('phone') || "", "\n              </div>\n            </div>");
            payroll += "<div style=\"text-align: center; width: 30%;\"><strong>".concat(cashAccount.get('bank_name') || "", " <br> ").concat(cashAccount.get('bank_address') || "", "</strong></div>");
            payroll += "<div style='text-align: right; width: 30%;'><strong>No.</strong> ".concat(payrollCheck.check_number ? payrollCheck.check_number.replace(/\D/g, '') : "", "</div>");
            payroll += "</div>";
          }

          payroll += "<div style='display:flex; justify-content: flex-end; margin-bottom: ".concat(blankCheck ? "1em" : "3em", "; margin-top: ").concat(blankCheck ? "1.5em" : "3em", "; margin-right: 2em'><span>").concat(blankCheck ? "<strong>DATE:</strong> " : "").concat(moment(_this6.get('payroll.pay_date')).format('MM/DD/YYYY'), "</span></div>");

          if (blankCheck) {
            payroll += "<div style='display:flex; justify-content: flex-end; margin-bottom: 1em; margin-right: 2em'><span>".concat(cashAccount.get('check_message') || "", "</span></div>");
          }

          payroll += "<div style='display: flex; justify-content: space-between; margin-bottom: 1.5em;'>";
          payroll += "<span style='margin-left: ".concat(blankCheck ? '' : '5em', ";'> ").concat(blankCheck ? "<strong style='margin-right: 1em;'>Pay To The <br> Order Of:</strong> " : "").concat(employee.get('name'), "</span>"); // Number Amount

          payroll += "<span style='margin-right: 2.5em;'>".concat(blankCheck ? "<strong>AMOUNT: $</strong> " : "", "**").concat(parseFloat(netAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "*</span></div>");
          payroll += "<span>*".concat(_this6.get('numberToWords').english(parseFloat(netAmount).toFixed(2), 'fraction', true), " ").concat(blankCheck ? "DOLLARS" : "", "</span>"); // payroll += `<span>*${$('.text-amount-' + payrollCheck.employee_id).text().trim().toUpperCase()} ${blankCheck ? "DOLLARS" : ""}</span>`;

          payroll += "<div style='margin-top: ".concat(blankCheck ? '4em' : '7em', "; margin-left: 3em; display: flex; justify-content: space-between;'><span style='").concat(blankCheck ? "border-bottom: 1px solid black;" : "", "'>Pay Period ").concat(moment(_this6.get('payroll.begin_date')).format('MM/DD/YYYY'), " - ").concat(moment(_this6.get('payroll.end_date')).format('MM/DD/YYYY'), "</span>");
          payroll += "".concat(blankCheck ? "<span style='width: 30%; border-bottom: 1px solid black'></span>" : "", "</div>");

          if (blankCheck) {
            // payroll += `<div style='margin-top: 2em; margin-left: -10em; text-align: center; font-family: micr-encoding; font-size: 24px;'>C${payrollCheck.check_number ? payrollCheck.check_number.replace(/\D/g,'') : ""}C A${cashAccount.get('routing_number') || ''} A${cashAccount.get('account_number') || ''}C</div>`;
            payroll += "<div style='margin-top: 2em; text-align: center; font-family: micr-encoding; font-size: 24px;'>A".concat(cashAccount.get('routing_number') || '', "A ").concat(cashAccount.get('account_number') || '', "C ").concat(payrollCheck.check_number ? payrollCheck.check_number.replace(/\D/g, '') : "", "</div>"); // Chrome update fix
            // payroll += `<div style='margin-top: 2em; margin-left: -10em; text-align: center; font-family: micr-encoding; font-size: 24px;'>A${cashAccount.get('routing_number') || ''}A ${cashAccount.get('account_number') || ''}C ${payrollCheck.check_number ? payrollCheck.check_number.replace(/\D/g,'') : ""}</div>`; // original
          } // Check Content


          payroll += "<div style='margin-top: ".concat(blankCheck ? '4em' : '9em', "; height: 62%;'>"); // Bottom Sections

          var section = "<div style='height: 50%; width: 96%; margin-left: 1em; display: flex;'>"; //   // Employee Info
          // section += `<div style='width: 30%>`;
          // section += `<span>Check Number #: ${payrollCheck.check_number}</span>`;
          // section += `<span>${employee.get('name')}</span>`;
          // section += `<span>${employee.get('social_security')}</span>`;
          // section += `<span>${parseFloat(payrollCheck.employee_rate.regular_rate).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</span>`;
          // section += '</div>';
          // Payroll Check Stub Data

          section += "<table style=\"font-size: 0.8vh; width: 100%;\">";
          section += "<thead><th style='text-align: left;' colspan=\"2\">Employee</th><th style='text-align: center;'>".concat(company.get('name'), "</th><th style='text-align: left;'>Description</th><th style='text-align: right;'>Current</th><th style='text-align: right;'>YTD Amount</th></thead>");
          section += "<tbody>";
          section += "<tr><td colspan=\"3\">".concat(employee.get('name'), "</td><td>Regular Amount</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.regular_amount || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.ytd.regular_amount || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
          section += "<tr><td colspan=\"3\">".concat(_this6.maskSsn(employee.get('social_security')), "</td><td>Overtime Amount</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.overtime_amount || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.ytd.overtime_amount || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
          section += "<tr><td colspan=\"3\">Check Date: ".concat(moment(_this6.get('payroll.pay_date')).format('MM/DD/YYYY'), "</td><td>Vacation Amount</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.vacation_amount || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.ytd.vacation_amount || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
          section += "<tr><td colspan=\"3\">Check Number #: ".concat(payrollCheck.check_number || "", "</td><td>Sick Amount</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.sick_amount || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.ytd.sick_amount || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
          section += "<tr><td colspan=\"3\">Pay Period: ".concat(moment(_this6.get('payroll.begin_date')).format('MM/DD/YYYY'), " - ").concat(moment(_this6.get('payroll.end_date')).format('MM/DD/YYYY'), "</td><td>Other Income</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.other_income || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.ytd.other_income || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
          section += "<tr><td colspan=\"3\"></td><td>Reimbursements</td><td style='text-align: right;'>".concat(parseFloat(payrollCheck.reimbursements || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.ytd.reimbursements || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
          section += "<tr><td><strong>Type</strong></td><td><strong>Rate</strong></td><td style=\"text-align: right; padding-right: 40em;\"><strong>Hours</strong></td>";
          section += "<td>Other Taxable Income</td><td style='text-align: right;'>".concat(parseFloat(payrollCheck.other_taxable_income || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.ytd.other_taxable_income || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
          section += "<tr><td>Regular</td><td>".concat(parseFloat(payrollCheck.employee_rate.regular_rate).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "/hr</td><td style=\"text-align: right; padding-right: 40em;\">").concat(parseFloat(payrollCheck.regular_hours || 0), "</td>");
          section += "<td>Other Non Taxable Income</td><td style='text-align: right;'>".concat(parseFloat(payrollCheck.other_non_taxable_income || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.ytd.other_non_taxable_income || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
          section += "<tr><td>Overtime</td><td>".concat(parseFloat(payrollCheck.employee_rate.overtime_rate).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "/hr</td><td style=\"text-align: right; padding-right: 40em;\">").concat(parseFloat(payrollCheck.overtime_hours || 0), "</td>");
          section += "<td>Income Tax</td><td style='text-align: right;'>".concat(parseFloat(payrollCheck.income_tax_total ? payrollCheck.income_tax_total * -1 : 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.ytd.income_tax * -1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
          section += "<tr><td>Vacation</td><td>".concat(parseFloat(payrollCheck.employee_rate.regular_rate).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "/hr</td><td style=\"text-align: right; padding-right: 40em;\">").concat(parseFloat(payrollCheck.vacation_hours || 0), "</td>");
          section += "<td>Social Security</td><td style='text-align: right;'>".concat(parseFloat(payrollCheck.social_security_total ? payrollCheck.social_security_total * -1 : 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.ytd.social_security * -1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
          section += "<tr><td>Sickness</td><td>".concat(parseFloat(payrollCheck.employee_rate.regular_rate).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "/hr</td><td style=\"text-align: right; padding-right: 40em;\">").concat(parseFloat(payrollCheck.sick_hours || 0), "</td>");
          section += "<td>Medicare</td><td style='text-align: right;'>".concat(parseFloat(payrollCheck.medicare_total ? payrollCheck.medicare_total * -1 : 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.ytd.medicare * -1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
          section += "<tr><td colspan=\"3\"></td><td>Disability</td><td style='text-align: right;'>".concat(parseFloat(payrollCheck.disability ? payrollCheck.disability * -1 : 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.ytd.disability * -1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
          section += "<tr><td colspan=\"3\"></td><td>Chauffeur Insurance</td><td style='text-align: right;'>".concat(parseFloat(payrollCheck.chauffeur_insurance ? payrollCheck.chauffeur_insurance * -1 : 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.ytd.chauffeur_insurance * -1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
          section += "<tr><td colspan=\"3\"></td><td>Medical Plan</td><td style='text-align: right;'>".concat(parseFloat(payrollCheck.medical_plan ? payrollCheck.medical_plan * -1 : 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.ytd.medical_plan * -1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
          section += "<tr><td colspan=\"3\"></td><td>Child Support</td><td style='text-align: right;'>".concat(parseFloat(payrollCheck.child_support ? payrollCheck.child_support * -1 : 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.ytd.child_support * -1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
          section += "<tr><td colspan=\"3\"></td><td>Garnishment</td><td style='text-align: right;'>".concat(parseFloat(payrollCheck.garnishment ? payrollCheck.garnishment * -1 : 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.ytd.garnishment * -1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
          section += "<tr><td colspan=\"3\"></td><td>Other Deductions</td><td style='text-align: right;'>".concat(parseFloat(payrollCheck.other_deductions_total ? payrollCheck.other_deductions_total * -1 : 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td><td style='text-align: right;'>").concat(parseFloat(payrollCheck.ytd.other_deductions * -1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
          section += "<tr><td colspan=\"3\"></td><td><strong>Net Pay</strong></td><td style='text-align: right;'><strong>".concat(parseFloat(netAmount || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</strong></td><td style='text-align: right;'><strong>").concat(parseFloat(payrollCheck.ytd.net_amount || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</strong></td></tr>");
          section += "</tbody></table></div>";
          payroll += section;
          payroll += "<div style='width: 100%; margin-top: 8em;'>"; // payroll += `<div style='position: absolute; width: 100%; margin-top: 8em;'>`;

          payroll += section;
          payroll += "</div></div></div>";
        }
      });
      return payroll;
    },
    print: function print(table) {
      try {
        Ember.$('.print-container').empty();
        Ember.$('.print-container').append(table); // let w = window.open();
        // w.document.write("<div id='print-container' style=></div>");
        // w.document.getElementById('print-container').innerHTML += table;

        Ember.$('.print-container').printThis({
          importCSS: false
        }); // setTimeout(function() {
        //   w.print();
        // w.close();
        // }, 300);
      } catch (error) {
        this.get('notify').alert({
          html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Please make sure you enable popups for AccountBerry.</span></div>"
        });
      }
    },
    setPayrollChecksFromImport: Ember.observer('employeeHourData', function () {
      var payrollChecks = this.get('payroll.payroll_checks');
      this.get('employeeHourData').forEach(function (data) {
        payrollChecks.forEach(function (payrollCheck, index) {
          if (data.employee_id && payrollCheck.employee_id === data.employee_id.toString()) {
            payrollChecks[index].regular_hours = data.regular_hours ? parseFloat(data.regular_hours) : "";
            payrollChecks[index].overtime_hours = data.overtime_hours ? parseFloat(data.overtime_hours) : "";
            payrollChecks[index].vacation_hours = data.vacation_hours ? parseFloat(data.vacation_hours) : "";
            payrollChecks[index].sick_hours = data.sick_hours ? parseFloat(data.sick_hours) : "";
            payrollChecks[index].bonus = data.bonus ? parseFloat(data.bonus) : "";
            payrollChecks[index].allowances = data.allowances ? parseFloat(data.allowances) : "";
            payrollChecks[index].commission = data.commission ? parseFloat(data.commission) : "";
            payrollChecks[index].tips = data.tips ? parseFloat(data.tips) : "";
            payrollChecks[index].reimbursements = data.reimbursements ? parseFloat(data.reimbursements) : "";
          }
        });
      });
      this.set('payroll.payroll_checks', payrollChecks);
      this.set('pagedPayrollChecks', (0, _pagedArray.default)('payroll.payroll_checks', {
        infinite: "unpaged",
        page: 1,
        perPage: 200
      }));
    }),
    searchEmployee: Ember.observer('search', function () {
      var search = this.search;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      if (search === "" || search.trim().length === 0) {
        this.set('filteredPayrollChecks', this.get('payrollChecks'));
      } else {
        this.set('filteredPayrollChecks', this.get('payrollChecks').filter(function (payrollCheck) {
          var employee = JSON.parse(JSON.stringify(payrollCheck.employee));
          var employeeValue = employee.first_name + " " + employee.last_name;

          if (employeeValue) {
            if (isNaN(employeeValue)) {
              employeeValue = employeeValue.toLowerCase();
            }

            if (employeeValue.includes(search)) {
              return payrollCheck;
            }
          }
        }));
      }

      this.set('pagedPayrollChecks', (0, _pagedArray.default)('filteredPayrollChecks', {
        infinite: "unpaged",
        page: 1,
        perPage: 200
      }));
    }),
    actions: {
      setPayrollChecks: function setPayrollChecks() {
        this.setPayrollChecks(true);
      },
      updateCashAccount: function updateCashAccount(value) {
        this.set('payroll.cash_account_id', value);
      },
      showEarningsTab: function showEarningsTab() {
        this.setProperties({
          'earningsTab': true,
          'deductionsTab': false,
          'totalsTab': false
        });
      },
      showDeductionsTab: function showDeductionsTab() {
        this.setProperties({
          'earningsTab': false,
          'deductionsTab': true,
          'totalsTab': false
        });
      },
      showTotalsTab: function showTotalsTab() {
        this.setProperties({
          'earningsTab': false,
          'deductionsTab': false,
          'totalsTab': true
        });
      },
      setRecurrency: function setRecurrency(recurrency) {
        if (recurrency.active) {
          this.set('payrollRecurrency', recurrency);
        } else {
          this.set('payrollRecurrency', null);
        }
      },
      create: function create() {
        var _this7 = this;

        var payrollChecks = this.filterPayrollChecks();
        this.set('payroll.employees', payrollChecks);
        this.set('payroll.company_id', this.get('companyId'));
        this.set('payroll.recurrency', this.get("payrollRecurrency"));
        var data = JSON.stringify({
          payroll: this.get('payroll')
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/payrolls"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            // this.sendAction('toggleShowLoading', true, true);
            _this7.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success: </strong>&nbsp<span>Payroll Created</span></div>'
            });

            _this7.sendAction('reloadRoute');

            _this7.get('router').transitionTo('companies.show.employees.payrolls', _this7.companyId);
          },
          errorCallback: function errorCallback(response) {
            var employeeTerminated = false;

            _this7.get('payroll.employees').forEach(function (pc) {
              if (pc.employee.terminated) {
                employeeTerminated = true;
              }
            });

            if (employeeTerminated && paystub) {
              _this7.send('print', paystub, blankCheck);
            } else {
              _this7.errorHandler(response);
            }
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      update: function update(paystub, blankCheck) {
        var _this8 = this;

        var payrollChecks = this.filterPayrollChecks();
        this.set('payroll.employees', payrollChecks);
        this.set('payroll.company_id', this.get('companyId'));
        this.set('payroll.recurrency', this.get("payrollRecurrency"));
        var data = JSON.stringify({
          payroll: this.get('payroll')
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/payrolls/").concat(this.get('payroll.id')),
          method: 'PUT',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            _this8.setProperties({
              'paystub': paystub,
              'blankCheck': blankCheck
            });

            _this8.sendAction('toggleShowLoading', true, true); // this.get('notify').success({ html: '<i class="icon-checkmark lg"></i> <div><strong>Success: </strong>&nbsp<span>Payroll Created</span></div>' });
            // this.sendAction('reloadRoute');
            // this.get('router').transitionTo('companies.show.employees.payrolls', this.companyId);

          },
          errorCallback: function errorCallback(response) {
            var employeeTerminated = false;

            _this8.get('payroll.employees').forEach(function (pc) {
              if (pc.employee.terminated) {
                employeeTerminated = true;
              }
            });

            if (employeeTerminated && paystub) {
              _this8.send('print', paystub, blankCheck);
            } else {
              _this8.errorHandler(response);
            }
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      loadNext: function loadNext() {
        this.get('pagedPayrollChecks').loadNextPage();
      },
      cancel: function cancel() {
        this.get('router').transitionTo('companies.show.employees.payrolls', this.companyId);
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      delete: function _delete() {
        var _this9 = this;

        this.payroll.destroyRecord().then(function () {
          _this9.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Payroll Deleted</span></div>"
          });

          _this9.sendAction('reloadRoute');

          _this9.get('router').transitionTo('companies.show.employees.payrolls', _this9.companyId);
        });
      },
      print: function print(paystub, blankCheck) {
        var _this10 = this;

        if (!this.get('userPermissions.delete')) {
          this.store.findRecord('payroll', this.get('payroll.id'), {
            reload: true
          }).then(function (payroll) {
            _this10.set('payroll', payroll);

            _this10.print(_this10.generatePayStub(blankCheck));
          });
        } else {
          this.print(this.generatePayStub(blankCheck));
        }
      },
      showConfirmPrint: function showConfirmPrint(employeeId) {
        this.set('employeeId', employeeId);
        this.set('confirmPrint', true);
      },
      hideConfirmPrint: function hideConfirmPrint() {
        this.set('confirmPrint', false);
      },
      sortPayrollChecks: function sortPayrollChecks(value) {
        this.set('sortValue', value);

        if (value === this.get('sortValue')) {
          this.toggleProperty('reversedSort');
        }

        var payrollChecks = this.get('payroll.payroll_checks').sortBy(value);

        if (value === "check_number") {
          payrollChecks = payrollChecks.sort(function (a, b) {
            return (a.check_number || "").localeCompare(b.check_number || "", undefined, {
              numeric: true,
              sensitivity: 'base'
            });
          });
        }

        if (this.get('reversedSort')) {
          payrollChecks = payrollChecks.reverse();
        }

        this.set('payroll.payroll_checks', payrollChecks);
        this.set('pagedPayrollChecks', (0, _pagedArray.default)('payroll.payroll_checks', {
          infinite: "unpaged",
          page: 1,
          perPage: 200
        }));
      },
      showImportModal: function showImportModal() {
        this.set('importModal', true);
      },
      hideImportModal: function hideImportModal() {
        this.set('importModal', false);
      },
      importFileAdded: function importFileAdded(file) {
        this.set('importFile', file);
      },
      toggleShowLoading: function toggleShowLoading() {
        this.sendAction('toggleShowLoading');
      },
      downloadImportHoursTemplate: function downloadImportHoursTemplate() {
        var element = document.createElement('a');
        var headers = "employee number,regular hours,overtime hours,vacation hours,sick hours,bonus,allowances,commission,tips,reimbursements";
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(headers));
        element.setAttribute('download', "employee_hours_import_template.csv");
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      },
      importHours: function importHours(type) {
        var _this11 = this;

        var importData = new FormData();
        importData.append("import_file", this.get('importFile'));
        importData.append("type", type);
        importData.append("begin_date", this.get('payroll.begin_date'));

        var _this$get = this.get('session.data.authenticated'),
            email = _this$get.email,
            token = _this$get.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/payroll_hours_imports"), {
          method: 'POST',
          contentType: false,
          processData: false,
          data: importData,
          headers: headers
        }).then(function (response) {
          // params.successCallback(response);
          _this11.sendAction('toggleShowLoading');

          _this11.set("importFile", null);
        }).catch(function (error) {
          _this11.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
          });
        });
      }
    }
  });

  _exports.default = _default;
});