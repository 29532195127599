define("account-berry-frontend/companies/show/journal-entries/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lpkUM1EV",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"journal-entry-form\",null,[[\"companyId\",\"journalEntry\",\"store\",\"accountId\",\"reconciliationId\",\"reloadRoute\"],[[20,[\"companyId\"]],[20,[\"model\"]],[20,[\"store\"]],[20,[\"accountId\"]],[20,[\"reconciliationId\"]],\"reloadRoute\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/companies/show/journal-entries/show/template.hbs"
    }
  });

  _exports.default = _default;
});