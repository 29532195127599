define("account-berry-frontend/companies/show/accounts/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import ENV from '../../../config/environment';
  var _default = Ember.Route.extend({
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    model: function model() {
      return this.store.findAll('account', {
        reload: true
      }).then(function (accounts) {
        return accounts.toArray();
      });
    },
    afterModel: function afterModel() {// let ajaxOptions = {
      //   url: `${ENV.apiURL}/companies/${this.paramsFor('companies.show').company_id}/accounts`,
      //   method: 'GET',
      //   contentType: 'application/json',
      //   data: { chart_of_accounts: true},
      //   successCallback: () => {},
      //   errorCallback: (e) => {
      //     this.get('notify').alert({ html: `<i class="icon-warning lg"></i> <div class="message-text"><span>An error occured</span></div>` });
      //   }
      // };
      // this.get('ajaxCall').request(ajaxOptions);
    },
    setupController: function setupController(controller) {
      controller.set('breadCrumb', 'Accounts');
      controller.set('companyId', this.paramsFor('companies.show').company_id); // controller.get('applicationController').set('showLoading', true);

      controller.set('analyzedImportData', null); // controller.set('model', this.store.peekAll('account'));
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.accounts', {
        into: 'application',
        model: model
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});