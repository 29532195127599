define("account-berry-frontend/components/select-labels/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectLabels: false,
    didRender: function didRender() {
      var labelsDom = Ember.$("#labels".concat(this.get('index'))).prop("outerHTML"); // tooltip

      Ember.$("#labels".concat(this.get('index'))).tooltip({
        placement: this.get('hoverDirection') ? this.get('hoverDirection') : "bottom",
        trigger: "hover",
        html: true,
        title: labelsDom
      });
    },
    // displayObserver: Ember.observer('allLabels', function() {
    //   let labelsDom = Ember.$(`#labels${this.get('index')}`).prop("outerHTML");
    //   if (labelsDom) {
    //     // tooltip
    //     Ember.$(`#labels${this.get('index')}`).tooltip({
    //       placement: "top",
    //       trigger: "hover",
    //       html: true,
    //       title: labelsDom,
    //     });
    //   }
    // }),
    labels: Ember.computed('transaction', 'allLabels', 'selectLabels', 'filter', 'selectedLabels', function () {
      var _this = this;

      var labels;
      var allLabels = this.store ? this.store.peekAll('label') || this.get('allLabels') : this.get('allLabels');

      if (allLabels) {
        if (this.get('transaction.labels')) {
          labels = allLabels.map(function (label) {
            Ember.set(label, 'selected', !!_this.get('transaction.labels').find(function (l) {
              return l.id.toString() === label.id.toString();
            })); // label.selected = !!this.get('transaction.labels').find(l => l.id.toString() === label.id.toString());

            return label;
          });
        } else {
          labels = allLabels.map(function (label) {
            if (_this.get('filter') && _this.get('selectedLabels')) {
              Ember.set(label, 'selected', !!_this.get('selectedLabels').find(function (l) {
                return l.toString() === label.id.toString();
              }));
            } else {
              Ember.set(label, 'selected', false);
            }

            return label;
          });
        }

        return labels;
      }
    }),
    transactionLabels: Ember.computed('labels', function () {
      if (this.get('labels')) {
        return this.get('labels').filter(function (label) {
          return label.selected;
        });
      }
    }),
    actions: {
      toggleSelectLabels: function toggleSelectLabels() {
        if (!this.get('display')) {
          this.toggleProperty('selectLabels');
        }
      },
      setLabels: function setLabels() {
        var transactionLabels = this.get('labels').filter(function (label) {
          return label.selected;
        });

        if (this.get('filter')) {
          this.sendAction('setLabels', transactionLabels ? transactionLabels.map(function (label) {
            return label.id;
          }) : []);
        } else {
          this.sendAction('setLabels', this.get('index'), transactionLabels, this.get('transaction'));
        }

        this.set('selectLabels', false);
      }
    }
  });

  _exports.default = _default;
});