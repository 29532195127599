define("account-berry-frontend/tax/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    company_id: _emberData.default.attr(),
    item_id: _emberData.default.attr(),
    name: _emberData.default.attr(),
    percentage: _emberData.default.attr(),
    description: _emberData.default.attr(),
    vendor_id: _emberData.default.attr(),
    account_id: _emberData.default.attr(),
    vendor: _emberData.default.attr(),
    undeletable: _emberData.default.attr(),
    account: _emberData.default.attr(),
    percentage_decimal: _emberData.default.attr()
  });

  _exports.default = _default;
});