define("account-berry-frontend/components/landed-cost-form/component", ["exports", "ember-cli-pagination/computed/paged-array", "account-berry-frontend/config/environment"], function (_exports, _pagedArray, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    refNumber: Ember.inject.service(),
    notify: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    landedReceivedItems: [],
    landedAccounts: [],
    pagedReceivedItems: (0, _pagedArray.default)('filteredReceivedItems', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    init: function init() {
      var _this = this;

      this._super();

      if (!this.get('landedCost')) {
        this.set('landedCost', {});
      }

      if (this.newLandedCost) {
        this.generateRefNumber();
      }

      this.store.findAll('bill').then(function () {
        _this.setBills();
      });
      this.store.findAll('payee').then(function (payees) {
        _this.set('payees', payees);
      });
      this.store.findAll('label').then(function (labels) {
        _this.set('labels', labels);
      });
      this.store.findAll('received-item').then(function (receivedItems) {
        _this.set('receivedItems', receivedItems);
      });
      this.store.findRecord('company', this.companyId).then(function (company) {
        _this.set('company', company);
      });
    },
    generateRefNumber: function generateRefNumber() {
      var _this2 = this;

      var ajaxOptions = {
        url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/landed_costs/number"),
        method: 'GET',
        contentType: 'application/json',
        successCallback: function successCallback(response) {
          var refNumber = _this2.get('refNumber').generate(response.last_ref_number || "");

          _this2.set('landedCost.ref_number', refNumber);
        },
        errorCallback: function errorCallback(response) {
          _this2.errorHandler(response);
        }
      };
      this.get('ajaxCall').request(ajaxOptions);
    },
    errorHandler: function errorHandler(response) {
      var errors = "An Unkown Error Occured.";

      if (response.errors && response.errors[0].status !== "500") {
        errors = response.errors.map(function (error) {
          return error;
        });
      } else if (response.payload.errors) {
        errors = response.payload.errors.map(function (error) {
          return error;
        });
      }

      this.get('notify').alert({
        html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
      });
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['vendors'];
    }),
    setBills: function setBills() {
      var _this3 = this;

      if (this.get('landedCost.bill_id')) {
        this.setProperties({
          'bill': this.store.peekRecord('bill', this.get('landedCost.bill_id')),
          'billId': this.get('landedCost.bill_id')
        });
      }

      if (this.get('bill') && this.get('landedCost.bill_id')) {
        if (this.get('landedCost.landed_accounts')) {
          this.get('bill.bill_accounts').forEach(function (billAccount) {
            _this3.get('landedCost.landed_accounts').forEach(function (landedAccount) {
              if (billAccount.id.toString() === landedAccount.bill_account_id.toString()) {
                Ember.set(billAccount, 'landed_amount', landedAccount.amount);
              }
            });
          });
        } else {
          this.set('landedCost.landed_accounts', this.get('bill.bill_accounts').map(function (billAccount) {
            return {
              bill_account_id: billAccount.id
            };
          }));
        }

        this.setupLandedAccounts();
      }
    },
    setupLandedAccounts: function setupLandedAccounts() {
      this.set('landedAccounts', this.get('landedCost.landed_accounts'));
    },
    setupLandedReceivedItems: function setupLandedReceivedItems() {
      this.set('landedReceivedItems', this.get('landedCost.landed_received_items'));
    },
    bill: Ember.computed('landedCost.bill_id', 'landedCost.vendor_id', function () {
      if (this.get('landedCost.bill_id')) {
        return this.store.peekRecord('bill', this.get('landedCost.bill_id'));
      } else {
        return {};
      }
    }),
    filteredReceivedItems: Ember.computed('receivedItems', 'landedCost.landed_received_items', function () {
      var _this4 = this;

      if (this.get('landedCost.landed_received_items') && this.get('receivedItems')) {
        this.get('receivedItems').forEach(function (receivedItem) {
          _this4.get('landedCost.landed_received_items').forEach(function (landedReceivedItem) {
            if (receivedItem.id.toString() === landedReceivedItem.received_item_id.toString()) {
              Ember.set(receivedItem, 'landed_amount', Number(landedReceivedItem.amount));
            }
          });
        });
        this.setupLandedReceivedItems();
      }

      return this.get('receivedItems');
    }),
    filteredReceivedItemsObserver: Ember.observer('filteredReceivedItems', function () {
      this.set('pagedReceivedItems', (0, _pagedArray.default)('filteredReceivedItems', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    billsObserver: Ember.observer('bill.bill_accounts', 'landedCost.landed_accounts', function () {
      this.setBills();
    }),
    bills: Ember.computed('landedCost.vendor_id', 'bill', function () {
      var _this5 = this;

      if (this.get('landedCost.vendor_id')) {
        return this.store.peekAll('bill').filter(function (bill) {
          return bill.get('vendor_id').toString() === _this5.get('landedCost.vendor_id').toString();
        });
      }
    }),
    actions: {
      updateBill: function updateBill(value) {
        this.set('landedCost.bill_id', value);
      },
      updateVendor: function updateVendor(value) {
        this.set('landedCost.vendor_id', value.id);
        this.set('landedCost.bill_id', null);
      },
      updateDate: function updateDate(date) {
        this.set('landedCost.date', date.toISOString().slice(0, 10));
      },
      updateLandedReceivedItem: function updateLandedReceivedItem(receivedItem) {
        var landedReceivedItems = this.get('landedReceivedItems').filter(function (landedReceivedItem) {
          return landedReceivedItem.received_item_id.toString() !== receivedItem.id.toString();
        });

        if (receivedItem.landed_amount) {
          landedReceivedItems.push({
            received_item_id: receivedItem.id,
            amount: Number(receivedItem.landed_amount)
          });
        }

        this.set('landedReceivedItems', landedReceivedItems);
      },
      updateLandedAccount: function updateLandedAccount(billAccount) {
        var landedAccounts = this.get('landedAccounts').filter(function (landedAccount) {
          return landedAccount.bill_account_id.toString() !== billAccount.id.toString();
        });

        if (billAccount.landed_amount) {
          landedAccounts.push({
            bill_account_id: billAccount.id,
            amount: billAccount.landed_amount
          });
        }

        this.set('landedAccounts', landedAccounts);
      },
      create: function create() {
        var _this6 = this;

        this.set('landedCost.received_items', this.get('landedReceivedItems'));
        this.set('landedCost.accounts', this.get('landedAccounts'));
        var landedCostAmount = 0;
        this.get('landedReceivedItems').forEach(function (landedReceivedItem) {
          landedCostAmount += Number(landedReceivedItem.amount);
        });
        this.set('landedCost.amount', landedCostAmount);
        this.set('landedCost.company_id', this.companyId);
        var data = JSON.stringify({
          landedCost: this.get('landedCost')
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/landed_costs"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            _this6.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Landed Cost Created</span></div>'
            });

            _this6.sendAction('reloadRoute');

            _this6.get('router').transitionTo('companies.show.vendors.landed-cost', _this6.companyId);
          },
          errorCallback: function errorCallback(response) {
            _this6.errorHandler(response);
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      update: function update() {
        var _this7 = this;

        this.store.findRecord('landed-cost', this.landedCost.id, {
          backgroundReload: false
        }).then(function (landedCost) {
          landedCost.setProperties(_this7.landedCost);

          _this7.set('landedCost.received_items', _this7.get('landedReceivedItems'));

          _this7.set('landedCost.accounts', _this7.get('landedAccounts'));

          var landedCostAmount = 0;

          _this7.get('landedReceivedItems').forEach(function (landedReceivedItem) {
            landedCostAmount += Number(landedReceivedItem.amount);
          });

          _this7.set('landedCost.amount', landedCostAmount);

          landedCost.save().then(function () {
            _this7.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Landed Cost Updated</span></div>'
            });

            _this7.get('router').transitionTo('companies.show.vendors.landed-cost', _this7.companyId);
          }, function (response) {
            _this7.errorHandler(response);
          });
        });
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      setLabels: function setLabels(index, labels) {
        this.set('landedCost.label_ids', labels.map(function (label) {
          return label.id;
        }));
        this.set('landedCost.labels', labels);
      },
      delete: function _delete() {
        var _this8 = this;

        this.landedCost.destroyRecord().then(function () {
          _this8.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Landed Cost Deleted</span></div>"
          });

          _this8.sendAction('reloadRoute');

          _this8.get('router').transitionTo('companies.show.vendors.landed-cost', _this8.companyId);
        }).catch(function (response) {
          _this8.errorHandler(response);
        });
      },
      cancel: function cancel() {
        if (Ember.typeOf(this.get('landedCost')) === "instance") {
          this.get('landedCost').rollbackAttributes();
        }

        this.get('router').transitionTo('companies.show.vendors.landed-cost', this.companyId);
      }
    }
  });

  _exports.default = _default;
});