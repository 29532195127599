define("account-berry-frontend/companies/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VBnUDVP0",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"company-form\",null,[[\"newCompany\",\"store\",\"reloadRoute\",\"applicationController\"],[true,[20,[\"store\"]],\"reloadRoute\",[20,[\"applicationController\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/companies/new/template.hbs"
    }
  });

  _exports.default = _default;
});