define("account-berry-frontend/utils/loading-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = loadingMessages;

  function loadingMessages() {
    return ["You can invite as many users as you need and set different permissions for each one.", "Loving AccountBerry? Like us on Facebook & follow us on Twitter!", "You can visit www.accountberry.com/videos and watch our video tutorials at any time.", "Don't like the default accounts? No problem! You can edit them or create new accounts.", "You can customize your payroll settings to accomodate to any workflow.", "Did you know you can set landed costs to your items? Visit the company section and try it out!", "Don't like the current theme? You can change to a different flavor via the dropdown option on the top right side of the navigation bar.", "We are processing your data, we should be finishing soon.", "Have any questions or suggestions? You can reach us by sending an email to info@accountberry.com", "We hope you are having berry sweet day!", "Remember you can export your data to csv format at any time.", "Liking AccountBerry? Refer a friend!", "Have a tablet? AccountBerry works in it!", "Don't berry and drive!", "Did you know you can change the language at any time? Try it out on the top right side of the navigation bar.", "You can read our twitter feed on the lower left corner of the menu bar.", "You can collapse or expand the menu bar on the left.", "We are constantly adding new features, tell us what you would like to see!", "Sweetness is our middle name", "You can categorize your transactions using custom labels.", "You can add pictures for your items.", "You can upload your company logo."];
  }
});