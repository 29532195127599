define("account-berry-frontend/reconciliation/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    cash_account_id: _emberData.default.attr(),
    cash_account: _emberData.default.attr(),
    date: _emberData.default.attr(),
    reconciled: _emberData.default.attr(),
    beginning_balance: _emberData.default.attr(),
    ending_balance: _emberData.default.attr(),
    cleared_balance: _emberData.default.attr(),
    difference: _emberData.default.attr(),
    transactions: _emberData.default.attr(),
    cash_account_transactions: _emberData.default.attr(),
    undeletable: _emberData.default.attr(),
    company_id: _emberData.default.attr(),
    last_reconciliation_date: _emberData.default.attr()
  });

  _exports.default = _default;
});