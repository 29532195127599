define("account-berry-frontend/stripe-connect-account/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    connect_account_id: _emberData.default.attr('string'),
    cash_account_id: _emberData.default.attr('number'),
    app_fee_account_id: _emberData.default.attr('number'),
    charges_enabled: _emberData.default.attr('boolean'),
    send_invoice_payments: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});