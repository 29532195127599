define("account-berry-frontend/components/account-form/component", ["exports", "account-berry-frontend/utils/account-type-activities", "account-berry-frontend/config/environment"], function (_exports, _accountTypeActivities, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    notify: Ember.inject.service('notify'),
    accountTypeId: "",
    confirmMerge: false,
    init: function init() {
      this._super();

      if (!this.get('account')) {
        this.set('account', {});
      }

      this.setAccountTypes();
      $(function () {
        $('[data-toggle="popover"]').popover({
          html: true
        });
      });
    },
    cashAccountFee: Ember.computed('companyId', function () {
      var company = this.store.peekRecord('company', this.companyId);
      return company.get('stripe_plan_id') === _environment.default.stripe_plan_id.bronze_plan;
    }),
    company: Ember.computed('companyId', function () {
      var company = this.store.peekRecord('company', this.companyId);
      this.set('informativePlan', company.get('stripe_plan_id') === _environment.default.stripe_plan_id.gold_plan);
      this.set('cashAccountFee', company.get('stripe_plan_id') === _environment.default.stripe_plan_id.bronze_plan);
      return company;
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    subAccountOfValid: Ember.computed('account', function () {
      return !this.get('account.is_title') ? true : false;
    }),
    setAccountTypes: function setAccountTypes() {
      var _this2 = this;

      var accountTypes = this.get('session.data.authenticated.account_types').filter(function (accountType) {
        switch (accountType.name) {
          case "Inventory":
            break;

          case "Accounts Payable":
            break;

          case "Accounts Receivable":
            break;

          default:
            if (accountType.name === "Cash") {
              if (_this2.get('newAccount') || _this2.get('accountTypeId') === 1) {
                _this2.set('cashAccountId', accountType.id);
              } else {
                break;
              }
            }

            return accountType;
        }
      });
      this.set('accountTypes', accountTypes.sort(function (a, b) {
        return a.order_number - b.order_number;
      }));
    },
    // accountTypes: computed(function() {
    //   let accountTypes = this.get('session.data.authenticated.account_types').filter((accountType) => {
    //     switch (accountType.name) {
    //       case "Inventory":
    //         break;
    //       case "Accounts Payable":
    //         break;
    //       case "Accounts Receivable":
    //         break;
    //       default:
    //         if (accountType.name === "Cash") {
    //           this.set('account.is_cash', true);
    //         }
    //         return accountType;
    //     }
    //   });
    //   return accountTypes.sort((a, b) => {
    //     return a.order_number - b.order_number;
    //   });
    // }),
    titleAccounts: Ember.computed('accountTypeId', function () {
      var _this = this;

      var accounts = this.store.peekAll('account').filter(function (account) {
        if (account.get('is_title') && account.get('account_type_id').toString() === _this.accountTypeId.toString()) {
          return account;
        }
      });
      accounts.push({
        id: 0,
        name: "No title account"
      });
      return accounts;
    }),
    subAccountOfValidationObserver: Ember.observer('account.is_title', 'accountTypeId', function () {
      this.set('subAccountOfValid', !this.get('account.is_title'));
    }),
    actions: {
      updateAccountActivity: function updateAccountActivity(value) {
        this.set('account.activity', value);
      },
      updateAccountTypeValue: function updateAccountTypeValue(value) {
        this.set('accountTypeId', value);
        var accountTypeName = this.get('session.data.authenticated.account_types').find(function (accountType) {
          return accountType.id.toString() === value.toString();
        }).name;
        this.set('account.activity', (0, _accountTypeActivities.default)()[accountTypeName]);
      },
      updateSubAccountOfValue: function updateSubAccountOfValue(value) {
        if (value === "0") {
          this.set('subAccountOf', null);
        } else {
          this.set('subAccountOf', value);
        }
      },
      create: function create() {
        var _this3 = this;

        this.setProperties({
          'account.company_id': this.companyId,
          'account.account_type_id': this.accountTypeId,
          'account.sub_account_of': this.subAccountOf
        });
        var newAccount = this.store.createRecord('account', this.account);
        newAccount.save().then(function () {
          _this3.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Account Created</span></div>'
          });

          _this3.sendAction('reloadRoute');

          _this3.get('router').transitionTo('companies.show.accounts', _this3.companyId);
        }, function (response) {
          newAccount.rollbackAttributes();
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      update: function update() {
        var merge = this.get('confirmMerge');
        this.set('confirmMerge', false);

        var _this = this;

        this.account.setProperties({
          'account_type_id': this.accountTypeId,
          'sub_account_of': this.subAccountOf
        });
        this.store.findRecord('account', this.account.id, {
          reload: true
        }).then(function (account) {
          account.setProperties(_this.account);
          account.save().then(function () {
            if (merge && !_this.get('account.undeletable')) {
              _this.store.peekRecord('account', account.id).unloadRecord();
            }

            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Account Updated</span></div>'
            });

            _this.sendAction('reloadRoute');

            _this.get('router').transitionTo('companies.show.accounts', _this.companyId);
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            account.rollbackAttributes();

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          });
        });
      },
      mergeVerification: function mergeVerification() {
        var _this4 = this;

        var accounts = this.store.peekAll('account');
        var merge;

        if (this.get('account').changedAttributes().name) {
          accounts.forEach(function (acc) {
            var sameAccount = acc.id === _this4.get('account.id');

            var equalNames = acc.get('name').toLowerCase().replace(/\s+/g, '') === _this4.get('account.name').toLowerCase().replace(/\s+/g, '');

            if (!sameAccount && equalNames) {
              merge = true;
            }
          });
        }

        if (merge) {
          this.set('confirmMerge', true);
        } else {
          this.send('update');
        }
      },
      toggleConfirmMerge: function toggleConfirmMerge() {
        this.toggleProperty('confirmMerge');
      },
      toggleConfirmCashAccount: function toggleConfirmCashAccount() {
        if (this.get('accountTypeId').toString() === "1") {
          this.set('confirmCashAccountMessage', Ember.String.htmlSafe("\n          You are currently on the Bronze Register Plan, creating an additional Cash Account has a $5 monthly fee.\n          <br>\n          Note: Silver Bussiness And Gold Enterprise Plans offer unlimited Cash Accounts.\n          <br>\n          Create Cash Account?"));
          this.toggleProperty('confirmCashAccount');
        } else {
          this.send('create');
        }
      },
      deleteAccount: function deleteAccount() {
        var _this5 = this;

        var accountName = this.account.get('name');
        this.account.destroyRecord().then(function () {
          _this5.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(accountName, " Account Deleted</span></div>")
          });

          _this5.sendAction('reloadRoute');

          _this5.get('router').transitionTo('companies.show.accounts', _this5.companyId);
        }).catch(function (response) {
          var errors = response.errors.map(function (error) {
            return error;
          });

          _this5.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });

          _this5.account.rollbackAttributes();
        });
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      cancel: function cancel() {
        if (Ember.typeOf(this.get('account')) === "instance") {
          this.get('account').rollbackAttributes();
        }

        this.get('router').transitionTo('companies.show.accounts', this.companyId);
      }
    }
  });

  _exports.default = _default;
});