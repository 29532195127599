define("account-berry-frontend/companies/show/items/transactions/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(param) {
      return this.store.peekRecord('item', param.item_id, {
        reload: true
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('breadCrumb', "".concat(model.get('name'), " transactions"));
      controller.set('companyId', this.paramsFor('companies.show').company_id);
      controller.set('transactions', []);
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.items.transactions', {
        into: 'application',
        model: model
      });
    }
  });

  _exports.default = _default;
});