define("account-berry-frontend/companies/show/customers/representatives/controller", ["exports", "ember-cli-pagination/computed/paged-array"], function (_exports, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    newRepresentative: false,
    representativeForm: false,
    confirmDelete: false,
    search: "",
    sortAscending: false,
    filter: "display_name",
    filters: Ember.computed(function () {
      return [{
        name: "DISPLAY NAME",
        value: "display_name"
      }, {
        name: "PHONE",
        value: "phone"
      }, {
        name: "EMAIL",
        value: "email"
      }];
    }),
    pagedRepresentatives: (0, _pagedArray.default)('filteredRepresentatives', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    modelObserver: Ember.observer('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      try {
        this.set('filteredRepresentatives', this.store.peekAll('representative').filter(function (representative) {
          return !representative.get('isDeleted');
        }));
        this.set('pagedRepresentatives', (0, _pagedArray.default)('filteredRepresentatives', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    totalRepresentatives: Ember.computed('filteredRepresentatives', function () {
      return this.get('filteredRepresentatives.length');
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this = this;

      var channel = this.get('session.pusher').subscribe("representative-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          _this.updateRepresentativeList(data.representative);
        }
      });
      channel.bind('updated', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var representativeId = data.representative.id;
          delete data.representative.id;

          var representative = _this.store.peekRecord('representative', representativeId);

          representative.setProperties(data.representative);
        }
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var representative = _this.store.peekRecord('representative', data.representative_id);

          representative.deleteRecord();

          _this.set('filteredRepresentatives', _this.store.peekAll('representative').filter(function (representative) {
            return !representative.get('isDeleted');
          }));

          _this.set('pagedRepresentatives', (0, _pagedArray.default)('filteredRepresentatives', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        }
      });
      this.set('subscribedToChannel', true);
    },
    updateRepresentativeList: function updateRepresentativeList(representative) {
      this.store.push({
        data: {
          id: representative.id,
          type: 'representative',
          attributes: representative
        }
      });
      this.set('filteredRepresentatives', this.store.peekAll('representative').filter(function (representative) {
        return !representative.get('isDeleted');
      }));
      this.set('pagedRepresentatives', (0, _pagedArray.default)('filteredRepresentatives', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    filteredRepresentatives: Ember.computed('model', function () {
      return this.model;
    }),
    filterRepresentatives: Ember.observer('search', function () {
      var search = this.search;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      var filter = this.filter;

      if (search === "" || search.trim().length === 0) {
        this.set('filteredRepresentatives', this.model);
      } else {
        this.set('filteredRepresentatives', this.model.filter(function (representative) {
          var representativeValue = representative.get(filter);

          if (representativeValue) {
            if (isNaN(representativeValue)) {
              representativeValue = representativeValue.toLowerCase();
            }

            if (representativeValue.includes(search)) {
              return representative;
            }
          }
        }));
      }

      this.set('pagedRepresentatives', (0, _pagedArray.default)('filteredRepresentatives', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['customers'];
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3><h3 style='text-align: center;'>Representatives</h3><table id='representativesTable' style='width:100%; font-size: 14px'>");
      table += "<thead><tr><th style='text-align: left;'>DISPLAY NAME</th>";
      table += "<th style='text-align: left;'>PHONE</th>";
      table += "<th style='text-align: left;'>EMAIL</th></tr></thead>";
      table += "<tbody>";
      this.get('filteredRepresentatives').forEach(function (representative) {
        table += "<tr>";
        table += "<td>".concat(representative.get('display_name'), "</td>");
        table += "<td>".concat(representative.get('phone') || "", "</td>");
        table += "<td>".concat(representative.get('email') || "", "</td>");
        table += "</tr>";
      });
      table += "</tbody></table></div>";
      return table;
    },
    generateCSV: function generateCSV() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3><h3 style='text-align: center;'>Representatives</h3><table id='representativesTable' style='width:100%; font-size: 14px'>");
      table += "<thead><tr><th style='text-align: left;'>FIRST NAME</th>";
      table += "<th style='text-align: left;'>LAST NAME</th>";
      table += "<th style='text-align: left;'>DISPLAY NAME</th>";
      table += "<th style='text-align: left;'>PHONE</th>";
      table += "<th style='text-align: left;'>EMAIL</th></tr></thead>";
      table += "<tbody>";
      this.get('filteredRepresentatives').forEach(function (representative) {
        table += "<tr>";
        table += "<td>".concat(representative.get('first_name'), "</td>");
        table += "<td>".concat(representative.get('last_name'), "</td>");
        table += "<td>".concat(representative.get('display_name'), "</td>");
        table += "<td>".concat(representative.get('phone') || "", "</td>");
        table += "<td>".concat(representative.get('email') || "", "</td>");
        table += "</tr>";
      });
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);
      },
      toggleNewRepresentative: function toggleNewRepresentative() {
        this.set('representative', {});
        this.toggleProperty('newRepresentative');
        this.toggleProperty('representativeForm');
      },
      toggleEditRepresentative: function toggleEditRepresentative(representative) {
        this.set('representative', representative);
        this.toggleProperty('representativeForm');
      },
      cancelEdit: function cancelEdit() {
        this.representative.rollbackAttributes();
        this.toggleProperty('representativeForm');
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        if (id) {
          var self = this;
          this.store.findRecord('representative', id, {
            backgroundReload: false
          }).then(function (representative) {
            self.set('representative', representative);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      deleteRepresentative: function deleteRepresentative() {
        var _this2 = this;

        this.representative.destroyRecord().then(function () {
          _this2.set('filteredRepresentatives', _this2.store.peekAll('representative').filter(function (representative) {
            return !representative.get('isDeleted');
          }));

          _this2.setProperties({
            pagedRepresentatives: (0, _pagedArray.default)('filteredRepresentatives', {
              infinite: "unpaged",
              page: 1,
              perPage: 100
            }),
            representative: {},
            confirmDelete: false
          });

          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Representative Deleted</span></div>"
          });
        }, function (response) {
          var errors = "An Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          }

          _this2.representative.rollbackAttributes();

          _this2.set('confirmDelete', false);

          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      loadNext: function loadNext() {
        this.get('pagedRepresentatives').loadNextPage();
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('filteredRepresentatives', this.get('filteredRepresentatives').sortBy(property));
        } else {
          this.set('filteredRepresentatives', this.get('filteredRepresentatives').sortBy(property).reverse());
        }

        this.set('pagedRepresentatives', (0, _pagedArray.default)('filteredRepresentatives', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      print: function print() {
        var table = this.generateTable();
        Ember.$('.print-container').empty();
        Ember.$('.print-container').append(table);
        Ember.$('.print-container').printThis({
          importCSS: false
        }); // let w = window.open();
        // w.document.write("<div id='print-container'></div>");
        // w.document.getElementById('print-container').innerHTML += table;
        // w.print();
        // w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateCSV();
        Ember.$('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        Ember.$('#representativesTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_representatives_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        Ember.$('#representativesTable').remove();
        Ember.$('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});