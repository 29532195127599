define("account-berry-frontend/companies/show/journal-entries/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(param) {
      return this.store.findRecord('journal-entry', param.journal_entry_id);
    },
    setupController: function setupController(controller, model) {
      controller.set('breadCrumb', model.id);
      controller.set('companyId', this.paramsFor('companies.show').company_id);
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.journal-entries.show', {
        into: 'application',
        model: model
      });
    }
  });

  _exports.default = _default;
});