define("account-berry-frontend/user-role/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    company_id: _emberData.default.attr(),
    role_id: _emberData.default.attr(),
    role_name: _emberData.default.attr(),
    user_id: _emberData.default.attr(),
    user: _emberData.default.attr(),
    user_name: _emberData.default.attr(),
    permissions: _emberData.default.attr(),
    ownership_transfer: _emberData.default.attr()
  });

  _exports.default = _default;
});