define("account-berry-frontend/companies/show/employees/499-quarterly/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    init: function init() {
      var _this2 = this;

      var channel = this.get('session.pusher').subscribe("499r-1b-channel-".concat(this.get('session.data.authenticated.id')));
      channel.bind('quarter', function (response) {
        _this2.store.findRecord('json-storage', response.json_storage_id).then(function (jsonStorage) {
          _this2.set('quarterData', jsonStorage.get('stored_json.quarter'));
        });
      });
    },
    enmendada: Ember.computed(function () {
      return false;
    }),
    // Quarter months, first 31 values are dates, last value is Month Liability
    quarterFirstMonth: function quarterFirstMonth() {
      return [{
        x: 67,
        y: 703
      }, {
        x: 67,
        y: 689
      }, {
        x: 67,
        y: 676
      }, {
        x: 67,
        y: 663
      }, {
        x: 67,
        y: 649
      }, {
        x: 67,
        y: 635
      }, {
        x: 67,
        y: 622
      }, {
        x: 67,
        y: 608
      }, {
        x: 67,
        y: 595
      }, {
        x: 67,
        y: 582
      }, {
        x: 253,
        y: 703
      }, {
        x: 253,
        y: 690
      }, {
        x: 253,
        y: 676
      }, {
        x: 253,
        y: 663
      }, {
        x: 253,
        y: 649
      }, {
        x: 253,
        y: 636
      }, {
        x: 253,
        y: 622
      }, {
        x: 253,
        y: 608
      }, {
        x: 253,
        y: 595
      }, {
        x: 253,
        y: 581
      }, {
        x: 443,
        y: 703
      }, {
        x: 443,
        y: 689
      }, {
        x: 443,
        y: 676
      }, {
        x: 443,
        y: 663
      }, {
        x: 443,
        y: 650
      }, {
        x: 443,
        y: 635
      }, {
        x: 443,
        y: 622
      }, {
        x: 443,
        y: 608
      }, {
        x: 443,
        y: 595
      }, {
        x: 443,
        y: 582
      }, {
        x: 443,
        y: 554
      }, {
        x: 253,
        y: 554
      }];
    },
    quarterSecondMonth: function quarterSecondMonth() {
      return [{
        x: 67,
        y: 511
      }, {
        x: 67,
        y: 497
      }, {
        x: 67,
        y: 484
      }, {
        x: 67,
        y: 471
      }, {
        x: 67,
        y: 457
      }, {
        x: 67,
        y: 443
      }, {
        x: 67,
        y: 430
      }, {
        x: 67,
        y: 416
      }, {
        x: 67,
        y: 403
      }, {
        x: 67,
        y: 390
      }, {
        x: 253,
        y: 511
      }, {
        x: 253,
        y: 497
      }, {
        x: 253,
        y: 484
      }, {
        x: 253,
        y: 471
      }, {
        x: 253,
        y: 457
      }, {
        x: 253,
        y: 443
      }, {
        x: 253,
        y: 430
      }, {
        x: 253,
        y: 416
      }, {
        x: 253,
        y: 403
      }, {
        x: 253,
        y: 390
      }, {
        x: 443,
        y: 511
      }, {
        x: 443,
        y: 497
      }, {
        x: 443,
        y: 484
      }, {
        x: 443,
        y: 471
      }, {
        x: 443,
        y: 457
      }, {
        x: 443,
        y: 443
      }, {
        x: 443,
        y: 430
      }, {
        x: 443,
        y: 416
      }, {
        x: 443,
        y: 403
      }, {
        x: 443,
        y: 390
      }, {
        x: 443,
        y: 362
      }, {
        x: 253,
        y: 362
      }];
    },
    quarterThirdMonth: function quarterThirdMonth() {
      return [{
        x: 67,
        y: 323
      }, {
        x: 67,
        y: 309
      }, {
        x: 67,
        y: 296
      }, {
        x: 67,
        y: 283
      }, {
        x: 67,
        y: 269
      }, {
        x: 67,
        y: 256
      }, {
        x: 67,
        y: 242
      }, {
        x: 67,
        y: 228
      }, {
        x: 67,
        y: 215
      }, {
        x: 67,
        y: 202
      }, {
        x: 253,
        y: 323
      }, {
        x: 253,
        y: 310
      }, {
        x: 253,
        y: 296
      }, {
        x: 253,
        y: 283
      }, {
        x: 253,
        y: 269
      }, {
        x: 253,
        y: 255
      }, {
        x: 253,
        y: 242
      }, {
        x: 253,
        y: 229
      }, {
        x: 253,
        y: 215
      }, {
        x: 253,
        y: 202
      }, {
        x: 443,
        y: 310
      }, {
        x: 443,
        y: 296
      }, {
        x: 443,
        y: 283
      }, {
        x: 443,
        y: 269
      }, {
        x: 443,
        y: 256
      }, {
        x: 443,
        y: 243
      }, {
        x: 443,
        y: 229
      }, {
        x: 443,
        y: 215
      }, {
        x: 443,
        y: 202
      }, {
        x: 443,
        y: 175
      }, {
        x: 443,
        y: 157
      }, {
        x: 253,
        y: 176
      }];
    },
    firstPagePdfCoordinates: Ember.computed('company', 'quarterEndingOn', 'enmendada', 'employerWhoStopsPayingWagesY', 'typeY', 'agencyY', 'selfEmployedSpecialist', 'quarterData', 'planillaFinal', 'planillaTemporera', 'semiWeeklyDepositor', 'date', 'specialistName', 'specialistRegistrationNumber', 'firmName', function () {
      return {
        quarterEndingOn: {
          value: this.get('quarterEndingOn') || "",
          x: 306,
          y: 695,
          size: 10
        },
        ein: {
          value: this.get('company.ein') || "",
          x: 35,
          y: 665,
          size: 10
        },
        phone: {
          value: this.get('company.phone') || "",
          x: 215,
          y: 665,
          size: 10
        },
        naics: {
          value: this.get('company.naics_code') || "",
          x: 345,
          y: 664,
          size: 10
        },
        companyName: {
          value: this.get('company.name') || "",
          x: 35,
          y: 635,
          size: 8
        },
        contactName: {
          value: this.get('company.contact_name') || "",
          x: 35,
          y: 602,
          size: 8
        },
        postalAddressLineOne: {
          value: this.get('company.postal_address_line_one') || "",
          x: 35,
          y: 577,
          size: 6
        },
        postalAddressLineTwo: {
          value: this.get('company.postal_address_line_two') || "",
          x: 35,
          y: 570,
          size: 6
        },
        postalAddressCity: {
          value: this.get('company.postal_address_city') || "",
          x: 35,
          y: 563,
          size: 6
        },
        postalAddressState: {
          value: this.get('company.postal_address_state') || "",
          x: 111,
          y: 563,
          size: 6
        },
        postalAddressZip: {
          value: this.get('company.postal_address_zip') || "",
          x: 126,
          y: 563,
          size: 6
        },
        postalAddressCountry: {
          value: this.get('company.postal_address_country') || "",
          x: 173,
          y: 563,
          size: 6
        },
        physicalAddressLineOne: {
          value: this.get('company.physical_address_line_one') || "",
          x: 35,
          y: 538,
          size: 6
        },
        physicalAddressLineTwo: {
          value: this.get('company.physical_address_line_two') || "",
          x: 35,
          y: 531,
          size: 6
        },
        physicalAddressCity: {
          value: this.get('company.physical_address_city') || "",
          x: 35,
          y: 524,
          size: 6
        },
        physicalAddressState: {
          value: this.get('company.physical_address_state') || "",
          x: 111,
          y: 524,
          size: 6
        },
        physicalAddressZip: {
          value: this.get('company.physical_address_zip') || "",
          x: 126,
          y: 524,
          size: 6
        },
        physicalAddressCountry: {
          value: this.get('company.physical_address_country') || "",
          x: 173,
          y: 524,
          size: 6
        },
        type: {
          value: this.get('typeY') ? "X" : "",
          x: 444,
          y: this.get('typeY') || 0,
          size: 8
        },
        agency: {
          value: this.get('agencyY') ? "X" : "",
          x: 444,
          y: this.get('agencyY') || 0,
          size: 8
        },
        enmendada: {
          value: this.get('enmendada') ? "X" : "",
          x: 566,
          y: 694,
          size: 8
        },
        planillaTemporalmente: {
          value: this.get('planillaTemporalmente') ? "X" : "",
          x: 566,
          y: 640,
          size: 8
        },
        planillaFinal: {
          value: this.get('planillaFinal') ? "X" : "",
          x: 566,
          y: 654,
          size: 8
        },
        typeOfDepositor: {
          value: this.get('typeOfDepositorY') ? "X" : "",
          x: 39,
          y: this.get('typeOfDepositorY') || 0,
          size: 8
        },
        employeesCount: {
          value: this.get('quarterData.employees_count').toString(),
          x: 454,
          y: 411,
          size: 8
        },
        totalExcemptWages: {
          value: parseFloat(this.get('quarterData.total_exempt_wages')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'),
          x: 454,
          y: 393,
          size: 8
        },
        totalWagesSubjectToCompensation: {
          value: parseFloat(this.get('quarterData.total_wages_subject_to_withholding')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'),
          x: 454,
          y: 375,
          size: 8
        },
        totalTipsSubjectToCompensation: {
          value: parseFloat(this.get('quarterData.total_tips_subject_to_withholding')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'),
          x: 454,
          y: 355,
          size: 8
        },
        totalIncomeTaxWithheld: {
          value: parseFloat(this.get('quarterData.total_income_tax')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'),
          x: 454,
          y: 337,
          size: 8
        },
        firstMonthLiability: {
          value: !this.get('semiWeeklyDepositor') ? parseFloat(this.get('quarterData.first_month_income_tax')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "",
          x: 41,
          y: 288,
          size: 8
        },
        secondMonthLiability: {
          value: !this.get('semiWeeklyDepositor') ? parseFloat(this.get('quarterData.second_month_income_tax')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "",
          x: 228,
          y: 288,
          size: 8
        },
        thirdMonthLiability: {
          value: !this.get('semiWeeklyDepositor') ? parseFloat(this.get('quarterData.third_month_income_tax')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "",
          x: 413,
          y: 288,
          size: 8
        },
        totalQuarterLiability: {
          value: !this.get('semiWeeklyDepositor') ? parseFloat(this.get('quarterData.total_income_tax')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "",
          x: 413,
          y: 268,
          size: 8
        },
        date: {
          value: this.get('date') || "",
          x: 25,
          y: 200,
          size: 8
        },
        title: {
          value: this.get('title') || "",
          x: 161,
          y: 200,
          size: 8
        },
        specialistName: {
          value: this.get('specialistName') || "",
          x: 24,
          y: 144,
          size: 8
        },
        specialistRegistrationNumber: {
          value: this.get('specialistRegistrationNumber') || "",
          x: 245,
          y: 144,
          size: 8
        },
        specialistDate: {
          value: this.get('date') || "",
          x: 359,
          y: 144,
          size: 8
        },
        selfEmployedSpecialist: {
          value: this.get('selfEmployedSpecialist') ? "X" : "",
          x: 519,
          y: 141,
          size: 8
        },
        firmName: {
          value: this.get('firmName') || "",
          x: 359,
          y: 112,
          size: 8
        },
        firmAddress: {
          value: this.get('firmAddress') || "",
          x: 24,
          y: 87,
          size: 8
        },
        paidSpecialist: {
          value: "X",
          x: this.get('paidSpecialist') ? 262 : 283,
          y: 58,
          size: 8,
          color: [1, 1, 1]
        }
      };
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    pdfProcess: function pdfProcess(existingPdfDocBytes) {
      var _this3 = this;

      var _this = this;

      var pdfDoc = PDFLib.PDFDocumentFactory.load(existingPdfDocBytes);

      var _pdfDoc$embedStandard = pdfDoc.embedStandardFont(PDFLib.StandardFonts.Helvetica),
          _pdfDoc$embedStandard2 = _slicedToArray(_pdfDoc$embedStandard, 2),
          helveticaRef = _pdfDoc$embedStandard2[0],
          helveticaFont = _pdfDoc$embedStandard2[1];

      var pages = pdfDoc.getPages();
      var firstPage = pages[0];
      var secondPage = pages[1];
      firstPage.addFontDictionary('Helvetica', helveticaRef);
      var firstPageKeys = Object.keys(this.get('firstPagePdfCoordinates')); // const secondPageKeys = Object.keys(this.get('secondPagePdfCoordinates'))

      var contentStream;
      firstPageKeys.forEach(function (k) {
        contentStream = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(_this3.get("firstPagePdfCoordinates.".concat(k, ".value"))), {
          x: _this.get("firstPagePdfCoordinates.".concat(k, ".x")),
          y: _this.get("firstPagePdfCoordinates.".concat(k, ".y")),
          size: _this.get("firstPagePdfCoordinates.".concat(k, ".size")),
          font: 'Helvetica',
          colorRgb: _this.get("firstPagePdfCoordinates.".concat(k, ".color")) ? _this.get("firstPagePdfCoordinates.".concat(k, ".color")) : [0, 0, 1]
        }));
        firstPage.addContentStreams(pdfDoc.register(contentStream));
      });
      secondPage.addFontDictionary('Helvetica', helveticaRef);
      var quarterFirstMonth = this.get('quarterFirstMonth')();
      var quarterSecondMonth = this.get('quarterSecondMonth')();
      var quarterThirdMonth = this.get('quarterThirdMonth')();

      if (this.get('semiWeeklyDepositor')) {
        for (var i = 0; i < 32; i++) {
          var contentStreamOne = void 0,
              contentStreamTwo = void 0,
              contentStreamThree = void 0;

          if (this.get("quarterData.first_month_dates.".concat(i + 1))) {
            var _amount = parseFloat(this.get("quarterData.first_month_dates.".concat(i + 1))).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

            contentStreamOne = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(_amount), {
              x: quarterFirstMonth[i].x,
              y: quarterFirstMonth[i].y,
              size: 8,
              font: 'Helvetica',
              colorRgb: [0, 0, 1]
            }));
            secondPage.addContentStreams(pdfDoc.register(contentStreamOne));
          }

          if (this.get("quarterData.second_month_dates.".concat(i + 1))) {
            var _amount2 = parseFloat(this.get("quarterData.second_month_dates.".concat(i + 1))).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

            contentStreamTwo = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(_amount2), {
              x: quarterSecondMonth[i].x,
              y: quarterSecondMonth[i].y,
              size: 8,
              font: 'Helvetica',
              colorRgb: [0, 0, 1]
            }));
            secondPage.addContentStreams(pdfDoc.register(contentStreamTwo));
          }

          if (this.get("quarterData.third_month_dates.".concat(i + 1))) {
            var _amount3 = parseFloat(this.get("quarterData.third_month_dates.".concat(i + 1))).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

            contentStreamThree = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(_amount3), {
              x: quarterThirdMonth[i].x,
              y: quarterThirdMonth[i].y,
              size: 8,
              font: 'Helvetica',
              colorRgb: [0, 0, 1]
            }));
            secondPage.addContentStreams(pdfDoc.register(contentStreamThree));
          }

          if (i == 31) {
            var firstMonthAmount = parseFloat(this.get('quarterData.first_month_income_tax')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
            var secondMonthAmount = parseFloat(this.get('quarterData.second_month_income_tax')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
            var thirdMonthAmount = parseFloat(this.get('quarterData.third_month_income_tax')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
            contentStreamOne = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(firstMonthAmount), {
              x: quarterFirstMonth[i].x,
              y: quarterFirstMonth[i].y,
              size: 8,
              font: 'Helvetica',
              colorRgb: [0, 0, 1]
            }));
            contentStreamTwo = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(secondMonthAmount), {
              x: quarterSecondMonth[i].x,
              y: quarterSecondMonth[i].y,
              size: 8,
              font: 'Helvetica',
              colorRgb: [0, 0, 1]
            }));
            contentStreamThree = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(thirdMonthAmount), {
              x: quarterThirdMonth[i].x,
              y: quarterThirdMonth[i].y,
              size: 8,
              font: 'Helvetica',
              colorRgb: [0, 0, 1]
            }));
            secondPage.addContentStreams(pdfDoc.register(contentStreamOne));
            secondPage.addContentStreams(pdfDoc.register(contentStreamTwo));
            secondPage.addContentStreams(pdfDoc.register(contentStreamThree));
          }
        }

        var amount = parseFloat(this.get('quarterData.total_income_tax')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        contentStream = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(amount), {
          x: 256,
          y: 143,
          size: 8,
          font: 'Helvetica',
          colorRgb: [0, 0, 1]
        }));
        secondPage.addContentStreams(pdfDoc.register(contentStream));
      }

      this.get('applicationController').set('showLoading', false);
      var pdfBytes = PDFLib.PDFDocumentWriter.saveToBytes(pdfDoc);
      this.downloadPdf(pdfBytes);
    },
    downloadPdf: function downloadPdf(data) {
      var blob = new Blob([data], {
        type: "application/pdf"
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob); // link.download = "myFileName.pdf";
      // link.click();

      window.open(link.href);
    },
    getPDF: Ember.observer('quarterData', function () {
      var _this4 = this;

      fetch('https://trimestrales.s3.amazonaws.com/Form499R-1B.pdf').then(function (res) {
        var file = res.arrayBuffer();
        file.then(function (f) {
          _this4.pdfProcess(new Uint8Array(f));
        });
      });
    }),
    actions: {
      setYear: function setYear(year) {
        this.set('year', year);

        if (this.get('quarter')) {
          this.set('quarterEndingOn', "".concat(this.get('quarter'), "-").concat(this.get('year')));
        }
      },
      setQuarter: function setQuarter(date) {
        this.set('quarter', date);

        if (this.get('year')) {
          this.set('quarterEndingOn', "".concat(this.get('quarter'), "-").concat(this.get('year')));
        } // this.set('pdfCoordinates.type.y', y)

      },
      setType: function setType(type) {
        var y = 0;

        switch (type) {
          case "publica":
            y = 639;
            break;

          case "privada":
            y = 617;
            break;

          case "sociedad":
            y = 591;
            break;

          case "individual":
            y = 580;
            break;

          default:
            this.set('pdfCoordinates.type.value', "");
            break;
        }

        this.set('typeY', y); // this.set('pdfCoordinates.type.y', y)
      },
      setAgency: function setAgency(agency) {
        var y = 0;

        switch (agency) {
          case "federal":
            y = 557;
            break;

          case "estatal":
            y = 544;
            break;

          case "municipal":
            y = 530;
            break;

          case "otros":
            y = 517;
            break;

          default:
            this.set('pdfCoordinates.agency.value', "");
            break;
        }

        this.set('agencyY', y); // this.set('pdfCoordinates.agency.y', y)
      },
      setEmployerWhoStopsPayingWages: function setEmployerWhoStopsPayingWages(value) {
        var y = 0;

        switch (value) {
          case "final":
            y = 654;
            break;

          case "temporary":
            y = 640;
            break;

          default:
            this.set('pdfCoordinates.employerWhoStopsPayingWages.value', "");
            break;
        }

        this.set('employerWhoStopsPayingWagesY', y); // this.set('pdfCoordinates.employerWhoStopsPayingWages.y', y)
      },
      setTypeOfDepositor: function setTypeOfDepositor(value) {
        var y = 0;

        switch (value) {
          case "mensual":
            y = 482;
            this.set('semiWeeklyDepositor', false);
            break;

          case "bisemanal":
            this.set('semiWeeklyDepositor', true);
            y = 467;
            break;

          case "retencion_menor":
            this.set('semiWeeklyDepositor', false);
            y = 454;
            break;

          default:
            this.set('semiWeeklyDepositor', false);
            this.set('pdfCoordinates.typeOfDepositor.value', "");
            break;
        }

        this.set('typeOfDepositorY', y); // this.set('pdfCoordinates.typeOfDepositor.y', y)
      },
      generate: function generate() {
        var _this5 = this;

        var data = JSON.stringify({
          quarter: {
            date: this.get('quarterEndingOn'),
            type: '499r-1b'
          }
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/quarters"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            _this5.get('applicationController').set('showLoading', true);
          },
          errorCallback: function errorCallback(e) {
            _this5.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(e.payload.errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      }
    }
  });

  _exports.default = _default;
});