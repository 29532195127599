define("account-berry-frontend/authenticators/devise", ["exports", "ember-simple-auth/authenticators/devise", "account-berry-frontend/config/environment"], function (_exports, _devise, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _devise.default.extend({
    serverTokenEndpoint: "".concat(_environment.default['apiURL'], "/users/sign_in")
  });

  _exports.default = _default;
});