define("account-berry-frontend/companies/show/employees/employees-table/rates/controller", ["exports", "ember-cli-pagination/computed/paged-array"], function (_exports, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    search: "",
    sortAscending: false,
    dateFilter: 'date',
    pagedRates: (0, _pagedArray.default)('filteredRates', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    ratesObserver: Ember.observer('filteredRates', 'model', function () {
      try {
        this.set('pagedRates', (0, _pagedArray.default)('filteredRates', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {}
    }),
    filteredRates: Ember.computed('model.employee_rates', function () {
      return this.get('model.employee_rates');
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['employees'];
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3>");
      table += "<h3 style='text-align: center'>".concat(this.model.get('display_name'), " Rates</h3>");
      table += "<table id='employeeRatesTable' style='width:100%; font-size: 14px'>";
      table += "<thead><tr><th style='text-align: left'>DATE</th>";
      table += "<th style='text-align: right'>REGULAR RATE</th>";
      table += "<th style='text-align: right'>OVERTIME RATE</th>";
      table += "<th style='text-align: right'>STATE INCOME TAX %</th>";
      table += "<th style='text-align: right'>FEDERAL INCOME TAX %</th></tr></thead><tbody>";
      this.get('filteredRates').forEach(function (rate) {
        table += "<tr><td style='text-align: left'>".concat(moment(rate.date).format('MM/DD/YYYY'), "</td>");
        table += "<td style='text-align: right'>".concat(parseFloat(rate.regular_rate).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style='text-align: right'>".concat(parseFloat(rate.overtime_rate).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style='text-align: right'>%".concat(rate.state_income_tax_percentage ? parseFloat(rate.state_income_tax_percentage).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "", "</td>");
        table += "<td style='text-align: right'>%".concat(rate.federal_income_tax_percentage ? parseFloat(rate.federal_income_tax_percentage).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "", "</td></tr>");
      });
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);

        if (value !== 'date') {
          this.setProperties({
            fromDateFilter: null,
            toDateFilter: null
          });
        }

        this.set('dateFilter', value === 'date');
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('filteredRates', this.get('filteredRates').sortBy(property));
        } else {
          this.set('filteredRates', this.get('filteredRates').sortBy(property).reverse());
        }

        this.set('pagedRates', (0, _pagedArray.default)('filteredRates', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      loadNext: function loadNext() {
        this.get('pagedRates').loadNextPage();
      },
      toggleConfirmDelete: function toggleConfirmDelete(rate) {
        if (rate) {
          var _this = this;

          this.store.findRecord('employee-rate', rate.id, {
            backgroundReload: false
          }).then(function (rate) {
            _this.set('rate', rate);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      deleteRate: function deleteRate() {
        var _this2 = this;

        this.rate.destroyRecord().then(function () {
          _this2.setProperties({
            confirmDelete: false
          });

          _this2.send('refreshModel');

          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Rate Deleted</span></div>"
          });
        }).catch(function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
        this.toggleProperty('confirmDelete');
      },
      print: function print() {
        var table = this.generateTable();
        Ember.$('.print-container').empty();
        Ember.$('.print-container').append(table);
        Ember.$('.print-container').printThis({
          importCSS: false
        }); //   let w = window.open();
        //   w.document.write("<div id='print-container'></div>");
        //   w.document.getElementById('print-container').innerHTML += table;
        //  w.print();
        //  w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        Ember.$('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        Ember.$('#employeeRatesTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_").concat(Ember.String.underscore(this.get('model.name')), "_rates_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        Ember.$('#employeeRatesTable').remove();
        Ember.$('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});