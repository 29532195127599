define("account-berry-frontend/companies/show/clover/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.findAll('clover-sync', {
        reload: true
      }).then(function (clover_sync) {
        return clover_sync.get('firstObject');
      });
    },
    setupController: function setupController(controller) {
      controller.set('breadCrumb', 'Clover');
      controller.set('companyId', this.paramsFor('companies.show').company_id);
      this.store.findAll('account', {
        reload: true
      }).then(function (accounts) {
        controller.set('accounts', accounts);
      });
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.clover', {
        into: 'application',
        model: model
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});