define("account-berry-frontend/components/dashboard-aging-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iFVOk64L",
    "block": "{\"symbols\":[\"placeholder\"],\"statements\":[[6,\"div\"],[9,\"class\",\"col-md-6 flex-column flex-center dashboard-item\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"agingSummary\"]]],null,{\"statements\":[[0,\"    \"],[6,\"h4\"],[7],[6,\"strong\"],[7],[0,\"Aging Summary\"],[8],[8],[0,\"\\n    \"],[1,[25,\"ember-chart\",null,[[\"type\",\"data\",\"options\"],[\"horizontalBar\",[20,[\"agingSummary\"]],[20,[\"agingSummaryOptions\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"content-placeholders\",null,null,{\"statements\":[[0,\"      \"],[1,[19,1,[\"chart\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/dashboard-aging-summary/template.hbs"
    }
  });

  _exports.default = _default;
});