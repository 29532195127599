define("account-berry-frontend/companies/show/customers/receive-payment/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      var companyId = this.paramsFor('companies.show').company_id;
      controller.set('breadCrumb', 'Receive Payment');
      controller.set('companyId', companyId);
      controller.set('deposits', null);
      controller.set('fromDate', null);
      controller.set('toDate', null);
      this.store.findAll('account', {
        backgroundReload: true
      }).then(function (accounts) {
        var filteredAccounts = accounts.toArray().filter(function (account) {
          return account.get('company_id').toString() === companyId.toString();
        });
        var cashAccounts = filteredAccounts.filter(function (account) {
          if (account.get('account_type.name').toLowerCase() === 'cash' && !account.get('is_title')) {
            return account;
          }
        });
        controller.set('accounts', filteredAccounts);
        controller.set('cashAccounts', cashAccounts);
      });
      this.store.findAll('customer', {
        backgroundReload: true
      }).then(function (customers) {
        var filteredCustomers = customers.toArray().filter(function (customer) {
          return customer.get('company_id').toString() === companyId.toString();
        });
        controller.set('customers', filteredCustomers);
      });
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.customers.receive-payment', {
        into: 'application',
        model: model
      });
    }
  });

  _exports.default = _default;
});