define("account-berry-frontend/services/stripev3", ["exports", "ember-stripe-elements/services/stripev3"], function (_exports, _stripev) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _stripev.default;
    }
  });
});