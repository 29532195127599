define("account-berry-frontend/companies/show/customers/payment-settings/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var _this = this;

      return this.store.findAll('stripe-connect-account').then(function (stripeConnectAccount) {
        return stripeConnectAccount.get('firstObject');
      }).catch(function (response) {
        // debugger;
        _this.get('notify').alert({
          html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(response.errors || "Oops. Something Went Wrong. Please Try Again Later.", "</span></div>")
        });

        _this.transitionTo('companies.show.customers');
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('breadCrumb', 'Receive Payments Settings');
      controller.set('companyId', this.paramsFor('companies.show').company_id);
      this.store.findAll('account').then(function (accounts) {
        controller.set('accounts', accounts);
      });
      controller.set('stripeConnectAccount', model);
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.customers.payment-settings', {
        into: 'application',
        model: model
      });
    }
  });

  _exports.default = _default;
});