define("account-berry-frontend/components/loading-spinner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6P6bF36k",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"showLoading\"]]],null,{\"statements\":[[0,\"  \"],[6,\"img\"],[9,\"src\",\"/assets/images/logos/berry.svg\"],[9,\"class\",\"loading-logo\"],[7],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"loader\"],[7],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"id\",\"loadingMessage\"],[9,\"class\",\"flex-row flex-center col-md-8\"],[7],[1,[18,\"loadingMessage\"],false],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/loading-spinner/template.hbs"
    }
  });

  _exports.default = _default;
});