define("account-berry-frontend/settings/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    model: function model() {
      var userId = this.get('session.data.authenticated.id');
      return this.store.findRecord('user', userId);
    },
    setupController: function setupController(controller) {
      controller.set('breadCrumbPath', 'settings');
      controller.set('breadCrumb', 'Settings');
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('settings', {
        into: 'application',
        model: model
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});