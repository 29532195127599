define("account-berry-frontend/companies/show/reports/cash-flow/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    cable: Ember.inject.service(),
    hideZeros: false,
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      Ember.$('tr.sub-header').on('click', function () {
        Ember.$(_this2).nextUntil('tr.sub-header').slideToggle(1000);
      });
    },
    init: function init() {
      var _this3 = this;

      Ember.$.initialize('#profit-loss-table', function () {
        _this3.setTable();
      });
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    comparativeYears: Ember.computed(function () {
      return "1";
    }),
    addReportSubData: function addReportSubData(account, table) {
      if (account.is_title) {
        table += "<tr><td>".concat(account.number ? account.number : "", " ").concat(account.name, "</td></tr>");
        account.sub_accounts.forEach(function (subAccount) {
          table += "<tr><td>".concat(subAccount.number ? subAccount.number : "", " ").concat(subAccount.name, "</td>");
          table += "<td>".concat(parseFloat(subAccount.balance).toFixed(2), "</td></tr>");
        });
        table += "<tr><td>Total ".concat(account.name, "</td>");
        table += "<td>".concat(parseFloat(account.total_balance).toFixed(2), "</td></tr>");
      } else {
        table += "<tr><td>".concat(account.number ? account.number : "", " ").concat(account.name, "</td>");
        table += "<td>".concat(parseFloat(account.balance).toFixed(2), "</td></tr>");
      }

      return table;
    },
    actions: {
      setComparativeYears: function setComparativeYears(years) {
        this.set('comparativeYears', years);
      },
      getCashFlow: function getCashFlow() {
        var _this4 = this;

        var data = JSON.stringify({
          report: {
            from_date: this.fromDate,
            to_date: this.toDate
          },
          report_type: 'cash_flow',
          comparative_years: this.get('comparativeYears')
        });

        var _this$get = this.get('session.data.authenticated'),
            email = _this$get.email,
            token = _this$get.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

        var _this = this;

        consumer.createSubscription({
          channel: "ReportsChannel",
          room: "CashFlow" + this.get('session.data.authenticated.id')
        }, {
          connected: function connected() {
            console.log('Connected to ReportsChannel-CashFlow');

            _this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(_this.companyId, "/reports"), {
              method: 'POST',
              contentType: 'application/json',
              data: data,
              headers: headers
            }).then(function () {
              _this.get('applicationController').set('showLoading', true);
            }).catch(function () {
              _this.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
              });
            });
          },
          received: function received(data) {
            if (data.error) {
              _this4.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
              });
            } else {
              _this4.set('cashFlowData', data.report);

              consumer.disconnect();

              _this4.get('applicationController').set('showLoading', false);
            }
          },
          disconnected: function disconnected() {
            console.log("ReportsChannel-CashFlow disconnected");
          }
        });
      },
      showAccountTransactions: function showAccountTransactions(id) {
        var _this5 = this;

        this.store.findRecord('account', id).then(function (account) {
          _this5.set('account', account); // this.transactionsActionCable();


          _this5.set('accountTransactions', true);
        });
      },
      cancelAccountTransactions: function cancelAccountTransactions() {
        this.set('accountTransactions', false);
      },
      print: function print() {
        // let divToPrint=document.getElementById("cashFlow");
        // let newWin = window.open("");
        // newWin.document.write(divToPrint.outerHTML);
        // newWin.print();
        // newWin.close();
        Ember.$('#cashFlow').printThis();
      },
      csvExport: function csvExport() {
        Ember.$('#cashFlow table').table2CSV({
          delivery: 'download',
          filename: 'CashFlowTable.csv'
        });
      }
    }
  });

  _exports.default = _default;
});