define("account-berry-frontend/invoice-payment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f1O8euVO",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"id\",\"invoicePayment\"],[9,\"class\",\"flex-column flex-center\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"invoicePayment\",\"paid\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"col-md-6 flex-column no-padding-left\"],[7],[0,\"\\n      \"],[6,\"h1\"],[9,\"class\",\"text-center paid\"],[7],[0,\"PAID\"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[18,\"template\"],false],[0,\"\\n\\n\"],[4,\"if\",[[25,\"and\",[[20,[\"invoicePayment\",\"paid\"]],[20,[\"invoicePayment\",\"receipt_url\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,\"a\"],[10,\"href\",[20,[\"invoicePayment\",\"receipt_url\"]],null],[7],[0,\"\\n      \"],[6,\"button\"],[9,\"id\",\"receiptButton\"],[9,\"class\",\"btn accountberry-btn success\"],[7],[0,\"RECEIPT\"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,\"and\",[[20,[\"invoicePayment\",\"session_id\"]],[25,\"not\",[[20,[\"invoicePayment\",\"paid\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[6,\"button\"],[9,\"id\",\"checkoutButton\"],[9,\"class\",\"btn accountberry-btn success width-25\"],[7],[0,\"PAY NOW\"],[8],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[25,\"and\",[[20,[\"invoicePayment\",\"public_token\"]],[25,\"not\",[[20,[\"invoicePayment\",\"paid\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[1,[25,\"ath-movil-button\",null,[[\"invoicePayment\"],[[20,[\"invoicePayment\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[6,\"img\"],[9,\"class\",\"width-15 bottom-spacing\"],[9,\"src\",\"/assets/images/logos/logo-slogan.svg\"],[7],[8],[0,\"\\n\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/invoice-payment/template.hbs"
    }
  });

  _exports.default = _default;
});