define("account-berry-frontend/components/deposit-form/component", ["exports", "ember-cli-pagination/computed/paged-array", "account-berry-frontend/config/environment"], function (_exports, _pagedArray, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    refNumber: Ember.inject.service(),
    notify: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    depositAmount: Ember.computed.alias('deposit.amount'),
    init: function init() {
      this._super();

      if (!this.get('deposit')) {
        this.set('deposit', {
          amount: 0
        });
      } else {
        this.set('deposit.payment_items', this.get('deposit.payments'));
      }

      if (this.newDeposit) {
        this.generateDepositNumber();
      }

      this.setProperties({
        accountOpen: !this.get('paymentForm'),
        paymentOpen: !!this.get('paymentForm')
      });

      if (this.get('deposit.recurrency')) {
        this.set('deposit.recurrency.active', true);
      }
    },
    generateDepositNumber: function generateDepositNumber() {
      var _this2 = this;

      var ajaxOptions = {
        url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/deposits/number"),
        method: 'GET',
        contentType: 'application/json',
        successCallback: function successCallback(response) {
          var depositNumber = _this2.get('refNumber').generate(response.last_deposit_number || "");

          _this2.set('deposit.deposit_number', depositNumber);
        },
        errorCallback: function errorCallback(response) {
          _this2.errorHandler(response);
        }
      };
      this.get('ajaxCall').request(ajaxOptions);
    },
    errorHandler: function errorHandler(response) {
      var errors = "An Unkown Error Occured.";

      if (response) {
        if (response.errors && response.errors[0].status !== "500") {
          errors = response.errors.map(function (error) {
            return error;
          });
        } else if (response.payload.errors) {
          errors = response.payload.errors.map(function (error) {
            return error;
          });
        }
      }

      this.get('notify').alert({
        html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
      });
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    companyPermission: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    paymentPermission: Ember.computed('company', function () {
      return this.get('company.permissions')['customers'];
    }),
    createPermission: Ember.computed('company', function () {
      if (this.get('paymentForm')) {
        return this.get('company.permissions')['customers'].write;
      } else {
        return this.get('company.permissions')['company'].write;
      }
    }),
    editPermission: Ember.computed('company', function () {
      if (this.get('paymentForm')) {
        return this.get('company.permissions')['customers'].edit;
      } else {
        return this.get('company.permissions')['company'].edit;
      }
    }),
    deletePermission: Ember.computed('company', function () {
      if (this.get('paymentForm')) {
        return this.get('company.permissions')['customers'].delete;
      } else {
        return this.get('company.permissions')['company'].delete;
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      this.store.findAll('account').then(function (accounts) {
        _this3.set('accounts', accounts);
      });
      this.store.findAll('payment').then(function (payments) {
        _this3.set('payments', payments);
      });
      this.store.findAll('customer').then(function (customers) {
        _this3.set('customers', customers);
      });
      this.store.findAll('payee').then(function (payees) {
        _this3.set('payees', payees);
      });
      this.store.findAll('label', {
        reload: true
      }).then(function (labels) {
        _this3.set('labels', labels);
      });

      if (this.get('deposit.cash_account')) {
        this.send('updateCashAccount', this.get('deposit.cash_account.id'));
      } // tooltip logic


      Ember.$('#depositTotal').tooltip({
        placement: "bottom",
        trigger: "hover",
        title: "Deposit amount is calculated from the forms below"
      });

      if (this.get('setCashAccountCleared')) {
        this.set('cashAccountCleared', true);
      }
    },
    accounts: [],
    cashAccounts: Ember.computed('accounts', function () {
      var cashAccounts = this.accounts.filter(function (account) {
        if (account.get('account_type.name').toLowerCase() === 'cash' && !account.get('is_title')) {
          return account;
        }
      });
      return cashAccounts;
    }),
    inputAccounts: Ember.computed('deposit.account_deposits', function () {
      var _this4 = this;

      var accountDeposits = this.get('deposit.account_deposits');

      if (accountDeposits) {
        accountDeposits.map(function (ad) {
          // if (ad.account_id.toString() !== (this.get('check.cash_account_id') ? this.get('check.cash_account_id').toString() : this.get('check.cash_account_id'))) {
          if (ad.account_id.toString() !== _this4.get('deposit.cash_account_id').toString()) {
            Ember.set(ad, 'amount', ad.account_action === 'credit' ? parseFloat(ad.amount).toFixed(2) : parseFloat(ad.amount).toFixed(2) * -1);
          } else if (ad.clear) {
            _this4.set('setCashAccountCleared', true);
          }
        });
        return accountDeposits;
      } else {
        return [{}];
      }
    }),
    inputPayments: Ember.computed('deposit.payment_items', 'deposit.payments', function () {
      if (this.get('deposit.payments') && !this.get('deposit.payment_items')) {
        this.set('deposit.payment_items', this.get('deposit.payments'));
        this.set('paymentOpen', true);
      }

      var paymentItems = this.get('deposit.payment_items');

      if (paymentItems) {
        paymentItems.map(function (paymentItem) {
          Ember.set(paymentItem, 'payment_amount', parseFloat(paymentItem.payment_amount).toFixed(2));
          Ember.set(paymentItem, 'discount', parseFloat(paymentItem.discount).toFixed(2));
        });
        return paymentItems;
      } else {
        return [{}];
      }
    }),
    pagedInputAccounts: (0, _pagedArray.default)('inputAccounts', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    pagedInputPayments: (0, _pagedArray.default)('inputPayments', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    inputAccountsObserver: Ember.observer('inputAccounts', function () {
      try {
        this.set('pagedInputAccounts', (0, _pagedArray.default)('inputAccounts', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    inputPaymentsObserver: Ember.observer('inputPayments', 'inputPayments.@each.amount_due', function () {
      try {
        this.set('pagedInputPayments', (0, _pagedArray.default)('inputPayments', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    customers: Ember.computed(function () {
      return this.store.peekAll('customer').toArray();
    }),
    formatCurrency: function formatCurrency(amount) {
      return parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    },
    voidProcedure: function voidProcedure() {
      this.get('inputAccounts').forEach(function (account) {
        Ember.set(account, 'amount', 0.00);
      });
      this.get('inputPayments').forEach(function (payment) {
        Ember.set(payment, 'discount', 0.00);
        Ember.set(payment, 'payment_amount', 0.00);
      });
      this.send('updateDepositAmount');
    },
    actions: {
      addAccount: function addAccount() {
        this.get('inputAccounts').pushObject({}); // this.set('pagedInputAccounts', pagedArray('inputAccounts', { infinite: "unpaged", page: 1, perPage: 100 }));
      },
      addPayment: function addPayment() {
        this.get('inputPayments').pushObject({}); // this.set('pagedInputPayments', pagedArray('inputPayments', { infinite: "unpaged", page: 1, perPage: 100 }));
      },
      removeAccountInput: function removeAccountInput(index) {
        if (this.get('inputAccounts')[index].id) {
          Ember.set(this.get('inputAccounts')[index], 'removed', true);
        } else {
          this.get('inputAccounts').removeAt(index);
        }

        this.send('updateDepositAmount');
        this.set('pagedInputAccounts', (0, _pagedArray.default)('inputAccounts', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      removePaymentInput: function removePaymentInput(index) {
        this.get('inputPayments').removeAt(index);
        this.send('updateDepositAmount');
        this.set('pagedInputPayments', (0, _pagedArray.default)('inputPayments', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      updateCustomer: function updateCustomer(payee) {
        this.set('deposit.customer_id', payee.id);
        this.setProperties({
          'inputPayments': [{}, {}],
          'deposit.payment_items': null
        });
      },
      updateCashAccount: function updateCashAccount(value) {
        this.set('deposit.cash_account_id', value);
      },
      updateAccountList: function updateAccountList(id, index) {
        Ember.set(this.get('inputAccounts')[index], 'account_id', id);
      },
      updateDiscountAccount: function updateDiscountAccount(id, index) {
        Ember.set(this.get('inputPayments')[index], 'discount_account_id', id);
      },
      setPaymentCategory: function setPaymentCategory(payment, index) {
        var selectOptions = Ember.$("#selectPaymentItem-".concat(index, " option"));
        selectOptions[0].text = "Select Payment Item";
        selectOptions[0].value = "";
        selectOptions[0].selected = true;
        Ember.set(this.get('inputPayments')[index], 'id', null);
        Ember.set(this.get('inputPayments')[index], 'type', null);
        Ember.set(this.get('inputPayments')[index], 'amount_due', "");
        var value = Ember.$("#selectPaymentCategory-".concat(index))[0].value;
        Ember.set(payment, 'type', value);
      },
      updatePaymentList: function updatePaymentList(payment, index) {
        var value = Ember.$("#selectPaymentItem-".concat(index))[0].value;
        var id = value.slice(0, -1);
        payment = this.store.peekRecord('payment', value);
        Ember.set(this.get('inputPayments')[index], 'id', id);
        Ember.set(this.get('inputPayments')[index], 'type', payment.get('type'));
        Ember.set(this.get('inputPayments')[index], 'amount_due', payment.get('amount_due')); // this.set('pagedInputPayments', pagedArray('inputPayments', { infinite: "unpaged", page: 1, perPage: 100 }));
      },
      updateDepositAmount: function updateDepositAmount() {
        var depositAmount = 0;
        this.get('inputAccounts').map(function (account) {
          if (!account.removed && !account.is_cash_account) {
            depositAmount += account.amount ? Number(account.amount) : 0;
          }
        });
        this.get('inputPayments').map(function (payment) {
          depositAmount += payment.payment_amount ? Number(payment.payment_amount) : 0; // depositAmount += payment.discount && payment.discount !== "NaN" ? Number(payment.discount) : 0;
        });

        if (depositAmount > 0) {
          this.set('deposit.void', false);
        }

        this.set('depositAmount', depositAmount.toFixed(2));
      },
      setRecurrency: function setRecurrency(recurrency) {
        if (recurrency.active) {
          this.set('depositRecurrency', recurrency);
        } else {
          this.set('depositRecurrency', null);
        }
      },
      formatAmount: function formatAmount(account, payment, discount) {
        if (account) {
          Ember.set(account, 'amount', Number(account.amount).toFixed(2));
        } else if (payment && !discount) {
          Ember.set(payment, 'payment_amount', Number(payment.payment_amount).toFixed(2));

          if (!payment.discount || payment.discount === "NaN") {
            Ember.set(payment, 'discount', Number("0").toFixed(2));
          }
        } else if (payment && discount) {
          Ember.set(payment, 'discount', Number(payment.discount).toFixed(2));
        }
      },
      updateDepositDate: function updateDepositDate(date) {
        this.set('deposit.deposit_date', date.toISOString().slice(0, 10));
      },
      create: function create() {
        var _this5 = this;

        var depositAccounts = this.get('inputAccounts').map(function (inputAccount) {
          if (inputAccount.account_id || inputAccount.account_id === 0) {
            inputAccount.label_ids = inputAccount.labels ? inputAccount.labels.map(function (label) {
              return label.id;
            }) : [];
            return inputAccount;
          }
        });
        var depositPayments = {};
        var accountPaymentAmountSum = 0;
        this.get('inputPayments').map(function (inputPayment) {
          accountPaymentAmountSum += inputPayment.payment_amount ? parseFloat(Number(inputPayment.payment_amount).toFixed(2)) : 0;

          if (inputPayment.id || inputPayment.id === 0) {
            var _data = {
              payment_amount: inputPayment.payment_amount,
              discount: inputPayment.discount,
              discount_account_id: inputPayment.discount_account_id,
              id: inputPayment.id,
              label_ids: inputPayment.labels ? inputPayment.labels.map(function (label) {
                return label.id;
              }) : []
            };

            if (depositPayments[inputPayment.type]) {
              depositPayments[inputPayment.type].push(_data);
            } else {
              depositPayments[inputPayment.type] = [_data];
            }
          }
        }); // Filter out unpopulated account rows

        this.set('deposit.accounts', depositAccounts.filter(Boolean));
        this.set('deposit.payments', depositPayments);
        this.set('deposit.recurrency', this.get("depositRecurrency"));
        var data = JSON.stringify({
          deposit: this.get('deposit')
        });
        this.get('inputAccounts').forEach(function (account) {
          accountPaymentAmountSum += account.amount ? parseFloat(Number(account.amount).toFixed(2)) : 0;
        }); // this.get('inputPayments').forEach((payment) => {
        //   accountPaymentAmountSum += payment.payment_amount ? parseFloat(Number(payment.payment_amount).toFixed(2)) : 0;
        //   // accountPaymentAmountSum += payment.discount ? Number(payment.discount) : 0;
        // });

        if (parseFloat(Number(accountPaymentAmountSum).toFixed(2)) !== parseFloat(Number(this.get('deposit.amount')).toFixed(2))) {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Account and payment amount sum must be equal to deposit amount</span></div>"
          });
          return;
        }

        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/deposits"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            _this5.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Deposit Created</span></div>'
            });

            if (_this5.accountId) {
              _this5.get('router').transitionTo('companies.show.accounts.transactions', _this5.companyId, _this5.accountId);
            } else if (_this5.paymentForm) {
              _this5.set('deposit', {
                amount: 0,
                cash_account_id: _this5.get('deposit.cash_account_id')
              });

              _this5.store.findAll('payment').then(function (payments) {
                _this5.set('payments', payments);
              });

              _this5.generateDepositNumber();

              _this5.set('inputPayments', [{}, {}]);

              Ember.$('#payeeSelect').val(0);
            } else {
              _this5.sendAction('reloadRoute');

              _this5.get('router').transitionTo('companies.show.deposits', _this5.companyId, {
                queryParams: {
                  show: true
                }
              });
            }
          },
          errorCallback: function errorCallback(response) {
            _this5.errorHandler(response);
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      update: function update() {
        var _this = this;

        var accountPaymentAmountSum = 0;
        var depositAccounts = this.get('inputAccounts').map(function (inputAccount) {
          if (inputAccount.account_id || inputAccount.account_id === 0) {
            inputAccount.label_ids = inputAccount.labels ? inputAccount.labels.map(function (label) {
              return label.id;
            }) : [];
            return inputAccount;
          }
        });
        var depositPayments = {};
        this.get('inputPayments').map(function (inputPayment) {
          accountPaymentAmountSum += inputPayment.payment_amount ? parseFloat(Number(inputPayment.payment_amount).toFixed(2)) : 0;

          if (inputPayment.id || inputPayment.id === 0) {
            var data = {
              payment_amount: inputPayment.payment_amount,
              discount: inputPayment.discount,
              discount_account_id: inputPayment.discount_account_id,
              id: inputPayment.id,
              label_ids: inputPayment.labels ? inputPayment.labels.map(function (label) {
                return label.id;
              }) : []
            };

            if (depositPayments[inputPayment.type]) {
              depositPayments[inputPayment.type].push(data);
            } else {
              depositPayments[inputPayment.type] = [data];
            }
          }
        });
        this.set('deposit.accounts', depositAccounts.filter(Boolean));
        this.set('deposit.payments', depositPayments);
        this.set('deposit.recurrency', this.get("depositRecurrency"));
        this.get('inputAccounts').forEach(function (account) {
          if (!account.is_cash_account && !account.removed) {
            accountPaymentAmountSum += account.amount ? parseFloat(Number(account.amount).toFixed(2)) : 0;
          }
        }); // this.get('inputPayments').forEach((payment) => {
        //   accountPaymentAmountSum += payment.payment_amount ? parseFloat(Number(payment.payment_amount).toFixed(2)) : 0;
        //   // accountPaymentAmountSum += payment.discount ? Number(payment.discount) : 0;
        // });

        if (parseFloat(Number(accountPaymentAmountSum).toFixed(2)) !== parseFloat(Number(this.get('deposit.amount')).toFixed(2))) {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Account amount sum must be equal to deposit amount</span></div>"
          });
          return;
        }

        this.store.findRecord('deposit', this.deposit.id, {
          backgroundReload: false
        }).then(function (deposit) {
          deposit.setProperties(_this.deposit);
          deposit.save().then(function () {
            _this.sendAction('reloadRoute');

            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Deposit Updated</span></div>'
            });

            if (_this.accountId) {
              _this.get('router').transitionTo('companies.show.accounts.transactions', _this.companyId, _this.accountId);
            } else if (_this.reconciliationId) {
              if (_this.reconciliationId === "new") {
                _this.get('router').transitionTo('companies.show.reconciliations.new', _this.companyId);
              } else {
                _this.get('router').transitionTo('companies.show.reconciliations.show', _this.companyId, _this.reconciliationId);
              }
            } else {
              _this.get('router').transitionTo('companies.show.deposits', _this.companyId, {
                queryParams: {
                  show: true
                }
              });
            }
          }, function (response) {
            _this.errorHandler(response);
          });
        });
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      deleteDeposit: function deleteDeposit() {
        var _this6 = this;

        this.deposit.destroyRecord().then(function () {
          _this6.get('notify').alert({
            html: '<i class="icon-warning lg"></i> <div class="message-text"><span>Deposit Deleted</span></div>'
          });

          _this6.sendAction('reloadRoute');

          _this6.get('router').transitionTo('companies.show.deposits', _this6.companyId);
        }).catch(function (response) {
          _this6.errorHandler(response);
        });
      },
      loadNext: function loadNext() {
        this.get('pagedDeposits').loadNextPage();
      },
      toggleAccountCollapse: function toggleAccountCollapse() {
        this.toggleProperty('accountOpen');
      },
      togglePaymentCollapse: function togglePaymentCollapse() {
        this.toggleProperty('paymentOpen');
      },
      cancel: function cancel() {
        if (Ember.typeOf(this.get('deposit')) === "instance") {
          this.get('deposit').rollbackAttributes();
        }

        if (this.accountId) {
          this.get('router').transitionTo('companies.show.accounts.transactions', this.companyId, this.accountId);
        } else if (this.reconciliationId) {
          if (this.reconciliationId === "new") {
            this.get('router').transitionTo('companies.show.reconciliations.new', this.companyId);
          } else {
            this.get('router').transitionTo('companies.show.reconciliations.show', this.companyId, this.reconciliationId);
          }
        } else if (this.paymentForm) {
          this.get('router').transitionTo('companies.show.customers', this.companyId);
        } else {
          this.get('router').transitionTo('companies.show.deposits', this.companyId);
        }
      },
      setAccountLabels: function setAccountLabels(index, labels) {
        Ember.set(this.get('inputAccounts')[index], 'labels', labels);
      },
      setPaymentLabels: function setPaymentLabels(index, labels) {
        Ember.set(this.get('inputPayments')[index], 'labels', labels);
      },
      toggleConfirmVoid: function toggleConfirmVoid() {
        this.toggleProperty('confirmVoid');
      },
      voidDeposit: function voidDeposit() {
        this.set('deposit.void', true);
        this.voidProcedure();
        this.set('confirmVoid', false);
      }
    }
  });

  _exports.default = _default;
});