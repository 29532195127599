define("account-berry-frontend/companies/show/employees/vacation-sickness-adjustments/controller", ["exports", "ember-cli-pagination/computed/paged-array"], function (_exports, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    confirmDelete: false,
    search: "",
    sortAscending: false,
    pagedVacationSicknessAdjustments: (0, _pagedArray.default)('vacationSicknessAdjustments', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    vacationSicknessAdjustments: Ember.computed('model', function () {
      return this.get('model');
    }),
    modelObserver: Ember.observer('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      try {
        this.set('vacationSicknessAdjustments', this.store.peekAll('vacation-sickness-adjustment').filter(function (employee) {
          return !employee.get('isDeleted');
        }));
        this.set('pagedVacationSicknessAdjustments', (0, _pagedArray.default)('vacationSicknessAdjustments', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this = this;

      var channel = this.get('session.pusher').subscribe("adjustment-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          _this.updateAdjustmentList(data.adjustment);
        }
      });
      channel.bind('updated', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var adjustmentId = data.adjustment.id;
          delete data.adjustment.id;

          var adjustment = _this.store.peekRecord('vacation-sickness-adjustment', adjustmentId);

          adjustment.setProperties(data.adjustment);
        }
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var adjustment = _this.store.peekRecord('vacation-sickness-adjustment', data.adjustment_id);

          adjustment.deleteRecord();

          _this.set('vacationSicknessAdjustments', _this.store.peekAll('vacation-sickness-adjustment').filter(function (adjustment) {
            return !adjustment.get('isDeleted');
          }));

          _this.set('pagedVacationSicknessAdjustments', (0, _pagedArray.default)('vacationSicknessAdjustments', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        }
      });
      this.set('subscribedToChannel', true);
    },
    updateAdjustmentList: function updateAdjustmentList(adjustment) {
      this.store.push({
        data: {
          id: adjustment.id,
          type: 'vacation-sickness-adjustment',
          attributes: adjustment
        }
      });
      this.set('vacationSicknessAdjustments', this.store.peekAll('vacation-sickness-adjustment').filter(function (adjustment) {
        return !adjustment.get('isDeleted');
      }));
      this.set('pagedVacationSicknessAdjustments', (0, _pagedArray.default)('vacationSicknessAdjustments', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['employees'];
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3>");
      table += "<h3 style='text-align: center'>Vacation & Sickness Adjustments</h3>";
      table += "<table id='vacationSicknessTable' style='width:100%; font-size: 1vw'>";
      table += "<thead><tr><th style='text-align: left'>DATE</th>";
      table += "<th style='text-align: left'>EMPLOYEE</th>";
      table += "<th style='text-align: right'>VACATION ADJUSTMENT</th>";
      table += "<th style='text-align: right'>SICK ADJUSTMENT</th></tr></thead><tbody>";
      this.get('vacationSicknessAdjustments').forEach(function (adjustment) {
        table += "<tr><td style='text-align: left'>".concat(moment(adjustment.get('date')).format('MM/DD/YYYY'), "</td>");
        table += "<td style='text-align: left'>".concat(adjustment.get('employee'), "</td>");
        table += "<td style='text-align: right'>".concat(parseFloat(adjustment.get('vacation_adjustment')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style='text-align: right'>".concat(parseFloat(adjustment.get('sickness_adjustment')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
      });
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      addAdjustmentToList: function addAdjustmentToList(vacationSicknessAdjustment) {
        if (this.get('vacationSicknessAdjustments')) {
          try {
            this.get('vacationSicknessAdjustments').pushObject(vacationSicknessAdjustment);
          } catch (e) {
            this.get('vacationSicknessAdjustments').push(vacationSicknessAdjustment);
            this.set('vacationSicknessAdjustments', this.get('vacationSicknessAdjustments'));
          }

          this.set('pagedVacationSicknessAdjustments', (0, _pagedArray.default)('vacationSicknessAdjustments', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        } else {
          this.set('vacationSicknessAdjustments', [vacationSicknessAdjustment]);
        }
      },
      reloadRoute: function reloadRoute() {
        this.send('refreshModel');
        ;
      },
      loadNext: function loadNext() {
        this.get('pagedVacationSicknessAdjustments').loadNextPage();
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('vacationSicknessAdjustments', this.get('vacationSicknessAdjustments').sortBy(property));
        } else {
          this.set('vacationSicknessAdjustments', this.get('vacationSicknessAdjustments').sortBy(property).reverse());
        }

        this.set('pagedVacationSicknessAdjustments', (0, _pagedArray.default)('vacationSicknessAdjustments', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      print: function print() {
        var table = this.generateTable();
        Ember.$('.print-container').empty();
        Ember.$('.print-container').append(table);
        Ember.$('.print-container').printThis({
          importCSS: false
        }); //   let w = window.open();
        //   w.document.write("<div id='print-container'></div>");
        //   w.document.getElementById('print-container').innerHTML += table;
        //  w.print();
        //  w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        Ember.$('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        Ember.$('#vacationSicknessTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_vacation_sickness_adjustments_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        Ember.$('#vacationSicknessTable').remove();
        Ember.$('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});