define("account-berry-frontend/companies/show/reports/vendor-tax-detail/route", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notify: Ember.inject.service(),
    setupController: function setupController(controller) {
      var companyId = this.paramsFor('companies.show').company_id;
      var company = this.store.peekRecord('company', companyId);

      if (company.get('stripe_plan_id') !== _environment.default.stripe_plan_id.gold_plan) {
        this.get('notify').alert({
          html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>This feature is only available in the gold subscription plan</span></div>"
        });
        this.transitionTo('companies.show.reports', companyId);
      }

      controller.set('breadCrumb', 'Vendor Tax Detail');
      controller.set('companyId', companyId);
      controller.setProperties({
        vendorTaxDetailData: null,
        fromDate: null,
        toDate: null
      });
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.reports.vendor-tax-detail', {
        into: 'application',
        model: model
      });
    }
  });

  _exports.default = _default;
});