define("account-berry-frontend/components/big-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OLDvoGVD",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"big-header flex-row col-md-12\"],[7],[0,\"\\n  \"],[1,[25,\"fa-icon\",[[20,[\"icon\"]]],null],false],[0,\"\\n  \"],[6,\"h1\"],[7],[0,\" \"],[1,[18,\"main-text\"],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/big-header/template.hbs"
    }
  });

  _exports.default = _default;
});