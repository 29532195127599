define("account-berry-frontend/companies/show/labels/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortAscending: false,
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    actions: {
      reloadRoute: function reloadRoute() {
        this.send('refreshModel');
        ;
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('model', this.get('model').sortBy(property));
        } else {
          this.set('model', this.get('model').sortBy(property).reverse());
        }
      }
    }
  });

  _exports.default = _default;
});