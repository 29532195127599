define("account-berry-frontend/settings/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    newPaymentMethod: false,
    init: function init() {
      this._super();

      var _this = this;

      setTimeout(function () {
        _this.get('applicationController').set('showLoading', false);
      }, 2000);
    },
    creditCardInfo: Ember.computed('model', function () {
      if (this.get('model.card_brand')) {
        return "Card: ".concat(this.get('model.card_brand'), "\nLast 4 Digits: ").concat(this.get('model.card_last4_digits'), "\nExp Date: ").concat(this.get('model.card_exp_date'));
      } else {
        return "No Payment Method";
      }
    }),
    actions: {
      toggleNewPaymentMethod: function toggleNewPaymentMethod() {
        this.toggleProperty('newPaymentMethod');
      },
      reloadRoute: function reloadRoute() {
        this.send('refreshModel');
      },
      deleteAccount: function deleteAccount() {
        var _this2 = this;

        this.model.destroyRecord().then(function () {
          _this2.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Account deleted</span></div>'
          });

          window.location = "https://goo.gl/forms/oFZY0Ic0iG4wkMmA3"; // this.get('session').invalidate();
        }, function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      firstConfirmation: function firstConfirmation() {
        this.set('firstConfirmDelete', true);
      },
      secondConfirmation: function secondConfirmation() {
        this.set('secondConfirmDelete', true);
      },
      thirdConfirmation: function thirdConfirmation() {
        this.set('thirdConfirmDelete', true);
      },
      cancelDeletion: function cancelDeletion() {
        this.setProperties({
          'firstConfirmDelete': false,
          'secondConfirmDelete': false,
          'thirdConfirmDelete': false
        });
      }
    }
  });

  _exports.default = _default;
});