define("account-berry-frontend/components/item-form/component", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    refNumber: Ember.inject.service(),
    session: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    notify: Ember.inject.service('notify'),
    init: function init() {
      var _this2 = this;

      this._super();

      if (!this.get('item')) {
        this.set('item', {
          on_hand: 0,
          ordered: 0,
          tax_ids: []
        });
      }

      if (this.newItem) {
        this.generateCheckNumber();
      }

      var companyId = this.companyId;
      this.store.findAll('account').then(function (accounts) {
        var filteredAccounts = accounts.toArray().filter(function (account) {
          return account.get('company_id').toString() === companyId.toString();
        });

        _this2.set('accounts', filteredAccounts);
      });
      this.store.findAll('tax').then(function (taxes) {
        _this2.set('taxes', taxes.map(function (tax) {
          tax.set('selected', _this2.get('item.taxes') ? !!_this2.get('item.taxes').find(function (t) {
            return t.id.toString() === tax.id.toString();
          }) : false); // if (this.get('item.taxes')) {
          //   tax.selected = !!this.get('item.taxes').find(t => t.id.toString() === tax.id.toString());
          // } else {
          //   tax.selected = false;
          // }

          return tax;
        }));
      });
    },
    generateCheckNumber: function generateCheckNumber() {
      var _this3 = this;

      var ajaxOptions = {
        url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/items/number"),
        method: 'GET',
        contentType: 'application/json',
        successCallback: function successCallback(response) {
          var itemNumber = _this3.get('refNumber').generate(response.last_number || "");

          _this3.set('item.number', itemNumber);
        },
        errorCallback: function errorCallback() {
          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\">An unexpected error occurred. Refresh page and try again.</div>"
          });
        }
      };
      this.get('ajaxCall').request(ajaxOptions);
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    preferredAccount: Ember.computed('accounts', 'item.preferred_account_id', function () {
      if (this.get('item.preferred_account_id') && this.get('accounts')) {
        var preferredAccount = this.store.peekRecord('account', this.get('item.preferred_account_id')).data;
        preferredAccount['account_id'] = this.get('item.preferred_account_id');
        return preferredAccount;
      }
    }),
    actions: {
      setItemImage: function setItemImage(file) {
        var reader = new FileReader();

        var _this = this;

        reader.onload = function (e) {
          _this.set('imagePreview', e.target.result);
        };

        reader.readAsDataURL(file);
        this.set('item.picture', file);
      },
      create: function create() {
        var _this4 = this;

        this.set('item.company_id', this.companyId);
        this.set('item.tax_ids', this.get('taxes').map(function (tax) {
          if (tax.selected) {
            return tax.id;
          }
        }).filter(Boolean));
        var newItem = this.store.createRecord('item', this.item);
        this.set('item', {});
        newItem.save().then(function () {
          _this4.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Item Created</span></div>'
          });

          _this4.sendAction('reloadRoute');

          _this4.get('router').transitionTo('companies.show.items', _this4.companyId);
        }, function (response) {
          var errors = "An Error Occured";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          }

          newItem.rollbackAttributes();

          _this4.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      update: function update() {
        var _this5 = this;

        this.store.findRecord('item', this.item.id, {
          backgroundReload: false
        }).then(function (item) {
          item.setProperties(_this5.item);
          item.set('tax_ids', _this5.get('taxes').map(function (tax) {
            if (tax.selected) {
              return tax.id;
            }
          }).filter(Boolean));
          item.save().then(function () {
            _this5.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Item Updated</span></div>'
            });

            _this5.sendAction('reloadRoute');

            _this5.get('router').transitionTo('companies.show.items', _this5.companyId);
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this5.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          });
        });
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      deleteItem: function deleteItem() {
        var _this6 = this;

        this.item.destroyRecord().then(function () {
          _this6.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Item Deleted</span></div>"
          });

          _this6.sendAction('reloadRoute');

          _this6.get('router').transitionTo('companies.show.items', _this6.companyId);
        });
      },
      setPreferredAccount: function setPreferredAccount(id) {
        this.set('item.preferred_account_id', id);
      },
      cancel: function cancel() {
        if (Ember.typeOf(this.get('item')) === "instance") {
          this.get('item').rollbackAttributes();
        }

        this.get('router').transitionTo('companies.show.items', this.companyId);
      }
    }
  });

  _exports.default = _default;
});