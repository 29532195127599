define("account-berry-frontend/companies/controller", ["exports", "ember-cli-pagination/computed/paged-array"], function (_exports, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    paymentModal: false,
    confirmDelete: false,
    router: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    search: "",
    company: Ember.computed(function () {
      return {};
    }),
    sortAscending: false,
    filters: Ember.computed(function () {
      return [{
        name: "NAME",
        value: "name"
      }, {
        name: "EIN",
        value: "ein"
      }, {
        name: "NAICS",
        value: "naics_code"
      }, {
        name: "EMAIL",
        value: "email"
      }, {
        name: "WEBSITE",
        value: "website"
      }];
    }),
    filter: "name",
    init: function init() {
      if (!localStorage.shownPaymentModal && !this.get('session.data.authenticated.stripe_card_id')) {
        this.send('togglePaymentModal');
      }

      ;
    },
    pagedCompanies: (0, _pagedArray.default)('companies', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    modelObserver: Ember.observer('model', function () {
      try {
        // // // this.set('companies', this.store.peekAll('companies').filter((companies) => { return !companies.get('isDeleted'); }))
        if (this.get('companies.length') === 0) {
          this.get('router').transitionTo('companies.new');
        }

        this.set('pagedCompanies', (0, _pagedArray.default)('companies', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        // Ember.set(this, 'pagedCompanies', pagedArray('companies', { infinite: "unpaged", page: 1, perPage: 100 }));
        Ember.Logger.error(err);
      }
    }),
    companies: Ember.computed('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      return this.model;
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this = this;

      var userChannel = this.get('session.pusher').subscribe("user-channel-".concat(this.get('session.data.authenticated.id')));
      userChannel.bind('created', function (data) {
        // this.send('addCompanyToList', data.company);
        _this.addCompanyToList(data.company);
      });
      userChannel.bind('deleted', function (data) {
        if (_this.get('companies')) {
          var company = _this.store.peekRecord('company', data.company_id);

          company.deleteRecord();
        }
      });
      this.set('subscribedToChannel', true);
    },
    addCompanyToList: function addCompanyToList(company) {
      if (this.get('companies')) {
        this.get('companies').push(company);
        this.set('model', this.get('companies')); // this.set('pagedCompanies', pagedArray('companies', { infinite: "unpaged", page: 1, perPage: 100 }));
      } else {
        this.set('model', [company]);
      }

      this.set('pagedCompanies', (0, _pagedArray.default)('companies', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>Companies List</h3><table id='companiesTable' style='width:100%; font-size: 12px'>";
      table += "<thead><tr><th style='text-align: left;'>COMPANY NAME</th>";
      table += "<th style='text-align: left;'>EMAIL</th>";
      table += "<th style='text-align: left;'>SUBSCRIPTION PLAN</th>";
      table += "<th style='text-align: left;'>WEBSITE</th>";
      table += "<th style='text-align: left;'>ROLE</th></tr></thead>";
      table += "<tbody>";
      this.get('companies').forEach(function (company) {
        table += "<tr>";
        table += "<td>".concat(company.get('name'), "</td>");
        table += "<td>".concat(company.get('email') || "", "</td>");
        table += "<td>".concat(company.get('stripe_plan_id'), "</td>");
        table += "<td>".concat(company.get('website') || "", "</td>");
        table += "<td>".concat(company.get('role') || "", "</td>");
        table += "</tr>";
      });
      table += "</tbody></table></div>";
      return table;
    },
    generateCSV: function generateCSV() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>Companies List</h3><table id='companiesTable' style='width:100%; font-size: 14px'>";
      table += "firstConfirmation<th style='text-align: left;'>PHYSICAL ADDRESS LINE ONE</th>";
      table += "<th style='text-align: left;'>PHYSICAL ADDRESS LINE TWO</th>";
      table += "<th style='text-align: left;'>PHYSICAL ADDRESS CITY</th>";
      table += "<th style='text-align: left;'>PHYSICAL ADDRESS STATE</th>";
      table += "<th style='text-align: left;'>PHYSICAL ADDRESS ZIP</th>";
      table += "<th style='text-align: left;'>PHYSICAL ADDRESS COUNTRY</th>";
      table += "<th style='text-align: left;'>PHONE</th>";
      table += "<th style='text-align: left;'>EMAIL</th>";
      table += "<th style='text-align: left;'>WEBSITE</th>";
      table += "<th style='text-align: left;'>EIN</th>";
      table += "<th style='text-align: left;'>INCORPORATION DATE</th>";
      table += "<th style='text-align: left;'>MERCHANT REGISTRATION</th>";
      table += "<th style='text-align: left;'>UNEMPLOYMENT NUMBER</th>";
      table += "<th style='text-align: left;'>TERMINATION DATE</th>";
      table += "<th style='text-align: left;'>NAICS CODE</th>";
      table += "<th style='text-align: left;'>CONTACT NAME</th>";
      table += "<th style='text-align: left;'>ACCOUNTING METHOD</th>";
      table += "<th style='text-align: left;'>TAX FORM</th>";
      table += "<th style='text-align: left;'>FIRST MONTH ACCOUNTING YEAR</th>";
      table += "<th style='text-align: left;'>LEGAL NAME</th>";
      table += "<th style='text-align: left;'>SUBSCRIPTION PLAN</th></tr></thead>";
      table += "<tbody>";
      this.get('companies').forEach(function (company) {
        table += "<tr>";
        table += "<td>".concat(company.get("name") || "", "</td>");
        table += "<td>".concat(company.get("postal_address_line_one") || "", "</td>");
        table += "<td>".concat(company.get("postal_address_line_two") || "", "</td>");
        table += "<td>".concat(company.get("postal_address_city") || "", "</td>");
        table += "<td>".concat(company.get("postal_address_state") || "", "</td>");
        table += "<td>".concat(company.get("postal_address_zip") || "", "</td>");
        table += "<td>".concat(company.get("postal_address_country") || "", "</td>");
        table += "<td>".concat(company.get("physical_address_line_one") || "", "</td>");
        table += "<td>".concat(company.get("physical_address_line_two") || "", "</td>");
        table += "<td>".concat(company.get("physical_address_city") || "", "</td>");
        table += "<td>".concat(company.get("physical_address_state") || "", "</td>");
        table += "<td>".concat(company.get("physical_address_zip") || "", "</td>");
        table += "<td>".concat(company.get("physical_address_country") || "", "</td>");
        table += "<td>".concat(company.get("phone") || "", "</td>");
        table += "<td>".concat(company.get("email") || "", "</td>");
        table += "<td>".concat(company.get("website") || "", "</td>");
        table += "<td>".concat(company.get("ein") || "", "</td>");
        table += "<td>".concat(company.get("incorporation_date") || "", "</td>");
        table += "<td>".concat(company.get("merchant_registration") || "", "</td>");
        table += "<td>".concat(company.get("unemployment_number") || "", "</td>");
        table += "<td>".concat(company.get("termination_date") || "", "</td>");
        table += "<td>".concat(company.get("naics_code") || "", "</td>");
        table += "<td>".concat(company.get("contact_name") || "", "</td>");
        table += "<td>".concat(company.get("accounting_method") || "", "</td>");
        table += "<td>".concat(company.get("tax_form") || "", "</td>");
        table += "<td>".concat(company.get("first_month_accounting_year") || "", "</td>");
        table += "<td>".concat(company.get("legal_name") || "", "</td>");
        table += "<td>".concat(company.get("stripe_plan_id") || "", "</td>");
        table += "</tr>";
      });
      table += "</tbody></table></div>";
      return table;
    },
    filterCompanies: Ember.observer('search', function () {
      var search = this.search;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      var filter = this.filter;

      if (search === "" || search.trim().length === 0) {
        this.set('companies', this.model);
      } else {
        this.set('companies', this.model.filter(function (company) {
          var companyValue = company.get(filter);

          if (companyValue) {
            if (isNaN(companyValue)) {
              companyValue = companyValue.toLowerCase();
            }

            if (companyValue.includes(search)) {
              return company;
            }
          }
        }));
      }

      this.set('pagedCompanies', (0, _pagedArray.default)('companies', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    companiesObserver: Ember.observer('model', function () {
      this.set('pagedCompanies', (0, _pagedArray.default)('companies', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    actions: {
      reloadRoute: function reloadRoute() {
        this.send('refreshModel');
      },
      togglePaymentModal: function togglePaymentModal() {
        localStorage.shownPaymentModal = true;
        this.toggleProperty('paymentModal');
      },
      linkToCompany: function linkToCompany(companyId) {
        window.location.href = "".concat(location.href).concat(companyId);
      },
      linkToEdit: function linkToEdit(companyId) {
        window.location.href = "".concat(location.href).concat(companyId, "/company-settings/edit");
      },
      changeFilter: function changeFilter(value) {
        this.set('filter', value);
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        if (id) {
          var self = this;
          this.store.findRecord('company', id, {
            backgroundReload: false
          }).then(function (company) {
            self.set('company', company);
          });
        }

        this.toggleProperty('firstConfirmDelete');
        this.toggleProperty('secondConfirmDelete');
      },
      firstDeleteConfirmation: function firstDeleteConfirmation(id) {
        if (id) {
          var self = this;
          this.store.findRecord('company', id, {
            backgroundReload: false
          }).then(function (company) {
            self.set('company', company);
          });
        } // this.toggleProperty('confirmDelete');


        this.set('firstConfirmDelete', true);
      },
      secondDeleteConfirmation: function secondDeleteConfirmation() {
        this.set('secondConfirmDelete', true);
      },
      cancel: function cancel() {
        this.setProperties({
          firstConfirmDelete: false,
          secondConfirmDelete: false,
          companyNameConfirmation: ""
        });
      },
      deleteCompany: function deleteCompany() {
        var _this2 = this;

        this.company.destroyRecord().then(function () {
          // this.set('company', {});
          _this2.send('cancel');

          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Company Deleted</span></div>"
          });
        }).catch(function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this2.company.rollbackAttributes();

          _this2.send('cancel');

          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
        this.set('pagedCompanies', (0, _pagedArray.default)('companies', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      loadNext: function loadNext() {
        this.get('pagedCompanies').loadNextPage();
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('companies', this.get('companies').sortBy(property));
        } else {
          this.set('companies', this.get('companies').sortBy(property).reverse());
        }
      },
      print: function print() {
        var table = this.generateTable();
        Ember.$('.print-container').empty();
        Ember.$('.print-container').append(table);
        Ember.$('.print-container').printThis({
          importCSS: false
        }); // let w = window.open();
        // w.document.write("<div id='print-container'></div>");
        // w.document.getElementById('print-container').innerHTML += table;
        // w.print();
        // w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateCSV();
        Ember.$('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        Ember.$('#companiesTable').table2CSV({
          delivery: 'download',
          filename: "companies_table_".concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        Ember.$('#companiesTable').remove();
        Ember.$('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});