define("account-berry-frontend/components/file-uploader/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    elementId: 'uploadFile',
    type: 'file',
    // attributeBindings: ['multiple'],
    accept: '*',
    multiple: false,
    change: function change(event) {
      var input = event.currentTarget;

      if (!Ember.isEmpty(input.files)) {
        this.sendAction("filesChanged", input.files[0]);
      }
    }
  });

  _exports.default = _default;
});