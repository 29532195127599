define("account-berry-frontend/companies/show/employees/employees-table/rates/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(param) {
      return this.store.findRecord('employee-rate', param.employee_rate_id);
    },
    setupController: function setupController(controller, model) {
      controller.set('breadCrumb', this.store.peekRecord('employee', model.get('employee_id')).get('name'));
      controller.set('companyId', this.paramsFor('companies.show').company_id);
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.employees.employees-table.rates.show', {
        into: 'application',
        model: model
      });
    }
  });

  _exports.default = _default;
});