define("account-berry-frontend/components/infinite-scroll/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["infiniteScroll"],
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.$(".infiniteScroll").on('scroll', function () {
        if (Ember.$(this).scrollTop() + Ember.$(this).innerHeight() >= Ember.$(this)[0].scrollHeight - 1) {
          _this.sendAction();
        }
      });
    }
  });

  _exports.default = _default;
});