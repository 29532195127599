define("account-berry-frontend/components/dashboard-profit-loss/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mqFw6qXB",
    "block": "{\"symbols\":[\"placeholder\"],\"statements\":[[6,\"div\"],[9,\"class\",\"col-md-6 flex-column flex-center dashboard-item\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"profitLoss\"]]],null,{\"statements\":[[0,\"    \"],[6,\"h4\"],[7],[6,\"strong\"],[7],[0,\"Profit & Loss Analysis\"],[8],[8],[0,\"\\n    \"],[6,\"h4\"],[7],[0,\"Net Income: \"],[6,\"strong\"],[7],[0,\"$\"],[1,[25,\"format-currency\",[[20,[\"netIncome\"]],true],null],false],[8],[8],[0,\"\\n    \"],[1,[25,\"ember-chart\",null,[[\"type\",\"data\",\"options\"],[\"horizontalBar\",[20,[\"profitLoss\"]],[20,[\"profitLossOptions\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"content-placeholders\",null,null,{\"statements\":[[0,\"      \"],[1,[19,1,[\"chart\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/dashboard-profit-loss/template.hbs"
    }
  });

  _exports.default = _default;
});