define("account-berry-frontend/password-reset/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    notify: Ember.inject.service('notify'),
    queryParams: ['resetPasswordToken'],
    resetPasswordToken: null,
    validPassword: Ember.computed('password', 'passwordConfirmation', function () {
      return this.get('password') === this.get('passwordConfirmation') ? true : false;
    }),
    actions: {
      passwordReset: function passwordReset() {
        var _this = this;

        if (this.get('validPassword')) {
          var data = {
            reset_password_token: this.get('resetPasswordToken'),
            password: this.get('password'),
            password_confirmation: this.get('passwordConfirmation')
          };
          this.get('ajax').request("".concat(_environment.default.apiURL, "/users/password"), {
            method: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify(data)
          }).then(function () {
            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Password has been reset</span></div>'
            });
          }).catch(function () {
            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Unable to reset password</span></div>"
            });
          });
          this.transitionToRoute('application');
        } else {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Passwords do not match</span></div>"
          });
        }
      }
    }
  });

  _exports.default = _default;
});