define("account-berry-frontend/physical-inventory/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    date: _emberData.default.attr(),
    ref_number: _emberData.default.attr(),
    account_id: _emberData.default.attr(),
    company_id: _emberData.default.attr(),
    physical_inventory_items: _emberData.default.attr(),
    inventory_items: _emberData.default.attr(),
    uneditable: _emberData.default.attr(),
    labels: _emberData.default.attr(),
    label_ids: _emberData.default.attr()
  });

  _exports.default = _default;
});