define("account-berry-frontend/helpers/multiply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.multiply = multiply;
  _exports.default = void 0;

  function multiply(params
  /*, hash*/
  ) {
    return params;
  }

  var _default = Ember.Helper.helper(function (params) {
    var total;
    params.forEach(function (value) {
      value = Number(value).toFixed(2);

      if (total || total === 0) {
        total *= Number(value);
      } else {
        total = Number(value);
      }
    });
    return total;
  });

  _exports.default = _default;
});