define("account-berry-frontend/settings/subscription-invoices/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.set('breadCrumb', 'Subscription Invoices');
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('settings.subscription-invoices', {
        into: 'application',
        model: model
      });
    }
  });

  _exports.default = _default;
});