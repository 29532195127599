define("account-berry-frontend/companies/show/employees/employees-table/controller", ["exports", "ember-cli-pagination/computed/paged-array"], function (_exports, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    newEmployee: false,
    employeeForm: false,
    confirmDelete: false,
    confirmReActivate: false,
    search: "",
    sortAscending: false,
    filter: "first_name",
    filters: [{
      name: "FIRST NAME",
      value: "first_name"
    }, {
      name: "LAST NAME",
      value: "last_name"
    }, {
      name: "EMAIL",
      value: "email"
    }],
    pagedEmployees: (0, _pagedArray.default)('filteredEmployees', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    modelObserver: Ember.observer('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      try {
        this.set('filteredEmployees', this.store.peekAll('employee').filter(function (employee) {
          return !employee.get('isDeleted');
        }));
        this.set('pagedEmployees', (0, _pagedArray.default)('filteredEmployees', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    totalEmployees: Ember.computed('filteredEmployees', function () {
      return this.get('filteredEmployees.length');
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this = this;

      var channel = this.get('session.pusher').subscribe("employee-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          _this.updateEmployeeList(data.employee);
        }
      });
      channel.bind('updated', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var employeeId = data.employee.id;
          delete data.employee.id;

          var employee = _this.store.peekRecord('employee', employeeId);

          employee.setProperties(data.employee);
        }
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var employee = _this.store.peekRecord('employee', data.employee_id);

          employee.deleteRecord();

          _this.set('filteredEmployees', _this.store.peekAll('employee').filter(function (employee) {
            return !employee.get('isDeleted');
          }));

          _this.set('pagedEmployees', (0, _pagedArray.default)('filteredEmployees', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        }
      });
      this.set('subscribedToChannel', true);
    },
    updateEmployeeList: function updateEmployeeList(employee) {
      this.store.push({
        data: {
          id: employee.id,
          type: 'employee',
          attributes: employee
        }
      });
      this.set('filteredEmployees', this.store.peekAll('employee').filter(function (employee) {
        return !employee.get('isDeleted');
      }));
      this.set('pagedEmployees', (0, _pagedArray.default)('filteredEmployees', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    filteredEmployees: Ember.computed('model', function () {
      return this.model;
    }),
    filterEmployees: Ember.observer('search', function () {
      var search = this.search;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      var filter = this.filter;

      if (search === "" || search.trim().length === 0) {
        this.set('filteredEmployees', this.model);
      } else {
        this.set('filteredEmployees', this.model.filter(function (employee) {
          var employeeValue = employee.get(filter);

          if (employeeValue) {
            if (isNaN(employeeValue)) {
              employeeValue = employeeValue.toLowerCase();
            }

            if (employeeValue.includes(search)) {
              return employee;
            }
          }
        }));
      }

      this.set('pagedEmployees', (0, _pagedArray.default)('filteredEmployees', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['employees'];
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3><h3 style='text-align: center;'>Employees</h3><table id='employeesTable' style='width:100%; font-size: 14px'>");
      table += "<thead><tr><th style='text-align: left;'>NUMBER</th>";
      table += "<th style='text-align: left;'>FIRST NAME</th>";
      table += "<th style='text-align: left;'>LAST NAME</th>";
      table += "<th style='text-align: left;'>EMAIL</th>";
      table += "<th style='text-align: left;'>PHONE</th>";
      table += "<th style='text-align: left;'>STATUS</th></tr></thead>";
      table += "<tbody>";
      this.get('filteredEmployees').forEach(function (employee) {
        table += "<tr>";
        table += "<td>".concat(employee.get('employee_number') || "", "</td>");
        table += "<td>".concat(employee.get('first_name') || "", "</td>");
        table += "<td>".concat(employee.get('last_name') || "", "</td>");
        table += "<td>".concat(employee.get('email') || "", "</td>");
        table += "<td>".concat(employee.get('phone') || "", "</td>");
        table += "<td>".concat(employee.get('terminated') ? "TERMINATED" : "ACTIVE", "</td>");
        table += "</tr>";
      });
      table += "</tbody></table></div>";
      return table;
    },
    generateCSV: function generateCSV() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3><h3 style='text-align: center;'>Employees</h3><table id='employeesTable' style='width:100%; font-size: 14px'>");
      table += "<thead><tr><th style='text-align: left;'>NUMBER</th>";
      table += "<th style='text-align: left;'>FIRST NAME</th>";
      table += "<th style='text-align: left;'>LAST NAME</th>";
      table += "<th style='text-align: left;'>BIRTH DATE</th>";
      table += "<th style='text-align: left;'>SOCIAL SECURITY</th>";
      table += "<th style='text-align: left;'>LICENSE NUMBER</th>";
      table += "<th style='text-align: left;'>GENDER</th>";
      table += "<th style='text-align: left;'>START DATE</th>";
      table += "<th style='text-align: left;'>FILING STATUS</th>";
      table += "<th style='text-align: left;'>CFSE CATEGORY</th>";
      table += "<th style='text-align: left;'>EMAIL</th>";
      table += "<th style='text-align: left;'>PHONE</th>";
      table += "<th style='text-align: left;'>POSTAL ADDRESS LINE 1</th>";
      table += "<th style='text-align: left;'>POSTAL ADDRESS LINE 2</th>";
      table += "<th style='text-align: left;'>POSTAL CITY</th>";
      table += "<th style='text-align: left;'>POSTAL STATE</th>";
      table += "<th style='text-align: left;'>POSTAL ZIPCODE</th>";
      table += "<th style='text-align: left;'>POSTAL COUNTRY</th>";
      table += "<th style='text-align: left;'>PHYSICAL ADDRESS LINE 1</th>";
      table += "<th style='text-align: left;'>PHYSICAL ADDRESS LINE 2</th>";
      table += "<th style='text-align: left;'>PHYSICAL CITY</th>";
      table += "<th style='text-align: left;'>PHYSICAL STATE</th>";
      table += "<th style='text-align: left;'>PHYSICAL ZIPCODE</th>";
      table += "<th style='text-align: left;'>PHYSICAL COUNTRY</th>";
      table += "<th style='text-align: left;'>VACATION ACCRUAL HOURS</th>";
      table += "<th style='text-align: left;'>VACATION HOURS WORKED</th>";
      table += "<th style='text-align: left;'>VACATION ACCRUAL TYPE</th>";
      table += "<th style='text-align: left;'>VACATION ACCRUAL EXPIRATION</th>";
      table += "<th style='text-align: left;'>VACATION ACCRUAL EXPIRATION DATE</th>";
      table += "<th style='text-align: left;'>SICKNESS ACCRUAL HOURS</th>";
      table += "<th style='text-align: left;'>SICKNESS HOURS WORKED</th>";
      table += "<th style='text-align: left;'>SICKNESS ACCRUAL TYPE</th>";
      table += "<th style='text-align: left;'>SICKNESS ACCRUAL EXPIRATION</th>";
      table += "<th style='text-align: left;'>SICKNESS ACCRUAL EXPIRATION DATE</th>";
      table += "<th style='text-align: left;'>STATUS</th></tr></thead>";
      table += "<tbody>";
      this.get('filteredEmployees').forEach(function (employee) {
        table += "<tr>";
        table += "<td>".concat(employee.get('employee_number') || "", "</td>");
        table += "<td>".concat(employee.get('first_name') || "", "</td>");
        table += "<td>".concat(employee.get('last_name') || "", "</td>");
        table += "<td>".concat(employee.get('birth_date') || "", "</td>");
        table += "<td>".concat(employee.get('social_security') || "", "</td>");
        table += "<td>".concat(employee.get('license_number') || "", "</td>");
        table += "<td>".concat(employee.get('gender') || "", "</td>");
        table += "<td>".concat(employee.get('start_date') || "", "</td>");
        table += "<td>".concat(employee.get('filing_status') || "", "</td>");
        table += "<td>".concat(employee.get('cfse_category') || "", "</td>");
        table += "<td>".concat(employee.get('email') || "", "</td>");
        table += "<td>".concat(employee.get('phone') || "", "</td>");
        table += "<td>".concat(employee.get('address_line_one') || "", "</td>");
        table += "<td>".concat(employee.get('address_line_two') || "", "</td>");
        table += "<td>".concat(employee.get('city') || "", "</td>");
        table += "<td>".concat(employee.get('state') || "", "</td>");
        table += "<td>".concat(employee.get('zipcode') || "", "</td>");
        table += "<td>".concat(employee.get('country') || "", "</td>");
        table += "<td>".concat(employee.get('physical_address_line_one') || "", "</td>");
        table += "<td>".concat(employee.get('physical_address_line_two') || "", "</td>");
        table += "<td>".concat(employee.get('physical_city') || "", "</td>");
        table += "<td>".concat(employee.get('physical_state') || "", "</td>");
        table += "<td>".concat(employee.get('physical_zipcode') || "", "</td>");
        table += "<td>".concat(employee.get('physical_country') || "", "</td>");
        table += "<td>".concat(employee.get('vacation_accrual_hours') || "", "</td>");
        table += "<td>".concat(employee.get('vacation_hours_worked') || "", "</td>");
        table += "<td>".concat(employee.get('vacation_accrual_type') || "", "</td>");
        table += "<td>".concat(employee.get('vacation_accrual_expiration') || "", "</td>");
        table += "<td>".concat(employee.get('vacation_expiration_date') || "", "</td>");
        table += "<td>".concat(employee.get('sickness_accrual_hours') || "", "</td>");
        table += "<td>".concat(employee.get('sickness_hours_worked') || "", "</td>");
        table += "<td>".concat(employee.get('sickness_accrual_type') || "", "</td>");
        table += "<td>".concat(employee.get('sickness_accrual_expiration') || "", "</td>");
        table += "<td>".concat(employee.get('sickness_expiration_date') || "", "</td>");
        table += "<td>".concat(employee.get('terminated') ? "TERMINATED" : "ACTIVE", "</td>");
        table += "</tr>";
      });
      table += "</tbody></table></div>";
      return table;
    },
    errorHandler: function errorHandler(response) {
      var errors = "An Error Occured.";

      if (response) {
        if (response.errors && response.errors[0].status !== "500") {
          errors = response.errors.map(function (error) {
            return error;
          });
        } else if (response.payload.errors) {
          errors = response.payload.errors.map(function (error) {
            return error;
          });
        }

        this.employee.rollbackAttributes();
        this.set('confirmDelete', false);
        this.get('notify').alert({
          html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
        });
      }
    },
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);
      },
      cancelEdit: function cancelEdit() {
        this.employee.rollbackAttributes();
        this.toggleProperty('employeeForm');
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        var _this2 = this;

        if (id) {
          this.store.findRecord('employee', id, {
            backgroundReload: false
          }).then(function (employee) {
            _this2.set('employee', employee);

            if (employee.get('undeletable')) {
              _this2.set('deleteModalMessage', Ember.String.htmlSafe("\n              You cannot delete this employee because it has transactions. You can still terminate the employee, if you do you will not be able to create new transactions for it. \n              <br>\n              THERE WILL BE A FEE TO RE-ACTIVATE THIS EMPLOYEE!\n              <br>\n              Are you sure you want to terminate ".concat(employee.get('first_name'), " ").concat(employee.get('last_name'), "?")));

              _this2.set('deleteButton', "Terminate");
            } else {
              _this2.set('deleteModalMessage', "Are you sure you want to delete this Employee?");

              _this2.set('deleteButton', "Delete");
            }
          });
        }

        this.toggleProperty('confirmDelete');
      },
      deleteEmployee: function deleteEmployee() {
        var _this3 = this;

        if (this.get('employee.undeletable')) {
          this.setProperties({
            'employee.company_id': this.companyId,
            'employee.terminated': true
          });
          this.store.findRecord('employee', this.employee.id, {
            reload: true
          }).then(function (employee) {
            employee.setProperties(_this3.employee);
            employee.save().then(function () {
              _this3.get('notify').success({
                html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Employee Terminated</span></div>'
              }); // this.send('refreshModel');;

            }, function (response) {
              _this3.errorHandler(response);
            });
          });
        } else {
          this.employee.destroyRecord().then(function () {
            _this3.set('filteredEmployees', _this3.store.peekAll('employee').filter(function (employee) {
              return !employee.get('isDeleted');
            }));

            _this3.setProperties({
              pagedEmployees: (0, _pagedArray.default)('filteredEmployees', {
                infinite: "unpaged",
                page: 1,
                perPage: 100
              }),
              employee: {},
              confirmDelete: false
            });

            _this3.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Employee Deleted</span></div>"
            });
          }, function (response) {
            _this3.errorHandler(response);
          });
        }

        this.set('confirmDelete', false);
      },
      toggleConfirmReActivate: function toggleConfirmReActivate(id) {
        var _this4 = this;

        if (id) {
          this.store.findRecord('employee', id, {
            backgroundReload: false
          }).then(function (employee) {
            _this4.set('employee', employee);

            _this4.set('reActivateModalMessage', Ember.String.htmlSafe("\n            Re-Activating employees has a $5 Fee. If you agree to this, please continue.\n            <br>\n            YOU WILL BE CHARGED $5 TO RE-ACTIVATE THIS EMPLOYEE \n            <br>\n            Are you sure you want to re-activate ".concat(employee.get('first_name'), " ").concat(employee.get('last_name'), "?")));
          });
        }

        this.toggleProperty('confirmReActivate');
      },
      reActivateEmployee: function reActivateEmployee() {
        var _this5 = this;

        this.setProperties({
          'employee.company_id': this.companyId,
          'employee.terminated': false
        });
        this.store.findRecord('employee', this.employee.id, {
          reload: true
        }).then(function (employee) {
          employee.setProperties(_this5.employee);
          employee.save().then(function () {
            _this5.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Employee Re-Activated</span></div>'
            }); // this.send('refreshModel');;

          }, function (response) {
            _this5.errorHandler(response);
          });
        });
        this.set('confirmReActivate', false);
      },
      loadNext: function loadNext() {
        this.get('pagedEmployees').loadNextPage();
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('filteredEmployees', this.get('filteredEmployees').sortBy(property));
        } else {
          this.set('filteredEmployees', this.get('filteredEmployees').sortBy(property).reverse());
        }

        this.set('pagedEmployees', (0, _pagedArray.default)('filteredEmployees', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      print: function print() {
        var table = this.generateTable();
        Ember.$('.print-container').empty();
        Ember.$('.print-container').append(table);
        Ember.$('.print-container').printThis({
          importCSS: false
        }); // let w = window.open();
        // w.document.write("<div id='print-container'></div>");
        // w.document.getElementById('print-container').innerHTML += table;
        // w.print();
        // w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateCSV();
        Ember.$('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        Ember.$('#employeesTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_employees_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        Ember.$('#employeesTable').remove();
        Ember.$('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});