define("account-berry-frontend/helpers/item-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.itemData = itemData;
  _exports.default = void 0;

  function itemData(params
  /*, hash*/
  ) {
    return params;
  }

  var _default = Ember.Helper.helper(function () {// let [item, items, itemAttr] = params;
    // let itemData;
    // if (item.id && items) {
    //   itemData = items.findBy('id', item.item_id ? item.item_id.toString() : item.id.toString()).get(itemAttr);
    // }
    // return itemData;
  });

  _exports.default = _default;
});