define("account-berry-frontend/components/paginated-transactions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eiDVyzB3",
    "block": "{\"symbols\":[\"transaction\"],\"statements\":[[4,\"each\",[[20,[\"paginatedData\"]]],null,{\"statements\":[[0,\"  \"],[6,\"tr\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"subData\"]]],null,{\"statements\":[[0,\"      \"],[6,\"td\"],[9,\"class\",\"small-report-column\"],[7],[0,\"  \"],[1,[19,1,[\"type\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"td\"],[9,\"class\",\"small-report-column\"],[7],[0,\" \"],[1,[19,1,[\"type\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[6,\"td\"],[9,\"class\",\"small-report-column\"],[7],[1,[25,\"moment-format\",[[19,1,[\"date\"]],\"MM/DD/YYYY\"],null],false],[8],[0,\"\\n    \"],[6,\"td\"],[9,\"class\",\"small-report-column\"],[7],[1,[19,1,[\"number\"]],false],[8],[0,\"\\n    \"],[6,\"td\"],[7],[1,[19,1,[\"name\"]],false],[8],[0,\"\\n    \"],[6,\"td\"],[7],[1,[19,1,[\"memo\"]],false],[8],[0,\"\\n    \"],[6,\"td\"],[9,\"class\",\"small-report-column amount\"],[7],[0,\"\\n\"],[4,\"link-to\",[[25,\"transaction-route\",[[19,1,[\"route\"]]],null],[19,1,[\"id\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"if\",[[19,1,[\"debit\"]],[25,\"format-currency\",[[19,1,[\"amount\"]],true],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n    \"],[6,\"td\"],[9,\"class\",\"small-report-column amount\"],[7],[0,\"\\n\"],[4,\"link-to\",[[25,\"transaction-route\",[[19,1,[\"route\"]]],null],[19,1,[\"id\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"if\",[[19,1,[\"credit\"]],[25,\"format-currency\",[[19,1,[\"amount\"]],true],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n    \"],[6,\"td\"],[9,\"class\",\"small-report-column amount\"],[7],[1,[25,\"format-currency\",[[19,1,[\"balance\"]],true],null],false],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/paginated-transactions/template.hbs"
    }
  });

  _exports.default = _default;
});