define("account-berry-frontend/check/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    account_checks: _emberData.default.attr(),
    accounts: _emberData.default.attr(),
    bank_account: _emberData.default.attr(),
    cash_account: _emberData.default.attr(),
    cash_account_id: _emberData.default.attr(),
    check_date: _emberData.default.attr(),
    check_number: _emberData.default.attr(),
    memo: _emberData.default.attr(),
    amount: _emberData.default.attr('number'),
    clear: _emberData.default.attr(),
    payee_name: _emberData.default.attr(),
    company_id: _emberData.default.attr(),
    company: _emberData.default.belongsTo('company'),
    created_at: _emberData.default.attr(),
    updated_at: _emberData.default.attr(),
    undeletable: _emberData.default.attr(),
    transaction_account: _emberData.default.attr(),
    transaction_account_name: _emberData.default.attr(),
    payment: _emberData.default.attr(),
    payments: _emberData.default.attr(),
    payee_id: _emberData.default.attr(),
    payee_type: _emberData.default.attr(),
    payroll: _emberData.default.attr(),
    payroll_id: _emberData.default.attr(),
    recurrency: _emberData.default.attr(),
    labels: _emberData.default.attr(),
    label_ids: _emberData.default.attr(),
    void: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});