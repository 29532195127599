define("account-berry-frontend/companies/show/reports/balance-sheet/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.set('breadCrumb', 'Balance Sheet');
      controller.set('companyId', this.paramsFor('companies.show').company_id);
      controller.setProperties({
        balanceSheetData: null,
        date: null,
        accountTransactions: false,
        reportType: "years",
        labelsReport: false,
        labelIds: null
      });
      this.store.findAll('label', {
        reload: true
      }).then(function (labels) {
        controller.set('labels', labels);
      });
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.reports.balance-sheet', {
        into: 'application',
        model: model
      });
    }
  });

  _exports.default = _default;
});