define("account-berry-frontend/companies/show/dashboard/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    ajaxCall: Ember.inject.service(),
    fromDate: Ember.computed(function () {
      var fromDate = new Date();
      fromDate.setDate(fromDate.getDate() - 7);
      return moment(fromDate).format('YYYY-MM-DD');
    }),
    toDate: Ember.computed(function () {
      return moment(new Date()).format('YYYY-MM-DD');
    }),
    companyOberver: Ember.observer('company', function () {
      if (this.get('company')) {
        var channel = this.get('session.pusher').subscribe("dashboard-channel-".concat(this.get('company.id')));
        this.setChannel(channel, 'profit-loss', 'profitLossData', '.report');
        this.setChannel(channel, 'cash-accounts', 'cashAccountsData', '');
        this.setChannel(channel, 'balance-sheet', 'balanceSheetData', '.report');
        this.setChannel(channel, 'ap-aging', 'apAgingData', '.report');
        this.setChannel(channel, 'ar-aging', 'arAgingData', '.report');
        this.send('getDashboardData');
      }
    }),
    setChannel: function setChannel(channel, event, value, key) {
      var _this = this;

      channel.bind(event, function (response) {
        _this.store.findRecord('json-storage', response.json_storage_id).then(function (jsonStorage) {
          _this.set(value, jsonStorage.get("stored_json".concat(key)));
        });
      });
    },
    actions: {
      getDashboardData: function getDashboardData() {
        var _this2 = this;

        this.setProperties({
          'profitLossData': undefined,
          'cashAccountsData': undefined,
          'balanceSheetData': undefined,
          'apAgingData': undefined,
          'arAgingData': undefined
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.get('company.id'), "/dashboard"),
          method: 'GET',
          contentType: 'application/json',
          data: {
            dashboard: {
              from_date: this.get('fromDate'),
              to_date: this.get('toDate')
            }
          },
          successCallback: function successCallback() {},
          errorCallback: function errorCallback(e) {
            _this2.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      }
    }
  });

  _exports.default = _default;
});