define("account-berry-frontend/components/adjustment-record/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service('notify'),
    tagName: '',
    editing: false,
    actions: {
      setEditMode: function setEditMode() {
        this.set('editing', true);
      },
      setEmployee: function setEmployee(employee) {
        this.set('adjustment.employee_id', employee.id);
      },
      save: function save() {
        var _this = this;

        var adjustment = this.store.peekRecord('vacation-sickness-adjustment', this.get('adjustment.id'));
        adjustment.setProperties(this.get('adjustment'));
        adjustment.save().then(function () {
          _this.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Adjustment Updated</span></div>'
          });

          _this.set('editing', false);
        }, function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });

          _this.send('cancel');
        });
      },
      cancel: function cancel() {
        this.get('adjustment').rollbackAttributes();
        this.set('editing', false);
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        if (id) {
          this.set('adjustment', this.store.peekRecord('vacation-sickness-adjustment', id));
        }

        this.toggleProperty('confirmDelete');
      },
      deleteAdjustment: function deleteAdjustment() {
        var _this2 = this;

        this.adjustment.destroyRecord().then(function () {
          // Edith me ama y yo la amo mas, mucho mas, mucho mucho mucho mas
          _this2.sendAction('reloadRoute');

          _this2.get('notify').alert({
            html: '<i class="icon-warning lg"></i> <div class="message-text"><span>Adjustment Deleted</span></div>'
          });
        }).catch(function (response) {
          var errors = "An Unkown Error Occured.";

          if (response) {
            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this2.adjustment.rollbackAttributes();

            _this2.set('confirmDelete', false);

            _this2.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});