define("account-berry-frontend/companies/show/employees/employees-table/transactions/controller", ["exports", "ember-cli-pagination/computed/paged-array"], function (_exports, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    search: "",
    sortAscending: false,
    dateFilter: 'date',
    pagedTransactions: (0, _pagedArray.default)('filteredTransactions', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    transactionsObserver: Ember.observer('filteredTransactions', 'model', function () {
      try {
        this.set('pagedTransactions', (0, _pagedArray.default)('filteredTransactions', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {}
    }),
    filteredTransactions: Ember.computed('model.transactions', function () {
      return this.get('model.transactions');
    }),
    filters: [{
      name: "DATE",
      value: "date"
    }, {
      name: "NUMBER",
      value: "number"
    }, {
      name: "TYPE",
      value: "type"
    }, {
      name: "AMOUNT",
      value: "amount"
    }],
    filter: "date",
    filterTransactions: Ember.observer('search', function () {
      var search = this.search;
      var filter = this.filter;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      if (search === "" || search.trim().length === 0) {
        this.set('filteredTransactions', this.get('model.transactions'));
      } else {
        this.set('filteredTransactions', this.get('model.transactions').filter(function (transaction) {
          var transactionValue = transaction[filter];

          if (transactionValue) {
            if (isNaN(transactionValue)) {
              transactionValue = transactionValue.toLowerCase();
            }

            if (transactionValue.includes(search)) {
              return transaction;
            }
          }
        }));
      }

      this.set('pagedTransactions', (0, _pagedArray.default)('filteredTransactions', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    filterTransactionsByDate: Ember.observer('fromDateFilter', 'toDateFilter', function () {
      var fromDateFilter = this.get('fromDateFilter');
      var toDateFilter = this.get('toDateFilter');

      if (fromDateFilter || toDateFilter) {
        this.set('filteredTransactions', this.get('model.transactions').sortBy('date').filter(function (transaction) {
          if (fromDateFilter && toDateFilter) {
            if (transaction.date >= fromDateFilter && transaction.date <= toDateFilter) {
              return transaction;
            }
          } else if (fromDateFilter && !toDateFilter) {
            if (transaction.date >= fromDateFilter) {
              return transaction;
            }
          } else if (!fromDateFilter && toDateFilter) {
            if (transaction.date <= toDateFilter) {
              return transaction;
            }
          }
        }));
      } else if (this.get('model.transactions')) {
        this.set('filteredTransactions', this.get('model.transactions'));
      }

      try {
        this.set('pagedTransactions', (0, _pagedArray.default)('filteredTransactions', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (e) {}
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3>");
      table += "<h3 style='text-align: center'>".concat(this.model.get('display_name'), " Transactions</h3>");
      table += "<table id='employeeTransactionsTable' style='width:100%; font-size: 14px'>";
      table += "<thead><tr><th style='text-align: left'>DATE</th>";
      table += "<th style='text-align: left'>NUMBER</th>";
      table += "<th style='text-align: left'>TYPE</th>";
      table += "<th style='text-align: right'>AMOUNT</th></tr></thead><tbody>";
      this.get('filteredTransactions').forEach(function (transaction) {
        table += "<tr><td style='text-align: left'>".concat(moment(transaction.date).format('MM/DD/YYYY'), "</td>");
        table += "<td style='text-align: left'>".concat(transaction.number ? transaction.number : '', "</td>");
        table += "<td style='text-align: left'>".concat(transaction.type, "</td>");
        table += "<td style='text-align: right'>".concat(parseFloat(transaction.amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
      });
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);

        if (value !== 'date') {
          this.setProperties({
            fromDateFilter: null,
            toDateFilter: null
          });
        }

        this.set('dateFilter', value === 'date');
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('filteredTransactions', this.get('filteredTransactions').sortBy(property));
        } else {
          this.set('filteredTransactions', this.get('filteredTransactions').sortBy(property).reverse());
        }

        this.set('pagedTransactions', (0, _pagedArray.default)('filteredTransactions', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      loadNext: function loadNext() {
        this.get('pagedTransactions').loadNextPage();
      },
      toggleConfirmDelete: function toggleConfirmDelete(transaction) {
        if (transaction) {
          var _this = this;

          this.store.findRecord(Ember.String.dasherize(transaction.type.toLowerCase()), transaction.id, {
            backgroundReload: false
          }).then(function (transaction) {
            _this.set('transaction', transaction);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      deleteTransaction: function deleteTransaction() {
        var _this2 = this;

        this.transaction.destroyRecord().then(function () {
          _this2.setProperties({
            confirmDelete: false
          });

          _this2.send('refreshModel');

          ;

          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Transaction Deleted</span></div>"
          });
        }).catch(function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
        this.toggleProperty('confirmDelete');
      },
      print: function print() {
        var table = this.generateTable();
        Ember.$('.print-container').empty();
        Ember.$('.print-container').append(table);
        Ember.$('.print-container').printThis({
          importCSS: false
        }); //   let w = window.open();
        //   w.document.write("<div id='print-container'></div>");
        //   w.document.getElementById('print-container').innerHTML += table;
        //  w.print();
        //  w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        Ember.$('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        Ember.$('#employeeTransactionsTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_").concat(Ember.String.underscore(this.get('model.last_name')), "_transactions_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        Ember.$('#employeeTransactionsTable').remove();
        Ember.$('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});