define("account-berry-frontend/ajax-call/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    request: function request(params) {
      var _this$get = this.get('session.data.authenticated'),
          email = _this$get.email,
          token = _this$get.token;

      var headers = {
        Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
      };
      this.get('ajax').request(params.url, {
        method: params.method,
        contentType: params.contentType,
        data: params.data,
        headers: headers || params.headers
      }).then(function (response) {
        params.successCallback(response);
      }).catch(function (error) {
        params.errorCallback(error);
      });
    }
  });

  _exports.default = _default;
});