define("account-berry-frontend/employee/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    first_name: _emberData.default.attr(),
    last_name: _emberData.default.attr(),
    social_security: _emberData.default.attr(),
    employee_number: _emberData.default.attr(),
    birth_date: _emberData.default.attr(),
    gender: _emberData.default.attr(),
    address_line_one: _emberData.default.attr(),
    address_line_two: _emberData.default.attr(),
    city: _emberData.default.attr(),
    state: _emberData.default.attr(),
    zip_code: _emberData.default.attr(),
    country: _emberData.default.attr(),
    physical_address_line_one: _emberData.default.attr(),
    physical_address_line_two: _emberData.default.attr(),
    physical_zipcode: _emberData.default.attr(),
    physical_country: _emberData.default.attr(),
    physical_state: _emberData.default.attr(),
    physical_city: _emberData.default.attr(),
    email: _emberData.default.attr(),
    phone: _emberData.default.attr(),
    license_number: _emberData.default.attr(),
    cfse_category: _emberData.default.attr(),
    company_id: _emberData.default.attr(),
    name: _emberData.default.attr(),
    employee_rates: _emberData.default.attr(),
    picture: _emberData.default.attr(),
    filing_status: _emberData.default.attr(),
    transactions: _emberData.default.attr(),
    undeletable: _emberData.default.attr(),
    terminated: _emberData.default.attr(),
    vacation_accrual_hours: _emberData.default.attr(),
    vacation_hours_worked: _emberData.default.attr(),
    vacation_accrual_type: _emberData.default.attr(),
    vacation_accrual_expiration: _emberData.default.attr(),
    vacation_expiration_date: _emberData.default.attr(),
    sickness_accrual_hours: _emberData.default.attr(),
    sickness_hours_worked: _emberData.default.attr(),
    sickness_accrual_type: _emberData.default.attr(),
    sickness_accrual_expiration: _emberData.default.attr(),
    sickness_expiration_date: _emberData.default.attr(),
    start_date: _emberData.default.attr(),
    full_time: _emberData.default.attr()
  });

  _exports.default = _default;
});