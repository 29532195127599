define("account-berry-frontend/employee-rate/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    employee_id: _emberData.default.attr(),
    state_income_tax_percentage: _emberData.default.attr(),
    federal_income_tax_percentage: _emberData.default.attr(),
    other_income_tax_percentage: _emberData.default.attr(),
    calc_chauffeur: _emberData.default.attr(),
    calc_disability: _emberData.default.attr(),
    date: _emberData.default.attr(),
    overtime_rate: _emberData.default.attr(),
    regular_rate: _emberData.default.attr(),
    undeletable: _emberData.default.attr(),
    label_ids: _emberData.default.attr(),
    labels: _emberData.default.attr()
  });

  _exports.default = _default;
});