define("account-berry-frontend/companies/show/batches/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(param) {
      return this.store.findRecord('batch', param.batch_id, {
        reload: true
      });
    },
    setupController: function setupController(controller, model) {
      var name = model.get('import_type') === "Banco Popular" ? "Bank" : model.get('import_type');
      controller.set('breadCrumb', "".concat(moment(model.get('date')).format("MM/DD/YYYY"), "-").concat(name));
      controller.set('companyId', this.paramsFor('companies.show').company_id);

      if (model.get('import_type') === "Checks" || model.get('import_type') === "Deposits" || model.get('import_type') === "Journal Entries" || model.get('import_type') === 'Banco Popular' || model.get('import_type') === 'First Bank') {
        this.setCheckDepositData(controller);
      }

      var importData = {};

      try {
        importData = JSON.parse(model.get('import_data'));
      } catch (e) {
        importData = model.get('import_data');
      }

      controller.set('importData', importData);
    },
    setCheckDepositData: function setCheckDepositData(controller) {
      this.store.findAll('vendor', {
        reload: true
      }).then(function (vendors) {
        controller.set('vendors', vendors);
      });
      this.store.findAll('employee', {
        reload: true
      }).then(function (employees) {
        controller.set('employees', employees);
      });
      this.store.findAll('customer', {
        reload: true
      }).then(function (customers) {
        controller.set('customers', customers);
      });
      this.store.findAll('payee', {
        reload: true
      }).then(function (payees) {
        controller.set('payees', payees);
      });
      this.store.findAll('account', {
        reload: true
      }).then(function (accounts) {
        var filteredAccounts = accounts.filter(function (account) {
          return account.get('account_type.name') !== "Inventory";
        });
        controller.set('accounts', filteredAccounts);
      });
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.batches.show', {
        into: 'application',
        model: model
      });
    }
  });

  _exports.default = _default;
});