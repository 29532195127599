define("account-berry-frontend/clover-sync/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    company_id: _emberData.default.attr(),
    merchant_id: _emberData.default.attr('string'),
    initial_setup: _emberData.default.attr('boolean'),
    sales_account_id: _emberData.default.attr('number'),
    refunds_account_id: _emberData.default.attr('number'),
    tax_account_id: _emberData.default.attr('number'),
    tips_account_id: _emberData.default.attr('number'),
    cash_account_id: _emberData.default.attr('number'),
    bank_account_id: _emberData.default.attr('number'),
    gift_card_account_id: _emberData.default.attr('number'),
    amex_charges_account_id: _emberData.default.attr('number'),
    amex_percentage: _emberData.default.attr('number'),
    last_sync: _emberData.default.attr('string')
  });

  _exports.default = _default;
});