define("account-berry-frontend/invoice-payment/route", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajaxCall: Ember.inject.service(),
    model: function model() {},
    setupController: function setupController(controller) {
      var _this = this;

      var ajaxOptions = {
        url: "".concat(_environment.default.apiURL, "/invoice_payments/").concat(this.paramsFor('invoice_payment').invoice_id),
        method: 'GET',
        contentType: 'application/json',
        successCallback: function successCallback(res) {
          controller.set('invoicePayment', res.invoice_payment);
        },
        errorCallback: function errorCallback(response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload && response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          debugger;

          _this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        }
      };
      this.get('ajaxCall').request(ajaxOptions);
    }
  });

  _exports.default = _default;
});