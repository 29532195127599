define("account-berry-frontend/sub-account/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    account_type_id: _emberData.default.attr(),
    account_type: _emberData.default.belongsTo('account-type'),
    company_id: _emberData.default.attr(),
    name: _emberData.default.attr(),
    number: _emberData.default.attr(),
    tax_id: _emberData.default.attr(),
    undeletable: _emberData.default.attr(),
    is_title: _emberData.default.attr(),
    title_account: _emberData.default.attr(),
    sub_account_of: _emberData.default.attr(),
    is_sub_account: _emberData.default.attr(),
    amount: _emberData.default.attr('number'),
    has_transactions: _emberData.default.attr(),
    error: _emberData.default.attr()
  });

  _exports.default = _default;
});