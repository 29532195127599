define("account-berry-frontend/companies/show/dashboard/route", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    // afterModel() {
    //   // get week range
    //   let curr = new Date 
    //   let week = []
    //   for (let i = 1; i <= 7; i++) {
    //     let first = curr.getDate() - curr.getDay() + i 
    //     let day = new Date(curr.setDate(first)).toISOString().slice(0, 10)
    //     week.push(day)
    //   }
    //   let ajaxOptions = {
    //     url: `${ENV.apiURL}/companies/${this.paramsFor('companies.show').company_id}/dashboard`,
    //     method: 'GET',
    //     contentType: 'application/json',
    //     data: { dashboard: {
    //       from_date: week[0],
    //       to_date: week[6]
    //     }},
    //     successCallback: () => {},
    //     errorCallback: (e) => {
    //       this.get('notify').alert({ html: `<i class="icon-warning lg"></i> <div class="message-text"><span>An error occured</span></div>` });
    //     }
    //   };
    //   this.get('ajaxCall').request(ajaxOptions);
    // },
    setupController: function setupController(controller, model) {
      controller.set('breadCrumb', 'Dashboard');
      controller.set('company', model);
    },
    renderTemplate: function renderTemplate() {
      this.render('companies.show.dashboard', {
        into: 'application'
      });
    }
  });

  _exports.default = _default;
});