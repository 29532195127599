define("account-berry-frontend/companies/show/customers/representatives/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3OcwXr8w",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"representative-form\",null,[[\"companyId\",\"newRepresentative\",\"reloadRoute\",\"store\"],[[20,[\"companyId\"]],true,\"reloadRoute\",[20,[\"store\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/companies/show/customers/representatives/new/template.hbs"
    }
  });

  _exports.default = _default;
});