define("account-berry-frontend/components/menu-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mvC8yhr8",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"col-xl-2 col-lg-3 col-md-3 col-sm-4 col-xs-12\"],[7],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",[26,[\"menu-button \",[25,\"if\",[[20,[\"disabled\"]],\"disabled\"],null],\" \",[20,[\"session\",\"data\",\"authenticated\",\"theme\"]]]]],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"image\"]]],null,{\"statements\":[[0,\"      \"],[6,\"img\"],[9,\"class\",\"bottom-spacing-small\"],[10,\"src\",[18,\"image\"],null],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"i\"],[10,\"class\",[18,\"icon\"],null],[7],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[6,\"span\"],[9,\"class\",\"line-two text-center\"],[7],[1,[18,\"line-two\"],false],[8],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"line-one text-center\"],[7],[1,[18,\"line-one\"],false],[8],[0,\"\\n\"],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/menu-button/template.hbs"
    }
  });

  _exports.default = _default;
});