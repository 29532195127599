define("account-berry-frontend/components/create-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E7Vth3qL",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"create-payee-form\"],[7],[0,\"\\n  \"],[6,\"h4\"],[7],[0,\"Create \"],[1,[25,\"if\",[[20,[\"nonInventoryItem\"]],\"Non Inventory Item\",\"Item\"],null],false],[8],[0,\"\\n  \"],[6,\"form\"],[3,\"action\",[[19,0,[]],\"createItem\"],[[\"on\"],[\"submit\"]]],[7],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"class\",\"value\",\"placeholder\",\"required\"],[\"form-control\",[20,[\"item\",\"name\"]],\"Enter item name\",true]]],false],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"class\",\"value\",\"placeholder\",\"required\"],[\"form-control\",[20,[\"item\",\"number\"]],\"Enter item number\",true]]],false],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"class\",\"value\",\"placeholder\"],[\"form-control\",[20,[\"item\",\"description\"]],\"Enter item description\"]]],false],[0,\"\\n\"],[4,\"unless\",[[20,[\"nonInventoryItem\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"input\",null,[[\"type\",\"step\",\"class\",\"value\",\"placeholder\",\"required\"],[\"number\",\"0.01\",\"form-control\",[20,[\"item\",\"cost\"]],\"Enter item cost\",true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[25,\"input\",null,[[\"type\",\"step\",\"class\",\"value\",\"placeholder\",\"required\"],[\"number\",\"0.01\",\"form-control\",[20,[\"item\",\"sale_price\"]],\"Enter item sale price\",true]]],false],[0,\"\\n\\n    \"],[6,\"div\"],[9,\"class\",\"flex-row\"],[7],[0,\"\\n      \"],[6,\"button\"],[9,\"class\",\"btn accountberry-btn\"],[9,\"type\",\"submit\"],[7],[0,\"Create\"],[8],[0,\"\\n      \"],[6,\"button\"],[9,\"class\",\"btn accountberry-btn\"],[3,\"action\",[[19,0,[]],\"close\"]],[7],[0,\"Cancel\"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/create-item/template.hbs"
    }
  });

  _exports.default = _default;
});