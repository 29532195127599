define("account-berry-frontend/components/941pr-2023/component", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    tagName: '',
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    partTwoProcedureUpdated: false,
    init: function init() {
      var _this2 = this;

      this._super();

      var channel = this.get('session.pusher').subscribe("941pr-channel-".concat(this.get('session.data.authenticated.id')));
      channel.bind('quarter', function (response) {
        _this2.store.findRecord('json-storage', response.json_storage_id).then(function (jsonStorage) {
          _this2.set('quarterData', jsonStorage.get('stored_json.quarter'));
        });
      });
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    ein: Ember.computed('company.ein', function () {
      if (this.get('company')) {
        var ein = this.get('company.ein') ? this.get('company.ein').replace("-", "") : "";
        return ein;
      } else {
        return "";
      }
    }),
    // Returns an object of shape: { width: number, height: number }
    getPageDimensions: function getPageDimensions(page) {
      var mediaBox; // Check for MediaBox on the page itself

      var hasMediaBox = !!page.getMaybe('MediaBox');

      if (hasMediaBox) {
        mediaBox = page.index.lookup(page.get('MediaBox'));
      } // Check for MediaBox on each parent node


      page.Parent.ascend(function (parent) {
        var parentHasMediaBox = !!parent.getMaybe('MediaBox');

        if (!mediaBox && parentHasMediaBox) {
          mediaBox = parent.index.lookup(parent.get('MediaBox'));
        }
      }, true); // This should never happen in valid PDF files

      if (!mediaBox) throw new Error('Page Tree is missing MediaBox'); // Extract and return the width and height

      return {
        width: mediaBox.array[2].number,
        height: mediaBox.array[3].number
      };
    },
    firstPageEINXPositions: [165, 189, 227, 252, 277, 301, 327, 351, 377],
    lastPageEINPositions: [135, 164, 200, 229, 258, 287, 316, 345, 374],
    quarterFirstMonth: function quarterFirstMonth() {
      return [{
        dollarX: 88,
        dollarY: 528,
        centX: 117,
        centY: 528
      }, {
        dollarX: 88,
        dollarY: 509,
        centX: 117,
        centY: 509
      }, {
        dollarX: 88,
        dollarY: 492,
        centX: 117,
        centY: 492
      }, {
        dollarX: 88,
        dollarY: 475,
        centX: 117,
        centY: 475
      }, {
        dollarX: 88,
        dollarY: 456,
        centX: 117,
        centY: 456
      }, {
        dollarX: 88,
        dollarY: 437,
        centX: 117,
        centY: 437
      }, {
        dollarX: 88,
        dollarY: 420,
        centX: 117,
        centY: 420
      }, {
        dollarX: 88,
        dollarY: 402,
        centX: 117,
        centY: 402
      }, {
        dollarX: 189,
        dollarY: 528,
        centX: 218,
        centY: 528
      }, {
        dollarX: 189,
        dollarY: 509,
        centX: 218,
        centY: 509
      }, {
        dollarX: 189,
        dollarY: 492,
        centX: 218,
        centY: 492
      }, {
        dollarX: 189,
        dollarY: 475,
        centX: 218,
        centY: 475
      }, {
        dollarX: 189,
        dollarY: 456,
        centX: 218,
        centY: 456
      }, {
        dollarX: 189,
        dollarY: 437,
        centX: 218,
        centY: 437
      }, {
        dollarX: 189,
        dollarY: 420,
        centX: 218,
        centY: 420
      }, {
        dollarX: 189,
        dollarY: 402,
        centX: 218,
        centY: 402
      }, {
        dollarX: 290,
        dollarY: 528,
        centX: 317,
        centY: 528
      }, {
        dollarX: 290,
        dollarY: 509,
        centX: 317,
        centY: 509
      }, {
        dollarX: 290,
        dollarY: 492,
        centX: 317,
        centY: 492
      }, {
        dollarX: 290,
        dollarY: 475,
        centX: 317,
        centY: 475
      }, {
        dollarX: 290,
        dollarY: 456,
        centX: 317,
        centY: 456
      }, {
        dollarX: 290,
        dollarY: 437,
        centX: 317,
        centY: 437
      }, {
        dollarX: 290,
        dollarY: 420,
        centX: 317,
        centY: 420
      }, {
        dollarX: 290,
        dollarY: 402,
        centX: 317,
        centY: 402
      }, {
        dollarX: 391,
        dollarY: 528,
        centX: 418,
        centY: 528
      }, {
        dollarX: 391,
        dollarY: 509,
        centX: 418,
        centY: 509
      }, {
        dollarX: 391,
        dollarY: 492,
        centX: 418,
        centY: 492
      }, {
        dollarX: 391,
        dollarY: 475,
        centX: 418,
        centY: 475
      }, {
        dollarX: 391,
        dollarY: 456,
        centX: 418,
        centY: 456
      }, {
        dollarX: 391,
        dollarY: 437,
        centX: 418,
        centY: 437
      }, {
        dollarX: 391,
        dollarY: 420,
        centX: 418,
        centY: 420
      }, {
        dollarX: 529,
        dollarY: 497,
        centX: 556,
        centY: 497
      }];
    },
    quarterSecondMonth: function quarterSecondMonth() {
      return [{
        dollarX: 88,
        dollarY: 372,
        centX: 117,
        centY: 372
      }, {
        dollarX: 88,
        dollarY: 354,
        centX: 117,
        centY: 354
      }, {
        dollarX: 88,
        dollarY: 336,
        centX: 117,
        centY: 336
      }, {
        dollarX: 88,
        dollarY: 318,
        centX: 117,
        centY: 318
      }, {
        dollarX: 88,
        dollarY: 336,
        centX: 117,
        centY: 336
      }, {
        dollarX: 88,
        dollarY: 282,
        centX: 117,
        centY: 282
      }, {
        dollarX: 88,
        dollarY: 263,
        centX: 117,
        centY: 263
      }, {
        dollarX: 88,
        dollarY: 246,
        centX: 117,
        centY: 246
      }, {
        dollarX: 189,
        dollarY: 372,
        centX: 218,
        centY: 372
      }, {
        dollarX: 189,
        dollarY: 354,
        centX: 218,
        centY: 354
      }, {
        dollarX: 189,
        dollarY: 336,
        centX: 218,
        centY: 336
      }, {
        dollarX: 189,
        dollarY: 318,
        centX: 218,
        centY: 318
      }, {
        dollarX: 189,
        dollarY: 336,
        centX: 218,
        centY: 336
      }, {
        dollarX: 189,
        dollarY: 282,
        centX: 218,
        centY: 282
      }, {
        dollarX: 189,
        dollarY: 263,
        centX: 218,
        centY: 263
      }, {
        dollarX: 189,
        dollarY: 246,
        centX: 218,
        centY: 246
      }, {
        dollarX: 290,
        dollarY: 372,
        centX: 317,
        centY: 372
      }, {
        dollarX: 290,
        dollarY: 354,
        centX: 317,
        centY: 354
      }, {
        dollarX: 290,
        dollarY: 336,
        centX: 317,
        centY: 336
      }, {
        dollarX: 290,
        dollarY: 318,
        centX: 317,
        centY: 318
      }, {
        dollarX: 290,
        dollarY: 336,
        centX: 317,
        centY: 336
      }, {
        dollarX: 290,
        dollarY: 282,
        centX: 317,
        centY: 282
      }, {
        dollarX: 290,
        dollarY: 263,
        centX: 317,
        centY: 263
      }, {
        dollarX: 290,
        dollarY: 246,
        centX: 317,
        centY: 246
      }, {
        dollarX: 391,
        dollarY: 372,
        centX: 418,
        centY: 372
      }, {
        dollarX: 391,
        dollarY: 354,
        centX: 418,
        centY: 354
      }, {
        dollarX: 391,
        dollarY: 336,
        centX: 418,
        centY: 336
      }, {
        dollarX: 391,
        dollarY: 318,
        centX: 418,
        centY: 318
      }, {
        dollarX: 391,
        dollarY: 336,
        centX: 418,
        centY: 336
      }, {
        dollarX: 391,
        dollarY: 282,
        centX: 418,
        centY: 282
      }, {
        dollarX: 391,
        dollarY: 263,
        centX: 418,
        centY: 263
      }, {
        dollarX: 529,
        dollarY: 342,
        centX: 556,
        centY: 342
      }];
    },
    quarterThirdMonth: function quarterThirdMonth() {
      return [{
        dollarX: 88,
        dollarY: 215,
        centX: 117,
        centY: 215
      }, {
        dollarX: 88,
        dollarY: 197,
        centX: 117,
        centY: 197
      }, {
        dollarX: 88,
        dollarY: 178,
        centX: 117,
        centY: 178
      }, {
        dollarX: 88,
        dollarY: 161,
        centX: 117,
        centY: 161
      }, {
        dollarX: 88,
        dollarY: 142,
        centX: 117,
        centY: 142
      }, {
        dollarX: 88,
        dollarY: 124,
        centX: 117,
        centY: 124
      }, {
        dollarX: 88,
        dollarY: 107,
        centX: 117,
        centY: 107
      }, {
        dollarX: 88,
        dollarY: 89,
        centX: 117,
        centY: 89
      }, {
        dollarX: 189,
        dollarY: 215,
        centX: 218,
        centY: 215
      }, {
        dollarX: 189,
        dollarY: 197,
        centX: 218,
        centY: 197
      }, {
        dollarX: 189,
        dollarY: 178,
        centX: 218,
        centY: 178
      }, {
        dollarX: 189,
        dollarY: 161,
        centX: 218,
        centY: 161
      }, {
        dollarX: 189,
        dollarY: 142,
        centX: 218,
        centY: 142
      }, {
        dollarX: 189,
        dollarY: 124,
        centX: 218,
        centY: 124
      }, {
        dollarX: 189,
        dollarY: 107,
        centX: 218,
        centY: 107
      }, {
        dollarX: 189,
        dollarY: 89,
        centX: 218,
        centY: 89
      }, {
        dollarX: 290,
        dollarY: 215,
        centX: 317,
        centY: 215
      }, {
        dollarX: 290,
        dollarY: 197,
        centX: 317,
        centY: 197
      }, {
        dollarX: 290,
        dollarY: 178,
        centX: 317,
        centY: 178
      }, {
        dollarX: 290,
        dollarY: 161,
        centX: 317,
        centY: 161
      }, {
        dollarX: 290,
        dollarY: 142,
        centX: 317,
        centY: 142
      }, {
        dollarX: 290,
        dollarY: 124,
        centX: 317,
        centY: 124
      }, {
        dollarX: 290,
        dollarY: 107,
        centX: 317,
        centY: 107
      }, {
        dollarX: 290,
        dollarY: 89,
        centX: 317,
        centY: 89
      }, {
        dollarX: 391,
        dollarY: 215,
        centX: 418,
        centY: 215
      }, {
        dollarX: 391,
        dollarY: 197,
        centX: 418,
        centY: 197
      }, {
        dollarX: 391,
        dollarY: 178,
        centX: 418,
        centY: 178
      }, {
        dollarX: 391,
        dollarY: 161,
        centX: 418,
        centY: 161
      }, {
        dollarX: 391,
        dollarY: 142,
        centX: 418,
        centY: 142
      }, {
        dollarX: 391,
        dollarY: 124,
        centX: 418,
        centY: 124
      }, {
        dollarX: 391,
        dollarY: 107,
        centX: 418,
        centY: 107
      }, {
        dollarX: 529,
        dollarY: 184,
        centX: 556,
        centY: 184
      }];
    },
    part1InputValues: Ember.computed(function () {
      return {
        '5f': 0.00,
        '_7': 0.00,
        '_8': 0.00,
        '_9': 0.00,
        '_10': 0.00,
        '11a': 0.00,
        '11b': 0.00 // '11c': 0.00

      };
    }),
    part2InputValues: Ember.computed(function () {
      return {
        '11d': 0.00,
        '11g': 0.00,
        '12': 0.00,
        '13a': 0.00,
        '13c': 0.00,
        '13e': 0.00,
        '13g': 0.00,
        '_14': 0.00,
        '_15': 0.00
      };
    }),
    part3InputValues: Ember.computed(function () {
      return {
        '_19': 0.00,
        '_20': 0.00,
        // '_21': 0.00,
        // '_22': 0.00,
        '_23': 0.00,
        '_24': 0.00,
        '_25': 0.00,
        '_26': 0.00,
        '_27': 0.00,
        '_28': 0.00
      };
    }),
    part1Values: Ember.computed('quarterData', 'noSSniMedicare', 'part1Values', function () {
      var values = this.get('part1InputValues');

      if (!this.get('noSSniMedicare')) {
        values['5a'] = parseFloat(this.get('quarterData.total_wages_subject_to_ss_withholding'));
        values['5b'] = parseFloat(this.get('quarterData.total_tips_subject_to_ss_withholding'));
        values['5c'] = parseFloat(this.get('quarterData.total_wages_subject_to_medicare_withholding'));
        values['5d'] = parseFloat(this.get('quarterData.total_wages_subject_to_over_medicare_withholding'));
        values['5ai'] = values['5ai'] ? parseFloat(values['5ai']) : 0;
        values['5aii'] = values['5aii'] ? parseFloat(values['5aii']) : 0;
        values['5e'] = values['5a'] * 0.124 - (values['5a'] > 0 ? 0.005 : 0) + (values['5ai'] * 0.0062 - (values['5ai'] > 0 ? 0.005 : 0)) + (values['5b'] * 0.124 - (values['5b'] > 0 ? 0.005 : 0)) + (values['5aii'] * 0.0062 - (values['5aii'] > 0 ? 0.005 : 0)) + (values['5c'] * 0.029 - (values['5c'] > 0 ? 0.005 : 0)) + (values['5d'] * 0.009 - (values['5d'] > 0 ? 0.005 : 0));
      } else {
        values['5a'] = null;
        values['5ai'] = null;
        values['5aii'] = null;
        values['5b'] = null;
        values['5c'] = null;
        values['5d'] = null;
        values['5e'] = null;
        Ember.set(values, '5f', null);
      }

      var _5f = values['5f'] ? parseFloat(values['5f']) : 0;

      var _7 = values['_7'] ? parseFloat(values['_7']) : 0;

      var _8 = values['_8'] ? parseFloat(values['_8']) : 0;

      var _9 = values['_9'] ? parseFloat(values['_9']) : 0; // let _13 = values['_13'] ? parseFloat(values['_13']) : 0


      values['_6'] = (values['5e'] || 0) + _5f;
      values['_10'] = (values['_6'] || 0) + _7 + _8 + _9; // values['_12'] = (values['_10'] || 0) - _11
      // if (values['_12'] > _13) {
      //   values['_14'] = values['_12'] - _13
      //   this.set('pagadaEnExceso', false)
      //   values['_15'] = null
      // } else if (_13 > values['_12']) {
      //   values['_15'] = _13 - values['_12']
      //   this.set('pagadaEnExceso', true)
      //   values['_14'] = null
      // } else {
      //   values['_14'] = null
      //   values['_15'] = null
      //   this.set('pagadaEnExceso', false)
      // }

      return values;
    }),
    part2Values: Ember.computed('part1Values', function () {
      var part1Values = this.get('part1InputValues');
      var values = this.get('part2InputValues');
      values['11d'] = parseFloat(part1Values['11a'] || 0) + parseFloat(part1Values['11b'] || 0);
      values['_12'] = parseFloat(part1Values['_10'] || 0) - parseFloat(values['11d'] || 0);
      values['13g'] = parseFloat(values['13a'] || 0) + parseFloat(values['13c'] || 0) + parseFloat(values['13e'] || 0) + parseFloat(values['13f'] || 0); // values['13i'] = parseFloat(values['13g'] || 0) - parseFloat(values['13h'] || 0)

      if (values['_12'] > values['13g']) {
        values['_14'] = values['_12'] - values['13g'];
        this.set('pagadaEnExceso', false);
        values['_15'] = null;
      } else if (values['13g'] > values['_12']) {
        values['_15'] = values['13g'] - values['_12'];
        this.set('pagadaEnExceso', true);
        values['_14'] = null;
      } else {
        values['_14'] = null;
        values['_15'] = null;
        this.set('pagadaEnExceso', false);
      }

      if (this.get('year') === '2021') {
        values['13b'] = null;
      }

      return values;
    }),
    part3Values: Ember.computed(function () {
      return this.get('part3InputValues');
    }),
    firstPageValues: Ember.computed(function () {
      return {
        '5a': [{
          dollarX: 250,
          dollarY: 413,
          centX: 282,
          centY: 413
        }, {
          dollarX: 393,
          dollarY: 413,
          centX: 425,
          centY: 413
        }],
        '5ai': [{
          dollarX: 250,
          dollarY: 390,
          centX: 282,
          centY: 390
        }, {
          dollarX: 393,
          dollarY: 390,
          centX: 425,
          centY: 390
        }],
        '5aii': [{
          dollarX: 250,
          dollarY: 363,
          centX: 282,
          centY: 363
        }, {
          dollarX: 393,
          dollarY: 363,
          centX: 425,
          centY: 363
        }],
        '5b': [{
          dollarX: 250,
          dollarY: 338,
          centX: 282,
          centY: 338
        }, {
          dollarX: 393,
          dollarY: 338,
          centX: 425,
          centY: 338
        }],
        '5c': [{
          dollarX: 250,
          dollarY: 315,
          centX: 282,
          centY: 315
        }, {
          dollarX: 393,
          dollarY: 315,
          centX: 425,
          centY: 315
        }],
        '5d': [{
          dollarX: 250,
          dollarY: 290,
          centX: 282,
          centY: 290
        }, {
          dollarX: 393,
          dollarY: 290,
          centX: 425,
          centY: 290
        }],
        '5e': [{
          dollarX: 525,
          dollarY: 260,
          centX: 554,
          centY: 260
        }],
        '5f': [{
          dollarX: 525,
          dollarY: 233,
          centX: 554,
          centY: 233
        }],
        '_6': [{
          dollarX: 525,
          dollarY: 215,
          centX: 554,
          centY: 215
        }],
        '_7': [{
          dollarX: 525,
          dollarY: 197,
          centX: 554,
          centY: 197
        }],
        '_8': [{
          dollarX: 525,
          dollarY: 179,
          centX: 554,
          centY: 179
        }],
        '_9': [{
          dollarX: 525,
          dollarY: 156,
          centX: 554,
          centY: 156
        }],
        '_10': [{
          dollarX: 525,
          dollarY: 136,
          centX: 554,
          centY: 136
        }],
        '11a': [{
          dollarX: 525,
          dollarY: 112,
          centX: 554,
          centY: 112
        }],
        '11b': [{
          dollarX: 525,
          dollarY: 88,
          centX: 554,
          centY: 88
        }] // '11c': [{ dollarX: 525, dollarY: 70, centX: 554, centY: 70 }],

      };
    }),
    secondPageValues: Ember.computed('quarterData', function () {
      return {
        '11d': [{
          dollarX: 528,
          dollarY: 686,
          centX: 556,
          centY: 686
        }],
        '11e': [{
          dollarX: 528,
          dollarY: 660,
          centX: 556,
          centY: 660
        }],
        '11f': [{
          dollarX: 341,
          dollarY: 642,
          centX: 1000,
          centY: 1000
        }],
        '11g': [{
          dollarX: 528,
          dollarY: 620,
          centX: 556,
          centY: 620
        }],
        '_12': [{
          dollarX: 528,
          dollarY: 592,
          centX: 556,
          centY: 592
        }],
        '13a': [{
          dollarX: 528,
          dollarY: 554,
          centX: 556,
          centY: 554
        }],
        // ME QUEDÉ AQUÍ
        '13c': [{
          dollarX: 528,
          dollarY: 507,
          centX: 556,
          centY: 507
        }],
        // '13d': [{ dollarX: 528, dollarY: 491, centX: 556, centY: 491 }],
        '13e': [{
          dollarX: 528,
          dollarY: 447,
          centX: 556,
          centY: 447
        }],
        '13f': [{
          dollarX: 528,
          dollarY: 422,
          centX: 556,
          centY: 422
        }],
        '13g': [{
          dollarX: 528,
          dollarY: 402,
          centX: 556,
          centY: 402
        }],
        // '13h': [{ dollarX: 528, dollarY: 387, centX: 556, centY: 387 }],
        // '13i': [{ dollarX: 528, dollarY: 363, centX: 556, centY: 363 }],
        '_14': [{
          dollarX: 528,
          dollarY: 320,
          centX: 556,
          centY: 320
        }],
        '_15': [{
          dollarX: 396,
          dollarY: 290,
          centX: 424,
          centY: 290
        }],
        '_16': [{
          value: this.get('quarterData.first_month_ss_medicare'),
          dollarX: 368,
          dollarY: 145,
          centX: 395,
          centY: 145
        }, {
          value: this.get('quarterData.second_month_ss_medicare'),
          dollarX: 368,
          dollarY: 125,
          centX: 395,
          centY: 125
        }, {
          value: this.get('quarterData.third_month_ss_medicare'),
          dollarX: 368,
          dollarY: 106,
          centX: 395,
          centY: 106
        }, {
          value: this.get('quarterData.total_ss_medicare'),
          dollarX: 368,
          dollarY: 90,
          centX: 395,
          centY: 90
        }]
      };
    }),
    thirdPageValues: Ember.computed(function () {
      return {
        '_19': [{
          dollarX: 528,
          dollarY: 645,
          centX: 556,
          centY: 645
        }],
        '_20': [{
          dollarX: 528,
          dollarY: 620,
          centX: 556,
          centY: 620
        }],
        // '_21': [{ dollarX: 528, dollarY: 592, centX: 556, centY: 592 }],
        // '_22': [{ dollarX: 528, dollarY: 573, centX: 556, centY: 573 }],
        '_23': [{
          dollarX: 528,
          dollarY: 556,
          centX: 556,
          centY: 556
        }],
        '_24': [{
          dollarX: 528,
          dollarY: 526,
          centX: 556,
          centY: 526
        }],
        '_25': [{
          dollarX: 528,
          dollarY: 503,
          centX: 556,
          centY: 503
        }],
        '_26': [{
          dollarX: 528,
          dollarY: 480,
          centX: 556,
          centY: 480
        }],
        '_27': [{
          dollarX: 528,
          dollarY: 455,
          centX: 556,
          centY: 455
        }],
        '_28': [{
          dollarX: 528,
          dollarY: 430,
          centX: 556,
          centY: 430
        }]
      };
    }),
    firstPagePdfCoordinates: Ember.computed('quarterData', 'company', 'trimestreY', 'nombreExtranjero', 'provinciaExtranjera', 'zipExtranjero', 'noSSniMedicare', 'pagadaEnExceso', function () {
      return {
        'name': {
          value: this.get('company.contact_name') || "",
          x: 190,
          y: 680,
          size: 8
        },
        'commercialName': {
          value: this.get('company.name') || "",
          x: 155,
          y: 655,
          size: 8
        },
        'trimestre': {
          value: this.get('trimestreY') ? "X" : "",
          x: 415,
          y: this.get('trimestreY') || 0,
          size: 8
        },
        'address': {
          value: "".concat(this.get('company.postal_address_line_one') || "", " ").concat(this.get('company.postal_address_line_two') || ""),
          x: 90,
          y: 635
        },
        'city': {
          value: this.get('company.postal_address_city') || "",
          x: 90,
          y: 605,
          size: 8
        },
        'state': {
          value: this.get('company.postal_address_state') || "",
          x: 277,
          y: 605,
          size: 8
        },
        'zip': {
          value: this.get('company.postal_address_zip') || "",
          x: 322,
          y: 605,
          size: 8
        },
        'nombreExtranjero': {
          value: this.get('nombreExtranjero') || "",
          x: 90,
          y: 575,
          size: 8
        },
        'provinciaExtranjera': {
          value: this.get('provinciaExtranjera') || "",
          x: 196,
          y: 575,
          size: 8
        },
        'zipExtranjero': {
          value: this.get('zipExtranjero') || "",
          x: 300,
          y: 575,
          size: 8
        },
        'numeroEmpleados': {
          value: this.get('quarterData.employees_count').toString(),
          x: 450,
          y: 508,
          size: 10
        }
      };
    }),
    secondPagePdfCoordinates: Ember.computed('company', 'proximaPlanilla', function () {
      return {
        'proximaPlanilla': {
          value: "X",
          x: 499,
          y: this.get('proximaPlanilla') ? 291 : 277
        },
        'name': {
          value: this.get('company.contact_name') || "",
          x: 50,
          y: 735,
          size: 10
        },
        'ein': {
          value: this.get('ein'),
          x: 400,
          y: 735,
          size: 10
        }
      };
    }),
    partTwoProcedure: function partTwoProcedure(pdfDoc, secondPage) {
      var _this3 = this;

      switch (this.get('partTwo')) {
        case "a":
          this.set("secondPagePdfCoordinates.partTwo", {
            value: "X",
            x: 125,
            y: 225,
            size: 10
          });
          break;

        case "b":
          this.set("secondPagePdfCoordinates.partTwo", {
            value: "X",
            x: 125,
            y: 170,
            size: 10
          });
          this.get('secondPageValues._16').forEach(function (data) {
            var coord = {
              dollarX: data.dollarX,
              dollarY: data.dollarY,
              centX: data.centX,
              centY: data.centY
            };

            _this3.setValues(pdfDoc, secondPage, parseFloat(data.value).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), coord);
          });
          break;

        case "c":
          this.set("secondPagePdfCoordinates.partTwo", {
            value: "X",
            x: 125,
            y: 75,
            size: 10
          });
          break;

        default:
      }

      var valueKeys = Object.keys(this.get("secondPageValues"));
      valueKeys.forEach(function (k) {
        _this3.get("secondPageValues.".concat(k)).forEach(function (coord) {
          if (_this3.get("part2Values.".concat(k)) || _this3.get("part2Values.".concat(k)) === 0) {
            var val = parseFloat(_this3.get("part2Values.".concat(k)));

            _this3.setValues(pdfDoc, secondPage, parseFloat(val).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), coord);
          }
        });
      });
    },
    part4Procedure: function part4Procedure(pdfDoc, thirdPage) {
      var _this4 = this;

      var pinCoords = [{
        x: 478,
        y: 324
      }, {
        x: 500,
        y: 324
      }, {
        x: 522,
        y: 324
      }, {
        x: 544,
        y: 324
      }, {
        x: 565,
        y: 324
      }];

      if (this.get('parte4Pin')) {
        this.get('parte4Pin').split("").forEach(function (num, index) {
          var contentStream = pdfDoc.createContentStream(PDFLib.drawText(_this4.get('helveticaFont').encodeText(num.toString()), {
            x: pinCoords[index].x,
            y: pinCoords[index].y,
            size: 10,
            font: 'Helvetica',
            colorRgb: [0, 0, 1]
          }));
          thirdPage.addContentStreams(pdfDoc.register(contentStream));
        });
      }
    },
    thirdPagePdfCoordinates: Ember.computed('company', '17check', '17date', '18aCheck', '18bCheck', 'parte4', 'parte4Nombre', 'parte4Numero', 'parte5Cargo', 'parte5Num', 'parte5Nombre', 'parte5CuentaPropia', 'parte5PTIN', 'parte5NombrePreparador', 'parte5Ein', 'parte5Ciudad', 'parte5Estado', 'parte5PreparadorNum', 'parte5Zip', 'parte5NombreEmpresa', 'parte5Direccion', function () {
      var splitted17Date = this.get('17date') && this.get('17check') ? this.get('17date').split("-") : [];
      var today = new Date();
      return {
        'name': {
          value: this.get('company.contact_name') || "",
          x: 50,
          y: 735,
          size: 10
        },
        'ein': {
          value: this.get('ein'),
          x: 400,
          y: 735,
          size: 10
        },
        '17check': {
          value: this.get('17check') ? "X" : "",
          x: 478,
          y: 704,
          size: 10
        },
        '17month': {
          value: splitted17Date[1] || "",
          x: 250,
          y: 682,
          size: 10
        },
        '17day': {
          value: splitted17Date[2] || "",
          x: 267,
          y: 682,
          size: 10
        },
        '17year': {
          value: splitted17Date[0] || "",
          x: 284,
          y: 682,
          size: 10
        },
        '18aCheck': {
          value: this.get('18aCheck') ? "X" : "",
          x: 478,
          y: 666,
          size: 10
        },
        '18bCheck': {
          value: this.get('18bCheck') ? "X" : "",
          x: 478,
          y: 640,
          size: 10
        },
        'parte4Si': {
          value: this.get('parte4') ? "X" : "",
          x: 68,
          y: 364,
          size: 10
        },
        'parte4No': {
          value: !this.get('parte4') ? "X" : "",
          x: 68,
          y: 310,
          size: 10
        },
        'parte4Nombre': {
          value: this.get('parte4Nombre') || "",
          x: 181,
          y: 350,
          size: 10
        },
        'parte4Numero': {
          value: this.get('parte4Numero') || "",
          x: 440,
          y: 350,
          size: 10
        },
        'parte5Nombre': {
          value: this.get('parte5Nombre') || "",
          x: 406,
          y: 250,
          size: 10
        },
        'parte5Cargo': {
          value: this.get('parte5Cargo') || "",
          x: 405,
          y: 225,
          size: 10
        },
        'parte5Num': {
          value: this.get('parte5Num') || "",
          x: 435,
          y: 200,
          size: 10
        },
        'parte5Month': {
          value: (today.getMonth() + 1).toString(),
          x: 100,
          y: 196,
          size: 10
        },
        'parte5Day': {
          value: today.getDate().toString(),
          x: 125,
          y: 196,
          size: 10
        },
        'parte5year': {
          value: today.getFullYear().toString(),
          x: 145,
          y: 196,
          size: 10
        },
        'parte5CuentaPropia': {
          value: this.get('parte5CuentaPropia') ? "X" : "",
          x: 563,
          y: 185,
          size: 10
        },
        'parte5NombrePreparador': {
          value: this.get('parte5NombrePreparador') || "",
          x: 125,
          y: 155,
          size: 10
        },
        'parte5NombreEmpresa': {
          value: this.get('parte5NombreEmpresa') || "",
          x: 125,
          y: 105,
          size: 10
        },
        'parte5Direccion': {
          value: this.get('parte5Direccion') || "",
          x: 125,
          y: 83,
          size: 10
        },
        'parte5Ciudad': {
          value: this.get('parte5Ciudad') || "",
          x: 125,
          y: 57,
          size: 10
        },
        'parte5Estado': {
          value: this.get('parte5Estado') || "",
          x: 355,
          y: 57,
          size: 10
        },
        'parte5Zip': {
          value: this.get('parte5Zip') || "",
          x: 457,
          y: 57,
          size: 10
        },
        'parte5PTIN': {
          value: this.get('parte5PTIN') || "",
          x: 457,
          y: 155,
          size: 10
        },
        'parte5PreparadorMonth': {
          value: (today.getMonth() + 1).toString(),
          x: 465,
          y: 130,
          size: 10
        },
        'parte5PreparadorDay': {
          value: today.getDate().toString(),
          x: 500,
          y: 130,
          size: 10
        },
        'parte5PreparadorYear': {
          value: today.getFullYear().toString(),
          x: 530,
          y: 130,
          size: 10
        },
        'parte5Ein': {
          value: this.get('parte5Ein') ? this.get('parte5Ein').replace("-", "") : "",
          x: 457,
          y: 105,
          size: 10
        },
        'parte5PreparadorNum': {
          value: this.get('parte5PreparadorNum') || "",
          x: 457,
          y: 83,
          size: 10
        }
      };
    }),
    fifthPagePdfCoordinates: Ember.computed('year', 'trimestreY', function () {
      return {
        'ein': {
          value: this.get('ein'),
          x: 59,
          y: 152,
          size: 10
        },
        // 'pagoDollar': { value: "1000000", x: 506, y: 162, size: 10 },
        // 'pagoCent': { value: "00", x: 540, y: 162, size: 10 },
        'primerTrimestre': {
          value: this.get('periodoContributivo') === "primero" ? "X" : "",
          x: 54,
          y: 118,
          size: 10
        },
        'segundoTrimestre': {
          value: this.get('periodoContributivo') === "segundo" ? "X" : "",
          x: 54,
          y: 88,
          size: 10
        },
        'tercerTrimestre': {
          value: this.get('periodoContributivo') === "tercero" ? "X" : "",
          x: 140,
          y: 118,
          size: 10
        },
        'cuartoTrimestre': {
          value: this.get('periodoContributivo') === "cuarto" ? "X" : "",
          x: 140,
          y: 88,
          size: 10
        },
        'nombreComercial': {
          value: this.get('company.name') || "",
          x: 236,
          y: 130,
          size: 10
        },
        'direccion1': {
          value: "".concat(this.get('company.postal_address_one') || "", " ").concat(this.get('company.postal_address_two') || ""),
          x: 236,
          y: 110,
          size: 10
        },
        'direccion2': {
          value: "".concat(this.get('company.postal_address_city') || "", " ").concat(this.get('company.postal_address_state') || "", " ").concat(this.get('company.postal_address_zip') || ""),
          x: 236,
          y: 105,
          size: 10
        }
      };
    }),
    lastPagePdfCoordinates: Ember.computed('year', 'trimestreY', function () {
      var splittedYear = this.get('year').split("");
      return {
        'name': {
          value: this.get('company.contact_name') || "",
          x: 135,
          y: 670,
          size: 10
        },
        'trimestre': {
          value: this.get('trimestreY') ? "X" : "",
          x: 420,
          y: this.get('trimestreY') || 0,
          size: 8
        },
        'yearDigitOne': {
          value: splittedYear[0],
          x: 135,
          y: 645
        },
        'yearDigitTwo': {
          value: splittedYear[1],
          x: 164,
          y: 645
        },
        'yearDigitThree': {
          value: splittedYear[2],
          x: 193,
          y: 645
        },
        'yearDigitFour': {
          value: splittedYear[3],
          x: 222,
          y: 645
        } // 'obligacionTotal': { value: this.get('obligacionTotal'), x: 135, y: 670, size: 10 }

      };
    }),
    lastPageProcedure: function lastPageProcedure(pdfDoc, lastPage) {
      if (this.get('partTwo') === "c") {
        for (var i = 0; i < 32; i++) {
          if (this.get("quarterData.first_month_dates.".concat(i + 1))) {
            var _amount = parseFloat(this.get("quarterData.first_month_dates.".concat(i + 1))).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

            this.setValues(pdfDoc, lastPage, _amount, this.get('quarterFirstMonth')()[i]);
          }

          if (this.get("quarterData.second_month_dates.".concat(i + 1))) {
            var _amount2 = parseFloat(this.get("quarterData.second_month_dates.".concat(i + 1))).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

            this.setValues(pdfDoc, lastPage, _amount2, this.get('quarterSecondMonth')()[i]);
          }

          if (this.get("quarterData.third_month_dates.".concat(i + 1))) {
            var _amount3 = parseFloat(this.get("quarterData.third_month_dates.".concat(i + 1))).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

            this.setValues(pdfDoc, lastPage, _amount3, this.get('quarterThirdMonth')()[i]);
          }

          if (i == 31) {
            var firstMonthAmount = parseFloat(this.get('quarterData.first_month_ss_medicare')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
            var secondMonthAmount = parseFloat(this.get('quarterData.second_month_ss_medicare')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
            var thirdMonthAmount = parseFloat(this.get('quarterData.third_month_ss_medicare')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
            this.setValues(pdfDoc, lastPage, firstMonthAmount, this.get('quarterFirstMonth')()[i]);
            this.setValues(pdfDoc, lastPage, secondMonthAmount, this.get('quarterSecondMonth')()[i]);
            this.setValues(pdfDoc, lastPage, thirdMonthAmount, this.get('quarterThirdMonth')()[i]);
          }
        }

        var amount = parseFloat(this.get('quarterData.total_ss_medicare')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        this.setValues(pdfDoc, lastPage, amount, {
          dollarX: 529,
          dollarY: 60,
          centX: 556,
          centY: 60
        });
      }
    },
    setEIN: function setEIN(pdfDoc, einPosition, page, y) {
      var _this5 = this;

      var ein = this.get('ein') ? this.get('ein').split("") : [];
      ein.forEach(function (num, index) {
        var contentStream = pdfDoc.createContentStream(PDFLib.drawText(_this5.get('helveticaFont').encodeText(num), {
          x: einPosition[index],
          y: y,
          size: 10,
          font: 'Helvetica',
          colorRgb: [0, 0, 1]
        }));
        page.addContentStreams(pdfDoc.register(contentStream));
      });
    },
    setValues: function setValues(pdfDoc, page, val, coord) {
      var splittedVal = val.toString().split("."); // Set dollar amount

      var textWidth = this.get('helveticaFont').widthOfTextAtSize(splittedVal[0], 10);
      var realX = coord.dollarX - textWidth + 20;
      var contentStream = pdfDoc.createContentStream(PDFLib.drawText(this.get('helveticaFont').encodeText(splittedVal[0]), {
        x: realX,
        y: coord.dollarY,
        size: 10,
        font: 'Helvetica',
        colorRgb: [0, 0, 1]
      }));
      page.addContentStreams(pdfDoc.register(contentStream));
      contentStream = pdfDoc.createContentStream(PDFLib.drawText(this.get('helveticaFont').encodeText(splittedVal[1] || "00"), {
        x: coord.centX,
        y: coord.centY,
        size: 10,
        font: 'Helvetica',
        colorRgb: [0, 0, 1]
      }));
      page.addContentStreams(pdfDoc.register(contentStream));
    },
    pageSetup: function pageSetup(page, keys, coordinates, pdfDoc) {
      var _this = this;

      var coord = this.get("".concat(coordinates, ".").concat(this.get('year'))) ? this.get("".concat(coordinates, ".").concat(this.get('year'))) : this.get("".concat(coordinates));
      keys.forEach(function (k) {
        var contentStream = pdfDoc.createContentStream(PDFLib.drawText(_this.get('helveticaFont').encodeText(coord[k]['value']), {
          x: coord[k]['x'],
          y: coord[k]['y'],
          size: coord[k]['size'] ? coord[k]['size'] : 10,
          font: 'Helvetica',
          colorRgb: coord[k]['color'] ? coord[k]['color'] : [0, 0, 1]
        }));
        page.addContentStreams(pdfDoc.register(contentStream));
      });
    },
    pdfProcess: function pdfProcess(pdfDoc) {
      var _this6 = this;

      var _this = this; // let pdfDoc = PDFLib.PDFDocumentFactory.load(existingPdfDocBytes);
      // pdfDoc = this.addPage(pdfDoc)


      var _pdfDoc$embedStandard = pdfDoc.embedStandardFont(PDFLib.StandardFonts.Helvetica),
          _pdfDoc$embedStandard2 = _slicedToArray(_pdfDoc$embedStandard, 2),
          helveticaRef = _pdfDoc$embedStandard2[0],
          helveticaFont = _pdfDoc$embedStandard2[1];

      this.set('helveticaRef', helveticaRef);
      this.set('helveticaFont', helveticaFont);
      var pages = pdfDoc.getPages();
      var firstPage = pages[0];
      var secondPage = pages[1];
      var thirdPage = pages[2];
      var fifthPage = pages[4];
      pages.forEach(function (page) {
        page.addFontDictionary('Helvetica', helveticaRef);
      }); // ------------- FIRST PAGE ---------------------

      var firstPageKeys = Object.keys(this.get('firstPagePdfCoordinates'));
      this.setEIN(pdfDoc, this.get('firstPageEINXPositions'), firstPage, 705);
      var valueKeys = Object.keys(this.get("firstPageValues"));
      valueKeys.forEach(function (k) {
        _this6.get("firstPageValues.".concat(k)).forEach(function (coord, index) {
          if (_this6.get("part1Values.".concat(k)) || _this6.get("part1Values.".concat(k)) === 0) {
            var val = parseFloat(_this6.get("part1Values.".concat(k)));

            if (index === 1) {
              switch (k) {
                case '5a':
                  val = parseFloat(_this6.get("part1Values.".concat(k))) * 0.124;
                  val -= val > 0 ? 0.005 : 0;
                  break;

                case '5ai':
                  val = parseFloat(_this6.get("part1Values.".concat(k))) * 0.062;
                  val -= val > 0 ? 0.005 : 0;
                  break;

                case '5aii':
                  val = parseFloat(_this6.get("part1Values.".concat(k))) * 0.062;
                  val -= val > 0 ? 0.005 : 0;
                  break;

                case '5b':
                  val = parseFloat(_this6.get("part1Values.".concat(k))) * 0.124;
                  val -= val > 0 ? 0.005 : 0;
                  break;

                case '5c':
                  val = parseFloat(_this6.get("part1Values.".concat(k))) * 0.029;
                  val -= val > 0 ? 0.005 : 0;
                  break;

                case '5d':
                  val = parseFloat(_this6.get("part1Values.".concat(k))) * 0.009;
                  val -= val > 0 ? 0.005 : 0;
                  break;

                default:
              }
            }

            _this6.setValues(pdfDoc, firstPage, parseFloat(val).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), coord);
          }
        });
      });
      this.pageSetup(firstPage, firstPageKeys, 'firstPagePdfCoordinates', pdfDoc); // ----------------------------------------------
      // ------------- SECOND PAGE ---------------------

      this.partTwoProcedure(pdfDoc, secondPage);
      var secondPageKeys = Object.keys(this.get("secondPagePdfCoordinates"));
      this.pageSetup(secondPage, secondPageKeys, 'secondPagePdfCoordinates', pdfDoc); // ----------------------------------------------
      // ------------- THIRD PAGE ---------------------

      this.part4Procedure(pdfDoc, thirdPage);
      var thirdPageValueKeys = Object.keys(this.get("thirdPageValues"));
      thirdPageValueKeys.forEach(function (k) {
        _this6.get("thirdPageValues.".concat(k)).forEach(function (coord) {
          if (_this6.get("part3Values.".concat(k)) || _this6.get("part3Values.".concat(k)) === 0) {
            if (!(_this6.get('year') === '2021' && (k === '_24' || k === '_25'))) {
              var val = parseFloat(_this6.get("part3Values.".concat(k)));

              _this6.setValues(pdfDoc, thirdPage, parseFloat(val).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), coord);
            }
          }
        });
      }); // debugger;
      // this.setValues(pdfDoc, thirdPage, parseFloat(this.get('part1Values._14')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), coord[this.get('year')])

      var thirdPageKeys = Object.keys(this.get("thirdPagePdfCoordinates"));
      this.pageSetup(thirdPage, thirdPageKeys, 'thirdPagePdfCoordinates', pdfDoc); // ----------------------------------------------
      // ------------- FIFTH PAGE ---------------------

      if (this.get('part2Values._14')) {
        var coord = {
          dollarX: 510,
          dollarY: 160,
          centX: 542,
          centY: 160
        };
        this.setValues(pdfDoc, fifthPage, parseFloat(this.get('part2Values._14')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), coord);
        var fifthPageKeys = Object.keys(this.get("fifthPagePdfCoordinates"));
        this.pageSetup(fifthPage, fifthPageKeys, 'fifthPagePdfCoordinates', pdfDoc);
      } // ----------------------------------------------
      // ------------- LAST PAGE ---------------------


      if (this.get('partTwo') === "c") {
        var lastPage = pages[6];
        this.lastPageProcedure(pdfDoc, lastPage);
        this.setEIN(pdfDoc, this.get('lastPageEINPositions'), lastPage, 694);
        var lastPageKeys = Object.keys(this.get('lastPagePdfCoordinates'));
        this.pageSetup(lastPage, lastPageKeys, 'lastPagePdfCoordinates', pdfDoc);
      } // ----------------------------------------------


      this.get('applicationController').set('showLoading', false);
      var pdfBytes = PDFLib.PDFDocumentWriter.saveToBytes(pdfDoc);
      this.downloadPdf(pdfBytes);
    },
    downloadPdf: function downloadPdf(data) {
      var blob = new Blob([data], {
        type: "application/pdf"
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob); // link.download = "myFileName.pdf";
      // link.click();

      window.open(link.href);
    },
    getPDF: Ember.observer('quarterData', function () {
      var _this7 = this;

      fetch("https://trimestrales.s3.amazonaws.com/f941pr2023.pdf").then(function (res) {
        var file = res.arrayBuffer();
        file.then(function (f) {
          var pdfDoc = PDFLib.PDFDocumentFactory.load(new Uint8Array(f));

          if (_this7.get('partTwo') === "c") {
            _this7.addPage(pdfDoc);
          } else {
            _this7.pdfProcess(pdfDoc);
          }
        });
      });
    }),
    addPage: function addPage(pdfDoc) {
      var _this8 = this;

      fetch('https://trimestrales.s3.amazonaws.com/f941pr_anexo_b.pdf').then(function (res) {
        var file = res.arrayBuffer();
        file.then(function (f) {
          var newDoc = PDFLib.PDFDocumentFactory.load(new Uint8Array(f));
          pdfDoc.addPage(newDoc.getPages()[0]);

          _this8.pdfProcess(pdfDoc);
        });
      });
    },
    actions: {
      generate: function generate() {
        var _this9 = this;

        var data = JSON.stringify({
          quarter: {
            date: this.get('quarterEndingOn'),
            type: '941pr'
          }
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/quarters"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            _this9.get('applicationController').set('showLoading', true);
          },
          errorCallback: function errorCallback(e) {
            _this9.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(e.payload.errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      }
    }
  });

  _exports.default = _default;
});