define("account-berry-frontend/received-item/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    item_quantity: _emberData.default.attr(),
    description: _emberData.default.attr(),
    ordered_item_id: _emberData.default.attr(),
    item_id: _emberData.default.attr(),
    po_number: _emberData.default.attr(),
    cost: _emberData.default.attr(),
    item: _emberData.default.attr(),
    receiving_number: _emberData.default.attr(),
    vendor_id: _emberData.default.attr()
  });

  _exports.default = _default;
});