define("account-berry-frontend/components/select-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    selectId: 'itemSelect',
    selectClass: "",
    createAccount: false,
    nonInventoryItems: false,
    didRender: function didRender() {
      Ember.$("#".concat(this.selectId)).select2();
    },
    allItems: Ember.computed('items', function () {
      if (this.get('items')) {
        return this.get('items');
      }
    }),
    itemId: Ember.computed('item', 'items', function () {
      if (this.get('item') && (this.get('item.id') || this.get('item.item_id'))) {
        return this.get('item.item_id') ? this.get('item.item_id').toString() : this.get('item.id').toString();
      }
    }),
    actions: {
      setItem: function setItem(value, newItem) {
        var selectOptions = Ember.$("#".concat(this.selectId, " option"));

        switch (value) {
          // Triggers create item modal
          case "new":
            this.send('toggleCreateItem');
            break;
          // If canceled, return dropdown to default value

          case "cancel":
            selectOptions[0].selected = true;
            break;
          // Set the value option to selected

          default:
            if (newItem) {
              this.set('items', this.store.peekAll('item').toArray());
            }

            this.sendAction('setItem', value, this.index);
        }
      },
      toggleCreateItem: function toggleCreateItem() {
        this.set('items', this.store.peekAll('item'));
        this.toggleProperty('createItem');
      },
      cancelCreateItem: function cancelCreateItem() {
        this.send('toggleCreateItem');
        this.send('setItem', 'cancel');
      }
    }
  });

  _exports.default = _default;
});