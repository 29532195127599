define("account-berry-frontend/templates/components/bread-crumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0I/ZdNi3",
    "block": "{\"symbols\":[\"crumb\"],\"statements\":[[6,\"ul\"],[9,\"class\",\"breadcrumbs\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"breadCrumbs\"]]],null,{\"statements\":[[0,\"    \"],[6,\"li\"],[10,\"class\",[25,\"if\",[[19,1,[\"isCurrent\"]],\"current\"],null],null],[7],[0,\"\\n\"],[4,\"if\",[[19,1,[\"linkable\"]]],null,{\"statements\":[[0,\"        \"],[4,\"if\",[[19,1,[\"model\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"link-to\",[[19,1,[\"path\"]],[19,1,[\"model\"]]],null,{\"statements\":[[0,\"            \"],[1,[19,1,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",[[19,1,[\"path\"]]],null,{\"statements\":[[0,\"            \"],[1,[19,1,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[19,1,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/templates/components/bread-crumbs.hbs"
    }
  });

  _exports.default = _default;
});