define("account-berry-frontend/companies/show/reports/bank-register/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import pagedArray from 'ember-cli-pagination/computed/paged-array';
  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    cable: Ember.inject.service(),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    actions: {
      getBankRegister: function getBankRegister() {
        var _this2 = this;

        var data = JSON.stringify({
          report: {
            from_date: this.fromDate,
            to_date: this.toDate
          },
          report_type: 'bank_register'
        });

        var _this$get = this.get('session.data.authenticated'),
            email = _this$get.email,
            token = _this$get.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

        var _this = this;

        consumer.createSubscription({
          channel: "ReportsChannel",
          room: "BankRegister" + this.get('session.data.authenticated.id')
        }, {
          connected: function connected() {
            console.log('Connected to ReportsChannel-BankRegister');

            _this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(_this.companyId, "/reports"), {
              method: 'POST',
              contentType: 'application/json',
              data: data,
              headers: headers
            }).then(function () {
              _this.get('applicationController').set('showLoading', true);
            }).catch(function () {
              _this.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
              });
            });
          },
          received: function received(data) {
            if (data.error) {
              _this2.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
              });
            } else {
              _this2.set('bankRegister', data.report);

              consumer.disconnect();

              _this2.get('applicationController').set('showLoading', false);
            }
          },
          disconnected: function disconnected() {
            console.log("ReportsChannel-BankRegister disconnected");
          }
        });
      },
      print: function print() {
        Ember.$('#bankRegister').printThis(); // let table = this.generateTable(true);
        // $('.print-container').empty()
        // $('.print-container').append(table)
        // $('.print-container').printThis({ importCSS: false })
        // let w = window.open();
        // w.document.write("<div id='print-container'></div>");
        // w.document.getElementById('print-container').innerHTML += table;
        // w.print();
        // w.close();
      },
      csvExport: function csvExport() {
        Ember.$('table').table2CSV({
          delivery: 'download',
          filename: 'BankRegister.csv'
        }); // let table = this.generateTable();
        // $('#csvExport').show();
        // document.getElementById('csvExport').innerHTML += table;
        // $('#journalEntriesDetailTable').table2CSV({
        //   delivery: 'download',
        //   filename: 'JournalEntriesDetailTable.csv'
        // });
        // // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;
        // $('#journalEntriesDetailTable').remove();
        // $('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});