define("account-berry-frontend/deposit/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      account_deposits: {
        serialize: false,
        deserialize: 'records'
      },
      cash_account: {
        serialize: false,
        deserialize: 'records'
      },
      transaction_account: {
        serialize: false,
        deserialize: 'records'
      },
      payments: {
        serialize: true,
        deserialize: 'records'
      },
      recurrency: {
        serialize: true,
        deserialize: 'records'
      },
      labels: {
        serialize: true,
        deserialize: 'records'
      },
      label_ids: {
        serialize: true,
        deserialize: 'records'
      }
    }
  });

  _exports.default = _default;
});