define("account-berry-frontend/components/physical-inventory-form/component", ["exports", "ember-cli-pagination/computed/paged-array", "account-berry-frontend/config/environment"], function (_exports, _pagedArray, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    refNumber: Ember.inject.service(),
    notify: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    cable: Ember.inject.service(),
    inventoryItems: [],
    pagedInventoryItems: (0, _pagedArray.default)('filteredItems', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    filter: "name",
    filters: Ember.computed(function () {
      return [{
        name: "NAME",
        value: "name"
      }, {
        name: "NUMBER",
        value: "number"
      }, {
        name: "DESCRIPTION",
        value: "description"
      }];
    }),
    init: function init() {
      var _this2 = this;

      this._super();

      this.setChannel();
      this.store.findAll('account').then(function (accounts) {
        var filteredAccounts = accounts.filter(function (account) {
          return account.get('account_type.name') !== "Cash" && account.get('account_type.name') !== "Inventory";
        });

        _this2.set('accounts', filteredAccounts);
      });

      if (this.newPhysicalInventory) {
        this.generateRefNumber();
      }

      this.store.findRecord('company', this.companyId).then(function (company) {
        _this2.set('company', company);
      });
      this.store.findAll('label', {
        reload: true
      }).then(function (labels) {
        _this2.set('labels', labels);
      });

      if (!this.get('physicalInventory')) {
        this.set('physicalInventory', {});
      } else {
        this.get('physicalInventory.physical_inventory_items').forEach(function (pii) {
          pii['itemData'] = _this2.store.findRecord('item', pii.item_id);
        });
        this.set('inventoryItems', this.get('physicalInventory.physical_inventory_items'));
        this.getItemsByDate();
      }
    },
    setChannel: function setChannel() {
      var _this3 = this;

      var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));
      consumer.createSubscription({
        channel: "ItemsChannel",
        room: this.get('companyId')
      }, {
        connected: function connected() {
          console.log('Connected to ItemsChannel');
        },
        received: function received(data) {
          if (data.error) {
            _this3.get('applicationController').set('showLoading', false);

            _this3.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>Please add or update your payment method.</span></div>"
            });

            _this3.set('paymentModal', true);
          } else {
            _this3.setItems(data, true);

            _this3.get('applicationController').set('showLoading', false);
          }
        }
      });
    },
    errorHandler: function errorHandler(response) {
      var errors = "An Unkown Error Occured.";

      if (response.errors && response.errors[0].status !== "500") {
        errors = response.errors.map(function (error) {
          return error;
        });
      } else if (response.payload.errors) {
        errors = response.payload.errors.map(function (error) {
          return error;
        });
      }

      this.get('notify').alert({
        html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
      });
    },
    generateRefNumber: function generateRefNumber() {
      var _this4 = this;

      var ajaxOptions = {
        url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/physical_inventories/number"),
        method: 'GET',
        contentType: 'application/json',
        successCallback: function successCallback(response) {
          var refNumber = _this4.get('refNumber').generate(response.last_ref_number || "");

          _this4.set('physicalInventory.ref_number', refNumber);
        },
        errorCallback: function errorCallback(response) {
          _this4.errorHandler(response);
        }
      };
      this.get('ajaxCall').request(ajaxOptions);
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    accountsObserver: Ember.observer('accounts', function () {
      if (this.get('physicalInventory.account_id')) {
        this.set('account', this.store.peekRecord('account', this.get('physicalInventory.account_id')));
      }
    }),
    filteredItems: Ember.computed('items', function () {
      if (this.items) {
        return this.items.sort(function (a, b) {
          return a.name > b.name ? 1 : -1;
        });
      }
    }),
    filterItems: Ember.observer('search', function () {
      var search = this.search;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      if (search === "" || search.trim().length === 0) {
        this.set('filteredItems', this.items);
      } else {
        this.set('filteredItems', this.items.filter(function (item) {
          var itemValue = item.name;

          if (itemValue) {
            if (isNaN(itemValue)) {
              itemValue = itemValue.toLowerCase();
            }

            if (itemValue.includes(search)) {
              return item;
            }
          }
        }));
      }

      this.set('pagedInventoryItems', (0, _pagedArray.default)('filteredItems', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    setItems: function setItems(items, dateChange) {
      var _this5 = this;

      if (dateChange) {
        this.set('items', items.toArray());
      } else {
        this.set('items', items.map(function (item) {
          var inventoryItem = _this5.get('inventoryItems').find(function (invItem) {
            return invItem.item_id.toString() === item.id.toString();
          });

          if (inventoryItem) {
            inventoryItem.number = item.number;
            inventoryItem.name = item.name;
            inventoryItem.description = item.description;
            return inventoryItem;
          } else {
            return item;
          }
        }));
      }
    },
    getItemsByDate: function getItemsByDate(dateChange) {
      var _this6 = this;

      if (this.get('physicalInventory.date')) {
        // Items query by date filter
        this.store.query('item', {
          company_id: this.companyId,
          item: {
            date: this.get('physicalInventory.date')
          }
        }).then(function () {
          // this.setItems(items, dateChange);
          _this6.get('applicationController').set('showLoading', true);
        });
      } else {
        this.store.findAll('item').then(function (items) {
          _this6.set('items', items.filter(function (item) {
            return !item.get('non_inventory');
          }));
        });
      }

      this.set('pagedInventoryItems', (0, _pagedArray.default)('filteredItems', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    filteredItemsObserver: Ember.observer('filteredItems', function () {
      this.set('pagedInventoryItems', (0, _pagedArray.default)('filteredItems', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);
      },
      dateUpdated: function dateUpdated() {
        this.getItemsByDate(true);
      },
      setAccount: function setAccount(id) {
        this.set('physicalInventory.account_id', id);
      },
      setLabels: function setLabels(index, labels) {
        this.set('physicalInventory.label_ids', labels.map(function (label) {
          return label.id;
        }));
        this.set('physicalInventory.labels', labels);
      },
      updateItems: function updateItems(item) {
        var inventoryItem = this.get('inventoryItems').find(function (invItem) {
          return invItem.id === item.id;
        });
        var itemData = {
          id: item.id,
          original_on_hand_quantity: item.on_hand,
          new_on_hand_quantity: item.new_on_hand_quantity
        };

        if (inventoryItem) {
          inventoryItem.original_on_hand_quantity = item.original_on_hand_quantity ? item.original_on_hand_quantity : item.get('on_hand');
          inventoryItem.new_on_hand_quantity = item.new_on_hand_quantity;
        } else {
          this.get('inventoryItems').push(itemData);
        }
      },
      create: function create() {
        var _this7 = this;

        this.set('physicalInventory.inventory_items', this.get('inventoryItems'));
        this.set('physicalInventory.company_id', this.companyId);
        var data = JSON.stringify({
          physicalInventory: this.get('physicalInventory')
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/physical_inventories"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            _this7.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Physical Inventory Created</span></div>'
            });

            _this7.sendAction('reloadRoute');

            _this7.get('router').transitionTo('companies.show.physical-inventory', _this7.companyId);
          },
          errorCallback: function errorCallback(response) {
            _this7.errorHandler(response);
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      update: function update() {
        var _this = this;

        this.store.findRecord('physical-inventory', this.physicalInventory.id, {
          backgroundReload: false
        }).then(function (physicalInventory) {
          physicalInventory.setProperties(_this.physicalInventory);
          physicalInventory.set('inventory_items', _this.get('inventoryItems'));
          physicalInventory.save().then(function () {
            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Physical Inventory Updated</span></div>'
            });

            _this.get('router').transitionTo('companies.show.physical-inventory', _this.companyId);
          }, function (response) {
            var errors = response.errors.map(function (error) {
              return error;
            });

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          });
        });
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      deletePhysicalInventory: function deletePhysicalInventory() {
        var _this8 = this;

        this.physicalInventory.destroyRecord().then(function () {
          _this8.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Physical Inventory Deleted</span></div>"
          });

          _this8.sendAction('reloadRoute');

          _this8.get('router').transitionTo('companies.show.physical-inventory', _this8.companyId);
        }).catch(function (response) {
          _this8.errorHandler(response);
        });
      },
      cancel: function cancel() {
        if (Ember.typeOf(this.get('physicalInventory')) === "instance") {
          this.get('physicalInventory').rollbackAttributes();
        }

        this.get('router').transitionTo('companies.show.physical-inventory', this.companyId);
      },
      loadNext: function loadNext() {
        this.get('pagedInventoryItems').loadNextPage();
      }
    }
  });

  _exports.default = _default;
});