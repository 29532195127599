define("account-berry-frontend/application/session-stores", ["exports", "ember-simple-auth/session-stores/cookie"], function (_exports, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cookie.default.extend(); // import LocalStorageStore from 'ember-simple-auth/session-stores/local-storage';
  // export default LocalStorageStore.extend();


  _exports.default = _default;
});