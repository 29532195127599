define("account-berry-frontend/companies/show/deposits/route", ["exports", "ember-cli-pagination/remote/paged-remote-array"], function (_exports, _pagedRemoteArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    reset: true,
    beforeModel: function beforeModel(transition) {
      this.set('reset', !transition.queryParams.show);
    },
    model: function model() {
      var companyId = this.paramsFor('companies.show').company_id;

      var deposits = _pagedRemoteArray.default.create({
        modelName: 'deposit',
        store: this.store,
        page: 1,
        otherParams: {
          company_id: companyId,
          deposit: {
            company_id: companyId
          }
        }
      });

      return deposits;
    },
    setupController: function setupController(controller) {
      var companyId = this.paramsFor('companies.show').company_id;
      controller.set('breadCrumb', 'Deposits');
      controller.set('companyId', companyId);

      if (this.reset) {
        controller.set('fromDate', null);
        controller.set('toDate', null);
        controller.set('cashAccountId', null);
        controller.set('sortBy', null);
        controller.set('search', null);
        this.store.findAll('customer', {
          backgroundReload: true
        }).then(function (customers) {
          controller.set('customers', customers);
        });
        this.store.findAll('payee', {
          backgroundReload: true
        }).then(function (payees) {
          controller.set('payees', payees);
        });
        this.store.findAll('label', {
          reload: true
        }).then(function (labels) {
          controller.set('labels', labels);
        });
        this.store.findRecord('company', companyId, {
          backgroundReload: true
        }).then(function (company) {
          controller.set('company', company);
        });
        this.store.findAll('account', {
          backgroundReload: true
        }).then(function (accounts) {
          var cashAccounts = accounts.filter(function (account) {
            if (account.get('account_type.name').toLowerCase() === 'cash' && !account.get('is_title')) {
              return account;
            }
          });
          var filteredAccounts = accounts.filter(function (account) {
            return account.get('account_type.name') !== "Inventory";
          });
          controller.set('accounts', filteredAccounts);
          controller.set('cashAccounts', cashAccounts);
        });
      } else {
        controller.sortFilterDeposits();
      }
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.deposits', {
        into: 'application',
        model: model
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});