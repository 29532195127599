define("account-berry-frontend/application/route", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    actions: {
      error: function error(_error) {
        // if (error.status === 0) {
        //     // showErrorDialog('Sorry, but we\'re having trouble connecting to the server. This problem is usually the result of a broken Internet connection. You can try refreshing this page.');
        // } else if (error.status === 403) {
        //   this.transitionTo('/');
        // } else if (error.status === 401) {
        //     //handle 401
        // } else if (error.status === 404) {
        //     this.transitionTo('/');
        // } else {
        //     // showErrorDialog(error);
        // }
        Ember.Logger.error(_error);
        this.transitionTo('/');
      }
    }
  });

  _exports.default = _default;
});