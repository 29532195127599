define("account-berry-frontend/components/employee-rate-form/component", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    init: function init() {
      this._super();

      if (!this.get('rate')) {
        this.set('rate', {
          employee_id: this.employeeId,
          calc_disability: true
        });
      }
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['employees'];
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.store.findAll('label', {
        reload: true
      }).then(function (labels) {
        _this.set('labels', labels);
      });
    },
    getLastRateData: function getLastRateData(date, employeeId) {
      var _this2 = this;

      var data = {
        employee_id: employeeId,
        date: date
      };
      var ajaxOptions = {
        url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/employee_rates/number"),
        method: 'GET',
        data: data,
        contentType: 'application/json',
        successCallback: function successCallback(response) {
          if (response.employee_rate) {
            _this2.set('rate', response.employee_rate);

            _this2.set('rate.undeletable', false);

            _this2.set('rate.date', date);
          }
        },
        errorCallback: function errorCallback(response) {
          if (response.errors && response.errors[0].status !== "500") {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload && response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this2.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });

            _this2.set('rate.date', undefined);
          }
        }
      };
      this.get('ajaxCall').request(ajaxOptions);
    },
    actions: {
      create: function create() {
        var _this3 = this;

        var newEmployeeRate = this.store.createRecord('employee-rate', this.rate);
        newEmployeeRate.save().then(function () {
          _this3.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span> Created</span></div>'
          });

          _this3.sendAction('reloadRoute');

          _this3.get('router').transitionTo('companies.show.employees.employees-table.rates', _this3.employeeId);
        }, function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
            newEmployeeRate.rollbackAttributes();

            _this3.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          } else if (response.payload && response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
            newEmployeeRate.rollbackAttributes();

            _this3.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          } else if (response.message) {
            _this3.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span> Created</span></div>'
            });

            _this3.sendAction('reloadRoute');

            _this3.get('router').transitionTo('companies.show.employees.employees-table.rates', _this3.employeeId);
          } else {
            newEmployeeRate.rollbackAttributes();

            _this3.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          }
        });
      },
      update: function update() {
        var _this4 = this;

        this.store.findRecord('employee-rate', this.rate.id, {
          reload: true
        }).then(function (rate) {
          rate.setProperties(_this4.rate);
          rate.save().then(function () {
            _this4.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Employee Updated</span></div>'
            });

            _this4.sendAction('reloadRoute'); // this.get('router').transitionTo('companies.show.employees.employees-table.rates', this.employeeId);


            history.back();
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this4.rate.rollbackAttributes();

            _this4.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          });
        });
      },
      updateRateDate: function updateRateDate() {
        if (this.newRate) {
          this.getLastRateData(this.get('rate.date'), this.get('employeeId'));
        }
      },
      setLabels: function setLabels(index, labels) {
        this.set('rate.label_ids', labels.map(function (label) {
          return label.id;
        }));
        this.set('rate.labels', labels);
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      deleteRate: function deleteRate() {
        var _this5 = this;

        this.rate.destroyRecord().then(function () {
          _this5.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Rate Deleted</span></div>"
          });

          _this5.sendAction('reloadRoute');

          _this5.get('router').transitionTo('companies.show.employees.employees-table.rates', _this5.get('rate.employee_id'));
        });
      },
      cancel: function cancel() {
        if (Ember.typeOf(this.get('rate')) === "instance") {
          this.get('rate').rollbackAttributes();
        } // this.get('router').transitionTo('companies.show.employees.employees-table.rates', this.employeeId);


        history.back();
      }
    }
  });

  _exports.default = _default;
});