define("account-berry-frontend/components/new-company-step-two/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      updateCompanyData: function updateCompanyData() {
        this.sendAction('updateCompanyData', this.get('company'), 'stepTwo', 'stepThree');
      },
      setAccountingMethod: function setAccountingMethod(val) {
        this.set('company.accounting_method', val);
      },
      back: function back() {
        this.sendAction('updateCompanyData', this.get('company'), 'stepTwo', 'stepOne');
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      }
    }
  });

  _exports.default = _default;
});