define("account-berry-frontend/companies/show/batches/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.findAll('batch', {
        reload: true
      }).then(function (batches) {
        return batches.toArray();
      });
    },
    setupController: function setupController(controller) {
      controller.set('breadCrumb', 'Import Batches');
      controller.set('companyId', this.paramsFor('companies.show').company_id);
      controller.set('model', this.model);
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.batches', {
        into: 'application',
        model: model
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});