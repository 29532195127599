define("account-berry-frontend/companies/show/customers/payment-settings/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    ajaxCall: Ember.inject.service(),
    canEnable: Ember.computed('model', 'model.cash_account_id', 'model.app_fee_account_id', 'model.charges_enabled', function () {
      if (this.get('model.cash_account_id') && this.get('model.app_fee_account_id') && this.get('model.charges_enabled')) {
        return true;
      } else {
        return false;
      }
    }),
    cashAccounts: Ember.computed('accounts', function () {
      if (this.get('accounts')) {
        var cashAccounts = this.accounts.filter(function (account) {
          if (account.get('account_type.name').toLowerCase() === 'cash' && !account.get('is_title')) {
            return account;
          }
        });
        return cashAccounts;
      }
    }),
    actions: {
      createAccount: function createAccount() {
        var _this = this;

        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/stripe_connect_accounts"),
          method: 'POST',
          contentType: 'application/json',
          // data: JSON.stringify({ ownership_transfer: { 
          //     new_owner_id: this.get('user.id'),
          //     current_owner_id: this.get('session.data.authenticated.id'),
          //     company_id: companyId
          //   }
          // }),
          successCallback: function successCallback(res) {
            console.log(res);
            window.location.href = res.url;
          },
          errorCallback: function errorCallback(response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload && response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      updateAccount: function updateAccount() {
        var _this2 = this;

        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/stripe_connect_accounts/").concat(this.get('model.connect_account_id'), "/refresh"),
          method: 'GET',
          contentType: 'application/json',
          // data: JSON.stringify({ ownership_transfer: { 
          //     new_owner_id: this.get('user.id'),
          //     current_owner_id: this.get('session.data.authenticated.id'),
          //     company_id: companyId
          //   }
          // }),
          successCallback: function successCallback(res) {
            console.log(res);
            window.location.href = res.url;
          },
          errorCallback: function errorCallback(response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload && response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this2.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      toggleSendInvoicePayments: function toggleSendInvoicePayments() {
        this.toggleProperty('model.send_invoice_payments');
      },
      updateCashAccount: function updateCashAccount(id) {
        this.set('model.cash_account_id', id);
      },
      updateAppFeeAccount: function updateAppFeeAccount(id) {
        this.set('model.app_fee_account_id', id);
      },
      save: function save() {
        var _this3 = this;

        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/stripe_connect_accounts/0"),
          method: 'PUT',
          contentType: 'application/json',
          data: JSON.stringify({
            stripe_connect_account: this.get('model')
          }),
          successCallback: function successCallback(res) {
            _this3.get('notify').success({
              html: "<i class=\"icon-checkmark lg\"></i> <div class=\"message-text\"><strong>Success:</strong>&nbsp<span>Payment Settings Updated</span></div>"
            });
          },
          errorCallback: function errorCallback(response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload && response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this3.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      }
    }
  });

  _exports.default = _default;
});