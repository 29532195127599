define("account-berry-frontend/components/log-in/component", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['width-100'],
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    notify: Ember.inject.service('notify'),
    passwordResetForm: false,
    captchaVerified: false,
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        if (!this.get('captchaVerified') && _environment.default.environment !== "development") {
          event.preventDefault();
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>Please Check the reCaptcha</span></div>"
          });
        } else {
          this.get('session').authenticate('authenticator:devise', this.get('email'), this.get('password')).then(function () {
            _this.sendAction("afterLogin");
          }, function (error) {
            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(error.error, "</span></div>")
            });
          });
        }
      },
      passwordReset: function passwordReset() {
        var _this2 = this;

        var data = JSON.stringify({
          user: {
            email: this.get('email')
          }
        });
        this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(undefined, "/users/").concat(undefined, "/password_reset"), {
          method: 'POST',
          contentType: 'application/json',
          data: data
        }).then(function () {
          _this2.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Password reset instructions have been sent</span></div>'
          });

          _this2.toggleProperty('passwordResetForm');
        }).catch(function (response) {
          var error = response.payload.errors;

          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(error, "</span></div>")
          });
        });
      },
      setPasswordReset: function setPasswordReset() {
        this.toggleProperty('passwordResetForm');
      },
      resendConfirmationEmail: function resendConfirmationEmail() {
        var _this3 = this;

        var data = JSON.stringify({
          user: {
            email: this.get('email')
          }
        });
        this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(undefined, "/users/").concat(undefined, "/resend_confirmation_email"), {
          method: 'POST',
          contentType: 'application/json',
          data: data
        }).then(function (response) {
          var error = response ? response.errors : response;

          if (error) {
            _this3.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(error, "</span></div>")
            });
          } else {
            _this3.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Confirmation Email was sent.</span></div>'
            });

            _this3.toggleProperty('resendConfirmationEmailForm');
          }
        }).catch(function (response) {
          console.log(response);
          var error = response.errors;

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(error, "</span></div>")
          });
        });
      },
      login: function login() {
        this.set('passwordResetForm', false);
        this.set('resendConfirmationEmailForm', false);
      },
      setResendConfirmationEmail: function setResendConfirmationEmail() {
        this.toggleProperty('resendConfirmationEmailForm');
      },
      verifyCaptcha: function verifyCaptcha() {
        this.set('captchaVerified', true);
      }
    }
  });

  _exports.default = _default;
});