define("account-berry-frontend/components/dashboard-income/component", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    ajaxCall: Ember.inject.service(),
    numFormat: Ember.inject.service(),
    totalIncome: Ember.computed('profitLossData', function () {
      return this.get('profitLossData.total_income')[0];
    }),
    income: Ember.computed('profitLossData', function () {
      if (this.get('profitLossData')) {
        var data = {
          labels: [],
          datasets: [{
            data: [],
            backgroundColor: ["rgba(136, 70, 218, 1)", "rgba(93, 59, 203, 1)", "rgba(35, 187, 27, 1)", "rgba(151, 220, 134, 1)", "rgba(51, 51, 51, 1)"]
          }]
        };
        var income = this.get('profitLossData.income').sort(function (a, b) {
          return parseFloat(a.is_title ? a.total_balance[0] : a.balance[0]) < parseFloat(b.is_title ? b.total_balance[0] : b.balance[0]) ? 1 : parseFloat(b.is_title ? b.total_balance[0] : b.balance[0]) < parseFloat(a.is_title ? a.total_balance[0] : a.balance[0]) ? -1 : 0;
        });
        var otherIncomeBalance = 0;
        income.forEach(function (income, index) {
          if (index <= 3) {
            data.labels.push("".concat(income.name.substring(0, 15), ": $").concat(parseFloat(income.is_title ? income.total_balance[0] : income.balance[0]).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')));
            data.datasets[0].data.push(income.is_title ? income.total_balance[0] : income.balance[0]);
          } else {
            otherIncomeBalance += income.is_title ? income.total_balance[0] : income.balance[0] ? parseFloat(income.is_title ? income.total_balance[0] : income.balance[0]) : 0;
          }
        });
        data.labels.push("Other Income: $".concat(otherIncomeBalance.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')));
        data.datasets[0].data.push(otherIncomeBalance);
        return data;
      }
    }),
    incomeOptions: Ember.computed('income', function () {
      if (this.get('income')) {
        return {
          tooltip: {},
          legend: {
            position: 'right',
            labels: {// fontSize: 12
            }
          }
        };
      }
    })
  });

  _exports.default = _default;
});