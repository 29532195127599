define("account-berry-frontend/components/fa-list", ["exports", "ember-font-awesome/components/fa-list"], function (_exports, _faList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _faList.default;
    }
  });
});