define("account-berry-frontend/components/plaid-link/component", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OPTIONS = ['clientName', 'env', 'key', 'product', 'webhook', 'token', 'transactions'];
  var DEFAULT_LABEL = 'Link Bank Account'; // Displayed on button if no block is passed to component

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    ajax: Ember.inject.service(),
    classNames: 'clickable',
    label: DEFAULT_LABEL,
    // Link action Parameters to pass into component via view
    onSuccess: function onSuccess() {},
    onOpen: function onOpen() {},
    onLoad: function onLoad() {},
    onExit: function onExit() {},
    onError: function onError() {},
    createPlaidLink: function createPlaidLink(publicToken, metadata) {
      var _this = this;

      this.set('success', true);
      var data = JSON.stringify({
        plaidLink: {
          public_token: publicToken,
          accounts: metadata.accounts,
          institution_name: metadata.institution.name,
          account_id: this.get('accountId'),
          company_id: this.get('companyId')
        }
      });
      var ajaxOptions = {
        url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/plaid_links/"),
        method: 'POST',
        contentType: 'application/json',
        data: data,
        successCallback: function successCallback() {
          _this.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Account was successfully linked</span></div>'
          });
        },
        errorCallback: function errorCallback(response) {
          _this.errorHandler(response);
        }
      };
      this.get('ajaxCall').request(ajaxOptions);
    },
    errorHandler: function errorHandler(response) {
      var errors = "An Unkown Error Occured.";

      if (response && response.errros) {
        if (response.errors && response.errors[0].status !== "500") {
          errors = response.errors.map(function (error) {
            return error;
          });
        } else if (response.payload.errors) {
          errors = response.payload.errors.map(function (error) {
            return error;
          });
        }
      }

      this.get('notify').alert({
        html: "<i class=\"icon-warnidatang lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
      });
    },
    // Link Parameters to pass into component via config file
    // Complete documentation: https://plaid.com/docs/api/#parameter-reference
    clientName: Ember.computed(function () {
      return _environment.default['plaid']['clientName'] || "";
    }),
    env: Ember.computed(function () {
      return _environment.default['plaid']['env'];
    }),
    key: Ember.computed(function () {
      return _environment.default['plaid']['key'];
    }),
    product: Ember.computed(function () {
      return _environment.default['plaid']['product'];
    }),
    transactions: Ember.computed(function () {
      return {
        days_requested: 730
      };
    }),
    webhook: null,
    token: null,
    // Private
    _link: null,
    // TODO: Implement onEvent callback
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var ajaxOptions = {
        url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/plaid_links/create_link_token"),
        method: 'POST',
        contentType: 'application/json',
        data: {},
        successCallback: function successCallback(res) {
          _this2.set('token', res.link_token);

          var options = Object.assign(_this2.getProperties(OPTIONS), {
            token: res.link_token,
            onLoad: _this2._onLoad.bind(_this2),
            onSuccess: _this2._onSuccess.bind(_this2),
            onExit: _this2._onExit.bind(_this2)
          });
          return new Ember.RSVP.Promise(function (resolve, reject) {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
            script.onload = resolve;
            script.onerror = reject;
            document.getElementsByTagName('head')[0].appendChild(script);
          }).then(function () {
            _this2._link = window.Plaid.create(options);
          }).catch(function () {
            _this2.get('_onError')();
          });
        },
        errorCallback: function errorCallback(res) {
          _this2.errorHandler(res);
        }
      };
      this.get('ajaxCall').request(ajaxOptions); // this.get('ajax').request(`${ENV['plaid']['URL']}/link/token/create`, {
      //   method: 'POST',
      //   contentType: 'application/json',
      //   headers: {
      //     "client_id": `${ENV['plaid']['clientId']}`,
      //     "secret": `${ENV['plaid']['key']}`
      //   },
      //   data: {
      //     "client_id": `${ENV['plaid']['clientId']}`,
      //     "secret": `${ENV['plaid']['key']}`,
      //     "user": {
      //       "client_user_id": this.get('companyId')
      //     },
      //     "client_name": "Accountberry",
      //     "products": ["transactions"],
      //     "transactions": {
      //       "days_requested": 730
      //     },
      //     "country_codes": ["US"],
      //     "language": "en"
      //   }
      // })
      // .then((res) => {
      //   console.log('request_id', res.request_id);
      //   this.set('token', res.link_token);
      //   const options = Object.assign(this.getProperties(OPTIONS), {
      //     token: res.link_token,
      //     onLoad: this._onLoad.bind(this),
      //     onSuccess: this._onSuccess.bind(this),
      //     onExit: this._onExit.bind(this)
      //   });
      //   return new Ember.RSVP.Promise((resolve, reject) => {
      //     const script = document.createElement('script');
      //     script.type = 'text/javascript';
      //     script.async = true;
      //     script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
      //     script.onload = resolve;
      //     script.onerror = reject;
      //     document.getElementsByTagName('head')[0].appendChild(script);
      //   })
      //     .then(() => {
      //       
      //       this._link = window.Plaid.create(options);
      //     })
      //     .catch(() => {
      //       this.get('_onError')();
      //     })
      // })
      // .catch((error) => {
      //   this.errorHandler(error);
      // });
    },
    click: function click() {
      this.send('clicked');

      this._link.open();
    },
    _onError: function _onError() {
      this.send('errored');
    },
    _onLoad: function _onLoad() {
      this.send('loaded');
    },
    _onExit: function _onExit(error, metadata) {
      this.send('exited', error, metadata);
    },
    _onSuccess: function _onSuccess(token, metadata) {
      this.send('succeeded', token, metadata);
    },
    actions: {
      // Send closure actions passed into component
      clicked: function clicked() {
        this.get('onOpen')();
        this.set('success', false);

        if (this.token) {
          this.sendAction('close');
        }
      },
      loaded: function loaded() {
        this.get('onLoad')();
        this.set('token', undefined);
      },
      exited: function exited(error, metadata) {
        this.get('onExit')(error, metadata);
      },
      errored: function errored() {
        this.get('onError')();
      },
      succeeded: function succeeded(token, metadata) {
        if (!this.success && !this.token) {
          this.createPlaidLink(token, metadata);
        } // this.get('onSuccess')(token, metadata);

      }
    }
  });

  _exports.default = _default;
});