define("account-berry-frontend/companies/show/shopify/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    shopifyPermissionUrl: Ember.computed('myshopifyShop', function () {
      var scope = "read_products,read_inventory,read_orders,read_reports,write_reports,read_shopify_payments_payouts";
      var state = "".concat(this.get('session.data.authenticated.id'), "-").concat(this.get('companyId'));
      var url = "https://".concat(this.get('myshopifyShop'), ".myshopify.com/admin/oauth/authorize?client_id=").concat(_environment.default['shopify']['clientId'], "&scope=").concat(scope, "&redirect_uri=http://localhost:4200/auth/shopify/callback&state=").concat(state, "&grant_options[]=value");
      return url; // https://accountberry.myshopify.com/admin/oauth/authorize?client_id=53f2d999d2a1ceda4c32d1c4df61ca3d&scope=read_products&redirect_uri=http://localhost:4200&state=1234&grant_options[]=per-user
    }),
    actions: {
      unsync: function unsync() {
        var _this = this;

        this.get('model').destroyRecord().then(function () {
          _this.set('model', null);

          _this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Shopify Sync Deleted</span></div>"
          });
        });
      }
    }
  });

  _exports.default = _default;
});