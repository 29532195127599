define("account-berry-frontend/components/reconciliation-form/component", ["exports", "ember-cli-pagination/computed/paged-array", "account-berry-frontend/config/environment"], function (_exports, _pagedArray, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    cable: Ember.inject.service(),
    saveWorkModal: false,
    confirmDelete: false,
    creditSortAscending: false,
    debitSortAscending: false,
    markedCreditTransactions: 0,
    markedDebitTransactions: 0,
    accounts: [],
    reconcileable: Ember.computed('reconciliation.difference', function () {
      return Number(this.get('reconciliation.difference')) !== 0;
    }),
    allDebits: true,
    allCredits: true,
    pagedCreditTransactions: (0, _pagedArray.default)('creditTransactions', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    pagedDebitTransactions: (0, _pagedArray.default)('debitTransactions', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    transactionsObserver: Ember.observer('transactions', 'creditTransactions', 'debitTransactions', function () {
      this.set('pagedCreditTransactions', (0, _pagedArray.default)('creditTransactions', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
      this.set('pagedDebitTransactions', (0, _pagedArray.default)('debitTransactions', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    cashAccountTransactionsActionCable: function cashAccountTransactionsActionCable() {
      var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

      var _this = this;

      consumer.createSubscription({
        channel: "ReconciliationChannel",
        room: "CashAccountTransactions" + this.get('session.data.authenticated.id') + this.get('reconciliation.id')
      }, {
        connected: function connected() {
          _this.get('applicationController').set('showLoading', true);
        },
        received: function received(data) {
          if (data.error) {
            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
            });
          } else {
            _this.set('reconciliation.cash_account_transactions', data.transactions);

            consumer.disconnect();

            _this.get('applicationController').set('showLoading', false);
          }
        },
        disconnected: function disconnected() {
          console.log("ReconciliationChannel-CashAccountTransactions disconnected");
        }
      });
    },
    init: function init() {
      var _this2 = this;

      this._super();

      this.cashAccountTransactionsActionCable();

      if (!this.get('reconciliation')) {
        this.set('reconciliation', {
          transactions: [],
          cleared_balance: 0,
          ending_balance: 0
        });
      } else {
        this.set('reconciliation.remembered', !this.get('reconciliation.reconciled'));
      }

      this.store.findAll('account').then(function (accounts) {
        _this2.set('accounts', accounts);

        if (_this2.get('reconciliation.cash_account_id')) {
          _this2.setTransactions();
        }
      });
      this.set('reconciliations', Ember.computed(function () {
        var _this3 = this;

        return this.store.peekAll('reconciliation').toArray().filter(function (reconciliation) {
          return reconciliation.get('company_id').toString() === _this3.get('companyId');
        });
      }));
      this.set('channel', this.get('session.pusher').subscribe("reconcile-channel-".concat(this.get('session.data.authenticated.id'))));
    },
    cashAccountObserver: Ember.observer('reconciliation.cash_account_id', 'reconciliation.date', function () {
      this.reconciliationDataSetter();
    }),
    calculateTotalDebitTransactions: function calculateTotalDebitTransactions() {
      var transactions = this.get('debitTransactions');
      var transactionTotal = 0;
      var markedTransactions = 0;

      if (transactions) {
        transactions.forEach(function (transaction) {
          if (transaction.clear || transaction.marked) {
            transactionTotal += Number(transaction.amount);
            markedTransactions++;
          }
        });
      }

      this.set('totalDebitTransactionsAmount', transactionTotal);
      this.set('markedDebitTransactions', markedTransactions);
    },
    calculateTotalCreditTransactions: function calculateTotalCreditTransactions() {
      var transactions = this.get('creditTransactions');
      var transactionTotal = 0;
      var markedTransactions = 0;

      if (transactions) {
        transactions.forEach(function (transaction) {
          if (transaction.clear || transaction.marked) {
            transactionTotal += Number(transaction.amount);
            markedTransactions++;
          }
        });
      }

      this.set('totalCreditTransactionsAmount', transactionTotal);
      this.set('markedCreditTransactions', markedTransactions);
    },
    debitTransactionsObserver: Ember.observer('debitTransactions', function () {
      this.calculateTotalDebitTransactions();
    }),
    creditTransactionsObserver: Ember.observer('creditTransactions', function () {
      this.calculateTotalCreditTransactions();
    }),
    reconciliationDataSetter: function reconciliationDataSetter() {
      if (this.get('reconciliation.cash_account_id') && this.get('reconciliation.date')) {
        var _this = this;

        var cashAccountReconciliations = [];

        if (!this.get('reconciliation.beginning_balance')) {
          this.get('reconciliations').filter(function (reconciliation) {
            if (reconciliation.get('cash_account_id').toString() === _this.get('reconciliation.cash_account_id').toString() && reconciliation.get('reconciled')) {
              cashAccountReconciliations.push(reconciliation);
            }
          });

          if (cashAccountReconciliations.length > 0) {
            this.set('reconciliation.beginning_balance', Number(cashAccountReconciliations[cashAccountReconciliations.length - 1].get('ending_balance')));
          } else {
            this.set('reconciliation.beginning_balance', 0);
          }
        }
      }
    },
    reconciliationObserver: Ember.observer('reconciliation.cleared_balance', 'reconciliation.ending_balance', 'transactions', function () {
      this.calculateValues();
    }),
    cashAccounts: Ember.computed('accounts', function () {
      var cashAccounts = this.accounts.filter(function (account) {
        if (account.get('account_type.name').toLowerCase() === 'cash' && !account.get('is_title')) {
          return account;
        }
      });

      if (cashAccounts.length === 1 && !this.get('reconciliation.cash_account_id')) {
        this.send('updateCashAccount', cashAccounts[0].get('id'));
        var selectOptions = Ember.$("#selectCashAccount option");
        selectOptions[0].text = cashAccounts[0].get('name');
        selectOptions[0].value = cashAccounts[0].get('id');
        selectOptions[0].selected = true;
      }

      return cashAccounts;
    }),
    cashAccountTransactionsObserver: Ember.observer('reconciliation.cash_account_transactions', 'reconciliation.id', function () {
      this.setCashAccountTransactions();
    }),
    setCashAccountTransactions: function setCashAccountTransactions() {
      // this.set('account.transactions', this.get('reconciliation.cash_account_transactions'));
      if (this.get('reconciliation.cash_account_transactions') && this.get('reconciliation.cash_account_transactions').length >= 0) {
        var transactions;
        var markedTransactions = [];
        this.set('transactions', Ember.computed(function () {
          var _this4 = this;

          transactions = this.get('reconciliation.cash_account_transactions');
          return transactions.filter(function (transaction) {
            if (new Date(transaction.date) <= new Date(_this4.get('reconciliation.date'))) {
              if (!transaction.clear) {
                if (transaction.marked && _this4.get('reconciliation.remembered')) {
                  markedTransactions.push({
                    transaction_id: transaction.transaction_id,
                    type: transaction.type
                  });
                }

                return transaction;
              } else if (transaction.clear && transaction.reconciliation_id && transaction.reconciliation_id.toString() === _this4.get('reconciliation.id')) {
                return transaction;
              }
            }
          });
        }));

        if (this.get('reconciliation.remembered')) {
          this.set('reconciliation.transactions', markedTransactions);
        }

        transactions = this.get('reconciliation.transactions');

        if (transactions && this.get('reconciliation.reconciled') && transactions.length > 0 && transactions[0].reconciliation_id) {
          var reconciliationTransactions = [];
          transactions.map(function (transaction) {
            if (transaction.account_check_id) {
              reconciliationTransactions.push({
                transaction_id: transaction.account_check_id,
                type: "check"
              });
            } else if (transaction.account_deposit_id) {
              reconciliationTransactions.push({
                transaction_id: transaction.account_deposit_id,
                type: "deposit"
              });
            } else if (transaction.journal_entry_account_id) {
              reconciliationTransactions.push({
                transaction_id: transaction.journal_entry_account_id,
                type: "journal-entry"
              });
            }
          });
          this.set('reconciliation.transactions', reconciliationTransactions);
        }
      }
    },
    creditTransactions: Ember.computed('transactions', function () {
      var reconciliation = this.get('reconciliation');

      if (this.get('transactions') && this.get('transactions').length >= 0) {
        return this.get('transactions').filter(function (transaction) {
          if (transaction.account_action === 'credit') {
            if (transaction.marked) {
              if (!reconciliation.get('reconciled')) {
                return transaction;
              }
            } else {
              // If amount is 0, it should not be displayed
              if (parseFloat(transaction.amount) !== 0) {
                return transaction;
              }
            }
          }
        });
      }
    }),
    debitTransactions: Ember.computed('transactions', function () {
      var reconciliation = this.get('reconciliation');

      if (this.get('transactions') && this.get('transactions').length >= 0) {
        return this.get('transactions').filter(function (transaction) {
          if (transaction.account_action === 'debit') {
            if (transaction.marked) {
              if (!reconciliation.get('reconciled')) {
                return transaction;
              }
            } else {
              // If amount is 0, it should not be displayed
              if (parseFloat(transaction.amount) !== 0) {
                return transaction;
              }
            }
          }
        });
      }
    }),
    setStartDate: Ember.observer('account.last_reconciliation', function () {
      if (this.get('account.last_reconciliation.date') && !this.get('reconciliation.undeletable')) {
        var startDate = new Date(this.get('account.last_reconciliation.date'));
        startDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * -60000 + 1 * 24 * 60 * 60 * 1000);

        if (this.get('account.last_reconciliation.id') && this.get('reconciliation.id')) {
          this.set('startDate', this.get('account.last_reconciliation.id').toString() !== this.get('reconciliation.id').toString() ? startDate.toISOString().slice(0, 10) : "");
        } else if (!this.get('reconciliation.id') && this.get('account.last_reconciliation')) {
          this.set('startDate', startDate.toISOString().slice(0, 10));
        }
      }
    }),
    setTransactions: function setTransactions() {
      if (this.get('reconciliation.cash_account_id')) {
        this.set('account', this.store.peekRecord('account', this.get('reconciliation.cash_account_id')));
        this.setCashAccountTransactions();
      }
    },
    calculateValues: function calculateValues() {
      var _this5 = this;

      // Calculate cleared balance
      if (this.get('transactions')) {
        var clearedBalance = Number(this.get('reconciliation.beginning_balance'));
        this.get('transactions').map(function (transaction) {
          if (transaction.marked && !_this5.get('reconciliation.reconciled') || transaction.clear) {
            if (transaction.account_action === "credit") {
              clearedBalance -= Number(transaction.amount);
            } else if (transaction.account_action === "debit") {
              clearedBalance += Number(transaction.amount);
            }
          }
        }); // Calculate difference

        var endingBalance = Number(this.get('reconciliation.ending_balance')) || 0;
        var difference = endingBalance - clearedBalance;
        this.setProperties({
          'reconciliation.cleared_balance': clearedBalance.toFixed(2),
          'reconciliation.difference': difference.toFixed(2)
        });
      }

      this.calculateTotalDebitTransactions();
      this.calculateTotalCreditTransactions();
    },
    verifyRememberedReconciliation: function verifyRememberedReconciliation() {
      var _this = this;

      this.get('reconciliations').map(function (reconciliation) {
        if (reconciliation.get('cash_account_id').toString() === _this.get('reconciliation.cash_account_id').toString() && !reconciliation.get('reconciled')) {
          _this.store.findRecord('reconciliation', reconciliation.id).then(function (reconciliation) {
            _this.set('reconciliation', reconciliation);
          });

          _this.set('reconciliation.remembered', true);
        }
      });
    },
    actions: {
      updateCashAccount: function updateCashAccount(value) {
        this.set('reconciliation.cash_account_id', value);
        this.setTransactions();

        if (this.verifyRememberedReconciliation()) {
          this.setCashAccountTransactions();
        }

        if (this.get('reconciliation.cash_account_id') && this.get('reconciliation.date')) {
          if (this.get('reconciliation.id')) {
            this.send('update', false, true);
          } else {
            this.send('create', false, true);
          }
        }
      },
      rememberBeforeTransition: function rememberBeforeTransition() {
        var _this6 = this;

        if (!Ember.$('#rememberButton')[0].disabled) {
          setTimeout(function () {
            var action = _this6.get('reconciliation.id') ? 'update' : 'create';

            _this6.send(action, false, false, true); // this.get('router').transitionTo('companies.show.reconciliations', this.companyId);

          }, 100);
        }
      },
      updateReconciliationDate: function updateReconciliationDate() {
        this.setTransactions();

        if (this.get('reconciliation.cash_account_id') && this.get('reconciliation.date')) {
          if (this.get('reconciliation.id')) {
            this.send('update', false, true);
          } else {
            this.send('create', false, true);
          }
        }
      },
      updateTransactionList: function updateTransactionList(transaction) {
        var _this = this;

        var transactionId = transaction.transaction_id;
        var transactionType = transaction.type;

        if (!transaction.undeletable) {
          this.get('transactions').map(function (transaction, index) {
            if (transaction.transaction_id === transactionId && transaction.type === transactionType) {
              if (transaction.marked || transaction.clear) {
                var transactions = _this.get('reconciliation.transactions').filter(function (reconciliationTransaction) {
                  return reconciliationTransaction.transaction_id !== transaction.transaction_id;
                });

                _this.set('reconciliation.transactions', transactions);

                Ember.set(_this.get('transactions')[index], 'marked', false);
                Ember.set(_this.get('transactions')[index], 'clear', false);
              } else {
                _this.get('reconciliation.transactions').push({
                  transaction_id: transaction.transaction_id,
                  type: transaction.type
                });

                Ember.set(_this.get('transactions')[index], 'marked', true);
              }
            }
          });
          this.calculateValues();
        }
      },
      updateAllTransactions: function updateAllTransactions(type, mark) {
        var _this7 = this;

        var transactions = type === "debit" ? this.get('debitTransactions') : this.get('creditTransactions');
        transactions.forEach(function (transaction) {
          if (mark && !transaction.marked || !mark && transaction.marked) {
            _this7.send('updateTransactionList', transaction);
          }
        });
        this.toggleProperty(type === "debit" ? 'allDebits' : "allCredits");
      },
      create: function create(reconciled, autoRemember, save) {
        var _this8 = this;

        var originalReconciled = this.get('reconciliation.reconciled');
        this.set('reconciliation.reconciled', reconciled);
        var data = JSON.stringify({
          reconciliation: this.get('reconciliation')
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/reconciliations"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback(response) {
            if (autoRemember) {
              _this8.sendAction('reloadRoute');

              if (response.reconciliation) {
                _this8.get('router').transitionTo('companies.show.reconciliations.show', _this8.companyId, response.reconciliation.id);
              }
            } else if (save) {// Do nothing after remember
            } else {
              _this8.get('notify').success({
                html: "<i class=\"icon-checkmark lg\"></i> <div><strong>Success:</strong>&nbsp<span>".concat(reconciled ? 'Reconciliation Created' : 'Reconciliation Remembered', "</span></div>")
              });

              _this8.sendAction('reloadRoute');

              _this8.get('router').transitionTo('companies.show.reconciliations', _this8.companyId);
            }
          },
          errorCallback: function errorCallback(response) {
            _this8.set('reconciliation.reconciled', originalReconciled);

            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this8.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      update: function update(reconciled, autoRemember, save) {
        var _this9 = this;

        this.channel.bind('update', function (response) {
          _this9.sendAction('toggleLoading', false);

          if (autoRemember) {
            _this9.sendAction('reloadRoute');

            if (response.reconciliation) {
              _this9.get('router').transitionTo('companies.show.reconciliations.show', _this9.companyId, response.reconciliation.id);
            }
          } else if (save) {// Do nothing after remember
          } else {
            _this9.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Reconciliation Updated</span></div>'
            });

            _this9.sendAction('reloadRoute'); // this.get('router').transitionTo('companies.show.reconciliations', this.companyId);

          }
        });
        var originalReconciled = this.get('reconciliation.reconciled');
        this.set('reconciliation.reconciled', reconciled);
        var reconciliation = this.get('reconciliation.data');
        reconciliation.difference = this.get('reconciliation.difference');
        reconciliation.cleared_balance = this.get('reconciliation.cleared_balance');
        reconciliation.reconciled = reconciled;
        reconciliation.transactions = this.get('reconciliation.transactions').filter(function (e, i) {
          return _this9.get('reconciliation.transactions').findIndex(function (a) {
            return a['transaction_id'] === e['transaction_id'];
          }) === i;
        });
        reconciliation.ending_balance = this.get('reconciliation.ending_balance');
        reconciliation.date = this.get('reconciliation.date'); // let data = JSON.stringify({ reconciliation: this.get('reconciliation') });

        var data = JSON.stringify({
          reconciliation: reconciliation
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/reconciliations/").concat(this.get('reconciliation.id')),
          method: 'PUT',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            _this9.sendAction('toggleLoading', true);
          },
          errorCallback: function errorCallback(response) {
            _this9.set('reconciliation.reconciled', originalReconciled);

            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this9.get('reconciliation').rollbackAttributes();

            _this9.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      toggleConfirmDelete: function toggleConfirmDelete(transaction) {
        this.toggleProperty('confirmDelete');
        this.set('transactionDeletion', !!transaction);
        this.set('transaction', transaction);
      },
      deleteReconciliation: function deleteReconciliation() {
        var _this10 = this;

        this.channel.bind('destroy', function (response) {
          _this10.sendAction('toggleLoading', false);

          if (response.errors) {
            if (response.errors && response.errors[0].status !== "500") {
              var errors = "An Unkown Error Occured.";

              if (response.errors && response.errors[0].status !== "500") {
                errors = response.errors.map(function (error) {
                  return error;
                });
              } else if (response.payload.errors) {
                errors = response.payload.errors.map(function (error) {
                  return error;
                });
              }

              _this10.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
              });
            } else {
              Ember.Logger.debug(response);
            }
          } else {
            _this10.setProperties({
              confirmDelete: false
            });

            _this10.get('reloadRoute');

            _this10.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Transaction Deleted</span></div>"
            });

            _this10.get('router').transitionTo('companies.show.reconciliations', _this10.companyId);
          }
        });
        this.reconciliation.destroyRecord().then(function () {
          _this10.sendAction('toggleLoading', true);
        }).catch(function (response) {
          if (response.errors && response.errors[0].status !== "500") {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this10.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          } else {
            Ember.Logger.debug(response);
          }
        });
        this.toggleProperty('confirmDelete');
      },
      deleteTransaction: function deleteTransaction() {
        var _this11 = this;

        this.store.findRecord(this.get('transaction.type'), this.get('transaction.id')).then(function (transaction) {
          transaction.destroyRecord().then(function () {
            _this11.set('transactions', _this11.get('transactions').filter(function (transaction) {
              return transaction.id !== _this11.get('transaction.id');
            }));

            _this11.set('confirmDelete', false);

            _this11.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Transaction Deleted</span></div>"
            });
          }).catch(function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this11.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          });
        });
      },
      creditSortBy: function creditSortBy(property) {
        this.toggleProperty('creditSortAscending');
        var sortedTransactions = [];

        if (property === "amount") {
          sortedTransactions = this.get('creditTransactions').sort(function (a, b) {
            return parseFloat(a.amount || 0) - parseFloat(b.amount || 0);
          });
        } else {
          sortedTransactions = this.get('creditTransactions').sortBy(property);
        }

        this.set('creditTransactions', this.creditSortAscending ? sortedTransactions : sortedTransactions.reverse());
        this.set('pagedCreditTransactions', (0, _pagedArray.default)('creditTransactions', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      debitSortBy: function debitSortBy(property) {
        this.toggleProperty('debitSortAscending');
        var sortedTransactions = [];

        if (property === "amount") {
          sortedTransactions = this.get('debitTransactions').sort(function (a, b) {
            return parseFloat(a.amount || 0) - parseFloat(b.amount || 0);
          });
        } else {
          sortedTransactions = this.get('debitTransactions').sortBy(property);
        }

        this.set('debitTransactions', this.debitSortAscending ? sortedTransactions : sortedTransactions.reverse());
        this.set('pagedDebitTransactions', (0, _pagedArray.default)('debitTransactions', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      debitLoadNext: function debitLoadNext() {
        this.get('pagedDebitTransactions').loadNextPage();
      },
      creditLoadNext: function creditLoadNext() {
        this.get('pagedCreditTransactions').loadNextPage();
      },
      back: function back() {
        history.back();
      }
    }
  });

  _exports.default = _default;
});