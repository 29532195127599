define("account-berry-frontend/companies/show/employees/desempleo-incapacidad/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    getPdfTrigger: false,
    init: function init() {
      var _this2 = this;

      this._super();

      var channel = this.get('session.pusher').subscribe("unemployment-disability-channel-".concat(this.get('session.data.authenticated.id')));
      channel.bind('quarter', function (response) {
        _this2.store.findRecord('json-storage', response.json_storage_id).then(function (jsonStorage) {
          _this2.set('quarterData', jsonStorage.get('stored_json.quarter'));
        });
      });
    },
    employeeRows: [{
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 593
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 577
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 561
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 545
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 529
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 513
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 496
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 480
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 462
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 445
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 429
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 411
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 395
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 380
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 362
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 344
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 328
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 312
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 293
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 278
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 263
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 244
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 229
    }, {
      ssX: 49,
      nameX: 163,
      salaryX: 345,
      partialX: 503,
      y: 212
    }],
    calculateUnemploymentAmountOfCheck: Ember.observer('_4a', '_4b', '_5a', '_6a', '_6b', 'quarterData', function () {
      if (this.get('quarterData')) {
        var _4A = parseFloat(this.get('_4a') || 0) * 0.01 * parseFloat(this.get('quarterData.total_unemployment_taxable_wages_paid'));

        var _4B = parseFloat(this.get('_4b') || 0) * 0.01 * parseFloat(this.get('quarterData.total_disability_taxable_wages_paid'));

        var _5A = parseFloat(this.get('_5a') || 0) * 0.01 * parseFloat(this.get('quarterData.total_unemployment_taxable_wages_paid'));

        var _7A = _4A + _5A - parseFloat(this.get('_6unemployment') || 0);

        var _7B = _4B - parseFloat(this.get('_6disability') || 0);

        if (!this.get('quarterData.exclude_unemployment')) {
          this.set('unemploymentAmountOfCheck', _7A);
        }

        this.set('disabilityAmountOfCheck', _7B);
      }
    }),
    firstPagePdfCoordinates: Ember.computed('company', 'employerCondition', 'quarter', 'suplementarySupport', 'amendmentReport', 'changeOfAddress', 'quarterData', '_4a', '_4b', '_5a', '_6a', '_6b', 'unemploymentAmountOfCheck', 'disabilityAmountOfCheck', 'wagesInMagneticMedia', function () {
      var today = new Date();
      return {
        firstEmployerNumber: {
          value: this.get('company.unemployment_number') || "",
          x: 122,
          y: 674
        },
        partOneAddressOne: {
          value: this.get('company.postal_address_line_one') || "",
          x: 330,
          y: 684
        },
        partOneAddressTwo: {
          value: this.get('company.postal_address_line_two') || "",
          x: 330,
          y: 660
        },
        partOneAddressThree: {
          value: "".concat(this.get('company.postal_address_city') || "", " ").concat(this.get('company.postal_address_country') || "", " ").concat(this.get('company.postal_address_zip') || ""),
          x: 330,
          y: 634
        },
        partTwoAddressOne: {
          value: this.get('company.physical_address_line_one') || "",
          x: 18,
          y: 535
        },
        partTwoAddressTwo: {
          value: this.get('company.physical_address_line_two') || "",
          x: 18,
          y: 510
        },
        partTwoAddressThree: {
          value: "".concat(this.get('company.physical_address_city') || "", " ").concat(this.get('company.physical_address_country') || "", " ").concat(this.get('company.physical_address_zip') || ""),
          x: 18,
          y: 487
        },
        firstBoxCompanyName: {
          value: this.get('company.name') || "",
          x: 29,
          y: 636
        },
        firstBoxAddressOne: {
          value: this.get('company.postal_address_line_one') || "",
          x: 29,
          y: 613
        },
        firstBoxAddressTwo: {
          value: this.get('company.postal_address_line_two') || "",
          x: 29,
          y: 600
        },
        firstBoxAddressThree: {
          value: "".concat(this.get('company.postal_address_city') || "", " ").concat(this.get('company.postal_address_country') || "", " ").concat(this.get('company.postal_address_zip') || ""),
          x: 29,
          y: 587
        },
        secondBoxCompanyName: {
          value: this.get('company.name') || "",
          x: 29,
          y: 312
        },
        secondBoxAddressOne: {
          value: this.get('company.postal_address_line_one') || "",
          x: 29,
          y: 290
        },
        secondBoxAddressTwo: {
          value: this.get('company.postal_address_line_two') || "",
          x: 29,
          y: 273
        },
        secondBoxAddressThree: {
          value: "".concat(this.get('company.postal_address_city') || "", " ").concat(this.get('company.postal_address_country') || "", " ").concat(this.get('company.postal_address_zip') || ""),
          x: 29,
          y: 260
        },
        taxId: {
          value: this.get('company.ein') || "",
          x: 38,
          y: 423
        },
        businessDiscontinued: {
          value: this.get('employerCondition') === "business_discontinued" ? "X" : "",
          x: 326,
          y: 594
        },
        businessSold: {
          value: this.get('employerCondition') === "business_sold" ? "X" : "",
          x: 326,
          y: 576
        },
        other: {
          value: this.get('employerCondition') === "other" ? "X" : "",
          x: 326,
          y: 562
        },
        name: {
          value: this.get('company.contact_name') || "",
          x: 356,
          y: 497
        },
        date: {
          value: moment(today).format("MM/DD/YYYY"),
          x: 356,
          y: 472
        },
        telephone: {
          value: this.get('company.phone') || "",
          x: 356,
          y: 428
        },
        secondEmployerNumber: {
          value: this.get('company.unemployment_number') || "",
          x: 335,
          y: 300
        },
        quarter: {
          value: this.get('quarter') || "",
          x: 497,
          y: 300
        },
        suplementarySupport: {
          value: this.get('suplementarySupport') ? "X" : "",
          x: 20,
          y: 228,
          size: 10
        },
        amendmentReport: {
          value: this.get('amendmentReport') ? "X" : "",
          x: 100,
          y: 228,
          size: 10
        },
        cambioDeDireccion: {
          value: this.get('changeOfAddress') ? "X" : "",
          x: 176,
          y: 228,
          size: 10
        },
        primerMes: {
          value: this.get('quarterData.first_month_employees_with_payroll_count') || "0",
          x: 297,
          y: 233
        },
        segundoMes: {
          value: this.get('quarterData.second_month_employees_with_payroll_count') || "0",
          x: 388,
          y: 233
        },
        tercerMes: {
          value: this.get('quarterData.third_month_employees_with_payroll_count') || "0",
          x: 497,
          y: 233
        },
        unemploymentInsuranceTotalPaid: {
          value: this.get('quarterData.total_wages_paid') ? parseFloat(this.get('quarterData.total_wages_paid')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "0.00",
          x: 388,
          y: 171
        },
        disabilityInsuranceTotalPaid: {
          value: this.get('quarterData.total_wages_paid') ? parseFloat(this.get('quarterData.total_wages_paid')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "0.00",
          x: 497,
          y: 171
        },
        unemploymentInsuranceTaxable: {
          value: this.get('quarterData.total_unemployment_taxable_wages_paid') ? parseFloat(this.get('quarterData.total_unemployment_taxable_wages_paid')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "0.00",
          x: 388,
          y: 144
        },
        disabilityInsuranceTaxable: {
          value: this.get('quarterData.total_disability_taxable_wages_paid') ? parseFloat(this.get('quarterData.total_disability_taxable_wages_paid')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "0.00",
          x: 497,
          y: 144
        },
        contributionDuePercentageA: {
          value: this.get('_4a') || "0",
          x: 297,
          y: 117
        },
        contributionDuePercentageB: {
          value: this.get('_4b') || "0",
          x: 344,
          y: 117
        },
        unemploymentContributionDue: {
          value: (parseFloat(this.get('_4a') || 0) * 0.01 * parseFloat(this.get('quarterData.total_unemployment_taxable_wages_paid'))).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'),
          x: 388,
          y: 117
        },
        disabilityContributionDue: {
          value: (parseFloat(this.get('_4b') || 0) * 0.01 * parseFloat(this.get('quarterData.total_disability_taxable_wages_paid'))).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'),
          x: 497,
          y: 117
        },
        specialAssessmentA: {
          value: this.get('_5a') || "0.00",
          x: 297,
          y: 90
        },
        unemploymentSpecialAssessment: {
          value: (parseFloat(this.get('_5a') || 0) * 0.01 * parseFloat(this.get('quarterData.total_unemployment_taxable_wages_paid'))).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'),
          x: 388,
          y: 90
        },
        amountOfCreditA4: {
          value: this.get('_6a') || "0.00",
          x: 182,
          y: 70
        },
        amountOfCreditB4: {
          value: this.get('_6b') || "0.00",
          x: 182,
          y: 58
        },
        _6unemployment: {
          value: this.get('_6unemployment') || "0.00",
          x: 388,
          y: 63
        },
        _6disability: {
          value: this.get('_6disability') || "0.00",
          x: 497,
          y: 63
        },
        amountOfCreditB: {
          value: parseFloat(this.get('_6a') || 0) + parseFloat(this.get('_6b') || 0),
          x: 294,
          y: 58
        },
        unemploymentAmountOfCheck: {
          value: this.get('unemploymentAmountOfCheck') ? this.get('unemploymentAmountOfCheck').toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "",
          x: 388,
          y: 32
        },
        disabilityAmountOfCheck: {
          value: this.get('disabilityAmountOfCheck') ? this.get('disabilityAmountOfCheck').toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "",
          x: 497,
          y: 32
        }
      };
    }),
    secondPagePdfCoordinates: Ember.computed('company', 'wagesInMagneticMedia', 'quarterEndingOn', 'quarterData', function () {
      return {
        sentWagesInMagneticMedia: {
          value: this.get('wagesInMagneticMedia') ? "X" : "",
          x: 254,
          y: 685,
          size: 12
        },
        employerNumber: {
          value: this.get('company.unemployment_number') || "",
          x: 360,
          y: 663
        },
        quarterEnded: {
          value: this.get('quarterEndingOn') || "",
          x: 510,
          y: 663
        },
        // employeesSubtotal: {
        //   value: this.get('quarterData.employee_data').length || "",
        //   x: 503,
        //   y: 172
        // },
        // employeesTotal: {
        //   value: this.get('quarterData.employee_data').length || "",
        //   x: 503,
        //   y: 142
        // },
        boxCompanyName: {
          value: this.get('company.name') || "",
          x: 49,
          y: 685
        },
        boxAddressOne: {
          value: this.get('company.postal_address_line_one') || "",
          x: 49,
          y: 670
        },
        boxAddressTwo: {
          value: this.get('company.postal_address_line_two') || "",
          x: 49,
          y: 660
        },
        boxAddressThree: {
          value: "".concat(this.get('company.postal_address_city') || "", " ").concat(this.get('company.postal_address_country') || "", " ").concat(this.get('company.postal_address_zip') || ""),
          x: 49,
          y: 650
        }
      };
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    getPDF: Ember.observer('quarterData', function () {
      var _this3 = this;

      fetch('https://trimestrales.s3.amazonaws.com/Desemp_e_incap.pdf').then(function (res) {
        var file = res.arrayBuffer();
        file.then(function (f) {
          // let pdfDoc = PDFLib.PDFDocumentFactory.load(new Uint8Array(f));
          _this3.pdfProcess(new Uint8Array(f));
        });
      });
    }),
    pdfProcess: function pdfProcess(existingPdfDocBytes) {
      var _this4 = this;

      var _this = this;

      var pdfDoc = PDFLib.PDFDocumentFactory.load(existingPdfDocBytes);

      var _pdfDoc$embedStandard = pdfDoc.embedStandardFont(PDFLib.StandardFonts.Helvetica),
          _pdfDoc$embedStandard2 = _slicedToArray(_pdfDoc$embedStandard, 2),
          helveticaRef = _pdfDoc$embedStandard2[0],
          helveticaFont = _pdfDoc$embedStandard2[1];

      this.set('helveticaFont', helveticaFont);
      var pages = pdfDoc.getPages();
      var firstPage = pages[0];
      var secondPage = pages[1];
      firstPage.addFontDictionary('Helvetica', helveticaRef);
      secondPage.addFontDictionary('Helvetica', helveticaRef); // ------------------------- FIRST PAGE PROCEDURE -------------------------

      var firstPageKeys = Object.keys(this.get('firstPagePdfCoordinates'));
      firstPageKeys.forEach(function (k) {
        var contentStream = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(_this4.get("firstPagePdfCoordinates.".concat(k, ".value")).toString()), {
          x: _this.get("firstPagePdfCoordinates.".concat(k, ".x")),
          y: _this.get("firstPagePdfCoordinates.".concat(k, ".y")),
          size: _this.get("firstPagePdfCoordinates.".concat(k, ".size")) || 8,
          font: 'Helvetica',
          colorRgb: _this.get("firstPagePdfCoordinates.".concat(k, ".color")) ? _this.get("firstPagePdfCoordinates.".concat(k, ".color")) : [0, 0, 1]
        }));
        firstPage.addContentStreams(pdfDoc.register(contentStream));
      }); // -----------------------------------------------------------------------
      // ------------------------- SECOND PAGE PROCEDURE ------------------------

      secondPage.addFontDictionary('Helvetica', helveticaRef);
      var secondPageKeys = Object.keys(this.get('secondPagePdfCoordinates'));
      secondPageKeys.forEach(function (k) {
        var contentStream = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(_this4.get("secondPagePdfCoordinates.".concat(k, ".value")).toString()), {
          x: _this.get("secondPagePdfCoordinates.".concat(k, ".x")),
          y: _this.get("secondPagePdfCoordinates.".concat(k, ".y")),
          size: _this.get("secondPagePdfCoordinates.".concat(k, ".size")) || 8,
          font: 'Helvetica',
          colorRgb: _this.get("secondPagePdfCoordinates.".concat(k, ".color")) ? _this.get("secondPagePdfCoordinates.".concat(k, ".color")) : [0, 0, 1]
        }));
        secondPage.addContentStreams(pdfDoc.register(contentStream));
      });
      this.employeeRowsProcedure(secondPage, pdfDoc); // -----------------------------------------------------------------------

      this.get('applicationController').set('showLoading', false);
      var pdfBytes = PDFLib.PDFDocumentWriter.saveToBytes(pdfDoc);
      this.downloadPdf(pdfBytes);
    },
    employeeRowsProcedure: function employeeRowsProcedure(secondPage, pdfDoc) {
      var _this5 = this;

      var employeesWithSalariesPaid = this.get('quarterData.employee_data').filter(function (employee) {
        return employee.total_salaries_paid !== 0 && employee.total_salaries_paid !== "0.0";
      });
      var totalEmployeeRowPages = Math.ceil(employeesWithSalariesPaid.length / 24);
      var contentStream;
      var employeeTotal = employeesWithSalariesPaid.length; // let originalSecondPage = secondPage

      var copyPage = function copyPage(page) {
        var copiedPage = PDFLib.PDFPage.fromDict(PDFLib.PDFDictionary.from(new Map(page.map), page.index));

        if (page.getMaybe('Contents')) {
          page.normalizeContents();
          var originalContents = page.Contents;
          var copiedContents = PDFLib.PDFArray.fromArray(originalContents.array.slice(), page.index);
          copiedPage.set('Contents', copiedContents);
        }

        return copiedPage;
      };

      var _loop = function _loop(i) {
        var newPage = copyPage(secondPage);
        var newPageIndex = i + 1;
        pdfDoc.insertPage(newPageIndex, newPage);
        var pages = pdfDoc.getPages();
        newPage = pages[newPageIndex];

        _this5.get('employeeRows').forEach(function (row, index) {
          var employeeIndex = index + 24 * i;

          if (employeesWithSalariesPaid[employeeIndex]) {
            _this5.addEmployeeRow(employeesWithSalariesPaid[employeeIndex].ss, {
              x: row.ssX,
              y: row.y
            }, pdfDoc, newPage);

            _this5.addEmployeeRow(employeesWithSalariesPaid[employeeIndex].name, {
              x: row.nameX,
              y: row.y
            }, pdfDoc, newPage);

            _this5.addEmployeeRow(parseFloat(employeesWithSalariesPaid[employeeIndex].total_salaries_paid).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), {
              x: row.salaryX,
              y: row.y
            }, pdfDoc, newPage);

            _this5.addEmployeeRow(employeesWithSalariesPaid[employeeIndex].partial ? "Si" : "No", {
              x: row.partialX,
              y: row.y
            }, pdfDoc, newPage);
          }
        });

        contentStream = pdfDoc.createContentStream(PDFLib.drawText(_this5.get('helveticaFont').encodeText(employeeTotal.toString()), {
          x: 505,
          y: 142,
          size: 8,
          font: 'Helvetica',
          colorRgb: [0, 0, 1]
        }));
        newPage.addContentStreams(pdfDoc.register(contentStream));
        contentStream = pdfDoc.createContentStream(PDFLib.drawText(_this5.get('helveticaFont').encodeText(parseFloat(_this5.get('quarterData.total_wages_paid')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')), {
          x: 505,
          y: 172,
          size: 8,
          font: 'Helvetica',
          colorRgb: [0, 0, 1]
        }));
        newPage.addContentStreams(pdfDoc.register(contentStream));
      };

      for (var i = 1; i < totalEmployeeRowPages; i++) {
        _loop(i);
      }

      ; // This is the second page
      // It is done after the other pages because the second page has to be copied with the employee rows empty

      this.get('employeeRows').forEach(function (row, index) {
        if (index + 1 <= employeesWithSalariesPaid.length) {
          _this5.addEmployeeRow(employeesWithSalariesPaid[index].ss, {
            x: row.ssX,
            y: row.y
          }, pdfDoc, secondPage);

          _this5.addEmployeeRow(employeesWithSalariesPaid[index].name, {
            x: row.nameX,
            y: row.y
          }, pdfDoc, secondPage);

          _this5.addEmployeeRow(parseFloat(employeesWithSalariesPaid[index].total_salaries_paid).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), {
            x: row.salaryX,
            y: row.y
          }, pdfDoc, secondPage);

          _this5.addEmployeeRow(employeesWithSalariesPaid[index].partial ? "Si" : "No", {
            x: row.partialX,
            y: row.y
          }, pdfDoc, secondPage);
        }
      });
      contentStream = pdfDoc.createContentStream(PDFLib.drawText(this.get('helveticaFont').encodeText(employeeTotal.toString()), {
        x: 505,
        y: 142,
        size: 8,
        font: 'Helvetica',
        colorRgb: [0, 0, 1]
      }));
      secondPage.addContentStreams(pdfDoc.register(contentStream));
      contentStream = pdfDoc.createContentStream(PDFLib.drawText(this.get('helveticaFont').encodeText(parseFloat(this.get('quarterData.total_wages_paid')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')), {
        x: 505,
        y: 172,
        size: 8,
        font: 'Helvetica',
        colorRgb: [0, 0, 1]
      }));
      secondPage.addContentStreams(pdfDoc.register(contentStream));
    },
    addEmployeeRow: function addEmployeeRow(val, coord, pdfDoc, page) {
      var contentStream = pdfDoc.createContentStream(PDFLib.drawText(this.get('helveticaFont').encodeText(val.toString()), {
        x: coord.x,
        y: coord.y,
        size: 8,
        font: 'Helvetica',
        colorRgb: [0, 0, 1]
      }));
      page.addContentStreams(pdfDoc.register(contentStream));
    },
    downloadPdf: function downloadPdf(data) {
      var blob = new Blob([data], {
        type: "application/pdf"
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob); // link.download = "myFileName.pdf";
      // link.click();

      window.open(link.href);
    },
    actions: {
      setYear: function setYear(year) {
        this.set('year', year); // this.set('pdfCoordinates.type.y', y)
      },
      setQuarter: function setQuarter(date) {
        this.set('quarterEndingOn', "".concat(date, "-").concat(this.get('year')));
        var quarterByYear = {
          "2019": {
            '31-03': "191",
            '30-06': "192",
            '30-09': "193",
            '31-12': "194"
          },
          "2020": {
            '31-03': "201",
            '30-06': "202",
            '30-09': "203",
            '31-12': "204"
          },
          "2021": {
            '31-03': "211",
            '30-06': "212",
            '30-09': "213",
            '31-12': "214"
          },
          "2022": {
            '31-03': "221",
            '30-06': "222",
            '30-09': "223",
            '31-12': "224"
          }
        };

        switch (date) {
          case '31-03':
            this.set('quarter', quarterByYear[this.get('year')]['31-03']);
            break;

          case '30-06':
            this.set('quarter', quarterByYear[this.get('year')]['30-06']);
            break;

          case '30-09':
            this.set('quarter', quarterByYear[this.get('year')]['30-09']);
            break;

          case '31-12':
            this.set('quarter', quarterByYear[this.get('year')]['31-12']);
            break;

          default:
        } // this.set('pdfCoordinates.type.y', y)

      },
      generate: function generate() {
        var _this6 = this;

        var data = JSON.stringify({
          quarter: {
            date: this.get('quarterEndingOn') || "31-03-2019",
            type: 'unemployment_disability'
          }
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/quarters"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            _this6.get('applicationController').set('showLoading', true);
          },
          errorCallback: function errorCallback(e) {
            _this6.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(e.payload.errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      } // generate() {
      //   fetch('https://trimestrales.s3.amazonaws.com/Form499R-1B.pdf').then((res) => {
      //     let file = res.arrayBuffer()
      //     file.then((f) => {
      //       this.pdfProcess(new Uint8Array(f));
      //     })
      //   })
      // }

    }
  });

  _exports.default = _default;
});