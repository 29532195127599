define("account-berry-frontend/components/import-form/component", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    notify: Ember.inject.service(),
    importTypes: [{
      name: "Banco Popular"
    }, {
      name: "First Bank"
    }, {
      name: "Other Bank",
      headers: ['Date', 'Description', 'Amount'],
      body: ['Required', 'Required', "Required (In positive/negative values using '-')"]
    }, {
      name: 'Accounts',
      headers: ['Number', 'Name', 'Activity', 'Type', 'Tax Id', 'Bank Account Number', 'Routing Number', 'Bank Name', 'Bank Address', 'Check Message'],
      body: ['Optional', 'Required', 'Required', 'Required', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional']
    }, {
      name: 'Customers',
      headers: ['First Name', 'Last Name', 'Display Name', 'Company Name', 'Company EIN', 'Website', 'Billing Address Line 1', 'Billing Address Line 2', 'Billing City', 'Billing State', 'Billing Country', 'Billing Zipcode', 'Shipping Address Line 1', 'Shipping Address Line 2', 'Shipping City', 'Shipping State', 'Shipping Country', 'Shipping Zipcode', 'Phone Number', 'Email Address', 'Notes'],
      body: ['Optional', 'Optional', 'Required', 'Required', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional']
    }, {
      name: 'Vendors',
      headers: ['First Name', 'Last Name', 'Display Name', 'Company Name', 'Company EIN', 'Website', 'Address Line 1', 'Address Line 2', 'City', 'State', 'Country', 'Zipcode', 'Phone Number', 'Email Address', 'Notes'],
      body: ['Optional', 'Optional', 'Required', 'Required', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional']
    }, {
      name: 'Representatives',
      headers: ['First Name', 'Last Name', 'Display Name', 'Phone', 'Email'],
      body: ['Optional', 'Optional', 'Required', 'Optional', 'Optional']
    }, {
      name: 'Employees',
      headers: ['Number', 'First Name', 'Last Name', 'Birth Date', 'Social Security', 'License Number', 'Gender', 'Start Date', 'Filing Status', 'CFSE Category', 'Email', 'Phone', 'Postal Address Line 1', 'Postal Address Line 2', 'Postal City', 'Postal State', 'Postal Zipcode', 'Postal Country', 'Physical Address Line 1', 'Physical Address Line 2', 'Physical City', 'Physical State', 'Physical Zipcode', 'Physical Country', 'Vacation Accrual Hours', 'Vacation Hours Worked', 'Vacation Accrual Type', 'Vacation Accrual Expiration', 'Vacation Accrual Expiration Date', 'Sickness Accrual Hours', 'Sickness Hours Worked', 'Sickness Accrual Type', 'Sickness Accrual Expiration', 'Sickness Accrual Expiration Date'],
      body: ['Optional', 'Required', 'Required', 'Optional (dd/mm/yyyy)', 'Required', 'Optional', 'Optional', 'Optional', 'Required', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Optional', 'Required', 'Required', 'Required', 'Required', 'Required', 'Required', 'Required', 'Required', 'Required', 'Required']
    }, {
      name: 'Items',
      headers: ['Number', 'Name', 'Cost', 'Sale Price', 'Type', 'Description'],
      body: ['Required', 'Required', 'Required (If Inventory Item)', 'Required', 'Required', 'Optional']
    }, {
      name: 'Checks',
      headers: ['Number', 'Date', 'New Transaction', 'Type', 'Payee Name', 'Payee Type', 'Account', 'Description', 'Amount'],
      body: ['Required (If New Check)', 'Required (If New Check)', '"Yes" (If New Check)', 'Required (If New Check)', 'Required (If New Check)', 'Required (If New Check)', 'Required', 'Optional', 'Required'],
      exampleBody: [['1433', '09/02/2018', 'Yes', 'Check', 'Julio Negrete', 'Vendor', 'Banco Popular', 'Payment for consultancy', '2,790'], ['', '', '', '', '', '', 'Profesional Fees', '', '3,000'], ['', '', '', '', '', '', 'Income tax withholding', '', '-210']],
      info: "Payroll & Payment type Checks are not supported for importing and will be ignored by the import system."
    }, {
      name: 'Deposits',
      headers: ['Number', 'Date', 'New Transaction', 'Type', 'Customer', 'Account', 'Description', 'Amount', 'Payment Method'],
      body: ['Required (If New Deposit)', 'Required (If New Deposit)', '"Yes" (If New Deposit)', 'Required (If New Deposit)', 'Required (If New Deposit)', 'Required (If New Deposit)', 'Optional', 'Required', 'Optional'],
      exampleBody: [['0012', '09/02/2018', 'Yes', 'Deposit', 'Hector Delgado', 'Banco Popular', 'Monday Sales', '500', 'Cash'], ['', '', '', '', '', 'Sales', 'Full Package', '500', '']],
      info: "Payment type Deposits are not supported for importing and will be ignored by the import system."
    }, {
      name: 'Journal Entries',
      headers: ['Number', 'Date', 'New Transaction', 'Account', 'Customer', 'Vendor', 'Description', 'Debit', 'Credit'],
      body: ['Required (If New Transaction)', 'Required (If New Transaction)', '"Yes" (If New Transaction)', 'Required (Unless New Transaction)', 'Optional', 'Optional', 'Optional', 'Required', 'Required'],
      exampleBody: [['162', '09/02/2018', 'Yes', '', '', '', '', '183.98', '183.98'], ['', '', '', 'Sales', '', '', '', '', '165'], ['', '', '', 'IVU', '', '', '', '', '18.98'], ['', '', '', 'Banco Popular', '', '', '', '183.98', '']]
    } // 'Accounts,'Checks', 'Customers', 'Deposits', 'Employees', 'Items', 'Representatives', 'Vendors'
    ],
    showBankConfig: Ember.computed('importType.name', function () {
      var names = ["Banco Popular", "First Bank", "Other Bank"];
      return names.includes(this.get('importType.name'));
    }),
    init: function init() {
      this._super();

      this.set('batch', null);

      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }
    },
    subscribeToChannels: function subscribeToChannels() {
      var _this = this;

      var channel = this.get('session.pusher').subscribe("import-channel-".concat(this.get('session.data.authenticated.id')));
      channel.bind('analyze', function (response) {
        // this.sendAction('toggleShowLoading');
        if (_this.isDestroyed) {
          return;
        } else {
          if (response.error) {
            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(response.error, "</span></div>")
            });

            _this.set('reviewing', false);
          } else {
            _this.store.findRecord('batch', response.batch_id).then(function (batch) {
              _this.set('batch', batch);

              _this.get('router').transitionTo('companies.show.batches.show', batch.id);
            });
          }
        }
      });
      this.set('subscribedToChannel', true);
    },
    resetProperties: function resetProperties() {
      this.setProperties({
        reviewing: false,
        importType: null,
        importFile: null,
        batch: {},
        analyzedImportData: null,
        cashAccountId: null,
        incomeAccountId: null,
        expenseAccountId: null
      });
    },
    cashAccounts: Ember.computed('accounts', function () {
      var cashAccounts = this.get('accounts').toArray().filter(function (account) {
        if (account.get('account_type.name').toLowerCase() === 'cash' && !account.get('is_title')) {
          return account;
        }
      });
      return cashAccounts;
    }),
    incomeAccounts: Ember.computed('accounts', function () {
      var incomeAccounts = this.get('accounts').toArray().filter(function (account) {
        if (account.get('account_type.name').toLowerCase() === 'income' && !account.get('is_title')) {
          return account;
        }
      });
      return incomeAccounts;
    }),
    expenseAccounts: Ember.computed('accounts', function () {
      var expenseAccounts = this.get('accounts').toArray().filter(function (account) {
        if (account.get('account_type.name').toLowerCase() === 'expense' && !account.get('is_title')) {
          return account;
        }
      });
      return expenseAccounts;
    }),
    valid: Ember.computed('importType', 'importFile', 'cashAccountId', 'incomeAccountId', 'expenseAccountId', function () {
      if (this.get('importType.headers')) {
        return this.get('importFile');
      } else {
        if (this.get('importFile') && this.get('cashAccountId') && this.get('incomeAccountId') && this.get('expenseAccountId')) {
          return true;
        } else {
          return false;
        }
      }
    }),
    actions: {
      reloadRoute: function reloadRoute() {
        this.sendAction('reloadRoute');
      },
      toggleShowLoading: function toggleShowLoading() {
        this.sendAction('toggleShowLoading');
      },
      setImportType: function setImportType(type) {
        this.set('importType', this.get('importTypes').find(function (importType) {
          return importType.name === type;
        }));
      },
      importFileAdded: function importFileAdded(file) {
        this.set('importFile', file);
      },
      analyzeBatch: function analyzeBatch() {
        var _this2 = this;

        this.set('reviewing', true);
        var importData = new FormData();
        importData.append("import_file", this.get('importFile'));
        importData.append("cash_account_id", this.get('cashAccountId'));
        importData.append("income_account_id", this.get('incomeAccountId'));
        importData.append("expense_account_id", this.get('expenseAccountId')); // let channel = this.get('session.pusher').subscribe(`import-channel-${this.get('session.data.authenticated.id')}`);
        // channel.bind('analyze', (response) => {
        //   this.sendAction('toggleShowLoading');
        //   if (this.isDestroyed) {
        //     return;
        //   } else {
        //     if (response.error) {
        //       this.get('notify').alert({ html: `<i class="icon-warning lg"></i> <div class="message-text"><span>${response.error}</span></div>` });
        //       this.set('reviewing', false);
        //     } else {
        //       this.store.findRecord('batch', response.batch_id).then((batch) => {
        //         this.set('batch', batch);
        //         this.get('router').transitionTo('companies.show.batches.show', batch.id);
        //       });
        //     }
        //   }
        // });

        var _this$get = this.get('session.data.authenticated'),
            email = _this$get.email,
            token = _this$get.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        var importTypeName = this.get('importType.name') === "Other Bank" ? "Banco Popular" : this.get('importType.name');
        this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/batches?import_type=").concat(importTypeName), {
          method: 'POST',
          contentType: false,
          processData: false,
          data: importData,
          headers: headers
        }).then(function (response) {// params.successCallback(response);
          // this.sendAction('toggleShowLoading');
        }).catch(function (error) {
          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
          });
        });
      },
      accountAnalysis: function accountAnalysis() {
        this.set('showLoading', true);
      },
      setIncomeAccount: function setIncomeAccount(id) {
        this.set('incomeAccountId', id);
      },
      setExpenseAccount: function setExpenseAccount(id) {
        this.set('expenseAccountId', id);
      },
      setCashAccount: function setCashAccount(id) {
        this.set('cashAccountId', id);
      },
      downloadTemplate: function downloadTemplate() {
        var csv = this.get('importType.headers');
        window.location.href = "data:text/csv;charset=UTF-8,".concat(encodeURIComponent(csv));
      },
      cancelReview: function cancelReview() {
        this.resetProperties(); // this.transitionToRoute('companies.show.batches');
      },
      cancel: function cancel() {
        this.resetProperties();
        this.sendAction("cancel");
      }
    }
  });

  _exports.default = _default;
});