define("account-berry-frontend/companies/show/accounts/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(param) {
      return this.store.peekRecord('account', param.account_id);
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        'breadCrumb': model.get('name'),
        'companyId': this.paramsFor('companies.show').company_id
      });
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.accounts.show', {
        into: 'application',
        model: model
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});