define("account-berry-frontend/companies/show/reports/payroll-detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.set('breadCrumb', 'Payroll Detail');
      controller.set('companyId', this.paramsFor('companies.show').company_id);
      controller.setProperties({
        payrollDetailData: null,
        date: null
      });
      this.store.findRecord('payroll-setting', 1).then(function (payrollSetting) {
        controller.set('payrollSettings', payrollSetting);
      });
      this.store.findAll('payroll').then(function (payrolls) {
        controller.set('payrolls', payrolls.sortBy('pay_date').reverse());
      });
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.reports.payroll-detail', {
        into: 'application',
        model: model
      });
    }
  });

  _exports.default = _default;
});