define("account-berry-frontend/components/create-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service('notify'),
    init: function init() {
      this._super();

      this.set('item', {
        on_hand: 0,
        ordered: 0,
        non_inventory: this.get('nonInventoryItem')
      });
    },
    actions: {
      createItem: function createItem() {
        var _this = this;

        // Create Item
        this.set('item.company_id', this.companyId);
        var newItem = this.store.createRecord('item', this.item);
        newItem.save().then(function (res) {
          _this.sendAction('setItem', res.id, true);

          _this.get('notify').success({
            html: "<i class=\"icon-checkmark lg\"></i> <div><strong>Success:</strong>&nbsp<span>Item Created</span></div>"
          });
        }, function (response) {
          newItem.rollbackAttributes();
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
        this.sendAction('toggleCreateItem');
      },
      close: function close() {
        this.set('item', {});
        this.sendAction('cancelCreateItem');
      }
    }
  });

  _exports.default = _default;
});