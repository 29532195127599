define("account-berry-frontend/components/add-tooltip/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run(function () {
        Ember.$("".concat(_this.get('el'))).tooltip({
          placement: _this.get('placement') || 'auto',
          trigger: "hover",
          html: true,
          title: "".concat(_this.get('title'))
        });

        if (_this.get('triggered')) {
          setTimeout(function () {
            Ember.$("".concat(_this.get('el'))).tooltip('show');
          }, 1000);
        }
      });
    }
  });

  _exports.default = _default;
});