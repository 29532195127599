define("account-berry-frontend/companies/show/employees/employees-table/rates/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/xPmOz93",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"employee-rate-form\",null,[[\"companyId\",\"employeeId\",\"newRate\",\"reloadRoute\",\"store\"],[[20,[\"companyId\"]],[20,[\"employeeId\"]],true,\"reloadRoute\",[20,[\"store\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/companies/show/employees/employees-table/rates/new/template.hbs"
    }
  });

  _exports.default = _default;
});