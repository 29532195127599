define("account-berry-frontend/components/select-representative/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    actions: {
      setRepresentative: function setRepresentative(value) {
        this.sendAction('setRepresentative', value);
      }
    }
  });

  _exports.default = _default;
});