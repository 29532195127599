define("account-berry-frontend/components/select-taxes/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    y: Ember.computed('orderedItem.taxes', 'change', function () {
      var _this = this;

      var taxable = false;

      if (this.get('orderedItem.taxes') && this.get('item.taxes')) {
        if (this.get('item.taxes').length !== 0 && this.get('item.taxes').length === this.get('orderedItem.taxes').length) {
          taxable = true;
          this.get('orderedItem.taxes').forEach(function (itemTax) {
            if (!_this.get('item.taxes').find(function (x) {
              return x.id.toString() === itemTax.id.toString();
            })) {
              taxable = false;
            }
          });
        } // Past custom logic


        if (!taxable) {
          if (this.get('orderedItem.taxes').length !== 0) {
            this.get('orderedItem.taxes').forEach(function (itemTax) {
              if (!_this.get('item.taxes').find(function (x) {
                return x.id.toString() === itemTax.id.toString();
              })) {
                taxable = true;
              }
            });
          }
        }
      } else if (this.get('item.taxes') && this.get('item.taxes').length > 0) {
        taxable = true;
        this.set('orderedItem.taxes', this.get('item.taxes'));
        this.setTaxes();
        this.send('setItemTaxes', this.get('orderedItem'), this.get('item.taxes'));
      }

      return taxable;
    }),
    n: Ember.computed('orderedItem.taxes', 'change', function () {
      var noTaxes = false;

      if (this.get('orderedItem.taxes')) {
        if (this.get('orderedItem.taxes').length === 0) {
          noTaxes = true;
        }
      } else if (this.get('item.taxes') && this.get('item.taxes').length === 0) {
        noTaxes = true;
      }

      return noTaxes;
    }),
    setTaxes: function setTaxes() {
      var _this2 = this;

      var taxes;

      if (this.get('allTaxes')) {
        if (this.get('orderedItem.taxes')) {
          taxes = this.get('allTaxes').map(function (tax) {
            Ember.set(tax, 'selected', !!_this2.get('orderedItem.taxes').find(function (x) {
              return x.id.toString() === tax.id.toString();
            })); // tax.selected = !!this.get('orderedItem.taxes').find(x => x.id.toString() === tax.id.toString());

            return tax;
          });
        } else {
          taxes = this.get('allTaxes').map(function (tax) {
            // tax.selected = false;
            Ember.set(tax, 'selected', false);
            return tax;
          });
        }

        this.set('taxes', taxes);
      }
    },
    setSelected: function setSelected() {
      var _this3 = this;

      var values = ['y', 'n', 'custom'];
      values.forEach(function (val) {
        if (_this3.get(val)) {
          var option = Ember.$("#".concat(val, "Option-").concat(_this3.get('index')));

          if (option[0]) {
            option[0].selected = true;
          }
        }
      });
    },
    actions: {
      updateTaxes: function updateTaxes(value) {
        switch (value) {
          case 'y':
            this.setTaxes();
            this.send('setItemTaxes', this.get('orderedItem'), this.get('item.taxes'));
            this.setSelected();
            break;

          case 'n':
            this.set('orderedItem.taxes', []);
            this.setTaxes();
            this.send('setItemTaxes', this.get('orderedItem'), []);
            this.setSelected();
            break;

          default:
        }
      },
      openItemTaxesModal: function openItemTaxesModal() {
        this.setTaxes();
        this.set('selectTaxes', true);
      },
      setItemTaxes: function setItemTaxes() {
        if (this.get('taxes')) {
          var itemTaxes = this.get('taxes').filter(function (tax) {
            return tax.selected;
          });
          this.set('selectTaxes', false);
          this.set('orderedItem.taxes', itemTaxes);
          this.setSelected();
          this.sendAction('setItemTaxes', this.get('orderedItem'), itemTaxes);
        }
      }
    }
  });

  _exports.default = _default;
});