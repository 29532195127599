define("account-berry-frontend/companies/show/checks/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uLng3dTr",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"check-form\",null,[[\"companyId\",\"store\",\"newCheck\",\"reloadRoute\"],[[20,[\"companyId\"]],[20,[\"store\"]],true,\"reloadRoute\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/companies/show/checks/new/template.hbs"
    }
  });

  _exports.default = _default;
});