define("account-berry-frontend/initializers/bread-crumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "ember-breadcrumbs",
    initialize: function initialize() {
      var application = arguments[1] || arguments[0];
      application.inject("component:bread-crumbs", "router", "router:main");
      application.inject("component:bread-crumbs", "applicationController", "controller:application");
    }
  };
  _exports.default = _default;
});