define("account-berry-frontend/components/sign-up/component", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['width-100'],
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    notify: Ember.inject.service('notify'),
    captchaVerified: false,
    secondStep: false,
    init: function init() {
      this._super();

      if (_environment.default.environment === "staging") {
        this.set('disabled', true);
        this.get('notify').alert({
          html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\">Oops! Are you lost? Please visit <a href=\"https://app.accountberry.com\">https://app.accountberry.com</a> to signup.</div>",
          closeAfter: null
        });
      }
    },
    actions: {
      verifyCoupon: function verifyCoupon() {
        var couponCode = this.get('coupon');

        switch (couponCode.toLowerCase()) {
          case "atc330":
            this.set('couponName', "30% OFF on first 3 Months after trial ends!");
            break;

          case "berry3months":
            this.set('couponName', "3 Months free!");
            break;

          case "":
            this.set('couponName', "");
            break;

          default:
            this.set('couponName', "Invalid Coupon Code");
        }
      },
      setReferenceFrom: function setReferenceFrom(value) {
        this.set('referenceFrom', value);
      },
      verifyCaptcha: function verifyCaptcha() {
        this.set('captchaVerified', true);
      },
      toggleSecondStep: function toggleSecondStep() {
        this.toggleProperty('secondStep');
      },
      submit: function submit() {
        var _this = this;

        if (!this.get('captchaVerified') && _environment.default.environment !== "development") {
          event.preventDefault();
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>Please Check the reCaptcha</span></div>"
          });
        } else {
          var user = {
            first_name: this.get('first_name'),
            last_name: this.get('last_name'),
            email: this.get('email'),
            password: this.get('password'),
            password_confirmation: this.get('passwordConfirmation'),
            // reference_from: this.get('referenceFrom'),
            coupon_code: this.get('coupon')
          };
          var emailConfirmed = this.get('email') === this.get('emailConfirmation');
          var passwordConfirmed = this.get('password') === this.get('passwordConfirmation');

          if (!emailConfirmed) {
            this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>Emails do not match</span></div>"
            });
          }

          if (!passwordConfirmed) {
            this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>Passwords do not match</span></div>"
            });
          }

          if (emailConfirmed && passwordConfirmed) {
            this.get('ajax').request("".concat(_environment.default.apiURL, "/users"), {
              method: 'POST',
              contentType: 'application/json',
              data: JSON.stringify({
                user: user
              })
            }).then(function () {
              _this.get('notify').success({
                html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>A Confirmation Email has been Sent.</span></div>'
              });

              _this.setProperties({
                'first_name': "",
                'last_name': "",
                'email': "",
                'emailConfirmation': "",
                'password': "",
                'passwordConfirmation': ""
              });

              _this.sendAction('toggleLogin');
            }).catch(function (response) {
              if (response.payload.errors && response.status !== "500") {
                if (response.payload.errors.email) {
                  _this.get('notify').alert({
                    html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>This email is already registered.</span></div>"
                  });
                } else {
                  var errors = response.errors.map(function (error) {
                    return error;
                  });

                  _this.get('notify').alert({
                    html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
                  });
                }
              }
            });
          }
        }
      }
    }
  });

  _exports.default = _default;
});