define("account-berry-frontend/payroll-setting/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    company_id: _emberData.default.attr(),
    calc_ss: _emberData.default.attr(),
    calc_medicare: _emberData.default.attr(),
    exclude_unemployment: _emberData.default.attr(),
    disability_percentage: _emberData.default.attr(),
    disability_ceiling: _emberData.default.attr(),
    unemployment_percentage: _emberData.default.attr(),
    unemployment_ceiling: _emberData.default.attr(),
    payroll_period: _emberData.default.attr(),
    other_deduction_one: _emberData.default.attr(),
    other_deduction_two: _emberData.default.attr(),
    other_deduction_three: _emberData.default.attr(),
    other_deduction_four: _emberData.default.attr(),
    other_deduction_five: _emberData.default.attr(),
    other_non_taxable_earnings_one: _emberData.default.attr(),
    other_non_taxable_earnings_two: _emberData.default.attr(),
    other_non_taxable_earnings_three: _emberData.default.attr(),
    other_non_taxable_earnings_four: _emberData.default.attr(),
    other_non_taxable_earnings_five: _emberData.default.attr(),
    other_taxable_earnings_one: _emberData.default.attr(),
    other_taxable_earnings_two: _emberData.default.attr(),
    other_taxable_earnings_three: _emberData.default.attr(),
    other_taxable_earnings_four: _emberData.default.attr(),
    other_taxable_earnings_five: _emberData.default.attr(),
    other_income_tax_account_id: _emberData.default.attr(),
    other_deduction_one_account_id: _emberData.default.attr(),
    other_deduction_two_account_id: _emberData.default.attr(),
    other_deduction_three_account_id: _emberData.default.attr(),
    other_deduction_four_account_id: _emberData.default.attr(),
    other_deduction_five_account_id: _emberData.default.attr(),
    garnishment_account_id: _emberData.default.attr(),
    union_dues_account_id: _emberData.default.attr(),
    life_insurance_account_id: _emberData.default.attr(),
    profit_sharing_account_id: _emberData.default.attr(),
    coda_plan_account_id: _emberData.default.attr(),
    cola_plan_account_id: _emberData.default.attr(),
    medical_plan_account_id: _emberData.default.attr(),
    retirement_plan_account_id: _emberData.default.attr(),
    payroll_expense_account_id: _emberData.default.attr(),
    other_non_taxable_earnings_one_account_id: _emberData.default.attr(),
    other_non_taxable_earnings_two_account_id: _emberData.default.attr(),
    other_non_taxable_earnings_three_account_id: _emberData.default.attr(),
    other_non_taxable_earnings_four_account_id: _emberData.default.attr(),
    other_non_taxable_earnings_five_account_id: _emberData.default.attr(),
    other_taxable_earnings_one_account_id: _emberData.default.attr(),
    other_taxable_earnings_two_account_id: _emberData.default.attr(),
    other_taxable_earnings_three_account_id: _emberData.default.attr(),
    other_taxable_earnings_four_account_id: _emberData.default.attr(),
    other_taxable_earnings_five_account_id: _emberData.default.attr(),
    vacation_accrual_hours: _emberData.default.attr(),
    vacation_hours_worked: _emberData.default.attr(),
    vacation_accrual_type: _emberData.default.attr(),
    vacation_accrual_expiration: _emberData.default.attr(),
    vacation_expiration_date: _emberData.default.attr(),
    sickness_accrual_hours: _emberData.default.attr(),
    sickness_hours_worked: _emberData.default.attr(),
    sickness_accrual_type: _emberData.default.attr(),
    sickness_accrual_expiration: _emberData.default.attr(),
    sickness_expiration_date: _emberData.default.attr()
  });

  _exports.default = _default;
});