define("account-berry-frontend/components/new-company-step-one/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    actions: {
      setFirstMonthAccountingYear: function setFirstMonthAccountingYear(val) {
        this.set('company.first_month_accounting_year', val);
      },
      setCompanyImage: function setCompanyImage(file) {
        var _this = this;

        var reader = new FileReader();

        reader.onload = function (e) {
          _this.set('imagePreview', e.target.result);
        };

        reader.readAsDataURL(file);
        this.set('company.picture', file); // this.sendAction('setCompanyImage', file);
      },
      updateCompanyData: function updateCompanyData() {
        this.sendAction('updateCompanyData', this.get('company'), 'stepOne', 'stepTwo');
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      }
    }
  });

  _exports.default = _default;
});