define("account-berry-frontend/components/nav-bar/component", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    toggled: Ember.computed(function () {
      return localStorage.toggled || '';
    }),
    session: Ember.inject.service('session'),
    confirmDelete: false,
    openTwitterFeed: false,
    didInsertElement: function didInsertElement() {
      Ember.$('#twitterFeed').hide();
      Ember.$(function () {
        Ember.$('[data-toggle="tooltip"]').tooltip({
          html: true
        });
      });
    },
    companyIdObserver: Ember.observer('companyId', function () {
      var _this = this;

      if (this.get('companyId') && !isNaN(this.get('companyId'))) {
        var company = this.store.peekRecord('company', this.get('companyId'));

        if (!company) {
          this.store.findRecord('company', this.get('companyId')).then(function (company) {
            _this.set('company', company);
          });
        } else {
          this.set('company', company);
        }
      }
    }),
    logoImg: Ember.computed('company', 'subscriptionPlans', 'inCompany', 'activeLogo', 'companyId', function () {
      var isActive = this.get('activeLogo');
      var rootURL = _environment.default.rootURL; // let imgUrl = isActive ? 'assets/images/menu-logo-active.png' : 'assets/images/menu-logo.png';

      var imgUrl = 'assets/images/logos/berry.svg';
      var plan;

      if (this.get('company') && this.get('inCompany')) {
        switch (this.get('company.stripe_plan_id')) {
          case _environment.default.stripe_plan_id.gold_plan:
            imgUrl = 'assets/images/logos/gold_plan.svg';
            plan = "Gold Plan";
            break;

          case _environment.default.stripe_plan_id.silver_plan:
            imgUrl = 'assets/images/logos/silver_plan.svg';
            plan = "Silver Inventory Plan";
            break;

          case _environment.default.stripe_plan_id.bronze_plan:
            imgUrl = 'assets/images/logos/bronze_plan.svg';
            plan = "Bronze Plan";
            break;

          default:
            break;
        }
      }

      if (plan) {
        Ember.$('#logoImg').tooltip({
          placement: "bottom",
          trigger: "hover",
          html: true,
          title: "<span>".concat(this.get('company.name'), "</span><br><span>").concat(plan, "</span>")
        });
      }

      return rootURL + imgUrl;
    }),
    active: Ember.computed('currentPath', function () {
      var currentPath = this.currentPath;
      var company = !currentPath.includes('companies.show.dashboard') && !currentPath.includes('companies.show.customers') && !currentPath.includes('companies.show.vendors') && !currentPath.includes('companies.show.employees') && !currentPath.includes('companies.show.reports');
      return {
        central: currentPath === 'companies.index',
        dashboard: currentPath.includes('companies.show.dashboard'),
        company: currentPath.includes('companies.show') && company,
        customers: currentPath.includes('customers'),
        vendors: currentPath.includes('vendors'),
        employees: currentPath.includes('employees'),
        reports: currentPath.includes('reports'),
        settings: currentPath.includes('settings') && !currentPath.includes('payroll-settings')
      };
    }),
    actions: {
      toggleSideBar: function toggleSideBar() {
        if (this.get('toggled') === 'toggled') {
          this.set('toggled', '');
          localStorage.toggled = '';
        } else {
          this.set('toggled', 'toggled');
          localStorage.toggled = 'toggled';
        }
      },
      toggleConfirmLogout: function toggleConfirmLogout() {
        this.toggleProperty('confirmDelete');
      },
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },
      activateLogo: function activateLogo() {
        this.set('activeLogo', true); // let logoImg = this.get('logoImg').replace('.png', '-active.png');
        // this.set('logoImg', logoImg);
      },
      inactivateLogo: function inactivateLogo() {
        this.set('activeLogo', false); // let logoImg = this.get('logoImg').replace('-active.png', '.png');
        // this.set('logoImg', logoImg);
      },
      toggleTwitter: function toggleTwitter() {
        this.toggleProperty('openTwitterFeed');

        if (this.get('openTwitterFeed')) {
          Ember.$('#twitterFeed').show();
        } else {
          Ember.$('#twitterFeed').hide();
        }
      },
      revertLogo: function revertLogo() {
        Ember.$('#logoImg').tooltip('destroy');
        this.set('logoImg', 'assets/images/logos/berry.svg');
      }
    }
  });

  _exports.default = _default;
});