define("account-berry-frontend/components/vacation-sickness-adjustment/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['vacation-sickness-adjustment'],
    notify: Ember.inject.service('notify'),
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    // For triggering reset of dropdown components
    reset: false,
    init: function init() {
      this._super();

      this.set('vacationSicknessAdjustment', {});
    },
    actions: {
      setDate: function setDate(date) {
        this.set('vacationSicknessAdjustment.date', date.toISOString().slice(0, 10));
      },
      setEmployee: function setEmployee(employee) {
        this.set('vacationSicknessAdjustment.employee_id', employee.id);
      },
      create: function create() {
        var _this = this;

        this.set('vacationSicknessAdjustment.company_id', this.companyId);
        var newAdjustment = this.store.createRecord('vacation-sickness-adjustment', this.vacationSicknessAdjustment);
        newAdjustment.save().then(function () {
          _this.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Adjustment Created</span></div>'
          });

          _this.sendAction('addAdjustmentToList', newAdjustment);

          _this.set('vacationSicknessAdjustment', {});

          _this.toggleProperty('reset');
        }, function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          newAdjustment.rollbackAttributes();

          _this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      }
    }
  });

  _exports.default = _default;
});