define("account-berry-frontend/components/search-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j9Eqe2TP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"a\"],[10,\"class\",[26,[\"btn accountberry-btn search-filter \",[18,\"buttonClass\"]]]],[9,\"data-toggle\",\"collapse\"],[9,\"href\",\"#searchFilter\"],[9,\"aria-expanded\",\"false\"],[9,\"aria-controls\",\"searchFilter\"],[7],[0,\"\\n  \"],[1,[25,\"if\",[[20,[\"title\"]],[20,[\"title\"]],\"Search/Filter\"],null],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"collapse\"],[9,\"id\",\"searchFilter\"],[7],[0,\"\\n  \"],[6,\"hr\"],[7],[8],[0,\"\\n  \"],[11,1],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/search-filter/template.hbs"
    }
  });

  _exports.default = _default;
});