define("account-berry-frontend/components/theme-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xkqP2DIP",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[6,\"select\"],[9,\"id\",\"themePicker\"],[9,\"class\",\"btn\"],[10,\"onchange\",[25,\"action\",[[19,0,[]],\"setTheme\"],[[\"value\"],[\"target.value\"]]],null],[7],[0,\"\\n  \"],[6,\"option\"],[9,\"value\",\"blackberry\"],[10,\"selected\",[25,\"eq\",[\"blackberry\",[20,[\"session\",\"data\",\"authenticated\",\"theme\"]]],null],null],[7],[0,\"Blackberry\"],[8],[0,\"\\n  \"],[6,\"option\"],[9,\"value\",\"strawberry\"],[10,\"selected\",[25,\"eq\",[\"strawberry\",[20,[\"session\",\"data\",\"authenticated\",\"theme\"]]],null],null],[7],[0,\"Strawberry\"],[8],[0,\"\\n  \"],[6,\"option\"],[9,\"value\",\"grape\"],[10,\"selected\",[25,\"eq\",[\"grape\",[20,[\"session\",\"data\",\"authenticated\",\"theme\"]]],null],null],[7],[0,\"Grape\"],[8],[0,\"\\n  \"],[6,\"option\"],[9,\"value\",\"blueberry\"],[10,\"selected\",[25,\"eq\",[\"blueberry\",[20,[\"session\",\"data\",\"authenticated\",\"theme\"]]],null],null],[7],[0,\"Blueberry\"],[8],[0,\"\\n  \"],[6,\"option\"],[9,\"value\",\"cranberry\"],[10,\"selected\",[25,\"eq\",[\"cranberry\",[20,[\"session\",\"data\",\"authenticated\",\"theme\"]]],null],null],[7],[0,\"Cranberry\"],[8],[0,\"\\n  \"],[6,\"option\"],[9,\"value\",\"cherry\"],[10,\"selected\",[25,\"eq\",[\"cherry\",[20,[\"session\",\"data\",\"authenticated\",\"theme\"]]],null],null],[7],[0,\"Cherry\"],[8],[0,\"\\n  \"],[6,\"option\"],[9,\"value\",\"orange\"],[10,\"selected\",[25,\"eq\",[\"orange\",[20,[\"session\",\"data\",\"authenticated\",\"theme\"]]],null],null],[7],[0,\"Orange\"],[8],[0,\"\\n  \"],[6,\"option\"],[9,\"value\",\"kiwi\"],[10,\"selected\",[25,\"eq\",[\"kiwi\",[20,[\"session\",\"data\",\"authenticated\",\"theme\"]]],null],null],[7],[0,\"Kiwi\"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"i\"],[9,\"class\",\"clickable icon-brush\"],[7],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/theme-picker/template.hbs"
    }
  });

  _exports.default = _default;
});