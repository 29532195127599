define("account-berry-frontend/subscription-plan/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    amount: _emberData.default.attr('number'),
    stripe_plan_id: _emberData.default.attr('string'),
    payroll: _emberData.default.attr('boolean'),
    inventory: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});