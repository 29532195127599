define("account-berry-frontend/receiving-and-bill/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    receiving_date: _emberData.default.attr(),
    ref_number: _emberData.default.attr(),
    total_amount: _emberData.default.attr('number'),
    memo: _emberData.default.attr(),
    vendor_id: _emberData.default.attr(),
    company_id: _emberData.default.attr(),
    freight_amount: _emberData.default.attr('number'),
    net_amount: _emberData.default.attr('number'),
    tax_amount: _emberData.default.attr('number'),
    vendor: _emberData.default.attr(),
    received_items: _emberData.default.attr(),
    terms: _emberData.default.attr(),
    uneditable: _emberData.default.attr(),
    due_date: _emberData.default.attr(),
    amount_due: _emberData.default.attr('number'),
    undeletable: _emberData.default.attr(),
    tax_account_id: _emberData.default.attr(),
    freight_account_id: _emberData.default.attr(),
    labels: _emberData.default.attr(),
    label_ids: _emberData.default.attr()
  });

  _exports.default = _default;
});