define("account-berry-frontend/components/representative-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service('notify'),
    confirmMerge: false,
    init: function init() {
      this._super();

      if (!this.get('representative')) {
        this.set('representative', {});
      }
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['customers'];
    }),
    actions: {
      create: function create() {
        var _this = this;

        this.set('representative.company_id', this.companyId);
        var newRepresentative = this.store.createRecord('representative', this.representative);
        newRepresentative.save().then(function () {
          _this.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Representative Created</span></div>'
          });

          _this.sendAction('reloadRoute');

          _this.get('router').transitionTo('companies.show.customers.representatives', _this.companyId);
        }, function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          newRepresentative.rollbackAttributes();

          _this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      update: function update() {
        var _this2 = this;

        var merge = this.get('confirmMerge');
        this.set('confirmMerge', false);
        this.set('representative.company_id', this.companyId);
        this.store.findRecord('representative', this.representative.id, {
          reload: true
        }).then(function (representative) {
          representative.setProperties(_this2.representative);
          representative.save().then(function () {
            if (merge) {
              _this2.store.unloadRecord(_this2.store.peekRecord('representative', representative.id));
            }

            _this2.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Representative Updated</span></div>'
            });

            _this2.sendAction('reloadRoute');

            _this2.get('router').transitionTo('companies.show.customers.representatives', _this2.companyId);
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this2.representative.rollbackAttributes();

            _this2.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          });
        });
      },
      mergeVerification: function mergeVerification() {
        var _this3 = this;

        var representatives = this.store.peekAll('representative').toArray();
        var merge;
        representatives.forEach(function (representative) {
          var equalName = representative.get('display_name').toLowerCase().replace(/\s+/g, '') === _this3.get('representative.display_name').toLowerCase().replace(/\s+/g, '');

          var sameRepresentative = _this3.get('representative.id').toString() !== representative.id.toString();

          if (equalName && sameRepresentative) {
            merge = true;
          }
        });

        if (merge) {
          this.set('confirmMerge', true);
        } else {
          this.send('update');
        }
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      deleteRepresentative: function deleteRepresentative() {
        var _this4 = this;

        this.representative.destroyRecord().then(function () {
          _this4.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Representative Deleted</span></div>"
          });

          _this4.sendAction('reloadRoute');

          _this4.get('router').transitionTo('companies.show.customers.representatives', _this4.companyId);
        });
      },
      toggleConfirmMerge: function toggleConfirmMerge() {
        this.toggleProperty('confirmMerge');
      },
      cancel: function cancel() {
        if (Ember.typeOf(this.get('representative')) === "instance") {
          this.get('representative').rollbackAttributes();
        }

        this.get('router').transitionTo('companies.show.customers.representatives', this.companyId);
      }
    }
  });

  _exports.default = _default;
});