define("account-berry-frontend/components/payment-items/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    paymentItems: Ember.computed('type', 'vendorId', 'customerId', 'payments', function () {
      if (this.get('payments')) {
        var type = this.get('type');
        var vendorId = this.get('vendorId');
        var customerId = this.get('customerId');

        if (type && vendorId) {
          return this.get('payments').filter(function (payment) {
            if (payment.get('type') === type && payment.get('vendor_id')) {
              return payment.get('vendor_id').toString() === vendorId.toString();
            }
          });
        } else if (type && customerId) {
          return this.get('payments').filter(function (payment) {
            if (payment.get('type') === type && payment.get('customer_id')) {
              return payment.get('customer_id').toString() === customerId.toString();
            }
          });
        }
      }
    })
  });

  _exports.default = _default;
});