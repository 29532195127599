define("account-berry-frontend/companies/show/customers/pvot/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    notify: Ember.inject.service(),
    pvotAccounts: Ember.computed('pvotConfig', 'accounts', function () {
      if (this.get('pvotConfig')) {
        return this.get('pvotConfig');
      } else {
        var accounts = this.get('accounts');

        if (accounts) {
          var cash_account_id = accounts.findBy('reference', 'Cash in Bank').id;
          var sales_account_id = accounts.findBy('reference', 'Sales').id;
          return {
            cash_account_id: cash_account_id,
            check_account_id: cash_account_id,
            visa_account_id: cash_account_id,
            mastercard_account_id: cash_account_id,
            amex_account_id: cash_account_id,
            discover_account_id: cash_account_id,
            gift_account_id: cash_account_id,
            other_account_id: cash_account_id,
            debit_account_id: cash_account_id,
            ebt_cash_account_id: cash_account_id,
            ebt_food_stamp_account_id: cash_account_id,
            total_returns_account_id: sales_account_id,
            total_sales_account_id: sales_account_id,
            cogs_account_id: accounts.findBy('reference', 'Purchases').id,
            inventory_account_id: accounts.findBy('reference', 'Inventory').id,
            gift_card_adj_account_id: accounts.findBy('reference', 'Accounts Payable').id
          };
        }
      }
    }),
    allFiles: Ember.computed('pvotFile1', 'pvotFile2', 'pvotFile3', function () {
      return this.get('pvotFile1') && this.get('pvotFile2') && this.get('pvotFile3');
    }),
    actions: {
      updateAccount: function updateAccount(value, attributeName) {
        this.set("pvotAccounts.".concat(attributeName), value);
      },
      importFile1Added: function importFile1Added(file) {
        this.set('pvotFile1', file);
      },
      importFile2Added: function importFile2Added(file) {
        this.set('pvotFile2', file);
      },
      importFile3Added: function importFile3Added(file) {
        this.set('pvotFile3', file);
      },
      goToJournalEntry: function goToJournalEntry() {
        this.transitionToRoute('companies.show.journal-entries.show', this.get('companyId'), this.get('journalEntryId')); // this.get('router').transitionTo('companies.new');
      },
      goBack: function goBack() {
        this.transitionToRoute('companies.show.customers', this.get('companyId'));
      },
      send: function send() {
        var _this = this;

        var importData = new FormData();
        importData.append("pvot_file1", this.get('pvotFile1'));
        importData.append("pvot_file2", this.get('pvotFile2'));
        importData.append("pvot_file3", this.get('pvotFile3'));
        importData.append("cash_account_id", this.get('pvotAccounts.cash_account_id'));
        importData.append("check_account_id", this.get('pvotAccounts.check_account_id'));
        importData.append("visa_account_id", this.get('pvotAccounts.visa_account_id'));
        importData.append("mastercard_account_id", this.get('pvotAccounts.mastercard_account_id'));
        importData.append("amex_account_id", this.get('pvotAccounts.amex_account_id'));
        importData.append("discover_account_id", this.get('pvotAccounts.discover_account_id'));
        importData.append("gift_account_id", this.get('pvotAccounts.gift_account_id'));
        importData.append("other_account_id", this.get('pvotAccounts.other_account_id'));
        importData.append("debit_account_id", this.get('pvotAccounts.debit_account_id'));
        importData.append("ebt_cash_account_id", this.get('pvotAccounts.ebt_cash_account_id'));
        importData.append("ebt_food_stamp_account_id", this.get('pvotAccounts.ebt_food_stamp_account_id'));
        importData.append("total_returns_account_id", this.get('pvotAccounts.total_returns_account_id'));
        importData.append("total_sales_account_id", this.get('pvotAccounts.total_sales_account_id'));
        importData.append("tax_collected_account_id", this.get('pvotAccounts.tax_collected_account_id'));
        importData.append("cogs_account_id", this.get('pvotAccounts.cogs_account_id'));
        importData.append("inventory_account_id", this.get('pvotAccounts.inventory_account_id'));
        importData.append("gift_card_adj_account_id", this.get('pvotAccounts.gift_card_adj_account_id'));
        var channel = this.get('session.pusher').subscribe("pvot-channel-".concat(this.get('companyId')));

        if (!this.get('channelOpened')) {
          channel.bind('created', function (response) {
            if (_this.isDestroyed) {
              return;
            } else {
              if (response.error) {
                _this.get('notify').alert({
                  html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(response.error, "</span></div>")
                });
              } else {
                _this.get('notify').success({
                  html: "<i class=\"icon-checkmark lg\"></i> <div class=\"message-text\"><span>PVOT Import Journal Entry Created</span></div>"
                });

                _this.set('journalEntryId', response.journal_entry_id);

                _this.set('showRedirectModal', true); // Show modal to redirect to created journal entry

              }
            }

            _this.set('channelOpened', true);
          });
        }

        var _this$get = this.get('session.data.authenticated'),
            email = _this$get.email,
            token = _this$get.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/pvot_imports"), {
          method: 'POST',
          contentType: false,
          processData: false,
          data: importData,
          headers: headers
        }).then(function (response) {// params.successCallback(response);
          // this.sendAction('toggleShowLoading');
        }).catch(function (error) {
          _this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(error ? error.payload.errors : 'An error occured', "</span></div>")
          });

          _this.setProperties({
            'pvotFile1': null,
            'pvotFile2': null,
            'pvotFile3': null
          });
        });
      }
    }
  });

  _exports.default = _default;
});