define("account-berry-frontend/components/file-uploader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4OE1x1Yh",
    "block": "{\"symbols\":[],\"statements\":[[6,\"label\"],[10,\"class\",[26,[\"btn accountberry-btn \",[18,\"classNames\"]]]],[9,\"for\",\"uploadFile\"],[7],[1,[25,\"if\",[[20,[\"uploadText\"]],[20,[\"uploadText\"]],\"Upload Image\"],null],false],[8],[0,\"\\n\"],[6,\"input\"],[9,\"id\",\"uploadFile\"],[9,\"type\",\"file\"],[9,\"accept\",\"accept\"],[9,\"multiple\",\"false\"],[7],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/file-uploader/template.hbs"
    }
  });

  _exports.default = _default;
});