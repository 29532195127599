define("account-berry-frontend/components/customer-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service('notify'),
    confirmMerge: false,
    init: function init() {
      this._super();

      if (!this.get('customer')) {
        this.set('customer', {});
      }
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['customers'];
    }),
    actions: {
      create: function create() {
        var _this2 = this;

        this.set('customer.company_id', this.companyId);
        var newCustomer = this.store.createRecord('customer', this.customer);
        newCustomer.save().then(function () {
          _this2.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Customer Created</span></div>'
          });

          _this2.sendAction('reloadRoute');

          _this2.get('router').transitionTo('companies.show.customers.customers-table', _this2.companyId);
        }, function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          newCustomer.rollbackAttributes();

          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      update: function update() {
        var merge = this.get('confirmMerge');
        this.set('confirmMerge', false);

        var _this = this;

        this.set('customer.company_id', this.companyId);
        this.store.findRecord('customer', this.customer.id, {
          reload: true
        }).then(function (customer) {
          customer.setProperties(_this.customer);
          customer.save().then(function () {
            if (merge) {
              _this.store.unloadRecord(_this.store.peekRecord('customer', customer.id));

              var payeeRecord = _this.store.peekRecord('payee', customer.id);

              if (payeeRecord) {
                _this.store.unloadRecord(payeeRecord);
              }
            }

            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Customer Updated</span></div>'
            });

            _this.sendAction('reloadRoute');

            _this.get('router').transitionTo('companies.show.customers.customers-table', _this.companyId);
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            customer.rollbackAttributes();

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          });
        });
      },
      mergeVerification: function mergeVerification() {
        var _this3 = this;

        var customers = this.store.peekAll('customer').toArray();
        var merge;
        customers.forEach(function (customer) {
          var equalName = customer.get('display_name').toLowerCase().replace(/\s+/g, '') === _this3.get('customer.display_name').toLowerCase().replace(/\s+/g, '');

          var sameCustomer = _this3.get('customer.id').toString() !== customer.id.toString();

          if (equalName && sameCustomer) {
            merge = true;
          }
        });

        if (merge) {
          this.set('confirmMerge', true);
        } else {
          this.send('update');
        }
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      deleteCustomer: function deleteCustomer() {
        var _this4 = this;

        this.customer.destroyRecord().then(function () {
          _this4.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Customer Deleted</span></div>"
          });

          _this4.sendAction('reloadRoute');

          _this4.get('router').transitionTo('companies.show.customers.customers-table', _this4.companyId);
        });
      },
      toggleConfirmMerge: function toggleConfirmMerge() {
        this.toggleProperty('confirmMerge');
      },
      cancel: function cancel() {
        if (Ember.typeOf(this.get('customer')) === "instance") {
          this.get('customer').rollbackAttributes();
        }

        this.get('router').transitionTo('companies.show.customers.customers-table', this.companyId);
      }
    }
  });

  _exports.default = _default;
});