define("account-berry-frontend/components/loading-spinner/component", ["exports", "account-berry-frontend/config/environment", "account-berry-frontend/utils/loading-messages"], function (_exports, _environment, _loadingMessages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // classNames: ['loader-container'],
    classNameBindings: ['showLoading:loader-container:standby-loader-container'],
    showLoading: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      setInterval(function () {
        if (!_this.isDestroyed) {
          _this.set('showLoading', true);

          _this.loadingMessageChanger();
        }
      }, 500);
    },
    loadingMessages: Ember.computed(function () {
      return (0, _loadingMessages.default)();
    }),
    // Do not remember why I do this.
    loadingMessageChanger: function loadingMessageChanger() {
      var _this2 = this;

      Ember.run(function () {
        // let loadingMessages = this.get('loadingMessages');
        setTimeout(function () {
          if (!_this2.isDestroyed) {
            _this2.set('loadingMessage', "We are processing your data, please wait...");
          }
        }, 3000);
        var interval = setInterval(function () {
          if (!_this2.isDestroyed) {
            _this2.set('loadingMessage', "We are processing your data, please wait...");
          }
        }, 7000);

        if (!_this2.isDestroyed) {
          _this2.set('interval', interval);
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      clearInterval(this.get('interval'));
    }
  });

  _exports.default = _default;
});