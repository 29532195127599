define("account-berry-frontend/sub-account/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'id',
    attrs: {
      title_account: {
        serialize: false,
        deserialize: 'records'
      },
      account_type: {
        serialize: false,
        deserialize: 'records'
      }
    },
    payloadKeyFromModelName: function payloadKeyFromModelName() {
      return "account";
    }
  });

  _exports.default = _default;
});