define("account-berry-frontend/components/journal-entry-import-analysis/component", ["exports", "ember-cli-pagination/computed/paged-array"], function (_exports, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    session: Ember.inject.service('session'),
    notify: Ember.inject.service('notify'),
    pagedAnalyzedImportData: (0, _pagedArray.default)('analyzedImportData', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    analyzedImportDataObserver: Ember.observer('analyzedImportData', function () {
      this.set('pagedAnalyzedImportData', (0, _pagedArray.default)('analyzedImportData', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    init: function init() {
      this._super();

      this.set('channel', this.get('session.pusher').subscribe("import-channel-".concat(this.get('session.data.authenticated.id'))));
    },
    didInsertElement: function didInsertElement() {
      Ember.$('.select-account').select2();
    },
    sendBatch: function sendBatch(remember) {
      var _this = this;

      var successMessage = remember ? "Batch Remembered for future analysis" : "Batch is being imported";
      this.store.findRecord('batch', this.batch.id, {
        backgroundReload: false
      }).then(function (batch) {
        batch.setProperties(_this.batch);
        batch.save().then(function () {
          _this.sendAction('toggleShowLoading');

          _this.get('notify').success({
            html: "<i class=\"icon-checkmark lg\"></i> <div><strong>Success:</strong>&nbsp<span>".concat(successMessage, "</span></div>")
          });

          if (remember) {
            // this.sendAction('toggleShowLoading');
            _this.sendAction('reloadRoute');

            _this.get('router').transitionTo('companies.show.batches', _this.companyId);
          }
        }, function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this.sendAction('toggleShowLoading', true, false);

          _this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          }); // this.sendAction('cancel');

        });
      });
    },
    accountsReceivablePayable: function accountsReceivablePayable(account, journalEntry) {
      Ember.set(journalEntry, 'accounts_receivable', account.get('reference') === "Accounts Receivable");
      Ember.set(journalEntry, 'accounts_payable', account.get('reference') === "Accounts Payable");
      Ember.set(journalEntry, 'vendor_id', null);
      Ember.set(journalEntry, 'vendor', null);
      Ember.set(journalEntry, 'customer_id', null);
      Ember.set(journalEntry, 'customer', null);
    },
    actions: {
      toggleImportAlert: function toggleImportAlert() {
        this.toggleProperty('importAlert');
      },
      updateCustomer: function updateCustomer(payee, type, journalEntry) {
        // type param is only included because select-payee component returns it.
        Ember.set(journalEntry, 'customer', payee.get('name'));
        Ember.set(journalEntry, 'customer_id', payee.get('id'));
      },
      updateVendor: function updateVendor(payee, type, journalEntry) {
        // type param is only included because select-payee component returns it.
        Ember.set(journalEntry, 'vendor', payee.get('name'));
        Ember.set(journalEntry, 'vendor_id', payee.get('id'));
      },
      updateAccount: function updateAccount(journalEntry, index) {
        var accountId = Ember.$("#selectJournalEntryAccount-".concat(index))[0].value;
        var account = this.store.peekRecord('account', accountId);
        Ember.set(journalEntry, 'account_id', accountId);

        if (account) {
          this.accountsReceivablePayable(account, journalEntry);
        }

        Ember.set(journalEntry, 'account_id', accountId);
      },
      updateDebitAmount: function updateDebitAmount(journalEntry) {
        var journalEntryAmount = 0;
        var newTransactionJournalEntry;
        this.get('pagedAnalyzedImportData.all.content').filter(function (journalEntryData) {
          return journalEntryData.import_id === journalEntry.import_id;
        }).forEach(function (journalEntryData) {
          if (journalEntryData.new_transaction) {
            newTransactionJournalEntry = journalEntryData;
          } else {
            journalEntryAmount += parseFloat(Number(journalEntryData.debit));
            Ember.set(journalEntry, 'credit', null);
          }
        });
        Ember.set(newTransactionJournalEntry, 'debit', journalEntryAmount.toFixed(2));
      },
      updateCreditAmount: function updateCreditAmount(journalEntry) {
        var journalEntryAmount = 0;
        var newTransactionJournalEntry;
        this.get('pagedAnalyzedImportData.all.content').filter(function (journalEntryData) {
          return journalEntryData.import_id === journalEntry.import_id;
        }).forEach(function (journalEntryData) {
          if (journalEntryData.new_transaction) {
            newTransactionJournalEntry = journalEntryData;
          } else {
            journalEntryAmount += parseFloat(Number(journalEntryData.credit));
            Ember.set(journalEntry, 'debit', null);
          }
        });
        Ember.set(newTransactionJournalEntry, 'credit', journalEntryAmount.toFixed(2));
      },
      import: function _import() {
        var _this2 = this;

        this.set('batch.import_data', {
          data: this.get('pagedAnalyzedImportData.all.content'),
          import: true
        });
        this.toggleProperty('importAlert');
        this.get('channel').bind('import', function (response) {
          _this2.sendAction('toggleShowLoading', true, false);

          if (response.error) {
            var errors;

            if (response.error) {
              errors = response.error.map(function (error) {
                return error;
              });
            }

            _this2.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(errors, "</span></div>")
            });
          } else {
            _this2.sendAction('reloadRoute');

            _this2.get('notify').success({
              html: "<i class=\"icon-checkmark lg\"></i> <div><strong>Success:</strong>&nbsp<span>Batch Imported Successfully.</span></div>"
            });

            _this2.get('router').transitionTo('companies.show.batches', _this2.companyId);
          }
        }); // this.sendAction('toggleShowLoading');

        this.sendBatch(false);
      },
      remember: function remember() {
        this.set('batch.import_data', {
          data: this.get('pagedAnalyzedImportData.all.content')
        });
        this.sendAction('toggleShowLoading');
        this.sendBatch(true);
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      deleteBatch: function deleteBatch() {
        var _this3 = this;

        this.batch.destroyRecord().then(function () {
          _this3.sendAction('toggleShowLoading');

          _this3.set('confirmDelete', false);

          _this3.get('channel').bind('destroy', function (response) {
            _this3.sendAction('toggleShowLoading', true, false);

            if (response.error) {
              _this3.store.unloadAll('batch');

              _this3.sendAction('reloadRoute');

              _this3.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(response.error, "</span></div>")
              });
            } else {
              _this3.sendAction('reloadRoute');

              _this3.get('notify').alert({
                html: "<i class=\"icon-checkmark lg\"></i> <div><strong>Success:</strong>&nbsp<span>Batch Deleted.</span></div>"
              });

              _this3.get('router').transitionTo('companies.show.batches', _this3.companyId);
            }
          });
        }).catch(function (response) {
          var errors = "An Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          }

          _this3.batch.rollbackAttributes();

          _this3.set('confirmDelete', false);

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      loadNext: function loadNext() {
        if (this.get('pagedAnalyzedImportData.length') < this.get('analyzedImportData.length')) {
          this.get('pagedAnalyzedImportData').loadNextPage();
        }
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      },
      editable: function editable(journalEntry) {
        Ember.set(journalEntry, 'editable', true);
      }
    }
  });

  _exports.default = _default;
});