define("account-berry-frontend/password-reset/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NuTvynQg",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"col-md-12\"],[7],[0,\"\\n  \"],[6,\"h5\"],[7],[0,\"Password Reset\"],[8],[0,\"\\n  \"],[6,\"form\"],[9,\"class\",\"flex-column\"],[3,\"action\",[[19,0,[]],\"passwordReset\"],[[\"on\"],[\"submit\"]]],[7],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"class\",\"type\",\"value\",\"placeholder\",\"required\"],[\"form-control\",\"password\",[20,[\"password\"]],\"Enter new password\",true]]],false],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"class\",\"type\",\"value\",\"placeholder\",\"required\"],[\"form-control\",\"password\",[20,[\"passwordConfirmation\"]],\"Confirm new password\",true]]],false],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"btn accountberry-btn\"],[9,\"type\",\"submit\"],[7],[0,\"Submit\"],[8],[0,\"\\n    \"],[1,[18,\"errorMessage\"],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/password-reset/template.hbs"
    }
  });

  _exports.default = _default;
});