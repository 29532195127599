define("account-berry-frontend/components/dashboard-balance-sheet/component", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    ajaxCall: Ember.inject.service(),
    balanceSheet: Ember.computed('balanceSheetData', function () {
      if (this.get('balanceSheetData')) {
        return {
          labels: ["Assets: $".concat(parseFloat(this.get('balanceSheetData.total_assets')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')), "Liabilities: $".concat(parseFloat(this.get('balanceSheetData.total_liabilities')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')), "Equity: $".concat(parseFloat(this.get('balanceSheetData.total_equity')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')) // `Other Income: $${parseFloat(this.get('balanceSheetData.total_other_income')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`,
          // `Other Expenses: $${parseFloat(this.get('balanceSheetData.total_other_expenses')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`
          ],
          datasets: [{
            data: [parseFloat(this.get('balanceSheetData.total_assets')), parseFloat(this.get('balanceSheetData.total_liabilities')), parseFloat(this.get('balanceSheetData.total_equity'))],
            backgroundColor: ["rgba(136, 70, 218, 1)", "rgba(93, 59, 203, 1)", "rgba(35, 187, 27, 1)"]
          }]
        };
      }
    }),
    balanceSheetOptions: Ember.computed('balanceSheet', function () {
      if (this.get('balanceSheet')) {
        return {
          legend: {
            display: false,
            labels: {
              fontSize: 16
            }
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                callback: function callback(label, index, labels) {
                  return "$".concat(label.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'));
                }
              },
              gridLines: {
                display: false
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }]
          }
        };
      }
    })
  });

  _exports.default = _default;
});