define("account-berry-frontend/settings/subscription-invoices/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VftQc/9B",
    "block": "{\"symbols\":[\"invoice\"],\"statements\":[[6,\"thead\"],[7],[0,\"\\n  \"],[6,\"tr\"],[9,\"class\",\"header-row\"],[7],[0,\"\\n    \"],[6,\"th\"],[7],[0,\"INVOICE DATE\"],[8],[0,\"\\n    \"],[6,\"th\"],[7],[8],[0,\"\\n    \"],[6,\"th\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"table-container\"],[7],[0,\"\\n    \"],[6,\"table\"],[9,\"class\",\"content-table table \"],[7],[0,\"\\n      \"],[6,\"tbody\"],[10,\"class\",[20,[\"session\",\"data\",\"authenticated\",\"theme\"]],null],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"subscriptionInvoices\"]]],null,{\"statements\":[[0,\"          \"],[6,\"tr\"],[7],[0,\"\\n            \"],[6,\"td\"],[7],[0,\"\\n              \"],[1,[19,1,[\"period_start\"]],false],[0,\"\\n            \"],[8],[0,\"\\n            \"],[6,\"td\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n              \"],[6,\"a\"],[10,\"href\",[19,1,[\"url\"]],null],[9,\"target\",\"_blank\"],[7],[0,\"\\n                Click to View\\n              \"],[8],[0,\"\\n            \"],[8],[0,\"\\n            \"],[6,\"td\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n              \"],[6,\"a\"],[10,\"href\",[25,\"concat\",[[19,1,[\"url\"]],\"/pdf\"],null],null],[9,\"target\",\"_blank\"],[7],[0,\"\\n                Download PDF\\n              \"],[8],[0,\"\\n            \"],[8],[0,\"\\n          \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/settings/subscription-invoices/template.hbs"
    }
  });

  _exports.default = _default;
});