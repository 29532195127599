define("account-berry-frontend/components/vendor-form/component", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service('notify'),
    session: Ember.inject.service(),
    confirmMerge: false,
    init: function init() {
      var _this2 = this;

      this._super();

      $(function () {
        $('[data-toggle="popover"]').popover({
          html: true
        });
      });

      if (!this.get('vendor')) {
        this.set('vendor', {
          retention_after_amount: 500,
          retention_percentage: 10
        });
      }

      var companyId = this.companyId;
      this.store.findAll('account').then(function (accounts) {
        var filteredAccounts = accounts.toArray().filter(function (account) {
          return account.get('company_id').toString() === companyId.toString();
        });

        _this2.set('accounts', filteredAccounts);
      });
    },
    company: Ember.computed('companyId', function () {
      var company = this.store.peekRecord('company', this.companyId);
      this.set('informativePlan', company.get('stripe_plan_id') === _environment.default.stripe_plan_id.gold_plan);
      return company;
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['vendors'];
    }),
    anyAccountSubjectToWithholding: Ember.computed('accounts', function () {
      if (this.get('accounts')) {
        var accountsSubjectToWithholding = this.get('accounts').filter(function (account) {
          return account.get('subject_to_vendor_withholding');
        });
        return accountsSubjectToWithholding.length > 0;
      }
    }),
    preferredAccount: Ember.computed('accounts', 'vendor.account_id', function () {
      if (this.get('vendor.account_id') && this.get('accounts')) {
        var preferredAccount = this.store.peekRecord('account', this.get('vendor.account_id')).data;
        preferredAccount['account_id'] = this.get('vendor.account_id');
        return preferredAccount;
      }
    }),
    retentionAccount: Ember.computed('accounts', 'vendor.retention_account_id', function () {
      if (this.get('accounts')) {
        if (!this.get('vendor.retention_account_id')) {
          var vendorWithholdingAccount = this.get('accounts').find(function (account) {
            return account.get('reference') === "Vendor Withholding";
          });
          this.set('vendor.retention_account_id', vendorWithholdingAccount ? vendorWithholdingAccount.id : null);
        }

        if (this.get('vendor.retention_account_id')) {
          var retentionAccount = this.store.peekRecord('account', this.get('vendor.retention_account_id')).data;
          retentionAccount['account_id'] = this.get('vendor.retention_account_id');
          return retentionAccount;
        }
      }
    }),
    currentLiabilityAccounts: Ember.computed('accounts', function () {
      if (this.get('accounts')) {
        return this.get('accounts').filter(function (account) {
          return account.get('account_type.name') === "Current Liability";
        });
      }
    }),
    actions: {
      create: function create() {
        var _this3 = this;

        this.set('vendor.company_id', this.companyId);
        var newVendor = this.store.createRecord('vendor', this.vendor);
        newVendor.save().then(function () {
          _this3.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Vendor Created</span></div>'
          });

          _this3.sendAction('reloadRoute');

          _this3.get('router').transitionTo('companies.show.vendors.vendors-table', _this3.companyId);
        }, function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          newVendor.rollbackAttributes();

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      update: function update() {
        var merge = this.get('confirmMerge');
        this.set('confirmMerge', false);

        var _this = this;

        this.set('vendor.company_id', this.companyId);
        this.store.findRecord('vendor', this.vendor.id, {
          reload: true
        }).then(function (vendor) {
          vendor.setProperties(_this.vendor);
          vendor.save().then(function () {
            if (merge) {
              _this.store.unloadRecord(_this.store.peekRecord('vendor', vendor.id));

              var payeeRecord = _this.store.peekRecord('payee', vendor.id);

              if (payeeRecord) {
                _this.store.unloadRecord(payeeRecord);
              }
            }

            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Vendor Updated</span></div>'
            });

            _this.sendAction('reloadRoute');

            _this.get('router').transitionTo('companies.show.vendors.vendors-table', _this.companyId);
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            vendor.rollbackAttributes();

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          });
        });
      },
      mergeVerification: function mergeVerification() {
        var _this4 = this;

        var vendors = this.store.peekAll('vendor').toArray();
        var merge;
        vendors.forEach(function (vendor) {
          if (vendor.get('display_name').toLowerCase().replace(/\s+/g, '') === _this4.get('vendor.display_name').toLowerCase().replace(/\s+/g, '') && _this4.get('vendor.id').toString() !== vendor.id.toString()) {
            merge = true;
          }
        });

        if (merge) {
          this.set('confirmMerge', true);
        } else {
          this.send('update');
        }
      },
      toggleConfirmMerge: function toggleConfirmMerge() {
        this.toggleProperty('confirmMerge');
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      setPreferredAccount: function setPreferredAccount(id) {
        this.set('vendor.account_id', id);
      },
      setRetentionAccount: function setRetentionAccount(id) {
        this.set('vendor.retention_account_id', id);
      },
      updateRetentionAfterAmount: function updateRetentionAfterAmount(amount) {
        this.set('vendor.retention_after_amount', parseFloat(amount));
      },
      updateRetentionPercentage: function updateRetentionPercentage(amount) {
        this.set('vendor.retention_percentage', parseFloat(amount));
      },
      deleteVendor: function deleteVendor() {
        var _this5 = this;

        this.vendor.destroyRecord().then(function () {
          _this5.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Vendor Deleted</span></div>"
          });

          _this5.sendAction('reloadRoute');

          _this5.get('router').transitionTo('companies.show.vendors.vendors-table', _this5.companyId);
        });
      },
      cancel: function cancel() {
        if (Ember.typeOf(this.get('vendor')) === "instance") {
          this.get('vendor').rollbackAttributes();
        }

        this.get('router').transitionTo('companies.show.vendors.vendors-table', this.companyId);
      }
    }
  });

  _exports.default = _default;
});