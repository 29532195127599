define("account-berry-frontend/components/dashboard-profit-loss/component", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    ajaxCall: Ember.inject.service(),
    netIncome: Ember.computed('profitLossData', function () {
      return this.get('profitLossData.net_income')[0];
    }),
    profitLoss: Ember.computed('profitLossData', function () {
      if (this.get('profitLossData')) {
        return {
          labels: ["Income: $".concat(parseFloat(this.get('profitLossData.total_income')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')), "Expenses: $".concat(parseFloat(this.get('profitLossData.total_expenses')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')), "Cost of Goods Sold: $".concat(parseFloat(this.get('profitLossData.total_cost_of_goods_sold')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')), "Other Income: $".concat(parseFloat(this.get('profitLossData.total_other_income')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')), "Other Expenses: $".concat(parseFloat(this.get('profitLossData.total_other_expenses')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'))],
          datasets: [{
            data: [parseFloat(this.get('profitLossData.total_income')), parseFloat(this.get('profitLossData.total_expenses')), parseFloat(this.get('profitLossData.total_cost_of_goods_sold')), parseFloat(this.get('profitLossData.total_other_income')), parseFloat(this.get('profitLossData.total_other_expenses'))],
            backgroundColor: ["rgba(136, 70, 218, 1)", "rgba(93, 59, 203, 1)", "rgba(35, 187, 27, 1)", "rgba(151, 220, 134, 1)", "rgba(51, 51, 51, 1)"]
          }]
        };
      }
    }),
    profitLossOptions: Ember.computed('profitLoss', function () {
      if (this.get('profitLoss')) {
        return {
          legend: {
            display: false,
            labels: {
              fontSize: 16
            }
          },
          scales: {
            xAxes: [{
              ticks: {
                display: false
              },
              gridLines: {
                display: false
              }
            }],
            yAxes: [{
              gridLines: {
                display: false
              }
            }]
          }
        };
      }
    })
  });

  _exports.default = _default;
});