define("account-berry-frontend/company/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      users: {
        serialize: false,
        deserialize: 'records'
      },
      items: {
        serialize: false,
        deserialize: 'records'
      },
      accounts: {
        serialize: false,
        deserialize: 'records'
      },
      user_roles: {
        serialize: false,
        deserialize: 'records'
      },
      checks: {
        serialize: false,
        deserialize: 'records'
      },
      customers_names: {
        serialize: false,
        deserialize: 'records'
      },
      vendors_names: {
        serialize: false,
        deserialize: 'records'
      },
      employees_names: {
        serialize: false,
        deserialize: 'records'
      },
      counts: {
        serialize: false,
        deserialize: 'records'
      },
      permissions: {
        serialize: false,
        deserialize: 'records'
      },
      invites: {
        serialize: false,
        deserialize: 'records'
      },
      clover_sync: {
        serialize: false,
        deserialize: 'records'
      },
      creator: {
        serialize: false,
        deserialize: 'records'
      }
    }
  });

  _exports.default = _default;
});