define("account-berry-frontend/companies/show/vendors/480sp/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    search: "",
    sortAscending: false,
    init: function init() {
      var _this2 = this;

      var channel = this.get('session.pusher').subscribe("sp4806-channel-".concat(this.get('session.data.authenticated.id')));
      this.set('channel', channel);
      channel.bind('electronicFiling', function (response) {
        _this2.get('applicationController').set('showLoading', true);

        _this2.store.findRecord('json-storage', response.json_storage_id).then(function (jsonStorage) {
          var element = document.createElement('a');
          var content = '';
          jsonStorage.get('stored_json.electronic_record_data').forEach(function (electronic_data) {
            content += electronic_data + '\n';
          });
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
          element.setAttribute('download', '4806sp_electronic_filing.txt');
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);

          _this2.get('applicationController').set('showLoading', false);

          _this2.set('electronicFileGenerated', false);
        }).catch(function (e) {
          _this2.get('applicationController').set('showLoading', false);

          if (_this2.get('electronicFileGenerated')) {
            _this2.set('electronicFileGenerated', false);

            _this2.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Oops! Something weird happened. Please try again.</span></div>"
            });
          }
        });
      });
      channel.bind('sp4806', function (response) {
        _this2.store.findRecord('json-storage', response.json_storage_id).then(function (jsonStorage) {
          _this2.set('sp4806Data', jsonStorage.get('stored_json.sp4806'));
        });
      });
    },
    filteredVendors: Ember.computed('sp4806Data', 'search', 'sortAscending', 'hideInvalidVendors', function () {
      var _this3 = this;

      var filteredVendors = [];

      for (var key in this.get('sp4806Data')) {
        if (key !== "electronicFillingNumber" && this.get("sp4806Data.".concat(key, ".general_info"))) {
          var vendorData = this.get("sp4806Data.".concat(key));
          var validVendor = this.get('hideInvalidVendors') ? this.validVendor(vendorData) : true;

          if (validVendor) {
            if (this.get('search') === "") {
              filteredVendors.push(vendorData);
            } else if ("".concat(vendorData.general_info.display_name || '').toLowerCase().includes(this.get('search').toLowerCase())) {
              filteredVendors.push(vendorData);
            }
          }
        }
      }

      if (this.get('sortBy')) {
        filteredVendors = filteredVendors.sort(function (a, b) {
          return a.general_info[_this3.get('sortBy')] > b.general_info[_this3.get('sortBy')];
        });
      }

      return this.get('sortAscending') ? filteredVendors.reverse() : filteredVendors;
    }),
    accounts: Ember.computed(function () {
      return this.store.findAll('account');
    }),
    reimbursedExpensesAccounts: Ember.computed(function () {
      return [undefined];
    }),
    withholdingAccounts: Ember.computed(function () {
      return [undefined];
    }),
    defaulPaymentsForServicesRenderedAccounts: Ember.computed(function () {
      return [undefined];
    }),
    paymentsForServicesRenderedAccounts: Ember.computed('accounts.@each', function () {
      var accounts = this.get('accounts').filter(function (account) {
        return account.get('subject_to_vendor_withholding');
      });
      accounts.pushObject(undefined);
      return accounts.map(function (a) {
        return a ? a.id : undefined;
      });
    }),
    inputAccounts: Ember.computed('accounts', 'reimbursedExpensesAccounts.@each', 'withholdingAccounts.@each', 'paymentsForServicesRenderedAccounts.@each', function () {
      var _this4 = this;

      var inputAccounts = [];
      var accountArrays = ['reimbursedExpensesAccounts', 'withholdingAccounts', 'paymentsForServicesRenderedAccounts'];
      accountArrays.forEach(function (arrName) {
        inputAccounts = inputAccounts.concat(_this4.get(arrName));
      });
      return inputAccounts;
    }),
    codeOptions: Ember.computed(function () {
      return [{
        value: "A",
        description: "A. Payments made to hospitals, clinics, clinical laboratories, homes for terminally ill patients, homes for the elderly, and institutions for disabled persons. The term \u201Chospital or clinic\u201D does not include the rendering of laboratory services, except when those services are rendered by laboratories forming an integral part of the hospital or clinic."
      }, {
        value: "B",
        description: "B. Payments made to exempt organizations, as provided in Section 1101.01 of the Code."
      }, {
        value: "C",
        description: "C. Payments made to contractors or subcontractors for construction work. The term \u201Cconstruction work\u201D does not include services for architecture, engineering, design, consulting or other services of similar nature."
      }, {
        value: "D",
        description: "D. Payments for services to a bona fide farmer complying with the requirements for the deduction provided by Section 1033.12 of the Code or in any other equivalent provision of a special act."
      }, {
        value: "E",
        description: "E. Payments made directly, or through agents, representatives or other intermediaries, to an eligible carrier"
      }, {
        value: "F",
        description: "F. Payments made directly, or through agents, representatives or other intermediaries, to an eligible carrier."
      }, {
        value: "G",
        description: "G. Payments made by an eligible carrier to a nonprofit entity for purposes of bookkeeping, recording, reporting and collection of air or sea transportation ticket sales and other related services, on behalf of or for the benefit of such eligible carrier"
      }, {
        value: "H",
        description: "H. Payments for ecclesiastic services rendered by priests or other ministers of the gospel, duly authorized or ordained, including Hebrew rabbis."
      }, {
        value: "I",
        description: "I. Payments for services rendered by drivers of Transportation Network Companies, according to Administrative Determination No. 16-16."
      }, {
        value: "J",
        description: "J. Payments for services rendered by a member of a controlled group of corporations or group of related entities, as defined in Sections 1010.04 and 1010.05 of the Code or payments made by a Pass-Through Entity for the services rendered by an owner of said entity."
      }, {
        value: "K",
        description: "K. Payments for services rendered not subject to withholding, as provided by the Secretary through Administrative Determination, Circular Letter or Informative Bulletin."
      }];
    }),
    sp4806PdfCoordinates: function sp4806PdfCoordinates(vendorData) {
      var vendor = vendorData.general_info;
      var isCompany = vendor.is_company && vendor.is_company !== 'false';

      if (vendor) {
        var amountPaidNotSubjectToWithholding = parseFloat(vendorData.payments_for_services_rendered_not_subject_to_withholding) !== 0 ? parseFloat(vendorData.payments_for_services_rendered_not_subject_to_withholding).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var amountPaidSubjectToWithholding = parseFloat(vendorData.payments_for_services_rendered_subject_to_withholding) !== 0 ? parseFloat(vendorData.payments_for_services_rendered_subject_to_withholding).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var amountWithheld = parseFloat(vendorData.withholding) !== 0 ? parseFloat(vendorData.withholding).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var reimbursedExpenses = parseFloat(vendorData.reimbursed_expenses || 0) !== 0 ? parseFloat(vendorData.reimbursed_expenses).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var specialContribution = parseFloat(vendorData.special_contribution || 0) !== 0 ? parseFloat(vendorData.special_contribution).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var paymentToHealthProviders = parseFloat(vendorData.payment_to_health_providers || 0) !== 0 ? parseFloat(vendorData.payment_to_health_providers).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var healthServices,
            physiciansAct14,
            healthProfessionals,
            swornStatementReceived,
            paymentsReportedCorrespondOutsourcesServices = [null, null, null, null, null];

        if (this.get('year') === "2019") {
          healthServices = {
            value: vendorData.healthServices ? "X" : "",
            x: 29,
            y: 383,
            size: 10
          };
          physiciansAct14 = {
            value: vendorData.physiciansAct14 ? "X" : "",
            x: 29,
            y: 368,
            size: 10
          };
          healthProfessionals = {
            value: vendorData.healthProfessionals ? "X" : "",
            x: 29,
            y: 368,
            size: 10
          };
          swornStatementReceived = {
            value: vendorData.swornStatementReceived ? "X" : "",
            x: 29,
            y: 348,
            size: 10
          };
          paymentsReportedCorrespondOutsourcesServices = {
            value: vendorData.paymentsReportedCorrespondOutsourcesServices ? "X" : "",
            x: 29,
            y: 328,
            size: 10
          }; // } else if (this.get('year') === "2020") {
        } else {
          healthServices = {
            value: vendorData.healthServices ? "X" : "",
            x: 29,
            y: 380,
            size: 10
          };
          physiciansAct14 = {
            value: vendorData.physiciansAct14 ? "X" : "",
            x: 29,
            y: 365,
            size: 10
          };
          healthProfessionals = {
            value: vendorData.healthProfessionals ? "X" : "",
            x: 29,
            y: 348,
            size: 10
          };
          swornStatementReceived = {
            value: vendorData.swornStatementReceived ? "X" : "",
            x: 29,
            y: 332,
            size: 10
          };
          paymentsReportedCorrespondOutsourcesServices = {
            value: vendorData.paymentsReportedCorrespondOutsourcesServices ? "X" : "",
            x: 29,
            y: 310,
            size: 10
          };
        }

        return {
          ein: {
            value: this.get('company.ein') || "",
            x: 27,
            y: 680,
            size: 10
          },
          name: {
            value: this.get('company.name') || "",
            x: 27,
            y: 655,
            size: 10
          },
          employerPostalAddressLineOne: {
            value: this.get('company.postal_address_line_one') || "",
            x: 27,
            y: 635,
            size: 7
          },
          employerPostalAddressLineTwo: {
            value: this.get('company.postal_address_line_two') || "",
            x: 27,
            y: 625,
            size: 7
          },
          employerPostalAddressLineThree: {
            value: "".concat(this.get('company.postal_address_city') || "", " ").concat(this.get('company.postal_address_state') || "", " ").concat(this.get('company.postal_address_country') || "", " ").concat(this.get('company.postal_address_zip') || ""),
            x: 27,
            y: 615,
            size: 7
          },
          vendorPostalAddressLineOne: {
            value: vendor.address_line_one || "",
            x: 27,
            y: 517,
            size: 7
          },
          vendorPostalAddressLineTwo: {
            value: vendor.address_line_two || "",
            x: 27,
            y: 510,
            size: 7
          },
          vendorPostalAddressLineThree: {
            value: "".concat(vendor.city || "", " ").concat(vendor.state || "", " ").concat(vendor.country || "", " ").concat(vendor.zipcode || ""),
            x: 27,
            y: 500,
            size: 7
          },
          vendorSsEin: {
            value: vendor.company_ein || "",
            x: 27,
            y: 565,
            size: 10
          },
          vendorName: {
            value: vendor.display_name,
            x: 27,
            y: 541,
            size: 10
          },
          specialContribution: {
            value: specialContribution || "",
            x: 27,
            y: 459,
            size: 10
          },
          reimbursedExpenses: {
            value: reimbursedExpenses || "",
            x: 27,
            y: 428,
            size: 10
          },
          paymentToHealthProviders: {
            value: paymentToHealthProviders || "",
            x: 27,
            y: 397,
            size: 10
          },
          healthServices: healthServices,
          physiciansAct14: physiciansAct14,
          healthProfessionals: healthProfessionals,
          swornStatementReceived: swornStatementReceived,
          paymentsReportedCorrespondOutsourcesServices: paymentsReportedCorrespondOutsourcesServices,
          electronicFilingNumber: {
            value: this.get('sp4806Data.electronicFilingNumber') ? this.get('sp4806Data.electronicFilingNumber') : "",
            x: 447,
            y: 740,
            size: 10
          },
          individual: {
            value: isCompany ? "" : vendorData.code || "",
            x: 338,
            y: 637,
            size: 10
          },
          corporation: {
            value: isCompany ? vendorData.code || "" : "",
            x: 338,
            y: 579,
            size: 10
          },
          amountPaidIndividualNotSubjectToWithholding: {
            value: isCompany ? "" : amountPaidNotSubjectToWithholding,
            x: 447,
            y: 653,
            size: 10
          },
          amountPaidCorporationNotSubjectToWithholding: {
            value: isCompany ? amountPaidNotSubjectToWithholding : "",
            x: 447,
            y: 595,
            size: 10
          },
          amountPaidIndividualSubjectToWithholding: {
            value: isCompany ? "" : amountPaidSubjectToWithholding,
            x: 447,
            y: 543,
            size: 10
          },
          amountPaidCorporationSubjectToWithholding: {
            value: isCompany ? amountPaidSubjectToWithholding : "",
            x: 447,
            y: 501,
            size: 10
          },
          amountWithheldIndividual: {
            value: isCompany ? "" : amountWithheld,
            x: 536,
            y: 543,
            size: 10
          },
          amountWithheldCompany: {
            value: isCompany ? amountWithheld : "",
            x: 536,
            y: 501,
            size: 10
          },
          controlNumber: {
            value: vendorData.control_number || "",
            x: 275,
            y: 342,
            size: 10
          }
        };
      }
    },
    calculateTotals: function calculateTotals() {
      var sp4806Data = this.get('sp4806Data');
      var amountPaidIndividualNotSubjectToWithholding = 0;
      var amountPaidCorporationNotSubjectToWithholding = 0;
      var amountPaidIndividualSubjectToWithholding = 0;
      var amountPaidCorporationSubjectToWithholding = 0;
      var amountWithheldIndividual = 0;
      var amountWithheldCompany = 0;
      var reimbursedExpenses = 0;
      var amountPaidTotal = 0;
      var amountWithheldTotal = 0;
      var paymentToHealthProviders = 0;
      var specialContribution = 0;
      var totalVendors = 0;

      for (var vendorId in sp4806Data) {
        if (vendorId !== "id" && vendorId !== "electronicFilingNumber" && vendorId !== "totals" && this.validVendor(sp4806Data[vendorId])) {
          var vendor = sp4806Data[vendorId];
          totalVendors++;

          if (vendor['general_info']['is_company']) {
            amountPaidCorporationNotSubjectToWithholding += parseFloat(vendor['payments_for_services_rendered_not_subject_to_withholding']);
            amountPaidCorporationSubjectToWithholding += parseFloat(vendor['payments_for_services_rendered_subject_to_withholding']);
            amountWithheldCompany += parseFloat(vendor['withholding']);
            amountWithheldTotal += parseFloat(amountWithheldCompany);
            amountPaidTotal += amountPaidCorporationNotSubjectToWithholding + amountPaidCorporationSubjectToWithholding;
          } else {
            amountPaidIndividualNotSubjectToWithholding += parseFloat(vendor['payments_for_services_rendered_not_subject_to_withholding']);
            amountPaidIndividualSubjectToWithholding += parseFloat(vendor['payments_for_services_rendered_subject_to_withholding']);
            amountWithheldIndividual += parseFloat(vendor['withholding']);
            amountWithheldTotal += parseFloat(amountWithheldIndividual);
            amountPaidTotal += amountPaidIndividualNotSubjectToWithholding + amountPaidIndividualSubjectToWithholding;
          }

          reimbursedExpenses += vendor["reimbursed_expenses"] && vendor["reimbursed_expenses"] !== "" ? parseFloat(vendor["reimbursed_expenses"]) : 0;
          paymentToHealthProviders += vendor["payment_to_health_providers"] && vendor["payment_to_health_providers"] !== "" ? parseFloat(vendor["payment_to_health_providers"]) : 0;
          specialContribution += vendor["special_contribution"] && vendor["special_contribution"] !== "" ? parseFloat(vendor["special_contribution"]) : 0;
        }
      }

      amountPaidIndividualNotSubjectToWithholding = parseFloat(amountPaidIndividualNotSubjectToWithholding) !== 0 ? parseFloat(amountPaidIndividualNotSubjectToWithholding).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      amountPaidCorporationNotSubjectToWithholding = parseFloat(amountPaidCorporationNotSubjectToWithholding) !== 0 ? parseFloat(amountPaidCorporationNotSubjectToWithholding).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      amountPaidIndividualSubjectToWithholding = parseFloat(amountPaidIndividualSubjectToWithholding) !== 0 ? parseFloat(amountPaidIndividualSubjectToWithholding).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      amountPaidCorporationSubjectToWithholding = parseFloat(amountPaidCorporationSubjectToWithholding) !== 0 ? parseFloat(amountPaidCorporationSubjectToWithholding).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      amountWithheldIndividual = parseFloat(amountWithheldIndividual) !== 0 ? parseFloat(amountWithheldIndividual).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      amountWithheldCompany = parseFloat(amountWithheldCompany) !== 0 ? parseFloat(amountWithheldCompany).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      amountPaidTotal = parseFloat(sp4806Data['totals']['total_amount_paid']) !== 0 ? parseFloat(sp4806Data['totals']['total_amount_paid']).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : ""; // amountPaidTotal = parseFloat(amountPaidTotal) !== 0 ? parseFloat(amountPaidTotal).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "" 

      amountWithheldTotal = parseFloat(sp4806Data['totals']['total_withholding']) !== 0 ? parseFloat(sp4806Data['totals']['total_withholding']).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : ""; // amountWithheldTotal = parseFloat(amountWithheldTotal) !== 0 ? parseFloat(amountWithheldTotal).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : ""

      reimbursedExpenses = reimbursedExpenses !== 0 ? parseFloat(reimbursedExpenses).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      paymentToHealthProviders = paymentToHealthProviders !== 0 ? parseFloat(paymentToHealthProviders).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      specialContribution = specialContribution !== 0 ? parseFloat(specialContribution).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      this.setProperties({
        'totalAmountPaidIndividualNotSubjectToWithholding': amountPaidIndividualNotSubjectToWithholding,
        'totalAmountPaidCorporationNotSubjectToWithholding': amountPaidCorporationNotSubjectToWithholding,
        'totalAmountPaidIndividualSubjectToWithholding': amountPaidIndividualSubjectToWithholding,
        'totalAmountPaidCorporationSubjectToWithholding': amountPaidCorporationSubjectToWithholding,
        'totalAmountWithheldIndividual': amountWithheldIndividual,
        'totalAmountWithheldCompany': amountWithheldCompany,
        'totalAmountPaid': amountPaidTotal,
        'totalAmountWithheld': amountWithheldTotal,
        'totalReimbursedExpenses': reimbursedExpenses,
        'totalPaymentToHealthProviders': paymentToHealthProviders,
        'totalSpecialContribution': specialContribution,
        'totalVendors': totalVendors
      });
    },
    sp4806_2PdfCoordinates: function sp4806_2PdfCoordinates() {
      this.calculateTotals();
      return {
        ein: {
          value: this.get('company.ein') || "",
          x: 66,
          y: 682,
          size: 10
        },
        name: {
          value: this.get('company.name') || "",
          x: 31,
          y: 654,
          size: 10
        },
        employerPostalAddressLineOne: {
          value: this.get('company.postal_address_line_one') || "",
          x: 27,
          y: 630,
          size: 8
        },
        employerPostalAddressLineTwo: {
          value: this.get('company.postal_address_line_two') || "",
          x: 27,
          y: 618,
          size: 8
        },
        employerPostalAddressLineThree: {
          value: "".concat(this.get('company.postal_address_city') || "", " ").concat(this.get('company.postal_address_state') || "", " ").concat(this.get('company.postal_address_country') || "", " ").concat(this.get('company.postal_address_zip') || ""),
          x: 27,
          y: 605,
          size: 8
        },
        employerPhysicalAddressLineOne: {
          value: this.get('company.physical_address_line_one') || "",
          x: 242,
          y: 630,
          size: 8
        },
        employerPhysicalAddressLineTwo: {
          value: this.get('company.physical_address_line_two') || "",
          x: 242,
          y: 618,
          size: 8
        },
        employerPhysicalAddressLineThree: {
          value: "".concat(this.get('company.physical_address_city') || "", " ").concat(this.get('company.physical_address_state') || "", " ").concat(this.get('company.physical_address_country') || "", " ").concat(this.get('company.postal_address_zip') || ""),
          x: 242,
          y: 605,
          size: 8
        },
        paymentToHealthProviders: {
          value: this.get('totalPaymentToHealthProviders') || "",
          x: 101,
          y: 577,
          size: 10
        },
        reimbursedExpenses: {
          value: this.get('totalReimbursedExpenses') || "",
          x: 278,
          y: 577,
          size: 10
        },
        specialContribution: {
          value: this.get('totalSpecialContribution') || "",
          x: 461,
          y: 577,
          size: 10
        },
        amountPaidIndividualNotSubjectToWithholding: {
          value: this.get('totalAmountPaidIndividualNotSubjectToWithholding') || "",
          x: 411,
          y: 522,
          size: 10
        },
        amountPaidCorporationNotSubjectToWithholding: {
          value: this.get('totalAmountPaidCorporationNotSubjectToWithholding') || "",
          x: 411,
          y: 497,
          size: 10
        },
        amountPaidIndividualSubjectToWithholding: {
          value: this.get('totalAmountPaidIndividualSubjectToWithholding') || "",
          x: 411,
          y: 472,
          size: 10
        },
        amountPaidCorporationSubjectToWithholding: {
          value: this.get('totalAmountPaidCorporationSubjectToWithholding') || "",
          x: 411,
          y: 447,
          size: 10
        },
        amountWithheldIndividual: {
          value: this.get('totalAmountWithheldIndividual') || "",
          x: 524,
          y: 472,
          size: 10
        },
        amountWithheldCompany: {
          value: this.get('totalAmountWithheldCompany') || "",
          x: 524,
          y: 447,
          size: 10
        },
        amountPaidTotal: {
          value: this.get('totalAmountPaid') || "",
          x: 411,
          y: 422,
          size: 10
        },
        amountWithheldTotal: {
          value: this.get('totalAmountWithheld') || "",
          x: 524,
          y: 422,
          size: 10
        }
      };
    },
    pdf4805Coordinates: function pdf4805Coordinates() {
      this.calculateTotals();
      var taxPayerTypeYData = {
        "Individual": 255,
        "Partnership": 311,
        "Corporation": 367,
        "Estate or Trust": 436,
        "Others": 507
      };
      return {
        ein: {
          value: this.get('company.ein') || "",
          x: 27,
          y: 673,
          size: 10
        },
        name: {
          value: this.get('company.name') || "",
          x: 31,
          y: 653,
          size: 10
        },
        address: {
          value: "".concat(this.get('company.postal_address_line_one') || "", " ").concat(this.get('company.postal_address_line_two') || "", " ").concat(this.get('company.postal_address_city') || "", " ").concat(this.get('company.postal_address_state') || "", " ").concat(this.get('company.postal_address_country') || "", " ").concat(this.get('company.postal_address_zip') || ""),
          x: 27,
          y: 625,
          size: 8
        },
        taxPayerType: {
          value: this.get('sp4806Data.taxPayerType') ? "X" : "",
          x: taxPayerTypeYData[this.get('sp4806Data.taxPayerType')] || 0,
          y: 678,
          size: 10
        },
        checkmark: {
          value: "X",
          x: 441,
          y: 588,
          size: 10
        },
        totalForms: {
          value: this.get('totalVendors').toString(),
          x: 50,
          y: 600,
          size: 10
        },
        totalWithheld: {
          value: this.get('totalAmountWithheld') || "",
          x: 182,
          y: 600,
          size: 10
        },
        totalPaid: {
          value: this.get('totalAmountPaid') || "",
          x: 314,
          y: 600,
          size: 10
        },
        electronicFilingNumber: {
          value: this.get('sp4806Data.electronicFilingNumber') ? this.get('sp4806Data.electronicFilingNumber') : "",
          x: 466,
          y: 729,
          size: 10
        }
      };
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    validVendor: function validVendor(data) {
      var values = [data.reimbursed_expenses, data.payments_for_services_rendered_not_subject_to_withholding, data.payments_for_services_rendered_subject_to_withholding, data.withholding, data.reimbursed_expenses, data.special_contribution, data.payment_to_health_providers];
      var valid = false;
      values.forEach(function (value) {
        if (!valid) {
          valid = value && parseFloat(value) !== 0;
        }
      });
      return valid;
    },
    sp4806pdfProcess: function sp4806pdfProcess(existingPdfDocBytes, _vendorId) {
      var _this5 = this;

      var _this = this;

      this.vendorSetup();
      var pdfDoc = PDFLib.PDFDocumentFactory.load(existingPdfDocBytes);

      var _pdfDoc$embedStandard = pdfDoc.embedStandardFont(PDFLib.StandardFonts.Helvetica),
          _pdfDoc$embedStandard2 = _slicedToArray(_pdfDoc$embedStandard, 2),
          helveticaRef = _pdfDoc$embedStandard2[0],
          helveticaFont = _pdfDoc$embedStandard2[1];

      this.set('helveticaRef', helveticaRef);
      this.set('helveticaFont', helveticaFont);
      var pages = pdfDoc.getPages();
      var firstPage = pages[0];
      pages[0].addFontDictionary('Helvetica', helveticaRef);
      var sp4806Data = this.get('sp4806Data'); // Copy first page

      var copyPage = function copyPage(page) {
        var copiedPage = PDFLib.PDFPage.fromDict(PDFLib.PDFDictionary.from(new Map(page.map), page.index));

        if (page.getMaybe('Contents')) {
          page.normalizeContents();
          var originalContents = page.Contents;
          var copiedContents = PDFLib.PDFArray.fromArray(originalContents.array.slice(), page.index);
          copiedPage.set('Contents', copiedContents);
        }

        return copiedPage;
      };

      var newPageIndex = 0;

      if (_vendorId) {
        // let newPage = copyPage(firstPage);
        // pdfDoc.insertPage(newPageIndex, newPage);
        var _pages = pdfDoc.getPages();

        var page = _pages[0];
        var pdfData = this.sp4806PdfCoordinates(sp4806Data[_vendorId]);
        var pageKeys = Object.keys(pdfData);
        var contentStream;
        pageKeys.forEach(function (k) {
          contentStream = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(pdfData["".concat(k)].value ? pdfData["".concat(k)].value.toString().replace(/(\r\n|\n|\r)/gm, "") : ""), {
            x: pdfData["".concat(k)].x,
            y: pdfData["".concat(k)].y,
            size: pdfData["".concat(k)].size,
            font: 'Helvetica',
            colorRgb: pdfData["".concat(k)].color ? pdfData["".concat(k)].color : [0, 0, 1]
          }));
          page.addContentStreams(pdfDoc.register(contentStream));
        });
      } else {
        var _loop = function _loop() {
          if (vendorId !== "id" && vendorId !== "electronicFilingNumber" && vendorId !== "totals" && _this5.validVendor(sp4806Data[vendorId])) {
            var newPage = copyPage(firstPage);
            pdfDoc.insertPage(newPageIndex, newPage);

            var _pages2 = pdfDoc.getPages();

            newPage = _pages2[newPageIndex];

            var _pdfData = _this5.sp4806PdfCoordinates(sp4806Data[vendorId]);

            var _pageKeys = Object.keys(_pdfData);

            var _contentStream;

            _pageKeys.forEach(function (k) {
              try {
                _contentStream = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(_pdfData["".concat(k)].value ? _pdfData["".concat(k)].value.toString().replace(/(\r\n|\n|\r)/gm, "") : ""), {
                  x: _pdfData["".concat(k)].x,
                  y: _pdfData["".concat(k)].y,
                  size: _pdfData["".concat(k)].size,
                  font: 'Helvetica',
                  colorRgb: _pdfData["".concat(k)].color ? _pdfData["".concat(k)].color : [0, 0, 1]
                }));
                newPage.addContentStreams(pdfDoc.register(_contentStream));
              } catch (error) {
                debugger;
              }
            });
          }
        };

        for (var vendorId in sp4806Data) {
          _loop();
        }

        pdfDoc.removePage(pdfDoc.getPages().length - 7); //remove empty sp4806 page
      }

      this.get('applicationController').set('showLoading', false);
      var pdfBytes = PDFLib.PDFDocumentWriter.saveToBytes(pdfDoc);
      this.downloadPdf(pdfBytes);
    },
    sp4806_2pdfProcess: function sp4806_2pdfProcess(pdfDoc) {
      var _pdfDoc$embedStandard3 = pdfDoc.embedStandardFont(PDFLib.StandardFonts.Helvetica),
          _pdfDoc$embedStandard4 = _slicedToArray(_pdfDoc$embedStandard3, 2),
          helveticaRef = _pdfDoc$embedStandard4[0],
          helveticaFont = _pdfDoc$embedStandard4[1];

      this.set('helveticaRef', helveticaRef);
      this.set('helveticaFont', helveticaFont);
      var pages = pdfDoc.getPages();
      var page = pages[0];
      var sp4806_2pdfData = this.sp4806_2PdfCoordinates();
      var sp4806_2pageKeys = Object.keys(sp4806_2pdfData);
      var contentStream;
      sp4806_2pageKeys.forEach(function (k) {
        contentStream = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(sp4806_2pdfData["".concat(k)].value ? sp4806_2pdfData["".concat(k)].value.toString().replace(/(\r\n|\n|\r)/gm, "") : ""), {
          x: sp4806_2pdfData["".concat(k)].x,
          y: sp4806_2pdfData["".concat(k)].y,
          size: sp4806_2pdfData["".concat(k)].size,
          font: 'Helvetica',
          colorRgb: sp4806_2pdfData["".concat(k)].color ? sp4806_2pdfData["".concat(k)].color : [0, 0, 1]
        }));
        page.addContentStreams(pdfDoc.register(contentStream));
      });
      this.get('applicationController').set('showLoading', false);
      var pdfBytes = PDFLib.PDFDocumentWriter.saveToBytes(pdfDoc);
      this.downloadPdf(pdfBytes);
    },
    pdf4805Process: function pdf4805Process(pdfDoc) {
      var _pdfDoc$embedStandard5 = pdfDoc.embedStandardFont(PDFLib.StandardFonts.Helvetica),
          _pdfDoc$embedStandard6 = _slicedToArray(_pdfDoc$embedStandard5, 2),
          helveticaRef = _pdfDoc$embedStandard6[0],
          helveticaFont = _pdfDoc$embedStandard6[1];

      this.set('helveticaRef', helveticaRef);
      this.set('helveticaFont', helveticaFont);
      var pages = pdfDoc.getPages();
      var page = pages[0];
      var summaryPdfData = this.pdf4805Coordinates();
      var summaryPageKeys = Object.keys(summaryPdfData);
      var contentStream;
      summaryPageKeys.forEach(function (k) {
        contentStream = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(summaryPdfData["".concat(k)].value ? summaryPdfData["".concat(k)].value.toString().replace(/(\r\n|\n|\r)/gm, "") : ""), {
          x: summaryPdfData["".concat(k)].x,
          y: summaryPdfData["".concat(k)].y,
          size: summaryPdfData["".concat(k)].size,
          font: 'Helvetica',
          colorRgb: summaryPdfData["".concat(k)].color ? summaryPdfData["".concat(k)].color : [0, 0, 1]
        }));
        page.addContentStreams(pdfDoc.register(contentStream));
      });
      this.get('applicationController').set('showLoading', false);
      var pdfBytes = PDFLib.PDFDocumentWriter.saveToBytes(pdfDoc);
      this.downloadPdf(pdfBytes);
    },
    vendorSetup: function vendorSetup() {
      var vendors = this.get('sp4806Data');
      var count = 1;

      for (var vendorId in vendors) {
        var vendor = vendors[vendorId];

        if (vendor.general_info) {
          var birthYear = vendor.general_info.birth_date ? vendor.general_info.birth_date.split("-")[0] : null;
          var age = birthYear ? parseFloat(this.get('year')) - parseFloat(birthYear) : null;
          var countLength = count.toString().length;
          var controlNumber = "0".repeat(9 - countLength) + count;
          this.set("sp4806Data.".concat(vendorId, ".control_number"), controlNumber);

          if (age && age <= 26 && vendor.wages) {
            var wages = parseFloat(vendor.wages);
            var value = wages > 40000 ? 40000 : wages;
            this.set("sp4806Data.".concat(vendorId, "._16Code"), "E");
            this.set("sp4806Data.".concat(vendorId, "._16Value"), value);
          }

          count += 1;
        }
      }
    },
    electronicFiling: function electronicFiling() {
      var _this6 = this;

      var data = JSON.stringify({
        sp4806: {
          sp4806_id: this.get('sp4806').id,
          company_id: this.companyId
        }
      });
      var ajaxOptions = {
        url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/sp4806s/electronic_filing"),
        method: 'POST',
        contentType: 'application/json',
        data: data,
        successCallback: function successCallback(response) {
          _this6.set('electronicFileGenerated', true);
        },
        errorCallback: function errorCallback(e) {
          _this6.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(e.payload.errors, "</span></div>")
          });
        }
      };
      this.get('ajaxCall').request(ajaxOptions);
    },
    downloadPdf: function downloadPdf(data) {
      var blob = new Blob([data], {
        type: "application/pdf"
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob); // link.download = "myFileName.pdf";
      // link.click();

      window.open(link.href);
    },
    getSp4806PDF: function getSp4806PDF(vendorId) {
      var _this7 = this;

      if (this.get('sp4806Data')) {
        fetch("https://comprobantes480.s3.amazonaws.com/480.6sp_".concat(this.get('year'), "_informativo.pdf")).then(function (res) {
          var file = res.arrayBuffer();
          file.then(function (f) {
            _this7.sp4806pdfProcess(new Uint8Array(f), vendorId);
          });
        });
      }
    },
    getSp4806_2PDF: function getSp4806_2PDF() {
      var _this8 = this;

      if (this.get('sp4806Data')) {
        fetch("https://comprobantes480.s3.amazonaws.com/480.6sp.2_".concat(this.get('year'), "_informativo.pdf")).then(function (res) {
          var file = res.arrayBuffer();
          file.then(function (f) {
            var pdfDoc = PDFLib.PDFDocumentFactory.load(new Uint8Array(f));

            _this8.sp4806_2pdfProcess(pdfDoc);
          });
        });
      }
    },
    get4805PDF: function get4805PDF() {
      var _this9 = this;

      if (this.get('sp4806Data')) {
        fetch("https://comprobantes480.s3.amazonaws.com/480.5_".concat(this.get('year'), "_informativo.pdf")).then(function (res) {
          var file = res.arrayBuffer();
          file.then(function (f) {
            var pdfDoc = PDFLib.PDFDocumentFactory.load(new Uint8Array(f));

            _this9.pdf4805Process(pdfDoc);
          });
        });
      }
    },
    actions: {
      setYear: function setYear(year) {
        this.set('year', year);
        var sp4806 = this.get('model').findBy('year', year);

        if (sp4806) {
          this.set('sp4806Data', sp4806.get('json_data'));
          this.set('sp4806Data.id', sp4806.id);
          this.set('sp4806Data.electronicFilingNumber', sp4806.get('json_data.electronicFilingNumber'));
        }
      },
      setEditVendor: function setEditVendor(vendorId) {
        this.set('editVendor', vendorId ? this.get("sp4806Data.".concat(vendorId)) : null);
      },
      saveSp4806s: function saveSp4806s(electronicFiling) {
        var _this10 = this;

        var sp4806 = this.get('model').findBy('year', this.get('year'));

        if (sp4806) {
          this.store.findRecord('sp4806', sp4806.id).then(function (sp4806) {
            sp4806.set('json_data', _this10.get('sp4806Data'));
            sp4806.save().then(function () {
              _this10.get('notify').success({
                html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>480.6SP Saved</span></div>'
              });
            });

            _this10.set('sp4806', sp4806);

            if (electronicFiling) {
              _this10.electronicFiling();
            }
          });
        } else {
          var newSp4806 = this.store.createRecord('sp4806', {
            company_id: this.get('companyId'),
            electronic_filing_number: this.get('sp4806Data.electronicFilingNumber'),
            year: this.get('year'),
            json_data: this.get('sp4806Data')
          });
          newSp4806.save().then(function (sp4806) {
            _this10.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>480.6SP Saved</span></div>'
            });

            _this10.set('sp4806', sp4806);

            if (electronicFiling) {
              _this10.electronicFiling();
            }
          });
        }
      },
      delete: function _delete() {
        var _this11 = this;

        var sp4806 = this.get('model').findBy('year', this.get('year'));
        sp4806.destroyRecord().then(function () {
          _this11.notify.alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(_this11.get('year'), " 480.6SP Deleted</span></div>")
          });

          _this11.set('sp4806Data', null);

          _this11.set('year', null);

          _this11.send('refreshModel');

          Ember.$('#sp4806YearSelect option')[0].selected = true;
        });
      },
      printVendorSp4806: function printVendorSp4806(vendorId) {
        this.getSp4806PDF(vendorId);
      },
      setReimbursedExpensesAccount: function setReimbursedExpensesAccount(accountId, index) {
        if (this.get('inputAccounts').includes(accountId)) {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>This Account was already selected, please choose a different one</span></div>"
          });
          Ember.$("#reimbursedExpensesAccount-".concat(index, " option"))[0].selected = true;
        } else {
          this.get('reimbursedExpensesAccounts').replace(index, 1, [accountId]);

          if (!this.get('reimbursedExpensesAccounts').includes(undefined)) {
            this.get('reimbursedExpensesAccounts').pushObject(undefined);
            Ember.$("#reimbursedExpensesAccount-".concat(index, " option"))[0].selected = true;
          }
        }
      },
      removeReimbursedExpensesAccount: function removeReimbursedExpensesAccount(index) {
        if (this.get('reimbursedExpensesAccounts').length === 1) {
          this.get('reimbursedExpensesAccounts').replace(index, 1, [undefined]);
          Ember.$("#reimbursedExpensesAccount-".concat(index, " option"))[0].selected = true;
        } else {
          this.get('reimbursedExpensesAccounts').replace(index, 1, []);
        }
      },
      setWithholdingAccount: function setWithholdingAccount(accountId, index) {
        if (this.get('inputAccounts').includes(accountId)) {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>This Account was already selected, please choose a different one</span></div>"
          });
          Ember.$("#withholdingAccount-".concat(index, " option"))[0].selected = true;
        } else {
          this.get('withholdingAccounts').replace(index, 1, [accountId]);

          if (!this.get('withholdingAccounts').includes(undefined)) {
            this.get('withholdingAccounts').pushObject(undefined);
            Ember.$("#withholdingAccount-".concat(index, " option"))[0].selected = true;
          }
        }
      },
      removeWithholdingAccount: function removeWithholdingAccount(index) {
        if (this.get('withholdingAccounts').length === 1) {
          this.get('withholdingAccounts').replace(index, 1, [undefined]);
          Ember.$("#withholdingAccount-".concat(index, " option"))[0].selected = true;
        } else {
          this.get('withholdingAccounts').replace(index, 1, []);
        }
      },
      setPaymentsForServicesRenderedAccount: function setPaymentsForServicesRenderedAccount(accountId, index) {
        if (this.get('inputAccounts').includes(accountId)) {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>This Account was already selected, please choose a different one</span></div>"
          });
          Ember.$("#paymentsForServicesRenderedAccounts-".concat(index, " option"))[0].selected = true;
        } else {
          this.get('paymentsForServicesRenderedAccounts').replace(index, 1, [accountId]);

          if (!this.get('paymentsForServicesRenderedAccounts').includes(undefined)) {
            this.get('paymentsForServicesRenderedAccounts').pushObject(undefined);
            Ember.$("#paymentsForServicesRenderedAccount-".concat(index, " option"))[0].selected = true;
          }
        }
      },
      removePaymentsForServicesRenderedAccount: function removePaymentsForServicesRenderedAccount(index) {
        if (this.get('paymentsForServicesRenderedAccounts').length === 1) {
          this.get('paymentsForServicesRenderedAccounts').replace(index, 1, [undefined]);
          Ember.$("#paymentsForServicesRenderedAccounts-".concat(index, " option"))[0].selected = true;
        } else {
          this.get('paymentsForServicesRenderedAccounts').replace(index, 1, []);
        }
      },
      setCode: function setCode(vendorId) {
        var value = Ember.$("#codeSelect-".concat(vendorId))[0].value;
        this.set("sp4806Data.".concat(vendorId, ".code"), value);
      },
      calculate: function calculate() {
        var _this12 = this;

        var inputAccounts = {
          reimbursed_expenses_accounts: this.get('reimbursedExpensesAccounts').filter(function (a) {
            return a;
          }),
          withholding_accounts: this.get('withholdingAccounts').filter(function (a) {
            return a;
          }),
          payments_for_services_rendered_accounts: this.get('paymentsForServicesRenderedAccounts').filter(function (a) {
            return a;
          })
        };
        var data = JSON.stringify({
          sp4806: {
            year: this.get('year'),
            input_accounts: inputAccounts
          }
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/sp4806s/calculate"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback(response) {
            _this12.get('applicationController').set('showLoading', true);

            _this12.get('channel').bind('calculate', function (response) {
              _this12.store.findRecord('json-storage', response.json_storage_id).then(function (jsonStorage) {
                _this12.set('sp4806Data', jsonStorage.get('stored_json.sp4806'));

                _this12.vendorSetup();

                _this12.get('applicationController').set('showLoading', false);
              });
            }); // this.set('sp4806Data', response);

          },
          errorCallback: function errorCallback(e) {
            _this12.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(e.payload.errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      generateSp4806: function generateSp4806() {
        this.send('saveSp4806s');
        this.getSp4806PDF();
      },
      generateSp4806_2: function generateSp4806_2() {
        this.send('saveSp4806s');
        this.getSp4806_2PDF();
      },
      generate4805: function generate4805() {
        this.send('saveSp4806s');
        this.get4805PDF();
      },
      sortVendors: function sortVendors(sortBy) {
        this.toggleProperty('sortAscending');
        this.set('sortBy', sortBy);
      }
    }
  });

  _exports.default = _default;
});