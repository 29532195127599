define("account-berry-frontend/recurrency/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    frequency: _emberData.default.attr(''),
    every: _emberData.default.attr(''),
    duration: _emberData.default.attr(''),
    created_count: _emberData.default.attr(''),
    unlimited: _emberData.default.attr(''),
    transaction_date: _emberData.default.attr(''),
    transaction: _emberData.default.attr(''),
    ref_number: _emberData.default.attr(''),
    date: _emberData.default.attr('date'),
    amount: _emberData.default.attr('number'),
    payee: _emberData.default.attr(''),
    transaction_type: _emberData.default.attr('')
  });

  _exports.default = _default;
});