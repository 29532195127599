define("account-berry-frontend/companies/show/employees/payrolls/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    applicationController: Ember.inject.controller('application'),
    actions: {
      reloadRoute: function reloadRoute() {
        this.send('refreshModel');
      },
      toggleShowLoading: function toggleShowLoading(status, show) {
        if (status) {
          this.set('applicationController.showLoading', show);
        } else {
          this.toggleProperty('applicationController.showLoading');
        }
      }
    }
  });

  _exports.default = _default;
});