define("account-berry-frontend/router", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import GoogleAnalyticsRoute from 'ember-tracker/mixins/google-analytics-route';
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),
    didTransition: function didTransition() {
      this._super.apply(this, arguments);

      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var page = _this.get('url');

        var title = _this.getWithDefault('currentRouteName', 'unknown');

        Ember.get(_this, 'metrics').trackPage({
          page: page,
          title: title
        });
      });
    }
  });
  Router.map(function () {
    // this.route('Application');
    this.route('login');
    this.route('companies', {
      path: '/'
    }, function () {
      this.route('show', {
        path: ':company_id'
      }, function () {
        this.route('users', function () {
          this.route('new');
          this.route('show', {
            path: ':user_id'
          });
        });
        this.route('items', function () {
          this.route('new');
          this.route('show', {
            path: ':item_id'
          });
          this.route('transactions', {
            path: 'transactions/:item_id'
          });
        });
        this.route('accounts', function () {
          this.route('new');
          this.route('show', {
            path: ':account_id'
          });
          this.route('transactions', {
            path: ':account_id/transactions'
          });
        });
        this.route('checks', function () {
          this.route('new');
          this.route('show', {
            path: ':check_id'
          });
        });
        this.route('customers', function () {
          this.route('invoices', function () {
            this.route('show', {
              path: ':invoice_id'
            });
            this.route('new');
          });
          this.route('customers-table', function () {
            this.route('new');
            this.route('show', {
              path: ':customer_id'
            });
            this.route('transactions', {
              path: ':customer_id/transactions'
            });
          });
          this.route('sales-orders', function () {
            this.route('new');
            this.route('show', {
              path: ':sales_order_id'
            });
          });
          this.route('returns', function () {
            this.route('new');
            this.route('show', {
              path: ':return_id'
            });
          });
          this.route('receive-payment');
          this.route('representatives', function () {
            this.route('new');
            this.route('show', {
              path: ':representative_id'
            });
            this.route('transactions', {
              path: ':representative_id/invoices'
            });
          });
          this.route('taxes', function () {
            this.route('new');
            this.route('show', {
              path: ':tax_id'
            });
          });
          this.route('pvot');
          this.route('payment-settings');
        });
        this.route('vendors', function () {
          this.route('vendors-table');
          this.route('bills', function () {
            this.route('new');
            this.route('show', {
              path: ':bill_id'
            });
          });
          this.route('vendors-table', function () {
            this.route('new');
            this.route('show', {
              path: ':vendor_id'
            });
            this.route('transactions', {
              path: ':vendor_id/transactions'
            });
          });
          this.route('purchase-orders', function () {
            this.route('new');
            this.route('show', {
              path: ':purchase_order_id'
            });
          });
          this.route('receiving-and-bills', function () {
            this.route('new');
            this.route('show', {
              path: ':receiving_and_bill_id'
            });
          });
          this.route('returns', function () {
            this.route('new');
            this.route('show', {
              path: ':return_id'
            });
          });
          this.route('pay-vendor', function () {});
          this.route('landed-cost', function () {
            this.route('new');
            this.route('show', {
              path: ':landed_cost_id'
            });
          });
          this.route('480sp');
          this.route('480a');
        });
        this.route('employees', function () {
          this.route('employees_table');
          this.route('employees-table', function () {
            this.route('show', {
              path: ':employee_id'
            });
            this.route('new');
            this.route('rates', {
              path: ':employee_id/rates'
            }, function () {
              this.route('show', {
                path: ':employee_rate_id'
              });
              this.route('new');
            });
            this.route('transactions', {
              path: ':employee_id/transactions'
            });
          });
          this.route('payrolls', function () {
            this.route('new');
            this.route('show', {
              path: ':payroll_id'
            });
          });
          this.route('payroll-settings');
          this.route('vacation-sickness-adjustments');
          this.route('499-quarterly');
          this.route('941-pr');
          this.route('desempleo-incapacidad');
          this.route('choferil');
          this.route('w2');
        });
        this.route('deposits', function () {
          this.route('new');
          this.route('show', {
            path: ':deposit_id'
          });
        });
        this.route('journal-entries', function () {
          this.route('new');
          this.route('show', {
            path: ':journal_entry_id'
          });
        });
        this.route('reconciliations', function () {
          this.route('new');
          this.route('show', {
            path: ':reconciliation_id'
          });
        });
        this.route('closing-entries', function () {
          this.route('new');
        });
        this.route('reports', function () {
          this.route('trial-balance');
          this.route('general-ledger');
          this.route('balance-sheet');
          this.route('profit-loss');
          this.route('ap-aging-summary');
          this.route('ap-aging-detail');
          this.route('unpaid-bills');
          this.route('ar-aging-summary');
          this.route('ar-aging-detail');
          this.route('uncollected-bills');
          this.route('cash-flow');
          this.route('employee-earnings-detail');
          this.route('payroll-detail');
          this.route('vacation-sickness');
          this.route('reconciliation-report');
          this.route('customer-statements');
          this.route('vendor-tax-detail');
          this.route('journal-entries-detail');
          this.route('bank-register');
          this.route('sales-by-customer');
          this.route('sales-by-item-detail');
          this.route('payroll-stubs');
        });
        this.route('physical-inventory', function () {
          this.route('new');
          this.route('show', {
            path: ':physical_inventory_id'
          });
        });
        this.route('employeess', function () {});
        this.route('accept-ownership');
        this.route('labels');
        this.route('batches', function () {
          this.route('new');
          this.route('show', {
            path: ':batch_id'
          });
        });
        this.route('recurrencies');
        this.route('dashboard');
        this.route('bank-sync', function () {
          this.route('show', {
            path: ':plaid_link_id'
          });
        });
        this.route('company-settings', function () {
          this.route('edit');
        });
        this.route('shopify');
        this.route('clover');
        this.route('ath-movil');
      }); // this.route('edit', { path: 'edit/:company_id' });

      this.route('new');
    });
    this.route('signup', {
      path: ':company_id/signup'
    });
    this.route('bad_url', {
      path: '/*badurl'
    }); // Catch unrecognized urls

    this.route("fourOhFour", {
      path: "/*path"
    });
    this.route('settings', function () {
      this.route('account-profile');
      this.route('subscription-invoices');
    });
    this.route('confirmed');
    this.route('password_reset');
    this.route('invoice_payment', {
      path: 'invoice_payment/:invoice_id'
    });
    this.route('auth', function () {
      this.route('shopify', function () {
        this.route('callback');
      });
      this.route('clover', function () {
        this.route('callback');
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});