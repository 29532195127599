define("account-berry-frontend/components/create-account/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    notify: Ember.inject.service('notify'),
    accountTypeId: "",
    init: function init() {
      this._super();

      this.set('account', {});
    },
    accountTypes: Ember.computed(function () {
      var accountTypes = this.get('session.data.authenticated.account_types').filter(function (accountType) {
        switch (accountType.name) {
          case "Inventory":
            break;

          case "Accounts Payable":
            break;

          case "Accounts Receivable":
            break;

          default:
            return accountType;
        }
      });
      return accountTypes.sort(function (a, b) {
        return a.order_number - b.order_number;
      });
    }),
    actions: {
      updateAccountTypeValue: function updateAccountTypeValue(value) {
        this.set('accountTypeId', value);
      },
      updateAccountActivity: function updateAccountActivity(value) {
        this.set('account.activity', value);
      },
      create: function create() {
        var _this = this;

        this.setProperties({
          'account.company_id': this.companyId,
          'account.account_type_id': this.accountTypeId
        });
        var newAccount = this.store.createRecord('account', this.account);
        newAccount.save().then(function (res) {
          _this.sendAction('setAccount', res.id, true);

          _this.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Account Created</span></div>'
          });
        }, function (response) {
          newAccount.rollbackAttributes();
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
        this.sendAction('toggleCreateAccount');
      },
      close: function close() {
        this.set('account', {});
        this.sendAction('cancelCreateAccount');
      }
    }
  });

  _exports.default = _default;
});