define("account-berry-frontend/components/ath-movil-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K/sbB18k",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"id\",\"ATHMovil_Checkout_Button\"],[9,\"class\",\"bottom-spacing top-spacing\"],[7],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/ath-movil-button/template.hbs"
    }
  });

  _exports.default = _default;
});