define("account-berry-frontend/components/dashboard-cash-accounts/component", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    ajaxCall: Ember.inject.service(),
    cashAccounts: Ember.computed('cashAccountsData', function () {
      if (this.get('cashAccountsData')) {
        var cashAccounts = {
          labels: [],
          datasets: [{
            data: [],
            backgroundColor: ["rgba(136, 70, 218, 1)", "rgba(93, 59, 203, 1)", "rgba(35, 187, 27, 1)", "rgba(151, 220, 134, 1)", "rgba(51, 51, 51, 1)"]
          }]
        };
        var otherCashAccountsBalance = 0;
        this.get('cashAccountsData').forEach(function (cashAccount, index) {
          if (index <= 3) {
            cashAccounts.labels.push("".concat(cashAccount.name.substring(0, 15), ": $").concat(parseFloat(cashAccount.amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')));
            cashAccounts.datasets[0].data.push(parseFloat(cashAccount.amount));
          } else {
            otherCashAccountsBalance += parseFloat(cashAccount.amount);
          }
        });

        if (this.get('cashAccountsData.length') > 5) {
          cashAccounts.labels.push('Other Cash Accounts');
          cashAccounts.datasets[0].data.push(otherCashAccountsBalance);
        }

        return cashAccounts;
      }
    }),
    cashAccountsOptions: Ember.computed('cashAccounts', function () {
      if (this.get('cashAccounts')) {
        return {
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              tooltips: {
                callbacks: {
                  label: function label(tooltipItems, data) {
                    return tooltipItems.yLabel;
                  }
                }
              },
              ticks: {
                beginAtZero: true,
                callback: function callback(label, index, labels) {
                  return "$".concat(label.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'));
                }
              },
              gridLines: {
                display: false
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }]
          }
        };
      }
    })
  });

  _exports.default = _default;
});