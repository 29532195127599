define("account-berry-frontend/representative/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    first_name: _emberData.default.attr(),
    last_name: _emberData.default.attr(),
    display_name: _emberData.default.attr(),
    phone: _emberData.default.attr(),
    email: _emberData.default.attr(),
    company_id: _emberData.default.attr(),
    invoices: _emberData.default.attr(),
    transactions: _emberData.default.attr()
  });

  _exports.default = _default;
});