define("account-berry-frontend/components/quick-add/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r/SEueqh",
    "block": "{\"symbols\":[\"transaction\"],\"statements\":[[4,\"if\",[[20,[\"inCompany\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"quick-add-dropdown\"],[7],[0,\"\\n\"],[4,\"add-tooltip\",null,[[\"el\",\"title\"],[\".dropdown-toggle\",\"Create Transaction\"]],{\"statements\":[[0,\"      \"],[6,\"a\"],[9,\"class\",\"dropdown-toggle clickable\"],[9,\"type\",\"button\"],[9,\"id\",\"quickAdd\"],[3,\"action\",[[19,0,[]],\"toggleDropdown\"]],[7],[0,\"\\n        \"],[6,\"i\"],[9,\"class\",\"icon-android-add left-spacing\"],[7],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[6,\"ul\"],[10,\"class\",[26,[\"dropdown-menu \",[25,\"if\",[[20,[\"dropdownClosed\"]],\"hidden\"],null]]]],[9,\"aria-labelledby\",\"quickAdd\"],[7],[0,\"\\n      \"],[6,\"li\"],[9,\"class\",\"disabled greyed-out\"],[7],[0,\"Add Transaction\"],[8],[0,\"\\n      \"],[6,\"li\"],[9,\"role\",\"separator\"],[9,\"class\",\"divider\"],[7],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"options\"]]],null,{\"statements\":[[4,\"if\",[[19,1,[\"route\"]]],null,{\"statements\":[[0,\"          \"],[6,\"li\"],[7],[0,\"\\n            \"],[4,\"link-to\",[[19,1,[\"route\"]],[20,[\"companyId\"]]],[[\"invokeAction\"],[\"toggleDropdown\"]],{\"statements\":[[1,[19,1,[\"name\"]],false]],\"parameters\":[]},null],[0,\"\\n          \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[6,\"li\"],[9,\"class\",\"disabled greyed-out\"],[7],[1,[19,1,[\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/quick-add/template.hbs"
    }
  });

  _exports.default = _default;
});