define("account-berry-frontend/companies/show/reports/ar-aging-detail/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    cable: Ember.inject.service(),
    daysRange: 30,
    dueDate: true,
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    actions: {
      getArAgingDetail: function getArAgingDetail() {
        var _this2 = this;

        var data = JSON.stringify({
          report: {
            date: this.date,
            due_date: this.dueDate,
            days_range: this.daysRange
          },
          report_type: 'ar_aging_detail'
        });

        var _this$get = this.get('session.data.authenticated'),
            email = _this$get.email,
            token = _this$get.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

        var _this = this;

        consumer.createSubscription({
          channel: "ReportsChannel",
          room: "ArAgingDetail" + this.get('session.data.authenticated.id')
        }, {
          connected: function connected() {
            console.log('Connected to ReportsChannel-ArAgingDetail');

            _this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(_this.companyId, "/reports"), {
              method: 'POST',
              contentType: 'application/json',
              data: data,
              headers: headers
            }).then(function () {
              _this.get('applicationController').set('showLoading', true);
            }).catch(function () {
              _this.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
              });
            });
          },
          received: function received(data) {
            if (data.error) {
              _this2.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
              });
            } else {
              _this2.set('arAgingDetailData', data.report);

              consumer.disconnect();

              _this2.get('applicationController').set('showLoading', false);
            }
          },
          disconnected: function disconnected() {
            console.log("ReportsChannel-ApAgingDetail disconnected");
          }
        });
      },
      setDaysRange: function setDaysRange(daysRange) {
        this.set('daysRange', daysRange);

        if (this.date) {
          this.send('getArAgingDetail');
        }
      },
      setDateType: function setDateType(dueDate) {
        this.set('dueDate', dueDate.length > 0 ? true : false);

        if (this.date) {
          this.send('getArAgingDetail');
        }
      },
      print: function print() {
        Ember.$('#arAgingDetail').printThis();
      },
      csvExport: function csvExport() {
        var table = "<table id='arAgingDetailTable' style='width:100%; font-size: 14px'><thead>";
        table += "<tr><th></th><th>TYPE</th><th>DATE</th><th>NUMBER</th><th>CUSTOMER</th><th>OPEN BALANCE</th></thead>";
        table += "<tbody><tr><td>Current</td></tr>";
        this.get('arAgingDetailData.current.transactions').forEach(function (transaction) {
          table += "<tr><td></td><td>".concat(transaction.type, "</td>");
          table += "<td>".concat(transaction.date, "</td>");
          table += "<td>".concat(transaction.number, "</td>");
          table += "<td>".concat(transaction.name, "</td>");
          table += "<td>".concat(parseFloat(transaction.open_balance).toFixed(2), "</td></tr>");
        });
        table += "<tr><td>Total Current</td><td></td><td></td><td></td><td></td>";
        table += "<td>".concat(parseFloat(this.get('arAgingDetailData.current.total')).toFixed(2), "</td></tr>");
        table += "<tr></tr>";
        table += "<tr><td>0 - ".concat(this.daysRange, "</td></tr>");
        this.get('arAgingDetailData.first_section.transactions').forEach(function (transaction) {
          table += "<tr><td></td><td>".concat(transaction.type, "</td>");
          table += "<td>".concat(transaction.date, "</td>");
          table += "<td>".concat(transaction.number, "</td>");
          table += "<td>".concat(transaction.name, "</td>");
          table += "<td>".concat(parseFloat(transaction.open_balance).toFixed(2), "</td></tr>");
        });
        table += "<tr><td>Total 0 - ".concat(this.daysRange, "</td><td></td><td></td><td></td><td></td>");
        table += "<td>".concat(parseFloat(this.get('arAgingDetailData.first_section.total')).toFixed(2), "</td></tr>");
        table += "<tr></tr>";
        table += "<tr><td>".concat(this.daysRange + 1, " - ").concat(this.daysRange * 2, "</td></tr>");
        this.get('arAgingDetailData.second_section.transactions').forEach(function (transaction) {
          table += "<tr><td></td><td>".concat(transaction.type, "</td>");
          table += "<td>".concat(transaction.date, "</td>");
          table += "<td>".concat(transaction.number, "</td>");
          table += "<td>".concat(transaction.name, "</td>");
          table += "<td>".concat(parseFloat(transaction.open_balance).toFixed(2), "</td></tr>");
        });
        table += "<tr><td>Total ".concat(this.daysRange + 1, " - ").concat(this.daysRange * 2, "</td><td></td><td></td><td></td><td></td>");
        table += "<td>".concat(parseFloat(this.get('arAgingDetailData.second_section.total')).toFixed(2), "</td></tr>");
        table += "<tr></tr>";
        table += "<tr><td>Total ".concat(this.daysRange * 2 + 1, " - ").concat(this.daysRange * 3, "</td></tr>");
        this.get('arAgingDetailData.third_section.transactions').forEach(function (transaction) {
          table += "<tr><td></td><td>".concat(transaction.type, "</td>");
          table += "<td>".concat(transaction.date, "</td>");
          table += "<td>".concat(transaction.number, "</td>");
          table += "<td>".concat(transaction.name, "</td>");
          table += "<td>".concat(parseFloat(transaction.open_balance).toFixed(2), "</td></tr>");
        });
        table += "<tr><td>Total ".concat(this.daysRange * 2 + 1, " - ").concat(this.daysRange * 3, "</td><td></td><td></td><td></td><td></td>");
        table += "<td>".concat(parseFloat(this.get('arAgingDetailData.third_section.total')).toFixed(2), "</td></tr>");
        table += "<tr></tr>";
        table += "<tr><td>".concat(this.daysRange * 3 + 1, " ></td></tr>");
        this.get('arAgingDetailData.fourth_section.transactions').forEach(function (transaction) {
          table += "<tr><td></td><td>".concat(transaction.type, "</td>");
          table += "<td>".concat(transaction.date, "</td>");
          table += "<td>".concat(transaction.number, "</td>");
          table += "<td>".concat(transaction.name, "</td>");
          table += "<td>".concat(parseFloat(transaction.open_balance).toFixed(2), "</td></tr>");
        });
        table += "<tr><td>Total ".concat(this.daysRange * 3 + 1, " ></td><td></td><td></td><td></td><td></td>");
        table += "<td>".concat(parseFloat(this.get('arAgingDetailData.fourth_section.total')).toFixed(2), "</td></tr>");
        table += "<tr></tr>";
        table += "</tbody></table>";
        Ember.$('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        Ember.$('#arAgingDetailTable').table2CSV({
          delivery: 'download',
          filename: 'ArAgingDetailTable.csv'
        });
        Ember.$('#arAgingDetailTable').remove();
        Ember.$('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});