define("account-berry-frontend/components/sale-return-form/component", ["exports", "ember-cli-pagination/computed/paged-array", "account-berry-frontend/config/environment"], function (_exports, _pagedArray, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    refNumber: Ember.inject.service(),
    notify: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    totalItemQuantity: 0,
    totalItemSalePrices: 0,
    init: function init() {
      var _this = this;

      this._super();

      if (this.newReturn) {
        this.generateRefNumber();
      }

      this.store.findAll('customer').then(function (customers) {
        _this.set('customers', customers.toArray());
      });
      this.store.findAll('label').then(function (labels) {
        _this.set('labels', labels.toArray());
      });
      this.store.findAll('payee').then(function (payees) {
        _this.set('payees', payees.toArray());
      });
      this.store.findAll('item').then(function (items) {
        _this.set('items', items);
      });
      this.store.findAll('invoice').then(function (invoices) {
        _this.set('invoices', invoices.toArray());

        if (_this.get('return.customer_id')) {
          _this.setInvoices(_this.get('return.customer_id'));
        }
      });
      this.store.findAll('tax').then(function (taxes) {
        _this.set('taxes', taxes.toArray());
      });

      if (!this.return) {
        this.set('return', {
          net_amount: 0,
          total_amount: 0,
          tax_amount: 0,
          discount_amount: 0,
          freight_amount: 0
        });
      } else {
        this.calculateTotalItemAmounts();
      }
    },
    errorHandler: function errorHandler(response) {
      var errors = "An Unkown Error Occured.";

      if (response.errors && response.errors[0].status !== "500") {
        errors = response.errors.map(function (error) {
          return error;
        });
      } else if (response.payload.errors) {
        errors = response.payload.errors.map(function (error) {
          return error;
        });
      }

      this.get('notify').alert({
        html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
      });
    },
    generateRefNumber: function generateRefNumber() {
      var _this2 = this;

      var ajaxOptions = {
        url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/returns/number"),
        method: 'GET',
        contentType: 'application/json',
        successCallback: function successCallback(response) {
          var refNumber = _this2.get('refNumber').generate(response.sale_last_ref_number || "");

          _this2.set('return.ref_number', refNumber);
        },
        errorCallback: function errorCallback(response) {
          _this2.errorHandler(response);
        }
      };
      this.get('ajaxCall').request(ajaxOptions);
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['customers'];
    }),
    setInputSoldItems: function setInputSoldItems() {
      var _this3 = this;

      var returnedItems = this.get('return.returned_items');
      var invoice = this.get('return.invoice_id') ? this.store.peekRecord('invoice', this.get('return.invoice_id')) : null;
      var returnedItemsData = [];

      if (returnedItems && invoice) {
        returnedItems.forEach(function (returnedItem) {
          if (returnedItem.id) {
            var soldItem = invoice.get('sold_items').find(function (soldItem) {
              return soldItem.id === returnedItem.sold_item_id;
            });
            var taxes = [];

            if (returnedItem.taxes) {
              taxes = returnedItem.taxes.map(function (tax) {
                tax.selected = true;
                return tax;
              });
            }

            if (returnedItem) {
              var item = _this3.store.peekRecord('item', returnedItem.item_id);

              returnedItemsData.push({
                sold_item_id: soldItem ? returnedItem.sold_item_id : returnedItem.id,
                item_id: returnedItem.item_id,
                item: soldItem && soldItem.item_id ? _this3.store.peekRecord('item', soldItem.item_id) : item,
                so_number: soldItem ? soldItem.so_number : returnedItem.so_number,
                sold_quantity: soldItem ? soldItem.item_quantity : returnedItem.item_quantity,
                returned_quantity: returnedItem.returned_quantity,
                sale_price: soldItem ? soldItem.sale_price : returnedItem.sale_price,
                taxes: taxes
              });
            }
          }
        });
      }

      this.set('inputSoldItems', returnedItemsData);
    },
    invoicesObserver: Ember.observer('invoices', 'items', function () {
      this.setInputSoldItems();
      this.updateReturnAmounts();
    }),
    inputSoldItems: [],
    pagedInputSoldItems: (0, _pagedArray.default)('inputSoldItems', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    inputSoldItemsObserver: Ember.observer('inputSoldItems', function () {
      try {
        this.set('pagedInputSoldItems', (0, _pagedArray.default)('inputSoldItems', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    updateReturnAmounts: function updateReturnAmounts() {
      var netAmount = 0;
      var freightAmount = this.get('return.freight_amount') ? Number(this.get('return.freight_amount')) : 0;
      var discountAmount = this.get('return.discount_amount') ? Number(this.get('return.discount_amount')) : 0;
      var taxAmount = 0;
      this.get('inputSoldItems').forEach(function (soldItem) {
        if (soldItem.returned_quantity) {
          netAmount += Number(soldItem.sale_price) * Number(soldItem.returned_quantity);
        }

        if (soldItem.taxes) {
          soldItem.taxes.forEach(function (tax) {
            var percentage = Number(tax.percentage_decimal) ? Number(tax.percentage_decimal) : Number(tax.get('percentage_decimal'));
            taxAmount += Number(soldItem.sale_price) * (Number(soldItem.returned_quantity) || 0) * percentage;
          });
        }
      });
      var totalAmount = netAmount + freightAmount + taxAmount - discountAmount;
      this.setProperties({
        'return.net_amount': Number(netAmount).toFixed(2),
        'return.tax_amount': Number(taxAmount).toFixed(2),
        'return.total_amount': Number(totalAmount).toFixed(2)
      });
    },
    calculateTotalItemAmounts: function calculateTotalItemAmounts() {
      var inputSoldItems = this.get('inputSoldItems');
      var totalItemQuantity = 0;
      var totalItemSalePrices = 0;

      for (var i in inputSoldItems) {
        // Set total item quantity
        totalItemQuantity += inputSoldItems[i].returned_quantity ? Number(inputSoldItems[i].returned_quantity) : 0; // Set total item unit costs

        if (inputSoldItems[i].item) {
          totalItemSalePrices += inputSoldItems[i].sale_price ? Number(inputSoldItems[i].sale_price) : 0;
        }
      }

      this.setProperties({
        'totalItemQuantity': totalItemQuantity,
        'totalItemSalePrices': totalItemSalePrices.toFixed(2)
      });
    },
    inputOrderedItemObserver: Ember.observer('inputSoldItems.@each.sale_price', 'inputSoldItems.@each.returned_quantity', 'inputSoldItems.@each', 'inputSoldItems', function () {
      this.calculateTotalItemAmounts();
    }),
    customersObserver: Ember.observer('customers', function () {
      if (this.get('return.customer_id')) {
        this.set('customer', this.store.peekRecord('customer', this.get('return.customer_id')));
        this.setCustomerInfo(this.get('return.customer_id'));
      }
    }),
    freightAmountObserver: Ember.observer('return.freight_amount', function () {
      this.updateReturnAmounts();
    }),
    discountAmountObserver: Ember.observer('return.discount_amount', function () {
      this.updateReturnAmounts();
    }),
    setCustomerInfo: function setCustomerInfo(customerId) {
      var customer = this.get('customer') ? this.get('customer') : this.store.peekRecord('customer', customerId);

      if (customer) {
        var customerInfo = "".concat(customer.get('first_name'), " ").concat(customer.get('last_name'));

        if (customer.get('email')) {
          customerInfo += "\n".concat(customer.get('email'));
        }

        if (customer.get('website')) {
          customerInfo += "\n".concat(customer.get('website'));
        }

        customerInfo += "\n".concat(customer.get('company_name'), "\n");

        if (customer.get('address_line_one')) {
          customerInfo += "".concat(customer.get('address_line_one'), "\n");
        }

        if (customer.get('address_line_two')) {
          customerInfo += "".concat(customer.get('address_line_two'), "\n");
        }

        if (customer.get('city')) {
          customerInfo += "".concat(customer.get('city'));
        }

        if (customer.get('state')) {
          customerInfo += ", ".concat(customer.get('state'));
        }

        if (customer.get('country')) {
          customerInfo += ", ".concat(customer.get('country'));
        }

        if (customer.get('zipcode')) {
          customerInfo += ", ".concat(customer.get('zipcode'));
        }

        this.set('customerInfo', customerInfo);
      }
    },
    setInvoices: function setInvoices(customer_id) {
      var customerInvoices = this.get('invoices').map(function (invoice) {
        if (invoice.get('customer_id').toString() === customer_id.toString()) {
          return invoice;
        }
      });
      this.set('customerInvoices', customerInvoices.filter(Boolean));
    },
    actions: {
      updateReturnedDate: function updateReturnedDate(date) {
        this.set('return.returned_date', date.toISOString().slice(0, 10));
      },
      updateCustomer: function updateCustomer(customer) {
        this.set('customer', this.store.peekRecord('customer', customer.id));
        this.set('return.customer_id', customer.id);
        this.setCustomerInfo(customer.id);
        this.setInvoices(customer.id);
        this.setProperties({
          'return.invoice_id': "",
          'return.returned_items': []
        });
      },
      updateInvoice: function updateInvoice(value) {
        if (!value) {
          value = Ember.$("#selectInvoice")[0].value;
        }

        var selectedInvoice = this.store.peekRecord('invoice', value);
        this.set('return.invoice_id', value);
        this.set('return.returned_items', selectedInvoice.get('sold_items'));
        this.setInputSoldItems();
      },
      updateItemExtendedCost: function updateItemExtendedCost(index) {
        var soldItem = this.get('inputSoldItems')[index];

        if (soldItem.returned_quantity) {
          Ember.set(soldItem, 'extendedCost', Number(soldItem.returned_quantity * Number(soldItem.sale_price)).toFixed(2));
        }

        this.updateReturnAmounts();
      },
      create: function create() {
        var _this4 = this;

        var returnedItems = [];
        this.get('inputSoldItems').forEach(function (soldItem) {
          if (soldItem.returned_quantity && soldItem.returned_quantity > 0) {
            returnedItems.push({
              item_id: soldItem.item_id,
              returned_quantity: soldItem.returned_quantity,
              sold_item_id: soldItem.sold_item_id,
              tax_ids: soldItem.taxes.map(function (tax) {
                return tax.id;
              })
            });
          }
        });
        this.setProperties({
          'return.returned_items': returnedItems,
          'return.company_id': this.get('companyId')
        });
        var data = JSON.stringify({
          return: this.get('return')
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/returns"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            _this4.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success: </strong>&nbsp<span>Return Created</span></div>'
            });

            _this4.sendAction('reloadRoute');

            _this4.get('router').transitionTo('companies.show.customers.returns', _this4.companyId);
          },
          errorCallback: function errorCallback(response) {
            _this4.errorHandler(response);
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      update: function update() {
        var _this5 = this;

        var returnedItems = [];
        this.get('inputSoldItems').forEach(function (soldItem) {
          if (soldItem.returned_quantity && soldItem.returned_quantity > 0) {
            returnedItems.push({
              item_id: soldItem.item_id,
              returned_quantity: soldItem.returned_quantity,
              sold_item_id: soldItem.sold_item_id,
              tax_ids: soldItem.taxes.map(function (tax) {
                return tax.id;
              })
            });
          }
        });
        this.setProperties({
          'return.returned_items': returnedItems,
          'return.company_id': this.get('companyId')
        });
        this.store.findRecord('return', this.return.id, {
          backgroundReload: false
        }).then(function (returnRecord) {
          returnRecord.setProperties(_this5.return);
          returnRecord.set('sold_item', returnedItems);
          returnRecord.save().then(function () {
            _this5.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Return Updated</span></div>'
            });

            _this5.get('router').transitionTo('companies.show.customers.returns', _this5.companyId);
          }, function (response) {
            _this5.errorHandler(response);
          });
        });
      },
      setItemTaxes: function setItemTaxes(soldItem, itemTaxes) {
        Ember.set(soldItem, 'taxes', itemTaxes);
        this.updateReturnAmounts();
      },
      setLabels: function setLabels(index, labels) {
        this.set('return.label_ids', labels.map(function (label) {
          return label.id;
        }));
        this.set('return.labels', labels);
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      deleteReturn: function deleteReturn() {
        var _this6 = this;

        this.return.destroyRecord().then(function () {
          _this6.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Return Deleted</span></div>"
          });

          _this6.sendAction('reloadRoute');

          _this6.get('router').transitionTo('companies.show.customers.returns', _this6.companyId);
        }).catch(function (response) {
          _this6.errorHandler(response);
        });
      },
      cancel: function cancel() {
        if (Ember.typeOf(this.get('return')) === "instance") {
          this.get('return').rollbackAttributes();
        }

        if (this.accountId) {
          this.get('router').transitionTo('companies.show.accounts.transactions', this.companyId, this.accountId);
        } else {
          this.get('router').transitionTo('companies.show.customers.returns', this.companyId);
        }
      }
    }
  });

  _exports.default = _default;
});