define("account-berry-frontend/utils/naics-industries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = industries;

  function industries() {
    return {
      industries: [{
        "level": 2,
        "code": 11,
        "name": "Agriculture, Forestry, Fishing and Hunting",
        "notes": ""
      }, {
        "level": 3,
        "code": 111,
        "name": "Crop Production",
        "notes": ""
      }, {
        "level": 4,
        "code": 1111,
        "name": "Oilseed and Grain Farming",
        "notes": ""
      }, {
        "level": 5,
        "code": 11111,
        "name": "Soybean Farming",
        "notes": ""
      }, {
        "level": 6,
        "code": 111110,
        "name": "Soybean Farming",
        "notes": ""
      }, {
        "level": 5,
        "code": 11112,
        "name": "Oilseed (except Soybean) Farming",
        "notes": ""
      }, {
        "level": 6,
        "code": 111120,
        "name": "Oilseed (except Soybean) Farming",
        "notes": "US"
      }, {
        "level": 5,
        "code": 11113,
        "name": "Dry Pea and Bean Farming",
        "notes": ""
      }, {
        "level": 6,
        "code": 111130,
        "name": "Dry Pea and Bean Farming",
        "notes": "US"
      }, {
        "level": 5,
        "code": 11114,
        "name": "Wheat Farming",
        "notes": ""
      }, {
        "level": 6,
        "code": 111140,
        "name": "Wheat Farming",
        "notes": ""
      }, {
        "level": 5,
        "code": 11115,
        "name": "Corn Farming",
        "notes": ""
      }, {
        "level": 6,
        "code": 111150,
        "name": "Corn Farming",
        "notes": "US"
      }, {
        "level": 5,
        "code": 11116,
        "name": "Rice Farming",
        "notes": ""
      }, {
        "level": 6,
        "code": 111160,
        "name": "Rice Farming",
        "notes": ""
      }, {
        "level": 5,
        "code": 11119,
        "name": "Other Grain Farming",
        "notes": ""
      }, {
        "level": 6,
        "code": 111190,
        "name": "Other Grain Farming",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 1112,
        "name": "Vegetable and Melon Farming",
        "notes": ""
      }, {
        "level": 5,
        "code": 11121,
        "name": "Vegetable and Melon Farming",
        "notes": ""
      }, {
        "level": 6,
        "code": 111211,
        "name": "Potato Farming",
        "notes": "US"
      }, {
        "level": 6,
        "code": 111219,
        "name": "Other Vegetable (except Potato) and Melon Farming",
        "notes": "US"
      }, {
        "level": 4,
        "code": 1113,
        "name": "Fruit and Tree Nut Farming",
        "notes": ""
      }, {
        "level": 5,
        "code": 11131,
        "name": "Orange Groves",
        "notes": ""
      }, {
        "level": 6,
        "code": 111310,
        "name": "Orange Groves",
        "notes": ""
      }, {
        "level": 5,
        "code": 11132,
        "name": "Citrus (except Orange) Groves",
        "notes": ""
      }, {
        "level": 6,
        "code": 111320,
        "name": "Citrus (except Orange) Groves",
        "notes": "US"
      }, {
        "level": 5,
        "code": 11133,
        "name": "Non-Citrus Fruit and Tree Nut Farming",
        "notes": ""
      }, {
        "level": 6,
        "code": 111330,
        "name": "Non-Citrus Fruit and Tree Nut Farming",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 1114,
        "name": "Greenhouse, Nursery and Floriculture Production",
        "notes": ""
      }, {
        "level": 5,
        "code": 11141,
        "name": "Food Crops Grown Under Cover",
        "notes": ""
      }, {
        "level": 6,
        "code": 111411,
        "name": "Mushroom Production",
        "notes": "US"
      }, {
        "level": 6,
        "code": 111419,
        "name": "Other Food Crops Grown Under Cover",
        "notes": "US"
      }, {
        "level": 5,
        "code": 11142,
        "name": "Nursery and Floriculture Production",
        "notes": ""
      }, {
        "level": 6,
        "code": 111421,
        "name": "Nursery and Tree Production",
        "notes": "US"
      }, {
        "level": 6,
        "code": 111422,
        "name": "Floriculture Production",
        "notes": "US"
      }, {
        "level": 4,
        "code": 1119,
        "name": "Other Crop Farming",
        "notes": ""
      }, {
        "level": 5,
        "code": 11191,
        "name": "Tobacco Farming",
        "notes": ""
      }, {
        "level": 6,
        "code": 111910,
        "name": "Tobacco Farming",
        "notes": ""
      }, {
        "level": 5,
        "code": 11192,
        "name": "Cotton Farming",
        "notes": ""
      }, {
        "level": 6,
        "code": 111920,
        "name": "Cotton Farming",
        "notes": ""
      }, {
        "level": 5,
        "code": 11193,
        "name": "Sugar Cane Farming",
        "notes": ""
      }, {
        "level": 6,
        "code": 111930,
        "name": "Sugar Cane Farming",
        "notes": ""
      }, {
        "level": 5,
        "code": 11194,
        "name": "Hay Farming",
        "notes": ""
      }, {
        "level": 6,
        "code": 111940,
        "name": "Hay Farming",
        "notes": "US"
      }, {
        "level": 5,
        "code": 11199,
        "name": "All Other Crop Farming",
        "notes": ""
      }, {
        "level": 6,
        "code": 111993,
        "name": "Fruit and Vegetable Combination Farming",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 111994,
        "name": "Maple Syrup and Products Production",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 111999,
        "name": "All Other Miscellaneous Crop Farming",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 112,
        "name": "Animal Production",
        "notes": ""
      }, {
        "level": 4,
        "code": 1121,
        "name": "Cattle Ranching and Farming",
        "notes": ""
      }, {
        "level": 5,
        "code": 11211,
        "name": "Beef Cattle Ranching and Farming, including Feedlots",
        "notes": ""
      }, {
        "level": 6,
        "code": 112110,
        "name": "Beef Cattle Ranching and Farming, including Feedlots",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 11212,
        "name": "Dairy Cattle and Milk Production",
        "notes": ""
      }, {
        "level": 6,
        "code": 112120,
        "name": "Dairy Cattle and Milk Production",
        "notes": ""
      }, {
        "level": 4,
        "code": 1122,
        "name": "Hog and Pig Farming",
        "notes": ""
      }, {
        "level": 5,
        "code": 11221,
        "name": "Hog and Pig Farming",
        "notes": ""
      }, {
        "level": 6,
        "code": 112210,
        "name": "Hog and Pig Farming",
        "notes": "US"
      }, {
        "level": 4,
        "code": 1123,
        "name": "Poultry and Egg Production",
        "notes": ""
      }, {
        "level": 5,
        "code": 11231,
        "name": "Chicken Egg Production",
        "notes": ""
      }, {
        "level": 6,
        "code": 112310,
        "name": "Chicken Egg Production",
        "notes": "US"
      }, {
        "level": 5,
        "code": 11232,
        "name": "Broiler and Other Meat-Type Chicken Production",
        "notes": ""
      }, {
        "level": 6,
        "code": 112320,
        "name": "Broiler and Other Meat-Type Chicken Production",
        "notes": ""
      }, {
        "level": 5,
        "code": 11233,
        "name": "Turkey Production",
        "notes": ""
      }, {
        "level": 6,
        "code": 112330,
        "name": "Turkey Production",
        "notes": ""
      }, {
        "level": 5,
        "code": 11234,
        "name": "Poultry Hatcheries",
        "notes": ""
      }, {
        "level": 6,
        "code": 112340,
        "name": "Poultry Hatcheries",
        "notes": ""
      }, {
        "level": 5,
        "code": 11239,
        "name": "Other Poultry Production",
        "notes": ""
      }, {
        "level": 6,
        "code": 112391,
        "name": "Combination Poultry and Egg Production",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 112399,
        "name": "All Other Poultry Production",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 1124,
        "name": "Sheep and Goat Farming",
        "notes": ""
      }, {
        "level": 5,
        "code": 11241,
        "name": "Sheep Farming",
        "notes": ""
      }, {
        "level": 6,
        "code": 112410,
        "name": "Sheep Farming",
        "notes": "US"
      }, {
        "level": 5,
        "code": 11242,
        "name": "Goat Farming",
        "notes": ""
      }, {
        "level": 6,
        "code": 112420,
        "name": "Goat Farming",
        "notes": ""
      }, {
        "level": 4,
        "code": 1125,
        "name": "Aquaculture",
        "notes": ""
      }, {
        "level": 5,
        "code": 11251,
        "name": "Aquaculture",
        "notes": ""
      }, {
        "level": 6,
        "code": 112510,
        "name": "Aquaculture",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 1129,
        "name": "Other Animal Production",
        "notes": ""
      }, {
        "level": 5,
        "code": 11291,
        "name": "Apiculture",
        "notes": ""
      }, {
        "level": 6,
        "code": 112910,
        "name": "Apiculture",
        "notes": ""
      }, {
        "level": 5,
        "code": 11292,
        "name": "Horse and Other Equine Production",
        "notes": ""
      }, {
        "level": 6,
        "code": 112920,
        "name": "Horse and Other Equine Production",
        "notes": ""
      }, {
        "level": 5,
        "code": 11293,
        "name": "Fur-Bearing Animal and Rabbit Production",
        "notes": ""
      }, {
        "level": 6,
        "code": 112930,
        "name": "Fur-Bearing Animal and Rabbit Production",
        "notes": ""
      }, {
        "level": 5,
        "code": 11299,
        "name": "All Other Animal Production",
        "notes": ""
      }, {
        "level": 6,
        "code": 112991,
        "name": "Animal Combination Farming",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 112999,
        "name": "All Other Miscellaneous Animal Production",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 113,
        "name": "Forestry and Logging",
        "notes": ""
      }, {
        "level": 4,
        "code": 1131,
        "name": "Timber Tract Operations",
        "notes": ""
      }, {
        "level": 5,
        "code": 11311,
        "name": "Timber Tract Operations",
        "notes": ""
      }, {
        "level": 6,
        "code": 113110,
        "name": "Timber Tract Operations",
        "notes": ""
      }, {
        "level": 4,
        "code": 1132,
        "name": "Forest Nurseries and Gathering of Forest Products",
        "notes": ""
      }, {
        "level": 5,
        "code": 11321,
        "name": "Forest Nurseries and Gathering of Forest Products",
        "notes": ""
      }, {
        "level": 6,
        "code": 113210,
        "name": "Forest Nurseries and Gathering of Forest Products",
        "notes": "US"
      }, {
        "level": 4,
        "code": 1133,
        "name": "Logging",
        "notes": ""
      }, {
        "level": 5,
        "code": 11331,
        "name": "Logging",
        "notes": ""
      }, {
        "level": 6,
        "code": 113311,
        "name": "Logging (except Contract)",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 113312,
        "name": "Contract Logging",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 114,
        "name": "Fishing, Hunting and Trapping",
        "notes": ""
      }, {
        "level": 4,
        "code": 1141,
        "name": "Fishing",
        "notes": ""
      }, {
        "level": 5,
        "code": 11411,
        "name": "Fishing",
        "notes": ""
      }, {
        "level": 6,
        "code": 114113,
        "name": "Salt Water Fishing",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 114114,
        "name": "Inland Fishing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 1142,
        "name": "Hunting and Trapping",
        "notes": ""
      }, {
        "level": 5,
        "code": 11421,
        "name": "Hunting and Trapping",
        "notes": ""
      }, {
        "level": 6,
        "code": 114210,
        "name": "Hunting and Trapping",
        "notes": ""
      }, {
        "level": 3,
        "code": 115,
        "name": "Support Activities for Agriculture and Forestry",
        "notes": ""
      }, {
        "level": 4,
        "code": 1151,
        "name": "Support Activities for Crop Production",
        "notes": ""
      }, {
        "level": 5,
        "code": 11511,
        "name": "Support Activities for Crop Production",
        "notes": ""
      }, {
        "level": 6,
        "code": 115110,
        "name": "Support Activities for Crop Production",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 1152,
        "name": "Support Activities for Animal Production",
        "notes": ""
      }, {
        "level": 5,
        "code": 11521,
        "name": "Support Activities for Animal Production",
        "notes": ""
      }, {
        "level": 6,
        "code": 115210,
        "name": "Support Activities for Animal Production",
        "notes": ""
      }, {
        "level": 4,
        "code": 1153,
        "name": "Support Activities for Forestry",
        "notes": ""
      }, {
        "level": 5,
        "code": 11531,
        "name": "Support Activities for Forestry",
        "notes": ""
      }, {
        "level": 6,
        "code": 115310,
        "name": "Support Activities for Forestry",
        "notes": ""
      }, {
        "level": 2,
        "code": 21,
        "name": "Mining, Quarrying, and Oil and Gas Extraction",
        "notes": ""
      }, {
        "level": 3,
        "code": 211,
        "name": "Oil and Gas Extraction",
        "notes": ""
      }, {
        "level": 4,
        "code": 2111,
        "name": "Oil and Gas Extraction",
        "notes": ""
      }, {
        "level": 5,
        "code": 21111,
        "name": "Oil and Gas Extraction",
        "notes": ""
      }, {
        "level": 6,
        "code": 211113,
        "name": "Conventional Oil and Gas Extraction",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 211114,
        "name": "Non-Conventional Oil Extraction",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 212,
        "name": "Mining and Quarrying (except Oil and Gas)",
        "notes": ""
      }, {
        "level": 4,
        "code": 2121,
        "name": "Coal Mining",
        "notes": ""
      }, {
        "level": 5,
        "code": 21211,
        "name": "Coal Mining",
        "notes": ""
      }, {
        "level": 6,
        "code": 212114,
        "name": "Bituminous Coal Mining",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 212115,
        "name": "Subbituminous Coal Mining",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 212116,
        "name": "Lignite Coal Mining",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 2122,
        "name": "Metal Ore Mining",
        "notes": ""
      }, {
        "level": 5,
        "code": 21221,
        "name": "Iron Ore Mining",
        "notes": ""
      }, {
        "level": 6,
        "code": 212210,
        "name": "Iron Ore Mining",
        "notes": ""
      }, {
        "level": 5,
        "code": 21222,
        "name": "Gold and Silver Ore Mining",
        "notes": ""
      }, {
        "level": 6,
        "code": 212220,
        "name": "Gold and Silver Ore Mining",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 21223,
        "name": "Copper, Nickel, Lead and Zinc Ore Mining",
        "notes": ""
      }, {
        "level": 6,
        "code": 212231,
        "name": "Lead-Zinc Ore Mining",
        "notes": "US"
      }, {
        "level": 6,
        "code": 212232,
        "name": "Nickel-Copper Ore Mining",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 212233,
        "name": "Copper-Zinc Ore Mining",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 21229,
        "name": "Other Metal Ore Mining",
        "notes": ""
      }, {
        "level": 6,
        "code": 212291,
        "name": "Uranium Ore Mining",
        "notes": "US"
      }, {
        "level": 6,
        "code": 212299,
        "name": "All Other Metal Ore Mining",
        "notes": "US"
      }, {
        "level": 4,
        "code": 2123,
        "name": "Non-Metallic Mineral Mining and Quarrying",
        "notes": ""
      }, {
        "level": 5,
        "code": 21231,
        "name": "Stone Mining and Quarrying",
        "notes": ""
      }, {
        "level": 6,
        "code": 212314,
        "name": "Granite Mining and Quarrying",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 212315,
        "name": "Limestone Mining and Quarrying",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 212316,
        "name": "Marble Mining and Quarrying",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 212317,
        "name": "Sandstone Mining and Quarrying",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 21232,
        "name": "Sand, Gravel, Clay, and Ceramic and Refractory Minerals Mining and Quarrying",
        "notes": ""
      }, {
        "level": 6,
        "code": 212323,
        "name": "Sand and Gravel Mining and Quarrying",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 212326,
        "name": "Shale, Clay and Refractory Mineral Mining and Quarrying",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 21239,
        "name": "Other Non-Metallic Mineral Mining and Quarrying",
        "notes": ""
      }, {
        "level": 6,
        "code": 212392,
        "name": "Diamond Mining",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 212393,
        "name": "Salt Mining",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 212394,
        "name": "Asbestos Mining",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 212395,
        "name": "Gypsum Mining",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 212396,
        "name": "Potash Mining",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 212397,
        "name": "Peat Extraction",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 212398,
        "name": "All Other Non-Metallic Mineral Mining and Quarrying",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 213,
        "name": "Support Activities for Mining and Oil and Gas Extraction",
        "notes": ""
      }, {
        "level": 4,
        "code": 2131,
        "name": "Support Activities for Mining and Oil and Gas Extraction",
        "notes": ""
      }, {
        "level": 5,
        "code": 21311,
        "name": "Support Activities for Mining and Oil and Gas Extraction",
        "notes": ""
      }, {
        "level": 6,
        "code": 213111,
        "name": "Oil and Gas Contract Drilling",
        "notes": ""
      }, {
        "level": 6,
        "code": 213117,
        "name": "Contract Drilling (except Oil and Gas)",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 213118,
        "name": "Services to Oil and Gas Extraction",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 213119,
        "name": "Other Support Activities for Mining",
        "notes": "CAN"
      }, {
        "level": 2,
        "code": 22,
        "name": "Utilities",
        "notes": ""
      }, {
        "level": 3,
        "code": 221,
        "name": "Utilities",
        "notes": "US"
      }, {
        "level": 4,
        "code": 2211,
        "name": "Electric Power Generation, Transmission and Distribution",
        "notes": ""
      }, {
        "level": 5,
        "code": 22111,
        "name": "Electric Power Generation",
        "notes": "US"
      }, {
        "level": 6,
        "code": 221111,
        "name": "Hydro-Electric Power Generation",
        "notes": "US"
      }, {
        "level": 6,
        "code": 221112,
        "name": "Fossil-Fuel Electric Power Generation",
        "notes": "US"
      }, {
        "level": 6,
        "code": 221113,
        "name": "Nuclear Electric Power Generation",
        "notes": "US"
      }, {
        "level": 6,
        "code": 221119,
        "name": "Other Electric Power Generation",
        "notes": "US"
      }, {
        "level": 5,
        "code": 22112,
        "name": "Electric Power Transmission, Control and Distribution",
        "notes": "US"
      }, {
        "level": 6,
        "code": 221121,
        "name": "Electric Bulk Power Transmission and Control",
        "notes": "US"
      }, {
        "level": 6,
        "code": 221122,
        "name": "Electric Power Distribution",
        "notes": "US"
      }, {
        "level": 4,
        "code": 2212,
        "name": "Natural Gas Distribution",
        "notes": "US"
      }, {
        "level": 5,
        "code": 22121,
        "name": "Natural Gas Distribution",
        "notes": "US"
      }, {
        "level": 6,
        "code": 221210,
        "name": "Natural Gas Distribution",
        "notes": "US"
      }, {
        "level": 4,
        "code": 2213,
        "name": "Water, Sewage and Other Systems",
        "notes": "US"
      }, {
        "level": 5,
        "code": 22131,
        "name": "Water Supply and Irrigation Systems",
        "notes": "US"
      }, {
        "level": 6,
        "code": 221310,
        "name": "Water Supply and Irrigation Systems",
        "notes": "US"
      }, {
        "level": 5,
        "code": 22132,
        "name": "Sewage Treatment Facilities",
        "notes": "US"
      }, {
        "level": 6,
        "code": 221320,
        "name": "Sewage Treatment Facilities",
        "notes": "US"
      }, {
        "level": 5,
        "code": 22133,
        "name": "Steam and Air-Conditioning Supply",
        "notes": "US"
      }, {
        "level": 6,
        "code": 221330,
        "name": "Steam and Air-Conditioning Supply",
        "notes": "US"
      }, {
        "level": 2,
        "code": 23,
        "name": "Construction",
        "notes": ""
      }, {
        "level": 3,
        "code": 236,
        "name": "Construction of Buildings",
        "notes": ""
      }, {
        "level": 4,
        "code": 2361,
        "name": "Residential Building Construction",
        "notes": ""
      }, {
        "level": 5,
        "code": 23611,
        "name": "Residential Building Construction",
        "notes": ""
      }, {
        "level": 6,
        "code": 236110,
        "name": "Residential Building Construction",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 2362,
        "name": "Non-residential Building Construction",
        "notes": ""
      }, {
        "level": 5,
        "code": 23621,
        "name": "Industrial Building and Structure Construction",
        "notes": ""
      }, {
        "level": 6,
        "code": 236210,
        "name": "Industrial Building and Structure Construction",
        "notes": "US"
      }, {
        "level": 5,
        "code": 23622,
        "name": "Commercial and Institutional Building Construction",
        "notes": ""
      }, {
        "level": 6,
        "code": 236220,
        "name": "Commercial and Institutional Building Construction",
        "notes": "US"
      }, {
        "level": 3,
        "code": 237,
        "name": "Heavy and Civil Engineering Construction",
        "notes": ""
      }, {
        "level": 4,
        "code": 2371,
        "name": "Utility System Construction",
        "notes": ""
      }, {
        "level": 5,
        "code": 23711,
        "name": "Water and Sewer Line and Related Structures Construction",
        "notes": ""
      }, {
        "level": 6,
        "code": 237110,
        "name": "Water and Sewer Line and Related Structures Construction",
        "notes": "US"
      }, {
        "level": 5,
        "code": 23712,
        "name": "Oil and Gas Pipeline and Related Structures Construction",
        "notes": ""
      }, {
        "level": 6,
        "code": 237120,
        "name": "Oil and Gas Pipeline and Related Structures Construction",
        "notes": "US"
      }, {
        "level": 5,
        "code": 23713,
        "name": "Power and Communication Line and Related Structures Construction",
        "notes": ""
      }, {
        "level": 6,
        "code": 237130,
        "name": "Power and Communication Line and Related Structures Construction",
        "notes": "US"
      }, {
        "level": 4,
        "code": 2372,
        "name": "Land Subdivision",
        "notes": ""
      }, {
        "level": 5,
        "code": 23721,
        "name": "Land Subdivision",
        "notes": ""
      }, {
        "level": 6,
        "code": 237210,
        "name": "Land Subdivision",
        "notes": "US"
      }, {
        "level": 4,
        "code": 2373,
        "name": "Highway, Street and Bridge Construction",
        "notes": ""
      }, {
        "level": 5,
        "code": 23731,
        "name": "Highway, Street and Bridge Construction",
        "notes": ""
      }, {
        "level": 6,
        "code": 237310,
        "name": "Highway, Street and Bridge Construction",
        "notes": "US"
      }, {
        "level": 4,
        "code": 2379,
        "name": "Other Heavy and Civil Engineering Construction",
        "notes": ""
      }, {
        "level": 5,
        "code": 23799,
        "name": "Other Heavy and Civil Engineering Construction",
        "notes": ""
      }, {
        "level": 6,
        "code": 237990,
        "name": "Other Heavy and Civil Engineering Construction",
        "notes": "US"
      }, {
        "level": 3,
        "code": 238,
        "name": "Specialty Trade Contractors",
        "notes": ""
      }, {
        "level": 4,
        "code": 2381,
        "name": "Foundation, Structure, and Building Exterior Contractors",
        "notes": ""
      }, {
        "level": 5,
        "code": 23811,
        "name": "Poured Concrete Foundation and Structure Contractors",
        "notes": ""
      }, {
        "level": 6,
        "code": 238110,
        "name": "Poured Concrete Foundation and Structure Contractors",
        "notes": ""
      }, {
        "level": 5,
        "code": 23812,
        "name": "Structural Steel and Precast Concrete Contractors",
        "notes": ""
      }, {
        "level": 6,
        "code": 238120,
        "name": "Structural Steel and Precast Concrete Contractors",
        "notes": "US"
      }, {
        "level": 5,
        "code": 23813,
        "name": "Framing Contractors",
        "notes": "US"
      }, {
        "level": 6,
        "code": 238130,
        "name": "Framing Contractors",
        "notes": "US"
      }, {
        "level": 5,
        "code": 23814,
        "name": "Masonry Contractors",
        "notes": "US"
      }, {
        "level": 6,
        "code": 238140,
        "name": "Masonry Contractors",
        "notes": "US"
      }, {
        "level": 5,
        "code": 23815,
        "name": "Glass and Glazing Contractors",
        "notes": "US"
      }, {
        "level": 6,
        "code": 238150,
        "name": "Glass and Glazing Contractors",
        "notes": "US"
      }, {
        "level": 5,
        "code": 23816,
        "name": "Roofing Contractors",
        "notes": "US"
      }, {
        "level": 6,
        "code": 238160,
        "name": "Roofing Contractors",
        "notes": "US"
      }, {
        "level": 5,
        "code": 23817,
        "name": "Siding Contractors",
        "notes": "US"
      }, {
        "level": 6,
        "code": 238170,
        "name": "Siding Contractors",
        "notes": "US"
      }, {
        "level": 5,
        "code": 23819,
        "name": "Other Foundation, Structure and Building Exterior Contractors",
        "notes": "US"
      }, {
        "level": 6,
        "code": 238190,
        "name": "Other Foundation, Structure and Building Exterior Contractors",
        "notes": "US"
      }, {
        "level": 4,
        "code": 2382,
        "name": "Building Equipment Contractors",
        "notes": ""
      }, {
        "level": 5,
        "code": 23821,
        "name": "Electrical Contractors and Other Wiring Installation Contractors",
        "notes": ""
      }, {
        "level": 6,
        "code": 238210,
        "name": "Electrical Contractors and Other Wiring Installation Contractors",
        "notes": ""
      }, {
        "level": 5,
        "code": 23822,
        "name": "Plumbing, Heating and Air-Conditioning Contractors",
        "notes": ""
      }, {
        "level": 6,
        "code": 238220,
        "name": "Plumbing, Heating and Air-Conditioning Contractors",
        "notes": "US"
      }, {
        "level": 5,
        "code": 23829,
        "name": "Other Building Equipment Contractors",
        "notes": ""
      }, {
        "level": 6,
        "code": 238291,
        "name": "Elevator and Escalator Installation Contractors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 238299,
        "name": "All Other Building Equipment Contractors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 2383,
        "name": "Building Finishing Contractors",
        "notes": ""
      }, {
        "level": 5,
        "code": 23831,
        "name": "Drywall and Insulation Contractors",
        "notes": ""
      }, {
        "level": 6,
        "code": 238310,
        "name": "Drywall and Insulation Contractors",
        "notes": "US"
      }, {
        "level": 5,
        "code": 23832,
        "name": "Painting and Wall Covering Contractors",
        "notes": ""
      }, {
        "level": 6,
        "code": 238320,
        "name": "Painting and Wall Covering Contractors",
        "notes": ""
      }, {
        "level": 5,
        "code": 23833,
        "name": "Flooring Contractors",
        "notes": ""
      }, {
        "level": 6,
        "code": 238330,
        "name": "Flooring Contractors",
        "notes": ""
      }, {
        "level": 5,
        "code": 23834,
        "name": "Tile and Terrazzo Contractors",
        "notes": ""
      }, {
        "level": 6,
        "code": 238340,
        "name": "Tile and Terrazzo Contractors",
        "notes": ""
      }, {
        "level": 5,
        "code": 23835,
        "name": "Finish Carpentry Contractors",
        "notes": ""
      }, {
        "level": 6,
        "code": 238350,
        "name": "Finish Carpentry Contractors",
        "notes": ""
      }, {
        "level": 5,
        "code": 23839,
        "name": "Other Building Finishing Contractors",
        "notes": ""
      }, {
        "level": 6,
        "code": 238390,
        "name": "Other Building Finishing Contractors",
        "notes": ""
      }, {
        "level": 4,
        "code": 2389,
        "name": "Other Specialty Trade Contractors",
        "notes": ""
      }, {
        "level": 5,
        "code": 23891,
        "name": "Site Preparation Contractors",
        "notes": ""
      }, {
        "level": 6,
        "code": 238910,
        "name": "Site Preparation Contractors",
        "notes": ""
      }, {
        "level": 5,
        "code": 23899,
        "name": "All Other Specialty Trade Contractors",
        "notes": ""
      }, {
        "level": 6,
        "code": 238990,
        "name": "All Other Specialty Trade Contractors",
        "notes": "US"
      }, {
        "level": 5,
        "code": 31,
        "name": "Manufacturing",
        "notes": ""
      }, {
        "level": 3,
        "code": 311,
        "name": "Food Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3111,
        "name": "Animal Food Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 31111,
        "name": "Animal Food Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 311111,
        "name": "Dog and Cat Food Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 311119,
        "name": "Other Animal Food Manufacturing",
        "notes": "US"
      }, {
        "level": 4,
        "code": 3112,
        "name": "Grain and Oilseed Milling",
        "notes": ""
      }, {
        "level": 5,
        "code": 31121,
        "name": "Flour Milling and Malt Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 311211,
        "name": "Flour Milling",
        "notes": "US"
      }, {
        "level": 6,
        "code": 311214,
        "name": "Rice Milling and Malt Manufacturing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 31122,
        "name": "Starch and Vegetable Fat and Oil Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 311221,
        "name": "Wet Corn Milling",
        "notes": "US"
      }, {
        "level": 6,
        "code": 311224,
        "name": "Oilseed Processing",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 311225,
        "name": "Fat and Oil Refining and Blending",
        "notes": "US"
      }, {
        "level": 5,
        "code": 31123,
        "name": "Breakfast Cereal Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 311230,
        "name": "Breakfast Cereal Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3113,
        "name": "Sugar and Confectionery Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 31131,
        "name": "Sugar Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 311310,
        "name": "Sugar Manufacturing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 31132,
        "name": "Chocolate and Confectionery Manufacturing from Cacao Beans",
        "notes": ""
      }, {
        "level": 6,
        "code": 311320,
        "name": "Chocolate and Confectionery Manufacturing from Cacao Beans",
        "notes": ""
      }, {
        "level": 5,
        "code": 31133,
        "name": "Confectionery Manufacturing from Purchased Chocolate",
        "notes": ""
      }, {
        "level": 6,
        "code": 311330,
        "name": "Confectionery Manufacturing from Purchased Chocolate",
        "notes": ""
      }, {
        "level": 5,
        "code": 31134,
        "name": "Non-Chocolate Confectionery Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 311340,
        "name": "Non-Chocolate Confectionery Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3114,
        "name": "Fruit and Vegetable Preserving and Specialty Food Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 31141,
        "name": "Frozen Food Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 311410,
        "name": "Frozen Food Manufacturing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 31142,
        "name": "Fruit and Vegetable Canning, Pickling and Drying",
        "notes": ""
      }, {
        "level": 6,
        "code": 311420,
        "name": "Fruit and Vegetable Canning, Pickling and Drying",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3115,
        "name": "Dairy Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 31151,
        "name": "Dairy Product (except Frozen) Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 311511,
        "name": "Fluid Milk Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 311515,
        "name": "Butter, Cheese, and Dry and Condensed Dairy Product Manufacturing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 31152,
        "name": "Ice Cream and Frozen Dessert Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 311520,
        "name": "Ice Cream and Frozen Dessert Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3116,
        "name": "Meat Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 31161,
        "name": "Animal Slaughtering and Processing",
        "notes": ""
      }, {
        "level": 6,
        "code": 311611,
        "name": "Animal (except Poultry) Slaughtering",
        "notes": "US"
      }, {
        "level": 6,
        "code": 311614,
        "name": "Rendering and Meat Processing from Carcasses",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 311615,
        "name": "Poultry Processing",
        "notes": "US"
      }, {
        "level": 4,
        "code": 3117,
        "name": "Seafood Product Preparation and Packaging",
        "notes": ""
      }, {
        "level": 5,
        "code": 31171,
        "name": "Seafood Product Preparation and Packaging",
        "notes": ""
      }, {
        "level": 6,
        "code": 311710,
        "name": "Seafood Product Preparation and Packaging",
        "notes": "MEX"
      }, {
        "level": 4,
        "code": 3118,
        "name": "Bakeries and Tortilla Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 31181,
        "name": "Bread and Bakery Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 311811,
        "name": "Retail Bakeries",
        "notes": "US"
      }, {
        "level": 6,
        "code": 311814,
        "name": "Commercial Bakeries and Frozen Bakery Product Manufacturing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 31182,
        "name": "Cookie, Cracker and Pasta Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 311821,
        "name": "Cookie and Cracker Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 311822,
        "name": "Flour Mixes and Dough Manufacturing from Purchased Flour",
        "notes": "US"
      }, {
        "level": 6,
        "code": 311823,
        "name": "Dry Pasta Manufacturing",
        "notes": "US"
      }, {
        "level": 5,
        "code": 31183,
        "name": "Tortilla Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 311830,
        "name": "Tortilla Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3119,
        "name": "Other Food Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 31191,
        "name": "Snack Food Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 311911,
        "name": "Roasted Nut and Peanut Butter Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 311919,
        "name": "Other Snack Food Manufacturing",
        "notes": "US"
      }, {
        "level": 5,
        "code": 31192,
        "name": "Coffee and Tea Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 311920,
        "name": "Coffee and Tea Manufacturing",
        "notes": "US"
      }, {
        "level": 5,
        "code": 31193,
        "name": "Flavouring Syrup and Concentrate Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 311930,
        "name": "Flavouring Syrup and Concentrate Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 31194,
        "name": "Seasoning and Dressing Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 311940,
        "name": "Seasoning and Dressing Manufacturing",
        "notes": "MEX"
      }, {
        "level": 5,
        "code": 31199,
        "name": "All Other Food Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 311990,
        "name": "All Other Food Manufacturing",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 312,
        "name": "Beverage and Tobacco Product Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3121,
        "name": "Beverage Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 31211,
        "name": "Soft Drink and Ice Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 312110,
        "name": "Soft Drink and Ice Manufacturing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 31212,
        "name": "Breweries",
        "notes": ""
      }, {
        "level": 6,
        "code": 312120,
        "name": "Breweries",
        "notes": ""
      }, {
        "level": 5,
        "code": 31213,
        "name": "Wineries",
        "notes": ""
      }, {
        "level": 6,
        "code": 312130,
        "name": "Wineries",
        "notes": "US"
      }, {
        "level": 5,
        "code": 31214,
        "name": "Distilleries",
        "notes": ""
      }, {
        "level": 6,
        "code": 312140,
        "name": "Distilleries",
        "notes": "US"
      }, {
        "level": 4,
        "code": 3122,
        "name": "Tobacco Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 31221,
        "name": "Tobacco Stemming and Redrying",
        "notes": ""
      }, {
        "level": 6,
        "code": 312210,
        "name": "Tobacco Stemming and Redrying",
        "notes": ""
      }, {
        "level": 5,
        "code": 31222,
        "name": "Tobacco Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 312220,
        "name": "Tobacco Product Manufacturing",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 313,
        "name": "Textile Mills",
        "notes": ""
      }, {
        "level": 4,
        "code": 3131,
        "name": "Fibre, Yarn and Thread Mills",
        "notes": ""
      }, {
        "level": 5,
        "code": 31311,
        "name": "Fibre, Yarn and Thread Mills",
        "notes": ""
      }, {
        "level": 6,
        "code": 313110,
        "name": "Fibre, Yarn and Thread Mills",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3132,
        "name": "Fabric Mills",
        "notes": ""
      }, {
        "level": 5,
        "code": 31321,
        "name": "Broad-Woven Fabric Mills",
        "notes": ""
      }, {
        "level": 6,
        "code": 313210,
        "name": "Broad-Woven Fabric Mills",
        "notes": ""
      }, {
        "level": 5,
        "code": 31322,
        "name": "Narrow Fabric Mills and Schiffli Machine Embroidery",
        "notes": ""
      }, {
        "level": 6,
        "code": 313220,
        "name": "Narrow Fabric Mills and Schiffli Machine Embroidery",
        "notes": "MEX"
      }, {
        "level": 5,
        "code": 31323,
        "name": "Nonwoven Fabric Mills",
        "notes": ""
      }, {
        "level": 6,
        "code": 313230,
        "name": "Nonwoven Fabric Mills",
        "notes": ""
      }, {
        "level": 5,
        "code": 31324,
        "name": "Knit Fabric Mills",
        "notes": ""
      }, {
        "level": 6,
        "code": 313240,
        "name": "Knit Fabric Mills",
        "notes": "MEX"
      }, {
        "level": 4,
        "code": 3133,
        "name": "Textile and Fabric Finishing and Fabric Coating",
        "notes": ""
      }, {
        "level": 5,
        "code": 31331,
        "name": "Textile and Fabric Finishing",
        "notes": ""
      }, {
        "level": 6,
        "code": 313310,
        "name": "Textile and Fabric Finishing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 31332,
        "name": "Fabric Coating",
        "notes": ""
      }, {
        "level": 6,
        "code": 313320,
        "name": "Fabric Coating",
        "notes": ""
      }, {
        "level": 3,
        "code": 314,
        "name": "Textile Product Mills",
        "notes": ""
      }, {
        "level": 4,
        "code": 3141,
        "name": "Textile Furnishings Mills",
        "notes": ""
      }, {
        "level": 5,
        "code": 31411,
        "name": "Carpet and Rug Mills",
        "notes": ""
      }, {
        "level": 6,
        "code": 314110,
        "name": "Carpet and Rug Mills",
        "notes": ""
      }, {
        "level": 5,
        "code": 31412,
        "name": "Curtain and Linen Mills",
        "notes": ""
      }, {
        "level": 6,
        "code": 314120,
        "name": "Curtain and Linen Mills",
        "notes": "MEX"
      }, {
        "level": 4,
        "code": 3149,
        "name": "Other Textile Product Mills",
        "notes": ""
      }, {
        "level": 5,
        "code": 31491,
        "name": "Textile Bag and Canvas Mills",
        "notes": ""
      }, {
        "level": 6,
        "code": 314910,
        "name": "Textile Bag and Canvas Mills",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 31499,
        "name": "All Other Textile Product Mills",
        "notes": ""
      }, {
        "level": 6,
        "code": 314990,
        "name": "All Other Textile Product Mills",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 315,
        "name": "Clothing Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3151,
        "name": "Clothing Knitting Mills",
        "notes": ""
      }, {
        "level": 5,
        "code": 31511,
        "name": "Hosiery and Sock Mills",
        "notes": ""
      }, {
        "level": 6,
        "code": 315110,
        "name": "Hosiery and Sock Mills",
        "notes": "MEX"
      }, {
        "level": 5,
        "code": 31519,
        "name": "Other Clothing Knitting Mills",
        "notes": ""
      }, {
        "level": 6,
        "code": 315190,
        "name": "Other Clothing Knitting Mills",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3152,
        "name": "Cut and Sew Clothing Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 31521,
        "name": "Cut and Sew Clothing Contracting",
        "notes": "US"
      }, {
        "level": 6,
        "code": 315210,
        "name": "Cut and Sew Clothing Contracting",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 31522,
        "name": "Men's and Boys' Cut and Sew Clothing Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 315221,
        "name": "Men's and Boys' Cut and Sew Underwear and Nightwear Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 315222,
        "name": "Men's and Boys' Cut and Sew Suit, Coat and Overcoat Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 315226,
        "name": "Men's and Boys' Cut and Sew Shirt Manufacturing",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 315227,
        "name": "Men's and Boys' Cut and Sew Trouser, Slack and Jean Manufacturing",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 315229,
        "name": "Other Men's and Boys' Cut and Sew Clothing Manufacturing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 31523,
        "name": "Women's and Girls' Cut and Sew Clothing Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 315231,
        "name": "Women's and Girls' Cut and Sew Lingerie, Loungewear and Nightwear Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 315232,
        "name": "Women's and Girls' Cut and Sew Blouse and Shirt Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 315233,
        "name": "Women's and Girls' Cut and Sew Dress Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 315234,
        "name": "Women's and Girls' Cut and Sew Suit, Coat, Tailored Jacket and Skirt Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 315239,
        "name": "Other Women's and Girls' Cut and Sew Clothing Manufacturing",
        "notes": "US"
      }, {
        "level": 5,
        "code": 31529,
        "name": "Other Cut and Sew Clothing Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 315291,
        "name": "Infants' Cut and Sew Clothing Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 315292,
        "name": "Fur and Leather Clothing Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 315299,
        "name": "All Other Cut and Sew Clothing Manufacturing",
        "notes": "US"
      }, {
        "level": 4,
        "code": 3159,
        "name": "Clothing Accessories and Other Clothing Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 31599,
        "name": "Clothing Accessories and Other Clothing Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 315990,
        "name": "Clothing Accessories and Other Clothing Manufacturing",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 316,
        "name": "Leather and Allied Product Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3161,
        "name": "Leather and Hide Tanning and Finishing",
        "notes": ""
      }, {
        "level": 5,
        "code": 31611,
        "name": "Leather and Hide Tanning and Finishing",
        "notes": ""
      }, {
        "level": 6,
        "code": 316110,
        "name": "Leather and Hide Tanning and Finishing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3162,
        "name": "Footwear Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 31621,
        "name": "Footwear Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 316210,
        "name": "Footwear Manufacturing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3169,
        "name": "Other Leather and Allied Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 31699,
        "name": "Other Leather and Allied Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 316990,
        "name": "Other Leather and Allied Product Manufacturing",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 321,
        "name": "Wood Product Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3211,
        "name": "Sawmills and Wood Preservation",
        "notes": ""
      }, {
        "level": 5,
        "code": 32111,
        "name": "Sawmills and Wood Preservation",
        "notes": ""
      }, {
        "level": 6,
        "code": 321111,
        "name": "Sawmills (except Shingle and Shake Mills)",
        "notes": "MEX"
      }, {
        "level": 6,
        "code": 321112,
        "name": "Shingle and Shake Mills",
        "notes": "MEX"
      }, {
        "level": 6,
        "code": 321114,
        "name": "Wood Preservation",
        "notes": "US"
      }, {
        "level": 4,
        "code": 3212,
        "name": "Veneer, Plywood and Engineered Wood Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32121,
        "name": "Veneer, Plywood and Engineered Wood Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 321211,
        "name": "Hardwood Veneer and Plywood Mills",
        "notes": "US"
      }, {
        "level": 6,
        "code": 321212,
        "name": "Softwood Veneer and Plywood Mills",
        "notes": "US"
      }, {
        "level": 6,
        "code": 321215,
        "name": "Structural Wood Product Manufacturing",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 321216,
        "name": "Particle Board and Fibreboard Mills",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 321217,
        "name": "Waferboard Mills",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3219,
        "name": "Other Wood Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32191,
        "name": "Millwork",
        "notes": ""
      }, {
        "level": 6,
        "code": 321911,
        "name": "Wood Window and Door Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 321919,
        "name": "Other Millwork",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 32192,
        "name": "Wood Container and Pallet Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 321920,
        "name": "Wood Container and Pallet Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32199,
        "name": "All Other Wood Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 321991,
        "name": "Manufactured (Mobile) Home Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 321992,
        "name": "Prefabricated Wood Building Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 321999,
        "name": "All Other Miscellaneous Wood Product Manufacturing",
        "notes": "US"
      }, {
        "level": 3,
        "code": 322,
        "name": "Paper Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3221,
        "name": "Pulp, Paper and Paperboard Mills",
        "notes": ""
      }, {
        "level": 5,
        "code": 32211,
        "name": "Pulp Mills",
        "notes": ""
      }, {
        "level": 6,
        "code": 322111,
        "name": "Mechanical Pulp Mills",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 322112,
        "name": "Chemical Pulp Mills",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 32212,
        "name": "Paper Mills",
        "notes": ""
      }, {
        "level": 6,
        "code": 322121,
        "name": "Paper (except Newsprint) Mills",
        "notes": "US"
      }, {
        "level": 6,
        "code": 322122,
        "name": "Newsprint Mills",
        "notes": "US"
      }, {
        "level": 5,
        "code": 32213,
        "name": "Paperboard Mills",
        "notes": ""
      }, {
        "level": 6,
        "code": 322130,
        "name": "Paperboard Mills",
        "notes": "US"
      }, {
        "level": 4,
        "code": 3222,
        "name": "Converted Paper Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32221,
        "name": "Paperboard Container Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 322211,
        "name": "Corrugated and Solid Fibre Box Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 322212,
        "name": "Folding Paperboard Box Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 322219,
        "name": "Other Paperboard Container Manufacturing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 32222,
        "name": "Paper Bag and Coated and Treated Paper Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 322220,
        "name": "Paper Bag and Coated and Treated Paper Manufacturing",
        "notes": "MEX"
      }, {
        "level": 5,
        "code": 32223,
        "name": "Stationery Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 322230,
        "name": "Stationery Product Manufacturing",
        "notes": "MEX"
      }, {
        "level": 5,
        "code": 32229,
        "name": "Other Converted Paper Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 322291,
        "name": "Sanitary Paper Product Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 322299,
        "name": "All Other Converted Paper Product Manufacturing",
        "notes": "US"
      }, {
        "level": 3,
        "code": 323,
        "name": "Printing and Related Support Activities",
        "notes": ""
      }, {
        "level": 4,
        "code": 3231,
        "name": "Printing and Related Support Activities",
        "notes": ""
      }, {
        "level": 5,
        "code": 32311,
        "name": "Printing",
        "notes": ""
      }, {
        "level": 6,
        "code": 323113,
        "name": "Commercial Screen Printing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 323114,
        "name": "Quick Printing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 323115,
        "name": "Digital Printing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 323116,
        "name": "Manifold Business Forms Printing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 323119,
        "name": "Other Printing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 32312,
        "name": "Support Activities for Printing",
        "notes": ""
      }, {
        "level": 6,
        "code": 323120,
        "name": "Support Activities for Printing",
        "notes": "MEX"
      }, {
        "level": 3,
        "code": 324,
        "name": "Petroleum and Coal Product Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3241,
        "name": "Petroleum and Coal Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32411,
        "name": "Petroleum Refineries",
        "notes": ""
      }, {
        "level": 6,
        "code": 324110,
        "name": "Petroleum Refineries",
        "notes": ""
      }, {
        "level": 5,
        "code": 32412,
        "name": "Asphalt Paving, Roofing and Saturated Materials Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 324121,
        "name": "Asphalt Paving Mixture and Block Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 324122,
        "name": "Asphalt Shingle and Coating Material Manufacturing",
        "notes": "US"
      }, {
        "level": 5,
        "code": 32419,
        "name": "Other Petroleum and Coal Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 324190,
        "name": "Other Petroleum and Coal Product Manufacturing",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 325,
        "name": "Chemical Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3251,
        "name": "Basic Chemical Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32511,
        "name": "Petrochemical Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 325110,
        "name": "Petrochemical Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32512,
        "name": "Industrial Gas Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 325120,
        "name": "Industrial Gas Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32513,
        "name": "Synthetic Dye and Pigment Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 325130,
        "name": "Synthetic Dye and Pigment Manufacturing",
        "notes": "MEX"
      }, {
        "level": 5,
        "code": 32518,
        "name": "Other Basic Inorganic Chemical Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 325181,
        "name": "Alkali and Chlorine Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 325189,
        "name": "All Other Basic Inorganic Chemical Manufacturing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 32519,
        "name": "Other Basic Organic Chemical Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 325190,
        "name": "Other Basic Organic Chemical Manufacturing",
        "notes": "MEX"
      }, {
        "level": 4,
        "code": 3252,
        "name": "Resin, Synthetic Rubber, and Artificial and Synthetic Fibres and Filaments Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32521,
        "name": "Resin and Synthetic Rubber Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 325210,
        "name": "Resin and Synthetic Rubber Manufacturing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 32522,
        "name": "Artificial and Synthetic Fibres and Filaments Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 325220,
        "name": "Artificial and Synthetic Fibres and Filaments Manufacturing",
        "notes": "MEX"
      }, {
        "level": 4,
        "code": 3253,
        "name": "Pesticide, Fertilizer and Other Agricultural Chemical Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32531,
        "name": "Fertilizer Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 325313,
        "name": "Chemical Fertilizer (except Potash) Manufacturing",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 325314,
        "name": "Mixed Fertilizer Manufacturing",
        "notes": "US"
      }, {
        "level": 5,
        "code": 32532,
        "name": "Pesticide and Other Agricultural Chemical Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 325320,
        "name": "Pesticide and Other Agricultural Chemical Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3254,
        "name": "Pharmaceutical and Medicine Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32541,
        "name": "Pharmaceutical and Medicine Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 325410,
        "name": "Pharmaceutical and Medicine Manufacturing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3255,
        "name": "Paint, Coating and Adhesive Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32551,
        "name": "Paint and Coating Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 325510,
        "name": "Paint and Coating Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32552,
        "name": "Adhesive Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 325520,
        "name": "Adhesive Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3256,
        "name": "Soap, Cleaning Compound and Toilet Preparation Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32561,
        "name": "Soap and Cleaning Compound Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 325610,
        "name": "Soap and Cleaning Compound Manufacturing",
        "notes": "MEX"
      }, {
        "level": 5,
        "code": 32562,
        "name": "Toilet Preparation Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 325620,
        "name": "Toilet Preparation Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3259,
        "name": "Other Chemical Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32591,
        "name": "Printing Ink Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 325910,
        "name": "Printing Ink Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32592,
        "name": "Explosives Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 325920,
        "name": "Explosives Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32599,
        "name": "All Other Chemical Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 325991,
        "name": "Custom Compounding of Purchased Resins",
        "notes": "US"
      }, {
        "level": 6,
        "code": 325999,
        "name": "All Other Miscellaneous Chemical Product Manufacturing",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 326,
        "name": "Plastics and Rubber Products Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3261,
        "name": "Plastic Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32611,
        "name": "Plastic Packaging Materials and Unlaminated Film and Sheet Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 326111,
        "name": "Plastic Bag and Pouch Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 326114,
        "name": "Plastic Film and Sheet Manufacturing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 32612,
        "name": "Plastic Pipe, Pipe Fitting, and Unlaminated Profile Shape Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 326121,
        "name": "Unlaminated Plastic Profile Shape Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 326122,
        "name": "Plastic Pipe and Pipe Fitting Manufacturing",
        "notes": "US"
      }, {
        "level": 5,
        "code": 32613,
        "name": "Laminated Plastic Plate, Sheet (except Packaging), and Shape Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 326130,
        "name": "Laminated Plastic Plate, Sheet (except Packaging), and Shape Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32614,
        "name": "Polystyrene Foam Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 326140,
        "name": "Polystyrene Foam Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32615,
        "name": "Urethane and Other Foam Product (except Polystyrene) Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 326150,
        "name": "Urethane and Other Foam Product (except Polystyrene) Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32616,
        "name": "Plastic Bottle Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 326160,
        "name": "Plastic Bottle Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32619,
        "name": "Other Plastic Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 326191,
        "name": "Plastic Plumbing Fixture Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 326193,
        "name": "Motor Vehicle Plastic Parts Manufacturing",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 326196,
        "name": "Plastic Window and Door Manufacturing",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 326198,
        "name": "All Other Plastic Product Manufacturing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3262,
        "name": "Rubber Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32621,
        "name": "Tire Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 326210,
        "name": "Tire Manufacturing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 32622,
        "name": "Rubber and Plastic Hose and Belting Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 326220,
        "name": "Rubber and Plastic Hose and Belting Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32629,
        "name": "Other Rubber Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 326290,
        "name": "Other Rubber Product Manufacturing",
        "notes": "MEX"
      }, {
        "level": 3,
        "code": 327,
        "name": "Non-Metallic Mineral Product Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3271,
        "name": "Clay Product and Refractory Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32711,
        "name": "Pottery, Ceramics and Plumbing Fixture Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 327110,
        "name": "Pottery, Ceramics and Plumbing Fixture Manufacturing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 32712,
        "name": "Clay Building Material and Refractory Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 327120,
        "name": "Clay Building Material and Refractory Manufacturing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3272,
        "name": "Glass and Glass Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32721,
        "name": "Glass and Glass Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 327214,
        "name": "Glass Manufacturing",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 327215,
        "name": "Glass Product Manufacturing from Purchased Glass",
        "notes": "US"
      }, {
        "level": 4,
        "code": 3273,
        "name": "Cement and Concrete Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32731,
        "name": "Cement Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 327310,
        "name": "Cement Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32732,
        "name": "Ready-Mix Concrete Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 327320,
        "name": "Ready-Mix Concrete Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32733,
        "name": "Concrete Pipe, Brick and Block Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 327330,
        "name": "Concrete Pipe, Brick and Block Manufacturing",
        "notes": "MEX"
      }, {
        "level": 5,
        "code": 32739,
        "name": "Other Concrete Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 327390,
        "name": "Other Concrete Product Manufacturing",
        "notes": "US"
      }, {
        "level": 4,
        "code": 3274,
        "name": "Lime and Gypsum Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32741,
        "name": "Lime Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 327410,
        "name": "Lime Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32742,
        "name": "Gypsum Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 327420,
        "name": "Gypsum Product Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3279,
        "name": "Other Non-Metallic Mineral Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32791,
        "name": "Abrasive Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 327910,
        "name": "Abrasive Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 32799,
        "name": "All Other Non-Metallic Mineral Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 327990,
        "name": "All Other Non-Metallic Mineral Product Manufacturing",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 331,
        "name": "Primary Metal Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3311,
        "name": "Iron and Steel Mills and Ferro-Alloy Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33111,
        "name": "Iron and Steel Mills and Ferro-Alloy Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 331110,
        "name": "Iron and Steel Mills and Ferro-Alloy Manufacturing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3312,
        "name": "Steel Product Manufacturing from Purchased Steel",
        "notes": ""
      }, {
        "level": 5,
        "code": 33121,
        "name": "Iron and Steel Pipes and Tubes Manufacturing from Purchased Steel",
        "notes": ""
      }, {
        "level": 6,
        "code": 331210,
        "name": "Iron and Steel Pipes and Tubes Manufacturing from Purchased Steel",
        "notes": ""
      }, {
        "level": 5,
        "code": 33122,
        "name": "Rolling and Drawing of Purchased Steel",
        "notes": ""
      }, {
        "level": 6,
        "code": 331221,
        "name": "Cold-Rolled Steel Shape Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 331222,
        "name": "Steel Wire Drawing",
        "notes": "US"
      }, {
        "level": 4,
        "code": 3313,
        "name": "Alumina and Aluminum Production and Processing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33131,
        "name": "Alumina and Aluminum Production and Processing",
        "notes": ""
      }, {
        "level": 6,
        "code": 331313,
        "name": "Primary Production of Alumina and Aluminum",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 331317,
        "name": "Aluminum Rolling, Drawing, Extruding and Alloying",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3314,
        "name": "Non-Ferrous Metal (except Aluminum) Production and Processing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33141,
        "name": "Non-Ferrous Metal (except Aluminum) Smelting and Refining",
        "notes": ""
      }, {
        "level": 6,
        "code": 331410,
        "name": "Non-Ferrous Metal (except Aluminum) Smelting and Refining",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 33142,
        "name": "Copper Rolling, Drawing, Extruding and Alloying",
        "notes": ""
      }, {
        "level": 6,
        "code": 331420,
        "name": "Copper Rolling, Drawing, Extruding and Alloying",
        "notes": "MEX"
      }, {
        "level": 5,
        "code": 33149,
        "name": "Non-Ferrous Metal (except Copper and Aluminum) Rolling, Drawing, Extruding and Alloying",
        "notes": ""
      }, {
        "level": 6,
        "code": 331490,
        "name": "Non-Ferrous Metal (except Copper and Aluminum) Rolling, Drawing, Extruding and Alloying",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3315,
        "name": "Foundries",
        "notes": ""
      }, {
        "level": 5,
        "code": 33151,
        "name": "Ferrous Metal Foundries",
        "notes": ""
      }, {
        "level": 6,
        "code": 331511,
        "name": "Iron Foundries",
        "notes": "US"
      }, {
        "level": 6,
        "code": 331514,
        "name": "Steel Foundries",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 33152,
        "name": "Non-Ferrous Metal Foundries",
        "notes": ""
      }, {
        "level": 6,
        "code": 331523,
        "name": "Non-Ferrous Die-Casting Foundries",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 331529,
        "name": "Non-Ferrous Foundries (except Die-Casting)",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 332,
        "name": "Fabricated Metal Product Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3321,
        "name": "Forging and Stamping",
        "notes": ""
      }, {
        "level": 5,
        "code": 33211,
        "name": "Forging and Stamping",
        "notes": ""
      }, {
        "level": 6,
        "code": 332113,
        "name": "Forging",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 332118,
        "name": "Stamping",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3322,
        "name": "Cutlery and Hand Tool Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33221,
        "name": "Cutlery and Hand Tool Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 332210,
        "name": "Cutlery and Hand Tool Manufacturing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3323,
        "name": "Architectural and Structural Metals Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33231,
        "name": "Plate Work and Fabricated Structural Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 332311,
        "name": "Prefabricated Metal Building and Component Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 332314,
        "name": "Concrete Reinforcing Bar Manufacturing",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 332319,
        "name": "Other Plate Work and Fabricated Structural Product Manufacturing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 33232,
        "name": "Ornamental and Architectural Metal Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 332321,
        "name": "Metal Window and Door Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 332329,
        "name": "Other Ornamental and Architectural Metal Product Manufacturing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3324,
        "name": "Boiler, Tank and Shipping Container Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33241,
        "name": "Power Boiler and Heat Exchanger Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 332410,
        "name": "Power Boiler and Heat Exchanger Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33242,
        "name": "Metal Tank (Heavy Gauge) Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 332420,
        "name": "Metal Tank (Heavy Gauge) Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33243,
        "name": "Metal Can, Box and Other Metal Container (Light Gauge) Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 332431,
        "name": "Metal Can Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 332439,
        "name": "Other Metal Container Manufacturing",
        "notes": "US"
      }, {
        "level": 4,
        "code": 3325,
        "name": "Hardware Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33251,
        "name": "Hardware Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 332510,
        "name": "Hardware Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3326,
        "name": "Spring and Wire Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33261,
        "name": "Spring and Wire Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 332611,
        "name": "Spring (Heavy Gauge) Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 332619,
        "name": "Other Fabricated Wire Product Manufacturing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3327,
        "name": "Machine Shops, Turned Product, and Screw, Nut and Bolt Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33271,
        "name": "Machine Shops",
        "notes": ""
      }, {
        "level": 6,
        "code": 332710,
        "name": "Machine Shops",
        "notes": ""
      }, {
        "level": 5,
        "code": 33272,
        "name": "Turned Product and Screw, Nut and Bolt Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 332720,
        "name": "Turned Product and Screw, Nut and Bolt Manufacturing",
        "notes": "MEX"
      }, {
        "level": 4,
        "code": 3328,
        "name": "Coating, Engraving, Heat Treating and Allied Activities",
        "notes": ""
      }, {
        "level": 5,
        "code": 33281,
        "name": "Coating, Engraving, Heat Treating and Allied Activities",
        "notes": ""
      }, {
        "level": 6,
        "code": 332810,
        "name": "Coating, Engraving, Heat Treating and Allied Activities",
        "notes": "MEX"
      }, {
        "level": 4,
        "code": 3329,
        "name": "Other Fabricated Metal Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33291,
        "name": "Metal Valve Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 332910,
        "name": "Metal Valve Manufacturing",
        "notes": "MEX"
      }, {
        "level": 5,
        "code": 33299,
        "name": "All Other Fabricated Metal Product Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 332991,
        "name": "Ball and Roller Bearing Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 332999,
        "name": "All Other Miscellaneous Fabricated Metal Product Manufacturing",
        "notes": "MEX"
      }, {
        "level": 3,
        "code": 333,
        "name": "Machinery Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3331,
        "name": "Agricultural, Construction and Mining Machinery Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33311,
        "name": "Agricultural Implement Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 333110,
        "name": "Agricultural Implement Manufacturing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 33312,
        "name": "Construction Machinery Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 333120,
        "name": "Construction Machinery Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33313,
        "name": "Mining and Oil and Gas Field Machinery Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 333130,
        "name": "Mining and Oil and Gas Field Machinery Manufacturing",
        "notes": "MEX"
      }, {
        "level": 4,
        "code": 3332,
        "name": "Industrial Machinery Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33321,
        "name": "Sawmill and Woodworking Machinery Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 333210,
        "name": "Sawmill and Woodworking Machinery Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33322,
        "name": "Rubber and Plastics Industry Machinery Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 333220,
        "name": "Rubber and Plastics Industry Machinery Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33329,
        "name": "Other Industrial Machinery Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 333291,
        "name": "Paper Industry Machinery Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 333299,
        "name": "All Other Industrial Machinery Manufacturing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3333,
        "name": "Commercial and Service Industry Machinery Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33331,
        "name": "Commercial and Service Industry Machinery Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 333310,
        "name": "Commercial and Service Industry Machinery Manufacturing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3334,
        "name": "Ventilation, Heating, Air-Conditioning and Commercial Refrigeration Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33341,
        "name": "Ventilation, Heating, Air-Conditioning and Commercial Refrigeration Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 333413,
        "name": "Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 333416,
        "name": "Heating Equipment and Commercial Refrigeration Equipment Manufacturing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3335,
        "name": "Metalworking Machinery Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33351,
        "name": "Metalworking Machinery Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 333511,
        "name": "Industrial Mould Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 333519,
        "name": "Other Metalworking Machinery Manufacturing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3336,
        "name": "Engine, Turbine and Power Transmission Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33361,
        "name": "Engine, Turbine and Power Transmission Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 333611,
        "name": "Turbine and Turbine Generator Set Unit Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 333619,
        "name": "Other Engine and Power Transmission Equipment Manufacturing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3339,
        "name": "Other General-Purpose Machinery Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33391,
        "name": "Pump and Compressor Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 333910,
        "name": "Pump and Compressor Manufacturing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 33392,
        "name": "Material Handling Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 333920,
        "name": "Material Handling Equipment Manufacturing",
        "notes": "MEX"
      }, {
        "level": 5,
        "code": 33399,
        "name": "All Other General-Purpose Machinery Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 333990,
        "name": "All Other General-Purpose Machinery Manufacturing",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 334,
        "name": "Computer and Electronic Product Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3341,
        "name": "Computer and Peripheral Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33411,
        "name": "Computer and Peripheral Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 334110,
        "name": "Computer and Peripheral Equipment Manufacturing",
        "notes": "MEX"
      }, {
        "level": 4,
        "code": 3342,
        "name": "Communications Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33421,
        "name": "Telephone Apparatus Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 334210,
        "name": "Telephone Apparatus Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33422,
        "name": "Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 334220,
        "name": "Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33429,
        "name": "Other Communications Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 334290,
        "name": "Other Communications Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3343,
        "name": "Audio and Video Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33431,
        "name": "Audio and Video Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 334310,
        "name": "Audio and Video Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3344,
        "name": "Semiconductor and Other Electronic Component Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33441,
        "name": "Semiconductor and Other Electronic Component Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 334410,
        "name": "Semiconductor and Other Electronic Component Manufacturing",
        "notes": "MEX"
      }, {
        "level": 4,
        "code": 3345,
        "name": "Navigational, Measuring, Medical and Control Instruments Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33451,
        "name": "Navigational, Measuring, Medical and Control Instruments Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 334511,
        "name": "Navigational and Guidance Instruments Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 334512,
        "name": "Measuring, Medical and Controlling Devices Manufacturing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3346,
        "name": "Manufacturing and Reproducing Magnetic and Optical Media",
        "notes": ""
      }, {
        "level": 5,
        "code": 33461,
        "name": "Manufacturing and Reproducing Magnetic and Optical Media",
        "notes": ""
      }, {
        "level": 6,
        "code": 334610,
        "name": "Manufacturing and Reproducing Magnetic and Optical Media",
        "notes": "MEX"
      }, {
        "level": 3,
        "code": 335,
        "name": "Electrical Equipment, Appliance and Component Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3351,
        "name": "Electric Lighting Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33511,
        "name": "Electric Lamp Bulb and Parts Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 335110,
        "name": "Electric Lamp Bulb and Parts Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33512,
        "name": "Lighting Fixture Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 335120,
        "name": "Lighting Fixture Manufacturing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3352,
        "name": "Household Appliance Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33521,
        "name": "Small Electrical Appliance Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 335210,
        "name": "Small Electrical Appliance Manufacturing",
        "notes": "MEX"
      }, {
        "level": 5,
        "code": 33522,
        "name": "Major Appliance Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 335223,
        "name": "Major Kitchen Appliance Manufacturing",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 335229,
        "name": "Other Major Appliance Manufacturing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3353,
        "name": "Electrical Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33531,
        "name": "Electrical Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 335311,
        "name": "Power, Distribution and Specialty Transformers Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 335312,
        "name": "Motor and Generator Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 335315,
        "name": "Switchgear and Switchboard, and Relay and Industrial Control Apparatus Manufacturing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3359,
        "name": "Other Electrical Equipment and Component Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33591,
        "name": "Battery Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 335910,
        "name": "Battery Manufacturing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 33592,
        "name": "Communication and Energy Wire and Cable Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 335920,
        "name": "Communication and Energy Wire and Cable Manufacturing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 33593,
        "name": "Wiring Device Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 335930,
        "name": "Wiring Device Manufacturing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 33599,
        "name": "All Other Electrical Equipment and Component Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 335990,
        "name": "All Other Electrical Equipment and Component Manufacturing",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 336,
        "name": "Transportation Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3361,
        "name": "Motor Vehicle Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33611,
        "name": "Automobile and Light-Duty Motor Vehicle Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 336110,
        "name": "Automobile and Light-Duty Motor Vehicle Manufacturing",
        "notes": "MEX"
      }, {
        "level": 5,
        "code": 33612,
        "name": "Heavy-Duty Truck Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 336120,
        "name": "Heavy-Duty Truck Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3362,
        "name": "Motor Vehicle Body and Trailer Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33621,
        "name": "Motor Vehicle Body and Trailer Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 336211,
        "name": "Motor Vehicle Body Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 336212,
        "name": "Truck Trailer Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 336215,
        "name": "Motor Home, Travel Trailer and Camper Manufacturing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3363,
        "name": "Motor Vehicle Parts Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33631,
        "name": "Motor Vehicle Gasoline Engine and Engine Parts Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 336310,
        "name": "Motor Vehicle Gasoline Engine and Engine Parts Manufacturing",
        "notes": "MEX"
      }, {
        "level": 5,
        "code": 33632,
        "name": "Motor Vehicle Electrical and Electronic Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 336320,
        "name": "Motor Vehicle Electrical and Electronic Equipment Manufacturing",
        "notes": "MEX"
      }, {
        "level": 5,
        "code": 33633,
        "name": "Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 336330,
        "name": "Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33634,
        "name": "Motor Vehicle Brake System Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 336340,
        "name": "Motor Vehicle Brake System Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33635,
        "name": "Motor Vehicle Transmission and Power Train Parts Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 336350,
        "name": "Motor Vehicle Transmission and Power Train Parts Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33636,
        "name": "Motor Vehicle Seating and Interior Trim Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 336360,
        "name": "Motor Vehicle Seating and Interior Trim Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33637,
        "name": "Motor Vehicle Metal Stamping",
        "notes": ""
      }, {
        "level": 6,
        "code": 336370,
        "name": "Motor Vehicle Metal Stamping",
        "notes": ""
      }, {
        "level": 5,
        "code": 33639,
        "name": "Other Motor Vehicle Parts Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 336390,
        "name": "Other Motor Vehicle Parts Manufacturing",
        "notes": "MEX"
      }, {
        "level": 4,
        "code": 3364,
        "name": "Aerospace Product and Parts Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33641,
        "name": "Aerospace Product and Parts Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 336410,
        "name": "Aerospace Product and Parts Manufacturing",
        "notes": "MEX"
      }, {
        "level": 4,
        "code": 3365,
        "name": "Railroad Rolling Stock Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33651,
        "name": "Railroad Rolling Stock Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 336510,
        "name": "Railroad Rolling Stock Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3366,
        "name": "Ship and Boat Building",
        "notes": ""
      }, {
        "level": 5,
        "code": 33661,
        "name": "Ship and Boat Building",
        "notes": ""
      }, {
        "level": 6,
        "code": 336611,
        "name": "Ship Building and Repairing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 336612,
        "name": "Boat Building",
        "notes": "US"
      }, {
        "level": 4,
        "code": 3369,
        "name": "Other Transportation Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33699,
        "name": "Other Transportation Equipment Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 336990,
        "name": "Other Transportation Equipment Manufacturing",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 337,
        "name": "Furniture and Related Product Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3371,
        "name": "Household and Institutional Furniture and Kitchen Cabinet Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33711,
        "name": "Wood Kitchen Cabinet and Counter Top Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 337110,
        "name": "Wood Kitchen Cabinet and Counter Top Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33712,
        "name": "Household and Institutional Furniture Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 337121,
        "name": "Upholstered Household Furniture Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 337123,
        "name": "Other Wood Household Furniture Manufacturing",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 337126,
        "name": "Household Furniture (except Wood and Upholstered) Manufacturing",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 337127,
        "name": "Institutional Furniture Manufacturing",
        "notes": "US"
      }, {
        "level": 4,
        "code": 3372,
        "name": "Office Furniture (including Fixtures) Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33721,
        "name": "Office Furniture (including Fixtures) Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 337213,
        "name": "Wood Office Furniture, including Custom Architectural Woodwork, Manufacturing",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 337214,
        "name": "Office Furniture (except Wood) Manufacturing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 337215,
        "name": "Showcase, Partition, Shelving and Locker Manufacturing",
        "notes": "US"
      }, {
        "level": 4,
        "code": 3379,
        "name": "Other Furniture-Related Product Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33791,
        "name": "Mattress Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 337910,
        "name": "Mattress Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33792,
        "name": "Blind and Shade Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 337920,
        "name": "Blind and Shade Manufacturing",
        "notes": ""
      }, {
        "level": 3,
        "code": 339,
        "name": "Miscellaneous Manufacturing",
        "notes": ""
      }, {
        "level": 4,
        "code": 3391,
        "name": "Medical Equipment and Supplies Manufacturing",
        "notes": ""
      }, {
        "level": 5,
        "code": 33911,
        "name": "Medical Equipment and Supplies Manufacturing",
        "notes": ""
      }, {
        "level": 6,
        "code": 339110,
        "name": "Medical Equipment and Supplies Manufacturing",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 3399,
        "name": "Other Miscellaneous Manufacturing",
        "notes": ""
      }, {
        "level": 2,
        "code": 41,
        "name": "Wholesale Trade",
        "notes": ""
      }, {
        "level": 3,
        "code": 411,
        "name": "Farm Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4111,
        "name": "Farm Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41111,
        "name": "Live Animal Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 411110,
        "name": "Live Animal Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41112,
        "name": "Oilseed and Grain Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 411120,
        "name": "Oilseed and Grain Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41113,
        "name": "Nursery Stock and Plant Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 411130,
        "name": "Nursery Stock and Plant Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41119,
        "name": "Other Farm Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 411190,
        "name": "Other Farm Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 412,
        "name": "Petroleum Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4121,
        "name": "Petroleum Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41211,
        "name": "Petroleum Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 412110,
        "name": "Petroleum Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 413,
        "name": "Food, Beverage and Tobacco Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4131,
        "name": "Food Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41311,
        "name": "General-Line Food Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 413110,
        "name": "General-Line Food Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41312,
        "name": "Dairy and Milk Products Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 413120,
        "name": "Dairy and Milk Products Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41313,
        "name": "Poultry and Egg Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 413130,
        "name": "Poultry and Egg Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41314,
        "name": "Fish and Seafood Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 413140,
        "name": "Fish and Seafood Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41315,
        "name": "Fresh Fruit and Vegetable Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 413150,
        "name": "Fresh Fruit and Vegetable Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41316,
        "name": "Red Meat and Meat Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 413160,
        "name": "Red Meat and Meat Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41319,
        "name": "Other Specialty-Line Food Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 413190,
        "name": "Other Specialty-Line Food Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4132,
        "name": "Beverage Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41321,
        "name": "Non-Alcoholic Beverage Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 413210,
        "name": "Non-Alcoholic Beverage Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41322,
        "name": "Alcoholic Beverage Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 413220,
        "name": "Alcoholic Beverage Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4133,
        "name": "Cigarette and Tobacco Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41331,
        "name": "Cigarette and Tobacco Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 413310,
        "name": "Cigarette and Tobacco Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 414,
        "name": "Personal and Household Goods Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4141,
        "name": "Textile, Clothing and Footwear Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41411,
        "name": "Clothing and Clothing Accessories Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 414110,
        "name": "Clothing and Clothing Accessories Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41412,
        "name": "Footwear Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 414120,
        "name": "Footwear Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41413,
        "name": "Piece Goods, Notions and Other Dry Goods Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 414130,
        "name": "Piece Goods, Notions and Other Dry Goods Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4142,
        "name": "Home Entertainment Equipment and Household Appliance Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41421,
        "name": "Home Entertainment Equipment Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 414210,
        "name": "Home Entertainment Equipment Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41422,
        "name": "Household Appliance Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 414220,
        "name": "Household Appliance Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4143,
        "name": "Home Furnishings Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41431,
        "name": "China, Glassware, Crockery and Pottery Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 414310,
        "name": "China, Glassware, Crockery and Pottery Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41432,
        "name": "Floor Covering Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 414320,
        "name": "Floor Covering Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41433,
        "name": "Linen, Drapery and Other Textile Furnishings Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 414330,
        "name": "Linen, Drapery and Other Textile Furnishings Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41439,
        "name": "Other Home Furnishings Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 414390,
        "name": "Other Home Furnishings Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4144,
        "name": "Personal Goods Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41441,
        "name": "Jewellery and Watch Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 414410,
        "name": "Jewellery and Watch Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41442,
        "name": "Book, Periodical and Newspaper Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 414420,
        "name": "Book, Periodical and Newspaper Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41443,
        "name": "Photographic Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 414430,
        "name": "Photographic Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41444,
        "name": "Sound Recording Wholesalers",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 414440,
        "name": "Sound Recording Wholesalers",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41445,
        "name": "Video Cassette Wholesalers",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 414450,
        "name": "Video Cassette Wholesalers",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41446,
        "name": "Toy and Hobby Goods Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 414460,
        "name": "Toy and Hobby Goods Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41447,
        "name": "Amusement and Sporting Goods Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 414470,
        "name": "Amusement and Sporting Goods Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4145,
        "name": "Pharmaceuticals, Toiletries, Cosmetics and Sundries Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41451,
        "name": "Pharmaceuticals and Pharmacy Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 414510,
        "name": "Pharmaceuticals and Pharmacy Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41452,
        "name": "Toiletries, Cosmetics and Sundries Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 414520,
        "name": "Toiletries, Cosmetics and Sundries Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 415,
        "name": "Motor Vehicle and Parts Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4151,
        "name": "Motor Vehicle Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41511,
        "name": "New and Used Automobile and Light-Duty Truck Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 415110,
        "name": "New and Used Automobile and Light-Duty Truck Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41512,
        "name": "Truck, Truck Tractor and Bus Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 415120,
        "name": "Truck, Truck Tractor and Bus Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41519,
        "name": "Recreational and Other Motor Vehicles Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 415190,
        "name": "Recreational and Other Motor Vehicles Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4152,
        "name": "New Motor Vehicle Parts and Accessories Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41521,
        "name": "Tire Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 415210,
        "name": "Tire Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41529,
        "name": "Other New Motor Vehicle Parts and Accessories Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 415290,
        "name": "Other New Motor Vehicle Parts and Accessories Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4153,
        "name": "Used Motor Vehicle Parts and Accessories Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41531,
        "name": "Used Motor Vehicle Parts and Accessories Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 415310,
        "name": "Used Motor Vehicle Parts and Accessories Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 416,
        "name": "Building Material and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4161,
        "name": "Electrical, Plumbing, Heating and Air-Conditioning Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41611,
        "name": "Electrical Wiring and Construction Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 416110,
        "name": "Electrical Wiring and Construction Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41612,
        "name": "Plumbing, Heating and Air-Conditioning Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 416120,
        "name": "Plumbing, Heating and Air-Conditioning Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4162,
        "name": "Metal Service Centres",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41621,
        "name": "Metal Service Centres",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 416210,
        "name": "Metal Service Centres",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4163,
        "name": "Lumber, Millwork, Hardware and Other Building Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41631,
        "name": "General-Line Building Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 416310,
        "name": "General-Line Building Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41632,
        "name": "Lumber, Plywood and Millwork Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 416320,
        "name": "Lumber, Plywood and Millwork Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41633,
        "name": "Hardware Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 416330,
        "name": "Hardware Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41634,
        "name": "Paint, Glass and Wallpaper Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 416340,
        "name": "Paint, Glass and Wallpaper Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41639,
        "name": "Other Specialty-Line Building Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 416390,
        "name": "Other Specialty-Line Building Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 417,
        "name": "Machinery, Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4171,
        "name": "Farm, Lawn and Garden Machinery and Equipment Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41711,
        "name": "Farm, Lawn and Garden Machinery and Equipment Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 417110,
        "name": "Farm, Lawn and Garden Machinery and Equipment Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4172,
        "name": "Construction, Forestry, Mining, and Industrial Machinery, Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41721,
        "name": "Construction and Forestry Machinery, Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 417210,
        "name": "Construction and Forestry Machinery, Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41722,
        "name": "Mining and Oil and Gas Well Machinery, Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 417220,
        "name": "Mining and Oil and Gas Well Machinery, Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41723,
        "name": "Industrial Machinery, Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 417230,
        "name": "Industrial Machinery, Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4173,
        "name": "Computer and Communications Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41731,
        "name": "Computer, Computer Peripheral and Pre-Packaged Software Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 417310,
        "name": "Computer, Computer Peripheral and Pre-Packaged Software Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41732,
        "name": "Electronic Components, Navigational and Communications Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 417320,
        "name": "Electronic Components, Navigational and Communications Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4179,
        "name": "Other Machinery, Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41791,
        "name": "Office and Store Machinery and Equipment Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 417910,
        "name": "Office and Store Machinery and Equipment Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41792,
        "name": "Service Establishment Machinery, Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 417920,
        "name": "Service Establishment Machinery, Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41793,
        "name": "Professional Machinery, Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 417930,
        "name": "Professional Machinery, Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41799,
        "name": "All Other Machinery, Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 417990,
        "name": "All Other Machinery, Equipment and Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 418,
        "name": "Miscellaneous Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4181,
        "name": "Recyclable Material Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41811,
        "name": "Recyclable Metal Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 418110,
        "name": "Recyclable Metal Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41812,
        "name": "Recyclable Paper and Paperboard Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 418120,
        "name": "Recyclable Paper and Paperboard Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41819,
        "name": "Other Recyclable Material Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 418190,
        "name": "Other Recyclable Material Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4182,
        "name": "Paper, Paper Product and Disposable Plastic Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41821,
        "name": "Stationery and Office Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 418210,
        "name": "Stationery and Office Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41822,
        "name": "Other Paper and Disposable Plastic Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 418220,
        "name": "Other Paper and Disposable Plastic Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4183,
        "name": "Agricultural Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41831,
        "name": "Agricultural Feed Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 418310,
        "name": "Agricultural Feed Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41832,
        "name": "Seed Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 418320,
        "name": "Seed Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41839,
        "name": "Agricultural Chemical and Other Farm Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 418390,
        "name": "Agricultural Chemical and Other Farm Supplies Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4184,
        "name": "Chemical (except Agricultural) and Allied Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41841,
        "name": "Chemical (except Agricultural) and Allied Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 418410,
        "name": "Chemical (except Agricultural) and Allied Product Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4189,
        "name": "Other Miscellaneous Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41891,
        "name": "Log and Wood Chip Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 418910,
        "name": "Log and Wood Chip Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41892,
        "name": "Mineral, Ore and Precious Metal Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 418920,
        "name": "Mineral, Ore and Precious Metal Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41893,
        "name": "Second-Hand Goods (except Machinery and Automotive) Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 418930,
        "name": "Second-Hand Goods (except Machinery and Automotive) Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 41899,
        "name": "All Other Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 418990,
        "name": "All Other Wholesaler-Distributors",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 419,
        "name": "Wholesale Electronic Markets, and Agents and Brokers",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4191,
        "name": "Wholesale Electronic Markets, and Agents and Brokers",
        "notes": "US"
      }, {
        "level": 5,
        "code": 41911,
        "name": "Business-to-Business Electronic Markets",
        "notes": "US"
      }, {
        "level": 6,
        "code": 419110,
        "name": "Business-to-Business Electronic Markets",
        "notes": "US"
      }, {
        "level": 5,
        "code": 41912,
        "name": "Wholesale Trade Agents and Brokers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 419120,
        "name": "Wholesale Trade Agents and Brokers",
        "notes": "US"
      }, {
        "level": 3,
        "code": 441,
        "name": "Motor Vehicle and Parts Dealers",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4411,
        "name": "Automobile Dealers",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44111,
        "name": "New Car Dealers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 441110,
        "name": "New Car Dealers",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44112,
        "name": "Used Car Dealers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 441120,
        "name": "Used Car Dealers",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4412,
        "name": "Other Motor Vehicle Dealers",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44121,
        "name": "Recreational Vehicle Dealers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 441210,
        "name": "Recreational Vehicle Dealers",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44122,
        "name": "Motorcycle, Boat and Other Motor Vehicle Dealers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 441220,
        "name": "Motorcycle, Boat and Other Motor Vehicle Dealers",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4413,
        "name": "Automotive Parts, Accessories and Tire Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44131,
        "name": "Automotive Parts and Accessories Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 441310,
        "name": "Automotive Parts and Accessories Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44132,
        "name": "Tire Dealers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 441320,
        "name": "Tire Dealers",
        "notes": "US"
      }, {
        "level": 3,
        "code": 442,
        "name": "Furniture and Home Furnishings Stores",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4421,
        "name": "Furniture Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44211,
        "name": "Furniture Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 442110,
        "name": "Furniture Stores",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4422,
        "name": "Home Furnishings Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44221,
        "name": "Floor Covering Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 442210,
        "name": "Floor Covering Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44229,
        "name": "Other Home Furnishings Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 442291,
        "name": "Window Treatment Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 442292,
        "name": "Print and Picture Frame Stores",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 442298,
        "name": "All Other Home Furnishings Stores",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 443,
        "name": "Electronics and Appliance Stores",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4431,
        "name": "Electronics and Appliance Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44311,
        "name": "Appliance, Television and Other Electronics Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 443110,
        "name": "Appliance, Television and Other Electronics Stores",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 44312,
        "name": "Computer and Software Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 443120,
        "name": "Computer and Software Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44313,
        "name": "Camera and Photographic Supplies Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 443130,
        "name": "Camera and Photographic Supplies Stores",
        "notes": "US"
      }, {
        "level": 3,
        "code": 444,
        "name": "Building Material and Garden Equipment and Supplies Dealers",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4441,
        "name": "Building Material and Supplies Dealers",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44411,
        "name": "Home Centres",
        "notes": "US"
      }, {
        "level": 6,
        "code": 444110,
        "name": "Home Centres",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44412,
        "name": "Paint and Wallpaper Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 444120,
        "name": "Paint and Wallpaper Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44413,
        "name": "Hardware Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 444130,
        "name": "Hardware Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44419,
        "name": "Other Building Material Dealers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 444190,
        "name": "Other Building Material Dealers",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4442,
        "name": "Lawn and Garden Equipment and Supplies Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44421,
        "name": "Outdoor Power Equipment Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 444210,
        "name": "Outdoor Power Equipment Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44422,
        "name": "Nursery Stores and Garden Centres",
        "notes": "US"
      }, {
        "level": 6,
        "code": 444220,
        "name": "Nursery Stores and Garden Centres",
        "notes": "US"
      }, {
        "level": 3,
        "code": 445,
        "name": "Food and Beverage Stores",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4451,
        "name": "Grocery Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44511,
        "name": "Supermarkets and Other Grocery (except Convenience) Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 445110,
        "name": "Supermarkets and Other Grocery (except Convenience) Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44512,
        "name": "Convenience Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 445120,
        "name": "Convenience Stores",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4452,
        "name": "Specialty Food Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44521,
        "name": "Meat Markets",
        "notes": "US"
      }, {
        "level": 6,
        "code": 445210,
        "name": "Meat Markets",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44522,
        "name": "Fish and Seafood Markets",
        "notes": "US"
      }, {
        "level": 6,
        "code": 445220,
        "name": "Fish and Seafood Markets",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44523,
        "name": "Fruit and Vegetable Markets",
        "notes": "US"
      }, {
        "level": 6,
        "code": 445230,
        "name": "Fruit and Vegetable Markets",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44529,
        "name": "Other Specialty Food Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 445291,
        "name": "Baked Goods Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 445292,
        "name": "Confectionery and Nut Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 445299,
        "name": "All Other Specialty Food Stores",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4453,
        "name": "Beer, Wine and Liquor Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44531,
        "name": "Beer, Wine and Liquor Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 445310,
        "name": "Beer, Wine and Liquor Stores",
        "notes": "US"
      }, {
        "level": 3,
        "code": 446,
        "name": "Health and Personal Care Stores",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4461,
        "name": "Health and Personal Care Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44611,
        "name": "Pharmacies and Drug Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 446110,
        "name": "Pharmacies and Drug Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44612,
        "name": "Cosmetics, Beauty Supplies and Perfume Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 446120,
        "name": "Cosmetics, Beauty Supplies and Perfume Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44613,
        "name": "Optical Goods Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 446130,
        "name": "Optical Goods Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44619,
        "name": "Other Health and Personal Care Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 446191,
        "name": "Food (Health) Supplement Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 446199,
        "name": "All Other Health and Personal Care Stores",
        "notes": "US"
      }, {
        "level": 3,
        "code": 447,
        "name": "Gasoline Stations",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4471,
        "name": "Gasoline Stations",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44711,
        "name": "Gasoline Stations with Convenience Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 447110,
        "name": "Gasoline Stations with Convenience Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44719,
        "name": "Other Gasoline Stations",
        "notes": "US"
      }, {
        "level": 6,
        "code": 447190,
        "name": "Other Gasoline Stations",
        "notes": "US"
      }, {
        "level": 3,
        "code": 448,
        "name": "Clothing and Clothing Accessories Stores",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4481,
        "name": "Clothing Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44811,
        "name": "Men's Clothing Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 448110,
        "name": "Men's Clothing Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44812,
        "name": "Women's Clothing Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 448120,
        "name": "Women's Clothing Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44813,
        "name": "Children's and Infants' Clothing Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 448130,
        "name": "Children's and Infants' Clothing Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44814,
        "name": "Family Clothing Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 448140,
        "name": "Family Clothing Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44815,
        "name": "Clothing Accessories Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 448150,
        "name": "Clothing Accessories Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44819,
        "name": "Other Clothing Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 448191,
        "name": "Fur Stores",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 448199,
        "name": "All Other Clothing Stores",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4482,
        "name": "Shoe Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44821,
        "name": "Shoe Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 448210,
        "name": "Shoe Stores",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4483,
        "name": "Jewellery, Luggage and Leather Goods Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44831,
        "name": "Jewellery Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 448310,
        "name": "Jewellery Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 44832,
        "name": "Luggage and Leather Goods Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 448320,
        "name": "Luggage and Leather Goods Stores",
        "notes": "US"
      }, {
        "level": 3,
        "code": 451,
        "name": "Sporting Goods, Hobby, Book and Music Stores",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4511,
        "name": "Sporting Goods, Hobby and Musical Instrument Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45111,
        "name": "Sporting Goods Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 451110,
        "name": "Sporting Goods Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45112,
        "name": "Hobby, Toy and Game Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 451120,
        "name": "Hobby, Toy and Game Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45113,
        "name": "Sewing, Needlework and Piece Goods Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 451130,
        "name": "Sewing, Needlework and Piece Goods Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45114,
        "name": "Musical Instrument and Supplies Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 451140,
        "name": "Musical Instrument and Supplies Stores",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4512,
        "name": "Book, Periodical and Music Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45121,
        "name": "Book Stores and News Dealers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 451210,
        "name": "Book Stores and News Dealers",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 45122,
        "name": "Pre-Recorded Tape, Compact Disc and Record Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 451220,
        "name": "Pre-Recorded Tape, Compact Disc and Record Stores",
        "notes": "US"
      }, {
        "level": 3,
        "code": 452,
        "name": "General Merchandise Stores",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4521,
        "name": "Department Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45211,
        "name": "Department Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 452110,
        "name": "Department Stores",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4529,
        "name": "Other General Merchandise Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45291,
        "name": "Warehouse Clubs and Superstores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 452910,
        "name": "Warehouse Clubs and Superstores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45299,
        "name": "All Other General Merchandise Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 452991,
        "name": "Home and Auto Supplies Stores",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 452999,
        "name": "All Other Miscellaneous General Merchandise Stores",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 453,
        "name": "Miscellaneous Store Retailers",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4531,
        "name": "Florists",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45311,
        "name": "Florists",
        "notes": "US"
      }, {
        "level": 6,
        "code": 453110,
        "name": "Florists",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4532,
        "name": "Office Supplies, Stationery and Gift Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45321,
        "name": "Office Supplies and Stationery Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 453210,
        "name": "Office Supplies and Stationery Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45322,
        "name": "Gift, Novelty and Souvenir Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 453220,
        "name": "Gift, Novelty and Souvenir Stores",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4533,
        "name": "Used Merchandise Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45331,
        "name": "Used Merchandise Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 453310,
        "name": "Used Merchandise Stores",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4539,
        "name": "Other Miscellaneous Store Retailers",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45391,
        "name": "Pet and Pet Supplies Stores",
        "notes": "US"
      }, {
        "level": 6,
        "code": 453910,
        "name": "Pet and Pet Supplies Stores",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45392,
        "name": "Art Dealers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 453920,
        "name": "Art Dealers",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45393,
        "name": "Mobile Home Dealers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 453930,
        "name": "Mobile Home Dealers",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45399,
        "name": "All Other Miscellaneous Store Retailers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 453992,
        "name": "Beer and Wine-Making Supplies Stores",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 453999,
        "name": "All Other Miscellaneous Store Retailers (except Beer and Wine-Making Supplies Stores)",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 454,
        "name": "Non-Store Retailers",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4541,
        "name": "Electronic Shopping and Mail-Order Houses",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45411,
        "name": "Electronic Shopping and Mail-Order Houses",
        "notes": "US"
      }, {
        "level": 6,
        "code": 454111,
        "name": "Internet Shopping",
        "notes": "US"
      }, {
        "level": 6,
        "code": 454112,
        "name": "Electronic Auctions",
        "notes": "US"
      }, {
        "level": 6,
        "code": 454113,
        "name": "Mail-Order Houses",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4542,
        "name": "Vending Machine Operators",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45421,
        "name": "Vending Machine Operators",
        "notes": "US"
      }, {
        "level": 6,
        "code": 454210,
        "name": "Vending Machine Operators",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4543,
        "name": "Direct Selling Establishments",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45431,
        "name": "Fuel Dealers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 454311,
        "name": "Heating Oil Dealers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 454312,
        "name": "Liquefied Petroleum Gas (Bottled Gas) Dealers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 454319,
        "name": "Other Fuel Dealers",
        "notes": "US"
      }, {
        "level": 5,
        "code": 45439,
        "name": "Other Direct Selling Establishments",
        "notes": "US"
      }, {
        "level": 6,
        "code": 454390,
        "name": "Other Direct Selling Establishments",
        "notes": "US"
      }, {
        "level": 3,
        "code": 481,
        "name": "Air Transportation",
        "notes": ""
      }, {
        "level": 4,
        "code": 4811,
        "name": "Scheduled Air Transportation",
        "notes": ""
      }, {
        "level": 5,
        "code": 48111,
        "name": "Scheduled Air Transportation",
        "notes": ""
      }, {
        "level": 6,
        "code": 481110,
        "name": "Scheduled Air Transportation",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4812,
        "name": "Non-Scheduled Air Transportation",
        "notes": ""
      }, {
        "level": 5,
        "code": 48121,
        "name": "Non-Scheduled Air Transportation",
        "notes": ""
      }, {
        "level": 6,
        "code": 481214,
        "name": "Non-Scheduled Chartered Air Transportation",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 481215,
        "name": "Non-Scheduled Specialty Flying Services",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 482,
        "name": "Rail Transportation",
        "notes": ""
      }, {
        "level": 4,
        "code": 4821,
        "name": "Rail Transportation",
        "notes": ""
      }, {
        "level": 5,
        "code": 48211,
        "name": "Rail Transportation",
        "notes": ""
      }, {
        "level": 6,
        "code": 482112,
        "name": "Short-Haul Freight Rail Transportation",
        "notes": "US"
      }, {
        "level": 6,
        "code": 482113,
        "name": "Mainline Freight Rail Transportation",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 482114,
        "name": "Passenger Rail Transportation",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 483,
        "name": "Water Transportation",
        "notes": ""
      }, {
        "level": 4,
        "code": 4831,
        "name": "Deep Sea, Coastal and Great Lakes Water Transportation",
        "notes": ""
      }, {
        "level": 5,
        "code": 48311,
        "name": "Deep Sea, Coastal and Great Lakes Water Transportation",
        "notes": ""
      }, {
        "level": 6,
        "code": 483115,
        "name": "Deep Sea, Coastal and Great Lakes Water Transportation (except by Ferries)",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 483116,
        "name": "Deep Sea, Coastal and Great Lakes Water Transportation by Ferries",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4832,
        "name": "Inland Water Transportation",
        "notes": ""
      }, {
        "level": 5,
        "code": 48321,
        "name": "Inland Water Transportation",
        "notes": ""
      }, {
        "level": 6,
        "code": 483213,
        "name": "Inland Water Transportation (except by Ferries)",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 483214,
        "name": "Inland Water Transportation by Ferries",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 484,
        "name": "Truck Transportation",
        "notes": ""
      }, {
        "level": 4,
        "code": 4841,
        "name": "General Freight Trucking",
        "notes": ""
      }, {
        "level": 5,
        "code": 48411,
        "name": "General Freight Trucking, Local",
        "notes": ""
      }, {
        "level": 6,
        "code": 484110,
        "name": "General Freight Trucking, Local",
        "notes": "US"
      }, {
        "level": 5,
        "code": 48412,
        "name": "General Freight Trucking, Long Distance",
        "notes": ""
      }, {
        "level": 6,
        "code": 484121,
        "name": "General Freight Trucking, Long Distance, Truck-Load",
        "notes": "US"
      }, {
        "level": 6,
        "code": 484122,
        "name": "General Freight Trucking, Long Distance, Less Than Truck-Load",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4842,
        "name": "Specialized Freight Trucking",
        "notes": ""
      }, {
        "level": 5,
        "code": 48421,
        "name": "Used Household and Office Goods Moving",
        "notes": ""
      }, {
        "level": 6,
        "code": 484210,
        "name": "Used Household and Office Goods Moving",
        "notes": ""
      }, {
        "level": 5,
        "code": 48422,
        "name": "Specialized Freight (except Used Goods) Trucking, Local",
        "notes": ""
      }, {
        "level": 6,
        "code": 484221,
        "name": "Bulk Liquids Trucking, Local",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 484222,
        "name": "Dry Bulk Materials Trucking, Local",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 484223,
        "name": "Forest Products Trucking, Local",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 484229,
        "name": "Other Specialized Freight (except Used Goods) Trucking, Local",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 48423,
        "name": "Specialized Freight (except Used Goods) Trucking, Long Distance",
        "notes": ""
      }, {
        "level": 6,
        "code": 484231,
        "name": "Bulk Liquids Trucking, Long Distance",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 484232,
        "name": "Dry Bulk Materials Trucking, Long Distance",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 484233,
        "name": "Forest Products Trucking, Long Distance",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 484239,
        "name": "Other Specialized Freight (except Used Goods) Trucking, Long Distance",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 485,
        "name": "Transit and Ground Passenger Transportation",
        "notes": ""
      }, {
        "level": 4,
        "code": 4851,
        "name": "Urban Transit Systems",
        "notes": ""
      }, {
        "level": 5,
        "code": 48511,
        "name": "Urban Transit Systems",
        "notes": ""
      }, {
        "level": 6,
        "code": 485110,
        "name": "Urban Transit Systems",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4852,
        "name": "Interurban and Rural Bus Transportation",
        "notes": ""
      }, {
        "level": 5,
        "code": 48521,
        "name": "Interurban and Rural Bus Transportation",
        "notes": ""
      }, {
        "level": 6,
        "code": 485210,
        "name": "Interurban and Rural Bus Transportation",
        "notes": ""
      }, {
        "level": 4,
        "code": 4853,
        "name": "Taxi and Limousine Service",
        "notes": ""
      }, {
        "level": 5,
        "code": 48531,
        "name": "Taxi Service",
        "notes": ""
      }, {
        "level": 6,
        "code": 485310,
        "name": "Taxi Service",
        "notes": "US"
      }, {
        "level": 5,
        "code": 48532,
        "name": "Limousine Service",
        "notes": ""
      }, {
        "level": 6,
        "code": 485320,
        "name": "Limousine Service",
        "notes": ""
      }, {
        "level": 4,
        "code": 4854,
        "name": "School and Employee Bus Transportation",
        "notes": ""
      }, {
        "level": 5,
        "code": 48541,
        "name": "School and Employee Bus Transportation",
        "notes": ""
      }, {
        "level": 6,
        "code": 485410,
        "name": "School and Employee Bus Transportation",
        "notes": ""
      }, {
        "level": 4,
        "code": 4855,
        "name": "Charter Bus Industry",
        "notes": ""
      }, {
        "level": 5,
        "code": 48551,
        "name": "Charter Bus Industry",
        "notes": ""
      }, {
        "level": 6,
        "code": 485510,
        "name": "Charter Bus Industry",
        "notes": ""
      }, {
        "level": 4,
        "code": 4859,
        "name": "Other Transit and Ground Passenger Transportation",
        "notes": ""
      }, {
        "level": 5,
        "code": 48599,
        "name": "Other Transit and Ground Passenger Transportation",
        "notes": ""
      }, {
        "level": 6,
        "code": 485990,
        "name": "Other Transit and Ground Passenger Transportation",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 486,
        "name": "Pipeline Transportation",
        "notes": ""
      }, {
        "level": 4,
        "code": 4861,
        "name": "Pipeline Transportation of Crude Oil",
        "notes": ""
      }, {
        "level": 5,
        "code": 48611,
        "name": "Pipeline Transportation of Crude Oil",
        "notes": ""
      }, {
        "level": 6,
        "code": 486110,
        "name": "Pipeline Transportation of Crude Oil",
        "notes": ""
      }, {
        "level": 4,
        "code": 4862,
        "name": "Pipeline Transportation of Natural Gas",
        "notes": ""
      }, {
        "level": 5,
        "code": 48621,
        "name": "Pipeline Transportation of Natural Gas",
        "notes": ""
      }, {
        "level": 6,
        "code": 486210,
        "name": "Pipeline Transportation of Natural Gas",
        "notes": ""
      }, {
        "level": 4,
        "code": 4869,
        "name": "Other Pipeline Transportation",
        "notes": ""
      }, {
        "level": 5,
        "code": 48691,
        "name": "Pipeline Transportation of Refined Petroleum Products",
        "notes": ""
      }, {
        "level": 6,
        "code": 486910,
        "name": "Pipeline Transportation of Refined Petroleum Products",
        "notes": ""
      }, {
        "level": 5,
        "code": 48699,
        "name": "All Other Pipeline Transportation",
        "notes": ""
      }, {
        "level": 6,
        "code": 486990,
        "name": "All Other Pipeline Transportation",
        "notes": ""
      }, {
        "level": 3,
        "code": 487,
        "name": "Scenic and Sightseeing Transportation",
        "notes": ""
      }, {
        "level": 4,
        "code": 4871,
        "name": "Scenic and Sightseeing Transportation, Land",
        "notes": ""
      }, {
        "level": 5,
        "code": 48711,
        "name": "Scenic and Sightseeing Transportation, Land",
        "notes": ""
      }, {
        "level": 6,
        "code": 487110,
        "name": "Scenic and Sightseeing Transportation, Land",
        "notes": ""
      }, {
        "level": 4,
        "code": 4872,
        "name": "Scenic and Sightseeing Transportation, Water",
        "notes": ""
      }, {
        "level": 5,
        "code": 48721,
        "name": "Scenic and Sightseeing Transportation, Water",
        "notes": ""
      }, {
        "level": 6,
        "code": 487210,
        "name": "Scenic and Sightseeing Transportation, Water",
        "notes": ""
      }, {
        "level": 4,
        "code": 4879,
        "name": "Scenic and Sightseeing Transportation, Other",
        "notes": ""
      }, {
        "level": 5,
        "code": 48799,
        "name": "Scenic and Sightseeing Transportation, Other",
        "notes": ""
      }, {
        "level": 6,
        "code": 487990,
        "name": "Scenic and Sightseeing Transportation, Other",
        "notes": ""
      }, {
        "level": 3,
        "code": 488,
        "name": "Support Activities for Transportation",
        "notes": ""
      }, {
        "level": 4,
        "code": 4881,
        "name": "Support Activities for Air Transportation",
        "notes": ""
      }, {
        "level": 5,
        "code": 48811,
        "name": "Airport Operations",
        "notes": ""
      }, {
        "level": 6,
        "code": 488111,
        "name": "Air Traffic Control",
        "notes": ""
      }, {
        "level": 6,
        "code": 488119,
        "name": "Other Airport Operations",
        "notes": "US"
      }, {
        "level": 5,
        "code": 48819,
        "name": "Other Support Activities for Air Transportation",
        "notes": ""
      }, {
        "level": 6,
        "code": 488190,
        "name": "Other Support Activities for Air Transportation",
        "notes": ""
      }, {
        "level": 4,
        "code": 4882,
        "name": "Support Activities for Rail Transportation",
        "notes": ""
      }, {
        "level": 5,
        "code": 48821,
        "name": "Support Activities for Rail Transportation",
        "notes": ""
      }, {
        "level": 6,
        "code": 488210,
        "name": "Support Activities for Rail Transportation",
        "notes": ""
      }, {
        "level": 4,
        "code": 4883,
        "name": "Support Activities for Water Transportation",
        "notes": ""
      }, {
        "level": 5,
        "code": 48831,
        "name": "Port and Harbour Operations",
        "notes": ""
      }, {
        "level": 6,
        "code": 488310,
        "name": "Port and Harbour Operations",
        "notes": ""
      }, {
        "level": 5,
        "code": 48832,
        "name": "Marine Cargo Handling",
        "notes": ""
      }, {
        "level": 6,
        "code": 488320,
        "name": "Marine Cargo Handling",
        "notes": ""
      }, {
        "level": 5,
        "code": 48833,
        "name": "Navigational Services to Shipping",
        "notes": ""
      }, {
        "level": 6,
        "code": 488331,
        "name": "Marine Salvage Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 488332,
        "name": "Ship Piloting Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 488339,
        "name": "Other Navigational Services to Shipping",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 48839,
        "name": "Other Support Activities for Water Transportation",
        "notes": ""
      }, {
        "level": 6,
        "code": 488390,
        "name": "Other Support Activities for Water Transportation",
        "notes": ""
      }, {
        "level": 4,
        "code": 4884,
        "name": "Support Activities for Road Transportation",
        "notes": ""
      }, {
        "level": 5,
        "code": 48841,
        "name": "Motor Vehicle Towing",
        "notes": ""
      }, {
        "level": 6,
        "code": 488410,
        "name": "Motor Vehicle Towing",
        "notes": ""
      }, {
        "level": 5,
        "code": 48849,
        "name": "Other Support Activities for Road Transportation",
        "notes": ""
      }, {
        "level": 6,
        "code": 488490,
        "name": "Other Support Activities for Road Transportation",
        "notes": "US"
      }, {
        "level": 4,
        "code": 4885,
        "name": "Freight Transportation Arrangement",
        "notes": ""
      }, {
        "level": 5,
        "code": 48851,
        "name": "Freight Transportation Arrangement",
        "notes": ""
      }, {
        "level": 6,
        "code": 488511,
        "name": "Marine Shipping Agencies",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 488519,
        "name": "Other Freight Transportation Arrangement",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 4889,
        "name": "Other Support Activities for Transportation",
        "notes": ""
      }, {
        "level": 5,
        "code": 48899,
        "name": "Other Support Activities for Transportation",
        "notes": ""
      }, {
        "level": 6,
        "code": 488990,
        "name": "Other Support Activities for Transportation",
        "notes": "MEX"
      }, {
        "level": 3,
        "code": 491,
        "name": "Postal Service",
        "notes": ""
      }, {
        "level": 4,
        "code": 4911,
        "name": "Postal Service",
        "notes": ""
      }, {
        "level": 5,
        "code": 49111,
        "name": "Postal Service",
        "notes": ""
      }, {
        "level": 6,
        "code": 491110,
        "name": "Postal Service",
        "notes": ""
      }, {
        "level": 3,
        "code": 492,
        "name": "Couriers and Messengers",
        "notes": ""
      }, {
        "level": 4,
        "code": 4921,
        "name": "Couriers",
        "notes": ""
      }, {
        "level": 5,
        "code": 49211,
        "name": "Couriers",
        "notes": ""
      }, {
        "level": 6,
        "code": 492110,
        "name": "Couriers",
        "notes": ""
      }, {
        "level": 4,
        "code": 4922,
        "name": "Local Messengers and Local Delivery",
        "notes": ""
      }, {
        "level": 5,
        "code": 49221,
        "name": "Local Messengers and Local Delivery",
        "notes": ""
      }, {
        "level": 6,
        "code": 492210,
        "name": "Local Messengers and Local Delivery",
        "notes": ""
      }, {
        "level": 3,
        "code": 493,
        "name": "Warehousing and Storage",
        "notes": ""
      }, {
        "level": 4,
        "code": 4931,
        "name": "Warehousing and Storage",
        "notes": ""
      }, {
        "level": 5,
        "code": 49311,
        "name": "General Warehousing and Storage",
        "notes": ""
      }, {
        "level": 6,
        "code": 493110,
        "name": "General Warehousing and Storage",
        "notes": "US"
      }, {
        "level": 5,
        "code": 49312,
        "name": "Refrigerated Warehousing and Storage",
        "notes": ""
      }, {
        "level": 6,
        "code": 493120,
        "name": "Refrigerated Warehousing and Storage",
        "notes": ""
      }, {
        "level": 5,
        "code": 49313,
        "name": "Farm Product Warehousing and Storage",
        "notes": ""
      }, {
        "level": 6,
        "code": 493130,
        "name": "Farm Product Warehousing and Storage",
        "notes": ""
      }, {
        "level": 5,
        "code": 49319,
        "name": "Other Warehousing and Storage",
        "notes": ""
      }, {
        "level": 6,
        "code": 493190,
        "name": "Other Warehousing and Storage",
        "notes": ""
      }, {
        "level": 2,
        "code": 51,
        "name": "Information and Cultural Industries",
        "notes": ""
      }, {
        "level": 3,
        "code": 511,
        "name": "Publishing Industries (except Internet)",
        "notes": ""
      }, {
        "level": 4,
        "code": 5111,
        "name": "Newspaper, Periodical, Book and Directory Publishers",
        "notes": ""
      }, {
        "level": 5,
        "code": 51111,
        "name": "Newspaper Publishers",
        "notes": ""
      }, {
        "level": 6,
        "code": 511110,
        "name": "Newspaper Publishers",
        "notes": "US"
      }, {
        "level": 5,
        "code": 51112,
        "name": "Periodical Publishers",
        "notes": ""
      }, {
        "level": 6,
        "code": 511120,
        "name": "Periodical Publishers",
        "notes": "US"
      }, {
        "level": 5,
        "code": 51113,
        "name": "Book Publishers",
        "notes": ""
      }, {
        "level": 6,
        "code": 511130,
        "name": "Book Publishers",
        "notes": "US"
      }, {
        "level": 5,
        "code": 51114,
        "name": "Directory and Mailing List Publishers",
        "notes": ""
      }, {
        "level": 6,
        "code": 511140,
        "name": "Directory and Mailing List Publishers",
        "notes": "US"
      }, {
        "level": 5,
        "code": 51119,
        "name": "Other Publishers",
        "notes": ""
      }, {
        "level": 6,
        "code": 511190,
        "name": "Other Publishers",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 5112,
        "name": "Software Publishers",
        "notes": ""
      }, {
        "level": 5,
        "code": 51121,
        "name": "Software Publishers",
        "notes": ""
      }, {
        "level": 6,
        "code": 511210,
        "name": "Software Publishers",
        "notes": ""
      }, {
        "level": 3,
        "code": 512,
        "name": "Motion Picture and Sound Recording Industries",
        "notes": ""
      }, {
        "level": 4,
        "code": 5121,
        "name": "Motion Picture and Video Industries",
        "notes": ""
      }, {
        "level": 5,
        "code": 51211,
        "name": "Motion Picture and Video Production",
        "notes": ""
      }, {
        "level": 6,
        "code": 512110,
        "name": "Motion Picture and Video Production",
        "notes": "US"
      }, {
        "level": 5,
        "code": 51212,
        "name": "Motion Picture and Video Distribution",
        "notes": ""
      }, {
        "level": 6,
        "code": 512120,
        "name": "Motion Picture and Video Distribution",
        "notes": ""
      }, {
        "level": 5,
        "code": 51213,
        "name": "Motion Picture and Video Exhibition",
        "notes": ""
      }, {
        "level": 6,
        "code": 512130,
        "name": "Motion Picture and Video Exhibition",
        "notes": "MEX"
      }, {
        "level": 5,
        "code": 51219,
        "name": "Post-Production and Other Motion Picture and Video Industries",
        "notes": ""
      }, {
        "level": 6,
        "code": 512190,
        "name": "Post-Production and Other Motion Picture and Video Industries",
        "notes": "MEX"
      }, {
        "level": 4,
        "code": 5122,
        "name": "Sound Recording Industries",
        "notes": ""
      }, {
        "level": 5,
        "code": 51221,
        "name": "Record Production",
        "notes": ""
      }, {
        "level": 6,
        "code": 512210,
        "name": "Record Production",
        "notes": ""
      }, {
        "level": 5,
        "code": 51222,
        "name": "Integrated Record Production/Distribution",
        "notes": ""
      }, {
        "level": 6,
        "code": 512220,
        "name": "Integrated Record Production/Distribution",
        "notes": ""
      }, {
        "level": 5,
        "code": 51223,
        "name": "Music Publishers",
        "notes": ""
      }, {
        "level": 6,
        "code": 512230,
        "name": "Music Publishers",
        "notes": ""
      }, {
        "level": 5,
        "code": 51224,
        "name": "Sound Recording Studios",
        "notes": ""
      }, {
        "level": 6,
        "code": 512240,
        "name": "Sound Recording Studios",
        "notes": ""
      }, {
        "level": 5,
        "code": 51229,
        "name": "Other Sound Recording Industries",
        "notes": ""
      }, {
        "level": 6,
        "code": 512290,
        "name": "Other Sound Recording Industries",
        "notes": ""
      }, {
        "level": 3,
        "code": 515,
        "name": "Broadcasting (except Internet)",
        "notes": ""
      }, {
        "level": 4,
        "code": 5151,
        "name": "Radio and Television Broadcasting",
        "notes": ""
      }, {
        "level": 5,
        "code": 51511,
        "name": "Radio Broadcasting",
        "notes": ""
      }, {
        "level": 6,
        "code": 515110,
        "name": "Radio Broadcasting",
        "notes": "MEX"
      }, {
        "level": 5,
        "code": 51512,
        "name": "Television Broadcasting",
        "notes": ""
      }, {
        "level": 6,
        "code": 515120,
        "name": "Television Broadcasting",
        "notes": ""
      }, {
        "level": 4,
        "code": 5152,
        "name": "Pay and Specialty Television",
        "notes": ""
      }, {
        "level": 5,
        "code": 51521,
        "name": "Pay and Specialty Television",
        "notes": ""
      }, {
        "level": 6,
        "code": 515210,
        "name": "Pay and Specialty Television",
        "notes": ""
      }, {
        "level": 3,
        "code": 517,
        "name": "Telecommunications",
        "notes": ""
      }, {
        "level": 4,
        "code": 5171,
        "name": "Wired Telecommunications Carriers",
        "notes": ""
      }, {
        "level": 5,
        "code": 51711,
        "name": "Wired Telecommunications Carriers",
        "notes": ""
      }, {
        "level": 6,
        "code": 517111,
        "name": "Wired Telecommunications Carriers (except Cable)",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 517112,
        "name": "Cable and Other Program Distribution",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 5172,
        "name": "Wireless Telecommunications Carriers (except Satellite)",
        "notes": ""
      }, {
        "level": 5,
        "code": 51721,
        "name": "Wireless Telecommunications Carriers (except Satellite)",
        "notes": ""
      }, {
        "level": 6,
        "code": 517210,
        "name": "Wireless Telecommunications Carriers (except Satellite)",
        "notes": "MEX"
      }, {
        "level": 4,
        "code": 5174,
        "name": "Satellite Telecommunications",
        "notes": ""
      }, {
        "level": 5,
        "code": 51741,
        "name": "Satellite Telecommunications",
        "notes": ""
      }, {
        "level": 6,
        "code": 517410,
        "name": "Satellite Telecommunications",
        "notes": ""
      }, {
        "level": 4,
        "code": 5179,
        "name": "Other Telecommunications",
        "notes": ""
      }, {
        "level": 5,
        "code": 51791,
        "name": "Other Telecommunications",
        "notes": ""
      }, {
        "level": 6,
        "code": 517910,
        "name": "Other Telecommunications",
        "notes": ""
      }, {
        "level": 3,
        "code": 518,
        "name": "Data Processing, Hosting, and Related Services",
        "notes": ""
      }, {
        "level": 4,
        "code": 5182,
        "name": "Data Processing, Hosting, and Related Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 51821,
        "name": "Data Processing, Hosting, and Related Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 518210,
        "name": "Data Processing, Hosting, and Related Services",
        "notes": ""
      }, {
        "level": 3,
        "code": 519,
        "name": "Other Information Services",
        "notes": ""
      }, {
        "level": 4,
        "code": 5191,
        "name": "Other Information Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 51911,
        "name": "News Syndicates",
        "notes": ""
      }, {
        "level": 6,
        "code": 519110,
        "name": "News Syndicates",
        "notes": ""
      }, {
        "level": 5,
        "code": 51912,
        "name": "Libraries and Archives",
        "notes": ""
      }, {
        "level": 6,
        "code": 519121,
        "name": "Libraries",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 519122,
        "name": "Archives",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 51913,
        "name": "Internet Publishing and Broadcasting, and Web Search Portals",
        "notes": ""
      }, {
        "level": 6,
        "code": 519130,
        "name": "Internet Publishing and Broadcasting, and Web Search Portals",
        "notes": ""
      }, {
        "level": 5,
        "code": 51919,
        "name": "All Other Information Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 519190,
        "name": "All Other Information Services",
        "notes": ""
      }, {
        "level": 2,
        "code": 52,
        "name": "Finance and Insurance",
        "notes": ""
      }, {
        "level": 3,
        "code": 521,
        "name": "Monetary Authorities - Central Bank",
        "notes": ""
      }, {
        "level": 4,
        "code": 5211,
        "name": "Monetary Authorities - Central Bank",
        "notes": ""
      }, {
        "level": 5,
        "code": 52111,
        "name": "Monetary Authorities - Central Bank",
        "notes": ""
      }, {
        "level": 6,
        "code": 521110,
        "name": "Monetary Authorities - Central Bank",
        "notes": ""
      }, {
        "level": 3,
        "code": 522,
        "name": "Credit Intermediation and Related Activities",
        "notes": ""
      }, {
        "level": 4,
        "code": 5221,
        "name": "Depository Credit Intermediation",
        "notes": "US"
      }, {
        "level": 5,
        "code": 52211,
        "name": "Banking",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 522111,
        "name": "Personal and Commercial Banking Industry",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 522112,
        "name": "Corporate and Institutional Banking Industry",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 52213,
        "name": "Local Credit Unions",
        "notes": "US"
      }, {
        "level": 6,
        "code": 522130,
        "name": "Local Credit Unions",
        "notes": "US"
      }, {
        "level": 5,
        "code": 52219,
        "name": "Other Depository Credit Intermediation",
        "notes": "US"
      }, {
        "level": 6,
        "code": 522190,
        "name": "Other Depository Credit Intermediation",
        "notes": "US"
      }, {
        "level": 4,
        "code": 5222,
        "name": "Non-Depository Credit Intermediation",
        "notes": "US"
      }, {
        "level": 5,
        "code": 52221,
        "name": "Credit Card Issuing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 522210,
        "name": "Credit Card Issuing",
        "notes": "US"
      }, {
        "level": 5,
        "code": 52222,
        "name": "Sales Financing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 522220,
        "name": "Sales Financing",
        "notes": "US"
      }, {
        "level": 5,
        "code": 52229,
        "name": "Other Non-Depository Credit Intermediation",
        "notes": "US"
      }, {
        "level": 6,
        "code": 522291,
        "name": "Consumer Lending",
        "notes": "US"
      }, {
        "level": 6,
        "code": 522299,
        "name": "All Other Non-Depository Credit Intermediation",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 5223,
        "name": "Activities Related to Credit Intermediation",
        "notes": "US"
      }, {
        "level": 5,
        "code": 52231,
        "name": "Mortgage and Non-mortgage Loan Brokers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 522310,
        "name": "Mortgage and Non-mortgage Loan Brokers",
        "notes": "US"
      }, {
        "level": 5,
        "code": 52232,
        "name": "Financial Transactions Processing, Reserve and Clearing House Activities",
        "notes": "US"
      }, {
        "level": 6,
        "code": 522321,
        "name": "Central Credit Unions",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 522329,
        "name": "Other Financial Transactions Processing and Clearing House Activities",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 52239,
        "name": "Other Activities Related to Credit Intermediation",
        "notes": "US"
      }, {
        "level": 6,
        "code": 522390,
        "name": "Other Activities Related to Credit Intermediation",
        "notes": "US"
      }, {
        "level": 3,
        "code": 523,
        "name": "Securities, Commodity Contracts, and Other Financial Investment and Related Activities",
        "notes": ""
      }, {
        "level": 4,
        "code": 5231,
        "name": "Securities and Commodity Contracts Intermediation and Brokerage",
        "notes": ""
      }, {
        "level": 5,
        "code": 52311,
        "name": "Investment Banking and Securities Dealing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 523110,
        "name": "Investment Banking and Securities Dealing",
        "notes": "US"
      }, {
        "level": 5,
        "code": 52312,
        "name": "Securities Brokerage",
        "notes": "US"
      }, {
        "level": 6,
        "code": 523120,
        "name": "Securities Brokerage",
        "notes": "US"
      }, {
        "level": 5,
        "code": 52313,
        "name": "Commodity Contracts Dealing",
        "notes": "US"
      }, {
        "level": 6,
        "code": 523130,
        "name": "Commodity Contracts Dealing",
        "notes": "US"
      }, {
        "level": 5,
        "code": 52314,
        "name": "Commodity Contracts Brokerage",
        "notes": "US"
      }, {
        "level": 6,
        "code": 523140,
        "name": "Commodity Contracts Brokerage",
        "notes": "US"
      }, {
        "level": 4,
        "code": 5232,
        "name": "Securities and Commodity Exchanges",
        "notes": ""
      }, {
        "level": 5,
        "code": 52321,
        "name": "Securities and Commodity Exchanges",
        "notes": ""
      }, {
        "level": 6,
        "code": 523210,
        "name": "Securities and Commodity Exchanges",
        "notes": ""
      }, {
        "level": 4,
        "code": 5239,
        "name": "Other Financial Investment Activities",
        "notes": ""
      }, {
        "level": 5,
        "code": 52391,
        "name": "Miscellaneous Intermediation",
        "notes": "US"
      }, {
        "level": 6,
        "code": 523910,
        "name": "Miscellaneous Intermediation",
        "notes": "US"
      }, {
        "level": 5,
        "code": 52392,
        "name": "Portfolio Management",
        "notes": "US"
      }, {
        "level": 6,
        "code": 523920,
        "name": "Portfolio Management",
        "notes": "US"
      }, {
        "level": 5,
        "code": 52393,
        "name": "Investment Advice",
        "notes": "US"
      }, {
        "level": 6,
        "code": 523930,
        "name": "Investment Advice",
        "notes": "US"
      }, {
        "level": 5,
        "code": 52399,
        "name": "All Other Financial Investment Activities",
        "notes": "US"
      }, {
        "level": 6,
        "code": 523990,
        "name": "All Other Financial Investment Activities",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 524,
        "name": "Insurance Carriers and Related Activities",
        "notes": ""
      }, {
        "level": 4,
        "code": 5241,
        "name": "Insurance Carriers",
        "notes": ""
      }, {
        "level": 5,
        "code": 52411,
        "name": "Direct Life, Health and Medical Insurance Carriers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 524111,
        "name": "Direct Individual Life, Health and Medical Insurance Carriers",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 524112,
        "name": "Direct Group Life, Health and Medical Insurance Carriers",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 52412,
        "name": "Direct Insurance (except Life, Health and Medical) Carriers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 524121,
        "name": "Direct General Property and Casualty Insurance Carriers",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 524122,
        "name": "Direct, Private, Automobile Insurance Carriers",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 524123,
        "name": "Direct, Public, Automobile Insurance Carriers",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 524124,
        "name": "Direct Property Insurance Carriers",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 524125,
        "name": "Direct Liability Insurance Carriers",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 524129,
        "name": "Other Direct Insurance (except Life, Health and Medical) Carriers",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 52413,
        "name": "Reinsurance Carriers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 524131,
        "name": "Life Reinsurance Carriers",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 524132,
        "name": "Accident and Sickness Reinsurance Carriers",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 524133,
        "name": "Automobile Reinsurance Carriers",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 524134,
        "name": "Property Reinsurance Carriers",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 524135,
        "name": "Liability Reinsurance Carriers",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 524139,
        "name": "General and Other Reinsurance Carriers",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 5242,
        "name": "Agencies, Brokerages and Other Insurance Related Activities",
        "notes": ""
      }, {
        "level": 5,
        "code": 52421,
        "name": "Insurance Agencies and Brokerages",
        "notes": "US"
      }, {
        "level": 6,
        "code": 524210,
        "name": "Insurance Agencies and Brokerages",
        "notes": "US"
      }, {
        "level": 5,
        "code": 52429,
        "name": "Other Insurance Related Activities",
        "notes": "US"
      }, {
        "level": 6,
        "code": 524291,
        "name": "Claims Adjusters",
        "notes": "US"
      }, {
        "level": 6,
        "code": 524299,
        "name": "All Other Insurance Related Activities",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 526,
        "name": "Funds and Other Financial Vehicles",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 5261,
        "name": "Pension Funds",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 52611,
        "name": "Pension Funds",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 526111,
        "name": "Trusteed Pension Funds",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 526112,
        "name": "Non-Trusteed Pension Funds",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 5269,
        "name": "Other Funds and Financial Vehicles",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 52691,
        "name": "Open-End Investment Funds",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 526911,
        "name": "Equity Funds - Canadian",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 526912,
        "name": "Equity Funds - Foreign",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 526913,
        "name": "Mortgage Funds",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 526914,
        "name": "Money Market Funds",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 526915,
        "name": "Bond and Income / Dividend Funds - Canadian",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 526916,
        "name": "Bond and Income / Dividend Funds - Foreign",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 526917,
        "name": "Balanced Funds / Asset Allocation Funds",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 526919,
        "name": "Other Open-Ended Funds",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 52693,
        "name": "Segregated (except Pension) Funds",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 526930,
        "name": "Segregated (except Pension) Funds",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 52698,
        "name": "All Other Funds and Financial Vehicles",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 526981,
        "name": "Securitization Vehicles",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 526989,
        "name": "All Other Miscellaneous Funds and Financial Vehicles",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 531,
        "name": "Real Estate",
        "notes": ""
      }, {
        "level": 4,
        "code": 5311,
        "name": "Lessors of Real Estate",
        "notes": ""
      }, {
        "level": 5,
        "code": 53111,
        "name": "Lessors of Residential Buildings and Dwellings",
        "notes": "US"
      }, {
        "level": 6,
        "code": 531111,
        "name": "Lessors of Residential Buildings and Dwellings (except Social Housing Projects)",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 531112,
        "name": "Lessors of Social Housing Projects",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 53112,
        "name": "Lessors of Non-Residential Buildings (except Mini-Warehouses)",
        "notes": "US"
      }, {
        "level": 6,
        "code": 531120,
        "name": "Lessors of Non-Residential Buildings (except Mini-Warehouses)",
        "notes": "US"
      }, {
        "level": 5,
        "code": 53113,
        "name": "Self-Storage Mini-Warehouses",
        "notes": "US"
      }, {
        "level": 6,
        "code": 531130,
        "name": "Self-Storage Mini-Warehouses",
        "notes": "US"
      }, {
        "level": 5,
        "code": 53119,
        "name": "Lessors of Other Real Estate Property",
        "notes": "US"
      }, {
        "level": 6,
        "code": 531190,
        "name": "Lessors of Other Real Estate Property",
        "notes": "US"
      }, {
        "level": 4,
        "code": 5312,
        "name": "Offices of Real Estate Agents and Brokers",
        "notes": ""
      }, {
        "level": 5,
        "code": 53121,
        "name": "Offices of Real Estate Agents and Brokers",
        "notes": ""
      }, {
        "level": 6,
        "code": 531211,
        "name": "Real Estate Agents",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 531212,
        "name": "Offices of Real Estate Brokers",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 5313,
        "name": "Activities Related to Real Estate",
        "notes": ""
      }, {
        "level": 5,
        "code": 53131,
        "name": "Real Estate Property Managers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 531310,
        "name": "Real Estate Property Managers",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 53132,
        "name": "Offices of Real Estate Appraisers",
        "notes": "US"
      }, {
        "level": 6,
        "code": 531320,
        "name": "Offices of Real Estate Appraisers",
        "notes": "US"
      }, {
        "level": 5,
        "code": 53139,
        "name": "Other Activities Related to Real Estate",
        "notes": "US"
      }, {
        "level": 6,
        "code": 531390,
        "name": "Other Activities Related to Real Estate",
        "notes": "US"
      }, {
        "level": 3,
        "code": 532,
        "name": "Rental and Leasing Services",
        "notes": ""
      }, {
        "level": 4,
        "code": 5321,
        "name": "Automotive Equipment Rental and Leasing",
        "notes": ""
      }, {
        "level": 5,
        "code": 53211,
        "name": "Passenger Car Rental and Leasing",
        "notes": ""
      }, {
        "level": 6,
        "code": 532111,
        "name": "Passenger Car Rental",
        "notes": "US"
      }, {
        "level": 6,
        "code": 532112,
        "name": "Passenger Car Leasing",
        "notes": "US"
      }, {
        "level": 5,
        "code": 53212,
        "name": "Truck, Utility Trailer and RV (Recreational Vehicle) Rental and Leasing",
        "notes": ""
      }, {
        "level": 6,
        "code": 532120,
        "name": "Truck, Utility Trailer and RV (Recreational Vehicle) Rental and Leasing",
        "notes": "US"
      }, {
        "level": 4,
        "code": 5322,
        "name": "Consumer Goods Rental",
        "notes": ""
      }, {
        "level": 5,
        "code": 53221,
        "name": "Consumer Electronics and Appliance Rental",
        "notes": ""
      }, {
        "level": 6,
        "code": 532210,
        "name": "Consumer Electronics and Appliance Rental",
        "notes": ""
      }, {
        "level": 5,
        "code": 53222,
        "name": "Formal Wear and Costume Rental",
        "notes": ""
      }, {
        "level": 6,
        "code": 532220,
        "name": "Formal Wear and Costume Rental",
        "notes": ""
      }, {
        "level": 5,
        "code": 53223,
        "name": "Video Tape and Disc Rental",
        "notes": ""
      }, {
        "level": 6,
        "code": 532230,
        "name": "Video Tape and Disc Rental",
        "notes": ""
      }, {
        "level": 5,
        "code": 53229,
        "name": "Other Consumer Goods Rental",
        "notes": ""
      }, {
        "level": 6,
        "code": 532290,
        "name": "Other Consumer Goods Rental",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 5323,
        "name": "General Rental Centres",
        "notes": ""
      }, {
        "level": 5,
        "code": 53231,
        "name": "General Rental Centres",
        "notes": ""
      }, {
        "level": 6,
        "code": 532310,
        "name": "General Rental Centres",
        "notes": ""
      }, {
        "level": 4,
        "code": 5324,
        "name": "Commercial and Industrial Machinery and Equipment Rental and Leasing",
        "notes": ""
      }, {
        "level": 5,
        "code": 53241,
        "name": "Construction, Transportation, Mining, and Forestry Machinery and Equipment Rental and Leasing",
        "notes": ""
      }, {
        "level": 6,
        "code": 532410,
        "name": "Construction, Transportation, Mining, and Forestry Machinery and Equipment Rental and Leasing",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 53242,
        "name": "Office Machinery and Equipment Rental and Leasing",
        "notes": ""
      }, {
        "level": 6,
        "code": 532420,
        "name": "Office Machinery and Equipment Rental and Leasing",
        "notes": ""
      }, {
        "level": 5,
        "code": 53249,
        "name": "Other Commercial and Industrial Machinery and Equipment Rental and Leasing",
        "notes": ""
      }, {
        "level": 6,
        "code": 532490,
        "name": "Other Commercial and Industrial Machinery and Equipment Rental and Leasing",
        "notes": "US"
      }, {
        "level": 3,
        "code": 533,
        "name": "Lessors of Non-Financial Intangible Assets (Except Copyrighted Works)",
        "notes": ""
      }, {
        "level": 4,
        "code": 5331,
        "name": "Lessors of Non-Financial Intangible Assets (Except Copyrighted Works)",
        "notes": ""
      }, {
        "level": 5,
        "code": 53311,
        "name": "Lessors of Non-Financial Intangible Assets (Except Copyrighted Works)",
        "notes": ""
      }, {
        "level": 6,
        "code": 533110,
        "name": "Lessors of Non-Financial Intangible Assets (Except Copyrighted Works)",
        "notes": ""
      }, {
        "level": 3,
        "code": 541,
        "name": "Professional, Scientific and Technical Services",
        "notes": ""
      }, {
        "level": 4,
        "code": 5411,
        "name": "Legal Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54111,
        "name": "Offices of Lawyers",
        "notes": ""
      }, {
        "level": 6,
        "code": 541110,
        "name": "Offices of Lawyers",
        "notes": ""
      }, {
        "level": 5,
        "code": 54112,
        "name": "Offices of Notaries",
        "notes": ""
      }, {
        "level": 6,
        "code": 541120,
        "name": "Offices of Notaries",
        "notes": ""
      }, {
        "level": 5,
        "code": 54119,
        "name": "Other Legal Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541190,
        "name": "Other Legal Services",
        "notes": "MEX"
      }, {
        "level": 4,
        "code": 5412,
        "name": "Accounting, Tax Preparation, Bookkeeping and Payroll Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54121,
        "name": "Accounting, Tax Preparation, Bookkeeping and Payroll Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541212,
        "name": "Offices of Accountants",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 541213,
        "name": "Tax Preparation Services",
        "notes": "US"
      }, {
        "level": 6,
        "code": 541215,
        "name": "Bookkeeping, Payroll and Related Services",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 5413,
        "name": "Architectural, Engineering and Related Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54131,
        "name": "Architectural Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541310,
        "name": "Architectural Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54132,
        "name": "Landscape Architectural Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541320,
        "name": "Landscape Architectural Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54133,
        "name": "Engineering Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541330,
        "name": "Engineering Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54134,
        "name": "Drafting Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541340,
        "name": "Drafting Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54135,
        "name": "Building Inspection Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541350,
        "name": "Building Inspection Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54136,
        "name": "Geophysical Surveying and Mapping Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541360,
        "name": "Geophysical Surveying and Mapping Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54137,
        "name": "Surveying and Mapping (except Geophysical) Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541370,
        "name": "Surveying and Mapping (except Geophysical) Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54138,
        "name": "Testing Laboratories",
        "notes": ""
      }, {
        "level": 6,
        "code": 541380,
        "name": "Testing Laboratories",
        "notes": ""
      }, {
        "level": 4,
        "code": 5414,
        "name": "Specialized Design Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54141,
        "name": "Interior Design Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541410,
        "name": "Interior Design Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54142,
        "name": "Industrial Design Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541420,
        "name": "Industrial Design Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54143,
        "name": "Graphic Design Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541430,
        "name": "Graphic Design Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54149,
        "name": "Other Specialized Design Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541490,
        "name": "Other Specialized Design Services",
        "notes": ""
      }, {
        "level": 4,
        "code": 5415,
        "name": "Computer Systems Design and Related Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54151,
        "name": "Computer Systems Design and Related Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541510,
        "name": "Computer Systems Design and Related Services",
        "notes": "MEX"
      }, {
        "level": 4,
        "code": 5416,
        "name": "Management, Scientific and Technical Consulting Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54161,
        "name": "Management Consulting Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541611,
        "name": "Administrative Management and General Management Consulting Services",
        "notes": "US"
      }, {
        "level": 6,
        "code": 541612,
        "name": "Human Resources Consulting Services",
        "notes": "US"
      }, {
        "level": 6,
        "code": 541619,
        "name": "Other Management Consulting Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 54162,
        "name": "Environmental Consulting Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541620,
        "name": "Environmental Consulting Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54169,
        "name": "Other Scientific and Technical Consulting Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541690,
        "name": "Other Scientific and Technical Consulting Services",
        "notes": ""
      }, {
        "level": 4,
        "code": 5417,
        "name": "Scientific Research and Development Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54171,
        "name": "Research and Development in the Physical, Engineering and Life Sciences",
        "notes": ""
      }, {
        "level": 6,
        "code": 541710,
        "name": "Research and Development in the Physical, Engineering and Life Sciences",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 54172,
        "name": "Research and Development in the Social Sciences and Humanities",
        "notes": ""
      }, {
        "level": 6,
        "code": 541720,
        "name": "Research and Development in the Social Sciences and Humanities",
        "notes": "US"
      }, {
        "level": 4,
        "code": 5418,
        "name": "Advertising, Public Relations, and Related Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54181,
        "name": "Advertising Agencies",
        "notes": ""
      }, {
        "level": 6,
        "code": 541810,
        "name": "Advertising Agencies",
        "notes": ""
      }, {
        "level": 5,
        "code": 54182,
        "name": "Public Relations Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541820,
        "name": "Public Relations Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54183,
        "name": "Media Buying Agencies",
        "notes": ""
      }, {
        "level": 6,
        "code": 541830,
        "name": "Media Buying Agencies",
        "notes": ""
      }, {
        "level": 5,
        "code": 54184,
        "name": "Media Representatives",
        "notes": ""
      }, {
        "level": 6,
        "code": 541840,
        "name": "Media Representatives",
        "notes": ""
      }, {
        "level": 5,
        "code": 54185,
        "name": "Display Advertising",
        "notes": ""
      }, {
        "level": 6,
        "code": 541850,
        "name": "Display Advertising",
        "notes": ""
      }, {
        "level": 5,
        "code": 54186,
        "name": "Direct Mail Advertising",
        "notes": ""
      }, {
        "level": 6,
        "code": 541860,
        "name": "Direct Mail Advertising",
        "notes": ""
      }, {
        "level": 5,
        "code": 54187,
        "name": "Advertising Material Distribution Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541870,
        "name": "Advertising Material Distribution Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54189,
        "name": "Other Services Related to Advertising",
        "notes": ""
      }, {
        "level": 6,
        "code": 541891,
        "name": "Specialty Advertising Distributors",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 541899,
        "name": "All Other Services Related to Advertising",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 5419,
        "name": "Other Professional, Scientific and Technical Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54191,
        "name": "Marketing Research and Public Opinion Polling",
        "notes": ""
      }, {
        "level": 6,
        "code": 541910,
        "name": "Marketing Research and Public Opinion Polling",
        "notes": ""
      }, {
        "level": 5,
        "code": 54192,
        "name": "Photographic Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541920,
        "name": "Photographic Services",
        "notes": "MEX"
      }, {
        "level": 5,
        "code": 54193,
        "name": "Translation and Interpretation Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541930,
        "name": "Translation and Interpretation Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 54194,
        "name": "Veterinary Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541940,
        "name": "Veterinary Services",
        "notes": "US"
      }, {
        "level": 5,
        "code": 54199,
        "name": "All Other Professional, Scientific and Technical Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 541990,
        "name": "All Other Professional, Scientific and Technical Services",
        "notes": ""
      }, {
        "level": 2,
        "code": 55,
        "name": "Management of Companies and Enterprises",
        "notes": ""
      }, {
        "level": 3,
        "code": 551,
        "name": "Management of Companies and Enterprises",
        "notes": ""
      }, {
        "level": 4,
        "code": 5511,
        "name": "Management of Companies and Enterprises",
        "notes": ""
      }, {
        "level": 5,
        "code": 55111,
        "name": "Management of Companies and Enterprises",
        "notes": ""
      }, {
        "level": 6,
        "code": 551113,
        "name": "Holding Companies",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 551114,
        "name": "Head Offices",
        "notes": "US"
      }, {
        "level": 2,
        "code": 56,
        "name": "Administrative and Support, Waste Management and Remediation Services",
        "notes": ""
      }, {
        "level": 3,
        "code": 561,
        "name": "Administrative and Support Services",
        "notes": ""
      }, {
        "level": 4,
        "code": 5611,
        "name": "Office Administrative Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 56111,
        "name": "Office Administrative Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 561110,
        "name": "Office Administrative Services",
        "notes": ""
      }, {
        "level": 4,
        "code": 5612,
        "name": "Facilities Support Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 56121,
        "name": "Facilities Support Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 561210,
        "name": "Facilities Support Services",
        "notes": ""
      }, {
        "level": 4,
        "code": 5613,
        "name": "Employment Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 56131,
        "name": "Employment Placement Agencies and Executive Search Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 561310,
        "name": "Employment Placement Agencies and Executive Search Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 56132,
        "name": "Temporary Help Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 561320,
        "name": "Temporary Help Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 56133,
        "name": "Professional Employer Organizations",
        "notes": ""
      }, {
        "level": 6,
        "code": 561330,
        "name": "Professional Employer Organizations",
        "notes": ""
      }, {
        "level": 4,
        "code": 5614,
        "name": "Business Support Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 56141,
        "name": "Document Preparation Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 561410,
        "name": "Document Preparation Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 56142,
        "name": "Telephone Call Centres",
        "notes": ""
      }, {
        "level": 6,
        "code": 561420,
        "name": "Telephone Call Centres",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 56143,
        "name": "Business Service Centres",
        "notes": ""
      }, {
        "level": 6,
        "code": 561430,
        "name": "Business Service Centres",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 56144,
        "name": "Collection Agencies",
        "notes": ""
      }, {
        "level": 6,
        "code": 561440,
        "name": "Collection Agencies",
        "notes": ""
      }, {
        "level": 5,
        "code": 56145,
        "name": "Credit Bureaus",
        "notes": ""
      }, {
        "level": 6,
        "code": 561450,
        "name": "Credit Bureaus",
        "notes": ""
      }, {
        "level": 5,
        "code": 56149,
        "name": "Other Business Support Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 561490,
        "name": "Other Business Support Services",
        "notes": "MEX"
      }, {
        "level": 4,
        "code": 5615,
        "name": "Travel Arrangement and Reservation Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 56151,
        "name": "Travel Agencies",
        "notes": ""
      }, {
        "level": 6,
        "code": 561510,
        "name": "Travel Agencies",
        "notes": ""
      }, {
        "level": 5,
        "code": 56152,
        "name": "Tour Operators",
        "notes": ""
      }, {
        "level": 6,
        "code": 561520,
        "name": "Tour Operators",
        "notes": ""
      }, {
        "level": 5,
        "code": 56159,
        "name": "Other Travel Arrangement and Reservation Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 561590,
        "name": "Other Travel Arrangement and Reservation Services",
        "notes": "MEX"
      }, {
        "level": 4,
        "code": 5616,
        "name": "Investigation and Security Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 56161,
        "name": "Investigation, Guard and Armoured Car Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 561611,
        "name": "Investigation Services",
        "notes": "US"
      }, {
        "level": 6,
        "code": 561612,
        "name": "Security Guard and Patrol Services",
        "notes": "US"
      }, {
        "level": 6,
        "code": 561613,
        "name": "Armoured Car Services",
        "notes": "US"
      }, {
        "level": 5,
        "code": 56162,
        "name": "Security Systems Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 561621,
        "name": "Security Systems Services (except Locksmiths)",
        "notes": "US"
      }, {
        "level": 6,
        "code": 561622,
        "name": "Locksmiths",
        "notes": "US"
      }, {
        "level": 4,
        "code": 5617,
        "name": "Services to Buildings and Dwellings",
        "notes": ""
      }, {
        "level": 5,
        "code": 56171,
        "name": "Exterminating and Pest Control Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 561710,
        "name": "Exterminating and Pest Control Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 56172,
        "name": "Janitorial Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 561721,
        "name": "Window Cleaning Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 561722,
        "name": "Janitorial Services (except Window Cleaning)",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 56173,
        "name": "Landscaping Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 561730,
        "name": "Landscaping Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 56174,
        "name": "Carpet and Upholstery Cleaning Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 561740,
        "name": "Carpet and Upholstery Cleaning Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 56179,
        "name": "Other Services to Buildings and Dwellings",
        "notes": ""
      }, {
        "level": 6,
        "code": 561791,
        "name": "Duct and Chimney Cleaning Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 561799,
        "name": "All Other Services to Buildings and Dwellings",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 5619,
        "name": "Other Support Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 56191,
        "name": "Packaging and Labelling Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 561910,
        "name": "Packaging and Labelling Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 56192,
        "name": "Convention and Trade Show Organizers",
        "notes": ""
      }, {
        "level": 6,
        "code": 561920,
        "name": "Convention and Trade Show Organizers",
        "notes": ""
      }, {
        "level": 5,
        "code": 56199,
        "name": "All Other Support Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 561990,
        "name": "All Other Support Services",
        "notes": ""
      }, {
        "level": 3,
        "code": 562,
        "name": "Waste Management and Remediation Services",
        "notes": ""
      }, {
        "level": 4,
        "code": 5621,
        "name": "Waste Collection",
        "notes": "US"
      }, {
        "level": 5,
        "code": 56211,
        "name": "Waste Collection",
        "notes": "US"
      }, {
        "level": 6,
        "code": 562110,
        "name": "Waste Collection",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 5622,
        "name": "Waste Treatment and Disposal",
        "notes": "US"
      }, {
        "level": 5,
        "code": 56221,
        "name": "Waste Treatment and Disposal",
        "notes": "US"
      }, {
        "level": 6,
        "code": 562210,
        "name": "Waste Treatment and Disposal",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 5629,
        "name": "Remediation and Other Waste Management Services",
        "notes": "US"
      }, {
        "level": 5,
        "code": 56291,
        "name": "Remediation Services",
        "notes": "US"
      }, {
        "level": 6,
        "code": 562910,
        "name": "Remediation Services",
        "notes": "US"
      }, {
        "level": 5,
        "code": 56292,
        "name": "Material Recovery Facilities",
        "notes": "US"
      }, {
        "level": 6,
        "code": 562920,
        "name": "Material Recovery Facilities",
        "notes": "US"
      }, {
        "level": 5,
        "code": 56299,
        "name": "All Other Waste Management Services",
        "notes": "US"
      }, {
        "level": 6,
        "code": 562990,
        "name": "All Other Waste Management Services",
        "notes": "CAN"
      }, {
        "level": 2,
        "code": 61,
        "name": "Educational Services",
        "notes": ""
      }, {
        "level": 3,
        "code": 611,
        "name": "Educational Services",
        "notes": ""
      }, {
        "level": 4,
        "code": 6111,
        "name": "Elementary and Secondary Schools",
        "notes": ""
      }, {
        "level": 5,
        "code": 61111,
        "name": "Elementary and Secondary Schools",
        "notes": "US"
      }, {
        "level": 6,
        "code": 611110,
        "name": "Elementary and Secondary Schools",
        "notes": "US"
      }, {
        "level": 4,
        "code": 6112,
        "name": "Community Colleges and C.E.G.E.P.s",
        "notes": ""
      }, {
        "level": 5,
        "code": 61121,
        "name": "Community Colleges and C.E.G.E.P.s",
        "notes": ""
      }, {
        "level": 6,
        "code": 611210,
        "name": "Community Colleges and C.E.G.E.P.s",
        "notes": "US"
      }, {
        "level": 4,
        "code": 6113,
        "name": "Universities",
        "notes": ""
      }, {
        "level": 5,
        "code": 61131,
        "name": "Universities",
        "notes": ""
      }, {
        "level": 6,
        "code": 611310,
        "name": "Universities",
        "notes": "US"
      }, {
        "level": 4,
        "code": 6114,
        "name": "Business Schools and Computer and Management Training",
        "notes": ""
      }, {
        "level": 5,
        "code": 61141,
        "name": "Business and Secretarial Schools",
        "notes": ""
      }, {
        "level": 6,
        "code": 611410,
        "name": "Business and Secretarial Schools",
        "notes": "US"
      }, {
        "level": 5,
        "code": 61142,
        "name": "Computer Training",
        "notes": ""
      }, {
        "level": 6,
        "code": 611420,
        "name": "Computer Training",
        "notes": "US"
      }, {
        "level": 5,
        "code": 61143,
        "name": "Professional and Management Development Training",
        "notes": ""
      }, {
        "level": 6,
        "code": 611430,
        "name": "Professional and Management Development Training",
        "notes": "US"
      }, {
        "level": 4,
        "code": 6115,
        "name": "Technical and Trade Schools",
        "notes": ""
      }, {
        "level": 5,
        "code": 61151,
        "name": "Technical and Trade Schools",
        "notes": ""
      }, {
        "level": 6,
        "code": 611510,
        "name": "Technical and Trade Schools",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 6116,
        "name": "Other Schools and Instruction",
        "notes": ""
      }, {
        "level": 5,
        "code": 61161,
        "name": "Fine Arts Schools",
        "notes": ""
      }, {
        "level": 6,
        "code": 611610,
        "name": "Fine Arts Schools",
        "notes": "US"
      }, {
        "level": 5,
        "code": 61162,
        "name": "Athletic Instruction",
        "notes": ""
      }, {
        "level": 6,
        "code": 611620,
        "name": "Athletic Instruction",
        "notes": "US"
      }, {
        "level": 5,
        "code": 61163,
        "name": "Language Schools",
        "notes": ""
      }, {
        "level": 6,
        "code": 611630,
        "name": "Language Schools",
        "notes": "US"
      }, {
        "level": 5,
        "code": 61169,
        "name": "All Other Schools and Instruction",
        "notes": ""
      }, {
        "level": 6,
        "code": 611690,
        "name": "All Other Schools and Instruction",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 6117,
        "name": "Educational Support Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 61171,
        "name": "Educational Support Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 611710,
        "name": "Educational Support Services",
        "notes": ""
      }, {
        "level": 2,
        "code": 62,
        "name": "Health Care and Social Assistance",
        "notes": ""
      }, {
        "level": 3,
        "code": 621,
        "name": "Ambulatory Health Care Services",
        "notes": ""
      }, {
        "level": 4,
        "code": 6211,
        "name": "Offices of Physicians",
        "notes": ""
      }, {
        "level": 5,
        "code": 62111,
        "name": "Offices of Physicians",
        "notes": ""
      }, {
        "level": 6,
        "code": 621110,
        "name": "Offices of Physicians",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 6212,
        "name": "Offices of Dentists",
        "notes": ""
      }, {
        "level": 5,
        "code": 62121,
        "name": "Offices of Dentists",
        "notes": ""
      }, {
        "level": 6,
        "code": 621210,
        "name": "Offices of Dentists",
        "notes": "US"
      }, {
        "level": 4,
        "code": 6213,
        "name": "Offices of Other Health Practitioners",
        "notes": ""
      }, {
        "level": 5,
        "code": 62131,
        "name": "Offices of Chiropractors",
        "notes": ""
      }, {
        "level": 6,
        "code": 621310,
        "name": "Offices of Chiropractors",
        "notes": "US"
      }, {
        "level": 5,
        "code": 62132,
        "name": "Offices of Optometrists",
        "notes": ""
      }, {
        "level": 6,
        "code": 621320,
        "name": "Offices of Optometrists",
        "notes": ""
      }, {
        "level": 5,
        "code": 62133,
        "name": "Offices of Mental Health Practitioners (except Physicians)",
        "notes": ""
      }, {
        "level": 6,
        "code": 621330,
        "name": "Offices of Mental Health Practitioners (except Physicians)",
        "notes": "US"
      }, {
        "level": 5,
        "code": 62134,
        "name": "Offices of Physical, Occupational, and Speech Therapists and Audiologists",
        "notes": ""
      }, {
        "level": 6,
        "code": 621340,
        "name": "Offices of Physical, Occupational, and Speech Therapists and Audiologists",
        "notes": "US"
      }, {
        "level": 5,
        "code": 62139,
        "name": "Offices of All Other Health Practitioners",
        "notes": ""
      }, {
        "level": 6,
        "code": 621390,
        "name": "Offices of All Other Health Practitioners",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 6214,
        "name": "Out-Patient Care Centres",
        "notes": ""
      }, {
        "level": 5,
        "code": 62141,
        "name": "Family Planning Centres",
        "notes": ""
      }, {
        "level": 6,
        "code": 621410,
        "name": "Family Planning Centres",
        "notes": "US"
      }, {
        "level": 5,
        "code": 62142,
        "name": "Out-Patient Mental Health and Substance Abuse Centres",
        "notes": ""
      }, {
        "level": 6,
        "code": 621420,
        "name": "Out-Patient Mental Health and Substance Abuse Centres",
        "notes": "US"
      }, {
        "level": 5,
        "code": 62149,
        "name": "Other Out-Patient Care Centres",
        "notes": ""
      }, {
        "level": 6,
        "code": 621494,
        "name": "Community Health Centres",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 621499,
        "name": "All Other Out-Patient Care Centres",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 6215,
        "name": "Medical and Diagnostic Laboratories",
        "notes": ""
      }, {
        "level": 5,
        "code": 62151,
        "name": "Medical and Diagnostic Laboratories",
        "notes": ""
      }, {
        "level": 6,
        "code": 621510,
        "name": "Medical and Diagnostic Laboratories",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 6216,
        "name": "Home Health Care Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 62161,
        "name": "Home Health Care Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 621610,
        "name": "Home Health Care Services",
        "notes": ""
      }, {
        "level": 4,
        "code": 6219,
        "name": "Other Ambulatory Health Care Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 62191,
        "name": "Ambulance Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 621911,
        "name": "Ambulance (except Air Ambulance) Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 621912,
        "name": "Air Ambulance Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 62199,
        "name": "All Other Ambulatory Health Care Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 621990,
        "name": "All Other Ambulatory Health Care Services",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 622,
        "name": "Hospitals",
        "notes": ""
      }, {
        "level": 4,
        "code": 6221,
        "name": "General Medical and Surgical Hospitals",
        "notes": ""
      }, {
        "level": 5,
        "code": 62211,
        "name": "General Medical and Surgical Hospitals",
        "notes": ""
      }, {
        "level": 6,
        "code": 622111,
        "name": "General (except Paediatric) Hospitals",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 622112,
        "name": "Paediatric Hospitals",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 6222,
        "name": "Psychiatric and Substance Abuse Hospitals",
        "notes": ""
      }, {
        "level": 5,
        "code": 62221,
        "name": "Psychiatric and Substance Abuse Hospitals",
        "notes": ""
      }, {
        "level": 6,
        "code": 622210,
        "name": "Psychiatric and Substance Abuse Hospitals",
        "notes": "US"
      }, {
        "level": 4,
        "code": 6223,
        "name": "Specialty (except Psychiatric and Substance Abuse) Hospitals",
        "notes": ""
      }, {
        "level": 5,
        "code": 62231,
        "name": "Specialty (except Psychiatric and Substance Abuse) Hospitals",
        "notes": ""
      }, {
        "level": 6,
        "code": 622310,
        "name": "Specialty (except Psychiatric and Substance Abuse) Hospitals",
        "notes": "US"
      }, {
        "level": 3,
        "code": 623,
        "name": "Nursing and Residential Care Facilities",
        "notes": ""
      }, {
        "level": 4,
        "code": 6231,
        "name": "Nursing Care Facilities",
        "notes": ""
      }, {
        "level": 5,
        "code": 62311,
        "name": "Nursing Care Facilities",
        "notes": ""
      }, {
        "level": 6,
        "code": 623110,
        "name": "Nursing Care Facilities",
        "notes": "US"
      }, {
        "level": 4,
        "code": 6232,
        "name": "Residential Developmental Handicap, Mental Health and Substance Abuse Facilities",
        "notes": ""
      }, {
        "level": 5,
        "code": 62321,
        "name": "Residential Developmental Handicap Facilities",
        "notes": ""
      }, {
        "level": 6,
        "code": 623210,
        "name": "Residential Developmental Handicap Facilities",
        "notes": "US"
      }, {
        "level": 5,
        "code": 62322,
        "name": "Residential Mental Health and Substance Abuse Facilities",
        "notes": ""
      }, {
        "level": 6,
        "code": 623221,
        "name": "Residential Substance Abuse Facilities",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 623222,
        "name": "Homes for the Psychiatrically Disabled",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 6233,
        "name": "Community Care Facilities for the Elderly",
        "notes": ""
      }, {
        "level": 5,
        "code": 62331,
        "name": "Community Care Facilities for the Elderly",
        "notes": ""
      }, {
        "level": 6,
        "code": 623310,
        "name": "Community Care Facilities for the Elderly",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 6239,
        "name": "Other Residential Care Facilities",
        "notes": ""
      }, {
        "level": 5,
        "code": 62399,
        "name": "Other Residential Care Facilities",
        "notes": ""
      }, {
        "level": 6,
        "code": 623991,
        "name": "Transition Homes for Women",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 623992,
        "name": "Homes for Emotionally Disturbed Children",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 623993,
        "name": "Homes for the Physically Handicapped or Disabled",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 623999,
        "name": "All Other Residential Care Facilities",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 624,
        "name": "Social Assistance",
        "notes": ""
      }, {
        "level": 4,
        "code": 6241,
        "name": "Individual and Family Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 62411,
        "name": "Child and Youth Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 624110,
        "name": "Child and Youth Services",
        "notes": "US"
      }, {
        "level": 5,
        "code": 62412,
        "name": "Services for the Elderly and Persons with Disabilities",
        "notes": ""
      }, {
        "level": 6,
        "code": 624120,
        "name": "Services for the Elderly and Persons with Disabilities",
        "notes": "US"
      }, {
        "level": 5,
        "code": 62419,
        "name": "Other Individual and Family Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 624190,
        "name": "Other Individual and Family Services",
        "notes": "US"
      }, {
        "level": 4,
        "code": 6242,
        "name": "Community Food and Housing, and Emergency and Other Relief Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 62421,
        "name": "Community Food Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 624210,
        "name": "Community Food Services",
        "notes": "US"
      }, {
        "level": 5,
        "code": 62422,
        "name": "Community Housing Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 624220,
        "name": "Community Housing Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 62423,
        "name": "Emergency and Other Relief Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 624230,
        "name": "Emergency and Other Relief Services",
        "notes": "US"
      }, {
        "level": 4,
        "code": 6243,
        "name": "Vocational Rehabilitation Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 62431,
        "name": "Vocational Rehabilitation Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 624310,
        "name": "Vocational Rehabilitation Services",
        "notes": "US"
      }, {
        "level": 4,
        "code": 6244,
        "name": "Child Day-Care Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 62441,
        "name": "Child Day-Care Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 624410,
        "name": "Child Day-Care Services",
        "notes": "US"
      }, {
        "level": 3,
        "code": 711,
        "name": "Performing Arts, Spectator Sports and Related Industries",
        "notes": ""
      }, {
        "level": 4,
        "code": 7111,
        "name": "Performing Arts Companies",
        "notes": ""
      }, {
        "level": 5,
        "code": 71111,
        "name": "Theatre Companies and Dinner Theatres",
        "notes": ""
      }, {
        "level": 6,
        "code": 711111,
        "name": "Theatre (except Musical) Companies",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 711112,
        "name": "Musical Theatre and Opera Companies",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 71112,
        "name": "Dance Companies",
        "notes": ""
      }, {
        "level": 6,
        "code": 711120,
        "name": "Dance Companies",
        "notes": "US"
      }, {
        "level": 5,
        "code": 71113,
        "name": "Musical Groups and Artists",
        "notes": ""
      }, {
        "level": 6,
        "code": 711130,
        "name": "Musical Groups and Artists",
        "notes": "US"
      }, {
        "level": 5,
        "code": 71119,
        "name": "Other Performing Arts Companies",
        "notes": ""
      }, {
        "level": 6,
        "code": 711190,
        "name": "Other Performing Arts Companies",
        "notes": "US"
      }, {
        "level": 4,
        "code": 7112,
        "name": "Spectator Sports",
        "notes": ""
      }, {
        "level": 5,
        "code": 71121,
        "name": "Spectator Sports",
        "notes": ""
      }, {
        "level": 6,
        "code": 711211,
        "name": "Sports Teams and Clubs",
        "notes": "US"
      }, {
        "level": 6,
        "code": 711213,
        "name": "Horse Race Tracks",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 711218,
        "name": "Other Spectator Sports",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 7113,
        "name": "Promoters (Presenters) of Performing Arts, Sports and Similar Events",
        "notes": ""
      }, {
        "level": 5,
        "code": 71131,
        "name": "Promoters (Presenters) of Performing Arts, Sports and Similar Events with Facilities",
        "notes": ""
      }, {
        "level": 6,
        "code": 711311,
        "name": "Live Theatres and Other Performing Arts Presenters with Facilities",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 711319,
        "name": "Sports Stadiums and Other Presenters with Facilities",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 71132,
        "name": "Promoters (Presenters) of Performing Arts, Sports and Similar Events without Facilities",
        "notes": ""
      }, {
        "level": 6,
        "code": 711321,
        "name": "Performing Arts Promoters (Presenters) without Facilities",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 711322,
        "name": "Festivals without Facilities",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 711329,
        "name": "Sports Presenters and Other Presenters without Facilities",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 7114,
        "name": "Agents and Managers for Artists, Athletes, Entertainers and Other Public Figures",
        "notes": ""
      }, {
        "level": 5,
        "code": 71141,
        "name": "Agents and Managers for Artists, Athletes, Entertainers and Other Public Figures",
        "notes": ""
      }, {
        "level": 6,
        "code": 711410,
        "name": "Agents and Managers for Artists, Athletes, Entertainers and Other Public Figures",
        "notes": ""
      }, {
        "level": 4,
        "code": 7115,
        "name": "Independent Artists, Writers and Performers",
        "notes": ""
      }, {
        "level": 5,
        "code": 71151,
        "name": "Independent Artists, Writers and Performers",
        "notes": ""
      }, {
        "level": 6,
        "code": 711511,
        "name": "Independent Artists, Visual Arts",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 711512,
        "name": "Independent Actors, Comedians and Performers",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 711513,
        "name": "Independent Writers and Authors",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 712,
        "name": "Heritage Institutions",
        "notes": ""
      }, {
        "level": 4,
        "code": 7121,
        "name": "Heritage Institutions",
        "notes": ""
      }, {
        "level": 5,
        "code": 71211,
        "name": "Museums",
        "notes": ""
      }, {
        "level": 6,
        "code": 712111,
        "name": "Non-Commercial Art Museums and Galleries",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 712115,
        "name": "History and Science Museums",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 712119,
        "name": "Other Museums",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 71212,
        "name": "Historic and Heritage Sites",
        "notes": ""
      }, {
        "level": 6,
        "code": 712120,
        "name": "Historic and Heritage Sites",
        "notes": ""
      }, {
        "level": 5,
        "code": 71213,
        "name": "Zoos and Botanical Gardens",
        "notes": ""
      }, {
        "level": 6,
        "code": 712130,
        "name": "Zoos and Botanical Gardens",
        "notes": "US"
      }, {
        "level": 5,
        "code": 71219,
        "name": "Nature Parks and Other Similar Institutions",
        "notes": ""
      }, {
        "level": 6,
        "code": 712190,
        "name": "Nature Parks and Other Similar Institutions",
        "notes": ""
      }, {
        "level": 3,
        "code": 713,
        "name": "Amusement, Gambling and Recreation Industries",
        "notes": ""
      }, {
        "level": 4,
        "code": 7131,
        "name": "Amusement Parks and Arcades",
        "notes": ""
      }, {
        "level": 5,
        "code": 71311,
        "name": "Amusement and Theme Parks",
        "notes": ""
      }, {
        "level": 6,
        "code": 713110,
        "name": "Amusement and Theme Parks",
        "notes": "US"
      }, {
        "level": 5,
        "code": 71312,
        "name": "Amusement Arcades",
        "notes": ""
      }, {
        "level": 6,
        "code": 713120,
        "name": "Amusement Arcades",
        "notes": ""
      }, {
        "level": 4,
        "code": 7132,
        "name": "Gambling Industries",
        "notes": ""
      }, {
        "level": 5,
        "code": 71321,
        "name": "Casinos (except Casino Hotels)",
        "notes": ""
      }, {
        "level": 6,
        "code": 713210,
        "name": "Casinos (except Casino Hotels)",
        "notes": ""
      }, {
        "level": 5,
        "code": 71329,
        "name": "Other Gambling Industries",
        "notes": ""
      }, {
        "level": 6,
        "code": 713291,
        "name": "Lotteries",
        "notes": "MEX"
      }, {
        "level": 6,
        "code": 713299,
        "name": "All Other Gambling Industries",
        "notes": "MEX"
      }, {
        "level": 4,
        "code": 7139,
        "name": "Other Amusement and Recreation Industries",
        "notes": ""
      }, {
        "level": 5,
        "code": 71391,
        "name": "Golf Courses and Country Clubs",
        "notes": ""
      }, {
        "level": 6,
        "code": 713910,
        "name": "Golf Courses and Country Clubs",
        "notes": ""
      }, {
        "level": 5,
        "code": 71392,
        "name": "Skiing Facilities",
        "notes": ""
      }, {
        "level": 6,
        "code": 713920,
        "name": "Skiing Facilities",
        "notes": ""
      }, {
        "level": 5,
        "code": 71393,
        "name": "Marinas",
        "notes": ""
      }, {
        "level": 6,
        "code": 713930,
        "name": "Marinas",
        "notes": ""
      }, {
        "level": 5,
        "code": 71394,
        "name": "Fitness and Recreational Sports Centres",
        "notes": ""
      }, {
        "level": 6,
        "code": 713940,
        "name": "Fitness and Recreational Sports Centres",
        "notes": "US"
      }, {
        "level": 5,
        "code": 71395,
        "name": "Bowling Centres",
        "notes": ""
      }, {
        "level": 6,
        "code": 713950,
        "name": "Bowling Centres",
        "notes": ""
      }, {
        "level": 5,
        "code": 71399,
        "name": "All Other Amusement and Recreation Industries",
        "notes": ""
      }, {
        "level": 6,
        "code": 713990,
        "name": "All Other Amusement and Recreation Industries",
        "notes": "US"
      }, {
        "level": 2,
        "code": 72,
        "name": "Accommodation and Food Services",
        "notes": ""
      }, {
        "level": 3,
        "code": 721,
        "name": "Accommodation Services",
        "notes": ""
      }, {
        "level": 4,
        "code": 7211,
        "name": "Traveller Accommodation",
        "notes": ""
      }, {
        "level": 5,
        "code": 72111,
        "name": "Hotels (except Casino Hotels) and Motels",
        "notes": ""
      }, {
        "level": 6,
        "code": 721111,
        "name": "Hotels",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 721112,
        "name": "Motor Hotels",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 721113,
        "name": "Resorts",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 721114,
        "name": "Motels",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 72112,
        "name": "Casino Hotels",
        "notes": ""
      }, {
        "level": 6,
        "code": 721120,
        "name": "Casino Hotels",
        "notes": ""
      }, {
        "level": 5,
        "code": 72119,
        "name": "Other Traveller Accommodation",
        "notes": ""
      }, {
        "level": 6,
        "code": 721191,
        "name": "Bed and Breakfast",
        "notes": "US"
      }, {
        "level": 6,
        "code": 721192,
        "name": "Housekeeping Cottages and Cabins",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 721198,
        "name": "All Other Traveller Accommodation",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 7212,
        "name": "RV (Recreational Vehicle) Parks and Recreational Camps",
        "notes": ""
      }, {
        "level": 5,
        "code": 72121,
        "name": "RV (Recreational Vehicle) Parks and Recreational Camps",
        "notes": ""
      }, {
        "level": 6,
        "code": 721211,
        "name": "RV (Recreational Vehicle) Parks and Campgrounds",
        "notes": "US"
      }, {
        "level": 6,
        "code": 721212,
        "name": "Hunting and Fishing Camps",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 721213,
        "name": "Recreational (except Hunting and Fishing) and Vacation Camps",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 7213,
        "name": "Rooming and Boarding Houses",
        "notes": ""
      }, {
        "level": 5,
        "code": 72131,
        "name": "Rooming and Boarding Houses",
        "notes": ""
      }, {
        "level": 6,
        "code": 721310,
        "name": "Rooming and Boarding Houses",
        "notes": "US"
      }, {
        "level": 3,
        "code": 722,
        "name": "Food Services and Drinking Places",
        "notes": ""
      }, {
        "level": 4,
        "code": 7221,
        "name": "Full-Service Restaurants",
        "notes": ""
      }, {
        "level": 5,
        "code": 72211,
        "name": "Full-Service Restaurants",
        "notes": ""
      }, {
        "level": 6,
        "code": 722110,
        "name": "Full-Service Restaurants",
        "notes": "US"
      }, {
        "level": 4,
        "code": 7222,
        "name": "Limited-Service Eating Places",
        "notes": ""
      }, {
        "level": 5,
        "code": 72221,
        "name": "Limited-Service Eating Places",
        "notes": ""
      }, {
        "level": 6,
        "code": 722210,
        "name": "Limited-Service Eating Places",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 7223,
        "name": "Special Food Services",
        "notes": ""
      }, {
        "level": 5,
        "code": 72231,
        "name": "Food Service Contractors",
        "notes": ""
      }, {
        "level": 6,
        "code": 722310,
        "name": "Food Service Contractors",
        "notes": ""
      }, {
        "level": 5,
        "code": 72232,
        "name": "Caterers",
        "notes": ""
      }, {
        "level": 6,
        "code": 722320,
        "name": "Caterers",
        "notes": ""
      }, {
        "level": 5,
        "code": 72233,
        "name": "Mobile Food Services",
        "notes": ""
      }, {
        "level": 6,
        "code": 722330,
        "name": "Mobile Food Services",
        "notes": ""
      }, {
        "level": 4,
        "code": 7224,
        "name": "Drinking Places (Alcoholic Beverages)",
        "notes": ""
      }, {
        "level": 5,
        "code": 72241,
        "name": "Drinking Places (Alcoholic Beverages)",
        "notes": ""
      }, {
        "level": 6,
        "code": 722410,
        "name": "Drinking Places (Alcoholic Beverages)",
        "notes": "US"
      }, {
        "level": 2,
        "code": 81,
        "name": "Other Services (except Public Administration)",
        "notes": ""
      }, {
        "level": 3,
        "code": 811,
        "name": "Repair and Maintenance",
        "notes": ""
      }, {
        "level": 4,
        "code": 8111,
        "name": "Automotive Repair and Maintenance",
        "notes": ""
      }, {
        "level": 5,
        "code": 81111,
        "name": "Automotive Mechanical and Electrical Repair and Maintenance",
        "notes": ""
      }, {
        "level": 6,
        "code": 811111,
        "name": "General Automotive Repair",
        "notes": "US"
      }, {
        "level": 6,
        "code": 811112,
        "name": "Automotive Exhaust System Repair",
        "notes": "US"
      }, {
        "level": 6,
        "code": 811119,
        "name": "Other Automotive Mechanical and Electrical Repair and Maintenance",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 81112,
        "name": "Automotive Body, Paint, Interior and Glass Repair",
        "notes": ""
      }, {
        "level": 6,
        "code": 811121,
        "name": "Automotive Body, Paint and Interior Repair and Maintenance",
        "notes": "US"
      }, {
        "level": 6,
        "code": 811122,
        "name": "Automotive Glass Replacement Shops",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81119,
        "name": "Other Automotive Repair and Maintenance",
        "notes": ""
      }, {
        "level": 6,
        "code": 811192,
        "name": "Car Washes",
        "notes": "US"
      }, {
        "level": 6,
        "code": 811199,
        "name": "All Other Automotive Repair and Maintenance",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 8112,
        "name": "Electronic and Precision Equipment Repair and Maintenance",
        "notes": ""
      }, {
        "level": 5,
        "code": 81121,
        "name": "Electronic and Precision Equipment Repair and Maintenance",
        "notes": ""
      }, {
        "level": 6,
        "code": 811210,
        "name": "Electronic and Precision Equipment Repair and Maintenance",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 8113,
        "name": "Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance",
        "notes": ""
      }, {
        "level": 5,
        "code": 81131,
        "name": "Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance",
        "notes": ""
      }, {
        "level": 6,
        "code": 811310,
        "name": "Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance",
        "notes": "US"
      }, {
        "level": 4,
        "code": 8114,
        "name": "Personal and Household Goods Repair and Maintenance",
        "notes": ""
      }, {
        "level": 5,
        "code": 81141,
        "name": "Home and Garden Equipment and Appliance Repair and Maintenance",
        "notes": ""
      }, {
        "level": 6,
        "code": 811411,
        "name": "Home and Garden Equipment Repair and Maintenance",
        "notes": "US"
      }, {
        "level": 6,
        "code": 811412,
        "name": "Appliance Repair and Maintenance",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81142,
        "name": "Reupholstery and Furniture Repair",
        "notes": ""
      }, {
        "level": 6,
        "code": 811420,
        "name": "Reupholstery and Furniture Repair",
        "notes": ""
      }, {
        "level": 5,
        "code": 81143,
        "name": "Footwear and Leather Goods Repair",
        "notes": ""
      }, {
        "level": 6,
        "code": 811430,
        "name": "Footwear and Leather Goods Repair",
        "notes": ""
      }, {
        "level": 5,
        "code": 81149,
        "name": "Other Personal and Household Goods Repair and Maintenance",
        "notes": ""
      }, {
        "level": 6,
        "code": 811490,
        "name": "Other Personal and Household Goods Repair and Maintenance",
        "notes": "US"
      }, {
        "level": 3,
        "code": 812,
        "name": "Personal and Laundry Services",
        "notes": ""
      }, {
        "level": 4,
        "code": 8121,
        "name": "Personal Care Services",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81211,
        "name": "Hair Care and Esthetic Services",
        "notes": "US"
      }, {
        "level": 6,
        "code": 812114,
        "name": "Barber Shops",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 812115,
        "name": "Beauty Salons",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 812116,
        "name": "Unisex Hair Salons",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 81219,
        "name": "Other Personal Care Services",
        "notes": "US"
      }, {
        "level": 6,
        "code": 812190,
        "name": "Other Personal Care Services",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 8122,
        "name": "Funeral Services",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81221,
        "name": "Funeral Homes",
        "notes": "US"
      }, {
        "level": 6,
        "code": 812210,
        "name": "Funeral Homes",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81222,
        "name": "Cemeteries and Crematoria",
        "notes": "US"
      }, {
        "level": 6,
        "code": 812220,
        "name": "Cemeteries and Crematoria",
        "notes": "US"
      }, {
        "level": 4,
        "code": 8123,
        "name": "Dry Cleaning and Laundry Services",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81231,
        "name": "Coin-Operated Laundries and Dry Cleaners",
        "notes": "US"
      }, {
        "level": 6,
        "code": 812310,
        "name": "Coin-Operated Laundries and Dry Cleaners",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81232,
        "name": "Dry Cleaning and Laundry Services (except Coin-Operated)",
        "notes": "US"
      }, {
        "level": 6,
        "code": 812320,
        "name": "Dry Cleaning and Laundry Services (except Coin-Operated)",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81233,
        "name": "Linen and Uniform Supply",
        "notes": "US"
      }, {
        "level": 6,
        "code": 812330,
        "name": "Linen and Uniform Supply",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 8129,
        "name": "Other Personal Services",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81291,
        "name": "Pet Care (except Veterinary) Services",
        "notes": "US"
      }, {
        "level": 6,
        "code": 812910,
        "name": "Pet Care (except Veterinary) Services",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81292,
        "name": "Photo Finishing Services",
        "notes": "US"
      }, {
        "level": 6,
        "code": 812921,
        "name": "Photo Finishing Laboratories (except One-Hour)",
        "notes": "US"
      }, {
        "level": 6,
        "code": 812922,
        "name": "One-Hour Photo Finishing",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81293,
        "name": "Parking Lots and Garages",
        "notes": "US"
      }, {
        "level": 6,
        "code": 812930,
        "name": "Parking Lots and Garages",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81299,
        "name": "All Other Personal Services",
        "notes": "US"
      }, {
        "level": 6,
        "code": 812990,
        "name": "All Other Personal Services",
        "notes": "US"
      }, {
        "level": 3,
        "code": 813,
        "name": "Religious, Grant-Making, Civic, and Professional and Similar Organizations",
        "notes": ""
      }, {
        "level": 4,
        "code": 8131,
        "name": "Religious Organizations",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81311,
        "name": "Religious Organizations",
        "notes": "US"
      }, {
        "level": 6,
        "code": 813110,
        "name": "Religious Organizations",
        "notes": "US"
      }, {
        "level": 4,
        "code": 8132,
        "name": "Grant-Making and Giving Services",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81321,
        "name": "Grant-Making and Giving Services",
        "notes": "US"
      }, {
        "level": 6,
        "code": 813210,
        "name": "Grant-Making and Giving Services",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 8133,
        "name": "Social Advocacy Organizations",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81331,
        "name": "Social Advocacy Organizations",
        "notes": "US"
      }, {
        "level": 6,
        "code": 813310,
        "name": "Social Advocacy Organizations",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 8134,
        "name": "Civic and Social Organizations",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81341,
        "name": "Civic and Social Organizations",
        "notes": "US"
      }, {
        "level": 6,
        "code": 813410,
        "name": "Civic and Social Organizations",
        "notes": "US"
      }, {
        "level": 4,
        "code": 8139,
        "name": "Business, Professional, Labour and Other Membership Organizations",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81391,
        "name": "Business Associations",
        "notes": "US"
      }, {
        "level": 6,
        "code": 813910,
        "name": "Business Associations",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81392,
        "name": "Professional Organizations",
        "notes": "US"
      }, {
        "level": 6,
        "code": 813920,
        "name": "Professional Organizations",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81393,
        "name": "Labour Organizations",
        "notes": "US"
      }, {
        "level": 6,
        "code": 813930,
        "name": "Labour Organizations",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81394,
        "name": "Political Organizations",
        "notes": "US"
      }, {
        "level": 6,
        "code": 813940,
        "name": "Political Organizations",
        "notes": "US"
      }, {
        "level": 5,
        "code": 81399,
        "name": "Other Membership Organizations",
        "notes": "US"
      }, {
        "level": 6,
        "code": 813990,
        "name": "Other Membership Organizations",
        "notes": "US"
      }, {
        "level": 3,
        "code": 814,
        "name": "Private Households",
        "notes": ""
      }, {
        "level": 4,
        "code": 8141,
        "name": "Private Households",
        "notes": ""
      }, {
        "level": 5,
        "code": 81411,
        "name": "Private Households",
        "notes": ""
      }, {
        "level": 6,
        "code": 814110,
        "name": "Private Households",
        "notes": ""
      }, {
        "level": 2,
        "code": 91,
        "name": "Public Administration",
        "notes": ""
      }, {
        "level": 3,
        "code": 911,
        "name": "Federal Government Public Administration",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 9111,
        "name": "Defence Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91111,
        "name": "Defence Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 911110,
        "name": "Defence Services",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 9112,
        "name": "Federal Protective Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91121,
        "name": "Federal Courts of Law",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 911210,
        "name": "Federal Courts of Law",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91122,
        "name": "Federal Correctional Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 911220,
        "name": "Federal Correctional Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91123,
        "name": "Federal Police Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 911230,
        "name": "Federal Police Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91124,
        "name": "Federal Regulatory Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 911240,
        "name": "Federal Regulatory Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91129,
        "name": "Other Federal Protective Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 911290,
        "name": "Other Federal Protective Services",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 9113,
        "name": "Federal Labour, Employment and Immigration Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91131,
        "name": "Federal Labour and Employment Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 911310,
        "name": "Federal Labour and Employment Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91132,
        "name": "Immigration Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 911320,
        "name": "Immigration Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91139,
        "name": "Other Federal Labour, Employment and Immigration Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 911390,
        "name": "Other Federal Labour, Employment and Immigration Services",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 9114,
        "name": "Foreign Affairs and International Assistance",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91141,
        "name": "Foreign Affairs",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 911410,
        "name": "Foreign Affairs",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91142,
        "name": "International Assistance",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 911420,
        "name": "International Assistance",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 9119,
        "name": "Other Federal Government Public Administration",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91191,
        "name": "Other Federal Government Public Administration",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 911910,
        "name": "Other Federal Government Public Administration",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 912,
        "name": "Provincial and Territorial Public Administration",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 9121,
        "name": "Provincial Protective Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91211,
        "name": "Provincial Courts of Law",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 912110,
        "name": "Provincial Courts of Law",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91212,
        "name": "Provincial Correctional Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 912120,
        "name": "Provincial Correctional Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91213,
        "name": "Provincial Police Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 912130,
        "name": "Provincial Police Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91214,
        "name": "Provincial Fire-Fighting Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 912140,
        "name": "Provincial Fire-Fighting Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91215,
        "name": "Provincial Regulatory Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 912150,
        "name": "Provincial Regulatory Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91219,
        "name": "Other Provincial Protective Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 912190,
        "name": "Other Provincial Protective Services",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 9122,
        "name": "Provincial Labour and Employment Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91221,
        "name": "Provincial Labour and Employment Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 912210,
        "name": "Provincial Labour and Employment Services",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 9129,
        "name": "Other Provincial and Territorial Public Administration",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91291,
        "name": "Other Provincial and Territorial Public Administration",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 912910,
        "name": "Other Provincial and Territorial Public Administration",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 913,
        "name": "Local, Municipal and Regional Public Administration",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 9131,
        "name": "Municipal Protective Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91311,
        "name": "Municipal Courts of Law",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 913110,
        "name": "Municipal Courts of Law",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91312,
        "name": "Municipal Correctional Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 913120,
        "name": "Municipal Correctional Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91313,
        "name": "Municipal Police Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 913130,
        "name": "Municipal Police Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91314,
        "name": "Municipal Fire-Fighting Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 913140,
        "name": "Municipal Fire-Fighting Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91315,
        "name": "Municipal Regulatory Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 913150,
        "name": "Municipal Regulatory Services",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91319,
        "name": "Other Municipal Protective Services",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 913190,
        "name": "Other Municipal Protective Services",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 9139,
        "name": "Other Local, Municipal and Regional Public Administration",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91391,
        "name": "Other Local, Municipal and Regional Public Administration",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 913910,
        "name": "Other Local, Municipal and Regional Public Administration",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 914,
        "name": "Aboriginal Public Administration",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 9141,
        "name": "Aboriginal Public Administration",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91411,
        "name": "Aboriginal Public Administration",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 914110,
        "name": "Aboriginal Public Administration",
        "notes": "CAN"
      }, {
        "level": 3,
        "code": 919,
        "name": "International and Other Extra-Territorial Public Administration",
        "notes": "CAN"
      }, {
        "level": 4,
        "code": 9191,
        "name": "International and Other Extra-Territorial Public Administration",
        "notes": "CAN"
      }, {
        "level": 5,
        "code": 91911,
        "name": "International and Other Extra-Territorial Public Administration",
        "notes": "CAN"
      }, {
        "level": 6,
        "code": 919110,
        "name": "International and Other Extra-Territorial Public Administration",
        "notes": "CAN"
      }]
    };
  }
});