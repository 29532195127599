define("account-berry-frontend/companies/show/ath-movil/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.findAll('ath-movil', {
        reload: true
      }).then(function (athMovil) {
        return athMovil.get('firstObject') || {
          setup: true
        };
      });
    },
    setupController: function setupController(controller) {
      controller.set('breadCrumb', 'ATH Business');
      controller.set('companyId', this.paramsFor('companies.show').company_id);
      this.store.findAll('account', {
        reload: true
      }).then(function (accounts) {
        controller.set('accounts', accounts);
      });
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.ath-movil', {
        into: 'application',
        model: model
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});