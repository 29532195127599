define("account-berry-frontend/account/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    account_type_id: _emberData.default.attr(),
    account_type: _emberData.default.belongsTo('account-type'),
    company_id: _emberData.default.attr('number'),
    group: _emberData.default.attr(),
    special_account: _emberData.default.attr(),
    name: _emberData.default.attr(),
    number: _emberData.default.attr(),
    tax_id: _emberData.default.attr(),
    undeletable: _emberData.default.attr(),
    is_title: _emberData.default.attr(),
    sub_accounts: _emberData.default.attr(),
    // sub_accounts: DS.hasMany('sub-account'),
    is_sub_account: _emberData.default.attr(),
    title_amount: _emberData.default.attr('number'),
    title_account: _emberData.default.attr(),
    sub_account_of: _emberData.default.attr(),
    amount: _emberData.default.attr('number'),
    transactions: _emberData.default.attr(),
    has_transactions: _emberData.default.attr(),
    reference: _emberData.default.attr(),
    error: _emberData.default.attr(),
    last_reconciliation: _emberData.default.attr(),
    activity: _emberData.default.attr(),
    routing_number: _emberData.default.attr(),
    account_number: _emberData.default.attr(),
    bank_name: _emberData.default.attr(),
    bank_address: _emberData.default.attr(),
    check_message: _emberData.default.attr(),
    year_end_report_line: _emberData.default.attr('string'),
    subject_to_vendor_withholding: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});