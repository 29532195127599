define("account-berry-frontend/components/new-company-step-three/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super();

      var today = new Date();
      this.set('account', {
        number: "10001",
        initial_balance: 0.00,
        initial_balance_date: moment(today).format('YYYY-MM-DD'),
        account_name: "Cash in Bank"
      });
    },
    actions: {
      updateCompanyData: function updateCompanyData() {
        this.set('company.bank_account', this.get('account'));
        this.sendAction('updateCompanyData', this.get('company'), 'stepThree', 'stepFour');
      },
      back: function back() {
        this.sendAction('updateCompanyData', this.get('company'), 'stepThree', 'stepTwo');
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      }
    }
  });

  _exports.default = _default;
});