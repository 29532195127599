define("account-berry-frontend/components/dashboard-aging-summary/component", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    ajaxCall: Ember.inject.service(),
    agingSummary: Ember.computed('apAgingData', 'arAgingData', function () {
      if (this.get('apAgingData') && this.get('arAgingData')) {
        return {
          labels: ["Accounts Payable: $".concat(parseFloat(this.get('apAgingData.totals.total')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')), "Accounts Receivable: $".concat(parseFloat(this.get('arAgingData.totals.total')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'))],
          datasets: [{
            label: 'Current',
            data: [this.get('apAgingData.totals.current'), this.get('arAgingData.totals.current')],
            backgroundColor: 'rgba(136, 70, 218, 1)'
          }, {
            label: '0 - 30',
            data: [this.get('apAgingData.totals.first_column'), this.get('arAgingData.totals.first_column')],
            backgroundColor: 'rgba(93, 59, 203, 1)'
          }, {
            label: '30 - 60',
            data: [this.get('apAgingData.totals.second_column'), this.get('arAgingData.totals.second_column')],
            backgroundColor: 'rgba(35, 187, 27, 1)'
          }, {
            label: '60 - 90',
            data: [this.get('apAgingData.totals.third_column'), this.get('arAgingData.totals.third_column')],
            backgroundColor: 'rgba(51, 51, 51, 1)'
          }, {
            label: '90 >',
            data: [this.get('apAgingData.totals.fourth_column'), this.get('arAgingData.totals.fourth_column')],
            backgroundColor: 'rgba(151, 220, 134, 1)'
          }]
        };
      }
    }),
    agingSummaryOptions: Ember.computed('agingSummary', function () {
      if (this.get('agingSummary')) {
        return {
          scales: {
            xAxes: [{
              ticks: {
                display: false,
                beginAtZero: true
              },
              stacked: true,
              gridLines: {
                display: false
              }
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false
              }
            }]
          }
        };
      }
    })
  });

  _exports.default = _default;
});