define("account-berry-frontend/companies/show/reports/customer-statements/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.set('breadCrumb', 'Customer Statement');
      controller.set('companyId', this.paramsFor('companies.show').company_id);
      this.store.findAll('customer').then(function (customers) {
        controller.set('customers', customers);
      });
      this.store.findAll('payee').then(function (payees) {
        controller.set('payees', payees);
      });
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.reports.customer-statements', {
        into: 'application',
        model: model
      });
    }
  });

  _exports.default = _default;
});