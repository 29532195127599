define("account-berry-frontend/companies/show/vendors/receiving-and-bills/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(param) {
      return this.store.findRecord('receivingAndBill', param.receiving_and_bill_id);
    },
    setupController: function setupController(controller, model) {
      controller.set('breadCrumb', model.id);
      controller.set('companyId', this.paramsFor('companies.show').company_id);
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.vendors.receiving-and-bills.show', {
        into: 'application',
        model: model
      });
    }
  });

  _exports.default = _default;
});