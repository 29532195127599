define("account-berry-frontend/json-storage/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    stored_json: _emberData.default.attr('')
  });

  _exports.default = _default;
});