define("account-berry-frontend/components/back-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IkYj1UM9",
    "block": "{\"symbols\":[],\"statements\":[[6,\"a\"],[9,\"id\",\"backButton\"],[9,\"href\",\"javascript:window.history.back();\"],[7],[0,\"\\n  \"],[6,\"button\"],[10,\"class\",[26,[\"btn accountberry-btn \",[20,[\"session\",\"data\",\"authenticated\",\"theme\"]]]]],[7],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"clickable icon-go-back\"],[3,\"action\",[[19,0,[]],\"back\"]],[7],[0,\"Back\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/back-button/template.hbs"
    }
  });

  _exports.default = _default;
});