define("account-berry-frontend/companies/show/vendors/purchase-orders/controller", ["exports", "ember-cli-pagination/computed/paged-array"], function (_exports, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    newPurchaseOrder: false,
    purchaseOrderForm: false,
    confirmDelete: false,
    search: "",
    sortAscending: false,
    pagedPurchaseOrders: (0, _pagedArray.default)('filteredPurchaseOrders', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    modelObserver: Ember.observer('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      try {
        this.set('filteredPurchaseOrders', this.store.peekAll('purchase-order').filter(function (purchaseOrder) {
          return !purchaseOrder.get('isDeleted');
        }));
        this.set('pagedPurchaseOrders', (0, _pagedArray.default)('filteredPurchaseOrders', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this = this;

      var channel = this.get('session.pusher').subscribe("purchase-order-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          _this.updatePurchaseOrderList(data.purchase_order);
        }
      });
      channel.bind('updated', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var purchaseOrderId = data.purchase_order.id;
          delete data.purchase_order.id;

          var purchaseOrder = _this.store.peekRecord('purchase-order', purchaseOrderId);

          purchaseOrder.setProperties(data.purchase_order);
        }
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var purchaseOrder = _this.store.peekRecord('purchase-order', data.purchase_order_id);

          purchaseOrder.deleteRecord();

          _this.set('filteredPurchaseOrders', _this.store.peekAll('purchase-order').filter(function (purchaseOrder) {
            return !purchaseOrder.get('isDeleted');
          }));

          _this.set('pagedPurchaseOrders', (0, _pagedArray.default)('filteredPurchaseOrders', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        }
      });
      this.set('subscribedToChannel', true);
    },
    updatePurchaseOrderList: function updatePurchaseOrderList(purchaseOrder) {
      this.store.push({
        data: {
          id: purchaseOrder.id,
          type: 'purchase-order',
          attributes: purchaseOrder
        }
      });
      this.set('filteredPurchaseOrders', this.store.peekAll('purchase-order').filter(function (purchaseOrder) {
        return !purchaseOrder.get('isDeleted');
      }));
      this.set('pagedPurchaseOrders', (0, _pagedArray.default)('filteredPurchaseOrders', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    totalPurchaseOrders: Ember.computed('filteredPurchaseOrders', function () {
      return this.get('filteredPurchaseOrders.length');
    }),
    filter: 'po_number',
    filters: [{
      name: "NUMBER",
      value: "po_number"
    }, {
      name: "SALES ORDER DATE",
      value: "po_date"
    }, {
      name: "VENDOR",
      value: "vendor"
    }],
    filteredPurchaseOrders: Ember.computed('model', function () {
      return this.model;
    }),
    filterPurchaseOrders: Ember.observer('search', 'filterLabelIds', function () {
      var _this2 = this;

      var search = this.search;
      var filter = this.filter;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      if (search === "" || search.trim().length === 0) {
        this.set('filteredPurchaseOrders', this.model);
      } else {
        this.set('filteredPurchaseOrders', this.model.filter(function (purchaseOrder) {
          var purchaseOrderValue = purchaseOrder.get(filter);

          if (purchaseOrderValue) {
            if (isNaN(purchaseOrderValue)) {
              purchaseOrderValue = purchaseOrderValue.toLowerCase();
            }

            if (purchaseOrderValue.includes(search)) {
              return purchaseOrder;
            }
          }
        }));
      }

      if (this.get('filterLabelIds') && this.get('filterLabelIds').length > 0) {
        var hasLabel;
        this.set('filteredPurchaseOrders', this.get('filteredPurchaseOrders').filter(function (po) {
          hasLabel = false;

          var _iterator = _createForOfIteratorHelper(_this2.get('filterLabelIds')),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var label = _step.value;
              hasLabel = po.get('labels').find(function (l) {
                return l.id.toString() === label.toString();
              });

              if (!hasLabel) {
                break;
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          if (hasLabel) {
            return po;
          }
        }));
      }

      this.set('pagedPurchaseOrders', (0, _pagedArray.default)('filteredPurchaseOrders', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['vendors'];
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3><h3 style='text-align: center'>Purchase Orders</h3><table id='purchaseOrdersTable' style='width:100%; font-size: 1vw'><thead><tr>");
      table += "<th style='text-align: left;'>NUMBER</th>";
      table += "<th style='text-align: left;'>ORDER DATE</th>";
      table += "<th style='text-align: left;'>VENDOR</th>";
      table += "<th style='text-align: center;'>CLOSE</th>";
      table += "<th style='text-align: left;'>BACK ORDERED</th>";
      table += "<th style='text-align: left;'>RECEIVED</th>";
      table += "<th style='text-align: right'>NET AMOUNT</th>";
      table += "<th style='text-align: right'>TAX AMOUNT</th>";
      table += "<th style='text-align: right'>FREIGHT AMOUNT</th>";
      table += "<th style='text-align: right'>TOTAL AMOUNT</th></tr></thead><tbody>";
      this.get('filteredPurchaseOrders').forEach(function (purchaseOrder) {
        table += "<td>".concat(purchaseOrder.get('po_number'), "</td>");
        table += "<td>".concat(moment(purchaseOrder.get('po_date')).format('MM/DD/YYYY'), "</td>");
        table += "<td>".concat(purchaseOrder.get('vendor'), "</td>");
        table += "<td style='text-align: center;'>".concat(purchaseOrder.get('closed') ? "YES" : "NO", "</td>");
        table += "<td style='text-align: left'>".concat(purchaseOrder.get('back_ordered_items'), "</td>");
        table += "<td style='text-align: left'>".concat(purchaseOrder.get('received_items'), "</td>");
        table += "<td style='text-align: right'>".concat(parseFloat(purchaseOrder.get('net_po_amount')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style='text-align: right'>".concat(parseFloat(purchaseOrder.get('tax_amount')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style='text-align: right'>".concat(parseFloat(purchaseOrder.get('freight_amount')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style='text-align: right'>".concat(parseFloat(purchaseOrder.get('total_po_amount')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
      });
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);
      },
      toggleNewPurchaseOrder: function toggleNewPurchaseOrder() {
        this.set('purchaseOrder', {});
        this.toggleProperty('newPurchaseOrder');
        this.toggleProperty('purchaseOrderForm');
      },
      toggleEditPurchaseOrder: function toggleEditPurchaseOrder(purchaseOrder) {
        this.set('purchaseOrder', purchaseOrder);
        this.toggleProperty('purchaseOrderForm');
      },
      cancelEdit: function cancelEdit() {
        this.purchaseOrder.rollbackAttributes();
        this.toggleProperty('purchaseOrderForm');
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        var _this3 = this;

        if (id) {
          this.store.findRecord('purchase-order', id, {
            backgroundReload: true
          }).then(function (purchaseOrder) {
            _this3.set('purchaseOrder', purchaseOrder);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      // create() {
      //   this.set('purchaseOrder.company_id', this.companyId);
      //   let newPurchaseOrder = this.store.createRecord('purchaseOrder', this.purchaseOrder);
      //   newPurchaseOrder.save().then(() => {
      //     this.get('notify').success({ html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Purchase Order Created</span></div>' });
      //     this.send('toggleNewPurchaseOrder');
      //     this.model.pushObject(newPurchaseOrder);
      //   }, (response) => {
      //     let errors = response.errors.map((error) => {
      //       return error;
      //     });
      //     newPurchaseOrder.rollbackAttributes();
      //     this.get('notify').alert({ html: `<i class="icon-warning lg"></i> <div class="message-text"><strong>Error:</strong>&nbsp<span>${errors}</span></div>` });
      //   });
      // },
      close: function close(po) {
        var _this4 = this;

        var orderedItems = po.get('ordered_items');
        po.setProperties({
          'company_id': this.get('companyId'),
          'closed': !po.get('closed')
        });
        this.store.findRecord('purchaseOrder', po.id, {
          backgroundReload: false
        }).then(function (purchaseOrder) {
          purchaseOrder.setProperties(po);
          purchaseOrder.set('items', orderedItems);
          purchaseOrder.save().then(function () {
            _this4.get('notify').success({
              html: "<i class=\"icon-checkmark lg\"></i> <div><strong>Success:</strong>&nbsp<span>Purchase Order ".concat(purchaseOrder.get('closed') ? "Closed" : "Opened", "</span></div>")
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this4.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          });
        });
      },
      deletePurchaseOrder: function deletePurchaseOrder() {
        var _this5 = this;

        this.purchaseOrder.destroyRecord().then(function () {
          _this5.set('filteredPurchaseOrders', _this5.store.peekAll('purchase-order').filter(function (purchaseOrder) {
            return !purchaseOrder.get('isDeleted');
          }));

          _this5.setProperties({
            pagedPurchaseOrders: (0, _pagedArray.default)('filteredPurchaseOrders', {
              infinite: "unpaged",
              page: 1,
              perPage: 100
            }),
            purchaseOrder: {},
            confirmDelete: false
          });

          _this5.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Purchase Order Deleted</span></div>"
          });
        }).catch(function (response) {
          var errors = "An Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          }

          _this5.purchaseOrder.rollbackAttributes();

          _this5.set('confirmDelete', false);

          _this5.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      setFilterLabels: function setFilterLabels(labels) {
        this.set('filterLabelIds', labels);
      },
      loadNext: function loadNext() {
        this.get('pagedPurchaseOrders').loadNextPage();
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('filteredPurchaseOrders', this.get('filteredPurchaseOrders').sortBy(property));
        } else {
          this.set('filteredPurchaseOrders', this.get('filteredPurchaseOrders').sortBy(property).reverse());
        }

        this.set('pagedPurchaseOrders', (0, _pagedArray.default)('filteredPurchaseOrders', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      print: function print() {
        var table = this.generateTable();
        Ember.$('.print-container').empty();
        Ember.$('.print-container').append(table);
        Ember.$('.print-container').printThis({
          importCSS: false
        }); // let w = window.open();
        // w.document.write("<div id='print-container'></div>");
        // w.document.getElementById('print-container').innerHTML += table;
        // w.print();
        // w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        Ember.$('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        Ember.$('#purchaseOrdersTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_purchase_order_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        Ember.$('#purchaseOrdersTable').remove();
        Ember.$('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});