define("account-berry-frontend/components/double-clickable/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['double-clickable']
  });

  _exports.default = _default;
});