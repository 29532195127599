define("account-berry-frontend/companies/show/vendors/receiving-and-bills/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lcE7jQ9f",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"receiving-and-bill-form\",null,[[\"receivingAndBill\",\"store\",\"companyId\",\"reloadRoute\"],[[20,[\"model\"]],[20,[\"store\"]],[20,[\"companyId\"]],\"reloadRoute\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/companies/show/vendors/receiving-and-bills/show/template.hbs"
    }
  });

  _exports.default = _default;
});