define("account-berry-frontend/signup/route", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    model: function model(params) {
      this.get('ajax').request("".concat(_environment.default.apiURL, "/invites/").concat(params.token), {
        method: 'GET',
        contentType: 'application/json',
        data: {
          company_id: params.company_id
        }
      }).then(function (response) {
        if (!response.invite) {
          window.location.replace('/');
        }
      });
    }
  });

  _exports.default = _default;
});