define("account-berry-frontend/components/theme-picker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    notify: Ember.inject.service('notify'),
    init: function init() {
      var _this = this;

      this._super();

      var userId = this.get('session.data.authenticated.id');
      this.store.findRecord('user', userId).then(function (user) {
        _this.set('user', user);

        _this.set('theme', user.get('theme'));

        _this.set('session.data.authenticated.theme', user.get('theme'));
      });
    },
    sessionThemeObserver: Ember.observer('session.data.authenticated.theme', function () {
      this.set('session.data.authenticated.theme', this.get('theme'));
    }),
    user: Ember.computed('session.data.authenticated.id', function () {
      var userId = this.get('session.data.authenticated.id');
      return this.store.findRecord('user', userId);
    }),
    actions: {
      setTheme: function setTheme(theme) {
        var _this2 = this;

        this.set('user.theme', theme);
        this.user.save().then(function () {
          _this2.set('theme', theme);

          _this2.set('session.data.authenticated.theme', theme);

          _this2.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Theme Updated</span></div>'
          });
        }, function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      }
    }
  });

  _exports.default = _default;
});