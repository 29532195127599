define("account-berry-frontend/companies/show/reports/sales-by-customer/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.set('breadCrumb', 'Sales By Customer');
      controller.set('companyId', this.paramsFor('companies.show').company_id); // controller.setProperties({
      //     generalLedgerData: null,
      //     fromDate: null,
      //     toDate: null,
      //     accountTransactions: false
      // });
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.reports.sales-by-customer', {
        into: 'application',
        model: model
      });
    }
  });

  _exports.default = _default;
});