define("account-berry-frontend/num-format/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    truncate: function truncate(number) {
      try {
        // let stringNum = number.toString()
        // let arrNum;
        // if (number.includes('.')) {
        //   arrNum = stringNum.split('.')
        //   arrNum[1] = arrNum[1].substring(0, 3)
        //   number = arrNum.join('.')
        // }
        return Number((Math.round((number + 0.00001) * 100) / 100).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]); // return Number((Math.round(number * 100) / 100).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0])
        // return Number((Math.round((number + 0.00001) * 100) / 100))
      } catch (e) {
        return 0;
      }
    }
  });

  _exports.default = _default;
});