define("account-berry-frontend/companies/show/customers/pvot/route", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajaxCall: Ember.inject.service(),
    setupController: function setupController(controller) {
      var _this = this;

      var companyId = this.paramsFor('companies.show').company_id;
      controller.set('breadCrumb', 'PVOT Data Import');
      controller.set('companyId', companyId);
      controller.setProperties({
        'showRedirectModal': false,
        'pvotFile1': undefined,
        'pvotFile2': undefined,
        'pvotFile3': undefined
      });
      this.store.findAll('account', {
        reload: true
      }).then(function (accounts) {
        controller.set('accounts', accounts);
      });
      var ajaxOptions = {
        url: "".concat(_environment.default.apiURL, "/companies/").concat(companyId, "/pvot_imports"),
        method: 'GET',
        contentType: 'application/json',
        successCallback: function successCallback(response) {
          controller.set('pvotConfig', response);
        },
        errorCallback: function errorCallback(response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        }
      };
      this.get('ajaxCall').request(ajaxOptions);
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.customers.pvot', {
        into: 'application',
        model: model
      });
    }
  });

  _exports.default = _default;
});