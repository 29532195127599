define("account-berry-frontend/companies/show/company-settings/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eUHxCp2f",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"company-form\",null,[[\"company\",\"store\",\"applicationController\",\"reloadRoute\"],[[20,[\"model\"]],[20,[\"store\"]],[20,[\"applicationController\"]],\"reloadRoute\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/companies/show/company-settings/edit/template.hbs"
    }
  });

  _exports.default = _default;
});