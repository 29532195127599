define("account-berry-frontend/companies/show/items/controller", ["exports", "account-berry-frontend/config/environment", "ember-cli-pagination/computed/paged-array"], function (_exports, _environment, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    // pusher: Ember.inject.service('pusher'),
    confirmDelete: false,
    search: "",
    sortAscending: false,
    cable: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    pagedItems: (0, _pagedArray.default)('filteredItems', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    filter: "name",
    filters: Ember.computed(function () {
      return [{
        name: "NAME",
        value: "name"
      }, {
        name: "NUMBER",
        value: "number"
      }, {
        name: "DESCRIPTION",
        value: "description"
      }];
    }),
    modelObserver: Ember.observer('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      try {
        this.set('filteredItems', this.store.peekAll('item').filter(function (item) {
          return !item.get('isDeleted');
        }));
        this.set('pagedItems', (0, _pagedArray.default)('filteredItems', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    totalItems: Ember.computed('filteredItems', function () {
      return this.get('filteredItems.length');
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this2 = this;

      var channel = this.get('session.pusher').subscribe("item-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          _this2.updateItemList(data.item);
        }
      });
      channel.bind('updated', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          var itemId = data.item.id;
          delete data.item.id;

          var item = _this2.store.peekRecord('item', itemId);

          item.setProperties(data.item);
        }
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          var item = _this2.store.peekRecord('item', data.item_id);

          item.deleteRecord();

          _this2.set('filteredItems', _this2.store.peekAll('item').filter(function (item) {
            return !item.get('isDeleted');
          }));

          _this2.set('pagedItems', (0, _pagedArray.default)('filteredItems', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        }
      }); // ActionCable Channels

      var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

      var _this = this;

      consumer.createSubscription({
        channel: "ItemsChannel",
        room: this.get('companyId')
      }, {
        connected: function connected() {
          console.log('Connected to ItemsChannel');

          _this.send('getItemsByDate');
        },
        received: function received(data) {
          if (data.error) {
            _this2.get('applicationController').set('showLoading', false);

            _this2.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>Please add or update your payment method.</span></div>"
            });

            _this2.set('paymentModal', true);
          } else {
            var items = data.toArray();

            _this2.get('filteredItems').forEach(function (fi) {
              var i = items.find(function (i) {
                return i.id.toString() === fi.id;
              });
              fi.setProperties({
                'on_hand': i.on_hand,
                'ordered': i.ordered,
                'available': i.available
              });
            }); // this.set('filteredItems', updatedItems);


            _this2.set('pagedItems', (0, _pagedArray.default)('filteredItems', {
              infinite: "unpaged",
              page: 1,
              perPage: 100
            }));

            _this2.get('applicationController').set('showLoading', false);
          }
        }
      });
      this.set('subscribedToChannel', true);
    },
    updateItemList: function updateItemList(item) {
      this.store.push({
        data: {
          id: item.id,
          type: 'item',
          attributes: item
        }
      });
      this.set('filteredItems', this.store.peekAll('item').filter(function (item) {
        return !item.get('isDeleted');
      }));
      this.set('pagedItems', (0, _pagedArray.default)('filteredItems', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      })); // this.set('model', this.store.peekAll('item'));
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    setItemTotals: Ember.observer('pagedItems', 'filteredItems', function () {
      var filteredItems = this.get('filteredItems');

      if (typeof filteredItems !== "function" && filteredItems.length > 0) {
        this.set('totalOnHandItems', filteredItems.reduce(function (a, b) {
          return (a.id ? a.get('on_hand') : 0) + (b.id ? b.get('on_hand') : 0);
        }, 0));
        this.set('totalOrderedItems', filteredItems.reduce(function (a, b) {
          return (a.id ? a.get('ordered') : 0) + (b.id ? b.get('ordered') : 0);
        }, 0));
        var totalOnHandItems = 0,
            totalOrderedItems = 0,
            totalItemsValue = 0,
            totalItemsAvailable = 0;
        filteredItems.forEach(function (item) {
          if (!item.get('non_inventory')) {
            totalOnHandItems += item.get('on_hand');
            totalOrderedItems += item.get('ordered');
            totalItemsValue += Number(typeof item.get('avg_cost') === 'string' ? item.get('avg_cost') : item.get('avg_cost').toFixed(2)) * item.get('on_hand');
            totalItemsAvailable += item.get('available');
          }
        });
        this.setProperties({
          totalOnHandItems: totalOnHandItems,
          totalOrderedItems: totalOrderedItems,
          totalItemsValue: totalItemsValue,
          totalItemsAvailable: totalItemsAvailable
        });
      }
    }),
    filteredItems: Ember.computed('model', 'items', function () {
      return this.get('items') ? this.get('items') : this.get('model');
    }),
    filterItems: Ember.observer('search', function () {
      var search = this.search;
      var filter = this.filter;
      var items = this.items || this.model;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      if (search === "" || search.trim().length === 0) {
        this.set('filteredItems', items || this.model);
      } else {
        this.set('filteredItems', items.filter(function (item) {
          var itemValue = item.get(filter);

          if (itemValue) {
            if (isNaN(itemValue)) {
              itemValue = itemValue.toLowerCase();
            }

            if (itemValue.includes(search)) {
              return item;
            }
          }
        }));
      }

      this.set('pagedItems', (0, _pagedArray.default)('filteredItems', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana; font-size: 6px'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3><h3 style='text-align: center;'>Items</h3><table id='itemsTable' style='width:100%; font-size: 14px'>");
      table += "<thead><tr><th style='text-align: left;'>NUMBER</th>";
      table += "<th style='text-align: left;'>NAME</th>";
      table += "<th style='text-align: right;'>AVG COST</th>";
      table += "<th style='text-align: right;'>SALE PRICE</th>";
      table += "<th style='text-align: center;'>DESCRIPTION</th>";
      table += "<th style='text-align: center;'>TYPE</th>";
      table += "<th style='text-align: center;'>ON HAND</th>";
      table += "<th style='text-align: center;'>ORDERED</th>";
      table += "<th style='text-align: center;'>AVAILABLE</th>";
      table += "<th style='text-align: right;'>VALUE</th></tr></thead>";
      table += "<tbody>";
      this.get('filteredItems').forEach(function (item) {
        table += "<tr>";
        table += "<td>".concat(item.get('number') || "", "</td>");
        table += "<td>".concat(item.get('name'), "</td>");
        table += "<td style='text-align: right;'>".concat(item.get('avg_cost') ? parseFloat(item.get('avg_cost')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "", "</td>");
        table += "<td style='text-align: right;'>".concat(parseFloat(item.get('sale_price')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style=\"text-align: center;\">".concat(item.get('description') || "", "</td>");
        table += "<td style='text-align: center;'>".concat(item.get('non_inventory') ? "Non Inventory" : "Inventory", "</td>");
        table += "<td style='text-align: center;'>".concat(item.get('non_inventory') ? "" : item.get('on_hand'), "</td>");
        table += "<td style='text-align: center;'>".concat(item.get('non_inventory') ? "" : item.get('ordered'), "</td>");
        table += "<td style='text-align: center;'>".concat(item.get('non_inventory') ? "" : item.get('available') || "", "</td>");
        table += "<td style='text-align: right;'>".concat(item.get('non_inventory') ? "" : parseFloat(parseFloat(item.get('avg_cost')) * item.get('on_hand')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "</tr>";
      });
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);
      },
      getItemsByDate: function getItemsByDate() {
        var _this3 = this;

        var companyId = this.get('companyId'); // if (this.get('dateFilter')) {
        // Items query by date filter

        this.store.query('item', {
          company_id: this.companyId,
          item: {
            date: this.get('dateFilter'),
            company_id: this.companyId
          }
        }).then(function (items) {
          // this.set('items', items.toArray());
          _this3.get('applicationController').set('showLoading', true);
        }); // } else {
        //   this.store.findAll('item').then((items) => {
        //     this.set('items', items.toArray().filter((item) => {
        //       return item.get('company_id').toString() === companyId.toString();
        //     }));
        //   });
        // }

        this.set('pagedItems', (0, _pagedArray.default)('filteredItems', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        if (id) {
          var _this = this;

          this.store.findRecord('item', id, {
            backgroundReload: false
          }).then(function (item) {
            _this.set('item', item);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      deleteItem: function deleteItem() {
        var _this4 = this;

        this.item.destroyRecord().then(function () {
          _this4.set('filteredItems', _this4.store.peekAll('item').filter(function (item) {
            return !item.get('isDeleted');
          }));

          _this4.setProperties({
            pagedItems: (0, _pagedArray.default)('filteredItems', {
              infinite: "unpaged",
              page: 1,
              perPage: 100
            }),
            item: {},
            confirmDelete: false
          });

          _this4.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Item Deleted</span></div>"
          });
        }, function (response) {
          var errors = "An Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          }

          _this4.item.rollbackAttributes();

          _this4.set('confirmDelete', false);

          _this4.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      loadNext: function loadNext() {
        this.get('pagedItems').loadNextPage();
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('filteredItems', this.get('filteredItems').sortBy(property));
        } else {
          this.set('filteredItems', this.get('filteredItems').sortBy(property).reverse());
        }

        this.set('pagedItems', (0, _pagedArray.default)('filteredItems', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      print: function print() {
        var table = this.generateTable();
        Ember.$('.print-container').empty();
        Ember.$('.print-container').append(table);
        Ember.$('.print-container').printThis({
          importCSS: false
        }); // let w = window.open();
        // w.document.write("<div id='print-container'></div>");
        // w.document.getElementById('print-container').innerHTML += table;
        // w.print();
        // w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        Ember.$('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        Ember.$('#itemsTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_items_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        Ember.$('#itemsTable').remove();
        Ember.$('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});