define("account-berry-frontend/companies/show/batches/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SVMjnrp9",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"import-form\",null,[[\"companyId\",\"store\",\"accounts\",\"cancel\",\"reloadRoute\",\"toggleShowLoading\"],[[20,[\"companyId\"]],[20,[\"store\"]],[20,[\"accounts\"]],[25,\"action\",[[19,0,[]],\"cancel\"],null],\"reloadRoute\",\"toggleShowLoading\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/companies/show/batches/new/template.hbs"
    }
  });

  _exports.default = _default;
});