define("account-berry-frontend/components/confirm-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u2sOQ5np",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"onClose\",\"targetAttachment\"],[[25,\"action\",[[19,0,[]],\"cancel\"],null],\"center\"]],{\"statements\":[[0,\"  \"],[6,\"span\"],[9,\"class\",\"text-center\"],[7],[6,\"strong\"],[7],[1,[18,\"message\"],false],[8],[8],[0,\"\\n\"],[4,\"unless\",[[20,[\"twoOptions\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"dialog-options\"],[7],[0,\"\\n      \"],[6,\"button\"],[10,\"class\",[26,[\"btn btn-\",[18,\"btnType\"],\" accountberry-btn \",[18,\"btnType\"]]]],[3,\"action\",[[19,0,[]],\"confirm\"]],[7],[1,[18,\"confirmOption\"],false],[8],[0,\"\\n      \"],[6,\"button\"],[9,\"class\",\"btn accountberry-btn\"],[3,\"action\",[[19,0,[]],\"cancel\"]],[7],[1,[18,\"cancelOption\"],false],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"dialog-options bottom-spacing-small\"],[7],[0,\"\\n      \"],[6,\"button\"],[10,\"class\",[26,[\"btn btn-\",[18,\"btnType\"],\" accountberry-btn \",[18,\"btnType\"]]]],[3,\"action\",[[19,0,[]],\"confirm\"]],[7],[1,[18,\"confirmOptionOne\"],false],[8],[0,\"\\n      \"],[6,\"button\"],[10,\"class\",[26,[\"btn btn-\",[18,\"btnType\"],\" accountberry-btn \",[18,\"btnType\"]]]],[3,\"action\",[[19,0,[]],\"confirmTwo\"]],[7],[1,[18,\"confirmOptionTwo\"],false],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"btn accountberry-btn text-center\"],[3,\"action\",[[19,0,[]],\"cancel\"]],[7],[1,[18,\"cancelOption\"],false],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/confirm-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});