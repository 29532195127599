define("account-berry-frontend/components/a480-input-account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bGzG0w3N",
    "block": "{\"symbols\":[],\"statements\":[[6,\"span\"],[9,\"class\",\"flex-row vertical-center\"],[7],[0,\"\\n  \"],[1,[25,\"add\",[[20,[\"index\"]],1],null],false],[0,\".\\n  \"],[1,[25,\"select-account\",null,[[\"setAccount\",\"companyId\",\"index\",\"store\",\"accounts\",\"accountId\",\"selectClass\",\"selectId\"],[[25,\"action\",[[19,0,[]],\"setAccount\"],null],[20,[\"companyId\"]],[20,[\"index\"]],[20,[\"store\"]],[20,[\"accounts\"]],[20,[\"accountId\"]],\"bottom-spacing-small\",[25,\"concat\",[[20,[\"selectId\"]],[20,[\"index\"]]],null]]]],false],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"icon-cross\"],[3,\"action\",[[19,0,[]],\"removeAccount\",[20,[\"index\"]]]],[7],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/a480-input-account/template.hbs"
    }
  });

  _exports.default = _default;
});