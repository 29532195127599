define("account-berry-frontend/components/create-payee/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service('notify'),
    payeeSelect: true,
    payeeTypes: ["Customer", "Vendor", "Employee"],
    isEmployee: false,
    filing_statuses: ["Married Filing Jointly", "Married Filing Separate", "Single", "Head of Household", "Qualifying Widow(er) with Dependent Child"],
    init: function init() {
      this._super();

      this.set('payee', {});
    },
    actions: {
      setPayeeType: function setPayeeType(type) {
        this.setProperties({
          'payeeType': type,
          'isEmployee': type === "Employee" ? true : false
        });
      },
      createPayee: function createPayee() {
        var _this = this;

        // Create Payee
        this.set('payee.company_id', this.companyId);
        var payeeType = this.payeeType.toLowerCase();
        var newPayee = this.store.createRecord(payeeType, this.payee);
        newPayee.save().then(function (res) {
          _this.send('setPayee', res);

          _this.get('notify').success({
            html: "<i class=\"icon-checkmark lg\"></i> <div><strong>Success:</strong>&nbsp<span>".concat(_this.payeeType, " Created</span></div>")
          });
        }, function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          newPayee.set('type', _this.payeeType);
          newPayee.rollbackAttributes();

          _this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
        this.sendAction('toggleCreatePayee');
      },
      setPayee: function setPayee(payee) {
        this.sendAction('setPayee', "".concat(payee.id, ",").concat(this.payeeType), true);
      },
      setFilingStatus: function setFilingStatus(value) {
        this.set('payee.filing_status', value);
      },
      close: function close() {
        this.set('payee', {});
        this.sendAction('cancelCreatePayee');
      }
    }
  });

  _exports.default = _default;
});