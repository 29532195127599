define("account-berry-frontend/application/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    keyForAttribute: function keyForAttribute(key) {
      return Ember.String.underscore(key);
    }
  });

  _exports.default = _default;
});