define("account-berry-frontend/components/account-transactions/component", ["exports", "ember-cli-pagination/computed/paged-array", "account-berry-frontend/utils/account-type-groups", "account-berry-frontend/config/environment"], function (_exports, _pagedArray, _accountTypeGroups, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    session: Ember.inject.service('session'),
    notify: Ember.inject.service('notify'),
    search: "",
    sortAscending: false,
    dateFilter: 'date',
    cable: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    init: function init() {
      this._super();

      if (!this.get('model.transactions')) {
        this.transactionsActionCable();
      } else {
        this.send('filterTransactionsByDate');
        this.balanceCalculator();
      }
    },
    transactionsActionCable: function transactionsActionCable() {
      var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

      var _this = this;

      consumer.createSubscription({
        channel: "AccountsChannel",
        room: "Transactions" + this.get('session.data.authenticated.id') + this.get('model.id')
      }, {
        connected: function connected() {
          console.log("AccountsChannel-Transactions connected");

          _this.get('applicationController').set('showLoading', true);

          var ajaxOptions = {
            url: "".concat(_environment.default.apiURL, "/companies/").concat(_this.companyId, "/accounts/").concat(_this.get('model.id'), "/transactions"),
            method: 'GET',
            contentType: 'application/json',
            data: JSON.stringify({
              account: {
                id: _this.get('model.id'),
                company_id: _this.get('companyId')
              }
            }),
            successCallback: function successCallback() {},
            errorCallback: function errorCallback(response) {
              var errors = "An Unkown Error Occured.";

              if (response.errors && response.errors[0].status !== "500") {
                errors = response.errors.map(function (error) {
                  return error;
                });
              } else if (response.payload && response.payload.errors) {
                errors = response.payload.errors.map(function (error) {
                  return error;
                });
              }

              _this.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
              });
            }
          };

          _this.get('ajaxCall').request(ajaxOptions);
        },
        received: function received(data) {
          if (data.error) {
            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
            });
          } else {
            consumer.disconnect();

            _this.set('model.transactions', data.transactions);

            _this.set('model.amount', data.amount);

            _this.send('filterTransactionsByDate');

            _this.balanceCalculator();

            _this.get('applicationController').set('showLoading', false);
          }
        },
        disconnected: function disconnected() {
          console.log("AccountsChannel-Transactions disconnected");
        }
      });
    },
    accountTypeGroups: Ember.computed(function () {
      return (0, _accountTypeGroups.default)();
    }),
    pagedTransactions: (0, _pagedArray.default)('filteredTransactions', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    transactionsObserver: Ember.observer('filteredTransactions', 'model', function () {
      try {
        this.set('pagedTransactions', (0, _pagedArray.default)('filteredTransactions', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));

        if (this.get('model.special_account')) {
          this.balanceCalculator();
        }
      } catch (err) {}
    }),
    filteredTransactions: Ember.computed('model.transactions', function () {
      return this.get('model.transactions');
    }),
    totalTransactions: Ember.computed('filteredTransactions', function () {
      return this.get('filteredTransactions.length');
    }),
    filters: [{
      name: "DATE",
      value: "date"
    }, {
      name: "NUMBER",
      value: "number"
    }, {
      name: "PAYEE NAME",
      value: "payee_name"
    }, {
      name: "AMOUNT",
      value: "amount"
    }],
    filter: "date",
    filterTransactions: Ember.observer('search', function () {
      var search = this.search;
      var filter = this.filter;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      if (search === "" || search.trim().length === 0) {
        this.set('filteredTransactions', this.get('model.transactions'));
      } else {
        this.set('filteredTransactions', this.get('model.transactions').filter(function (transaction) {
          var transactionValue = transaction[filter];

          if (transactionValue) {
            if (isNaN(transactionValue)) {
              transactionValue = transactionValue.toLowerCase();
            }

            if (transactionValue.includes(search)) {
              return transaction;
            }
          }
        }));
      }

      this.set('pagedTransactions', (0, _pagedArray.default)('filteredTransactions', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    filterTransactionsByDateObserver: Ember.observer('model.transactions', function () {
      this.send('filterTransactionsByDate');
    }),
    balanceCalculator: function balanceCalculator() {
      var _this2 = this;

      var balance = 0;
      var filteredTransactions = this.get('filteredTransactions');

      if (filteredTransactions) {
        filteredTransactions.forEach(function (transaction) {
          if ((0, _accountTypeGroups.default)()[_this2.get('model.group')][transaction.account_action]) {
            balance += Math.abs(Number(transaction.amount));
          } else {
            balance -= Math.abs(Number(transaction.amount));
          }

          Ember.set(transaction, 'balance', balance);
        });
      }

      this.set('totalBalance', balance);
      this.set('pagedTransactions', (0, _pagedArray.default)('filteredTransactions', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    generateTable: function generateTable(print) {
      var table = "<div style=\"font-family: Verdana;\"><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('model.company_id')).get('name'), "</h3><h3 style='text-align: center'>").concat(this.model.get('name'), " Transactions</h3><table id='accountTransactionsTable' style='width:100%; font-size: 14px'><thead><tr>");
      table += "<th style='text-align: left;'>DATE</th>";

      if (this.get('model.special_account')) {
        table += "<th style='text-align: left;'>NUMBER</th>";
        table += "<th style='text-align: center;'>CLEAR</th>";
        table += "<th style='text-align: left;'>TYPE</th>";
        table += "<th style='text-align: left;'>PAYEE NAME</th>";
        table += "<th style='text-align: center'>MEMO</th>";
        table += "<th style='text-align: right'>DEBIT</th>";
        table += "<th style='text-align: right'>CREDIT</th>";
        table += "<th style='text-align: right'>BALANCE</th></tr></thead>";
        table += "<tbody>";
        this.get('filteredTransactions').forEach(function (transaction) {
          table += "<td style='text-align: left;'>".concat(moment(transaction.date).format('MM/DD/YYYY'), "</td>");
          table += "<td style='text-align: left;'>".concat(transaction.number ? transaction.number : '', "</td>");

          if (transaction.clear) {
            if (!print) {
              table += "<td style='text-align: center;'>Cleared</td>";
            } else {
              table += "<td style='text-align: center;'><i class=\"icon-check\"></i></td>";
            }
          } else {
            table += "<td></td>";
          }

          table += "<td style='text-align: left;'>".concat(transaction.type, "</td>");
          table += "<td style='text-align: left;'>".concat(transaction.payee_name ? transaction.payee_name : '', "</td>");
          table += "<td style='text-align: center'>".concat(transaction.memo ? transaction.memo : '', "</td>");
          table += transaction.account_action === "debit" ? "<td style='text-align: right'>".concat(parseFloat(transaction.amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>") : "<td></td>";
          table += transaction.account_action === "credit" ? "<td style='text-align: right'>".concat(parseFloat(transaction.amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>") : "<td></td>";
          table += "<td style='text-align: right'>".concat(parseFloat(transaction.balance).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
        });
        table += "</tbody></table>";
      } else {
        table += "<th style='text-align: left;'>NUMBER</th>";
        table += "<th style='text-align: center;'>CLEAR</th>";
        table += "<th style='text-align: left;'>TYPE</th>";
        table += "<th style='text-align: left;'>PAYEE NAME</th>";
        table += "<th style='text-align: center'>MEMO</th>";
        table += "<th style='text-align: right'>AMOUNT</th></tr></thead>";
        table += "<tbody>";
        this.get('filteredTransactions').forEach(function (transaction) {
          table += "<td style='text-align: left;'>".concat(moment(transaction.date).format('MM/DD/YYYY'), "</td>");
          table += "<td style='text-align: left;'>".concat(transaction.number ? transaction.number : '', "</td>");

          if (transaction.clear) {
            if (!print) {
              table += "<td style='text-align: center;'>Cleared</td>";
            } else {
              table += "<td style='text-align: center;'><i class=\"icon-check\"></i></td>";
            }
          } else {
            table += "<td></td>";
          }

          table += "<td style='text-align: left;'>".concat(transaction.type, "</td>");
          table += "<td style='text-align: left;'>".concat(transaction.payee_name ? transaction.payee_name : '', "</td>");
          table += "<td style='text-align: center'>".concat(transaction.memo ? transaction.memo : '', "</td>");
          table += "<td style='text-align: right'>".concat(parseFloat(transaction.amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
        });
        table += "</tbody></table></div>";
      }

      return table;
    },
    actions: {
      goBack: function goBack() {
        this.sendAction("goBack");
      },
      changeFilter: function changeFilter(value) {
        this.set('filter', value);

        if (value !== 'date') {
          this.setProperties({
            fromDateFilter: null,
            toDateFilter: null
          });
        }

        this.set('dateFilter', value === 'date');
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('filteredTransactions', this.get('filteredTransactions').sortBy(property));
        } else {
          this.set('filteredTransactions', this.get('filteredTransactions').sortBy(property).reverse());
        }

        this.set('pagedTransactions', (0, _pagedArray.default)('filteredTransactions', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
        this.balanceCalculator();
      },
      loadNext: function loadNext() {
        this.get('pagedTransactions').loadNextPage();
      },
      toggleConfirmDelete: function toggleConfirmDelete(transaction) {
        if (transaction) {
          var _this = this; // Change transaction.type to camelCase!!!


          this.store.findRecord(Ember.String.camelize(transaction.type), transaction.id, {
            backgroundReload: false
          }).then(function (transaction) {
            _this.set('transaction', transaction);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      deleteTransaction: function deleteTransaction() {
        var _this3 = this;

        var id = this.transaction.id;
        this.transaction.destroyRecord().then(function () {
          _this3.setProperties({
            confirmDelete: false
          });

          _this3.get('filteredTransactions').find(function (transaction) {
            if (transaction.id.toString() === id.toString()) {
              transaction.isDeleted = true;
              return true;
            }
          });

          _this3.set('pagedTransactions', (0, _pagedArray.default)('filteredTransactions', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Transaction Deleted</span></div>"
          });

          _this3.set('model', _this3.store.peekRecord('account', param.account_id));
        }).catch(function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
        this.toggleProperty('confirmDelete');
      },
      print: function print() {
        var table = this.generateTable(true);
        Ember.$('.print-container').empty();
        Ember.$('.print-container').append(table);
        Ember.$('.print-container').printThis({
          importCSS: false
        }); // let w = window.open();
        // w.document.write("<div id='print-container'></div>");
        // w.document.getElementById('print-container').innerHTML += table;
        // w.print();
        // w.close();
      },
      filterTransactionsByDate: function filterTransactionsByDate() {
        var fromDateFilter = this.get('fromDateFilter');
        var toDateFilter = this.get('toDateFilter');

        if ((fromDateFilter || toDateFilter) && this.get('model.transactions')) {
          this.set('filteredTransactions', this.get('model.transactions').sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          }).filter(function (transaction) {
            if (fromDateFilter && toDateFilter) {
              if (transaction.date >= fromDateFilter && transaction.date <= toDateFilter) {
                return transaction;
              }
            } else if (fromDateFilter && !toDateFilter) {
              if (transaction.date >= fromDateFilter) {
                return transaction;
              }
            } else if (!fromDateFilter && toDateFilter) {
              if (transaction.date <= toDateFilter) {
                return transaction;
              }
            }
          }));
        } else if (this.get('model.transactions')) {
          this.set('filteredTransactions', this.get('model.transactions'));
        }

        try {
          this.set('pagedTransactions', (0, _pagedArray.default)('filteredTransactions', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        } catch (e) {}
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        Ember.$('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        Ember.$('#accountTransactionsTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('model.company_id')).get('name'), "_").concat(Ember.String.underscore(this.get('model.name')), "_transactions_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        Ember.$('#accountTransactionsTable').remove();
        Ember.$('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});