define("account-berry-frontend/auth/shopify/callback/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Controller.extend({
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    queryParams: ['code', 'hmac', 'host', 'shop', 'state', 'timestamp'],
    code: null,
    hmac: null,
    host: null,
    shop: null,
    state: null,
    timestamp: null,
    syncStarted: false,
    shopifySync: Ember.observer('code', 'hmac', 'host', 'shop', 'state', 'timestamp', function () {
      var _this2 = this;

      if (this.get('code') && !this.get('syncStarted')) {
        this.set('syncStarted', true);

        var _this$get$split3 = this.get('state').split('-'),
            _this$get$split4 = _slicedToArray(_this$get$split3, 2),
            userId = _this$get$split4[0],
            companyId = _this$get$split4[1];

        var _this = this;

        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/shopify/sync"),
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            shopify_sync: {
              user_id: userId,
              company_id: companyId,
              url: window.location.href,
              code: this.get('code'),
              hmac: this.get('hmac'),
              host: this.get('host'),
              shop: this.get('shop'),
              state: this.get('state'),
              timestamp: this.get('timestamp')
            }
          }),
          successCallback: function successCallback() {
            _this2.get('notify').success({
              html: "<i class=\"icon-checkmark lg\"></i> <div class=\"message-text\"><strong>Success:</strong>&nbsp<span>Your Shop has Synced!</span></div>"
            });

            setTimeout(function () {
              // _this.transitionToRoute('companies.show.shopify', companyId);
              window.location.href = "".concat(_environment.default.clientURL, "/").concat(companyId, "/shopify");
            }, 2000);
          },
          errorCallback: function errorCallback(response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload && response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this2.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });

            setTimeout(function () {
              window.location.href = "".concat(_environment.default.clientURL, "/").concat(companyId, "/shopify");
            }, 2000);
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      }
    })
  }); // http://localhost:4200/auth/shopify/callback?code=5b6d4be2ac59a460dec7f25209e7887d&hmac=4a8d7e71e9670a5187e30c0960bca651f301e23044d1f45fa0781b642622e17a&host=YWNjb3VudGJlcnJ5Lm15c2hvcGlmeS5jb20vYWRtaW4&shop=accountberry.myshopify.com&state=2-4&timestamp=1643938180


  _exports.default = _default;
});