define("account-berry-frontend/item/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    cost: _emberData.default.attr('number'),
    company_id: _emberData.default.attr(),
    description: _emberData.default.attr(),
    name: _emberData.default.attr(),
    number: _emberData.default.attr(),
    on_hand: _emberData.default.attr('number'),
    ordered: _emberData.default.attr('number'),
    picture: _emberData.default.attr(),
    sale_price: _emberData.default.attr('number'),
    taxes: _emberData.default.attr(),
    taxable: _emberData.default.attr(),
    uneditable: _emberData.default.attr(),
    non_inventory: _emberData.default.attr(),
    preferred_account_id: _emberData.default.attr(),
    available: _emberData.default.attr('number'),
    avg_cost: _emberData.default.attr('number'),
    tax_ids: _emberData.default.attr()
  });

  _exports.default = _default;
});