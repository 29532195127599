define("account-berry-frontend/helpers/loop-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loopRange = loopRange;
  _exports.default = void 0;

  function loopRange(params
  /*, hash*/
  ) {
    return params;
  }

  var _default = Ember.Helper.helper(function (params) {
    var n = Number(params[0]);
    return Array.from({
      length: n
    }, function (v, k) {
      return k + 1;
    });
  });

  _exports.default = _default;
});