define("account-berry-frontend/utils/videos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = videos;

  function videos() {
    return {
      central: [{
        titulo: "Crear Compañia",
        title: "Create Company",
        embeded: "3z7auz3PSSg",
        route: 'companies.index'
      }],
      company: [{
        titulo: "Introducción a la Compañia",
        title: "Intro to Company",
        embeded: "09K5LBzNSag",
        route: 'companies.show.index'
      }, {
        titulo: "Tabla de Usuarios",
        title: "Users Table",
        embeded: "aG2a718oADs",
        route: 'companies.show.users'
      }, {
        titulo: "Artículos",
        title: "Items",
        embeded: "2DvwZX-S8LI",
        route: 'companies.show.items'
      }, {
        titulo: "Inventario Físico",
        title: "Physical Inventory",
        embeded: "XvBVIDYde9g",
        route: 'companies.show.physical-inventory'
      }, {
        titulo: "Cuentas",
        title: "Accounts",
        embeded: "OwmiK8HgtYA",
        route: 'companies.show.accounts'
      }, {
        titulo: "Cheques",
        title: "Checks",
        embeded: "vpEEm5Pu38Q",
        route: 'companies.show.checks'
      }, {
        titulo: "Depósitos",
        title: "Deposits",
        embeded: "VLFKQLmLJAo",
        route: 'companies.show.deposits'
      }, {
        titulo: "Entradas de Jornal",
        title: "Journal Entries",
        embeded: "ZYBWDN-KR2g",
        route: 'companies.show.journal-entries'
      }, {
        titulo: "Conciliar",
        title: "Reconcile",
        embeded: "Cb8GlpusXx0",
        route: 'companies.show.reconciliations'
      }, {
        titulo: "Cierre de Fin de Año",
        title: "Year-end Closing",
        embeded: "W1kQKGLZgnI",
        route: 'companies.show.closing-entries'
      }, {
        titulo: "Etiquetas",
        title: "Labels",
        embeded: "QbFwK_SsJGs",
        route: 'companies.show.labels'
      }, {
        titulo: "Transacciones Recurrentes",
        title: "Recurrent Transactions",
        embeded: "neSJFNB7-cs",
        route: 'companies.show.recurrencies'
      }, {
        titulo: "Importar Lotes",
        title: "Import Batches",
        embeded: "SsxJA7x2fKA",
        route: 'companies.show.batches'
      }, {
        titulo: "Syncronizacion Bancaria",
        title: "Bank Sync",
        embeded: "jcVBjxGk-CU",
        route: 'companies.show.bank-sync'
      }, {
        titulo: "Importar Transacciones",
        title: "Import Transactions",
        embeded: "LYR6TwTy9A0",
        route: 'companies.show.bank-sync.show'
      }, {
        titulo: "ATH Business Sync",
        title: "ATH Business Sync",
        embeded: "oUCOqoImT2s",
        route: 'companies.show.ath-movil'
      }],
      customers: [{
        titulo: "Introducción a los Clientes",
        title: "Intro to Customers",
        embeded: "09K5LBzNSag",
        route: 'companies.show.customers.index'
      }, {
        titulo: "Tabla de Clientes",
        title: "Customers Table",
        embeded: "6_p2KqWw7nQ",
        route: 'companies.show.customers.customers-table'
      }, {
        titulo: "Representantes",
        title: "Representatives",
        embeded: "pprsG-BNhtE",
        route: 'companies.show.customers.representatives'
      }, {
        titulo: "Impuestos",
        title: "Taxes",
        embeded: "hIDNVgLOiOg",
        route: 'companies.show.customers.taxes'
      }, {
        titulo: "Ordenes de Venta",
        title: "Sales Orders",
        embeded: "27zi--58UpY",
        route: 'companies.show.customers.sales-orders'
      }, {
        titulo: "Facturas",
        title: "Invoices",
        embeded: "TjcgV6R5_4o",
        route: 'companies.show.customers.invoices'
      }, {
        titulo: "Devoluciones",
        title: "Returns",
        embeded: "_LvF4WC_EKM",
        route: 'companies.show.customers.returns'
      }, {
        titulo: "Recibir Pagos",
        title: "Receive Payments",
        embeded: "g6hoFqIqbes",
        route: 'companies.show.customers.receive-payment'
      }, {
        titulo: "Recibir Pagos Directo de la Factura",
        title: "Receive Payments Direct from Invoice",
        embeded: "wp7bPtjgeQM",
        route: 'companies.show.customers.payment-settings'
      }],
      vendors: [{
        titulo: "Introducción al Navegador de Suplidores",
        title: "Introduction to Suppliers Navigator",
        embeded: "GhcD3p97fGs",
        route: 'companies.show.vendors.index'
      }, {
        titulo: "Tabla de Suplidores",
        title: "Vendors Table",
        embeded: "3dd_Q53259A",
        route: 'companies.show.vendors.vendors-table'
      }, {
        titulo: "Rastrear un Suplidor para Efectos de la Retención",
        title: "Track a Supplier for Retention Purposes",
        embeded: "QK8D6wlpots",
        route: 'companies.show.vendors.vendors-table.new'
      }, {
        titulo: "Rastrear un Suplidor para Efectos de la Retención",
        title: "Track a Supplier for Retention Purposes",
        embeded: "QK8D6wlpots",
        route: 'companies.show.vendors.vendors-table.show'
      }, {
        titulo: "Órdenes de Compra",
        title: "Purchase Orders",
        embeded: "QLx7V9Krr-A",
        route: 'companies.show.vendors.purchase-orders'
      }, {
        titulo: "Recibiendo Inventario y Facturas",
        title: "Receiving & Bill",
        embeded: "ZcqWg_zuQ8w",
        route: 'companies.show.vendors.receiving-and-bills'
      }, {
        titulo: "+ Facturas o - Créditos",
        title: "+ Bills & - Credits",
        embeded: "ve2lAlyQLug",
        route: 'companies.show.vendors.bills'
      }, {
        titulo: "Costos de Desembarque",
        title: "Landed Cost",
        embeded: "64SwKCxF7L4",
        route: 'companies.show.vendors.landed-cost'
      }, {
        titulo: "Devoluciones",
        title: "Returns",
        embeded: "bpPi-1frVR0",
        route: 'companies.show.vendors.returns'
      }, {
        titulo: "Pagos a Suplidores",
        title: "Pay Vendors",
        embeded: "azeB-GQx_t4",
        route: 'companies.show.vendors.pay-vendor'
      }],
      employees: [{
        titulo: "Introducción al Navegador de Empleados",
        title: "Introduction to Employees Navigator",
        embeded: "v1DgrmWVGN8",
        route: 'companies.show.employees.index'
      }, {
        titulo: "Tabla de Empleados",
        title: "Employees Table",
        embeded: "jn2TNSsqqCw",
        route: 'companies.show.employees.employees-table'
      }, {
        titulo: "Preparar una Nómina",
        title: "Run Payroll",
        embeded: "npDZrlsXWXc",
        route: 'companies.show.employees.payrolls'
      }, {
        titulo: "Ajustes de Vacaciones y Enfermedad ",
        title: "Vacation & Sickness Adj",
        embeded: "EP14dGJH860",
        route: 'companies.show.employees.vacation-sickness-adjustments'
      }, {
        titulo: "Preferencias de Nómina",
        title: "Payroll Settings",
        embeded: "EPvOM89j9F0",
        route: 'companies.show.employees.payroll-settings'
      }, {
        titulo: "Rates para los Empleados",
        title: "Rates for Employees",
        embeded: "ZFNXxxzMPQY",
        route: 'companies.show.employees.rates'
      }, {
        titulo: "Trabajando Trimestrales",
        title: "Trabajando Trimestrales",
        embeded: "mlyqRl7m_1g",
        route: 'companies.show.employees.499-quarterly'
      }, {
        titulo: "Trabajando Trimestrales",
        title: "Trabajando Trimestrales",
        embeded: "mlyqRl7m_1g",
        route: 'companies.show.employees.941-pr'
      }, {
        titulo: "Trabajando Trimestrales",
        title: "Trabajando Trimestrales",
        embeded: "mlyqRl7m_1g",
        route: 'companies.show.employees.desempleo-incapacidad'
      }, {
        titulo: "Trabajando Trimestrales",
        title: "Trabajando Trimestrales",
        embeded: "mlyqRl7m_1g",
        route: 'companies.show.employees.choferil'
      }],
      reports: [{
        titulo: "Introducción al Navegador de Reportes",
        title: "Introduction to Report Navigator",
        embeded: "WoLch8ddqx8",
        route: 'companies.show.reports.index'
      }, {
        titulo: "Balance de Prueba y Mayor General",
        title: "Trial Balance & General Ledger",
        embeded: "i6cpisPrTGA",
        route: 'companies.show.reports.trial-balance'
      }, {
        titulo: "Balance de Prueba y Mayor General",
        title: "Trial Balance & General Ledger",
        embeded: "i6cpisPrTGA",
        route: 'companies.show.reports.general-ledger'
      }, {
        titulo: "Hoja de Balance, Pérdidas y Ganancias y Flujo de Caja",
        title: "Balance Sheet, Profit & Loss & Cash Flow",
        embeded: "pKbP88bYmfw",
        route: 'companies.show.reports.balance-sheet'
      }]
    };
  }
});