define("account-berry-frontend/settings/subscription-invoices/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    notify: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super();

      var channel = this.get('session.pusher').subscribe("subscription-invoices-channel-".concat(this.get('session.data.authenticated.id')));
      var ajaxOptions = {
        url: "".concat(_environment.default.apiURL, "/stripe/subscription_invoices/").concat(this.get('session.data.authenticated.id')),
        method: 'GET',
        contentType: 'application/json',
        successCallback: function successCallback() {
          _this.get('applicationController').set('showLoading', true);

          channel.bind('subscription_invoices', function (data) {
            _this.get('applicationController').set('showLoading', false);

            _this.set('subscriptionInvoices', data.invoices);
          });
        },
        errorCallback: function errorCallback() {
          _this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
          });
        }
      };
      this.get('ajaxCall').request(ajaxOptions);
    }
  });

  _exports.default = _default;
});