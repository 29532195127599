define("account-berry-frontend/components/ar-aging-full-detail/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service('notify'),
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    actions: {
      back: function back() {
        this.sendAction('back');
      },
      print: function print() {
        Ember.$('#arAgingSummaryDetail').printThis();
      }
    }
  });

  _exports.default = _default;
});