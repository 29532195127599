define("account-berry-frontend/confirmed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N5A0CrF+",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"id\",\"confirmedContainer\"],[7],[0,\"\\n  \"],[6,\"img\"],[10,\"src\",[26,[[18,\"rootURL\"],\"assets/images/logos/logo-slogan.svg\"]]],[7],[8],[0,\"\\n  \"],[6,\"hr\"],[7],[8],[0,\"\\n  \"],[6,\"h1\"],[7],[0,\"Welcome to AccountBerry!\"],[8],[0,\"\\n  \"],[6,\"span\"],[7],[0,\"Account was successfully confirmed. If you are not redirected in 5 seconds \"],[4,\"link-to\",[\"companies\"],null,{\"statements\":[[0,\"click here\"]],\"parameters\":[]},null],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/confirmed/template.hbs"
    }
  });

  _exports.default = _default;
});