define("account-berry-frontend/companies/show/employees/employees-table/rates/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vh18KQLM",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"employee-rate-form\",null,[[\"companyId\",\"rate\",\"store\",\"reloadRoute\"],[[20,[\"companyId\"]],[20,[\"model\"]],[20,[\"store\"]],\"reloadRoute\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/companies/show/employees/employees-table/rates/show/template.hbs"
    }
  });

  _exports.default = _default;
});