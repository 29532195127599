define("account-berry-frontend/application/adapter", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "ember-cli-form-data/mixins/form-data-adapter", "account-berry-frontend/config/environment", "ember-inflector"], function (_exports, _emberData, _dataAdapterMixin, _formDataAdapter, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, _formDataAdapter.default, {
    session: Ember.inject.service(),
    authorize: function authorize(xhr) {
      var _this$get = this.get('session.data.authenticated'),
          email = _this$get.email,
          token = _this$get.token;

      var authData = "Token token=\"".concat(token, "\", email=\"").concat(email, "\"");
      xhr.setRequestHeader('Authorization', authData);
    },
    headers: Ember.computed(function () {
      return {
        'unique_session_id': this.get('session.data.authenticated.unique_session_id')
      };
    }),
    host: _environment.default.apiURL,
    companyId: function companyId() {
      var companyId = location.href.split("//")[1].split("/")[1];
      return companyId === "" ? 1 : companyId;
    },
    urlForFindAll: function urlForFindAll(type) {
      var baseURL = this.buildURL().replace('?signup=true', '');
      var formattedType = (0, _emberInflector.pluralize)(Ember.String.underscore(type));

      if (type === "company") {
        return "".concat(baseURL, "/companies");
      } else {
        return "".concat(baseURL, "/companies/").concat(this.companyId(), "/").concat(formattedType);
      }
    },
    urlForFindRecord: function urlForFindRecord(id, type) {
      var baseURL = this.buildURL().replace('?signup=true', '');
      var formattedType = (0, _emberInflector.pluralize)(Ember.String.underscore(type));

      if (type === "company") {
        return "".concat(baseURL, "/companies/").concat(id);
      } else {
        return "".concat(baseURL, "/companies/").concat(this.companyId(), "/").concat(formattedType, "/").concat(id);
      }
    },
    urlForCreateRecord: function urlForCreateRecord(modelName) {
      var baseURL = this.buildURL().replace('?signup=true', '');
      var formattedType = (0, _emberInflector.pluralize)(Ember.String.underscore(modelName));

      if (modelName === "company") {
        return "".concat(baseURL, "/companies");
      } else {
        return "".concat(baseURL, "/companies/").concat(this.companyId(), "/").concat(formattedType);
      }
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName) {
      var baseURL = this.buildURL().replace('?signup=true', '');
      var formattedType = (0, _emberInflector.pluralize)(Ember.String.underscore(modelName));

      if (modelName === "company") {
        return "".concat(baseURL, "/companies/").concat(id);
      } else {
        return "".concat(baseURL, "/companies/").concat(this.companyId(), "/").concat(formattedType, "/").concat(id);
      }
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName) {
      var baseURL = this.buildURL().replace('?signup=true', '');
      var formattedType = (0, _emberInflector.pluralize)(Ember.String.underscore(modelName));

      if (modelName === "company") {
        return "".concat(baseURL, "/companies/").concat(id);
      } else {
        return "".concat(baseURL, "/companies/").concat(this.companyId(), "/").concat(formattedType, "/").concat(id);
      }
    },
    pathForType: function pathForType(modelName) {
      // Just in case more model name changes are needed
      switch (modelName) {
        case 'sub-account':
          modelName = "account";
          break;

        default:
      }

      modelName = modelName === "sub-account" ? "account" : modelName;
      var snakecased = Ember.String.underscore(modelName);
      return (0, _emberInflector.pluralize)(snakecased);
    },
    urlForQuery: function urlForQuery(query, modelName) {
      var baseURL = this.buildURL();
      var formattedType = (0, _emberInflector.pluralize)(Ember.String.underscore(modelName));
      return "".concat(baseURL, "/companies/").concat(query.company_id, "/").concat(formattedType, "?").concat(query);
    },
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return Ember.String.underscore(modelName);
    }
  });

  _exports.default = _default;
});