define("account-berry-frontend/ath-movil/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _DS$Model$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _emberData.default.Model.extend((_DS$Model$extend = {
    public_token: _emberData.default.attr('string'),
    private_token: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean')
  }, _defineProperty(_DS$Model$extend, "active", _emberData.default.attr('boolean')), _defineProperty(_DS$Model$extend, "bank_account_id", _emberData.default.attr('number')), _defineProperty(_DS$Model$extend, "tax_account_id", _emberData.default.attr('number')), _defineProperty(_DS$Model$extend, "fee_account_id", _emberData.default.attr('number')), _defineProperty(_DS$Model$extend, "sale_account_id", _emberData.default.attr('number')), _defineProperty(_DS$Model$extend, "company_id", _emberData.default.attr()), _DS$Model$extend));

  _exports.default = _default;
});