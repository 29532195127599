define("account-berry-frontend/companies/show/company-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Aa306Amq",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"companies.show.company-settings.edit\"],null,{\"statements\":[[0,\"    \"],[1,[25,\"menu-button\",null,[[\"icon\",\"line-two\"],[\"icon-apartment\",\"Edit Company Info\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"settings\"],null,{\"statements\":[[0,\"    \"],[1,[25,\"menu-button\",null,[[\"icon\",\"line-two\"],[\"icon-cog\",\"General Settings\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/companies/show/company-settings/template.hbs"
    }
  });

  _exports.default = _default;
});