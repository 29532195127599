define("account-berry-frontend/utils/account-type-activities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = accountTypeActivities;

  function accountTypeActivities() {
    return {
      "Cash": "Operating",
      "Accounts Receivable": "Operating",
      "Inventory": "Operating",
      "Current Assets": "Operating",
      "Fixed Assets": "Investing",
      "Other Assets": "Operating",
      "Accounts Payable": "Operating",
      "Current Liability": "Operating",
      "Long Term Liability": "Financing",
      "Equity": "Financing",
      "Income": "Operating",
      "Cost Of Goods Sold": "Operating",
      "Expense": "Operating",
      "Other Income": "Operating",
      "Other Expense": "Operating"
    };
  }
});