define("account-berry-frontend/companies/show/accounts/controller", ["exports", "ember-cli-pagination/computed/paged-array", "account-berry-frontend/config/environment"], function (_exports, _pagedArray, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    applicationController: Ember.inject.controller('application'),
    session: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    newAccount: false,
    accountForm: false,
    confirmDelete: false,
    isCalculating: false,
    search: "",
    account: Ember.computed(function () {
      return {};
    }),
    sortAscending: false,
    subAccountOfValid: true,
    accountTypeId: "",
    pagedAccounts: (0, _pagedArray.default)('filteredAccounts', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    filters: Ember.computed(function () {
      return [{
        name: "NAME",
        value: "name"
      }, {
        name: "NUMBER",
        value: "number"
      }, {
        name: "TYPE",
        value: "account_type.name"
      }];
    }),
    filter: "name",
    init: function init() {
      this._super(); // this.get('applicationController').set('showLoading', true);

    },
    modelObserver: Ember.observer('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      } // Having some issues with setting pagedAccounts after creating/updating accounts for some reason


      try {
        this.set('filteredAccounts', this.store.peekAll('account').filter(function (account) {
          return !account.get('isDeleted');
        }));
        this.set('pagedAccounts', (0, _pagedArray.default)('filteredAccounts', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    totalAccounts: Ember.computed('filteredAccounts', function () {
      return this.get('filteredAccounts.length');
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this2 = this;

      var channel = this.get('session.pusher').subscribe("account-channel-".concat(this.companyId));
      var dataChannel = this.get('session.pusher').subscribe("account-channel-".concat(this.get('session.data.authenticated.id')));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          _this2.updateAccountList(data.account);
        }
      });
      channel.bind('updated', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          var accountId = data.account.id;
          delete data.account.id;

          var account = _this2.store.peekRecord('account', accountId);

          account.setProperties(data.account);
        }
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          var account = _this2.store.peekRecord('account', data.account_id);

          account.deleteRecord();

          _this2.set('filteredAccounts', _this2.store.peekAll('account').filter(function (account) {
            return !account.get('isDeleted');
          }));

          _this2.set('pagedAccounts', (0, _pagedArray.default)('filteredAccounts', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        }
      });
      dataChannel.bind('chart-of-accounts', function (response) {
        // this.store.unloadAll('account');
        _this2.store.findRecord('json-storage', response.json_storage_id).then(function (jsonStorage) {
          // this.get('applicationController').set('showLoading', false);
          _this2.get('store').pushPayload({
            accounts: jsonStorage.get('stored_json')
          });

          var titleAccounts = _this2.store.peekAll('account').filter(function (acc) {
            return acc.get('is_title');
          });

          if (titleAccounts) {
            titleAccounts.forEach(function (account) {
              var subAccounts = [];
              account.get('sub_accounts').forEach(function (subAccount) {
                subAccounts.push(jsonStorage.get('stored_json').find(function (account) {
                  return account.id === subAccount.id;
                }));
              });

              var acc = _this2.store.peekRecord('account', account.id);

              acc.set('sub_accounts', subAccounts);
              acc.set('amount', subAccounts.reduce(function (a, b) {
                return a + parseFloat(b.amount);
              }, 0));
            });
          }

          _this2.set('model', _this2.store.peekAll('account'));

          _this2.set('isCalculating', false);
        });
      });
      this.set('subscribedToChannel', true);
    },
    updateAccountList: function updateAccountList(account) {
      this.store.push({
        data: {
          id: account.id,
          type: 'account',
          attributes: account
        }
      });
      this.set('filteredAccounts', this.store.peekAll('account').filter(function (account) {
        return !account.get('isDeleted');
      }));
      this.set('pagedAccounts', (0, _pagedArray.default)('filteredAccounts', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    filteredAccounts: Ember.computed('model', function () {
      return this.model.filter(function (account) {
        if (account.get('is_sub_account') !== true) {
          return account;
        }
      });
    }),
    filterAccounts: Ember.observer('search', function () {
      var search = this.search;
      var filter = this.filter;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      if (search === "" || search.trim().length === 0) {
        this.set('filteredAccounts', this.model);
      } else {
        this.set('filteredAccounts', this.model.filter(function (account) {
          var accountValue = account.get(filter);

          if (accountValue) {
            if (isNaN(accountValue)) {
              accountValue = accountValue.toLowerCase();
            }

            if (accountValue.includes(search)) {
              return account;
            }
          }
        }));
      }

      this.set('pagedAccounts', (0, _pagedArray.default)('filteredAccounts', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    accountTypes: Ember.computed(function () {
      var accountTypes = this.get('session.data.authenticated.account_types').filter(function (accountType) {
        switch (accountType.name) {
          case "Inventory":
            break;

          case "Accounts Payable":
            break;

          case "Accounts Receivable":
            break;

          default:
            return accountType;
        }
      });
      return accountTypes.sort(function (a, b) {
        return a.order_number - b.order_number;
      });
    }),
    generateTable: function generateTable() {
      var table = "<div style=\"font-family: Verdana;\"><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3><h3 style='text-align: center;'>Accounts</h3><table id='accountsTable' style='width:100%; font-size: 14px'><thead><tr><th style='text-align: left;'>NAME</th>");
      table += "<th style='text-align: left;'>NUMBER</th>";
      table += "<th style='text-align: left;'>TYPE</th>";
      table += "<th style='text-align: left;'>ACTIVITY</th>";
      table += "<th style='text-align: left;'>TAX ID</th>";
      table += "<th style='text-align: right'>AMOUNT</th></tr></thead>";
      this.get('filteredAccounts').forEach(function (account) {
        table += "<tbody>";

        if (!account.get('is_sub_account')) {
          table += "<tr><td>".concat(account.get('name'), "</td>");
          table += "<td>".concat(account.get('number') ? account.get('number') : "", "</td>");
          table += "<td>".concat(account.get('account_type.name'), "</td>");
          table += "<td>".concat(account.get('activity'), "</td>");
          table += "<td>".concat(account.get('tax_id') ? account.get('tax_id') : "", "</td>");

          if (account.get('is_title')) {
            table += "<td style=\"text-align: right\">".concat(account.get('title_amount') ? parseFloat(account.get('title_amount')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "-", "</td></tr>");
          } else {
            table += "<td style=\"text-align: right\">".concat(account.get('amount') ? parseFloat(account.get('amount')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "-", "</td></tr>");
          }

          account.get('sub_accounts').forEach(function (subAccount) {
            table += "<tr><td>&emsp;".concat(subAccount.name, "</td>");
            table += "<td>".concat(subAccount.number ? subAccount.number : "", "</td>");
            table += "<td></td>";
            table += "<td>".concat(subAccount.activity, "</td>");
            table += "<td>".concat(subAccount.tax_id ? subAccount.tax_id : "", "</td>");
            table += "<td style=\"text-align: right\">".concat(subAccount.amount ? parseFloat(subAccount.amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "-", "</td></tr>");
          });
        }
      });
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      toggleEditAccount: function toggleEditAccount(account) {
        this.set('account', account);

        if (account) {
          this.set('accountTypeId', account.get('account_type_id'));
        }

        this.toggleProperty('accountForm');
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        if (id) {
          var _this = this;

          this.store.findRecord('account', id, {
            backgroundReload: false
          }).then(function (account) {
            _this.set('account', account);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      changeFilter: function changeFilter(value) {
        this.set('filter', value);
      },
      updateAccountType: function updateAccountType(account, typeId) {
        var _this3 = this;

        var accountType = this.get('session.data.authenticated.account_types').find(function (accType) {
          return accType.id.toString() === typeId.toString();
        });
        account.setProperties({
          'account_type': accountType,
          'account_type_id': typeId
        });
        this.store.findRecord('account', account.id, {
          backgroundReload: false
        }).then(function (account) {
          account.setProperties(account);
          account.save().then(function () {
            _this3.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Account Cash Flow Activity Updated</span></div>'
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this3.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });

            account.rollbackAttributes();
          });
        });
      },
      updateAccountActivity: function updateAccountActivity(account, activity) {
        var _this4 = this;

        account.set('activity', activity);
        this.store.findRecord('account', account.id, {
          backgroundReload: false
        }).then(function (account) {
          account.setProperties(account);
          account.save().then(function () {
            _this4.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Account Cash Flow Activity Updated</span></div>'
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this4.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });

            account.rollbackAttributes();
          });
        });
      },
      deleteAccount: function deleteAccount() {
        var _this5 = this;

        var account_name = this.account.get('name');
        this.account.destroyRecord().then(function () {
          _this5.set('filteredAccounts', _this5.store.peekAll('account').filter(function (account) {
            return !account.get('isDeleted');
          }));

          _this5.setProperties({
            pagedAccounts: (0, _pagedArray.default)('filteredAccounts', {
              infinite: "unpaged",
              page: 1,
              perPage: 100
            }),
            account: {},
            confirmDelete: false
          });

          _this5.notify.alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(account_name, " Account Deleted</span></div>")
          });
        }).catch(function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this5.toggleProperty('confirmDelete');

          _this5.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      loadNext: function loadNext() {
        try {
          this.get('pagedAccounts').loadNextPage();
        } catch (e) {
          return;
        }
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('filteredAccounts', this.get('filteredAccounts').sortBy(property));
        } else {
          this.set('filteredAccounts', this.get('filteredAccounts').sortBy(property).reverse());
        }

        this.set('pagedAccounts', (0, _pagedArray.default)('filteredAccounts', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      print: function print() {
        var table = this.generateTable();
        Ember.$('.print-container').empty();
        Ember.$('.print-container').append(table);
        Ember.$('.print-container').printThis({
          importCSS: false
        }); // let w = window.open();
        // w.document.write("<div id='print-container'></div>");
        // w.document.getElementById('print-container').innerHTML += table;
        // w.print();
        // w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        Ember.$('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        Ember.$('#accountsTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_accounts_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        Ember.$('#accountsTable').remove();
        Ember.$('#csvExport').hide();
      },
      cancel: function cancel() {
        this.set('analyzedImportData', null);
      },
      calculateAmounts: function calculateAmounts() {
        var _this6 = this;

        this.set('isCalculating', true);
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.get('companyId'), "/accounts"),
          method: 'GET',
          contentType: 'application/json',
          data: {
            chart_of_accounts: true
          },
          successCallback: function successCallback() {},
          errorCallback: function errorCallback(e) {
            _this6.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      }
    }
  });

  _exports.default = _default;
});